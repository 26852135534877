@import '../scss/variables';

.login-register {
  padding: 55px 0 140px 0;

  .header {
    background: $white;
    box-shadow: 0px 0px 6px $tertiary-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px 20px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    max-width: 480px;
    margin: 0 auto;
    z-index: 10;

    &.logo {
      padding: 0;
      box-shadow: none;
    }

    .img-wrap {
      text-align: center;
      min-width: 100%;
      width: 100%;

      img {
        width: 144px;
        display: inline-block;
      }
    }

    .back {
      width: 40px;
      position: absolute;
      left: 20px;
      cursor: pointer;
    }

    .close {
      left: auto;
      right: 0;
    }

    .text {
      flex-grow: 1;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: $secondary;
    }
  }

  .content-wrapper {
    padding: 8px;
    background-color: $white;
    margin-top: 2px;
  }

  &.login {
    .content-wrapper {
      padding: 38px 20px 0 20px;
    }

    .logo-wrapper {
      margin: 140px 0 120px 0;
      text-align: center;
    }

    .title-text {
      margin-bottom: 14px;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #2f2f2f;
      margin-bottom: 24px;
    }

    .sub-text {
      font-size: 14px;
      line-height: 18px;
      color: $text-light;
      margin-bottom: 8px;

      .link {
        color: $primary;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        font-family: 'sukh-bold';
        cursor: pointer;
        margin: 0 4px;

        &.underlined {
          text-decoration: underline;
        }

        &.big {
          font-size: 16px;
        }
      }
    }

    .buttons-wrapper {
      margin-top: 32px;

      button {
        width: 100%;
      }
    }

    .line {
      background-color: #d3dded;
      width: 100%;
      height: 1px;
      margin: 24px 0 16px 0;
    }

    .link-wrap {
      // position: fixed;
      // left: 0;
      // bottom: 40px;
      // right: 0;
      text-align: center;
      width: 100%;
      margin-top: 60px;

      p {
        font-size: 14px;
        line-height: 17px;
        color: #707070;
        margin-bottom: 8px;

        &.center {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &.second-message {
        margin-top: 15px;
      }

      .link {
        color: $primary;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        font-family: 'sukh-bold';
        cursor: pointer;
        margin: 0 4px;

        &.underlined {
          text-decoration: underline;
        }

        &.big {
          font-size: 16px;
        }
      }
    }
  }

  &.otp {
    .content-wrapper {
      padding: 10px 30px;
    }

    .buttons-wrapper {
      margin-bottom: 16px;

      button {
        width: 100%;
      }
    }

    .otp-img-wrap {
      text-align: center;
      margin: 30px auto 20px auto;

      img {
        width: 165px;
        height: 140px;
      }
    }

    .text-wrapper {
      margin-bottom: 20px;
      text-align: center;

      .title-text {
        font-size: 20px;
        line-height: 24px;
        color: $text-dark;
        margin-bottom: 8px;
      }

      .sub-text {
        font-size: 14px;
        line-height: 18px;
        color: $text-light;

        .bold {
          font-family: 'sukh-bold';
        }
      }

      .sub-text.warning {
        color: #ff6400;
      }
    }

    .link-wrap {
      text-align: center;

      .link {
        font-size: 14px;
        line-height: 17px;
        color: #6d6e71;

        span {
          color: $primary;
          font-family: 'sukh-medium';
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .input-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      flex-direction: column;

      > label {
        font-family: 'sukh-bold';
        font-size: 12px;
        line-height: 14px;
        color: #707070;
        margin-bottom: 4px;
      }

      &.error {
        input[type='tel'] {
          border: 1px solid $error;
        }
      }

      .inline-error {
        display: block;
        margin-top: 10px;
        font-family: 'sukh-medium';
        font-size: 12px;
        line-height: 14px;
      }
      .input-row {
        display: flex; 
        align-items: center; 
        width: 100%;
      }

      > div {
        > div {
          &:not(:last-child) {
            margin-right: 12px;
          }
        }
      }
      .toggleEyeButton {
        height: 40px;
        border: 1px solid $tertiary;
        border-left: none;
        background: transparent;
        cursor: pointer;
        
        &.error {
          border-color: red;
        }
      }
      .password {
        flex: 1; 
        border: 1px solid $tertiary;
        min-width: 40px;
        height: 40px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $text-dark;
        outline: none;
        border-right: none;

        &.error {
          border-color: red;
        }
      }

      input[type='tel'] {
        border: 0;
        border: 1px solid $tertiary;
        min-width: 40px;
        height: 40px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $text-dark;
        outline: none;
        border-radius: 0;
        border-radius: 8px;
      }
    }
    #passwordReset{
      margin-bottom: 5px;
      // background-color: green;
    }
    .helper-text {
      font-family: 'sukh-medium';
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: $text-light;
    }

    .bottom-section {
      flex-direction: column;
      padding: 16px 8px 32px 8px;
      background: #ffffff;
      box-shadow: 0px 0px 6px #d8e7fd;

      .link-wrap {
        margin-top: 20px;
      }
    }
  }

  &.welcome {
    padding: 0 24px;

    .dp {
      margin: 100px auto 40px auto;
      background: url(../../../images/fairdee-x-plus/auth/display-pic-bg.svg) no-repeat;
      width: 210px;
      height: 195px;

      img {
        border-radius: 100%;
        width: 168px;
        height: 168px;
        object-fit: contain;
        margin: 0 auto;
        display: block;
      }
    }

    .select-account-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      margin-top: 28px;

      .img-wrap {
        padding: 8px 0 8px 0;
        margin-bottom: 20px;
        text-align: center;
        flex-grow: 1;
        flex-basis: 100%;
        max-height: 120px;
        max-width: 118px;
        background: #f6f9ff;
        box-shadow: 0px 3px 6px #d8e7fd;
        border-radius: 8px;
        cursor: pointer;

        p {
          margin-top: 4px;
          font-size: 14px;
          font-weight: bold;
          line-height: 16px;
          color: $secondary;
        }

        .img-text {
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
          color: #6d6e71;
        }

        &:first-child {
          margin-right: 24px;
        }
      }
    }

    .title-text {
      font-size: 22px;
      line-height: 24px;
      text-align: center;
      color: $text-dark;
      margin-bottom: 12px;
    }

    .sub-text {
      font-size: 14px;
      line-height: 18px;
      color: $text-light;
      margin-bottom: 8px;
      text-align: center;
    }

    .bottom-section {
      flex-direction: column;

      button,
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &.orange-bordered-button {
          color: #2f2f2f;
          height: auto;
          line-height: 1 !important;
          padding: 12px !important;
          display: inline-block;
        }
      }
    }
  }

  &.welcome-modal {
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0;
    background-color: transparent;

    .content-wrapper {
      text-align: center;
      max-width: calc(100% - 40px);
      width: 100%;
      background: #ffffff;
      border-radius: 8px;
      position: relative;
    }

    .close {
      position: absolute;
      right: -10px;
      top: -10px;
      z-index: 1;
    }

    .img-wrap {
      background: #f6f9ff;
      text-align: center;
      padding: 20px 20px 30px 20px;
      margin-bottom: 24px;
    }

    .title-text {
      font-family: 'sukh-bold';
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #2f2f2f;
      margin-bottom: 10px;
    }

    .text {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #707070;
      margin-bottom: 15px;
    }

    button {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &.number-verified,
  &.email-sent,
  &.update-successful {
    .content-wrapper {
      padding: 80px 24px 24px;
    }

    .img-wrap {
      margin-bottom: 26px;
      text-align: center;
    }

    .text-wrapper {
      font-style: normal;
      line-height: 24px;
      text-align: center;
      color: $text-dark;

      .title-text {
        margin-bottom: 20px;
        font-size: 20px;
        font-family: 'sukh-medium';
      }

      .sub-text {
        margin-top: 8px;
        padding: 11px;
        font-size: 14px;
        font-weight: lighter;
      }
    }

    .bottom-section {
      flex-direction: column;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &.orange-bordered-button {
          color: #2f2f2f;
        }
      }
    }

    .link-text {
      font-family: 'sukh-medium';
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #ff6400;
    }
  }

  &.number-verified {
    .bottom-section {
      .orange-bordered-button {
        span {
          margin-left: 4px;
        }
      }
    }
  }

  &.sign-up {
    background: $tertiary-light;
    padding: 0;
    min-height: calc(100vh - 120px);

    .content-wrapper {
      background-color: transparent;
      margin: 0;
    }

    .profile-form-wrapper {
      padding-bottom: 120px;

      .down-hint-wrap {
        position: fixed;
        bottom: 120px;
        left: 0;
        right: 0;
        margin: auto;
        width: 36px;
        cursor: pointer;
      }

      .profile-form {
        padding: 8px 12px 12px 12px;
        background: $white;
        box-shadow: 0px 3px 6px $tertiary-dark;
        border-radius: 4px;

        .personal-details {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          .img-wrap {
            margin-right: 8px;
          }

          .text {
            flex-grow: 1;
            font-family: 'sukh-medium';
            font-size: 14px;
            line-height: 17px;
            color: $secondary;
          }
        }

        .prefix-select {
          width: 120;
        }
      }
    }
  }

  .terms-conditions-wrap {
    background: #ffffff;
    box-shadow: 0px 3px 6px #d8e7fd;
    border-radius: 8px;
    margin-top: 12px;

    .content-wrap {
      padding: 12px;

      .title-text {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #6d6e71;
        margin-bottom: 4px;
      }

      .text {
        font-size: 12px;
        line-height: 16px;
        color: #707070;
      }
    }

    .checkbox-wrap {
      background: #f8fbff;
      border-radius: 0px 0px 8px 8px;
      padding: 12px;

      span {
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #1253a4;
      }
    }
  }

  .bottom-section {
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 8px 20px 8px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 480px;
    z-index: 99;

    &.with-logout {
      flex-direction: column;

      .logout-wrapper {
        background: #ffffff;
        margin: 16px 0 0 0;
        padding: 12px;
        font-family: 'sukh-medium';
        font-size: 12px;
        line-height: 14px;
        color: #323c47;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;

        img {
          margin-right: 8px;
        }
      }
    }

    button {
      width: 100%;
    }

    &.border {
      box-shadow: 0px 0px 6px $tertiary-dark;
    }
  }
}

.recaptcha {
  .link-wrap {
    &.left {
      text-align: left !important;
    }

    &.right {
      text-align: right !important;
    }

    &.center {
      text-align: left !important;
    }

    &.m-0 {
      margin: 0
    }

    .text-link {
      font-size: 14px;
      line-height: 18px;
      color: $text-light;
      margin-bottom: 8px;

      .clicked-txt {
        color: $primary;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        font-family: 'sukh-bold';
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .login-register {
    &.sign-up {
      .profile-form-wrapper {
        padding-bottom: 0;
      }
    }
  }
}


.password-indicators {
  margin-top: 0px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  font-size: 13px;
}

.indicator {
  color: green; /* Initially red */

  &.error {
    color: red;
  }
}

.progress-lines {
  display: flex;
  justify-content: space-between;
  margin-top: 0px; /* Adjust as needed */
  margin-bottom: 10px;
}

.progress-line {
  flex: 1;
  height: 4px;
  background-color: #ccc; /* Default color */
  margin-right: 4px;
}

.progress-line.valid {
  background-color: green; /* Success color */
}
