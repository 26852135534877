.referral-details {
  background: #ffffff;
  border-radius: 8px;
  padding: 12px 12px 16px 12px;
  // min-height: var(--vh);

  .top-section {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #2f2f2f;
    padding-bottom: 12px;
    border-bottom: 0.3px solid #94c9de;
  }

  .content {
    margin-top: 16px;

    img {
      max-width: 100%;
    }
  }

  .button-section {
    margin-top: 16px;

    button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .referral-details {
    min-height: var(--vh);

    .content {
      height: calc(var(--vh) - 120px);

      img {
        width: 100%;
        border-radius: 8px;
      }
    }

    .button-section {
      position: absolute;
      left: 12px;
      right: 12px;
      bottom: 12px;
    }
  }
}
