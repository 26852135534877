@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url(~slick-carousel/slick/slick-theme.css);
@import url(~antd/dist/antd.css);
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Chonburi&display=swap");
* {
  box-sizing: border-box; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.font-xs {
  font-size: .75rem !important; }

.font-sm {
  font-size: .85rem !important; }

.font-lg {
  font-size: 1rem !important; }

.font-xl {
  font-size: 1.25rem !important; }

.font-2xl {
  font-size: 1.5rem !important; }

.font-3xl {
  font-size: 1.75rem !important; }

.font-4xl {
  font-size: 2rem !important; }

.font-5xl {
  font-size: 2.5rem !important; }

.file-upload {
  padding-bottom: 12px; }
  .file-upload .gallery-view {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns with equal width */
    gap: 10px;
    margin-bottom: 32px; }
    .file-upload .gallery-view .card {
      background: #fff;
      border-radius: 8px;
      padding: 8px;
      max-width: 160px; }
      .file-upload .gallery-view .card .card-header {
        display: flex !important;
        align-items: flex-start;
        position: relative;
        margin-bottom: 4px !important; }
        .file-upload .gallery-view .card .card-header .cancel-wrap {
          flex-grow: 1;
          text-align: right; }
          .file-upload .gallery-view .card .card-header .cancel-wrap img {
            max-width: 12px;
            cursor: pointer;
            margin-left: 4px; }
      .file-upload .gallery-view .card .card-text {
        font-size: 12px;
        color: #1253a4;
        margin-top: 8px;
        cursor: pointer;
        text-decoration: underline;
        font-family: 'sukh-bold';
        word-wrap: break-word; }
      .file-upload .gallery-view .card .img-wrap {
        position: relative;
        height: 100px;
        max-width: 160px;
        width: 100%;
        cursor: pointer; }
        .file-upload .gallery-view .card .img-wrap img {
          max-width: 100%;
          width: 100%;
          height: 100%;
          object-fit: contain;
          /* This will make the images cover the square without stretching */
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 4px;
          border: 1px solid #d0d0d0; }
        .file-upload .gallery-view .card .img-wrap .pdf-viewer {
          max-width: 100%;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          border: 1px solid #d0d0d0;
          overflow: hidden; }
  .file-upload .uploaded-file {
    border-radius: 2px;
    background-color: #ffeed2;
    font-size: 12px;
    font-family: 'sukh-regular';
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #5f5f5f;
    padding: 10px 12px;
    border: 1px solid #f5a623;
    margin: 0 12px 12px 0;
    display: inline-block;
    position: relative;
    padding-right: 24px;
    max-width: 100%;
    vertical-align: top;
    word-break: break-all; }
    .file-upload .uploaded-file .img-wrap {
      width: 22px;
      height: 100%;
      display: inline-block;
      cursor: pointer;
      vertical-align: middle;
      position: absolute;
      top: 0;
      right: 0;
      text-align: center; }
      .file-upload .uploaded-file .img-wrap img {
        min-width: 12px;
        min-height: 12px;
        max-width: 12px;
        position: relative;
        top: calc(50% - 6px);
        right: 4px; }
  .file-upload .file-input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    width: 100%; }
  .file-upload button {
    position: relative;
    padding: 8px 24px !important;
    margin-bottom: 12px; }

.search-dropdown {
  width: 200px;
  margin-left: 8px;
  margin-right: 8px;
  display: inline-flex;
  vertical-align: top;
  position: relative;
  flex-direction: column;
  margin-top: 11px; }
  .search-dropdown .ui.selection.dropdown.custom {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    min-width: 100%;
    padding: 12px 0 8px 0;
    position: relative;
    vertical-align: top;
    line-height: 1;
    min-height: auto; }
    .search-dropdown .ui.selection.dropdown.custom .menu {
      max-height: 180px; }
    .search-dropdown .ui.selection.dropdown.custom input.search {
      padding: 0;
      height: 100%;
      bottom: 0;
      line-height: 1;
      border-radius: 0;
      border: 0;
      box-shadow: none; }
    .search-dropdown .ui.selection.dropdown.custom:hover {
      border-bottom: 2px solid #b26414;
      box-sizing: border-box; }
    .search-dropdown .ui.selection.dropdown.custom .text {
      padding: 0;
      font-size: 1rem;
      font-family: 'sukh-regular';
      line-height: 1;
      color: rgba(0, 0, 0, 0.87); }
      .search-dropdown .ui.selection.dropdown.custom .text.default {
        color: rgba(0, 0, 0, 0.54); }
  .search-dropdown .inline-error {
    display: block;
    margin-top: 8px;
    min-height: 1em;
    font-family: 'sukh-regular';
    line-height: 1em; }

.pagination-input {
  display: inline-block;
  margin: 0 10px; }
  .pagination-input.disabled {
    pointer-events: none; }
  .pagination-input input[type='number'] {
    background-color: #ffffff;
    position: relative;
    min-width: 60px;
    border: 1px solid #d0d0d0;
    max-width: 60px;
    padding: 5px;
    color: #4a4a4a; }
    .pagination-input input[type='number']:focus {
      border: 1px solid #d0d0d0;
      padding: 5px; }

.fab-btn {
  z-index: 9999 !important; }

.message-popover div:nth-child(2) {
  padding: 6px;
  background: #fff;
  border-radius: 6px; }
  .message-popover div:nth-child(2) span {
    font-size: 12px; }

.uploaded-file {
  border-radius: 2px;
  background-color: #ffeed2;
  font-size: 12px;
  font-family: 'sukh-regular';
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5f5f5f;
  padding: 9px 12px;
  border: 1px solid #f5a623;
  margin: 0 12px 12px 0;
  display: inline-block;
  cursor: pointer;
  position: relative; }
  .uploaded-file.with-tag {
    margin-bottom: 24px; }
    .uploaded-file.with-tag.top {
      margin-top: 16px; }
    .uploaded-file.with-tag .tag {
      position: absolute;
      border-top: 0;
      font-size: 10px;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #fff;
      padding: 2px;
      bottom: -16px;
      width: calc(100% + 2px);
      left: -1px;
      right: -1px;
      border-radius: 0 0 4px 4px; }
      .uploaded-file.with-tag .tag.no-action {
        background: #f3b329; }
      .uploaded-file.with-tag .tag.accepted {
        background: #2fd000; }
      .uploaded-file.with-tag .tag.rejected {
        background: #e15948; }
      .uploaded-file.with-tag .tag.blue {
        background: #1890ff;
        font-family: 'sukh-bold'; }
      .uploaded-file.with-tag .tag.top {
        top: -10px;
        bottom: auto;
        border-radius: 4px 4px 0 0; }
  .uploaded-file img {
    margin-left: 4px;
    display: inline-block;
    cursor: pointer; }

.no-data {
  padding: 12px; }

hr {
  border: 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.4); }

.radio-label.block {
  display: block !important; }

.info-text {
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5f5f5f; }
  .info-text.process-info {
    font-size: 16px; }

.field > label.block {
  display: block !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-24 {
  margin-top: 24px !important; }

.mt-12 {
  margin-top: 12px !important; }

.ml-12 {
  margin-left: 12px; }

.ml-8 {
  margin-left: 8px; }

.ml-18 {
  margin-left: 18px; }

.ml-30 {
  margin-left: 30px; }

.mt-4 {
  margin-top: 4px; }

.mr-12 {
  margin-right: 12px; }

.mr-0 {
  margin-right: 0; }

.mb-12 {
  margin-bottom: 12px; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-24 {
  margin-bottom: 24px !important; }

.ml-0 {
  margin-left: 0; }

.max-width-100 {
  max-width: 100%; }

.relative {
  position: relative; }

.link-text {
  display: inline-block;
  color: blue;
  cursor: pointer;
  text-decoration: underline; }

.align-center {
  text-align: center; }

.flex-item {
  display: flex; }

.warning-wrapper {
  position: relative;
  white-space: pre-wrap;
  border-radius: 8px;
  border: 2px solid #f58b1e;
  margin: 0 auto;
  padding: 12px;
  display: block;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start; }
  .warning-wrapper .icon-wrap {
    margin-right: 12px; }
    .warning-wrapper .icon-wrap i {
      color: #f58b1e;
      font-size: 24px; }
  .warning-wrapper p:not(:last-child) {
    margin-bottom: 12px; }

.new-vehicle-number {
  margin-top: 12px !important; }
  .new-vehicle-number .ui.checkbox label {
    padding-left: 24px !important;
    font-size: 14px !important; }

.cursor-pointer {
  cursor: pointer; }

.download-doc-label {
  background: #f5a623;
  border-radius: 4px;
  padding: 2px 4px;
  cursor: pointer;
  font-size: 10px;
  display: inline-block;
  margin: 4px; }
  .download-doc-label a {
    color: #000;
    font-family: 'sukh-regular'; }

.error-info {
  margin: 12px 0 !important;
  color: #f44336; }
  .error-info .title {
    font-size: 14px;
    margin: 0 0 12px 0; }
  .error-info .text {
    font-size: 12px;
    position: relative;
    margin-bottom: 12px; }
    .error-info .text:before {
      content: '\203A';
      position: relative;
      display: inline-block;
      margin-right: 8px;
      font-size: 12px; }

.dynamic-promotions {
  background-image: linear-gradient(354deg, #ffe8d1 185%, #ffd5a7 46%, #fde9ce -54%);
  padding: 18px;
  text-align: center;
  background-size: 100% 100%;
  position: relative;
  border-radius: 8px;
  height: 146px;
  border: 2px solid #e8903d; }
  .dynamic-promotions .gift-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px 12px 8px 36px;
    background: #e8903d url(images/icons/gift.svg) no-repeat;
    background-position: 4px center;
    background-size: 30px 30px;
    border-radius: 4px 0 0 0;
    font-size: 14px;
    font-family: 'sukh-bold';
    line-height: normal;
    letter-spacing: 0.3px;
    color: #ffffff;
    text-transform: uppercase;
    display: flex;
    align-items: center; }
  .dynamic-promotions.centered {
    padding: 24px 120px;
    background-size: 100%; }
  .dynamic-promotions p {
    text-align: left;
    line-height: 1; }
    .dynamic-promotions p .text-one {
      font-size: 20px;
      font-family: 'sukh-bold';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383; }
    .dynamic-promotions p .text-two {
      font-size: 16px;
      font-family: 'sukh-bold';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383; }
    .dynamic-promotions p .text-three {
      font-size: 20px;
      font-family: 'sukh-bold';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383; }
    .dynamic-promotions p .text-four {
      font-size: 12px;
      font-family: 'sukh-bold';
      color: #192383;
      line-height: normal; }

.personal-accident {
  max-width: 836.5px;
  margin: 32px auto; }
  .personal-accident .img-wrap {
    margin: 0 auto;
    display: flex; }
    .personal-accident .img-wrap div:first-child {
      max-width: 280px;
      width: 100%; }
    .personal-accident .img-wrap div:last-child {
      flex-grow: 1; }
    .personal-accident .img-wrap div img {
      max-width: 100%; }
  .personal-accident .button-wrap {
    text-align: center;
    margin-top: 24px; }
  .personal-accident .text-wrap {
    margin: 24px 0; }
    .personal-accident .text-wrap p {
      color: #282c3f;
      font-size: 16px;
      font-family: 'sukh-regular';
      margin-bottom: 8px;
      line-height: 1.4; }

.margin-bottom-24 {
  margin-bottom: 24px; }

.quotation-compare-wrapper {
  background: rgba(62, 63, 64, 0.9);
  padding: 12px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center; }
  .quotation-compare-wrapper .button-wrapper {
    max-width: 200px; }
    .quotation-compare-wrapper .button-wrapper button {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center; }
      .quotation-compare-wrapper .button-wrapper button img {
        max-width: 16px;
        margin-right: 8px; }
      .quotation-compare-wrapper .button-wrapper button:not(:last-child) {
        margin-bottom: 12px; }
  .quotation-compare-wrapper .compare-card-mini {
    background: #fff;
    padding: 10px 6px 10px 6px;
    margin: 0;
    margin-right: 12px;
    margin-bottom: 12px;
    border-radius: 2px;
    max-width: 240px;
    min-width: 180px; }
    .quotation-compare-wrapper .compare-card-mini .close {
      right: 8px;
      top: 8px;
      cursor: pointer;
      max-width: 10px; }
    .quotation-compare-wrapper .compare-card-mini .img-wrap {
      width: 40px;
      margin-bottom: 8px;
      height: 24px; }
      .quotation-compare-wrapper .compare-card-mini .img-wrap img {
        object-fit: contain;
        width: 100%;
        height: 100%; }
    .quotation-compare-wrapper .compare-card-mini .insurer-name,
    .quotation-compare-wrapper .compare-card-mini .plan-name {
      font-size: 12px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #4a4a4a;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .quotation-compare-wrapper .compare-card-mini .plan-name {
      margin-bottom: 0;
      display: none; }
    .quotation-compare-wrapper .compare-card-mini .premium-text,
    .quotation-compare-wrapper .compare-card-mini .insurance-type {
      font-size: 12px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a; }
      .quotation-compare-wrapper .compare-card-mini .premium-text span.number,
      .quotation-compare-wrapper .compare-card-mini .insurance-type span.number {
        font-family: 'sukh-bold';
        display: inline-block;
        margin: 0 4px; }

.calculation-wrap {
  margin-bottom: 24px; }
  .calculation-wrap .text {
    font-size: 16px;
    font-family: 'sukh-regular';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    margin-bottom: 8px; }
    .calculation-wrap .text span {
      font-family: 'sukh-bold'; }

.info-bar {
  border-radius: 1px;
  border: solid 1px #ffb94d;
  background-color: #fff6e8;
  padding: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 12px; }
  .info-bar.red {
    border: solid 1px #d0021b;
    background-color: rgba(208, 2, 27, 0.1); }
  .info-bar.change-action {
    border-radius: 3px;
    box-shadow: 0 5px 8px 0 rgba(197, 197, 207, 0.78);
    background-color: #fffcf2;
    padding: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: center;
    border: 0;
    cursor: pointer; }
    .info-bar.change-action img {
      margin-right: 32px;
      max-width: 68px; }
    .info-bar.change-action p {
      flex-grow: initial;
      font-size: 20px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a; }
      .info-bar.change-action p span span {
        font-family: 'sukh-medium'; }
    .info-bar.change-action.coa-change {
      justify-content: start;
      padding: 16px 52px; }
      .info-bar.change-action.coa-change p span span {
        color: #f58b1e;
        display: inline-block;
        margin-left: 4px; }
    .info-bar.change-action .link {
      color: #f58b1e;
      cursor: pointer;
      text-decoration: underline;
      margin-left: 8px; }
  .info-bar.is-broker {
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff6e8;
    border: 0; }
    .info-bar.is-broker p {
      line-height: 1; }
      .info-bar.is-broker p:first-child {
        margin-bottom: 8px; }
    .info-bar.is-broker .text-one {
      font-size: 14px;
      font-style: oblique;
      font-stretch: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      font-family: 'sukh-regular'; }
      .info-bar.is-broker .text-one span {
        font-family: 'sukh-medium';
        color: #1d2785; }
    .info-bar.is-broker img {
      margin-right: 8px;
      max-width: 32px; }
  .info-bar img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px; }
  .info-bar span.text {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-family: 'sukh-medium';
    line-height: 1.43;
    color: #5e5e5e;
    flex-grow: 1; }
    .info-bar span.text .bold {
      font-family: 'sukh-bold';
      color: #000000; }

.refer-earn-flat p {
  font-size: 14px;
  font-family: 'sukh-regular';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 8px;
  font-family: 'sukh-regular'; }

.refer-earn-flat button {
  min-width: 230px;
  background: #ff901d;
  color: #fff; }

.red-tag {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 2px;
  background: #f73c3c;
  color: #fff; }

.blue-tag {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 2px;
  background: #6054e2;
  color: #fff; }

.info-content-wrapper {
  background-color: #fff6e8;
  padding: 6px 10px;
  text-align: left;
  margin: 8px 0; }
  .info-content-wrapper .header-text {
    font-size: 10px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    text-align: left;
    padding-left: 20px;
    position: relative; }
    .info-content-wrapper .header-text.sum-insured-description {
      font-size: 12px; }
    .info-content-wrapper .header-text span {
      font-family: 'sukh-bold';
      color: #000000; }
    .info-content-wrapper .header-text:before {
      content: 'i';
      margin-right: 8px;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background: #f5a623;
      position: absolute;
      color: #fff;
      text-align: center;
      line-height: 1.2;
      vertical-align: middle;
      left: 0;
      top: 2px;
      font-size: 9px;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg); }
  .info-content-wrapper .text {
    font-size: 11px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    text-align: left;
    position: relative;
    display: flex;
    margin-bottom: 4px; }
    .info-content-wrapper .text:before {
      content: '\2022';
      font-size: 12px;
      position: relative;
      margin-right: 24px;
      padding-left: 4px; }

.align-right {
  text-align: right; }

.dd-menu > div:last-child {
  margin-top: 24px; }

.dd-menu ul {
  min-width: 130px; }

.admin-tag {
  background: rgba(245, 139, 30, 0.2);
  border-radius: 4px;
  padding: 4px 10px;
  border: 1px solid #f58b1e;
  font-size: 12px;
  font-family: 'sukh-bold'; }

.action-sheet {
  background-color: rgba(77, 92, 116, 0.9);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  transform: translateY(300%);
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center; }
  .action-sheet .backdrop {
    position: fixed;
    background: transparent;
    width: 100%;
    height: 100%;
    z-index: 0; }
    .action-sheet .backdrop + div {
      position: relative;
      z-index: 1; }
  .action-sheet.bottom-aligned {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; }
  .action-sheet.show {
    transform: translateY(0); }
  .action-sheet .blank-space {
    flex-grow: 1; }
  .action-sheet .content {
    max-width: 360px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    text-align: center;
    width: 100%;
    padding: 20px !important;
    margin-right: 0 !important; }
    .action-sheet .content .title-wrap p {
      font-size: 20px;
      font-family: 'sukh-bold';
      text-align: center;
      color: #192383;
      margin-bottom: 16px; }
    .action-sheet .content .buttons-wrap {
      display: flex;
      flex-direction: column; }
      .action-sheet .content .buttons-wrap.auth button {
        text-align: center; }
      .action-sheet .content .buttons-wrap button {
        flex-grow: 1;
        flex-basis: 100%;
        width: 100%;
        border-radius: 8px;
        border: solid 1px #f58b1e;
        background-color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 12px; }
        .action-sheet .content .buttons-wrap button .icon-wrap {
          width: 32px;
          height: 32px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #f58b1e;
          margin-right: 8px; }
          .action-sheet .content .buttons-wrap button .icon-wrap .fa {
            color: #f58b1e; }
        .action-sheet .content .buttons-wrap button.orange {
          background-color: #ff901d;
          color: #fff; }
        .action-sheet .content .buttons-wrap button img {
          margin-right: 10px; }
        .action-sheet .content .buttons-wrap button:not(:last-child) {
          margin-bottom: 12px; }

.header .language-switch {
  position: absolute;
  right: 0; }

.empty-state {
  text-align: left;
  margin-top: 24px; }

.inline-datepicker {
  display: flex;
  margin-bottom: 24px; }
  .inline-datepicker .search-list {
    margin: 0;
    padding: 0 4px; }
    .inline-datepicker .search-list .search-input {
      border: 0; }
      .inline-datepicker .search-list .search-input input {
        padding: 9px 0; }
      .inline-datepicker .search-list .search-input > label {
        font-size: 10px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.85);
        text-transform: capitalize; }
  .inline-datepicker > div {
    flex-grow: 1;
    flex-basis: 100%;
    border-radius: 1px;
    border: solid 1px #dcd9d6;
    background-color: #ffffff; }
    .inline-datepicker > div:not(:last-child) {
      margin-right: 8px; }

.microsite-edit-form .form-field {
  margin-bottom: 24px; }
  .microsite-edit-form .form-field .helper-text {
    font-size: 12px; }
  .microsite-edit-form .form-field button {
    width: 100%; }
  .microsite-edit-form .form-field > div {
    width: 100%; }

.upload-file-banner {
  background: url(images/onboarding/background-modal.png) no-repeat;
  width: 100%;
  background-size: cover;
  padding: 0 60px 60px;
  display: flex; }
  .upload-file-banner > div:first-child {
    padding-top: 60px;
    flex-grow: 1;
    margin-right: 40px; }
  .upload-file-banner > div:last-child {
    min-width: 110px;
    width: 110px;
    background: url(images/icons/coins-flying.png) no-repeat;
    background-size: contain;
    background-position: top; }
  .upload-file-banner .text-one {
    -webkit-text-stroke: 0.6px #1f1a8e;
    font-family: 'sukh-bold';
    font-size: 26px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #1b1f88;
    margin-bottom: 10px; }
  .upload-file-banner .text-two {
    font-size: 16px;
    font-family: 'sukh-bold';
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 10px; }
  .upload-file-banner button {
    border-radius: 10px;
    background-color: #f58b1e;
    padding: 10px;
    width: 100%;
    -webkit-text-stroke: 0.6px #ffffff;
    font-family: 'sukh-bold';
    font-size: 18px;
    border: 0;
    color: #ffffff;
    cursor: pointer; }

.padded-wrapper {
  padding: 20px;
  max-width: calc(100% - 60px); }

.center {
  text-align: center; }

.inline-div {
  display: inline-flex;
  min-width: 200px;
  max-width: 200px;
  margin: 0 8px 0 8px; }
  .inline-div > div {
    flex-grow: 1;
    flex-basis: 100%;
    margin: 0; }
    .inline-div > div:first-child {
      margin: 0 8px 0 0; }

.quotation-sheet-image {
  padding-top: 30px; }
  .quotation-sheet-image img {
    max-width: 100%; }

.notification-popup {
  position: fixed;
  margin: auto;
  overflow: hidden;
  width: 250px;
  height: 250px;
  right: 20px;
  bottom: 20px;
  z-index: 9; }
  .notification-popup h1 {
    font-size: 12px;
    font-weight: 100;
    letter-spacing: 2px;
    padding-top: 5px;
    color: #fcfcfc;
    padding-bottom: 5px;
    text-transform: uppercase; }
  .notification-popup .green {
    color: #4ec07d; }
  .notification-popup .alert {
    font-weight: 900;
    letter-spacing: 4px; }
  .notification-popup button,
  .notification-popup .dot {
    cursor: pointer; }
  .notification-popup .success-box {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #ff902b 40%, #fcfcfc 100%);
    border-radius: 20px; }
  .notification-popup .dot {
    width: 8px;
    height: 8px;
    background: #fcfcfc;
    border-radius: 50%;
    position: absolute;
    top: 4%;
    right: 6%; }
    .notification-popup .dot:hover {
      background: #c9c9c9; }
  .notification-popup .face {
    position: absolute;
    width: 22%;
    height: 22%;
    background: #fcfcfc;
    border-radius: 50%;
    border: 1px solid #777777;
    top: 21%;
    left: 37.5%;
    z-index: 2;
    animation: bounce 1s ease-in infinite; }
  .notification-popup .eye {
    position: absolute;
    width: 5px;
    height: 5px;
    background: #777777;
    border-radius: 50%;
    top: 40%;
    left: 20%; }
  .notification-popup .right {
    left: 68%; }
  .notification-popup .mouth {
    position: absolute;
    top: 43%;
    left: 41%;
    width: 7px;
    height: 7px;
    border-radius: 50%; }
  .notification-popup .happy {
    border: 2px solid;
    border-color: transparent #777777 #777777 transparent;
    transform: rotate(45deg); }
  .notification-popup .shadow {
    position: absolute;
    width: 21%;
    height: 3%;
    opacity: 0.5;
    background: #777777;
    left: 40%;
    top: 43%;
    border-radius: 50%;
    z-index: 1; }
  .notification-popup .scale {
    animation: scale 1s ease-in infinite; }
  .notification-popup .move {
    animation: move 3s ease-in-out infinite; }
  .notification-popup .message {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 40%;
    top: 47%; }
  .notification-popup .button-box {
    position: absolute;
    background: #fcfcfc;
    width: 50%;
    height: 15%;
    border-radius: 20px;
    top: 73%;
    left: 25%;
    outline: 0;
    border: none;
    box-shadow: 2px 2px 10px rgba(119, 119, 119, 0.5);
    transition: all 0.5s ease-in-out; }
    .notification-popup .button-box:hover {
      background: #efefef;
      transform: scale(1.05);
      transition: all 0.3s ease-in-out; }

@keyframes bounce {
  50% {
    transform: translateY(-10px); } }

@keyframes scale {
  50% {
    transform: scale(0.9); } }

@keyframes roll {
  0% {
    transform: rotate(0deg);
    left: 25%; }
  50% {
    left: 60%;
    transform: rotate(168deg); }
  100% {
    transform: rotate(0deg);
    left: 25%; } }

@keyframes move {
  0% {
    left: 25%; }
  50% {
    left: 60%; }
  100% {
    left: 25%; } }

.error-wrapper {
  max-width: 300px;
  border-radius: 4px;
  background-color: #ff901d;
  color: #fff;
  padding: 20px;
  margin: 40px auto;
  font-size: 18px;
  text-align: left; }
  .error-wrapper p {
    margin-bottom: 12px; }

.free-insurance-banner {
  background-image: linear-gradient(354deg, #ffe8d1 185%, #ffd5a7 46%, #fde9ce -54%);
  background-size: 100% 100%;
  position: relative;
  border-radius: 8px;
  margin: 20px 0;
  position: relative;
  border: 2px solid #e8903d;
  padding: 18px;
  font-size: 18px; }

.commission-rates-form {
  text-align: left; }
  .commission-rates-form label {
    display: block;
    font-size: 12px; }
  .commission-rates-form .product-choice {
    margin: 8px 0 20px 0; }

.vmi-error {
  text-align: center;
  background: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 300px;
  padding: 8px;
  margin: 12px;
  border-radius: 8px;
  overflow: auto; }
  .vmi-error .title-text {
    margin-bottom: 10px; }
    .vmi-error .title-text.red {
      color: red;
      font-size: 18px;
      font-weight: bolder; }
  .vmi-error .info-text {
    padding: 6px;
    color: #0099ff;
    font-weight: bold;
    font-size: 16px; }

.mui-dropdown {
  position: relative;
  margin-bottom: 20px;
  padding: 0 0 0 10px; }
  .mui-dropdown label {
    position: absolute;
    top: 2px;
    z-index: 1;
    color: rgba(0, 0, 0, 0.54); }
  .mui-dropdown .ui.fluid.dropdown {
    border: 0;
    border-radius: 0;
    padding: 20px 0 24px 0;
    position: relative;
    height: 24px;
    border-bottom: 1px solid #8f8f8f; }
    .mui-dropdown .ui.fluid.dropdown:hover {
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid #8f8f8f; }

.insurer-settings-wrapper {
  padding: 20px; }
  .insurer-settings-wrapper .content-wrapper {
    border-radius: 4px;
    background-color: #fff;
    padding: 16px 24px; }
  .insurer-settings-wrapper .key-value-wrap {
    display: flex;
    align-items: center;
    padding: 10px 0; }
    .insurer-settings-wrapper .key-value-wrap:not(:last-child) {
      border-bottom: solid 0.5px #e3e3e3; }
    .insurer-settings-wrapper .key-value-wrap.title-wrap .key,
    .insurer-settings-wrapper .key-value-wrap.title-wrap .value {
      font-family: 'sukh-bold'; }
    .insurer-settings-wrapper .key-value-wrap .key {
      margin-right: 10px;
      max-width: 300px;
      min-width: 300px; }
    .insurer-settings-wrapper .key-value-wrap .value {
      flex-grow: 1; }
  .insurer-settings-wrapper .ant-checkbox-group .ant-checkbox-wrapper,
  .insurer-settings-wrapper .ant-checkbox-group .ant-radio-wrapper,
  .insurer-settings-wrapper .ant-radio-group .ant-checkbox-wrapper,
  .insurer-settings-wrapper .ant-radio-group .ant-radio-wrapper {
    align-items: center;
    padding: 10px 0;
    flex-direction: row-reverse;
    justify-content: flex-start; }
    .insurer-settings-wrapper .ant-checkbox-group .ant-checkbox-wrapper > span:not(.ant-checkbox),
    .insurer-settings-wrapper .ant-checkbox-group .ant-radio-wrapper > span:not(.ant-checkbox),
    .insurer-settings-wrapper .ant-radio-group .ant-checkbox-wrapper > span:not(.ant-checkbox),
    .insurer-settings-wrapper .ant-radio-group .ant-radio-wrapper > span:not(.ant-checkbox) {
      max-width: 300px;
      min-width: 300px; }
    .insurer-settings-wrapper .ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox,
    .insurer-settings-wrapper .ant-checkbox-group .ant-checkbox-wrapper .ant-radio,
    .insurer-settings-wrapper .ant-checkbox-group .ant-radio-wrapper .ant-checkbox,
    .insurer-settings-wrapper .ant-checkbox-group .ant-radio-wrapper .ant-radio,
    .insurer-settings-wrapper .ant-radio-group .ant-checkbox-wrapper .ant-checkbox,
    .insurer-settings-wrapper .ant-radio-group .ant-checkbox-wrapper .ant-radio,
    .insurer-settings-wrapper .ant-radio-group .ant-radio-wrapper .ant-checkbox,
    .insurer-settings-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio {
      top: auto; }
  .insurer-settings-wrapper .button-wrapper {
    text-align: right; }
    .insurer-settings-wrapper .button-wrapper .button:not(:last-child) {
      margin-right: 12px; }

.inline-input-keys-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between; }
  .inline-input-keys-wrapper .flex-item {
    display: flex;
    align-items: flex-end; }
  .inline-input-keys-wrapper .input-wrapper {
    position: relative; }
  .inline-input-keys-wrapper .select-input-wrapper {
    position: relative; }
  .inline-input-keys-wrapper .button-wrapper {
    position: relative; }
  .inline-input-keys-wrapper .date-picker-wrapper {
    position: relative; }
    .inline-input-keys-wrapper .date-picker-wrapper .date-picker.error .ant-picker {
      border: 1px solid #ff0000; }
  .inline-input-keys-wrapper .label {
    font-size: 14px;
    margin-bottom: 4px; }
  .inline-input-keys-wrapper > div {
    flex-grow: 1;
    flex-basis: 100%;
    margin-right: 12px; }
  .inline-input-keys-wrapper .inline-error {
    position: absolute;
    left: 0;
    bottom: -18px; }
  .inline-input-keys-wrapper .inline-button-wrapper {
    margin: 0; }

.common-form {
  padding: 20px; }
  .common-form .title-label {
    margin-bottom: 12px;
    display: block;
    font-size: 16px;
    line-height: 20px;
    color: #1e2886;
    font-family: 'sukh-medium'; }
  .common-form .title-sub-label {
    margin-bottom: 12px;
    display: block;
    font-size: 14px;
    line-height: 18px; }
  .common-form .label {
    margin-bottom: 4px;
    display: block;
    font-size: 12px;
    line-height: 14px;
    color: #777171; }
  .common-form .form-element {
    margin-bottom: 16px; }
    .common-form .form-element.right {
      text-align: right; }
    .common-form .form-element ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      text-transform: capitalize !important;
      color: #bfbfbf !important;
      text-transform: capitalize; }
    .common-form .form-element ::-moz-placeholder {
      /* Firefox 19+ */
      text-transform: capitalize !important;
      color: #bfbfbf !important;
      text-transform: capitalize; }
    .common-form .form-element :-ms-input-placeholder {
      /* IE 10+ */
      text-transform: capitalize !important;
      color: #bfbfbf !important;
      text-transform: capitalize; }
    .common-form .form-element :-moz-placeholder {
      /* Firefox 18- */
      text-transform: capitalize !important;
      color: #bfbfbf !important;
      text-transform: capitalize; }
    .common-form .form-element .readonly-field {
      border-radius: 4px;
      background-color: #fff;
      border: solid 1px #e3e3e3;
      padding: 7px 13px;
      font-size: 14px;
      text-align: left;
      color: #4a4a4a;
      line-height: 1.5;
      width: 100%; }
    .common-form .form-element input[type='text'],
    .common-form .form-element input[type='email'],
    .common-form .form-element input[type='password'],
    .common-form .form-element input[type='number'],
    .common-form .form-element textarea {
      border-radius: 4px;
      background-color: #fff;
      border: solid 1px #e3e3e3;
      padding: 7px 13px;
      font-size: 14px;
      text-align: left;
      color: #4a4a4a;
      line-height: 1.5;
      width: 100%;
      outline: none; }
      .common-form .form-element input[type='text']:focus,
      .common-form .form-element input[type='email']:focus,
      .common-form .form-element input[type='password']:focus,
      .common-form .form-element input[type='number']:focus,
      .common-form .form-element textarea:focus {
        border-radius: 4px;
        border: solid 1px #e3e3e3; }

.ml-10 {
  margin-left: 10px; }

@media screen and (max-width: 1024px) {
  .info-bar {
    position: relative;
    display: flex;
    padding: 8px;
    margin: 12px; }
    .info-bar.change-action {
      padding: 8px;
      margin: 0 0 16px 0; }
      .info-bar.change-action p {
        text-align: left; }
      .info-bar.change-action.coa-change {
        padding: 8px; }
      .info-bar.change-action p {
        font-size: 12px; }
      .info-bar.change-action img {
        margin-right: 16px;
        width: 66px; }
    .info-bar.is-broker img {
      max-width: 26px; }
    .info-bar span.text {
      font-size: 11px; }
    .info-bar button {
      min-width: 110px;
      margin-left: 8px; }
  .wizard-page {
    padding-top: 80px; }
  .action-sheet {
    background-color: rgba(77, 92, 116, 0.9);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    transform: translateY(300%);
    transition: all 0.3s ease-out;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    align-items: unset;
    justify-content: unset; }
    .action-sheet .collapse-wrap {
      border-top: solid 0.5px rgba(176, 178, 198, 0.2); }
      .action-sheet .collapse-wrap.open p svg {
        color: #ff901d;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg); }
      .action-sheet .collapse-wrap p {
        font-size: 14px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626;
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        padding: 12px 12px 12px 0; }
        .action-sheet .collapse-wrap p .icon {
          min-width: 24px;
          max-width: 24px;
          height: 24px;
          margin-right: 12px;
          display: flex;
          align-items: center; }
          .action-sheet .collapse-wrap p .icon img {
            max-width: 100%; }
        .action-sheet .collapse-wrap p svg {
          position: absolute;
          right: 24px;
          transition-duration: 0.4s;
          transition-property: transform; }
      .action-sheet .collapse-wrap .open-wrap {
        background-color: #ffffff;
        padding: 0 24px 0 60px; }
        .action-sheet .collapse-wrap .open-wrap .link {
          border: 0 !important; }
    .action-sheet .links-wrap {
      padding: 0; }
    .action-sheet.no-translate {
      transform: translateY(0);
      top: 120%;
      opacity: 0; }
    .action-sheet.navigation {
      display: block; }
      .action-sheet.navigation .bottom-section {
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 20px 20px 20px 0;
        display: flex; }
        .action-sheet.navigation .bottom-section .text {
          opacity: 0.8;
          font-size: 14px;
          font-family: 'sukh-medium';
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #282c3f;
          flex-grow: 1;
          display: flex; }
      .action-sheet.navigation .content {
        position: absolute;
        height: 100%;
        width: calc(100% - 32px);
        right: 0;
        left: auto;
        display: block;
        overflow-y: auto;
        padding-bottom: 60px !important;
        padding-left: 20px !important;
        background-color: #fff; }
        .action-sheet.navigation .content .img-wrap {
          text-align: right; }
        .action-sheet.navigation .content a,
        .action-sheet.navigation .content .list {
          display: flex;
          align-items: center;
          padding: 12px 0;
          font-size: 14px;
          font-family: 'sukh-bold';
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #262626;
          width: 100%; }
          .action-sheet.navigation .content a .icon,
          .action-sheet.navigation .content .list .icon {
            min-width: 24px;
            max-width: 24px;
            height: 24px;
            margin-right: 12px;
            display: flex;
            align-items: center; }
            .action-sheet.navigation .content a .icon img,
            .action-sheet.navigation .content .list .icon img {
              max-width: 100%; }
          .action-sheet.navigation .content a.active,
          .action-sheet.navigation .content .list.active {
            color: #ff901d; }
          .action-sheet.navigation .content a:not(:last-child),
          .action-sheet.navigation .content .list:not(:last-child) {
            border-bottom: solid 0.5px rgba(176, 178, 198, 0.2); }
    .action-sheet.show {
      transform: translateY(0); }
      .action-sheet.show.no-translate {
        transform: translateY(0);
        top: 0;
        opacity: 1; }
    .action-sheet .blank-space {
      flex-grow: 1; }
    .action-sheet .img-wrap {
      padding: 24px; }
    .action-sheet .content {
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      padding: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0;
      max-width: 100%;
      padding: 0 !important; }
      .action-sheet .content .title-wrap {
        padding: 8px;
        border-bottom: 1px solid #f6f6f6; }
        .action-sheet .content .title-wrap p {
          font-size: 14px;
          font-family: 'sukh-medium';
          text-align: center;
          color: #4a4a4a;
          margin: 0; }
      .action-sheet .content .buttons-wrap {
        padding: 0 16px 16px 0;
        display: block; }
        .action-sheet .content .buttons-wrap button {
          display: block;
          width: 100%;
          padding: 12px;
          font-size: 14px;
          font-family: 'sukh-bold'; }
          .action-sheet .content .buttons-wrap button:not(:last-child) {
            margin-bottom: 8px; }
  .amount-summary-card .amount-payable {
    font-size: 14px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    padding: 0 24px;
    margin-bottom: 12px; }
  .amount-summary-card .card-wrap {
    box-shadow: none !important; }
    .amount-summary-card .card-wrap .card .section.three .text-one,
    .amount-summary-card .card-wrap .card .section.three .text-two {
      font-size: 14px; }
  .personal-accident {
    padding: 0 24px; }
    .personal-accident .img-wrap {
      display: block; }
      .personal-accident .img-wrap div:first-child {
        width: 100%;
        max-width: 100%; }
      .personal-accident .img-wrap div img {
        width: 100%; }
  .free-insurance-banner {
    max-width: calc(100% - 20px);
    margin: 12px auto; } }

.margin-right-24 {
  margin-right: 24px; }

.margin-0 {
  margin: 0 !important; }

.center-aligned {
  text-align: center; }

.right-aligned {
  text-align: right; }

.even-spaced-content {
  line-height: 1.5; }

.micro-text-base {
  font-size: 8px; }

.tiny-text-base {
  font-size: 10px; }

.small-text-base {
  font-size: 11px; }

.regular-text-base, .custom-title-regular, .regular-text-light {
  font-size: 12px; }

.medium-text-base, .custom-title-medium, .medium-text-light {
  font-size: 14px; }

.custom-title-base, .custom-title-medium, .custom-title-regular {
  font-family: 'sukh-bold';
  color: #000000;
  margin-bottom: 4px; }
  .custom-title-base.break, .break.custom-title-medium, .break.custom-title-regular {
    word-break: break-word; }

.light-text-base, .medium-text-light, .regular-text-light {
  color: #4a4a4a;
  font-family: 'sukh-medium'; }

.valign-top {
  vertical-align: top; }

.space-between-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .space-between-flex.sixty-wide {
    width: 60vw;
    max-width: 800px; }
  .space-between-flex .col {
    width: 32%;
    margin-bottom: 12px; }

.vertical-align-bottom {
  vertical-align: bottom; }

@media screen and (max-width: 768px) {
  .space-between-flex {
    display: block; }
    .space-between-flex .sixty-wide {
      width: 100%; }
    .space-between-flex .col {
      width: 100%; }
  .upload-file-banner {
    padding: 2.4rem;
    flex-direction: column; }
    .upload-file-banner > div:first-child {
      margin-right: 0;
      padding-top: 5rem;
      background: url(images/icons/coins.png) no-repeat;
      background-position: top center;
      background-size: 123px 70px; }
    .upload-file-banner > div:last-child {
      display: none; }
    .upload-file-banner > div .text-one {
      font-size: 20px;
      text-align: center; }
    .upload-file-banner > div .text-two {
      font-size: 16px;
      text-align: center; }
    .upload-file-banner > div button {
      font-size: 18px;
      padding: 0.8rem;
      width: 100%; }
  .dynamic-promotions {
    background: url(images/icons/coins-bg-mw.svg) no-repeat;
    background-position: center center;
    padding: 8px 16px;
    text-align: left;
    background-size: 100% 100%; }
    .dynamic-promotions .gift-wrap {
      display: none; }
    .dynamic-promotions.centered {
      padding: 8px 48px 8px 16px;
      background-size: 100% 100%; }
    .dynamic-promotions p .text-one {
      font-size: 18px;
      display: block; }
    .dynamic-promotions p .text-two {
      font-size: 14px; }
    .dynamic-promotions p .text-three {
      font-size: 20px; } }

.word-breaker {
  word-break: break-word; }

.word-no-breaker {
  word-break: normal; }

.no-border {
  border: none !important; }

.no-padding {
  padding: 0 !important; }

.inline-flex {
  display: inline-flex !important; }

.no-letter-spacing {
  letter-spacing: 0 !important; }

.chatwoot-payments-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #FFF;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  right: 0;
  bottom: 0; }

.login-wrap {
  width: 100%;
  max-width: 320px;
  padding-top: 120px;
  position: relative;
  margin: auto; }
  .login-wrap .button-wrapper {
    display: block;
    text-align: center; }
    .login-wrap .button-wrapper button {
      margin-bottom: 12px; }

#menu-appbar .language-switch,
#render-props-menu .language-switch,
#menu-policy_status .language-switch,
#menu-payment_status .language-switch {
  margin: 0; }

#menu-appbar ul li,
#render-props-menu ul li,
#menu-policy_status ul li,
#menu-payment_status ul li {
  font-size: 16px;
  text-transform: capitalize; }

#menu-appbar .list {
  padding: 20px;
  display: flex;
  align-items: center;
  cursor: pointer; }
  #menu-appbar .list .icon {
    display: none; }
  #menu-appbar .list .text {
    font-size: 16px;
    line-height: 1;
    font-stretch: normal;
    letter-spacing: 0.2px;
    text-align: left;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; }

.affiliate-form .address-wrapper .form-field .dropdown {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  outline: none;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  padding: 0.78571429em 2.1em 0.78571429em 0; }
  .affiliate-form .address-wrapper .form-field .dropdown .icon {
    border: 0; }

.affiliate-form .address-wrapper .form-field input {
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  cursor: text;
  display: inline-flex;
  font-size: 1rem;
  font-family: 'sukh-regular';
  line-height: 1.1875em;
  align-items: center;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  outline: none; }

.affiliate-form .key-value {
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 24px;
  display: table;
  width: 100%; }
  .affiliate-form .key-value span {
    display: table-cell;
    text-align: left; }
    .affiliate-form .key-value span.key {
      width: 180px; }
    .affiliate-form .key-value span.value {
      position: relative;
      padding-left: 32px; }
      .affiliate-form .key-value span.value:before {
        content: ':';
        position: absolute;
        left: 0; }

.affiliate-form .section-title {
  text-align: center;
  font-family: sukh-bold;
  margin-top: 48px; }

.inline-error {
  font-size: 12px;
  color: #d8001a;
  margin-top: 6px;
  display: inline-block;
  font-family: 'sukh-medium'; }

.inline-error {
  color: #f44336;
  font-size: 0.75rem; }

.admin-label {
  position: relative;
  margin-right: 12px; }
  .admin-label img {
    max-width: 60px; }

.header-flex-item {
  display: flex;
  align-items: center; }
  .header-flex-item > div > p img {
    margin-left: 4px; }
  .header-flex-item .line-icon {
    width: 24px;
    height: 24px;
    margin-left: 12px; }
  .header-flex-item .inline-wrap {
    display: flex;
    align-items: center;
    margin-right: 12px; }
    .header-flex-item .inline-wrap button {
      font-size: 12px;
      padding: 4px 8px; }

.mr-10 {
  margin-right: 10px !important; }

.layout-wrap {
  padding: 20px; }

.error-text {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  line-height: 1em; }
  .error-text.pull-up {
    margin-top: -5px; }

.misc-form {
  padding: 20px; }
  .misc-form .key-value {
    display: flex;
    align-items: center;
    margin-bottom: 12px; }
    .misc-form .key-value .key {
      margin-right: 12px;
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 1rem;
      font-family: 'sukh-regular';
      line-height: 1; }
    .misc-form .key-value .value .file-upload {
      padding: 0; }
      .misc-form .key-value .value .file-upload button {
        margin: 0; }

.custom-auto-complete {
  position: relative;
  display: block;
  width: 100%; }
  .custom-auto-complete > div {
    width: 100%; }
  .custom-auto-complete .list-wrap {
    max-height: 220px;
    overflow-y: auto;
    border: 1px solid #efefef;
    position: absolute;
    background: #fff;
    z-index: 1;
    width: 100%;
    top: 100%;
    z-index: 2; }
    .custom-auto-complete .list-wrap p {
      padding: 12px;
      cursor: pointer;
      font-size: 14px;
      color: #666;
      word-break: break-all; }
      .custom-auto-complete .list-wrap p:not(:last-child) {
        border-bottom: 1px solid #efefef; }

.action-wrap {
  margin: 16px auto 0 auto;
  text-align: center; }

.affiliates-wrapper {
  position: relative;
  padding: 20px; }
  .affiliates-wrapper .create-btn {
    position: absolute;
    right: 0;
    top: -12px; }

.title {
  font-size: 16px;
  margin: 0 12px 24px 12px; }

.list-item {
  background: #fff; }

.grid-item {
  padding: 0 8px !important; }

.password-strength {
  margin-top: 0px;
  width: 100%;
  background-color: #efefef;
  padding: 4px 8px;
  margin-left: 8px;
  margin-right: 8px; }
  .password-strength .message-wrap .text {
    font-size: 12px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #878787;
    margin: 0;
    text-align: left; }
  .password-strength .message-wrap ul {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 8px; }
    .password-strength .message-wrap ul li {
      margin-bottom: 6px;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333;
      vertical-align: top; }
      .password-strength .message-wrap ul li img {
        display: none; }
      .password-strength .message-wrap ul li.active {
        color: #09b109; }
        .password-strength .message-wrap ul li.active img {
          display: inline-block;
          margin-right: 4px;
          vertical-align: middle;
          max-width: 10px; }

.create-affiliate,
.modal-custom {
  z-index: 1200 !important; }

.manual-quote-wrap .full-width,
.cover-note .full-width {
  width: 100%; }

.manual-quote-wrap .form-label,
.cover-note .form-label {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  line-height: 1; }

.manual-quote-wrap .form-element-wrap,
.cover-note .form-element-wrap {
  margin-bottom: 16px; }
  .manual-quote-wrap .form-element-wrap > div,
  .cover-note .form-element-wrap > div {
    margin: 0; }

.manual-quote-wrap .button-wrap,
.cover-note .button-wrap {
  text-align: center; }

.manual-quote-wrap .filled-data,
.cover-note .filled-data {
  margin-bottom: 16px; }

.manual-quote-wrap .mt-16,
.cover-note .mt-16 {
  margin-top: 16px; }

.affiliate-wrapper .full-width {
  width: 100%; }

.loader-wrap {
  text-align: center;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  background: #fff;
  padding: 24px;
  margin: 24px 0; }

.custom-table-wrapper .loader-wrap,
.crm-table-wrapper .loader-wrap {
  box-shadow: none; }

.custom-table-wrapper .table-headers,
.crm-table-wrapper .table-headers {
  font-size: 16px;
  font-family: 'sukh-bold';
  font-style: normal;
  font-stretch: normal;
  line-height: 1.58;
  letter-spacing: normal;
  color: #000000; }

.custom-table-wrapper .insurer-email,
.crm-table-wrapper .insurer-email {
  font-size: 15px;
  font-family: 'sukh';
  font-style: normal;
  font-stretch: normal;
  line-height: 1.58;
  letter-spacing: normal;
  color: #000000;
  flex-grow: 1; }
  .custom-table-wrapper .insurer-email .edit-icon,
  .crm-table-wrapper .insurer-email .edit-icon {
    padding-left: 100px;
    justify-content: space-between;
    float: right; }

.affiliate-quotes .fairdee-modal-wrapper {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 1; }
  .affiliate-quotes .fairdee-modal-wrapper.make-model .header-wrap {
    display: flex;
    align-items: center;
    padding: 16px 72px; }
    .affiliate-quotes .fairdee-modal-wrapper.make-model .header-wrap img {
      max-width: 180px;
      margin-right: 20px; }
    .affiliate-quotes .fairdee-modal-wrapper.make-model .header-wrap p {
      font-size: 23px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.58;
      letter-spacing: normal;
      color: #000000;
      flex-grow: 1; }
  .affiliate-quotes .fairdee-modal-wrapper .modal-content {
    margin: 0 auto;
    padding: 0; }
  .affiliate-quotes .fairdee-modal-wrapper .close {
    display: none; }

.buy-insurance-wrapper {
  text-align: center; }
  .buy-insurance-wrapper .grid {
    background: #fff;
    display: inline-block;
    max-width: 335px;
    min-width: 300px;
    width: 100%;
    margin: 15px;
    border-radius: 6px;
    box-shadow: 1px 4px 6px 0 #e2e1e1;
    padding-bottom: 24px; }
    .buy-insurance-wrapper .grid .title {
      font-size: 16px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      margin-bottom: 12px;
      padding: 28px 28px 0 28px; }
    .buy-insurance-wrapper .grid .text {
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      margin-bottom: 20px;
      padding: 0 28px; }
    .buy-insurance-wrapper .grid .line {
      border-bottom: 1px solid rgba(151, 151, 151, 0.1);
      margin-bottom: 24px; }

.disabled {
  pointer-events: none;
  opacity: 0.4; }

.sales-track-wrap {
  height: 100%;
  position: relative; }
  .sales-track-wrap.policy-wrap .sale-track-stepper-content {
    height: calc(100% - 80px); }
    .sales-track-wrap.policy-wrap .sale-track-stepper-content .tabs {
      margin-bottom: 24px; }
  .sales-track-wrap .sale-track-stepper-content {
    padding: 8px 0 36px 0;
    height: calc(100% - 120px);
    overflow-y: auto;
    margin-top: 12px; }
    .sales-track-wrap .sale-track-stepper-content .user-form {
      height: 100%; }
    .sales-track-wrap .sale-track-stepper-content .text {
      margin-bottom: 12px; }
      .sales-track-wrap .sale-track-stepper-content .text span {
        font-family: 'sukh-bold'; }
    .sales-track-wrap .sale-track-stepper-content .label-text {
      font-weight: bold; }
    .sales-track-wrap .sale-track-stepper-content .form-field {
      width: 100%; }
      .sales-track-wrap .sale-track-stepper-content .form-field .form-label {
        display: inline-block;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        position: relative;
        z-index: 2;
        top: 8px; }
    .sales-track-wrap .sale-track-stepper-content .ui.selection.dropdown {
      border: 0;
      padding: 0;
      border-radius: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      box-shadow: none;
      width: 100%;
      max-width: 100%;
      margin: 0;
      margin-bottom: 24px;
      background: transparent; }
      .sales-track-wrap .sale-track-stepper-content .ui.selection.dropdown input {
        padding-left: 0; }
      .sales-track-wrap .sale-track-stepper-content .ui.selection.dropdown:hover {
        border-bottom: 2px solid rgba(0, 0, 0, 0.87); }
      .sales-track-wrap .sale-track-stepper-content .ui.selection.dropdown .text {
        margin: 0;
        color: rgba(0, 0, 0, 0.87);
        margin-top: 18px; }
        .sales-track-wrap .sale-track-stepper-content .ui.selection.dropdown .text.default {
          display: none; }
      .sales-track-wrap .sale-track-stepper-content .ui.selection.dropdown.active .menu {
        border: 1px solid rgba(0, 0, 0, 0.42); }
  .sales-track-wrap .buttons-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding-top: 12px;
    display: flex; }
    .sales-track-wrap .buttons-wrap .mr-24 {
      margin-right: 24px; }
    .sales-track-wrap .buttons-wrap div {
      text-align: left; }
      .sales-track-wrap .buttons-wrap div:last-child {
        text-align: right;
        flex-grow: 1; }
    .sales-track-wrap .buttons-wrap.right {
      justify-content: flex-end; }

.cover-note-wrap .calendar-wrap {
  width: 100%;
  margin-top: 12px; }
  .cover-note-wrap .calendar-wrap > div {
    width: 100%; }

.increment-decrement {
  width: 100%; }
  .increment-decrement .label {
    text-align: center;
    font-size: 14px;
    margin: 4px 0; }

.increment-decrement-wrapper {
  display: flex;
  align-items: center; }
  .increment-decrement-wrapper button {
    padding: 6px 16px;
    border-radius: 3px 0 0 3px;
    min-width: 32px;
    font-size: 18px;
    color: #fff;
    outline: none; }
    .increment-decrement-wrapper button:hover {
      background: rgba(245, 139, 30, 0.31); }
    .increment-decrement-wrapper button:first-child {
      border: solid 1px #d8001a;
      background-color: #d8001a; }
    .increment-decrement-wrapper button:last-child {
      border-radius: 3px;
      border: solid 1px #589707;
      background-color: #589707;
      border-radius: 0 3px 3px 0; }
  .increment-decrement-wrapper input[type='text'] {
    text-align: center !important;
    border-top: solid 1px #d7d7d7 !important;
    border-bottom: solid 1px #d7d7d7 !important;
    flex-grow: 1;
    min-width: auto !important;
    width: 100% !important; }
  .increment-decrement-wrapper > div {
    flex-grow: 1; }
    .increment-decrement-wrapper > div > div:before, .increment-decrement-wrapper > div > div:after {
      display: none; }

.default-item {
  background: #efefef; }

.app-frame header {
  padding: 0 16px; }
  .app-frame header .logo-anchor {
    display: flex;
    position: relative;
    align-items: center; }
    .app-frame header .logo-anchor > span {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #5c5c5c;
      border-left: solid 1px #e2e2e2;
      padding-left: 0;
      margin-left: 20px; }
    .app-frame header .logo-anchor a {
      display: flex;
      align-items: center;
      padding-right: 24px; }
      .app-frame header .logo-anchor a img {
        max-height: 3rem; }
    .app-frame header .logo-anchor .phone {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #5c5c5c;
      padding-left: 48px;
      background: url(images/phone.svg) no-repeat;
      background-position: 12px center; }
    .app-frame header .logo-anchor .links {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #5c5c5c; }
    .app-frame header .logo-anchor .flex-content-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
      margin-right: 12px; }
    .app-frame header .logo-anchor button.blue {
      font-size: 16px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      border-radius: 4px;
      background-color: #192383;
      padding: 5px 24px;
      cursor: pointer;
      outline: none;
      border: 0;
      position: relative;
      padding-left: 40px; }
      .app-frame header .logo-anchor button.blue:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        background: url(images/new-landing/add-icon.svg) no-repeat;
        background-position: center center;
        background-size: contain;
        left: 12px; }
      .app-frame header .logo-anchor button.blue img {
        display: none; }
  .app-frame header .logo-image {
    max-height: 25px; }

.react-select {
  margin-bottom: 20px; }
  .react-select legend {
    font-size: 12px; }
  .react-select > div {
    margin-bottom: 0; }
  .react-select.error > div > div {
    border: 1px solid #f44336; }

.full-width {
  width: 100%; }

.nudge-card-mobile {
  background-color: #1e4d94;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 2px 0; }
  .nudge-card-mobile .text-wrap {
    background: url(images/rectangle-text-wrap.svg) no-repeat;
    padding: 5px 30px 5px 8px;
    text-align: left; }
    .nudge-card-mobile .text-wrap .text-one {
      font-size: 12px;
      font-family: 'sukh-medium';
      color: #ffffff;
      margin-bottom: 2px;
      line-height: 1.5; }
      .nudge-card-mobile .text-wrap .text-one span {
        color: #f58b1e; }
    .nudge-card-mobile .text-wrap .text-two {
      font-size: 8px;
      color: #ffffff;
      line-height: 1.2; }
      .nudge-card-mobile .text-wrap .text-two span {
        font-family: 'sukh-bold';
        color: #f58b1e; }
  .nudge-card-mobile .img-wrap {
    text-align: right;
    flex-grow: 1;
    padding-right: 25px; }
    .nudge-card-mobile .img-wrap img {
      width: 100%;
      max-width: 75px; }

.nudge-card {
  padding: 32px 28px;
  background: url(images/blurred-card.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .nudge-card img {
    max-width: 33px;
    margin-bottom: 12px; }
  .nudge-card .text {
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a; }
    .nudge-card .text span {
      font-family: 'sukh-bold';
      color: #f58b1e;
      text-decoration: underline;
      cursor: pointer; }

.copy-link-wrap {
  display: flex;
  margin-top: 12px; }
  .copy-link-wrap p {
    flex-grow: 1;
    margin-right: 8px;
    font-size: 12px; }

.generate-email-wrap .title-text {
  font-size: 12px;
  color: #333;
  margin: 12px 0 0; }

.insurer-email-layout {
  padding: 20px; }
  .insurer-email-layout .form-field {
    margin-bottom: 20px;
    width: 100%;
    display: block; }
    .insurer-email-layout .form-field .field {
      width: 100%; }

.leave-form,
.team-form {
  padding: 20px; }
  .leave-form input[type="text"],
  .leave-form input[type="email"],
  .leave-form input[type="password"],
  .leave-form input[type="number"],
  .leave-form textarea,
  .team-form input[type="text"],
  .team-form input[type="email"],
  .team-form input[type="password"],
  .team-form input[type="number"],
  .team-form textarea {
    width: 230px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #192383;
    padding: 7px 13px;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
    font-family: 'sukh-medium', sans-serif;
    line-height: 1.5;
    width: 100%; }
    .leave-form input[type="text"]:focus,
    .leave-form input[type="email"]:focus,
    .leave-form input[type="password"]:focus,
    .leave-form input[type="number"]:focus,
    .leave-form textarea:focus,
    .team-form input[type="text"]:focus,
    .team-form input[type="email"]:focus,
    .team-form input[type="password"]:focus,
    .team-form input[type="number"]:focus,
    .team-form textarea:focus {
      border-radius: 2px;
      border: solid 1px #192383; }
  .leave-form textarea,
  .team-form textarea {
    resize: none;
    height: 100px;
    border-radius: 15px; }
    .leave-form textarea:focus,
    .team-form textarea:focus {
      border-radius: 15px; }
  .leave-form .form-element,
  .team-form .form-element {
    display: block;
    margin-bottom: 20px; }
    .leave-form .form-element .inline-error,
    .team-form .form-element .inline-error {
      margin: 4px 0 0; }
    .leave-form .form-element .label,
    .team-form .form-element .label {
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 12px;
      font-family: 'sukh-regular';
      line-height: 1;
      display: block;
      margin-bottom: 4px; }
    .leave-form .form-element .selection.dropdown,
    .team-form .form-element .selection.dropdown {
      width: 100%;
      display: block;
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      padding: 8px 0; }
      .leave-form .form-element .selection.dropdown input.search,
      .team-form .form-element .selection.dropdown input.search {
        padding: 0;
        height: 100%; }
      .leave-form .form-element .selection.dropdown .icon,
      .team-form .form-element .selection.dropdown .icon {
        border: 0; }
    .leave-form .form-element .datepicker,
    .team-form .form-element .datepicker {
      width: 100%; }

.leave-table {
  padding: 12px; }
  .leave-table .action-wrap {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 20px; }

.confirmation-component {
  padding: 20px; }
  .confirmation-component .title-text {
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1b1f88; }
  .confirmation-component .buttons-wrap {
    margin-top: 60px;
    display: flex;
    vertical-align: middle; }
    .confirmation-component .buttons-wrap button {
      flex-grow: 1;
      flex-basis: 100%;
      margin: 0 12px; }

.loader-backdrop {
  background: rgba(77, 92, 116, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .loader-backdrop .circular-loader {
    justify-content: center;
    text-align: center;
    padding: 24px 28px;
    width: 460px;
    background-color: #fff;
    position: relative; }
    .loader-backdrop .circular-loader .loading-icon {
      margin-bottom: 24px; }
      .loader-backdrop .circular-loader .loading-icon .anticon-loading {
        font-size: 60px;
        color: #f58b1e; }
    .loader-backdrop .circular-loader .title {
      padding-top: 10px;
      font-size: 24px;
      font-weight: 600;
      line-height: 38px;
      color: #1253a4;
      margin: 0 0 12px 0; }
    .loader-backdrop .circular-loader .info-text {
      font-weight: 500;
      font-size: 18px;
      line-height: 31px;
      color: #6d6e71; }

@media screen and (max-width: 1024px) {
  .app-frame header .logo-anchor {
    padding: 0 12px 0;
    width: 100%; }
    .app-frame header .logo-anchor .label {
      border-radius: 2px;
      font-size: 12px;
      font-family: 'sukh-bold';
      line-height: normal;
      padding: 4px 12px;
      display: inline-block;
      text-align: center;
      text-transform: capitalize;
      border: 1px solid #192383;
      color: #192383; }
    .app-frame header .logo-anchor .phone {
      font-size: 16px;
      min-height: 18px;
      display: inline-block; }
      .app-frame header .logo-anchor .phone span {
        display: none; }
    .app-frame header .logo-anchor button.blue {
      display: none; }
    .app-frame header .logo-anchor > span {
      display: none; }
    .app-frame header .logo-anchor .flex-content-wrap .buy-insurance-action {
      display: none; }
    .app-frame header .logo-anchor a.profile {
      padding-right: 0;
      color: #5c5c5c;
      font-size: 16px; }
      .app-frame header .logo-anchor a.profile img {
        max-width: 22px;
        margin-right: 4px; }
  .logo-image {
    max-height: 25px; }
  .affiliate-quotes .fairdee-modal-wrapper .modal-content {
    padding: 24px;
    min-height: calc(var(--vh) - 60px); }
    .affiliate-quotes .fairdee-modal-wrapper .modal-content .ui.form {
      max-width: 100%; }
    .affiliate-quotes .fairdee-modal-wrapper .modal-content .make-modal-wrap {
      padding: 0;
      margin: 0; }
  .affiliate-quotes .fairdee-modal-wrapper.make-model .header-wrap {
    display: block;
    padding: 0;
    margin-bottom: 32px; }
    .affiliate-quotes .fairdee-modal-wrapper.make-model .header-wrap p {
      font-size: 16px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin: 0;
      margin-bottom: 24px; }
  .policy-tab span {
    font-size: 10px; }
  .admin-label {
    margin-right: 0; }
    .admin-label img {
      max-width: 48px; }
  .create-affiliate > div {
    min-width: auto !important; }
  .create-affiliate .affiliate-form,
  .create-affiliate .stepper {
    width: 620px; }
  .create-affiliate .stepper {
    padding: 24px; }
  .generate-email-wrap .title-text {
    font-size: 12px;
    color: #333;
    margin: 12px 0 0; }
  .generate-email-wrap .button-wrap {
    margin-top: 12px !important;
    position: relative !important;
    z-index: 0; }
  .loader-wrap {
    background: transparent;
    box-shadow: none; } }

.manage-staffs {
  position: relative; }
  .manage-staffs .action-wrap {
    text-align: right;
    margin-bottom: 24px; }
    .manage-staffs .action-wrap button:not(:last-child) {
      margin-right: 16px; }
  .manage-staffs .fab {
    margin: 0px;
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: fixed; }
  .manage-staffs .section {
    margin-bottom: 40px; }
    .manage-staffs .section .title {
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #1b1b1b;
      margin: 0 0 20px 0;
      text-transform: capitalize; }
    .manage-staffs .section .staffs-wrap {
      display: flex;
      flex-wrap: wrap; }
      .manage-staffs .section .staffs-wrap .staff {
        background: #fff;
        padding: 12px;
        position: relative;
        flex-basis: calc(25% - 18px);
        margin-bottom: 24px; }
        .manage-staffs .section .staffs-wrap .staff:not(:last-child) {
          margin-right: 24px; }
        .manage-staffs .section .staffs-wrap .staff.default {
          background: #cdcdcd; }
        .manage-staffs .section .staffs-wrap .staff .text {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #5e5e5e;
          padding-right: 60px; }
        .manage-staffs .section .staffs-wrap .staff .cancel {
          position: absolute;
          right: 12px;
          cursor: pointer;
          top: calc(50% - 10px); }
        .manage-staffs .section .staffs-wrap .staff .set-default {
          position: absolute;
          right: 40px;
          cursor: pointer;
          top: calc(50% - 10px); }

.full-width {
  width: 100%; }
  .full-width label {
    z-index: 1; }

.auto-complete {
  width: 100%;
  margin-top: 16px; }
  .auto-complete:not(:last-child) {
    margin: 16px 0 32px 0; }
  .auto-complete.no-margin {
    margin-bottom: 0; }
  .auto-complete.error > div:before, .auto-complete.error > div:after {
    border-bottom: 2px solid red; }
  .auto-complete.error:hover > div:before, .auto-complete.error:hover > div:after {
    border-bottom: 2px solid red; }
  .auto-complete > div {
    width: 100%;
    position: relative;
    font-size: 1rem;
    line-height: 1.1875em; }
    .auto-complete > div:hover:before, .auto-complete > div:hover:after, .auto-complete > div:focus:before, .auto-complete > div:focus:after {
      border-bottom: 2px solid rgba(0, 0, 0, 0.87); }
    .auto-complete > div:before {
      left: 0;
      right: 0;
      bottom: 0;
      content: "\00a0";
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      pointer-events: none; }
    .auto-complete > div:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid #123446;
      pointer-events: none; }
    .auto-complete > div input {
      border: 0;
      margin: 0;
      padding: 6px 0 7px;
      display: block;
      min-width: 0;
      flex-grow: 1;
      box-sizing: content-box;
      background: none;
      -webkit-tap-highlight-color: transparent;
      width: 100%;
      outline: none;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87); }
  .auto-complete div input + div {
    margin-top: 2px;
    background: #fff !important;
    z-index: 1; }
    .auto-complete div input + div div {
      color: rgba(0, 0, 0, 0.87);
      overflow: hidden;
      font-size: 16px;
      box-sizing: content-box;
      font-family: 'sukh-light';
      line-height: 1.5em;
      white-space: nowrap;
      padding: 12px;
      text-overflow: ellipsis; }

@media screen and (max-width: 1024px) {
  .manage-staffs .section .staffs-wrap {
    display: block; }
    .manage-staffs .section .staffs-wrap .staff {
      display: block;
      width: 100%; } }

* html .clearfix {
  height: 1%; }

html {
  /* Optional: show position indicator in red */ }
  html::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */ }
  html::-webkit-scrollbar-thumb {
    background: #fff; }
  html.fixed {
    overflow: hidden; }
    html.fixed body {
      overflow: hidden; }

body {
  color: #282c3f;
  font-family: 'sukh-regular'; }
  body[lang='thai'] {
    font-family: 'sukh-regular'; }
  body .animated {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s; }

.root-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%; }
  .root-wrapper.mlm .mobile-modal-wrapper {
    background-color: #fff;
    z-index: 9;
    position: absolute;
    padding-top: 120px;
    overflow-y: auto;
    align-items: flex-start; }
    .root-wrapper.mlm .mobile-modal-wrapper .fairdee-modal {
      background-color: transparent; }
      .root-wrapper.mlm .mobile-modal-wrapper .fairdee-modal .modal-wrapper {
        box-shadow: 0px 0px 6px #d8e7fd; }
    .root-wrapper.mlm .mobile-modal-wrapper .mobile-layout {
      box-shadow: 0px 0px 6px #d8e7fd; }
  .root-wrapper.non-logged-in .routes-wrap {
    width: 100%; }
  .root-wrapper.logged-in .routes-wrap {
    max-width: calc(100% - 60px); }
  .root-wrapper.logged-in.affiliate .custom-mobile-table.affiliate-referrals {
    min-height: var(--vh);
    background: #f6f9ff; }
  .root-wrapper.logged-in.affiliate .custom-mobile-table {
    background: #f6f9ff;
    min-height: var(--vh);
    padding-top: 70px; }
  .root-wrapper.logged-in.affiliate .routes-wrap {
    max-width: 100%;
    padding-top: 55px;
    min-height: auto; }
  .root-wrapper.logged-in.affiliate .referral-layout {
    background: #f6f9ff;
    padding: 8px;
    min-height: var(--vh);
    padding-bottom: 160px;
    position: relative;
    max-width: 480px;
    margin: 0 auto; }
    .root-wrapper.logged-in.affiliate .referral-layout.referral-list {
      background: #f6f9ff;
      padding-top: 12px; }
    .root-wrapper.logged-in.affiliate .referral-layout .list {
      border-radius: 2px;
      border: solid 1px #efefef;
      background: #fff url(images/states/arrow-right.svg) no-repeat;
      background-position: calc(100% - 46px) center;
      padding: 24px 46px 24px 24px;
      margin: 0 0 4px 0;
      box-shadow: none;
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 14px;
      color: #707070;
      cursor: pointer; }
    .root-wrapper.logged-in.affiliate .referral-layout.no-padding {
      padding-top: 0; }
    .root-wrapper.logged-in.affiliate .referral-layout .mobile-dash-card .card {
      padding: 0;
      margin: 0 0 12px 0; }
  .root-wrapper.logged-in.affiliate .referral-dashboard {
    padding: 0; }
    .root-wrapper.logged-in.affiliate .referral-dashboard .home-page {
      padding: 0 12px 12px; }
  .root-wrapper.logged-in.static .routes-wrap {
    max-width: 100%; }
  .root-wrapper.static .routes-wrap {
    padding-top: 100px; }

.container {
  padding: 0 65px;
  margin: 0 auto;
  width: 100% !important; }

.routes-wrap {
  flex-grow: 1;
  align-self: flex-start;
  padding: 64px 0px 0;
  width: 100%;
  min-height: 110vh; }

.compulsory-insurance-form .vehicle-ctegory-wrapper {
  padding: 20px; }
  .compulsory-insurance-form .vehicle-ctegory-wrapper .text-field {
    min-width: 320px; }

.compulsory-insurance-form .inline-wrap {
  display: inline-block;
  vertical-align: top; }
  .compulsory-insurance-form .inline-wrap .inline-error {
    display: block;
    margin-left: 8px; }

.compulsory-insurance-form .date-error {
  border-bottom: 2px solid #f44336; }

.compulsory-insurance-form .vehicle-number-wrap {
  padding: 20px;
  display: flex;
  align-items: flex-end; }
  .compulsory-insurance-form .vehicle-number-wrap > div div {
    width: 320px;
    margin-right: 20px; }

.compulsory-insurance-form .insurer-select {
  padding: 0 20px 20px; }
  .compulsory-insurance-form .insurer-select p {
    margin-bottom: 12px; }
  .compulsory-insurance-form .insurer-select .insurer-card {
    background: #fff;
    box-shadow: 0px 1px 6px rgba(216, 231, 253, 0.5);
    border-radius: 8px;
    width: 130px;
    height: 130px;
    margin: 0 16px 16px 0;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    cursor: pointer; }
    .compulsory-insurance-form .insurer-select .insurer-card.selected {
      border: 1px solid #f58b1e; }
  .compulsory-insurance-form .insurer-select .img-wrap {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .compulsory-insurance-form .insurer-select .img-wrap .img {
      max-width: 100%;
      max-height: 100%; }
  .compulsory-insurance-form .insurer-select .name {
    font-display: 'sukh-bold';
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000;
    margin-top: 4px; }

.compulsory-insurance-form .form-wrapper > div {
  margin-bottom: 12px;
  position: relative; }

.compulsory-insurance-form .form-wrapper .dropdown-label {
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  top: -12px;
  left: 0;
  position: absolute;
  color: rgba(0, 0, 0, 0.54);
  z-index: 1; }

.compulsory-insurance-form .premium-grid {
  margin: auto;
  max-width: 200px; }

.compulsory-insurance-form .warning-cmi-premium {
  max-width: 300px;
  background: #fffef2;
  padding: 15px 10px;
  font-size: 14px; }
  .compulsory-insurance-form .warning-cmi-premium .warning-heading {
    margin-bottom: 5px;
    align-items: center;
    display: flex; }
  .compulsory-insurance-form .warning-cmi-premium .warning-heading-text {
    color: #1253a4;
    font-weight: 700;
    margin-left: 5px; }
  .compulsory-insurance-form .warning-cmi-premium .warning-text {
    padding: 2px 5px 2px 15px; }

.non-motor-layout {
  padding: 24px;
  max-width: 600px;
  margin: auto; }
  .non-motor-layout .NonStandard-body-71 {
    padding: 0; }
  .non-motor-layout .button-wrap .button {
    width: 100%; }
    .non-motor-layout .button-wrap .button:hover {
      background-color: #ff6400; }

@media screen and (max-width: 1200px) {
  .container {
    padding: 0 40px; } }

@media screen and (max-width: 1024px) {
  .container {
    padding: 0 20px; }
  .routes-wrap {
    padding: 0;
    width: 100%;
    position: relative;
    min-height: auto; }
  .root-wrapper {
    padding: 54px 0 0; }
    .root-wrapper.mlm .mobile-modal-wrapper {
      top: 0;
      z-index: 99999; }
    .root-wrapper.ios-wv {
      padding-top: 98px; }
      .root-wrapper.ios-wv.logged-in {
        padding: 98px 0; }
        .root-wrapper.ios-wv.logged-in.no-bottom-nav {
          padding-bottom: 0; }
      .root-wrapper.ios-wv.non-logged-in .routes-wrap {
        padding-top: 0; }
      .root-wrapper.ios-wv .landing-header {
        padding-top: 48px; }
      .root-wrapper.ios-wv .action-sheet.navigation .content .img-wrap {
        margin-top: 48px; }
        .root-wrapper.ios-wv .action-sheet.navigation .content .img-wrap img {
          min-width: 20px; }
      .root-wrapper.ios-wv .fairdee-modal-wrapper.make-model .mobile-header {
        padding-top: 48px; }
      .root-wrapper.ios-wv .fairdee-modal-wrapper.login-signup .modal-content .mobile-header .current-page {
        top: 48px; }
      .root-wrapper.ios-wv .fairdee-modal-wrapper .modal-content {
        padding-top: 120px; }
        .root-wrapper.ios-wv .fairdee-modal-wrapper .modal-content .mobile-header {
          padding-top: 48px;
          margin-bottom: 0;
          display: flex; }
          .root-wrapper.ios-wv .fairdee-modal-wrapper .modal-content .mobile-header .current-page {
            padding: 0; }
            .root-wrapper.ios-wv .fairdee-modal-wrapper .modal-content .mobile-header .current-page img {
              top: 0;
              position: relative;
              margin: 0;
              margin-right: 12px;
              min-width: 20px; }
        .root-wrapper.ios-wv .fairdee-modal-wrapper .modal-content .buttons-wrap button {
          padding-bottom: 40px; }
      .root-wrapper.ios-wv .fairdee-form-wrapper.fairdee-wizard .button-wrap button {
        padding-bottom: 40px; }
      .root-wrapper.ios-wv .mobile-bottom-nav {
        padding-bottom: 32px;
        min-height: 76px;
        max-height: 76px; }
      .root-wrapper.ios-wv .landing-header {
        box-shadow: none; }
      .root-wrapper.ios-wv .fairdee-modal-wrapper.corona-cards .prev-next {
        padding-bottom: 40px; }
      .root-wrapper.ios-wv .routes-wrap .referral-dashboard.mobile .fixed-header {
        padding: 48px 20px 8px 20px; }
      .root-wrapper.ios-wv .routes-wrap .referral-dashboard.mobile.fixed .mobile-modal {
        padding: 152px 20px 20px; }
        .root-wrapper.ios-wv .routes-wrap .referral-dashboard.mobile.fixed .mobile-modal.no-search {
          padding: 64px 20px 20px; }
      .root-wrapper.ios-wv .routes-wrap .custom-mobile-table.with-tab .search-input-filter {
        top: 68px; }
      .root-wrapper.ios-wv .routes-wrap .referral-dashboard.mobile .fixed-header img {
        min-width: 20px; }
      .root-wrapper.ios-wv .routes-wrap .make-model .make-modal-wrap.with-margin {
        margin-top: 60px; }
      .root-wrapper.ios-wv .routes-wrap .make-model .make-modal-wrap .ui.form .button-wrap button {
        padding-bottom: 32px;
        border-radius: 0; }
      .root-wrapper.ios-wv .filter-wrap.show .mobile-header.show {
        padding-top: 42px;
        display: block;
        z-index: 1001; }
      .root-wrapper.ios-wv .filter-wrap .mobile-header {
        display: none; }
      .root-wrapper.ios-wv .mobile-layout .content-wrapper {
        padding-top: 90px; }
      .root-wrapper.ios-wv .mobile-layout .header {
        padding-top: 60px; }
      .root-wrapper.ios-wv .mobile-layout .bottom-section {
        padding-bottom: 40px; }
      .root-wrapper.ios-wv .profile-layout .header-section {
        padding-top: 60px; }
      .root-wrapper.ios-wv .profile-layout .personal-details {
        padding: 100px 0 100px 0; }
      .root-wrapper.ios-wv .profile-layout .footer-section {
        padding-bottom: 40px; }
      .root-wrapper.ios-wv .referral-dashboard.tutorials .back-arrow {
        padding-top: 50px; }
      .root-wrapper.ios-wv .x-plus-header {
        padding-top: 40px; }
      .root-wrapper.ios-wv .header-section {
        padding-top: 60px; }
      .root-wrapper.ios-wv .make-model-header {
        padding-top: 70px; }
      .root-wrapper.ios-wv .bottom-navigation {
        padding-bottom: 40px; }
      .root-wrapper.ios-wv .policies-listing-layout {
        padding-top: 100px; }
      .root-wrapper.ios-wv .dashboard-layout,
      .root-wrapper.ios-wv .profile-layout,
      .root-wrapper.ios-wv .policies-layout,
      .root-wrapper.ios-wv .account-layout,
      .root-wrapper.ios-wv .tutorials,
      .root-wrapper.ios-wv .teams-layout,
      .root-wrapper.ios-wv .referral-layout {
        min-height: calc(var(--vh) - 200px);
        padding-bottom: 12px; }
      .root-wrapper.ios-wv.logged-in.affiliate .referral-layout {
        min-height: calc(var(--vh) - 200px);
        padding-bottom: 12px; }
      .root-wrapper.ios-wv .fd-connect {
        bottom: 100px; }
    .root-wrapper.homepage {
      min-height: var(--vh);
      padding-bottom: 0; }
      .root-wrapper.homepage .routes-wrap {
        min-height: 100%;
        max-height: initial;
        height: auto; }
      .root-wrapper.homepage .landing-header {
        position: fixed; }
    .root-wrapper.logged-in .routes-wrap {
      max-width: 100%; }
    .root-wrapper.logged-in.no-bottom-nav {
      padding-bottom: 0; }
    .root-wrapper.logged-in.affiliate .routes-wrap {
      max-width: 100%;
      padding-top: 0; }
    .root-wrapper.logged-in.affiliate .referral-layout {
      padding-top: 60px; }
      .root-wrapper.logged-in.affiliate .referral-layout.no-padding {
        padding-top: 60px; }
    .root-wrapper.static .routes-wrap {
      padding-top: 0; }
  body ::-webkit-scrollbar {
    width: 0px;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */ } }

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available; } }

.animated {
  animation-duration: 1s; }

.animated.infinite {
  animation-iteration-count: infinite; }

.animated.hinge {
  animation-duration: 2s; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@font-face {
  font-family: 'sukh-light';
  src: url("./font/SukhumvitSet-Light.ttf");
  font-display: swap; }

@font-face {
  font-family: 'sukh-regular';
  src: url("./font/SukhumvitSet-Text.ttf");
  font-display: swap; }

@font-face {
  font-family: 'sukh-bold';
  src: url("./font/SukhumvitSet-Bold.ttf");
  font-display: swap; }

@font-face {
  font-family: 'sukh-medium';
  src: url("./font/SukhumvitSet-Medium.ttf");
  font-display: swap; }

@font-face {
  font-family: 'brusher';
  src: url("./font/brusher.ttf") format("truetype"), url("./font/brusher.otf");
  font-display: swap; }

@font-face {
  font-family: 'lato-light';
  src: url("./font/lato/Lato-Light.ttf");
  font-display: swap; }

@font-face {
  font-family: 'lato-regular';
  src: url("./font/lato/Lato-Regular.ttf");
  font-display: swap; }

@font-face {
  font-family: 'lato-bold';
  src: url("./font/lato/Lato-Bold.ttf");
  font-display: swap; }

@font-face {
  font-family: 'lato-semi-bold';
  src: url("./font/lato/Lato-Semibold.ttf");
  font-display: swap; }

@font-face {
  font-family: 'lato-medium';
  src: url("./font/lato/Lato-Medium.ttf");
  font-display: swap; }

.button {
  border-radius: 2px;
  font-family: 'sukh-regular', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  display: inline-block;
  border: 0; }
  .button.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .button.primary {
    background-color: #ff901d;
    color: #fff;
    padding: 10px; }
  .button.medium {
    background-color: #ff901d;
    font-size: 12px;
    text-transform: uppercase;
    color: #f9f9fe;
    padding: 10px 20px; }
  .button.orange-bordered-button {
    background-color: #fff;
    border: solid 0.8px #f58b1e;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: #4a4a4a;
    background: #fff;
    align-items: center; }
  .button.black-bordered-button {
    background-color: #fff;
    border: solid 0.8px #282c3f;
    letter-spacing: 0.6px;
    color: #4a4a4a; }
  .button.white {
    background: #fff;
    color: #f58b1e; }
  .button.light-bordered-button {
    background-color: #fff;
    border: solid 1px rgba(155, 155, 155, 0.3);
    letter-spacing: 0.6px;
    color: #192383; }
  .button.green {
    background: #69aa15;
    color: #fff; }
  .button.blue {
    background: #4b93e7;
    color: #fff; }
  .button.dark-blue {
    background: #192383;
    color: #fff; }
  .button.red {
    background: #e15948;
    color: #fff; }
  .button.google {
    background-color: #3d82f4;
    border: solid 1px #3d82f4;
    font-family: 'sukh-light', sans-serif;
    font-size: 14px;
    color: #fff;
    padding: 6px 20px;
    width: 100%;
    text-transform: none;
    padding-left: 35px;
    text-align: left; }
    .button.google img {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 12px; }
  .button.facebook {
    background-color: #192383;
    border: solid 1px #192383;
    font-family: 'sukh-light', sans-serif;
    font-size: 14px;
    color: #fff;
    padding: 8px 20px;
    width: 100%;
    text-transform: none;
    padding-left: 35px;
    text-align: left; }
    .button.facebook img {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 12px; }
  .button.line {
    background-color: #2fd000;
    border: solid 1px #2fd000;
    font-family: 'sukh-light', sans-serif;
    font-size: 14px;
    color: #fff;
    padding: 8px 20px;
    width: 100%;
    text-transform: none;
    padding-left: 35px;
    text-align: left; }
    .button.line img {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 12px; }
  .button.line-btn {
    background: #00c300;
    color: #ffffff;
    font-family: 'sukh-bold';
    text-align: center;
    display: flex;
    padding: 0 40px 0 0;
    align-items: center;
    margin: 6px 0;
    font-size: 11px;
    text-transform: none; }
    .button.line-btn span {
      background: url(images/line/btn_base.png) no-repeat;
      background-size: contain;
      display: inline-block;
      width: 38px;
      height: 38px;
      margin-right: 40px; }
    .button.line-btn:hover {
      background: #00e000; }
      .button.line-btn:hover span {
        background: url(images/line/btn_hover.png) no-repeat;
        background-size: contain; }
    .button.line-btn:active {
      background: #00b300; }
      .button.line-btn:active span {
        background: url(images/line/btn_press.png) no-repeat;
        background-size: contain; }
    .button.line-btn.disabled {
      background: #c6c6c6; }
      .button.line-btn.disabled span {
        background: url(images/line/btn_hover.png) no-repeat;
        background-size: contain; }
  .button.mini {
    font-size: 10px;
    padding: 6px;
    line-height: 1; }
    .button.mini img {
      max-width: 12px; }
    .button.mini svg {
      font-size: 14px;
      color: #151d68; }
  .button.large {
    width: 100%;
    margin-top: 20px; }

.kep-login-facebook.metro {
  background-color: #192383;
  border: solid 1px #192383;
  font-family: 'sukh-light', sans-serif;
  font-size: 14px;
  color: #fff;
  padding: 8px 20px;
  width: 100%;
  border-radius: 6px !important;
  outline: none; }

@media screen and (max-width: 1024px) {
  .button {
    font-size: 14px; } }

.make-model {
  background-color: #ffffff;
  padding: 0;
  position: relative;
  z-index: 1001;
  width: 100%;
  font-family: 'sukh-regular', sans-serif;
  min-height: calc(var(--vh) - 54px);
  max-width: 580px;
  width: 100%;
  margin: 0 auto; }
  .make-model .coa-form .search-list {
    margin-bottom: 20px; }
  .make-model .coa-form .form-upload-wrap .uploaded-file {
    margin-top: 20px; }
  .make-model .coa-form .file-upload-wrap {
    margin-bottom: 20px; }
    .make-model .coa-form .file-upload-wrap .file-upload {
      padding-bottom: 0; }
  .make-model.fairdee-modal-wrapper {
    padding: 24px; }
  .make-model .header-wrap {
    display: flex;
    align-items: center;
    padding: 16px 72px; }
    .make-model .header-wrap img {
      max-width: 180px;
      margin-right: 20px; }
    .make-model .header-wrap div {
      flex-grow: 1; }
    .make-model .header-wrap p {
      font-size: 22px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.58;
      letter-spacing: normal;
      color: #000000; }
  .make-model .make-modal-wrap {
    padding: 32px 0; }
    .make-model .make-modal-wrap .input-wrap .input {
      border-bottom: 1px solid #e3e3e3; }
    .make-model .make-modal-wrap .text-box {
      margin: 10px 0 10px 0;
      border: 1px solid #e3e3e3;
      border-radius: 8px;
      padding: 10px; }
      .make-model .make-modal-wrap .text-box .text-box-label {
        color: #4a4a4a; }
    .make-model .make-modal-wrap .ui.form {
      max-width: 380px;
      margin: 0 auto; }
      .make-model .make-modal-wrap .ui.form ::-webkit-input-placeholder {
        /* WebKit browsers */
        color: transparent !important; }
      .make-model .make-modal-wrap .ui.form :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: transparent !important; }
      .make-model .make-modal-wrap .ui.form ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent !important; }
      .make-model .make-modal-wrap .ui.form :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent !important; }
      .make-model .make-modal-wrap .ui.form textarea::-webkit-input-placeholder {
        /* WebKit browsers */
        color: transparent; }
      .make-model .make-modal-wrap .ui.form textarea:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: transparent !important; }
      .make-model .make-modal-wrap .ui.form textarea::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent !important; }
      .make-model .make-modal-wrap .ui.form textarea:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent !important; }
      .make-model .make-modal-wrap .ui.form input[type='text'],
      .make-model .make-modal-wrap .ui.form input[type='email'],
      .make-model .make-modal-wrap .ui.form input[type='password'],
      .make-model .make-modal-wrap .ui.form input[type='number'],
      .make-model .make-modal-wrap .ui.form input[type='radio'],
      .make-model .make-modal-wrap .ui.form .ui.fluid.dropdown,
      .make-model .make-modal-wrap .ui.form textarea {
        width: 100%;
        border: 0;
        border-radius: 0;
        padding: 4px 0 !important; }
        .make-model .make-modal-wrap .ui.form input[type='text']:focus,
        .make-model .make-modal-wrap .ui.form input[type='email']:focus,
        .make-model .make-modal-wrap .ui.form input[type='password']:focus,
        .make-model .make-modal-wrap .ui.form input[type='number']:focus,
        .make-model .make-modal-wrap .ui.form input[type='radio']:focus,
        .make-model .make-modal-wrap .ui.form .ui.fluid.dropdown:focus,
        .make-model .make-modal-wrap .ui.form textarea:focus {
          border: 0;
          border-radius: 0; }
        .make-model .make-modal-wrap .ui.form input[type='text']:focus ~ label,
        .make-model .make-modal-wrap .ui.form input[type='email']:focus ~ label,
        .make-model .make-modal-wrap .ui.form input[type='password']:focus ~ label,
        .make-model .make-modal-wrap .ui.form input[type='number']:focus ~ label,
        .make-model .make-modal-wrap .ui.form input[type='radio']:focus ~ label,
        .make-model .make-modal-wrap .ui.form .ui.fluid.dropdown:focus ~ label,
        .make-model .make-modal-wrap .ui.form textarea:focus ~ label {
          bottom: 30px;
          color: #f58b1e;
          font-size: 12px; }
        .make-model .make-modal-wrap .ui.form input[type='text']:not(:placeholder-shown) ~ label,
        .make-model .make-modal-wrap .ui.form input[type='email']:not(:placeholder-shown) ~ label,
        .make-model .make-modal-wrap .ui.form input[type='password']:not(:placeholder-shown) ~ label,
        .make-model .make-modal-wrap .ui.form input[type='number']:not(:placeholder-shown) ~ label,
        .make-model .make-modal-wrap .ui.form input[type='radio']:not(:placeholder-shown) ~ label,
        .make-model .make-modal-wrap .ui.form .ui.fluid.dropdown:not(:placeholder-shown) ~ label,
        .make-model .make-modal-wrap .ui.form textarea:not(:placeholder-shown) ~ label {
          bottom: 30px;
          color: #f58b1e;
          font-size: 12px; }
      .make-model .make-modal-wrap .ui.form .element-wrap {
        margin-bottom: 24px; }
        .make-model .make-modal-wrap .ui.form .element-wrap > div label {
          color: #4a4a4a;
          font-family: 'sukh-regular';
          z-index: 1; }
        .make-model .make-modal-wrap .ui.form .element-wrap > div > div:hover:before {
          border-bottom: 1px solid rgba(216, 216, 216, 0.3); }
        .make-model .make-modal-wrap .ui.form .element-wrap > div > div:before {
          border-bottom: 1px solid rgba(216, 216, 216, 0.3); }
        .make-model .make-modal-wrap .ui.form .element-wrap > div > div:after {
          border-bottom: 1px solid #f58b1e; }
      .make-model .make-modal-wrap .ui.form .file-upload-wrap .title-text {
        font-size: 14px;
        font-family: 'sukh-bold';
        color: #4a4a4a;
        margin-bottom: 8px; }
      .make-model .make-modal-wrap .ui.form .file-upload-wrap .text {
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: normal;
        color: #5f5f5f;
        text-align: left; }
      .make-model .make-modal-wrap .ui.form .file-upload-wrap .mb-12 {
        margin-bottom: 24px; }
      .make-model .make-modal-wrap .ui.form .file-upload-wrap .error {
        position: relative;
        top: auto;
        bottom: auto; }
      .make-model .make-modal-wrap .ui.form .checkbox-wrap {
        position: relative; }
        .make-model .make-modal-wrap .ui.form .checkbox-wrap .error-wrap {
          margin-bottom: 50px;
          position: relative; }
          .make-model .make-modal-wrap .ui.form .checkbox-wrap .error-wrap .inline-error {
            top: 0 !important; }
        .make-model .make-modal-wrap .ui.form .checkbox-wrap input[type='text'] {
          background: url(images/down-arrow.svg) no-repeat;
          background-position: right center;
          padding-right: 24px;
          border-bottom: 2px solid rgba(216, 216, 216, 0.3);
          padding-right: 24px;
          color: #000000; }
          .make-model .make-modal-wrap .ui.form .checkbox-wrap input[type='text']:focus {
            padding-right: 24px !important;
            color: #000000; }
          .make-model .make-modal-wrap .ui.form .checkbox-wrap input[type='text']:focus ~ label {
            color: #f58b1e !important; }
        .make-model .make-modal-wrap .ui.form .checkbox-wrap .ui.checkbox label {
          padding-left: 32px;
          font-size: 12px;
          font-family: 'sukh-medium';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #4a4a4a;
          top: 0; }
          .make-model .make-modal-wrap .ui.form .checkbox-wrap .ui.checkbox label.selected {
            color: #9b9b9b !important; }
          .make-model .make-modal-wrap .ui.form .checkbox-wrap .ui.checkbox label:before, .make-model .make-modal-wrap .ui.form .checkbox-wrap .ui.checkbox label:after {
            top: 0;
            width: 20px;
            height: 20px; }
        .make-model .make-modal-wrap .ui.form .checkbox-wrap .ui.checkbox input[type='checkbox'] {
          width: 20px;
          height: 20px; }
        .make-model .make-modal-wrap .ui.form .checkbox-wrap .ui.checkbox input:checked ~ label:after {
          border: solid 2px #ff9800;
          background: #ff9800;
          width: 20px;
          height: 20px;
          font-size: 15px;
          top: 0; }
        .make-model .make-modal-wrap .ui.form .checkbox-wrap .field {
          margin-bottom: 24px !important;
          display: inline-block !important;
          width: auto !important; }
          .make-model .make-modal-wrap .ui.form .checkbox-wrap .field:not(:last-child) {
            margin-right: 32px; }
      .make-model .make-modal-wrap .ui.form .inline-error {
        margin-left: 0;
        position: absolute;
        left: 0;
        top: 30px; }
      .make-model .make-modal-wrap .ui.form .inline.field,
      .make-model .make-modal-wrap .ui.form .field {
        width: 100%;
        position: relative;
        margin-bottom: 35px; }
        .make-model .make-modal-wrap .ui.form .inline.field.mb-0,
        .make-model .make-modal-wrap .ui.form .field.mb-0 {
          margin-bottom: 0; }
        .make-model .make-modal-wrap .ui.form .inline.field > label,
        .make-model .make-modal-wrap .ui.form .field > label {
          width: 100%;
          margin: 0 !important;
          font-size: 12px;
          color: #f58b1e;
          position: absolute;
          bottom: 5px;
          z-index: 1;
          transition: 0.2s ease all;
          font-size: 14px;
          color: #4a4a4a;
          display: inline-block;
          left: 0;
          pointer-events: none; }
          .make-model .make-modal-wrap .ui.form .inline.field > label.move-up,
          .make-model .make-modal-wrap .ui.form .field > label.move-up {
            bottom: 35px; }
        .make-model .make-modal-wrap .ui.form .inline.field .ui.input,
        .make-model .make-modal-wrap .ui.form .field .ui.input {
          width: 100% !important;
          margin-top: 20px; }
          .make-model .make-modal-wrap .ui.form .inline.field .ui.input input[type='text'],
          .make-model .make-modal-wrap .ui.form .inline.field .ui.input input[type='email'],
          .make-model .make-modal-wrap .ui.form .inline.field .ui.input input[type='password'],
          .make-model .make-modal-wrap .ui.form .inline.field .ui.input input[type='number'],
          .make-model .make-modal-wrap .ui.form .inline.field .ui.input input[type='radio'],
          .make-model .make-modal-wrap .ui.form .inline.field .ui.input textarea,
          .make-model .make-modal-wrap .ui.form .field .ui.input input[type='text'],
          .make-model .make-modal-wrap .ui.form .field .ui.input input[type='email'],
          .make-model .make-modal-wrap .ui.form .field .ui.input input[type='password'],
          .make-model .make-modal-wrap .ui.form .field .ui.input input[type='number'],
          .make-model .make-modal-wrap .ui.form .field .ui.input input[type='radio'],
          .make-model .make-modal-wrap .ui.form .field .ui.input textarea {
            width: 100%; }
    .make-model .make-modal-wrap .datepicker label {
      font-size: 16px;
      color: #4a4a4a;
      display: inline-block;
      left: 0;
      pointer-events: none;
      font-family: 'sukh-regular'; }
      .make-model .make-modal-wrap .datepicker label[data-shrink='true'] {
        font-size: 18px;
        color: #9b9b9b !important; }
    .make-model .make-modal-wrap .datepicker > div:before {
      border-bottom: 2px solid rgba(216, 216, 216, 0.3); }
    .make-model .make-modal-wrap .datepicker > div:after {
      border-bottom: 2px solid rgba(216, 216, 216, 0.3); }
    .make-model .make-modal-wrap .datepicker > div:hover:before {
      border-bottom: 2px solid rgba(216, 216, 216, 0.3) !important; }
    .make-model .make-modal-wrap .datepicker > div:hover:after {
      border-bottom: 2px solid rgba(216, 216, 216, 0.3) !important; }
    .make-model .make-modal-wrap p.label {
      font-size: 12px;
      margin-top: 24px; }
    .make-model .make-modal-wrap .sum-insured-wrap {
      margin: -12px 0 40px 0; }
      .make-model .make-modal-wrap .sum-insured-wrap .title {
        font-size: 12px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        margin: 0 0 8px 0; }
    .make-model .make-modal-wrap .range-slider-wrap {
      margin-bottom: 40px; }
      .make-model .make-modal-wrap .range-slider-wrap .rc-slider-mark-text {
        font-family: 'Nunito', sans-serif;
        font-size: 12px !important; }
      .make-model .make-modal-wrap .range-slider-wrap label {
        color: #f58b1e;
        font-size: 12px;
        margin-bottom: 8px;
        font-family: 'Nunito', sans-serif;
        font-family: 'sukh-light';
        letter-spacing: 0;
        display: block; }
    .make-model .make-modal-wrap .or-text {
      text-align: center;
      color: #000;
      font-size: 12px;
      text-align: center;
      margin-bottom: 24px; }
    .make-model .make-modal-wrap .field label {
      text-align: left; }
    .make-model .make-modal-wrap .field .inline-error {
      margin-top: 12px;
      padding-left: 0 !important; }
    .make-model .make-modal-wrap .titletext {
      font-family: 'sukh-medium', sans-serif;
      font-size: 22px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #192383;
      margin: 0;
      margin-bottom: 20px; }
    .make-model .make-modal-wrap .button {
      width: 100%;
      margin-top: 10px;
      font-size: 16px;
      font-family: 'sukh-medium';
      padding: 8px; }
    .make-model .make-modal-wrap .ui.form .inline-error {
      margin-left: 0; }
    .make-model .make-modal-wrap .ocr-form {
      margin-bottom: 20px; }
      .make-model .make-modal-wrap .ocr-form label {
        font-size: 14px;
        font-weight: 700;
        color: #1253a4;
        line-height: 157%; }
      .make-model .make-modal-wrap .ocr-form .upload-wrapper {
        border: 1px dashed #cfd8dc;
        border-radius: 8px;
        padding: 12px 20px;
        display: flex; }
        .make-model .make-modal-wrap .ocr-form .upload-wrapper .description-wrap {
          flex-grow: 1;
          flex-basis: 50%;
          color: #555454;
          font-size: 12px;
          line-height: 14px;
          margin: 0 8px; }
          .make-model .make-modal-wrap .ocr-form .upload-wrapper .description-wrap .title {
            font-size: 14px;
            line-height: 14.4px;
            font-weight: 500;
            color: #2f2f2f;
            margin: 0; }
          .make-model .make-modal-wrap .ocr-form .upload-wrapper .description-wrap ol {
            list-style: number;
            list-style-position: outside; }
            .make-model .make-modal-wrap .ocr-form .upload-wrapper .description-wrap ol li {
              margin-left: 17px; }
          .make-model .make-modal-wrap .ocr-form .upload-wrapper .description-wrap .read-more {
            color: #1253a4;
            text-decoration: underline;
            cursor: pointer; }
        .make-model .make-modal-wrap .ocr-form .upload-wrapper .upload-btn .file-upload {
          display: flex; }
          .make-model .make-modal-wrap .ocr-form .upload-wrapper .upload-btn .file-upload.block {
            display: block; }
          .make-model .make-modal-wrap .ocr-form .upload-wrapper .upload-btn .file-upload .uploaded-file {
            word-break: normal; }
          .make-model .make-modal-wrap .ocr-form .upload-wrapper .upload-btn .file-upload .button {
            margin-top: 0px;
            font-size: 12px;
            padding: 4px 9px !important;
            color: #555454;
            text-transform: capitalize; }
  .make-model .text-field-one {
    width: 100%;
    margin-bottom: 40px;
    margin-top: 0px; }
  .make-model .text-field-two {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 0px; }
  .make-model .date-field {
    width: 100%;
    margin-bottom: 8px; }
  .make-model .label {
    margin-bottom: 10px;
    display: block; }
  .make-model .button-wrap-manual {
    padding: 5px; }

@media screen and (max-width: 1024px) {
  .make-model {
    padding: 24px; }
    .make-model .mobile-header {
      display: block;
      position: fixed;
      background: #fff;
      z-index: 9;
      left: 0;
      top: 0;
      right: 0; }
    .make-model .header-wrap {
      display: block;
      margin-bottom: 0;
      padding: 0; }
      .make-model .header-wrap p {
        font-size: 16px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        margin: 0; }
    .make-model .make-modal-wrap {
      width: 100%; }
      .make-model .make-modal-wrap.with-margin {
        margin-top: 32px; }
      .make-model .make-modal-wrap .ui.form .button-wrap {
        margin: 0;
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2; }
      .make-model .make-modal-wrap .ui.form .checkbox-wrap .field {
        width: calc(50% - 24px) !important; }
        .make-model .make-modal-wrap .ui.form .checkbox-wrap .field:nth-child(even) {
          margin-right: 0; }
      .make-model .make-modal-wrap .title-text {
        font-size: 22px !important;
        text-align: left !important; }
      .make-model .make-modal-wrap .ui.search.dropdown > .text {
        left: 0;
        top: 5px; }
      .make-model .make-modal-wrap .ui.form .inline-error {
        top: 30px; } }

.ui.grid {
  margin: 0; }
  .ui.grid.container {
    width: 100% !important; }

.ui.fluid.dropdown {
  border-radius: 2px;
  background-color: #fff;
  border: solid 1px #192383;
  padding: 12px;
  position: relative;
  height: 51px; }
  .ui.fluid.dropdown.active {
    border-color: #192383; }
    .ui.fluid.dropdown.active.dropdown:hover {
      border-color: #192383; }
    .ui.fluid.dropdown.active.dropdown .menu {
      border-color: #192383; }
  .ui.fluid.dropdown .menu {
    max-height: 220px; }
  .ui.fluid.dropdown .dropdown.icon {
    width: 30px;
    height: 100%; }
    .ui.fluid.dropdown .dropdown.icon:before {
      content: "";
      background: url(images/arrow-down.svg) no-repeat !important;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 15px;
      top: 17px;
      z-index: 5; }
  .ui.fluid.dropdown:hover {
    border: solid 1px #192383; }
  .ui.fluid.dropdown .text {
    font-family: 'sukh-light';
    letter-spacing: 0;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #535766; }
    .ui.fluid.dropdown .text.default {
      color: #535766; }
  .ui.fluid.dropdown input.search {
    font-family: 'sukh-light';
    letter-spacing: 0;
    font-size: 16px;
    padding: 10px 10px 0 10px !important;
    text-transform: uppercase;
    height: 40px; }
  .ui.fluid.dropdown .menu::-webkit-scrollbar {
    width: 3px; }
  .ui.fluid.dropdown .menu::-webkit-scrollbar-track {
    -webkit-box-shadow: #f4f4f4; }
  .ui.fluid.dropdown .menu::-webkit-scrollbar-thumb {
    background-color: rgba(22, 43, 106, 0.05);
    outline: 1px solid rgba(22, 43, 106, 0.05); }
  .ui.fluid.dropdown.mini {
    display: inline-block;
    width: 200px;
    height: auto;
    padding: 5px 30px 5px 15px;
    min-height: auto; }
    .ui.fluid.dropdown.mini .dropdown.icon {
      width: 22px; }
      .ui.fluid.dropdown.mini .dropdown.icon:before {
        right: 0px;
        top: 10px;
        width: 18px;
        height: 18px; }
    .ui.fluid.dropdown.mini > .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%; }
    .ui.fluid.dropdown.mini .text {
      font-family: 'sukh-light';
      letter-spacing: 0;
      font-size: 12px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      text-transform: uppercase;
      vertical-align: middle; }
      .ui.fluid.dropdown.mini .text.default {
        color: #1e2886;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%; }

.ui .form .field {
  margin-bottom: 14px; }

@media screen and (max-width: 1024px) {
  .ui.fluid.dropdown {
    border: solid 1px rgba(216, 216, 216, 0.3); }
    .ui.fluid.dropdown.active {
      border-color: rgba(216, 216, 216, 0.3); }
      .ui.fluid.dropdown.active.dropdown:hover {
        border-color: rgba(216, 216, 216, 0.3); }
      .ui.fluid.dropdown.active.dropdown .menu {
        border-color: rgba(216, 216, 216, 0.3); }
        .ui.fluid.dropdown.active.dropdown .menu .item {
          padding: 0.78571429rem 12px !important; }
    .ui.fluid.dropdown input.search {
      padding: 0 0 10px 15px !important; }
    .ui.fluid.dropdown .menu {
      max-height: 220px; } }

.listing-header-wrap {
  opacity: 0.98;
  background-color: #192383;
  position: fixed;
  left: 0;
  right: 0;
  padding: 14px 0 24px 0;
  z-index: 4;
  top: 55px; }
  .listing-header-wrap .container {
    max-width: 1320px; }
  .listing-header-wrap.modify .make-model-form .ui.form .field:last-child {
    padding: 2px 2px 2px 5px; }
  .listing-header-wrap.modify .make-model-form .ui.form .field .dropdown {
    pointer-events: auto; }
    .listing-header-wrap.modify .make-model-form .ui.form .field .dropdown .dropdown.icon {
      display: block; }
      .listing-header-wrap.modify .make-model-form .ui.form .field .dropdown .dropdown.icon:before {
        top: 16px; }
  .listing-header-wrap .breadcrumb {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block; }
    .listing-header-wrap .breadcrumb li {
      margin: 0;
      padding: 0;
      display: inline-block;
      position: relative; }
      .listing-header-wrap .breadcrumb li[lang='english']:after {
        font-family: 'sukh-light';
        letter-spacing: 0; }
      .listing-header-wrap .breadcrumb li:after {
        content: '/';
        color: #fff;
        padding: 0 2px;
        font-family: 'sukh-regular', sans-serif; }
      .listing-header-wrap .breadcrumb li:last-child:after {
        content: ''; }
      .listing-header-wrap .breadcrumb li a {
        font-family: 'sukh-regular', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff; }
        .listing-header-wrap .breadcrumb li a[lang='english'] {
          font-family: 'sukh-regular', sans-serif;
          letter-spacing: 0; }
        .listing-header-wrap .breadcrumb li a:hover {
          text-decoration: none;
          color: inherit;
          opacity: 0.9;
          color: #fff; }
  .listing-header-wrap div {
    position: relative; }
  .listing-header-wrap .make-model-bar {
    border-radius: 4px;
    background-color: #fff;
    border: solid 1px #ecebeb;
    padding: 13px 0;
    margin: 0;
    margin-top: 15px;
    list-style: none;
    display: table;
    table-layout: fixed;
    width: 700px; }
    .listing-header-wrap .make-model-bar li {
      font-family: 'sukh-bold', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #535766;
      display: table-cell;
      text-transform: uppercase;
      padding-left: 25px;
      border-right: 1px solid #d8d8d8; }
      .listing-header-wrap .make-model-bar li:last-child {
        border: 0;
        color: #192383; }
  .listing-header-wrap .make-model-form {
    margin-top: 15px; }
    .listing-header-wrap .make-model-form .ui.form {
      display: table;
      table-layout: fixed;
      width: 800px;
      background: #fff;
      border-radius: 4px;
      position: relative;
      z-index: 1;
      border: 1px solid #192383; }
      .listing-header-wrap .make-model-form .ui.form .mobile-label {
        display: none;
        font-family: 'sukh-regular', sans-serif;
        letter-spacing: 0;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #f58b1e;
        padding-left: 5px;
        margin-bottom: 5px; }
      .listing-header-wrap .make-model-form .ui.form .field {
        margin: 0;
        display: table-cell;
        text-transform: uppercase;
        padding: 14px 0 14px 0;
        vertical-align: middle; }
        .listing-header-wrap .make-model-form .ui.form .field:last-child {
          padding: 2px 2px 2px 5px; }
        .listing-header-wrap .make-model-form .ui.form .field.modify {
          padding-left: 0;
          text-align: center; }
        .listing-header-wrap .make-model-form .ui.form .field button {
          width: 100%;
          padding: 10px 20px 10px 20px; }
        .listing-header-wrap .make-model-form .ui.form .field:last-child {
          border: 0; }
        .listing-header-wrap .make-model-form .ui.form .field .modify-text {
          display: inline-block;
          font-family: 'sukh-bold', sans-serif;
          font-size: 14px;
          color: #192383;
          cursor: pointer; }
        .listing-header-wrap .make-model-form .ui.form .field .dropdown {
          padding: 0;
          border: 0;
          background: transparent;
          height: auto;
          min-height: auto;
          border-radius: 0;
          box-shadow: none;
          padding-left: 18px;
          padding-right: 40px; }
          .listing-header-wrap .make-model-form .ui.form .field .dropdown .menu {
            top: calc(100% + 14px); }
          .listing-header-wrap .make-model-form .ui.form .field .dropdown .dropdown.icon {
            top: 0; }
            .listing-header-wrap .make-model-form .ui.form .field .dropdown .dropdown.icon:before {
              background: url(images/down-arrow.svg) no-repeat !important;
              background-size: contain;
              width: 20px;
              height: 20px; }
          .listing-header-wrap .make-model-form .ui.form .field .dropdown input.search {
            height: auto;
            font-size: 14px;
            padding: 0 !important;
            box-shadow: none !important;
            padding-left: 18px !important; }
          .listing-header-wrap .make-model-form .ui.form .field .dropdown .text {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            line-height: 1;
            vertical-align: middle;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%; }
          .listing-header-wrap .make-model-form .ui.form .field .dropdown .message {
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            line-height: 1;
            vertical-align: middle;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%; }
  .listing-header-wrap .desc-text {
    font-family: 'sukh-medium', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    display: inline-block;
    position: absolute;
    right: 0;
    top: calc(50% - 12px); }
    .listing-header-wrap .desc-text span {
      display: inline-block;
      margin-left: 10px; }
      .listing-header-wrap .desc-text span img {
        vertical-align: bottom;
        margin-bottom: 2px; }
    .listing-header-wrap .desc-text[lang='english'] {
      font-family: 'sukh-regular', sans-serif;
      letter-spacing: 0; }

.modify-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(77, 92, 116, 0.8);
  z-index: 3; }

@media screen and (max-width: 1024px) {
  .listing-header-wrap {
    background-color: #fff;
    padding: 8px 0 0;
    box-shadow: none;
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    z-index: 1; }
    .listing-header-wrap .make-model-form .ui.form .field {
      pointer-events: none; }
      .listing-header-wrap .make-model-form .ui.form .field .dropdown .dropdown.icon:before {
        background: none !important; }
      .listing-header-wrap .make-model-form .ui.form .field.modify {
        pointer-events: auto; }
    .listing-header-wrap.modify {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 15;
      overflow-y: auto;
      padding: 80px 20px;
      -webkit-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      opacity: 1; }
      .listing-header-wrap.modify .container {
        margin-top: 25%; }
      .listing-header-wrap.modify .make-model-form {
        margin: 0; }
        .listing-header-wrap.modify .make-model-form .ui.form {
          display: block; }
          .listing-header-wrap.modify .make-model-form .ui.form .mobile-label {
            display: block; }
          .listing-header-wrap.modify .make-model-form .ui.form .field {
            display: block;
            text-align: left;
            margin-bottom: 5px; }
            .listing-header-wrap.modify .make-model-form .ui.form .field > .dropdown {
              text-align: left;
              border: 0;
              border-bottom: 1px solid #d8d8d8;
              padding-bottom: 5px; }
              .listing-header-wrap.modify .make-model-form .ui.form .field > .dropdown .menu {
                top: 100%; }
              .listing-header-wrap.modify .make-model-form .ui.form .field > .dropdown input.search {
                padding: 0 7px !important; }
    .listing-header-wrap .container {
      padding: 0 15px;
      background: #fff; }
    .listing-header-wrap .breadcrumb {
      display: none; }
    .listing-header-wrap .desc-text,
    .listing-header-wrap .modify-wrap {
      display: none; }
    .listing-header-wrap .make-model-form {
      margin-top: 0; }
      .listing-header-wrap .make-model-form .ui.form {
        width: 100%;
        margin-top: 0;
        border: 1px solid #ecebeb; }
        .listing-header-wrap .make-model-form .ui.form .field {
          padding: 8px 0 8px 0;
          text-align: center; }
          .listing-header-wrap .make-model-form .ui.form .field .modify-text {
            font-size: 12px; }
          .listing-header-wrap .make-model-form .ui.form .field .dropdown {
            text-align: center;
            padding: 0 5px; }
            .listing-header-wrap .make-model-form .ui.form .field .dropdown .dropdown.icon {
              top: 5px;
              right: 0; }
            .listing-header-wrap .make-model-form .ui.form .field .dropdown .menu > .item {
              padding: 5px 10px !important; }
            .listing-header-wrap .make-model-form .ui.form .field .dropdown .text {
              font-size: 12px; }
            .listing-header-wrap .make-model-form .ui.form .field .dropdown .message {
              font-size: 12px; }
            .listing-header-wrap .make-model-form .ui.form .field .dropdown input.search {
              padding: 7px 10px 7px 10px !important;
              font-size: 12px; } }

.filter-wrap {
  width: 280px; }
  .filter-wrap .title-text {
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #192383;
    font-family: 'sukh-medium', sans-serif;
    text-transform: uppercase;
    margin-bottom: 22px; }
    .filter-wrap .title-text[lang='english'] {
      font-family: 'sukh-medium', sans-serif;
      letter-spacing: 0; }
  .filter-wrap .each-filter {
    margin-bottom: 24px;
    position: relative; }
    .filter-wrap .each-filter.bold-label .ui.checkbox label {
      font-family: 'sukh-light', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #192383;
      margin-bottom: 10px; }
      .filter-wrap .each-filter.bold-label .ui.checkbox label[lang='english'] {
        font-family: 'sukh-medium', sans-serif;
        letter-spacing: 0; }
    .filter-wrap .each-filter .toggle-box {
      width: 32px;
      height: 32px;
      position: absolute;
      top: -5px;
      right: 30px;
      background: url(images/arrow-down.svg) no-repeat;
      background-position: center center;
      cursor: pointer;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg); }
      .filter-wrap .each-filter .toggle-box.rotate {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg); }
    .filter-wrap .each-filter .toggle-wrap.hide {
      display: none; }
    .filter-wrap .each-filter .range-slider-wrap {
      margin-bottom: 50px;
      max-width: 180px; }
    .filter-wrap .each-filter .filter-title {
      font-family: 'sukh-light', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #192383;
      margin-bottom: 10px; }
      .filter-wrap .each-filter .filter-title[lang='english'] {
        font-family: 'sukh-medium', sans-serif;
        letter-spacing: 0; }
    .filter-wrap .each-filter .field {
      margin-bottom: 10px !important; }
    .filter-wrap .each-filter .search-list {
      max-width: 175px;
      margin: 0; }
      .filter-wrap .each-filter .search-list .search-input {
        border-bottom: 1px solid rgba(0, 0, 0, 0.42); }
        .filter-wrap .each-filter .search-list .search-input input {
          font-family: 'sukh-light', sans-serif;
          font-size: 14px;
          color: #4a4a4a;
          border: 0;
          outline: none;
          width: 100%; }
    .filter-wrap .each-filter .province-wrap {
      max-width: 175px; }
      .filter-wrap .each-filter .province-wrap > label {
        font-family: 'sukh-light', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #192383;
        transition: none;
        transform: none; }
        .filter-wrap .each-filter .province-wrap > label[data-shrink='false'] {
          transform: none; }
      .filter-wrap .each-filter .province-wrap > div div > div {
        font-family: 'sukh-light', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a; }
  .filter-wrap .mobile-buttons-wrap {
    display: none; }

@media screen and (max-width: 1024px) {
  .filter-wrap {
    top: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 15;
    background-color: #fff;
    overflow-y: auto;
    padding: 80px 20px;
    transition: all 0.3s ease-out;
    /* optional: show position indicator in red */ }
    .filter-wrap::-webkit-scrollbar {
      width: 0px;
      /* remove scrollbar space */
      background: transparent;
      /* optional: just make scrollbar invisible */ }
    .filter-wrap::-webkit-scrollbar-thumb {
      background: #ff0000; }
    .filter-wrap .mobile-header,
    .filter-wrap .mobile-header.show {
      top: 100%;
      transition: all 0.3s ease-out;
      display: none; }
    .filter-wrap.show {
      top: 0;
      z-index: 9999; }
      .filter-wrap.show .mobile-header.show {
        display: block;
        top: 0; }
      .filter-wrap.show .mobile-buttons-wrap {
        position: fixed; }
    .filter-wrap .title-text {
      display: none; }
    .filter-wrap .each-filter .toggle-box {
      display: none; }
    .filter-wrap .each-filter .search-list,
    .filter-wrap .each-filter .province-wrap {
      max-width: calc(100% - 40px); }
    .filter-wrap .each-filter .range-slider-wrap {
      padding-right: 40px;
      max-width: 100%; }
      .filter-wrap .each-filter .range-slider-wrap .rangeslider {
        width: 100%; }
    .filter-wrap .each-filter .ui.checkbox {
      display: block;
      text-align: left;
      padding-right: 40px; }
      .filter-wrap .each-filter .ui.checkbox label {
        text-align: left;
        padding-left: 0; }
        .filter-wrap .each-filter .ui.checkbox label:before, .filter-wrap .each-filter .ui.checkbox label:after {
          right: 0;
          left: auto; }
    .filter-wrap .mobile-buttons-wrap {
      z-index: 999;
      display: table;
      width: 100%;
      table-layout: fixed;
      position: relative;
      left: 0;
      right: 0;
      bottom: 0; }
      .filter-wrap .mobile-buttons-wrap li {
        display: table-cell;
        vertical-align: middle;
        font-family: 'sukh-medium', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #4a4a4a;
        text-transform: uppercase;
        padding: 18px 0;
        background: #fff; }
        .filter-wrap .mobile-buttons-wrap li:first-child {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); }
        .filter-wrap .mobile-buttons-wrap li:last-child {
          background: #f58b1e;
          color: #fff; } }

.insurer-card {
  border-radius: 12px;
  background-color: #fff;
  border: solid 1px #ecebeb;
  padding: 10px 28px 20px 28px;
  position: relative;
  text-align: center; }
  .insurer-card.default {
    min-height: 385px; }
    .insurer-card.default .insurer-name {
      margin-bottom: 95px; }
  .insurer-card.add {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; }
    .insurer-card.add .plus {
      font-size: 48px; }
  .insurer-card .styled-banner {
    border-radius: 5px;
    background-color: #99d6d2;
    position: absolute;
    right: -6px;
    padding-left: 15px; }
    .insurer-card .styled-banner:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      border-left: 18px solid #fff;
      position: absolute;
      z-index: 1;
      left: -3px;
      top: -5px; }
    .insurer-card .styled-banner span {
      font-family: 'sukh-regular', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      display: inline-block;
      position: relative;
      padding: 5px 6px; }
  .insurer-card .downpayment-text {
    display: none; }
  .insurer-card .insurer-logo {
    height: 50px;
    margin-top: 30px;
    text-align: center;
    width: 100%;
    position: relative; }
    .insurer-card .insurer-logo img {
      display: inline-block;
      max-height: 35px;
      max-width: 150px;
      position: absolute;
      margin: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0; }
  .insurer-card .insurer-name {
    font-family: 'sukh-bold', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    text-align: center;
    margin-bottom: 5px; }
  .insurer-card .default-text {
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a; }
    .insurer-card .default-text span {
      text-decoration: underline;
      cursor: pointer; }
  .insurer-card .insurance-price {
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    font-size: 12px;
    font-family: 'sukh-light', sans-serif; }
    .insurer-card .insurance-price img {
      vertical-align: middle;
      margin-left: 8px; }
    .insurer-card .insurance-price span.number {
      font-family: 'sukh-bold', sans-serif;
      font-size: 15px;
      display: inline-block;
      padding: 0 4px; }
      .insurer-card .insurance-price span.number.no-strike {
        text-decoration: none; }
  .insurer-card .cashback-text {
    font-family: 'sukh-light', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a; }
    .insurer-card .cashback-text span.number {
      font-size: 17px;
      font-family: 'sukh-bold', sans-serif; }
    .insurer-card .cashback-text img {
      display: inline-block;
      vertical-align: inherit;
      margin-left: 5px;
      cursor: pointer;
      margin-bottom: -2px; }
  .insurer-card .insurance-description {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 15px; }
    .insurer-card .insurance-description li {
      margin: 5px 0;
      padding: 0 0 0 20px;
      font-family: 'sukh-light', sans-serif;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      position: relative; }
      .insurer-card .insurance-description li:before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: #9b9b9b;
        position: absolute;
        left: 0;
        top: 9px; }
      .insurer-card .insurance-description li span {
        color: #4a4a4a;
        font-family: Avenir-Roman;
        font-family: 'sukh-light'; }
        .insurer-card .insurance-description li span.lang {
          font-family: 'sukh-regular', sans-serif; }
        .insurer-card .insurance-description li span:first-child {
          padding-left: 4px; }
  .insurer-card .see-details {
    font-family: 'sukh-regular', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #192383;
    margin: 6px 0 15px 0;
    cursor: pointer;
    padding-left: 20px; }
  .insurer-card .extra-info {
    background: #f7f8ff;
    padding: 5px;
    margin: 6px 0;
    position: relative;
    height: 70px; }
    .insurer-card .extra-info:before, .insurer-card .extra-info:after {
      content: '';
      position: absolute;
      width: 28px;
      background: #f7f8ff;
      left: -28px;
      height: 100%;
      top: 0; }
    .insurer-card .extra-info:after {
      right: -28px;
      left: auto; }
    .insurer-card .extra-info .text {
      font-size: 12px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      font-family: 'sukh-medium', sans-serif; }
      .insurer-card .extra-info .text:last-child span {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .insurer-card .extra-info .text.light {
        font-weight: normal; }
  .insurer-card .button {
    font-family: 'sukh-regular', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    width: 100%;
    padding: 7px 20px;
    margin-bottom: 13px; }
  .insurer-card .field {
    text-align: center !important; }
  .insurer-card .ui.checkbox label {
    font-family: 'sukh-regular', sans-serif;
    font-size: 12px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a; }

.insurer-card-new {
  border-radius: 12px;
  border: solid 1px #ecebeb;
  background-color: #ffffff;
  font-family: 'Nunito';
  font-family: 'sukh-regular';
  position: relative;
  padding-bottom: 100px; }
  .insurer-card-new.renewal-price-list {
    background-color: #fffcf2;
    box-shadow: 0 5px 8px 0 rgba(197, 197, 207, 0.78); }
  .insurer-card-new.quo-card .buttons-wrap {
    display: block; }
    .insurer-card-new.quo-card .buttons-wrap div {
      margin: 0;
      padding: 0 !important; }
    .insurer-card-new.quo-card .buttons-wrap button {
      width: 100%; }
  .insurer-card-new .instalment-split {
    background-color: #fdefe2;
    padding: 8px 20px; }
    .insurer-card-new .instalment-split .key-value {
      display: flex; }
      .insurer-card-new .instalment-split .key-value:not(:last-child) {
        margin-bottom: 10px; }
      .insurer-card-new .instalment-split .key-value .key {
        font-size: 12px;
        font-family: 'sukh-regular';
        color: #4a4a4a;
        max-width: 100px; }
      .insurer-card-new .instalment-split .key-value .value {
        font-size: 12px;
        font-family: 'sukh-regular';
        color: #4a4a4a;
        flex-grow: 1;
        text-align: right; }
  .insurer-card-new .header {
    background-color: #e8f8fe;
    margin-bottom: 8px;
    text-align: right;
    border-radius: 12px 12px 0 0;
    padding: 24px 15px 10px 15px;
    margin-bottom: 8px; }
    .insurer-card-new .header > div {
      position: relative;
      align-items: center;
      display: flex;
      margin-bottom: 4px; }
    .insurer-card-new .header .img-wrap {
      position: relative;
      max-width: 100px;
      text-align: left;
      padding-right: 8px; }
      .insurer-card-new .header .img-wrap:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 12px;
        background: #c9dde5;
        right: 0;
        top: calc(50% - 6px); }
      .insurer-card-new .header .img-wrap img {
        max-height: 30px;
        max-width: 90px;
        object-fit: cover; }
    .insurer-card-new .header .restriction-text {
      background: #84bdff;
      border-radius: 16px 0 0 16px;
      padding: 4px 12px;
      font-size: 10px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      position: absolute;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      right: 0;
      top: -12px; }
      .insurer-card-new .header .restriction-text.green {
        background-color: #009c79; }
      .insurer-card-new .header .restriction-text.yellow {
        background-color: #eeb22a; }
    .insurer-card-new .header .text {
      font-size: 12px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      text-align: left; }
    .insurer-card-new .header .details-wrap {
      flex-grow: 1;
      padding-left: 8px; }
  .insurer-card-new .insurance-price {
    font-size: 14px;
    color: #4a4a4a;
    display: block;
    padding: 0 15px;
    margin-bottom: 4px;
    text-align: center; }
    .insurer-card-new .insurance-price img {
      vertical-align: middle;
      margin-left: 8px; }
    .insurer-card-new .insurance-price span.number {
      font-family: 'sukh-bold', sans-serif;
      font-size: 20px;
      font-family: 'sukh-bold';
      display: inline-block;
      padding: 0 4px; }
      .insurer-card-new .insurance-price span.number.no-strike {
        text-decoration: none; }
  .insurer-card-new .insurance-type {
    font-size: 14px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    padding: 0 15px; }
  .insurer-card-new .line {
    width: calc(100% - 36px);
    margin: 8px auto;
    height: 1px;
    background: #e8e8e8; }
  .insurer-card-new .see-details {
    font-size: 10px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1d2785;
    text-decoration: underline;
    cursor: pointer;
    padding: 0 24px;
    margin-top: 8px;
    position: absolute;
    bottom: 80px; }
  .insurer-card-new .insurance-description {
    padding: 0 26px;
    margin: 0;
    list-style: none;
    margin-bottom: 10px; }
    .insurer-card-new .insurance-description li {
      margin: 5px 0;
      padding: 0;
      font-size: 12px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      position: relative;
      text-align: left; }
      .insurer-card-new .insurance-description li span {
        font-family: 'sukh-bold'; }
        .insurer-card-new .insurance-description li span:first-child {
          padding-left: 4px; }
  .insurer-card-new .discount-wrap {
    margin-top: 10px;
    padding: 0 24px;
    text-align: left; }
    .insurer-card-new .discount-wrap img {
      display: inline-block;
      vertical-align: middle;
      max-width: 17px;
      margin-right: 12px; }
    .insurer-card-new .discount-wrap .tags {
      border-radius: 100px;
      background-color: #fef1e3;
      font-size: 10px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      padding: 2px 8px;
      margin: 0 4px 8px 0;
      display: inline-block; }
    .insurer-card-new .discount-wrap span {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px; }
      .insurer-card-new .discount-wrap span.key {
        font-family: 'sukh-bold';
        color: #3b8fae;
        margin-right: 4px; }
      .insurer-card-new .discount-wrap span.value {
        color: #414141; }
  .insurer-card-new .no-discount {
    height: 16px;
    margin-top: 10px; }
  .insurer-card-new .buttons-wrap {
    display: flex;
    margin-top: 12px;
    align-items: center;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 32px;
    right: 0; }
    .insurer-card-new .buttons-wrap > div {
      flex-basis: 100%;
      padding: 4px 8px 4px 16px; }
      .insurer-card-new .buttons-wrap > div:last-child {
        padding: 4px 16px 4px 8px; }
    .insurer-card-new .buttons-wrap .field {
      text-align: center !important; }
    .insurer-card-new .buttons-wrap .ui.checkbox label {
      font-size: 12px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      padding-left: 20px;
      text-align: left; }
      .insurer-card-new .buttons-wrap .ui.checkbox label:before {
        border: solid 1px #e8e8e8;
        width: 14px;
        height: 14px; }
    .insurer-card-new .buttons-wrap .ui.checkbox input:checked ~ .box:after,
    .insurer-card-new .buttons-wrap .ui.checkbox input:checked ~ label:after {
      width: 14px;
      height: 14px; }
    .insurer-card-new .buttons-wrap .button {
      background-color: #f58b1e;
      font-size: 12px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #ffffff;
      padding: 4px;
      width: 100%;
      text-transform: none; }
  .insurer-card-new .extra-info-wrap {
    border-radius: 0 0 12px 12px;
    background-color: #555555;
    padding: 6px 16px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    right: 0; }
    .insurer-card-new .extra-info-wrap > p {
      font-size: 10px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      text-align: left;
      cursor: pointer; }
      .insurer-card-new .extra-info-wrap > p span {
        font-size: 10px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        text-decoration: underline; }

.ui.popup.popover-el {
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1), 0 -2px 4px 2px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  padding: 10px;
  max-width: 300px; }
  .ui.popup.popover-el div p {
    font-size: 10px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a; }
  .ui.popup.popover-el div span {
    font-size: 10px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #00ad87;
    cursor: pointer; }

.ui.popup.effective-price,
.ui.popup.corona-popups {
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  border: solid 1px #eeeeee;
  padding: 6px;
  z-index: 999; }
  .ui.popup.effective-price .text,
  .ui.popup.corona-popups .text {
    font-family: 'sukh-light', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a; }
    .ui.popup.effective-price .text span,
    .ui.popup.corona-popups .text span {
      font-size: 8px;
      display: block; }
    .ui.popup.effective-price .text .line,
    .ui.popup.corona-popups .text .line {
      opacity: 0.7;
      border-bottom: solid 0.5px #4a4a4a; }
    .ui.popup.effective-price .text.final-calc,
    .ui.popup.corona-popups .text.final-calc {
      margin-top: 13px; }
  .ui.popup.effective-price .content-wrap,
  .ui.popup.corona-popups .content-wrap {
    width: 150px; }
    .ui.popup.effective-price .content-wrap .text,
    .ui.popup.corona-popups .content-wrap .text {
      font-size: 12px;
      font-family: 'sukh-light'; }
      .ui.popup.effective-price .content-wrap .text.right,
      .ui.popup.corona-popups .content-wrap .text.right {
        position: relative;
        text-align: right; }
        .ui.popup.effective-price .content-wrap .text.right:before,
        .ui.popup.corona-popups .content-wrap .text.right:before {
          content: '';
          width: 40px;
          border-top: 1px solid rgba(74, 74, 74, 0.7);
          position: absolute;
          right: 0; }
      .ui.popup.effective-price .content-wrap .text span,
      .ui.popup.corona-popups .content-wrap .text span {
        font-size: 8px;
        display: inline-block; }
    .ui.popup.effective-price .content-wrap .row,
    .ui.popup.corona-popups .content-wrap .row {
      display: flex; }
      .ui.popup.effective-price .content-wrap .row .text:last-child,
      .ui.popup.corona-popups .content-wrap .row .text:last-child {
        flex-grow: 1;
        text-align: right;
        margin-left: 4px; }
  .ui.popup.effective-price.center:before,
  .ui.popup.corona-popups.center:before {
    -webkit-box-shadow: -1px -1px 0 0 rgba(0, 0, 0, 0.06);
    box-shadow: -1px -1px 0 0 rgba(0, 0, 0, 0.06); }

.ui.popup.corona-popups {
  z-index: 1300; }

@media screen and (max-width: 1024px) {
  .insurer-card {
    padding: 10px 16px 20px 16px;
    border-radius: 0;
    border: 0;
    border-right: solid 1px #ecebeb;
    border-bottom: solid 1px #ecebeb;
    display: inline-block;
    width: 50%; }
    .insurer-card.add {
      border: 0;
      box-shadow: none;
      background: transparent; }
    .insurer-card .extra-info {
      height: auto; }
      .insurer-card .extra-info:before, .insurer-card .extra-info:after {
        content: '';
        left: -16px;
        width: 16px; }
      .insurer-card .extra-info:after {
        right: -16px;
        left: auto; }
    .insurer-card .downpayment-text {
      display: block;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      font-family: 'sukh-light', sans-serif;
      position: relative;
      padding-left: 15px;
      margin: 10px 0 0; }
      .insurer-card .downpayment-text::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: #4a4a4a;
        position: absolute;
        left: 0;
        top: 8px; }
    .insurer-card.default {
      min-height: auto; }
    .insurer-card:nth-child(even) {
      border-right: 0; }
    .insurer-card .insurer-logo {
      margin: 0 0 10px 0;
      height: 30px; }
      .insurer-card .insurer-logo img {
        max-width: 75px; }
    .insurer-card .styled-banner {
      display: none; }
    .insurer-card .insurance-price {
      margin-top: 5px;
      font-size: 10px; }
      .insurer-card .insurance-price img {
        margin-left: 2px; }
      .insurer-card .insurance-price span.number {
        font-size: 13px; }
    .insurer-card .cashback-text {
      margin-top: 5px;
      position: relative; }
      .insurer-card .cashback-text span.dtext {
        display: block; }
    .insurer-card .insurance-description {
      margin-top: 10px; }
      .insurer-card .insurance-description li {
        font-size: 12px;
        padding-left: 0; }
        .insurer-card .insurance-description li:before {
          display: none; }
    .insurer-card .see-details {
      font-size: 12px;
      text-align: left;
      padding-left: 0; }
    .insurer-card .button {
      font-size: 12px;
      padding: 7px 0px;
      letter-spacing: 0;
      border-radius: 2px;
      font-family: 'sukh-regular', sans-serif; }
    .insurer-card .ui.checkbox label {
      font-size: 12px; }
  .insurer-card-new {
    border-radius: 0;
    margin-bottom: 8px;
    border: 0;
    padding-bottom: 0; }
    .insurer-card-new .see-details {
      font-size: 10px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1d2785;
      padding: 0;
      margin-bottom: 0;
      display: inline-block;
      margin: 0 0 4px 0;
      position: relative;
      bottom: auto; }
    .insurer-card-new .header {
      padding: 5px 8px;
      border-radius: 0; }
      .insurer-card-new .header .restriction-text {
        position: relative;
        font-size: 8px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 0;
        right: -8px;
        top: auto;
        margin: 2px 0;
        max-width: 100px; }
      .insurer-card-new .header .img-wrap {
        min-width: auto;
        margin-right: 20px;
        max-width: 50px;
        overflow: hidden; }
        .insurer-card-new .header .img-wrap img {
          max-height: 40px; }
        .insurer-card-new .header .img-wrap:after {
          display: none; }
      .insurer-card-new .header .details-wrap {
        padding: 0;
        position: relative;
        display: flex;
        align-items: flex-start; }
        .insurer-card-new .header .details-wrap div .see-details {
          position: relative; }
        .insurer-card-new .header .details-wrap div:first-child {
          flex-grow: 1;
          margin-right: 8px;
          flex-grow: 1; }
        .insurer-card-new .header .details-wrap div:last-child {
          text-align: right;
          max-width: 100px;
          min-width: 100px; }
        .insurer-card-new .header .details-wrap .text-one {
          font-size: 12px;
          color: #414141;
          text-align: left;
          margin-bottom: 4px;
          font-family: 'sukh-regular'; }
        .insurer-card-new .header .details-wrap .text-two {
          font-size: 16px;
          font-family: 'sukh-bold';
          text-align: left;
          color: #4a4a4a; }
          .insurer-card-new .header .details-wrap .text-two span {
            font-family: 'sukh-regular'; }
        .insurer-card-new .header .details-wrap img {
          position: absolute;
          top: 4px;
          right: 8px;
          width: 16px;
          height: 16px; }
    .insurer-card-new .column-wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0;
      padding: 10px 14px;
      align-items: center; }
      .insurer-card-new .column-wrap.single-column-wrap {
        grid-template-columns: repeat(1, 1fr); }
      .insurer-card-new .column-wrap .col .text {
        font-size: 12px;
        color: #414141; }
      .insurer-card-new .column-wrap .col .key {
        font-size: 10px;
        text-align: center;
        color: #9e9d9d;
        margin-bottom: 4px; }
      .insurer-card-new .column-wrap .col .value {
        font-size: 12px;
        text-align: center;
        color: #4a4a4a; }
    .insurer-card-new .buttons-wrap {
      margin: 4px 0 0;
      position: relative;
      bottom: 0; }
      .insurer-card-new .buttons-wrap div {
        padding: 4px 8px 8px 8px; }
        .insurer-card-new .buttons-wrap div:last-child {
          text-align: right;
          padding: 4px 16px 8px 8px; }
      .insurer-card-new .buttons-wrap .button {
        padding: 5px 24px;
        width: auto; }
      .insurer-card-new .buttons-wrap .ui.checkbox label {
        font-size: 12px;
        font-family: 'sukh-bold'; }
        .insurer-card-new .buttons-wrap .ui.checkbox label:before {
          border: solid 2px #4a4a4a; }
      .insurer-card-new .buttons-wrap .ui.checkbox input:checked ~ .box:after,
      .insurer-card-new .buttons-wrap .ui.checkbox input:checked ~ label:after {
        border: solid 2px #4a4a4a;
        background: #4a4a4a; }
    .insurer-card-new .discount-wrap {
      margin: 0;
      padding: 0 12px; }
    .insurer-card-new .extra-info-wrap {
      position: relative;
      border-radius: 0;
      padding: 0; }
      .insurer-card-new .extra-info-wrap p {
        font-size: 12px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #dedede;
        padding: 12px 14px; }
        .insurer-card-new .extra-info-wrap p span {
          font-size: 10px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff; }
      .insurer-card-new .extra-info-wrap .content {
        background: #fff;
        border: solid 1px #555555;
        border-top: 0;
        padding: 8px 14px;
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -ms-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
        display: none; }
        .insurer-card-new .extra-info-wrap .content.show {
          display: block; }
        .insurer-card-new .extra-info-wrap .content p {
          font-size: 10px;
          font-family: 'sukh-medium';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a;
          padding: 0;
          text-align: left; }
          .insurer-card-new .extra-info-wrap .content p span {
            font-size: 10px;
            font-family: 'sukh-bold';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #00ad87; } }

.quick-view-wrapper,
.package-details {
  background-color: rgba(77, 92, 116, 0.8);
  width: 100%;
  min-height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  padding: 57px 0 10px 0; }
  .quick-view-wrapper.tokenised .quick-view-content .insurer-details-wrap,
  .package-details.tokenised .quick-view-content .insurer-details-wrap {
    padding-top: 124px; }
  .quick-view-wrapper.hide,
  .package-details.hide {
    display: none; }
  .quick-view-wrapper .quick-view-content,
  .package-details .quick-view-content {
    position: relative;
    max-width: 830px;
    margin: 0 auto;
    height: 100%; }
    .quick-view-wrapper .quick-view-content.container,
    .package-details .quick-view-content.container {
      padding: 0; }
    .quick-view-wrapper .quick-view-content .extra-info,
    .package-details .quick-view-content .extra-info {
      padding-bottom: 16px; }
      .quick-view-wrapper .quick-view-content .extra-info .list,
      .package-details .quick-view-content .extra-info .list {
        font-size: 14px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000; }
        .quick-view-wrapper .quick-view-content .extra-info .list span,
        .package-details .quick-view-content .extra-info .list span {
          font-size: 8px;
          display: inline-block;
          margin-right: 4px; }
    .quick-view-wrapper .quick-view-content .insurer-card-new,
    .package-details .quick-view-content .insurer-card-new {
      max-width: 280px;
      position: absolute;
      z-index: 1;
      top: 5px; }
    .quick-view-wrapper .quick-view-content .insurer-details-wrap,
    .package-details .quick-view-content .insurer-details-wrap {
      min-height: 500px;
      border-radius: 12px;
      background-color: #ffffff;
      box-shadow: 1px 1px 11px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #ecebeb;
      left: 260px;
      position: relative;
      padding: 30px 42px;
      width: calc(100% - 260px);
      height: 100%;
      overflow: auto; }
      .quick-view-wrapper .quick-view-content .insurer-details-wrap::-webkit-scrollbar,
      .package-details .quick-view-content .insurer-details-wrap::-webkit-scrollbar {
        width: 3px; }
      .quick-view-wrapper .quick-view-content .insurer-details-wrap::-webkit-scrollbar-track,
      .package-details .quick-view-content .insurer-details-wrap::-webkit-scrollbar-track {
        -webkit-box-shadow: #f4f4f4; }
      .quick-view-wrapper .quick-view-content .insurer-details-wrap::-webkit-scrollbar-thumb,
      .package-details .quick-view-content .insurer-details-wrap::-webkit-scrollbar-thumb {
        background-color: rgba(22, 43, 106, 0.05);
        outline: 1px solid rgba(22, 43, 106, 0.05); }
      .quick-view-wrapper .quick-view-content .insurer-details-wrap .close,
      .package-details .quick-view-content .insurer-details-wrap .close {
        position: absolute;
        right: 25px;
        top: 30px;
        cursor: pointer; }
      .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid,
      .package-details .quick-view-content .insurer-details-wrap .ui.grid {
        margin: 0 0;
        padding: 20px 0; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .column,
        .package-details .quick-view-content .insurer-details-wrap .ui.grid .column {
          margin: 0;
          padding: 0;
          margin-bottom: 15px; }
          .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .column.no-margin,
          .package-details .quick-view-content .insurer-details-wrap .ui.grid .column.no-margin {
            margin-bottom: 0; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section,
        .package-details .quick-view-content .insurer-details-wrap .ui.grid .section {
          margin-top: 7px;
          position: relative;
          padding: 5px 0px; }
          .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .title-wrap,
          .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .title-wrap {
            background: #f5f4f4;
            padding: 5px;
            margin-bottom: 15px !important; }
            .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .title-wrap .text-one,
            .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .title-wrap .text-one {
              font-size: 12px;
              margin: 0;
              padding: 0 15px;
              font-family: 'sukh-bold';
              color: #474f9c; }
          .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .content,
          .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .content {
            padding: 0px 20px 15px 12px;
            text-align: left; }
            .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .content .text-one,
            .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .content .text-one {
              padding-left: 20px;
              font-size: 12px;
              font-family: 'sukh-regular';
              color: rgba(0, 0, 0, 0.85); }
              .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .content .text-one.bold,
              .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .content .text-one.bold {
                font-family: 'sukh-bold';
                line-height: 1.8; }
            .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular,
            .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular {
              padding: 0; }
              .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row,
              .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row {
                display: flex;
                align-items: stretch; }
                .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row:not(:last-child),
                .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row:not(:last-child) {
                  border-bottom: solid 1px #eaeaea; }
                .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row:last-child .col,
                .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row:last-child .col {
                  padding-bottom: 14px; }
                .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row .col,
                .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row .col {
                  flex-basis: 100%;
                  flex-grow: 1;
                  padding: 5px 16px 5px 12px; }
                  .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row .col:not(:last-child),
                  .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row .col:not(:last-child) {
                    border-right: solid 1px #eaeaea; }
                  .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row .col:not(:first-child),
                  .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row .col:not(:first-child) {
                    padding-left: 12px; }
                  .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row .col .key-value,
                  .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row .col .key-value {
                    display: flex; }
                    .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row .col .key-value p:not(:first-child),
                    .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row .col .key-value p:not(:first-child) {
                      flex-grow: 1;
                      text-align: right; }
                    .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row .col .key-value > div,
                    .package-details .quick-view-content .insurer-details-wrap .ui.grid .section .content.tabular > .row .col .key-value > div {
                      flex-grow: 1;
                      text-align: right;
                      min-width: 35%; }
      .quick-view-wrapper .quick-view-content .insurer-details-wrap .each-wrap,
      .package-details .quick-view-content .insurer-details-wrap .each-wrap {
        border-bottom: dashed 1px #ecebeb; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .each-wrap:last-child,
        .package-details .quick-view-content .insurer-details-wrap .each-wrap:last-child {
          border-bottom: 0; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .each-wrap.title-wrap,
        .package-details .quick-view-content .insurer-details-wrap .each-wrap.title-wrap {
          border: 0;
          padding-bottom: 5px; }
          .quick-view-wrapper .quick-view-content .insurer-details-wrap .each-wrap.title-wrap .column,
          .package-details .quick-view-content .insurer-details-wrap .each-wrap.title-wrap .column {
            margin-bottom: 0; }
      .quick-view-wrapper .quick-view-content .insurer-details-wrap .title-text,
      .package-details .quick-view-content .insurer-details-wrap .title-text {
        font-size: 18px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #474f9c;
        margin-bottom: 12px;
        text-transform: uppercase; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .title-text.with-margin,
        .package-details .quick-view-content .insurer-details-wrap .title-text.with-margin {
          margin-bottom: 10px; }
      .quick-view-wrapper .quick-view-content .insurer-details-wrap .sub-title-text,
      .package-details .quick-view-content .insurer-details-wrap .sub-title-text {
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #474f9c; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .sub-title-text.with-margin,
        .package-details .quick-view-content .insurer-details-wrap .sub-title-text.with-margin {
          margin-bottom: 10px; }
      .quick-view-wrapper .quick-view-content .insurer-details-wrap .text,
      .package-details .quick-view-content .insurer-details-wrap .text {
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a;
        margin: 0; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .text.pre-wrap,
        .package-details .quick-view-content .insurer-details-wrap .text.pre-wrap {
          white-space: pre-wrap; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .text a,
        .package-details .quick-view-content .insurer-details-wrap .text a {
          color: #192383; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .text.bold,
        .package-details .quick-view-content .insurer-details-wrap .text.bold {
          font-family: 'sukh-medium'; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .text.with-margin,
        .package-details .quick-view-content .insurer-details-wrap .text.with-margin {
          margin-bottom: 15px; }

@media screen and (max-width: 1024px) {
  .quick-view-wrapper,
  .package-details {
    padding: 56px 0 0;
    background: #fff;
    overflow-y: auto; }
    .quick-view-wrapper .quick-view-content,
    .package-details .quick-view-content {
      padding: 0; }
      .quick-view-wrapper .quick-view-content .extra-info,
      .package-details .quick-view-content .extra-info {
        padding: 15px; }
        .quick-view-wrapper .quick-view-content .extra-info .title-text,
        .package-details .quick-view-content .extra-info .title-text {
          font-size: 14px; }
        .quick-view-wrapper .quick-view-content .extra-info .list,
        .package-details .quick-view-content .extra-info .list {
          font-size: 12px;
          font-family: 'sukh-medium';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000; }
          .quick-view-wrapper .quick-view-content .extra-info .list span,
          .package-details .quick-view-content .extra-info .list span {
            font-size: 8px;
            display: inline-block;
            margin-right: 4px; }
      .quick-view-wrapper .quick-view-content .insurer-card-new,
      .package-details .quick-view-content .insurer-card-new {
        width: 100%;
        max-width: 100%;
        margin: 0;
        box-shadow: 0 5px 12px 0 rgba(217, 226, 233, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px rgba(219, 223, 227, 0.3);
        background-color: #ffffff;
        position: relative;
        left: 0;
        right: 0; }
        .quick-view-wrapper .quick-view-content .insurer-card-new .discount-wrap,
        .package-details .quick-view-content .insurer-card-new .discount-wrap {
          display: none; }
        .quick-view-wrapper .quick-view-content .insurer-card-new .see-details,
        .package-details .quick-view-content .insurer-card-new .see-details {
          display: none; }
        .quick-view-wrapper .quick-view-content .insurer-card-new .extra-info-wrap,
        .package-details .quick-view-content .insurer-card-new .extra-info-wrap {
          display: none; }
        .quick-view-wrapper .quick-view-content .insurer-card-new .discount-wrap,
        .package-details .quick-view-content .insurer-card-new .discount-wrap {
          margin-bottom: 16px; }
        .quick-view-wrapper .quick-view-content .insurer-card-new .buttons-wrap,
        .package-details .quick-view-content .insurer-card-new .buttons-wrap {
          border-bottom: 1px solid #f0f0f5; }
        .quick-view-wrapper .quick-view-content .insurer-card-new .header .restriction-text,
        .package-details .quick-view-content .insurer-card-new .header .restriction-text {
          display: inline-block;
          margin: 0 0 8px 0; }
      .quick-view-wrapper .quick-view-content .insurer-details-wrap,
      .package-details .quick-view-content .insurer-details-wrap {
        width: 100%;
        left: 0;
        padding: 0;
        padding-bottom: 100px;
        overflow: hidden;
        height: auto;
        border-radius: 0; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap.reduced-padding-top,
        .package-details .quick-view-content .insurer-details-wrap.reduced-padding-top {
          padding-top: 120px; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap[lang='thai'],
        .package-details .quick-view-content .insurer-details-wrap[lang='thai'] {
          padding-top: 184px; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .ui.grid,
        .package-details .quick-view-content .insurer-details-wrap .ui.grid {
          padding: 0; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .close,
        .package-details .quick-view-content .insurer-details-wrap .close {
          display: none; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap > .title-text,
        .package-details .quick-view-content .insurer-details-wrap > .title-text {
          display: none; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .text,
        .package-details .quick-view-content .insurer-details-wrap .text {
          font-size: 12px; }
          .quick-view-wrapper .quick-view-content .insurer-details-wrap .text img,
          .package-details .quick-view-content .insurer-details-wrap .text img {
            max-width: 15px; }
        .quick-view-wrapper .quick-view-content .insurer-details-wrap .each-wrap,
        .package-details .quick-view-content .insurer-details-wrap .each-wrap {
          border: 0;
          margin-bottom: 15px !important; }
          .quick-view-wrapper .quick-view-content .insurer-details-wrap .each-wrap.with-line,
          .package-details .quick-view-content .insurer-details-wrap .each-wrap.with-line {
            position: relative;
            padding-top: 12px;
            margin-top: 0; }
            .quick-view-wrapper .quick-view-content .insurer-details-wrap .each-wrap.with-line:before,
            .package-details .quick-view-content .insurer-details-wrap .each-wrap.with-line:before {
              content: '';
              width: calc(100% - 30px);
              margin: auto;
              left: 0;
              right: 0;
              top: 0;
              height: 1px;
              border-top: solid 1px #d7d7d7;
              position: absolute; }
          .quick-view-wrapper .quick-view-content .insurer-details-wrap .each-wrap .sixteen.wide,
          .package-details .quick-view-content .insurer-details-wrap .each-wrap .sixteen.wide {
            background: #f5f4f4;
            padding: 5px;
            margin-bottom: 15px !important; }
            .quick-view-wrapper .quick-view-content .insurer-details-wrap .each-wrap .sixteen.wide .title-text,
            .package-details .quick-view-content .insurer-details-wrap .each-wrap .sixteen.wide .title-text {
              font-size: 12px;
              margin: 0;
              padding: 0 15px;
              font-family: 'sukh-bold'; }
              .quick-view-wrapper .quick-view-content .insurer-details-wrap .each-wrap .sixteen.wide .title-text.with-margin,
              .package-details .quick-view-content .insurer-details-wrap .each-wrap .sixteen.wide .title-text.with-margin {
                margin-bottom: 0; }
          .quick-view-wrapper .quick-view-content .insurer-details-wrap .each-wrap .ten.wide,
          .package-details .quick-view-content .insurer-details-wrap .each-wrap .ten.wide {
            padding-left: 15px;
            max-width: 50%; }
          .quick-view-wrapper .quick-view-content .insurer-details-wrap .each-wrap .six.wide,
          .package-details .quick-view-content .insurer-details-wrap .each-wrap .six.wide {
            padding-right: 15px;
            max-width: 50%;
            min-width: 50%; }
            .quick-view-wrapper .quick-view-content .insurer-details-wrap .each-wrap .six.wide .text,
            .package-details .quick-view-content .insurer-details-wrap .each-wrap .six.wide .text {
              text-align: right; }
  .package-details .quick-view-content .insurer-details-wrap {
    padding-bottom: 140px; } }

.insurer-comparison-card {
  background: #fff; }
  .insurer-comparison-card .header {
    background-color: #e8f8fe;
    padding: 30px 24px 12px 24px;
    margin-bottom: 8px; }
    .insurer-comparison-card .header .flex-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 12px; }
    .insurer-comparison-card .header .img-wrap {
      padding-right: 12px;
      position: relative; }
      .insurer-comparison-card .header .img-wrap:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 18px;
        background: rgba(151, 151, 151, 0.4);
        right: 0;
        top: calc(50% - 9px); }
      .insurer-comparison-card .header .img-wrap img {
        width: 40px;
        height: 40px; }
    .insurer-comparison-card .header .details-wrap {
      flex-grow: 1;
      padding-left: 12px; }
      .insurer-comparison-card .header .details-wrap .text {
        font-size: 12px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        text-align: left; }
    .insurer-comparison-card .header .ins-type {
      font-size: 12px;
      text-align: left;
      color: #414141; }
  .insurer-comparison-card .price-details-wrap {
    padding: 0 24px; }
    .insurer-comparison-card .price-details-wrap .title-text {
      font-size: 14px;
      font-family: 'sukh-bold';
      color: #4a4a4a;
      margin-bottom: 12px; }
    .insurer-comparison-card .price-details-wrap .text {
      font-size: 12px;
      color: #4a4a4a;
      display: flex;
      align-items: center;
      margin-bottom: 8px; }
      .insurer-comparison-card .price-details-wrap .text span:last-child {
        flex-grow: 1;
        text-align: right; }
      .insurer-comparison-card .price-details-wrap .text .bold {
        font-size: 12px;
        font-family: 'sukh-bold';
        color: #4a4a4a; }
  .insurer-comparison-card .line {
    padding-top: 4px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.4); }
  .insurer-comparison-card .button-wrap {
    margin: 14px 0 32px 0;
    padding: 0 24px; }
    .insurer-comparison-card .button-wrap .show-more {
      display: none; }
    .insurer-comparison-card .button-wrap .button {
      text-transform: none; }

.compare-card-mini {
  padding: 16px 20px;
  position: relative;
  font-family: 'sukh-regular';
  border-bottom: 1px solid #f0f0f5;
  margin-bottom: 12px; }
  .compare-card-mini:not(:last-child) {
    border-right: 1px solid #f0f0f5; }
  .compare-card-mini .close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    max-width: 16px; }
  .compare-card-mini .img-wrap {
    width: 40px;
    height: 40px;
    margin-bottom: 8px; }
    .compare-card-mini .img-wrap img {
      max-width: 40px; }
  .compare-card-mini .insurer-name,
  .compare-card-mini .plan-name {
    font-size: 14px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4a4a4a; }
  .compare-card-mini .plan-name {
    margin-bottom: 8px; }
  .compare-card-mini .premium-text,
  .compare-card-mini .insurance-type {
    font-size: 14px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a; }
    .compare-card-mini .premium-text span.number,
    .compare-card-mini .insurance-type span.number {
      font-family: 'sukh-bold';
      display: inline-block;
      margin: 0 4px; }
  .compare-card-mini .button.medium {
    font-size: 10px;
    padding: 6px 12px;
    margin-top: 8px; }

@media screen and (max-width: 1024px) {
  .insurer-comparison-card {
    background: #fff; }
    .insurer-comparison-card .header {
      margin-bottom: 12px; }
    .insurer-comparison-card .button-wrap {
      padding: 16px 24px;
      margin: 0;
      display: flex; }
      .insurer-comparison-card .button-wrap .button {
        width: calc(50% - 4px); }
        .insurer-comparison-card .button-wrap .button:first-child {
          margin-right: 4px;
          background: #fff;
          border: 1px solid #192383;
          color: #192383; }
        .insurer-comparison-card .button-wrap .button:last-child {
          margin-left: 4px;
          background: #192383;
          color: #fff; }
      .insurer-comparison-card .button-wrap .show-more {
        display: inline-block; }
    .insurer-comparison-card .card-details-wrapper {
      position: relative;
      padding: 0 8px; }
      .insurer-comparison-card .card-details-wrapper .each-wrap .header-wrap {
        padding: 10px 16px;
        background: #f8f8f8;
        cursor: pointer;
        position: relative; }
        .insurer-comparison-card .card-details-wrapper .each-wrap .header-wrap .arrow {
          position: absolute;
          right: 40px;
          top: 22px; }
          .insurer-comparison-card .card-details-wrapper .each-wrap .header-wrap .arrow.rotate {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg); }
        .insurer-comparison-card .card-details-wrapper .each-wrap .header-wrap .title-text {
          font-size: 12px;
          font-family: 'sukh-medium';
          letter-spacing: 1px;
          color: #1b1b1b;
          text-transform: uppercase; }
      .insurer-comparison-card .card-details-wrapper .each-wrap .content-wrap {
        padding: 16px 16px 4px 16px; }
        .insurer-comparison-card .card-details-wrapper .each-wrap .content-wrap .key-value-wrap {
          display: flex;
          margin-bottom: 12px; }
          .insurer-comparison-card .card-details-wrapper .each-wrap .content-wrap .key-value-wrap .key,
          .insurer-comparison-card .card-details-wrapper .each-wrap .content-wrap .key-value-wrap .value {
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1b1b1b; }
          .insurer-comparison-card .card-details-wrapper .each-wrap .content-wrap .key-value-wrap .value {
            text-align: right;
            flex-grow: 1; } }

.comparison-header-wrap {
  background: #fff; }
  .comparison-header-wrap.with-mini-card + div .insurer-details-wrapper {
    padding-top: 202px; }
    .comparison-header-wrap.with-mini-card + div .insurer-details-wrapper.responsive {
      padding-top: 120px; }
  .comparison-header-wrap.with-mini-card .comparison-card-wrapper {
    position: fixed;
    z-index: 2;
    top: 62px;
    left: 0;
    right: 0;
    padding: 0 81px; }
  .comparison-header-wrap.with-shadow {
    box-shadow: 0 5px 11px 0 rgba(162, 160, 160, 0.36); }
  .comparison-header-wrap .comparison-card-wrapper > .column-wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0;
    background: #fff;
    border-bottom: 1px solid rgba(151, 151, 151, 0.2); }
    .comparison-header-wrap .comparison-card-wrapper > .column-wrap .column {
      border-right: 1px solid rgba(151, 151, 151, 0.2); }
      .comparison-header-wrap .comparison-card-wrapper > .column-wrap .column:last-child {
        border-right: 0; }
    .comparison-header-wrap .comparison-card-wrapper > .column-wrap .filter-wrapper {
      padding: 40px 24px; }
      .comparison-header-wrap .comparison-card-wrapper > .column-wrap .filter-wrapper .title-text {
        font-size: 12px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #1b1b1b;
        text-transform: uppercase;
        margin-bottom: 24px; }
      .comparison-header-wrap .comparison-card-wrapper > .column-wrap .filter-wrapper .ui.selection.dropdown {
        border: 1px solid #cbcbcb;
        background: transparent;
        height: auto;
        min-height: auto;
        padding: 5px 10px;
        border-radius: 2px;
        min-width: 150px;
        width: 100%;
        border: solid 1px #192383;
        border-radius: 6px; }
        .comparison-header-wrap .comparison-card-wrapper > .column-wrap .filter-wrapper .ui.selection.dropdown .menu {
          max-height: 180px; }
          .comparison-header-wrap .comparison-card-wrapper > .column-wrap .filter-wrapper .ui.selection.dropdown .menu .item {
            padding: 8px !important; }
        .comparison-header-wrap .comparison-card-wrapper > .column-wrap .filter-wrapper .ui.selection.dropdown .text {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #5f5f5f;
          margin: 0; }
        .comparison-header-wrap .comparison-card-wrapper > .column-wrap .filter-wrapper .ui.selection.dropdown .icon {
          padding: 6px 10px;
          margin: 0;
          top: 0;
          right: 0; }
  .comparison-header-wrap .comparison-card-wrapper .pdf-button-wrap {
    text-align: right;
    padding: 4px;
    display: block; }
    .comparison-header-wrap .comparison-card-wrapper .pdf-button-wrap .button {
      padding: 4px;
      font-size: 10px; }
  .comparison-header-wrap .comparison-card-wrapper .empty-card {
    text-align: center;
    position: relative;
    padding: 18px 10px;
    background-color: #fff;
    width: 100%;
    height: 100%; }
    .comparison-header-wrap .comparison-card-wrapper .empty-card p.text {
      font-family: 'sukh-regular', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #9b9b9b;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer; }
      .comparison-header-wrap .comparison-card-wrapper .empty-card p.text span {
        display: inline-block;
        margin-right: 10px; }

@media screen and (max-width: 1024px) {
  .comparison-header-wrap {
    background-color: transparent;
    padding: 0;
    top: 56px;
    box-shadow: none; }
    .comparison-header-wrap.with-shadow {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.29); }
    .comparison-header-wrap .breadcrumb {
      display: none; }
    .comparison-header-wrap .comparison-card-wrapper > .column-wrap {
      display: block;
      background: transparent;
      border: 0; }
      .comparison-header-wrap .comparison-card-wrapper > .column-wrap .column {
        margin-bottom: 12px;
        border: 0; }
      .comparison-header-wrap .comparison-card-wrapper > .column-wrap .filter-wrapper {
        padding: 12px 24px;
        margin-bottom: 12px;
        background: #fff; }
        .comparison-header-wrap .comparison-card-wrapper > .column-wrap .filter-wrapper .title-text {
          display: none; }
        .comparison-header-wrap .comparison-card-wrapper > .column-wrap .filter-wrapper .ui.form .field {
          margin: 0;
          padding: 16px 0; }
          .comparison-header-wrap .comparison-card-wrapper > .column-wrap .filter-wrapper .ui.form .field:not(:last-child) {
            border-bottom: 1px solid #f0f0f5; }
    .comparison-header-wrap .comparison-card-wrapper.container {
      padding: 0; }
      .comparison-header-wrap .comparison-card-wrapper.container .empty-card {
        min-height: 160px; }
      .comparison-header-wrap .comparison-card-wrapper.container .grid .column {
        width: 50% !important;
        padding: 5px; }
        .comparison-header-wrap .comparison-card-wrapper.container .grid .column:first-child {
          display: none; } }

.fairdee-modal-wrapper {
  background-color: rgba(77, 92, 116, 0.8);
  width: 100%;
  min-height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1300;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden; }
  .fairdee-modal-wrapper.grey {
    background-color: rgba(77, 92, 116, 0.9); }
  .fairdee-modal-wrapper.youtube-player {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(77, 92, 116, 0.9);
    z-index: 1299;
    display: flex;
    align-items: center;
    justify-content: center; }
    .fairdee-modal-wrapper.youtube-player .modal-content {
      width: 722px;
      height: 491px;
      background: #fff;
      border-radius: 12px;
      position: relative;
      margin: 0;
      min-height: auto;
      border: 0;
      padding: 40px 12px;
      max-width: inherit; }
      .fairdee-modal-wrapper.youtube-player .modal-content .youtube-loading {
        position: absolute;
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center; }
      .fairdee-modal-wrapper.youtube-player .modal-content .close {
        background: #fff;
        border-radius: 15px;
        padding: 6px;
        top: -22px;
        right: -22px; }
      .fairdee-modal-wrapper.youtube-player .modal-content #yt-player-thumb {
        position: relative;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center; }
      .fairdee-modal-wrapper.youtube-player .modal-content .custom-close {
        font-size: 20px;
        width: 20px;
        height: 20px;
        border-radius: 12px;
        position: absolute;
        text-align: center;
        background-color: #fff;
        right: 24%;
        top: -4%;
        cursor: pointer; }
      .fairdee-modal-wrapper.youtube-player .modal-content .youtube-player {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 0;
        height: 0;
        overflow: hidden;
        width: 100%; }
      .fairdee-modal-wrapper.youtube-player .modal-content .youtube-player iframe,
      .fairdee-modal-wrapper.youtube-player .modal-content .youtube-player object,
      .fairdee-modal-wrapper.youtube-player .modal-content .youtube-player embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
  .fairdee-modal-wrapper::-webkit-scrollbar {
    width: 1px; }
  .fairdee-modal-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px #efefef; }
  .fairdee-modal-wrapper::-webkit-scrollbar-thumb {
    background-color: #efefef;
    outline: 1px solid #efefef; }
  .fairdee-modal-wrapper.file-viewer.med .modal-content {
    max-width: 60%;
    margin: 60px auto; }
  .fairdee-modal-wrapper.file-viewer.medium-large .modal-content {
    max-width: 60%;
    margin: 60px auto; }
    .fairdee-modal-wrapper.file-viewer.medium-large .modal-content .title-text {
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000; }
    .fairdee-modal-wrapper.file-viewer.medium-large .modal-content .tabs .tab {
      min-width: auto;
      line-height: 1.6;
      font-size: 16px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: rgba(168, 167, 167, 0.85);
      text-transform: none;
      min-height: auto;
      padding-bottom: 4px; }
      .fairdee-modal-wrapper.file-viewer.medium-large .modal-content .tabs .tab[aria-selected='true'] {
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgba(0, 0, 0, 0.85); }
      .fairdee-modal-wrapper.file-viewer.medium-large .modal-content .tabs .tab:not(:last-child) {
        margin-right: 40px; }
      .fairdee-modal-wrapper.file-viewer.medium-large .modal-content .tabs .tab > span > span {
        padding: 0; }
    .fairdee-modal-wrapper.file-viewer.medium-large .modal-content .tabs > div > div > span {
      background-color: #f58b1e; }
    .fairdee-modal-wrapper.file-viewer.medium-large .modal-content .payment-items {
      display: flex; }
      .fairdee-modal-wrapper.file-viewer.medium-large .modal-content .payment-items .payment-item {
        box-shadow: 0px 1px 6px rgba(216, 231, 253, 0.5);
        background: #fff;
        border-radius: 8px;
        padding: 16px;
        margin-right: 8px;
        width: 100%; }
        .fairdee-modal-wrapper.file-viewer.medium-large .modal-content .payment-items .payment-item .payment-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 16px 0; }
        .fairdee-modal-wrapper.file-viewer.medium-large .modal-content .payment-items .payment-item .payment-title {
          font-weight: bold;
          margin-bottom: 16px; }
        .fairdee-modal-wrapper.file-viewer.medium-large .modal-content .payment-items .payment-item .data {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #d3dded;
          padding-bottom: 8px;
          margin-bottom: 12px; }
  .fairdee-modal-wrapper.file-viewer .modal-content {
    position: relative; }
    .fairdee-modal-wrapper.file-viewer .modal-content .title-text {
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000; }
    .fairdee-modal-wrapper.file-viewer .modal-content .zoom-btn,
    .fairdee-modal-wrapper.file-viewer .modal-content .download-btn {
      border-radius: 4px;
      background-color: #192383;
      font-size: 12px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      padding: 6px 12px;
      position: absolute;
      right: 16px;
      top: 48px;
      outline: none;
      border: 0;
      cursor: pointer; }
    .fairdee-modal-wrapper.file-viewer .modal-content .ai-result-text {
      font-size: 16px;
      font-weight: 900;
      color: red;
      padding: 10px; }
    .fairdee-modal-wrapper.file-viewer .modal-content .download-btn {
      right: 120px; }
    .fairdee-modal-wrapper.file-viewer .modal-content .file-view {
      height: 300px;
      border-radius: 2px;
      width: 100%;
      margin: 48px 0 24px 0;
      position: relative; }
      .fairdee-modal-wrapper.file-viewer .modal-content .file-view.zoom {
        overflow: auto; }
        .fairdee-modal-wrapper.file-viewer .modal-content .file-view.zoom img {
          width: auto;
          height: auto;
          object-fit: none; }
      .fairdee-modal-wrapper.file-viewer .modal-content .file-view img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .fairdee-modal-wrapper.file-viewer .modal-content .button-wrap {
      display: flex; }
      .fairdee-modal-wrapper.file-viewer .modal-content .button-wrap button,
      .fairdee-modal-wrapper.file-viewer .modal-content .button-wrap .button {
        font-size: 12px;
        font-weight: 900;
        text-transform: capitalize;
        flex-grow: 1;
        flex-basis: 100%; }
        .fairdee-modal-wrapper.file-viewer .modal-content .button-wrap button:first-child,
        .fairdee-modal-wrapper.file-viewer .modal-content .button-wrap .button:first-child {
          margin-right: 5px; }
      .fairdee-modal-wrapper.file-viewer .modal-content .button-wrap.centered {
        justify-content: center; }
        .fairdee-modal-wrapper.file-viewer .modal-content .button-wrap.centered .button {
          margin-top: 12px;
          max-width: fit-content; }
    .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper {
      margin-top: 30px;
      border-top: solid 1px #eeeeee;
      padding: 24px 0; }
      .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .errorBankRefNo {
        background-color: #f8d7da;
        padding: 16px;
        font-size: 14px;
        border-radius: 4px;
        margin-bottom: 14px;
        font-weight: bold;
        width: 100%; }
        .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .errorBankRefNo .qoutationLink {
          color: #721c24; }
        .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .errorBankRefNo:hover {
          text-decoration: underline; }
      .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field {
        position: relative;
        padding-top: 24px;
        margin-bottom: 16px;
        display: block; }
        .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field .number-format label {
          position: relative;
          display: block; }
        .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field .number-format > div {
          margin-top: 10px;
          width: 100%; }
          .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field .number-format > div:before, .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field .number-format > div:after {
            display: none; }
        .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field > .dropdown {
          border-radius: 2px;
          border: solid 1px #dcd9d6;
          background-color: #fff;
          padding: 9px 6px;
          position: relative;
          height: auto;
          line-height: 1;
          min-height: auto; }
          .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field > .dropdown .text {
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 0.85); }
            .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field > .dropdown .text .crm-tag {
              margin-left: 50px;
              border: solid 2px #417505;
              border-radius: 20px;
              padding: 0px 4px;
              color: #417505;
              font-family: 'sukh-medium';
              font-weight: 600; }
          .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field > .dropdown input.search {
            padding: 0 8px !important;
            height: 80%;
            top: 10%; }
          .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field > .dropdown.active .menu {
            border: solid 1px #dcd9d6; }
        .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field > div {
          display: block;
          margin: 0; }
          .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field > div::before, .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field > div::after {
            display: none; }
        .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field input {
          border-radius: 2px;
          border: solid 1px #dcd9d6;
          background-color: #fff;
          padding: 10px 6px;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.85);
          display: block;
          width: 100%;
          outline: none;
          box-sizing: border-box; }
          .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field input:focus {
            border: solid 1px #ff901d;
            background: #fffcf2; }
            .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field input:focus ~ label {
              font-size: 14px;
              font-weight: 900;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #ff901d; }
        .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field .leftLabel {
          left: 0; }
        .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field label {
          font-size: 12px;
          font-weight: 900;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #454346;
          position: absolute;
          top: 0;
          transform: none;
          transform-origin: 0; }
          .fairdee-modal-wrapper.file-viewer .modal-content .form-wrapper .form-field label[data-shrink='true'] {
            font-size: 12px;
            font-weight: 900;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #454346; }
  .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content {
    margin: 30px auto; }
    .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .title-text, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .title-text {
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383; }
    .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .sub-title-text, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .sub-title-text {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #4a4a4a;
      text-align: center; }
    .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .tabs .tab, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .tabs .tab {
      min-width: auto;
      line-height: 1.6;
      font-size: 16px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: rgba(168, 167, 167, 0.85);
      text-transform: none;
      min-height: auto;
      padding-bottom: 4px; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .tabs .tab[aria-selected='true'], .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .tabs .tab[aria-selected='true'] {
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgba(0, 0, 0, 0.85); }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .tabs .tab:not(:last-child), .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .tabs .tab:not(:last-child) {
        margin-right: 40px; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .tabs .tab > span > span, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .tabs .tab > span > span {
        padding: 0; }
    .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .tabs > div > div > span, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .tabs > div > div > span {
      background-color: #f58b1e; }
    .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .key-value, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .key-value {
      margin-bottom: 16px;
      display: table;
      width: 100%; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .key-value .profile-img-wrap, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .key-value .profile-img-wrap {
        text-align: center;
        margin: 16px auto;
        position: relative;
        width: 92px;
        height: 92px;
        overflow: hidden;
        border-radius: 100%; }
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .key-value .profile-img-wrap img, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .key-value .profile-img-wrap img {
          width: 100%;
          height: 100%;
          display: inline-block;
          object-fit: cover; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .key-value .file-input, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .key-value .file-input {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0;
        cursor: pointer;
        width: 100%; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .key-value .img-error, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .key-value .img-error {
        text-align: center;
        margin-bottom: 16px; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .key-value > span, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .key-value > span {
        display: table-cell;
        text-align: left;
        vertical-align: middle; }
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .key-value > span.key, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .key-value > span.key {
          width: 120px;
          font-size: 14px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: rgba(46, 46, 46, 0.85);
          font-family: 'sukh-regular'; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .key-value .value, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .key-value .value {
        position: relative;
        padding-left: 32px;
        display: table-cell;
        vertical-align: middle; }
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .key-value .value label > span:last-child, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .key-value .value label > span:last-child {
          font-family: 'sukh-regular';
          color: #5e5e5e;
          font-size: 12px; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .key-value .checkbox, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .key-value .checkbox {
        padding: 0 12px; }
    .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .section-title, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .section-title {
      text-align: center;
      font-family: sukh-bold; }
    .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field {
      margin: 0 0 8px 0;
      position: relative; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field.upload label, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field.upload label {
        padding: 8px 12px;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid #dedede;
        display: inline-block;
        font-size: 12px;
        margin: 0 5px; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .inline-error, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .inline-error {
        position: absolute;
        left: 0;
        top: 100%; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field.error input[type='text'],
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field.error input[type='number'],
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field.error input[type='password'], .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field.error input[type='text'],
      .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field.error input[type='number'],
      .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field.error input[type='password'] {
        border: 1px solid #d8001a; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .radio-group > label > span:first-child, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .radio-group > label > span:first-child {
        padding: 0 12px; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .radio-group > label > span:last-child, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .radio-group > label > span:last-child {
        font-family: 'sukh-regular';
        color: #5e5e5e;
        font-size: 12px; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .datepicker, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .datepicker {
        border-radius: 2px;
        background-color: #ffffff;
        border: 1px solid #cbcbcb;
        width: 100%; }
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .datepicker label, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .datepicker label {
          display: none; }
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .datepicker p, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .datepicker p {
          display: none; }
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .datepicker > div, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .datepicker > div {
          margin: 0; }
          .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .datepicker > div:before, .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .datepicker > div:after, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .datepicker > div:before, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .datepicker > div:after {
            display: none; }
          .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .datepicker > div > div > button, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .datepicker > div > div > button {
            padding: 0; }
          .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .datepicker > div input, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .datepicker > div input {
            border: 0;
            width: 100%;
            pointer-events: none; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .form-label, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .form-label {
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 1rem;
        line-height: 1; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .ui.selection.dropdown, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .ui.selection.dropdown {
        border: 1px solid #cbcbcb !important;
        border-radius: 2px !important;
        padding: 6px 10px;
        min-height: auto;
        min-width: auto;
        width: 100%; }
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .ui.selection.dropdown .menu > .item, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .ui.selection.dropdown .menu > .item {
          padding: 8px 12px !important; }
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .ui.selection.dropdown .text, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .ui.selection.dropdown .text {
          margin: 0 !important;
          font-size: 12px;
          color: #5f5f5f; }
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .ui.selection.dropdown .icon, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .ui.selection.dropdown .icon {
          top: 4px !important; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field.with-width, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field.with-width {
        width: 150px; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .form-label, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .form-label {
        display: none; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .label-input, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .label-input {
        border: solid 1px #cbcbcb;
        border-radius: 2px;
        display: flex;
        width: 100%; }
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .label-input input[type='text'],
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .label-input input[type='number'], .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .label-input input[type='text'],
        .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .label-input input[type='number'] {
          border: 0;
          width: 100%; }
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field .label-input label, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field .label-input label {
          padding: 5px;
          font-size: 12px;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #5f5f5f;
          border-right: 1px solid #cbcbcb; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field > label.form-label, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field > label.form-label {
        display: none; }
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field > label.form-label[lang='english'], .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field > label.form-label[lang='english'] {
          font-family: 'sukh-light';
          letter-spacing: 0; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field > p, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field > p {
        display: inline-block;
        vertical-align: middle;
        margin: 0 8px 0 0;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #5f5f5f;
        padding-left: 28px; }
        .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field > p.display-val-nopadding, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field > p.display-val-nopadding {
          padding-left: 0; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field input[type='text'],
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field input[type='number'],
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .form-field input[type='password'], .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field input[type='text'],
      .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field input[type='number'],
      .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .form-field input[type='password'] {
        border-radius: 2px;
        background-color: #ffffff;
        border: 1px solid #cbcbcb;
        padding: 5px 10px;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #5f5f5f;
        width: 100%;
        outline: none; }
    .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .buttons-wrap, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .buttons-wrap {
      position: relative;
      margin-top: 24px;
      width: 100%; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .buttons-wrap button, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .buttons-wrap button {
        width: 100%;
        text-transform: none; }
    .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .ant-space, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .ant-space {
      display: flex;
      justify-content: flex-end; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .ant-space .button, .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .ant-space .button {
        min-width: 150px; }
  .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content {
    padding: 14px; }
    .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .display-row {
      display: flex;
      align-items: center; }
      .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .display-row .display-col {
        padding: 0 6px; }
    .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .key-value {
      margin-bottom: 6px; }
      .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .key-value .value {
        padding: 0; }
      .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .key-value.display-col {
        max-width: 50%; }
    .fairdee-modal-wrapper.edit-affiliate-info.large .modal-content .arrow-down {
      display: none; }
  .fairdee-modal-wrapper.edit-affiliate-info.large .title-text {
    margin-bottom: 0;
    text-align: center; }
  .fairdee-modal-wrapper.edit-affiliate-info.small-medium .arrow-down {
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 24px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: MoveUpDown 2s linear infinite; }
    .fairdee-modal-wrapper.edit-affiliate-info.small-medium .arrow-down.green {
      border: 1px solid #0aa22e; }
    .fairdee-modal-wrapper.edit-affiliate-info.small-medium .arrow-down.orange {
      border: 1px solid #e02020; }

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0; }
  50% {
    bottom: 20px; } }
  .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content {
    padding: 32px 52px; }
    .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .desc-wrap {
      background-color: #fff6e8;
      padding: 10px;
      text-align: left;
      margin-bottom: 8px; }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .desc-wrap .header-text {
        font-size: 11px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        text-align: left;
        padding-left: 20px;
        position: relative;
        margin-bottom: 8px; }
        .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .desc-wrap .header-text:before {
          content: 'i';
          margin-right: 8px;
          width: 12px;
          height: 12px;
          border-radius: 12px;
          background: #f5a623;
          position: absolute;
          color: #fff;
          text-align: center;
          line-height: 1.2;
          vertical-align: middle;
          left: 0;
          top: 2px;
          font-size: 9px;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .desc-wrap .text {
        font-size: 11px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        text-align: left;
        position: relative;
        display: flex;
        margin-bottom: 4px; }
        .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .desc-wrap .text:before {
          content: '\2022';
          font-size: 12px;
          position: relative;
          margin-right: 24px;
          padding-left: 4px; }
    .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .with-link {
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      text-align: center;
      margin-top: 24px;
      margin-bottom: 8px;
      cursor: pointer; }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .with-link span {
        font-family: 'sukh-bold';
        color: #1d2785; }
    .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .social-action-wrap {
      margin-top: 36px;
      text-align: center; }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .social-action-wrap span {
        margin: 0 12px;
        display: inline-block; }
    .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .banner-img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
      margin-bottom: 24px; }
    .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .text-one {
      font-size: 19px;
      font-family: 'sukh-bold';
      text-align: center;
      color: #192383;
      line-height: 1.4; }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .text-one.with-margin {
        margin-bottom: 24px; }
    .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .text-two {
      font-size: 14px;
      line-height: 1.57;
      color: #4a4a4a;
      margin-bottom: 8px;
      position: relative;
      padding-left: 32px; }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .text-two:before {
        content: '';
        width: 16px;
        height: 16px;
        background: url("images/rounded-green-tick.svg") no-repeat;
        background-position: center;
        position: absolute;
        left: 0; }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .text-two span {
        color: #f58b1e;
        cursor: pointer; }
    .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .button-wrap {
      text-align: center; }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .button-wrap button {
        padding: 10px 48px; }
  .fairdee-modal-wrapper.full-screen {
    overflow-y: auto; }
    .fairdee-modal-wrapper.full-screen .modal-content {
      width: 100%;
      height: 100%;
      max-width: 100%;
      margin: 0;
      padding: 24px; }
      .fairdee-modal-wrapper.full-screen .modal-content .empty-wrapper {
        padding: 32px;
        text-align: center; }
        .fairdee-modal-wrapper.full-screen .modal-content .empty-wrapper img {
          max-width: 60px;
          margin-bottom: 16px; }
        .fairdee-modal-wrapper.full-screen .modal-content .empty-wrapper .title {
          font-size: 18px;
          font-family: 'sukh-medium';
          color: #4a4a4a;
          margin-bottom: 12px;
          text-align: center; }
        .fairdee-modal-wrapper.full-screen .modal-content .empty-wrapper .text {
          font-size: 14px;
          line-height: 1.43;
          color: #4a4a4a; }
      .fairdee-modal-wrapper.full-screen .modal-content .sale-type:not(:first-child) {
        margin-top: 24px; }
      .fairdee-modal-wrapper.full-screen .modal-content .title {
        margin-bottom: 32px;
        position: relative;
        text-align: left; }
        .fairdee-modal-wrapper.full-screen .modal-content .title.flex {
          display: flex; }
        .fairdee-modal-wrapper.full-screen .modal-content .title button {
          position: absolute;
          right: 48px;
          top: -20px; }
        .fairdee-modal-wrapper.full-screen .modal-content .title .inline-wrap {
          display: flex;
          position: absolute;
          right: 24px;
          top: -20px;
          align-items: flex-end; }
          .fairdee-modal-wrapper.full-screen .modal-content .title .inline-wrap > div {
            margin: 0 0 0 24px; }
          .fairdee-modal-wrapper.full-screen .modal-content .title .inline-wrap button {
            position: relative;
            right: auto;
            top: auto; }
      .fairdee-modal-wrapper.full-screen .modal-content .content-wrapper {
        height: calc(100% - 20px);
        overflow-y: auto; }
        .fairdee-modal-wrapper.full-screen .modal-content .content-wrapper .custom-table-wrapper > div {
          margin: 0; }
  .fairdee-modal-wrapper.province-modal {
    text-align: center; }
    .fairdee-modal-wrapper.province-modal .province-wrap {
      width: 100%;
      position: relative;
      text-align: left; }
    .fairdee-modal-wrapper.province-modal .modal-content .title-text {
      margin-bottom: 12px;
      font-size: 20px;
      font-family: 'sukh-bold'; }
    .fairdee-modal-wrapper.province-modal .modal-content .select-wrap {
      position: relative;
      margin-top: 40px;
      text-align: left; }
      .fairdee-modal-wrapper.province-modal .modal-content .select-wrap .inline-error {
        margin: 0; }
      .fairdee-modal-wrapper.province-modal .modal-content .select-wrap > div {
        width: 100%; }
    .fairdee-modal-wrapper.province-modal .modal-content .form-field {
      text-align: left;
      margin-top: 40px; }
    .fairdee-modal-wrapper.province-modal .sub-text {
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a; }
    .fairdee-modal-wrapper.province-modal .ui.form input[type='text'],
    .fairdee-modal-wrapper.province-modal .ui.form input[type='email'],
    .fairdee-modal-wrapper.province-modal .ui.form input[type='password'],
    .fairdee-modal-wrapper.province-modal .ui.form input[type='number'],
    .fairdee-modal-wrapper.province-modal .ui.form textarea {
      border: 0; }
    .fairdee-modal-wrapper.province-modal .button-wrap {
      margin-top: 20px;
      text-align: center; }
      .fairdee-modal-wrapper.province-modal .button-wrap button {
        min-width: 170px; }
  .fairdee-modal-wrapper.delete-modal .title-text {
    font-size: 20px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #192383;
    margin-bottom: 12px; }
  .fairdee-modal-wrapper.delete-modal .text {
    font-size: 14px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a; }
  .fairdee-modal-wrapper.delete-modal .modal-content {
    padding: 32px 95px;
    min-height: auto; }
  .fairdee-modal-wrapper.delete-modal .buttons-wrap {
    margin-top: 14px; }
    .fairdee-modal-wrapper.delete-modal .buttons-wrap.single {
      text-align: center; }
    .fairdee-modal-wrapper.delete-modal .buttons-wrap button {
      width: calc(50% - 6px); }
      .fairdee-modal-wrapper.delete-modal .buttons-wrap button:first-child {
        margin-right: 6px; }
      .fairdee-modal-wrapper.delete-modal .buttons-wrap button:last-child {
        margin-left: 6px; }
  .fairdee-modal-wrapper.customer-info .editable-field {
    background-image: url("images/pencil.svg");
    background-repeat: no-repeat;
    background-position: right;
    cursor: pointer; }
    .fairdee-modal-wrapper.customer-info .editable-field > span {
      width: 100%;
      display: inline-block; }
    .fairdee-modal-wrapper.customer-info .editable-field.center-icon {
      background-position: center; }
  .fairdee-modal-wrapper.customer-info.remarks .modal-content {
    background: #fff;
    padding: 32px 42px 24px 42px; }
  .fairdee-modal-wrapper.customer-info.remarks .mobile-header {
    margin-bottom: 0;
    background: #fff; }
  .fairdee-modal-wrapper.customer-info.remarks .remarks-wrapper {
    padding: 12px; }
  .fairdee-modal-wrapper.customer-info.remarks .remark-card {
    margin-bottom: 8px; }
    .fairdee-modal-wrapper.customer-info.remarks .remark-card .content {
      padding: 10px 0; }
      .fairdee-modal-wrapper.customer-info.remarks .remark-card .content .text {
        font-size: 14px;
        font-family: 'sukh-medium';
        color: #414141;
        margin-bottom: 10px;
        text-align: left; }
      .fairdee-modal-wrapper.customer-info.remarks .remark-card .content .date {
        font-size: 12px;
        font-family: 'sukh-medium';
        letter-spacing: 1px;
        color: #929292;
        display: none; }
    .fairdee-modal-wrapper.customer-info.remarks .remark-card .remark-footer {
      display: flex;
      border-bottom: 1px solid #f0f0f5;
      align-items: center; }
      .fairdee-modal-wrapper.customer-info.remarks .remark-card .remark-footer .date {
        font-size: 12px;
        font-family: 'sukh-medium';
        letter-spacing: 1px;
        color: #929292;
        flex-grow: 1; }
      .fairdee-modal-wrapper.customer-info.remarks .remark-card .remark-footer div {
        padding: 8px;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        font-family: 'sukh-medium';
        color: #4a4a4a; }
        .fairdee-modal-wrapper.customer-info.remarks .remark-card .remark-footer div:last-child {
          color: #f58b1e;
          border-left: 1px solid #f0f0f5; }
  .fairdee-modal-wrapper.customer-info .aff-credit {
    padding: 0 24px; }
    .fairdee-modal-wrapper.customer-info .aff-credit .full-width {
      margin-bottom: 24px; }
    .fairdee-modal-wrapper.customer-info .aff-credit .text {
      font-size: 15px;
      margin-top: 20px; }
    .fairdee-modal-wrapper.customer-info .aff-credit .info-text {
      font-size: 15px;
      color: #1b1b1b;
      margin-top: 12px; }
    .fairdee-modal-wrapper.customer-info .aff-credit .button-wrap {
      width: 100%;
      margin-top: 24px;
      text-align: center;
      overflow: hidden; }
      .fairdee-modal-wrapper.customer-info .aff-credit .button-wrap .credit-transaction-button {
        float: left;
        font-size: 12px;
        padding: 10px;
        font-weight: bold;
        margin-right: 30px;
        color: #f3f4f7; }
  .fairdee-modal-wrapper.customer-info .modal-content {
    padding: 32px 94px;
    max-width: 530px;
    min-height: auto; }
    .fairdee-modal-wrapper.customer-info .modal-content .emails-form {
      padding: 16px; }
      .fairdee-modal-wrapper.customer-info .modal-content .emails-form .button-wrap {
        margin-top: 24px;
        text-align: center; }
    .fairdee-modal-wrapper.customer-info .modal-content .form-field {
      margin-bottom: 8px; }
      .fairdee-modal-wrapper.customer-info .modal-content .form-field .ui.selection.dropdown {
        border: 1px solid #cbcbcb !important;
        border-radius: 2px !important; }
        .fairdee-modal-wrapper.customer-info .modal-content .form-field .ui.selection.dropdown .text {
          margin: 0 !important;
          font-size: 12px; }
        .fairdee-modal-wrapper.customer-info .modal-content .form-field .ui.selection.dropdown .icon {
          top: 0 !important; }
      .fairdee-modal-wrapper.customer-info .modal-content .form-field.with-width {
        width: 150px; }
      .fairdee-modal-wrapper.customer-info .modal-content .form-field .form-label {
        display: none; }
      .fairdee-modal-wrapper.customer-info .modal-content .form-field .label-input {
        border: 1px solid #cbcbcb;
        border-radius: 2px;
        display: flex; }
        .fairdee-modal-wrapper.customer-info .modal-content .form-field .label-input input[type='text'],
        .fairdee-modal-wrapper.customer-info .modal-content .form-field .label-input input[type='number'] {
          border: 0; }
        .fairdee-modal-wrapper.customer-info .modal-content .form-field .label-input label {
          padding: 5px;
          font-size: 12px;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #5f5f5f;
          border-right: 1px solid #cbcbcb; }
      .fairdee-modal-wrapper.customer-info .modal-content .form-field > label.form-label {
        display: none; }
        .fairdee-modal-wrapper.customer-info .modal-content .form-field > label.form-label[lang='english'] {
          font-family: 'sukh-light';
          letter-spacing: 0; }
      .fairdee-modal-wrapper.customer-info .modal-content .form-field > p {
        display: inline-block;
        vertical-align: middle;
        margin: 0 8px 0 0;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #5f5f5f;
        padding-left: 28px; }
      .fairdee-modal-wrapper.customer-info .modal-content .form-field input[type='text'],
      .fairdee-modal-wrapper.customer-info .modal-content .form-field input[type='number'] {
        border-radius: 2px;
        background-color: #ffffff;
        border: 1px solid #cbcbcb;
        padding: 5px 10px;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #5f5f5f;
        width: 100%;
        outline: none; }
    .fairdee-modal-wrapper.customer-info .modal-content .content-details-wrap {
      margin-top: 24px; }
      .fairdee-modal-wrapper.customer-info .modal-content .content-details-wrap .key-value-wrap {
        display: flex;
        margin-bottom: 20px;
        width: 100%; }
        .fairdee-modal-wrapper.customer-info .modal-content .content-details-wrap .key-value-wrap .key {
          font-size: 14px;
          color: #4a4a4a; }
          .fairdee-modal-wrapper.customer-info .modal-content .content-details-wrap .key-value-wrap .key.cust-width {
            min-width: 88px; }
        .fairdee-modal-wrapper.customer-info .modal-content .content-details-wrap .key-value-wrap .value {
          font-size: 14px;
          font-family: 'sukh-bold';
          color: #000;
          flex-grow: 1;
          word-break: break-all;
          padding-left: 12px;
          text-align: left; }
          .fairdee-modal-wrapper.customer-info .modal-content .content-details-wrap .key-value-wrap .value.link {
            font-size: 14px;
            font-family: 'sukh-medium';
            color: #1d2785; }
    .fairdee-modal-wrapper.customer-info .modal-content .quotation-form {
      text-align: left; }
      .fairdee-modal-wrapper.customer-info .modal-content .quotation-form .block.error {
        color: #d8001a; }
      .fairdee-modal-wrapper.customer-info .modal-content .quotation-form .inline-error {
        margin: 0; }
    .fairdee-modal-wrapper.customer-info .modal-content .title-text {
      margin-bottom: 12px;
      font-size: 20px;
      font-family: 'sukh-bold';
      margin-bottom: 24px; }
      .fairdee-modal-wrapper.customer-info .modal-content .title-text.black {
        color: #000; }
    .fairdee-modal-wrapper.customer-info .modal-content > .text {
      font-size: 14px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      margin-bottom: 12px; }
    .fairdee-modal-wrapper.customer-info .modal-content .blue-label {
      font-size: 14px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1d2785; }
    .fairdee-modal-wrapper.customer-info .modal-content .ui.form.coa {
      padding: 0; }
      .fairdee-modal-wrapper.customer-info .modal-content .ui.form.coa .label-text {
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
        margin-bottom: 8px; }
      .fairdee-modal-wrapper.customer-info .modal-content .ui.form.coa .inline-fields {
        margin-bottom: 24px; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form.coa .inline-fields .inline-error {
          position: relative;
          top: auto;
          bottom: auto;
          margin: 0; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form.coa .inline-fields .form-field {
          margin-bottom: 0; }
          .fairdee-modal-wrapper.customer-info .modal-content .ui.form.coa .inline-fields .form-field .ui.selection.dropdown .text {
            font-size: 12px; }
    .fairdee-modal-wrapper.customer-info .modal-content .ui.form .inline-fields {
      display: flex; }
      .fairdee-modal-wrapper.customer-info .modal-content .ui.form .inline-fields > div {
        flex-grow: 1;
        flex-basis: 100%; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form .inline-fields > div:first-child {
          margin-right: 12px; }
    .fairdee-modal-wrapper.customer-info .modal-content .ui.form .field {
      position: relative; }
      .fairdee-modal-wrapper.customer-info .modal-content .ui.form .field .form-label {
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a;
        font-family: 'sukh-light', sans-serif;
        line-height: 1.5; }
      .fairdee-modal-wrapper.customer-info .modal-content .ui.form .field .helper-text {
        position: absolute;
        font-size: 14px;
        text-align: right;
        color: #858585;
        right: 0;
        top: 4px; }
      .fairdee-modal-wrapper.customer-info .modal-content .ui.form .field input {
        border-radius: 6px;
        color: #000; }
    .fairdee-modal-wrapper.customer-info .modal-content .ui.form .form-field p {
      font-size: 10px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #626262;
      margin: 12px 0 4px 0; }
    .fairdee-modal-wrapper.customer-info .modal-content .ui.form .ui.selection.dropdown {
      border: 1px solid #cbcbcb;
      background: transparent;
      height: auto;
      min-height: auto;
      padding: 5px 10px;
      border-radius: 2px;
      min-width: 150px;
      width: 100%;
      border: solid 1px #192383;
      border-radius: 6px; }
      .fairdee-modal-wrapper.customer-info .modal-content .ui.form .ui.selection.dropdown .menu {
        max-height: 180px; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form .ui.selection.dropdown .menu .item {
          padding: 8px !important; }
      .fairdee-modal-wrapper.customer-info .modal-content .ui.form .ui.selection.dropdown .text {
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #5f5f5f;
        margin: 4px 0; }
      .fairdee-modal-wrapper.customer-info .modal-content .ui.form .ui.selection.dropdown .icon {
        padding: 6px 10px;
        margin: 0;
        top: 4px;
        right: 0; }
    .fairdee-modal-wrapper.customer-info .modal-content .ui.form textarea {
      min-height: 110px;
      border-radius: 0;
      height: auto; }
    .fairdee-modal-wrapper.customer-info .modal-content .checkbox-wrap .field {
      margin-bottom: 8px; }
    .fairdee-modal-wrapper.customer-info .modal-content .buttons-wrap {
      margin-top: 14px; }
      .fairdee-modal-wrapper.customer-info .modal-content .buttons-wrap.single {
        text-align: center; }
      .fairdee-modal-wrapper.customer-info .modal-content .buttons-wrap button {
        width: calc(50% - 6px); }
        .fairdee-modal-wrapper.customer-info .modal-content .buttons-wrap button:first-child {
          margin-right: 6px; }
        .fairdee-modal-wrapper.customer-info .modal-content .buttons-wrap button:last-child {
          margin-left: 6px; }
    .fairdee-modal-wrapper.customer-info .modal-content .ui.checkbox label {
      font-size: 14px;
      font-family: 'sukh-medium';
      color: #4a4a4a;
      padding-left: 20px;
      bottom: 0; }
  .fairdee-modal-wrapper.customer-info .conditions-wrap .row {
    display: flex;
    padding: 12px 0; }
    .fairdee-modal-wrapper.customer-info .conditions-wrap .row:not(:last-child) {
      border-bottom: 1px solid #d7d7d7; }
    .fairdee-modal-wrapper.customer-info .conditions-wrap .row .col {
      flex-grow: 1;
      flex-basis: 100%; }
      .fairdee-modal-wrapper.customer-info .conditions-wrap .row .col.img-wrap {
        max-width: 40px;
        width: 100%;
        margin-right: 15px; }
        .fairdee-modal-wrapper.customer-info .conditions-wrap .row .col.img-wrap img {
          max-width: 40px; }
      .fairdee-modal-wrapper.customer-info .conditions-wrap .row .col .text {
        font-size: 12px;
        font-family: 'sukh-medium';
        color: #4a4a4a;
        margin: 0;
        text-align: left; }
        .fairdee-modal-wrapper.customer-info .conditions-wrap .row .col .text.padded-text {
          padding: 0 0 12px 0; }
  .fairdee-modal-wrapper.customer-info .sub-title {
    margin-bottom: 10px;
    color: #4a4a4a; }
  .fairdee-modal-wrapper.affiliate-info .modal-content {
    max-width: 580px;
    padding: 44px 100px; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .retrieved-info .key-value {
      margin-top: 24px; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .retrieved-info .key-value .key {
        font-family: 'sukh-bold';
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 4px;
        color: #454346; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .retrieved-info .key-value .value {
        font-size: 14px;
        font-family: 'sukh-medium';
        border-radius: 2px;
        border: solid 1px #dcd9d6;
        background-color: #f6f6f6;
        padding: 10px;
        line-height: 1.43;
        letter-spacing: normal;
        color: #929292; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .line {
      margin-bottom: 12px;
      border: solid 1px #d7d6d6; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form {
      margin-top: 15px;
      max-width: 100%; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .file-upload-wrap.field > label {
        font-size: 14px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        margin-bottom: 8px !important; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .file-upload-wrap.field > label.error {
          color: #d8001a; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .radio-group > label > span {
        font-family: 'sukh-regular';
        color: #5e5e5e;
        font-size: 12px; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .radio-group > label > span:first-child {
          padding: 0 12px; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .radio-group > label > span:last-child {
          font-family: 'sukh-regular';
          color: #5e5e5e;
          font-size: 12px; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form ::-webkit-input-placeholder {
        /* WebKit browsers */
        color: transparent !important; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: transparent !important; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent !important; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent !important; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form textarea::-webkit-input-placeholder {
        /* WebKit browsers */
        color: transparent; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form textarea:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: transparent !important; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form textarea::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent !important; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form textarea:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent !important; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list {
        margin-bottom: 24px; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list .search-input {
          padding-right: 0;
          border-radius: 2px;
          background: #ffffff;
          border: 0;
          padding-top: 24px; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list .search-input.errors > label {
            color: #d8001a; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list .search-input.errors input[type='text'] {
            border: 1px solid #d8001a; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list .search-input .inline-error {
            position: relative;
            top: auto;
            bottom: auto; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list .search-input > label {
            position: absolute;
            top: 0;
            font-size: 14px;
            font-family: 'sukh-bold';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #454346; }
            .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list .search-input > label.selected {
              color: #454346 !important; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list .search-input input[type='text'] {
            border: 0;
            background: transparent;
            border: solid 1px #dcd9d6; }
            .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list .search-input input[type='text']:focus ~ label {
              color: #f58b1e; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list .search-input ::placeholder {
            color: transparent !important; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list .search-input ::-webkit-input-placeholder {
            /* WebKit browsers */
            color: transparent !important; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list .search-input :-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: transparent !important; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list .search-input ::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: transparent !important; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .search-list .search-input :-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: transparent !important; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline-error {
        margin-left: 0;
        position: relative;
        top: auto;
        bottom: auto;
        font-size: 10px; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline-error.with-offset {
          top: 64px; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.checkbox input:checked ~ .box:after,
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.checkbox input:checked ~ label:after {
        width: 14px;
        height: 14px;
        border: solid 1px #192383;
        border-radius: 2px;
        background: #fff;
        color: #192383 !important;
        font-size: 11px;
        padding: 0 0 0 1px; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.checkbox label {
        font-family: 'sukh-light', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.checkbox label:before {
          width: 14px;
          height: 14px;
          border: solid 1px #192383;
          border-radius: 0; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.checkbox label[lang='english'] {
          font-family: 'sukh-light';
          letter-spacing: 0; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.checkbox.normal-input-checkbox {
        display: block; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.checkbox.normal-input-checkbox label {
          font-size: 14px;
          font-family: 'sukh-regular';
          color: #000; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.dropdown {
        padding: 6px 13px;
        min-height: 35px;
        height: auto; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.dropdown .text {
          font-size: 14px; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.dropdown .text.default {
            color: #9b9b9b; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.dropdown.fluid input.search {
          font-size: 14px;
          padding: 0.67857143em 2.1em 0.67857143em 1em; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.dropdown .icon {
          top: 6px;
          right: 0; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='text'],
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='email'],
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='password'],
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='number'],
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='radio'],
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.fluid.dropdown,
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form textarea {
        width: 100%;
        padding: 4px 0 !important;
        font-size: 16px !important;
        border-radius: 2px;
        border: solid 1px #dcd9d6;
        background-color: #ffffff;
        color: #000;
        font-family: 'sukh-regular';
        padding: 6px !important;
        line-height: 1; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='text']:focus,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='email']:focus,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='password']:focus,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='number']:focus,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='radio']:focus,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.fluid.dropdown:focus,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form textarea:focus {
          border: solid 1px #ff901d !important;
          background-color: #fffcf2 !important; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='text']:focus ~ label,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='email']:focus ~ label,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='password']:focus ~ label,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='number']:focus ~ label,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form input[type='radio']:focus ~ label,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .ui.fluid.dropdown:focus ~ label,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form textarea:focus ~ label {
          color: #f58b1e; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field,
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field {
        width: 100%;
        position: relative;
        margin: 0 0 24px 0;
        padding-top: 24px; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field.with-mb,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field.with-mb {
          margin-bottom: 60px; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field.errors input[type='text'],
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field.errors input[type='email'],
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field.errors input[type='password'],
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field.errors input[type='number'],
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field.errors input[type='radio'],
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field.errors textarea,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field.errors input[type='text'],
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field.errors input[type='email'],
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field.errors input[type='password'],
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field.errors input[type='number'],
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field.errors input[type='radio'],
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field.errors textarea {
          border: 1px solid #d8001a; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field.errors > label,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field.errors > label {
          color: #d8001a; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field.mb-0,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field.mb-0 {
          margin-bottom: 0; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field .htext,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field .htext {
          font-size: 12px;
          font-family: 'sukh-regular';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field > label,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field > label {
          font-size: 14px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #454346;
          display: block;
          position: absolute;
          top: 0; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field > label > span,
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field > label > span {
            color: #d8001a; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field > label.with-offset,
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field > label.with-offset {
            top: -44px; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field .ui.input,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field .ui.input {
          width: 100% !important;
          margin-top: 20px; }
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field .ui.input input[type='text'],
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field .ui.input input[type='email'],
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field .ui.input input[type='password'],
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field .ui.input input[type='number'],
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field .ui.input input[type='radio'],
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .inline.field .ui.input textarea,
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field .ui.input input[type='text'],
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field .ui.input input[type='email'],
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field .ui.input input[type='password'],
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field .ui.input input[type='number'],
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field .ui.input input[type='radio'],
          .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .field .ui.input textarea {
            width: 100%; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .button-wrap {
        margin-top: 56px; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .button-wrap .button {
          width: 100%;
          font-size: 14px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: #ffffff;
          padding: 13px;
          text-transform: capitalize; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .mb-10 {
        margin-bottom: 10px; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .mb-0 {
        margin-bottom: 0 !important; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .info-bar {
      margin: 0;
      padding: 8px 16px 8px 8px;
      border: 0;
      background: #fff; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .info-bar img {
        max-width: 16px;
        width: 100%; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .info-bar .text-one {
        font-size: 14px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #000; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .header-wrap {
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 40px; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .header-wrap img {
        max-width: 110px;
        margin-right: 20px; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .header-wrap div {
        flex-grow: 1; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .header-wrap p {
        font-size: 22px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.58;
        letter-spacing: normal;
        color: #000; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .step-text {
      font-size: 18px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      margin-bottom: 24px;
      position: relative; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .step-text.extra-margin {
        margin-bottom: 48px; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .step-text > span {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 12px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.85); }
        .fairdee-modal-wrapper.affiliate-info .modal-content .step-text > span span {
          color: #d8001a; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .step-text .htext {
        font-size: 14px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
        display: block;
        position: relative; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .welcome-text {
      font-size: 14px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      margin-bottom: 40px; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .error-msg-wrap {
      border-radius: 2px;
      border: solid 1px #d0021b;
      background-color: #ffd9de;
      padding: 8px 20px;
      margin-bottom: 64px;
      display: flex;
      align-items: flex-start;
      width: 110%;
      margin-left: -5%; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .error-msg-wrap img {
        max-width: 15px;
        margin-right: 6px; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .error-msg-wrap .text-one {
        font-size: 12px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.85);
        flex-grow: 1; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .inline-error {
      position: absolute;
      left: 0;
      margin: 0;
      bottom: -18px; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .title-text {
      font-size: 18px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #192383;
      margin: 0; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .quotation-form {
      padding: 0; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .quotation-form > .field {
        margin-bottom: 40px; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .legal-wrapper .text-one {
      font-size: 16px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      margin-bottom: 8px; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .legal-wrapper .text-two {
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #5f5f5f;
      margin-bottom: 12px; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .legal-wrapper .text-three {
      font-size: 16px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ff8500;
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 48px; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .legal-wrapper .text-three a {
        color: inherit; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .legal-wrapper .text-four > span {
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #5f5f5f; }
    .fairdee-modal-wrapper.affiliate-info .modal-content .legal-wrapper .line {
      height: 1px;
      border: solid 1px #d7d6d6;
      margin: 24px 0; }
  .fairdee-modal-wrapper.coa-form .modal-content {
    max-width: 480px;
    padding: 32px 52px; }
    .fairdee-modal-wrapper.coa-form .modal-content .coa-img-wrap {
      padding-bottom: 18px;
      padding-top: 5px;
      position: relative; }
    .fairdee-modal-wrapper.coa-form .modal-content .add-coa-form-wrap {
      padding-bottom: 10px; }
    .fairdee-modal-wrapper.coa-form .modal-content .coa-title-text {
      font-size: 14px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      margin-bottom: 8px; }
    .fairdee-modal-wrapper.coa-form .modal-content .form-upload-wrap {
      padding-top: 10px;
      padding-bottom: 8px; }
    .fairdee-modal-wrapper.coa-form .modal-content .input input[type='text'] {
      background: url(images/down-arrow.svg) no-repeat;
      background-position: right center;
      padding-right: 24px; }
    .fairdee-modal-wrapper.coa-form .modal-content .total {
      font-size: 14px;
      font-family: 'sukh-regular';
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      margin: 12px 0; }
    .fairdee-modal-wrapper.coa-form .modal-content .fairdee-form .search-list {
      margin: 0; }
      .fairdee-modal-wrapper.coa-form .modal-content .fairdee-form .search-list .search-input {
        border-bottom: 1px solid rgba(216, 216, 216, 0.3); }
        .fairdee-modal-wrapper.coa-form .modal-content .fairdee-form .search-list .search-input > label {
          color: #4a4a4a; }
        .fairdee-modal-wrapper.coa-form .modal-content .fairdee-form .search-list .search-input input {
          border-bottom: 0; }
          .fairdee-modal-wrapper.coa-form .modal-content .fairdee-form .search-list .search-input input:focus {
            border-bottom: 0; }
    .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field {
      margin-bottom: 24px; }
      .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row {
        display: flex;
        align-items: flex-end;
        margin: 0 0 24px 0; }
        .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col {
          position: relative;
          background-position: right center;
          border-bottom: 2px solid rgba(216, 216, 216, 0.3);
          flex-grow: 1;
          flex-basis: 100%;
          margin: 0 8px; }
          .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col ::-webkit-input-placeholder {
            /* WebKit browsers */
            color: transparent; }
          .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col :-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: transparent; }
          .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col ::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: transparent; }
          .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col :-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: transparent; }
          .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col input:-internal-autofill-selected {
            background-color: #fff !important; }
          .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col input {
            font-size: 13px;
            color: #000;
            box-shadow: none;
            border: 0;
            border-radius: 0;
            padding: 5px 0;
            color: #9b9b9b;
            font-size: 14px;
            font-family: 'sukh-light';
            letter-spacing: 0;
            text-transform: none;
            width: 100%;
            outline: none; }
            .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col input::selection {
              background-color: transparent; }
            .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col input:focus {
              border: 0;
              border-radius: 0; }
            .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col input:focus ~ label {
              bottom: 30px;
              font-size: 12px; }
            .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col input:not(:placeholder-shown) ~ label {
              bottom: 30px;
              font-size: 12px; }
          .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col > label {
            width: 100%;
            font-size: 14px;
            position: absolute;
            bottom: 10px;
            z-index: 1;
            transition: 0.2s ease all;
            font-size: 14px;
            color: #9b9b9b;
            display: inline-block;
            left: 0;
            pointer-events: none; }
            .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col > label.move-up {
              bottom: 35px; }
          .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row .col .inline-error {
            position: absolute;
            bottom: -18px;
            left: 0; }
        .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row.no-border .col {
          border: 0; }
          .fairdee-modal-wrapper.coa-form .modal-content .inline-input-field .row.no-border .col p {
            font-size: 14px;
            font-family: 'sukh-regular';
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #4a4a4a; }
    .fairdee-modal-wrapper.coa-form .modal-content .form-field > label.form-label {
      display: block;
      font-size: 14px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      margin-bottom: 8px; }
    .fairdee-modal-wrapper.coa-form .modal-content .form-field .field > .checkbox {
      margin-bottom: 8px; }
      .fairdee-modal-wrapper.coa-form .modal-content .form-field .field > .checkbox label {
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5e5e5e;
        font-family: 'sukh-regular';
        padding-left: 24px; }
    .fairdee-modal-wrapper.coa-form .modal-content .title-text {
      font-size: 18px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #192383;
      margin: 0; }
    .fairdee-modal-wrapper.coa-form .modal-content .button {
      text-transform: capitalize;
      min-width: 110px;
      padding: 8px 12px; }
    .fairdee-modal-wrapper.coa-form .modal-content .element-wrap {
      margin-bottom: 24px; }
      .fairdee-modal-wrapper.coa-form .modal-content .element-wrap > div label {
        color: #4a4a4a;
        font-family: 'sukh-regular'; }
      .fairdee-modal-wrapper.coa-form .modal-content .element-wrap > div > div:hover:before {
        border-bottom: 1px solid rgba(216, 216, 216, 0.3); }
      .fairdee-modal-wrapper.coa-form .modal-content .element-wrap > div > div:before {
        border-bottom: 1px solid rgba(216, 216, 216, 0.3); }
      .fairdee-modal-wrapper.coa-form .modal-content .element-wrap > div > div:after {
        border-bottom: 1px solid #f58b1e; }
    .fairdee-modal-wrapper.coa-form .modal-content .file-upload-wrap {
      margin-bottom: 24px; }
      .fairdee-modal-wrapper.coa-form .modal-content .file-upload-wrap .title-text {
        font-size: 14px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        margin-bottom: 8px; }
      .fairdee-modal-wrapper.coa-form .modal-content .file-upload-wrap .text {
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #5f5f5f;
        text-align: left; }
    .fairdee-modal-wrapper.coa-form .modal-content .desc-wrap {
      background-color: #fff6e8;
      padding: 10px;
      text-align: left;
      margin-bottom: 24px; }
      .fairdee-modal-wrapper.coa-form .modal-content .desc-wrap p {
        font-size: 11px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        text-align: left;
        display: flex;
        margin-bottom: 4px;
        position: relative;
        padding-left: 20px; }
        .fairdee-modal-wrapper.coa-form .modal-content .desc-wrap p:first-child:before {
          content: 'i';
          width: 11px;
          height: 11px;
          border-radius: 100%;
          background: #f5a623;
          position: absolute;
          color: #fff;
          text-align: center;
          line-height: 1.4;
          vertical-align: middle;
          left: 0;
          top: 4px;
          font-size: 9px;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
    .fairdee-modal-wrapper.coa-form .modal-content .button-wrap .button {
      width: 100%;
      font-size: 14px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #ffffff;
      padding: 6px 12px; }
    .fairdee-modal-wrapper.coa-form .modal-content .buttons-wrap {
      display: flex; }
      .fairdee-modal-wrapper.coa-form .modal-content .buttons-wrap .button {
        width: 100%;
        font-size: 14px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        padding: 6px 12px; }
  .fairdee-modal-wrapper.sale-track {
    height: 100%;
    overflow: hidden; }
    .fairdee-modal-wrapper.sale-track .modal-content {
      border: 0;
      padding: 32px;
      margin: 5% auto;
      height: 80%; }
  .fairdee-modal-wrapper.small .modal-content {
    width: 320px;
    padding: 20px 30px 30px; }
    .fairdee-modal-wrapper.small .modal-content .close {
      right: 8px;
      top: 12px;
      cursor: pointer;
      padding: 7px; }
    .fairdee-modal-wrapper.small .modal-content .back-text {
      font-family: 'sukh-light', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      cursor: pointer;
      margin: 0;
      margin-top: 30px; }
      .fairdee-modal-wrapper.small .modal-content .back-text img {
        margin-right: 15px;
        vertical-align: middle; }
      .fairdee-modal-wrapper.small .modal-content .back-text a {
        font-family: 'sukh-light', sans-serif;
        color: #192383; }
    .fairdee-modal-wrapper.small .modal-content .title-text {
      font-family: 'sukh-regular', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #192383;
      margin-bottom: 15px; }
      .fairdee-modal-wrapper.small .modal-content .title-text[lang='english'] {
        font-family: 'sukh-bold', sans-serif; }
    .fairdee-modal-wrapper.small .modal-content .sub-title {
      font-family: 'sukh-light', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      line-height: 1.5; }
      .fairdee-modal-wrapper.small .modal-content .sub-title[lang='english'] {
        font-family: 'sukh-light';
        letter-spacing: 0; }
    .fairdee-modal-wrapper.small .modal-content .ui.form {
      margin-top: 20px; }
      .fairdee-modal-wrapper.small .modal-content .ui.form .inline-error {
        margin-left: 0; }
      .fairdee-modal-wrapper.small .modal-content .ui.form .ui.dropdown {
        padding: 7px 13px;
        min-height: 40px;
        height: auto; }
        .fairdee-modal-wrapper.small .modal-content .ui.form .ui.dropdown .text {
          font-size: 16px; }
          .fairdee-modal-wrapper.small .modal-content .ui.form .ui.dropdown .text.default {
            color: #9b9b9b; }
        .fairdee-modal-wrapper.small .modal-content .ui.form .ui.dropdown input.search {
          font-size: 16px; }
        .fairdee-modal-wrapper.small .modal-content .ui.form .ui.dropdown.fluid input.search {
          font-size: 14px;
          padding: 0.67857143em 2.1em 0.67857143em 1em !important; }
        .fairdee-modal-wrapper.small .modal-content .ui.form .ui.dropdown .icon {
          top: 10px;
          right: 0; }
    .fairdee-modal-wrapper.small .modal-content .button {
      width: 100%; }
  .fairdee-modal-wrapper.medium .modal-content {
    max-width: 630px;
    width: 100%;
    padding: 20px 30px 30px;
    min-height: 200px; }
  .fairdee-modal-wrapper.med .modal-content {
    max-width: 440px;
    width: 100%;
    padding: 20px 30px 30px;
    min-height: 200px; }
  .fairdee-modal-wrapper.small-medium .modal-content {
    max-width: 530px;
    width: 100%;
    padding: 20px 30px 30px;
    min-height: 200px; }
    .fairdee-modal-wrapper.small-medium .modal-content.left-align {
      text-align: left; }
    .fairdee-modal-wrapper.small-medium .modal-content .description-text-wrap {
      padding: 6px;
      background: #fff2d5;
      font-size: 12px; }
      .fairdee-modal-wrapper.small-medium .modal-content .description-text-wrap .bold-description-text {
        font-weight: bold; }
      .fairdee-modal-wrapper.small-medium .modal-content .description-text-wrap .light-description-text {
        margin-top: 4px; }
    .fairdee-modal-wrapper.small-medium .modal-content .confirm-text {
      margin-top: 8px;
      font-size: 12px; }
  .fairdee-modal-wrapper.message.med .tick {
    max-width: 80px;
    margin-bottom: 12px; }
  .fairdee-modal-wrapper.large .modal-content {
    max-width: 830px;
    width: 100%;
    padding: 32px;
    min-height: 200px; }
  .fairdee-modal-wrapper.x-large .modal-content {
    max-width: 1024px;
    width: 100%;
    padding: 32px;
    min-height: 200px; }
  .fairdee-modal-wrapper.full-width .modal-content {
    max-width: 100%;
    width: 100%;
    padding: 20px;
    min-height: 200px;
    margin-top: 20px; }
  .fairdee-modal-wrapper.manual-quote .modal-content, .fairdee-modal-wrapper.cover-note .modal-content {
    margin: 60px auto; }
  .fairdee-modal-wrapper.crm-modal .modal-content {
    max-width: 392px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    padding: 48px;
    margin: 60px auto; }
  .fairdee-modal-wrapper.corona-cards .modal-content {
    margin: 60px auto; }
  .fairdee-modal-wrapper.corona-cards.medium .modal-content {
    margin: 30px auto; }
  .fairdee-modal-wrapper .modal-content {
    max-width: 580px;
    width: 100%;
    margin: 150px auto;
    border-radius: 4px;
    background-color: #fff;
    border: solid 3px #f5f5f6;
    min-height: 400px;
    position: relative;
    padding: 35px 60px; }
    .fairdee-modal-wrapper .modal-content.hollow-container {
      padding: 0;
      border-radius: 6px;
      min-height: auto;
      margin: 2rem auto;
      border: 0; }
      .fairdee-modal-wrapper .modal-content.hollow-container .close-wrap {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 100%;
        background: #fff6ee;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -1.6rem;
        top: -1.6rem;
        cursor: pointer; }
        .fairdee-modal-wrapper .modal-content.hollow-container .close-wrap img {
          max-width: 100%; }
      .fairdee-modal-wrapper .modal-content.hollow-container.small.modal-content {
        width: 100%;
        max-width: 400px; }
      .fairdee-modal-wrapper .modal-content.hollow-container.medium.modal-content {
        width: 100%;
        max-width: 768px; }
      .fairdee-modal-wrapper .modal-content.hollow-container.large.modal-content {
        width: 100%;
        max-width: 1024px; }
      .fairdee-modal-wrapper .modal-content.hollow-container.non-standard {
        min-height: 100vh; }
      .fairdee-modal-wrapper .modal-content.hollow-container .cmi-details {
        padding: 20px; }
        .fairdee-modal-wrapper .modal-content.hollow-container .cmi-details .title-text {
          padding-top: 10px;
          font-size: 18px; }
        .fairdee-modal-wrapper .modal-content.hollow-container .cmi-details .space-between-flex {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          max-width: 100%; }
          .fairdee-modal-wrapper .modal-content.hollow-container .cmi-details .space-between-flex .col {
            flex-basis: 50%;
            margin-bottom: 12px; }
        .fairdee-modal-wrapper .modal-content.hollow-container .cmi-details .button-wrap {
          margin: 20px auto;
          text-align: center; }
      .fairdee-modal-wrapper .modal-content.hollow-container .reconcile-modal {
        padding: 20px; }
        .fairdee-modal-wrapper .modal-content.hollow-container .reconcile-modal .card {
          display: flex;
          align-items: center;
          justify-content: space-around; }
          .fairdee-modal-wrapper .modal-content.hollow-container .reconcile-modal .card .key-value-wrap {
            display: flex;
            margin-bottom: 10px;
            width: 100%; }
            .fairdee-modal-wrapper .modal-content.hollow-container .reconcile-modal .card .key-value-wrap .key {
              font-size: 14px; }
            .fairdee-modal-wrapper .modal-content.hollow-container .reconcile-modal .card .key-value-wrap .value {
              font-size: 14px;
              font-family: 'sukh-bold';
              color: #000;
              flex-grow: 1;
              word-break: break-all;
              padding-left: 12px;
              text-align: left; }
      .fairdee-modal-wrapper .modal-content.hollow-container .cancel-progress {
        justify-content: center;
        text-align: center;
        padding: 20px; }
        .fairdee-modal-wrapper .modal-content.hollow-container .cancel-progress .title {
          padding-top: 10px;
          font-size: 18px;
          color: #1253a4;
          font-weight: 600;
          margin: 10px 12px; }
      .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits {
        padding: 20px; }
        .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits .title-text {
          text-align: center;
          font-family: 'sukh-bold';
          font-size: 18px;
          margin-bottom: 15px; }
        .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits .qr-details {
          padding: 0;
          margin-top: 20px; }
          .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits .qr-details .pay-via-qr {
            margin-bottom: 12px;
            width: 100%;
            justify-content: center; }
          .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits .qr-details .qr-image-wrapper {
            margin: 0 auto; }
        .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits .red-medium-text {
          font-size: 14px;
          color: #e93d3d;
          margin-bottom: 8px; }
        .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits .bold-text {
          text-align: center; }
          .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits .bold-text.flex-item {
            justify-content: flex-start;
            margin-top: 20px; }
        .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits .form-wrapper {
          margin-top: 30px;
          border-top: 1px solid #eee;
          padding: 24px 0; }
          .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits .form-wrapper .form-field {
            position: relative;
            padding-top: 24px;
            margin-bottom: 16px;
            display: block; }
            .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits .form-wrapper .form-field .amount-label {
              display: block;
              font-size: 12px;
              font-weight: 900;
              color: #232222; }
            .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits .form-wrapper .form-field .amount-input {
              margin-top: 6px;
              border-radius: 2px;
              border: 1px solid #dcd9d6;
              padding: 10px 6px;
              font-size: 12px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.85);
              display: block;
              width: 100%;
              outline: none;
              box-sizing: border-box; }
            .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits .form-wrapper .form-field.vertical {
              display: flex;
              flex-direction: column; }
        .fairdee-modal-wrapper .modal-content.hollow-container .topup-credits .button-wrap button.medium {
          width: 100%; }
      .fairdee-modal-wrapper .modal-content.hollow-container.mass-task.medium-large {
        margin: 60px auto;
        padding: 30px 30px; }
        .fairdee-modal-wrapper .modal-content.hollow-container.mass-task.medium-large .tabs .tab {
          min-width: auto;
          line-height: 1.6;
          font-size: 16px;
          font-family: 'sukh-regular';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: rgba(168, 167, 167, 0.85);
          text-transform: none;
          min-height: auto;
          padding-bottom: 4px; }
          .fairdee-modal-wrapper .modal-content.hollow-container.mass-task.medium-large .tabs .tab[aria-selected='true'] {
            font-family: 'sukh-bold';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: rgba(0, 0, 0, 0.85); }
          .fairdee-modal-wrapper .modal-content.hollow-container.mass-task.medium-large .tabs .tab:not(:last-child) {
            margin-right: 40px; }
          .fairdee-modal-wrapper .modal-content.hollow-container.mass-task.medium-large .tabs .tab > span > span {
            padding: 0; }
        .fairdee-modal-wrapper .modal-content.hollow-container.mass-task.medium-large .tabs > div > div > span {
          background-color: #f58b1e; }
        .fairdee-modal-wrapper .modal-content.hollow-container.mass-task.medium-large .button-wrap {
          margin-top: 20px;
          text-align: center; }
          .fairdee-modal-wrapper .modal-content.hollow-container.mass-task.medium-large .button-wrap .button {
            min-width: 300px; }
    .fairdee-modal-wrapper .modal-content.with-overflow {
      padding: 32px;
      height: calc(100% - 80px);
      margin: 40px auto; }
      .fairdee-modal-wrapper .modal-content.with-overflow .table-wrapper {
        height: calc(100% - 100px);
        overflow-y: auto; }
      .fairdee-modal-wrapper .modal-content.with-overflow table tr td,
      .fairdee-modal-wrapper .modal-content.with-overflow table tr th {
        padding: 12px; }
      .fairdee-modal-wrapper .modal-content.with-overflow .tab-wrapper {
        display: flex; }
        .fairdee-modal-wrapper .modal-content.with-overflow .tab-wrapper h1 {
          text-align: center;
          font-size: 18px;
          font-family: 'sukh-light';
          flex-grow: 1;
          flex-basis: 100%;
          cursor: pointer;
          padding-bottom: 4px; }
          .fairdee-modal-wrapper .modal-content.with-overflow .tab-wrapper h1.active {
            border-bottom: 2px solid #192383; }
    .fairdee-modal-wrapper .modal-content.middle-aligned {
      top: 50%;
      transform: translateY(-50%);
      margin: auto; }
    .fairdee-modal-wrapper .modal-content .modal-message .button {
      margin-top: 32px; }
    .fairdee-modal-wrapper .modal-content .label-text {
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      text-align: left; }
    .fairdee-modal-wrapper .modal-content .title-text {
      font-family: 'sukh-medium', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #192383;
      margin-bottom: 30px; }
      .fairdee-modal-wrapper .modal-content .title-text[lang='english'] {
        font-family: 'sukh-medium', sans-serif;
        letter-spacing: 0; }
    .fairdee-modal-wrapper .modal-content .close {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer; }
    .fairdee-modal-wrapper .modal-content .ui.steps {
      margin-bottom: 12px; }
      .fairdee-modal-wrapper .modal-content .ui.steps .title {
        margin: 0; }
    .fairdee-modal-wrapper .modal-content .ui.form {
      max-width: 380px;
      margin: 0 auto; }
      .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form {
        max-width: fit-content; }
        .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form .fields {
          flex-wrap: wrap; }
        .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form .field label {
          display: block;
          color: #4a4a4a; }
        .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form input[type="text"],
        .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form input[type="email"],
        .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form input[type="password"],
        .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form input[type="number"],
        .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form textarea {
          width: 230px;
          border-radius: 2px;
          background-color: #ffffff;
          border: 1px solid rgba(34, 36, 38, 0.15);
          padding: 7px 13px;
          font-size: 14px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #4a4a4a;
          font-family: 'sukh-medium', sans-serif;
          line-height: 1.5;
          width: 100%; }
          .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form input[type="text"]:focus,
          .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form input[type="email"]:focus,
          .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form input[type="password"]:focus,
          .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form input[type="number"]:focus,
          .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form textarea:focus {
            border-radius: 2px;
            border: solid 1px #192383; }
        .fairdee-modal-wrapper .modal-content .ui.form.no-frills-form .ui.selection.dropdown {
          border: 1px solid #cbcbcb;
          border-radius: 0; }
      .fairdee-modal-wrapper .modal-content .ui.form .button-wrap {
        text-align: center;
        margin-top: 30px; }
        .fairdee-modal-wrapper .modal-content .ui.form .button-wrap button {
          padding: 9px 40px; }
    .fairdee-modal-wrapper .modal-content .download-btn-group {
      display: flex;
      flex-flow: column; }
      .fairdee-modal-wrapper .modal-content .download-btn-group .button {
        margin: 10px; }
  .fairdee-modal-wrapper .message-modal-content .text-one {
    font-size: 14px;
    line-height: 1.4;
    margin: 12px 0; }
  .fairdee-modal-wrapper .message-modal-content .buttons-wrap {
    text-align: center; }
    .fairdee-modal-wrapper .message-modal-content .buttons-wrap .button {
      font-size: 12px;
      padding-left: 12px;
      padding-right: 12px;
      margin-bottom: 12px; }
      .fairdee-modal-wrapper .message-modal-content .buttons-wrap .button:not(:last-child) {
        margin-right: 12px; }
  .fairdee-modal-wrapper.video-player {
    display: flex;
    align-items: center; }
    .fairdee-modal-wrapper.video-player .modal-content {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      margin: auto;
      height: auto;
      background: none;
      border: none;
      max-width: 722px;
      height: 490px;
      background: #fff;
      border-radius: 12px;
      position: relative;
      padding: 0; }
      .fairdee-modal-wrapper.video-player .modal-content .close {
        background: #fff;
        border-radius: 15px;
        padding: 6px;
        top: -22px;
        right: -22px; }
      .fairdee-modal-wrapper.video-player .modal-content #yt-player-thumb {
        position: relative;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center; }
      .fairdee-modal-wrapper.video-player .modal-content .custom-close {
        font-size: 20px;
        width: 20px;
        height: 20px;
        border-radius: 12px;
        position: absolute;
        text-align: center;
        background-color: #fff;
        right: 24%;
        top: -4%;
        cursor: pointer; }
      .fairdee-modal-wrapper.video-player .modal-content .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
        width: 100%; }
      .fairdee-modal-wrapper.video-player .modal-content .video-container iframe,
      .fairdee-modal-wrapper.video-player .modal-content .video-container object,
      .fairdee-modal-wrapper.video-player .modal-content .video-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
    .fairdee-modal-wrapper.video-player.corona-banner .modal-content {
      width: fit-content; }
  .fairdee-modal-wrapper .verification-message p {
    display: block;
    text-align: left;
    margin-bottom: 12px; }
  .fairdee-modal-wrapper.license-modal .modal-content .title-text {
    text-align: center; }
  .fairdee-modal-wrapper.license-modal .modal-content .help-text {
    font-family: sukh-bold;
    font-size: 12px; }
  .fairdee-modal-wrapper.generate-documents-modal .modal-content .email-docs {
    width: 100%;
    padding: 10px 0; }
  .fairdee-modal-wrapper.generate-documents-modal .modal-content .bold-text {
    font-family: 'sukh-bold'; }
  .fairdee-modal-wrapper.generate-documents-modal .modal-content .margin-top-10 {
    margin-top: 10px; }
  .fairdee-modal-wrapper.generate-documents-modal .modal-content .checkbox-wrapper {
    margin-top: 10px; }
    .fairdee-modal-wrapper.generate-documents-modal .modal-content .checkbox-wrapper .title {
      margin: 0;
      margin-bottom: 10px;
      font-size: 18px;
      font-family: 'sukh-bold'; }
    .fairdee-modal-wrapper.generate-documents-modal .modal-content .checkbox-wrapper .checkboxes > div {
      margin-right: 16px; }

.print-msg-modal img {
  max-width: 320px;
  display: inline-block;
  margin: 0 auto;
  border-radius: 100%; }

.cif-modal .modal-content {
  background: white !important; }

.image-viewer {
  padding: 12px; }
  .image-viewer img {
    max-width: 100%; }

.assignment-modal {
  padding: 40px; }
  .assignment-modal .form-field {
    margin-bottom: 20px; }
    .assignment-modal .form-field .label {
      display: block;
      margin-bottom: 8px; }
    .assignment-modal .form-field .react-select-custom {
      width: 100%; }
  .assignment-modal .button-wrap {
    display: flex;
    justify-content: center; }
  .assignment-modal .button-wrap button {
    margin: 0 10px; }

@media screen and (max-width: 1024px) {
  .fairdee-modal-wrapper {
    background: #fff; }
    .fairdee-modal-wrapper.crm-modal .modal-content {
      max-width: 100%;
      padding: 24px;
      margin: 0 auto; }
      .fairdee-modal-wrapper.crm-modal .modal-content .close {
        display: none; }
    .fairdee-modal-wrapper.onboarding-modal .modal-content {
      padding: 0; }
    .fairdee-modal-wrapper.onboarding-modal .slide {
      height: var(--vh);
      overflow: hidden; }
      .fairdee-modal-wrapper.onboarding-modal .slide img {
        width: 100%;
        height: 104vh;
        object-fit: cover; }
    .fairdee-modal-wrapper.onboarding-modal .slick-dots {
      bottom: 18%; }
      .fairdee-modal-wrapper.onboarding-modal .slick-dots li.slick-active button:before {
        color: #fff; }
      .fairdee-modal-wrapper.onboarding-modal .slick-dots li button:before {
        line-height: 1 !important;
        color: rgba(255, 255, 255, 0.5); }
    .fairdee-modal-wrapper.onboarding-modal .footer {
      display: flex;
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      box-shadow: 0 -2px 7px 0 rgba(0, 0, 0, 0.21);
      background-color: #ffffff;
      z-index: 9;
      padding: 4px 4px;
      margin: 14% 5%; }
      .fairdee-modal-wrapper.onboarding-modal .footer > button {
        flex-basis: 100%;
        flex-grow: 1;
        border-radius: 2px;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        padding: 10px;
        background-color: #ffffff;
        color: #f58b1e;
        border: solid 2px #f58b1e; }
        .fairdee-modal-wrapper.onboarding-modal .footer > button:first-child {
          margin-right: 4px;
          color: #ffffff;
          border: 0;
          background-color: #f58b1e; }
    .fairdee-modal-wrapper.youtube-player .modal-content {
      height: auto; }
      .fairdee-modal-wrapper.youtube-player .modal-content .close {
        width: 24px;
        height: 24px;
        right: 4px;
        top: -32px; }
    .fairdee-modal-wrapper.file-viewer.med .modal-content {
      max-width: 100%; }
      .fairdee-modal-wrapper.file-viewer.med .modal-content .close {
        display: none; }
    .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content {
      margin: 0;
      padding: 72px 24px 24px; }
      .fairdee-modal-wrapper.edit-affiliate-info.small-medium .modal-content .close {
        display: none; }
    .fairdee-modal-wrapper.share-details.small .modal-content .ui.form {
      margin-top: 80px; }
    .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content {
      padding: 90px 40px 40px; }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .desc-wrap {
        margin-left: -12px;
        margin-right: -12px; }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .mobile-header {
        margin: 0; }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .banner-img {
        max-width: 200px; }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .text-one {
        font-size: 16px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #192383;
        margin: 0; }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .text-two {
        font-size: 13px;
        line-height: 1.69;
        max-width: 227px; }
        .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .text-two:before {
          top: 4px; }
      .fairdee-modal-wrapper.refer-earn-modal.small-medium .modal-content .button-wrap button {
        padding: 9px 24px;
        width: 100%; }
    .fairdee-modal-wrapper.video-player {
      background-color: rgba(77, 92, 116, 0.8); }
      .fairdee-modal-wrapper.video-player .modal-content .video-container {
        position: static; }
      .fairdee-modal-wrapper.video-player .modal-content .custom-close {
        right: -2%;
        top: -4%; }
      .fairdee-modal-wrapper.video-player .modal-content .close {
        position: absolute;
        right: 0;
        top: -30px;
        cursor: pointer;
        background: #fff;
        border-radius: 15px;
        padding: 6px; }
      .fairdee-modal-wrapper.video-player.corona-banner .modal-content {
        background: none; }
        .fairdee-modal-wrapper.video-player.corona-banner .modal-content .close {
          right: -2%;
          top: 13%; }
    .fairdee-modal-wrapper.community {
      background-color: rgba(77, 92, 116, 0.8); }
    .fairdee-modal-wrapper.delete-modal {
      background-color: rgba(77, 92, 116, 0.8);
      padding: 120px 30px; }
      .fairdee-modal-wrapper.delete-modal .modal-content {
        padding: 24px 40px 32px 40px;
        box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.02);
        background-color: #ffffff;
        border-radius: 6px; }
        .fairdee-modal-wrapper.delete-modal .modal-content .close {
          cursor: pointer;
          bottom: -44px;
          top: auto;
          right: auto;
          left: calc(50% - 9px);
          width: 18px; }
        .fairdee-modal-wrapper.delete-modal .modal-content .buttons-wrap .orange-bordered-button {
          display: none; }
        .fairdee-modal-wrapper.delete-modal .modal-content .buttons-wrap .button {
          width: 100%;
          margin: 0; }
    .fairdee-modal-wrapper.sale-track .modal-content {
      margin: 0 auto;
      height: 100%; }
    .fairdee-modal-wrapper.province-modal {
      text-align: center; }
      .fairdee-modal-wrapper.province-modal .province-wrap {
        width: 100%; }
      .fairdee-modal-wrapper.province-modal .ui.form .inline-error {
        position: relative;
        text-align: left;
        top: 5px; }
      .fairdee-modal-wrapper.province-modal .button-wrap {
        margin-top: 20px;
        text-align: center; }
        .fairdee-modal-wrapper.province-modal .button-wrap button {
          min-width: 170px; }
    .fairdee-modal-wrapper.customer-info.remarks .modal-content {
      background: #f3f4f7;
      min-height: 100%;
      padding: 0;
      padding-bottom: 60px; }
    .fairdee-modal-wrapper.customer-info.remarks .mobile-header {
      margin-bottom: 0;
      background: #fff; }
    .fairdee-modal-wrapper.customer-info.remarks .remarks-wrapper {
      padding: 12px;
      padding-top: 70px; }
    .fairdee-modal-wrapper.customer-info.remarks .remark-card {
      box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.02);
      background-color: #ffffff;
      margin-bottom: 8px; }
      .fairdee-modal-wrapper.customer-info.remarks .remark-card .content {
        padding: 10px 8px; }
        .fairdee-modal-wrapper.customer-info.remarks .remark-card .content .text {
          font-size: 14px;
          font-family: 'sukh-medium';
          color: #414141;
          margin-bottom: 10px;
          text-align: left; }
        .fairdee-modal-wrapper.customer-info.remarks .remark-card .content .date {
          font-size: 12px;
          font-family: 'sukh-medium';
          letter-spacing: 1px;
          color: #929292;
          display: block; }
      .fairdee-modal-wrapper.customer-info.remarks .remark-card .remark-footer {
        display: flex;
        border-top: 1px solid #f0f0f5; }
        .fairdee-modal-wrapper.customer-info.remarks .remark-card .remark-footer .date {
          display: none; }
        .fairdee-modal-wrapper.customer-info.remarks .remark-card .remark-footer div {
          flex-grow: 1;
          flex-basis: 100%;
          padding: 8px;
          text-align: center;
          cursor: pointer;
          font-size: 12px;
          font-family: 'sukh-medium';
          color: #4a4a4a; }
          .fairdee-modal-wrapper.customer-info.remarks .remark-card .remark-footer div:last-child {
            color: #f58b1e;
            border-left: 1px solid #f0f0f5; }
    .fairdee-modal-wrapper.customer-info.remark .modal-content {
      background: #f3f4f7;
      height: 100%; }
      .fairdee-modal-wrapper.customer-info.remark .modal-content .quotation-form {
        margin-top: 12px; }
        .fairdee-modal-wrapper.customer-info.remark .modal-content .quotation-form .remark-box {
          box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.02);
          background-color: #ffffff;
          height: 250px; }
        .fairdee-modal-wrapper.customer-info.remark .modal-content .quotation-form .field textarea {
          padding: 12px !important; }
      .fairdee-modal-wrapper.customer-info.remark .modal-content .mobile-header {
        margin-bottom: 0;
        background: #fff; }
    .fairdee-modal-wrapper.customer-info .modal-content {
      padding: 90px 0 0 0; }
      .fairdee-modal-wrapper.customer-info .modal-content .quotation-form {
        padding: 16px; }
        .fairdee-modal-wrapper.customer-info .modal-content .quotation-form .button-wrap {
          margin-top: 24px;
          text-align: center; }
      .fairdee-modal-wrapper.customer-info .modal-content .close {
        display: none; }
      .fairdee-modal-wrapper.customer-info .modal-content .ui.form {
        max-width: 100%;
        padding: 0 16px; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form .ui.selection.dropdown {
          border: 0;
          border-bottom: 1px solid #cbcbcb;
          border-radius: 0; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form.coa .inline-fields .form-field .ui.selection.dropdown .text {
          font-size: 16px; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form .field .inline-error {
          top: 44px; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form ::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #5f5f5f !important; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form :-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #5f5f5f !important; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form ::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #5f5f5f !important; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form :-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #5f5f5f !important; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form textarea::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #5f5f5f; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form textarea:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #5f5f5f !important; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form textarea::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #5f5f5f !important; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form textarea:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #5f5f5f !important; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form input {
          padding: 5px 10px !important; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form .field input {
          border-radius: 0; }
        .fairdee-modal-wrapper.customer-info .modal-content .ui.form .form-label {
          display: none; }
      .fairdee-modal-wrapper.customer-info .modal-content .mobile-content {
        padding: 0 24px; }
      .fairdee-modal-wrapper.customer-info .modal-content .buttons-wrap {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        background: #fff;
        z-index: 9;
        display: flex;
        justify-content: flex-end; }
        .fairdee-modal-wrapper.customer-info .modal-content .buttons-wrap.single button {
          width: 100%; }
        .fairdee-modal-wrapper.customer-info .modal-content .buttons-wrap button {
          width: 50%;
          margin: 0;
          border: 0;
          font-size: 14px;
          font-family: 'sukh-medium';
          text-transform: none;
          border-radius: 0;
          padding: 18px; }
          .fairdee-modal-wrapper.customer-info .modal-content .buttons-wrap button:first-child {
            background-color: #ffffff;
            color: #4a4a4a;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            margin: 0; }
          .fairdee-modal-wrapper.customer-info .modal-content .buttons-wrap button:last-child {
            background-color: #ff901d;
            color: #fff;
            margin: 0; }
          .fairdee-modal-wrapper.customer-info .modal-content .buttons-wrap button.orange-bordered-button {
            background-color: #ffffff;
            color: #f58b1e;
            border: 1px solid #f58b1e; }
    .fairdee-modal-wrapper.coa-form .modal-content {
      padding: 80px 24px 80px 24px; }
      .fairdee-modal-wrapper.coa-form .modal-content .title-text {
        display: block;
        margin-bottom: 0;
        font-size: 16px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.31;
        letter-spacing: normal;
        color: #000; }
      .fairdee-modal-wrapper.coa-form .modal-content .button-wrap {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0; }
        .fairdee-modal-wrapper.coa-form .modal-content .button-wrap .button {
          font-size: 14px;
          padding: 16px; }
      .fairdee-modal-wrapper.coa-form .modal-content .buttons-wrap {
        display: flex; }
        .fairdee-modal-wrapper.coa-form .modal-content .buttons-wrap .button {
          font-size: 14px;
          padding: 16px; }
          .fairdee-modal-wrapper.coa-form .modal-content .buttons-wrap .button.orange-bordered-button {
            border-color: #fff; }
    .fairdee-modal-wrapper.small .modal-content, .fairdee-modal-wrapper.medium .modal-content, .fairdee-modal-wrapper.large .modal-content, .fairdee-modal-wrapper.x-large .modal-content {
      width: 100%;
      height: 100%;
      padding: 0;
      padding-top: 15%; }
      .fairdee-modal-wrapper.small .modal-content.middle-aligned, .fairdee-modal-wrapper.medium .modal-content.middle-aligned, .fairdee-modal-wrapper.large .modal-content.middle-aligned, .fairdee-modal-wrapper.x-large .modal-content.middle-aligned {
        transform: none;
        top: 0; }
      .fairdee-modal-wrapper.small .modal-content .back-text, .fairdee-modal-wrapper.medium .modal-content .back-text, .fairdee-modal-wrapper.large .modal-content .back-text, .fairdee-modal-wrapper.x-large .modal-content .back-text {
        text-align: left; }
      .fairdee-modal-wrapper.small .modal-content .sub-title, .fairdee-modal-wrapper.medium .modal-content .sub-title, .fairdee-modal-wrapper.large .modal-content .sub-title, .fairdee-modal-wrapper.x-large .modal-content .sub-title {
        margin-bottom: 30px !important;
        text-align: center !important; }
      .fairdee-modal-wrapper.small .modal-content .ui.form .ui.dropdown.fluid input.search, .fairdee-modal-wrapper.medium .modal-content .ui.form .ui.dropdown.fluid input.search, .fairdee-modal-wrapper.large .modal-content .ui.form .ui.dropdown.fluid input.search, .fairdee-modal-wrapper.x-large .modal-content .ui.form .ui.dropdown.fluid input.search {
        font-size: 12px;
        padding: 0 0 10px 0 !important; }
      .fairdee-modal-wrapper.small .modal-content .close, .fairdee-modal-wrapper.medium .modal-content .close, .fairdee-modal-wrapper.large .modal-content .close, .fairdee-modal-wrapper.x-large .modal-content .close {
        top: 20px;
        right: 20px;
        display: none; }
      .fairdee-modal-wrapper.small .modal-content .sub-title, .fairdee-modal-wrapper.medium .modal-content .sub-title, .fairdee-modal-wrapper.large .modal-content .sub-title, .fairdee-modal-wrapper.x-large .modal-content .sub-title {
        text-align: left;
        max-width: 280px;
        margin: 0 auto; }
      .fairdee-modal-wrapper.small .modal-content .button, .fairdee-modal-wrapper.medium .modal-content .button, .fairdee-modal-wrapper.large .modal-content .button, .fairdee-modal-wrapper.x-large .modal-content .button {
        padding: 15px 20px; }
      .fairdee-modal-wrapper.small .modal-content .ui.form, .fairdee-modal-wrapper.medium .modal-content .ui.form, .fairdee-modal-wrapper.large .modal-content .ui.form, .fairdee-modal-wrapper.x-large .modal-content .ui.form {
        margin-top: 40px; }
        .fairdee-modal-wrapper.small .modal-content .ui.form.no-margin, .fairdee-modal-wrapper.medium .modal-content .ui.form.no-margin, .fairdee-modal-wrapper.large .modal-content .ui.form.no-margin, .fairdee-modal-wrapper.x-large .modal-content .ui.form.no-margin {
          margin: 0; }
        .fairdee-modal-wrapper.small .modal-content .ui.form.confirmation-step, .fairdee-modal-wrapper.medium .modal-content .ui.form.confirmation-step, .fairdee-modal-wrapper.large .modal-content .ui.form.confirmation-step, .fairdee-modal-wrapper.x-large .modal-content .ui.form.confirmation-step {
          margin-top: 0;
          padding-top: 12px; }
          .fairdee-modal-wrapper.small .modal-content .ui.form.confirmation-step .dropdown-title, .fairdee-modal-wrapper.medium .modal-content .ui.form.confirmation-step .dropdown-title, .fairdee-modal-wrapper.large .modal-content .ui.form.confirmation-step .dropdown-title, .fairdee-modal-wrapper.x-large .modal-content .ui.form.confirmation-step .dropdown-title {
            padding-bottom: 0; }
          .fairdee-modal-wrapper.small .modal-content .ui.form.confirmation-step .field, .fairdee-modal-wrapper.medium .modal-content .ui.form.confirmation-step .field, .fairdee-modal-wrapper.large .modal-content .ui.form.confirmation-step .field, .fairdee-modal-wrapper.x-large .modal-content .ui.form.confirmation-step .field {
            margin-bottom: 20px; }
          .fairdee-modal-wrapper.small .modal-content .ui.form.confirmation-step .inline-error, .fairdee-modal-wrapper.medium .modal-content .ui.form.confirmation-step .inline-error, .fairdee-modal-wrapper.large .modal-content .ui.form.confirmation-step .inline-error, .fairdee-modal-wrapper.x-large .modal-content .ui.form.confirmation-step .inline-error {
            position: static;
            margin-bottom: 12px; }
    .fairdee-modal-wrapper.small .ui.form, .fairdee-modal-wrapper.medium .ui.form, .fairdee-modal-wrapper.large .ui.form, .fairdee-modal-wrapper.x-large .ui.form {
      margin-top: 40px; }
      .fairdee-modal-wrapper.small .ui.form .ui.dropdown, .fairdee-modal-wrapper.medium .ui.form .ui.dropdown, .fairdee-modal-wrapper.large .ui.form .ui.dropdown, .fairdee-modal-wrapper.x-large .ui.form .ui.dropdown {
        box-shadow: none; }
      .fairdee-modal-wrapper.small .ui.form .buttons-wrap,
      .fairdee-modal-wrapper.small .ui.form .button-wrap, .fairdee-modal-wrapper.medium .ui.form .buttons-wrap,
      .fairdee-modal-wrapper.medium .ui.form .button-wrap, .fairdee-modal-wrapper.large .ui.form .buttons-wrap,
      .fairdee-modal-wrapper.large .ui.form .button-wrap, .fairdee-modal-wrapper.x-large .ui.form .buttons-wrap,
      .fairdee-modal-wrapper.x-large .ui.form .button-wrap {
        margin-top: 50px !important; }
        .fairdee-modal-wrapper.small .ui.form .buttons-wrap .button,
        .fairdee-modal-wrapper.small .ui.form .button-wrap .button, .fairdee-modal-wrapper.medium .ui.form .buttons-wrap .button,
        .fairdee-modal-wrapper.medium .ui.form .button-wrap .button, .fairdee-modal-wrapper.large .ui.form .buttons-wrap .button,
        .fairdee-modal-wrapper.large .ui.form .button-wrap .button, .fairdee-modal-wrapper.x-large .ui.form .buttons-wrap .button,
        .fairdee-modal-wrapper.x-large .ui.form .button-wrap .button {
          padding: 14px 40px;
          font-size: 14px;
          width: 100%;
          margin-bottom: 10px;
          height: auto; }
        .fairdee-modal-wrapper.small .ui.form .buttons-wrap.inline,
        .fairdee-modal-wrapper.small .ui.form .button-wrap.inline, .fairdee-modal-wrapper.medium .ui.form .buttons-wrap.inline,
        .fairdee-modal-wrapper.medium .ui.form .button-wrap.inline, .fairdee-modal-wrapper.large .ui.form .buttons-wrap.inline,
        .fairdee-modal-wrapper.large .ui.form .button-wrap.inline, .fairdee-modal-wrapper.x-large .ui.form .buttons-wrap.inline,
        .fairdee-modal-wrapper.x-large .ui.form .button-wrap.inline {
          display: flex;
          flex-direction: column; }
          .fairdee-modal-wrapper.small .ui.form .buttons-wrap.inline .button:first-child,
          .fairdee-modal-wrapper.small .ui.form .button-wrap.inline .button:first-child, .fairdee-modal-wrapper.medium .ui.form .buttons-wrap.inline .button:first-child,
          .fairdee-modal-wrapper.medium .ui.form .button-wrap.inline .button:first-child, .fairdee-modal-wrapper.large .ui.form .buttons-wrap.inline .button:first-child,
          .fairdee-modal-wrapper.large .ui.form .button-wrap.inline .button:first-child, .fairdee-modal-wrapper.x-large .ui.form .buttons-wrap.inline .button:first-child,
          .fairdee-modal-wrapper.x-large .ui.form .button-wrap.inline .button:first-child {
            order: 2; }
          .fairdee-modal-wrapper.small .ui.form .buttons-wrap.inline .button:last-child,
          .fairdee-modal-wrapper.small .ui.form .button-wrap.inline .button:last-child, .fairdee-modal-wrapper.medium .ui.form .buttons-wrap.inline .button:last-child,
          .fairdee-modal-wrapper.medium .ui.form .button-wrap.inline .button:last-child, .fairdee-modal-wrapper.large .ui.form .buttons-wrap.inline .button:last-child,
          .fairdee-modal-wrapper.large .ui.form .button-wrap.inline .button:last-child, .fairdee-modal-wrapper.x-large .ui.form .buttons-wrap.inline .button:last-child,
          .fairdee-modal-wrapper.x-large .ui.form .button-wrap.inline .button:last-child {
            order: 1; }
    .fairdee-modal-wrapper.message.med {
      background-color: rgba(77, 92, 116, 0.8); }
      .fairdee-modal-wrapper.message.med .docs-wrap {
        margin-top: 32px; }
        .fairdee-modal-wrapper.message.med .docs-wrap .row {
          display: flex;
          margin-bottom: 24px; }
          .fairdee-modal-wrapper.message.med .docs-wrap .row .col a {
            font-size: 14px;
            font-family: 'sukh-medium';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #f58b1e; }
          .fairdee-modal-wrapper.message.med .docs-wrap .row .col:first-child {
            flex-grow: 1;
            font-size: 14px;
            font-family: 'sukh-medium';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4a4a4a;
            text-align: left; }
      .fairdee-modal-wrapper.message.med .close-btn {
        position: absolute;
        bottom: -32px; }
      .fairdee-modal-wrapper.message.med .modal-content {
        max-width: calc(100% - 48px);
        margin: 60px auto;
        width: 100%;
        border-radius: 6px;
        box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.02);
        min-height: auto;
        padding: 24px 20px 32px 20px !important; }
        .fairdee-modal-wrapper.message.med .modal-content .button-wrap {
          text-align: center; }
          .fairdee-modal-wrapper.message.med .modal-content .button-wrap .button {
            padding: 8px;
            max-width: 180px; }
    .fairdee-modal-wrapper.affiliate-info .modal-content {
      padding: 24px 24px 80px 24px;
      min-height: calc(var(--vh) - 50px);
      border: 0; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .info-bar {
        border-radius: 1px;
        border: solid 1px #ffb94d;
        background-color: #fff6e8; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .header-wrap {
        display: none; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .header-wrap p {
          font-size: 16px;
          font-family: 'sukh-medium'; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .mobile-header .current-page {
        padding-left: 0; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .step-text {
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .quotation-form,
      .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form {
        max-width: 100%; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .quotation-form .file-upload-wrap label,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .file-upload-wrap label {
          display: block; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .quotation-form .block.error,
        .fairdee-modal-wrapper.affiliate-info .modal-content .ui.form .block.error {
          color: #d8001a !important; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .title-text {
        display: block;
        margin-bottom: 0;
        font-size: 16px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.31;
        letter-spacing: normal;
        color: #000; }
      .fairdee-modal-wrapper.affiliate-info .modal-content .button-wrap {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        margin: 0; }
        .fairdee-modal-wrapper.affiliate-info .modal-content .button-wrap .button {
          font-size: 14px;
          padding: 14px !important; }
    .fairdee-modal-wrapper .modal-content {
      border: 0;
      border-radius: 0;
      margin: 0 auto;
      padding: 40px 10px;
      max-width: 100%; }
      .fairdee-modal-wrapper .modal-content.hollow-container {
        max-width: calc(100% - 3.2rem);
        margin: 20px auto; }
        .fairdee-modal-wrapper .modal-content.hollow-container .close-wrap {
          top: 5px;
          right: 20px; }
        .fairdee-modal-wrapper .modal-content.hollow-container.full-screen {
          padding: 10px;
          max-width: 100%;
          margin: 0;
          border-radius: 0; }
      .fairdee-modal-wrapper .modal-content.with-overflow {
        height: auto;
        margin: 0 auto; }
        .fairdee-modal-wrapper .modal-content.with-overflow .table-wrapper {
          height: auto;
          padding-top: 60px; }
      .fairdee-modal-wrapper .modal-content .modal-message {
        padding: 24px;
        position: relative;
        height: 100%; }
        .fairdee-modal-wrapper .modal-content .modal-message .button {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          font-size: 18px; }
        .fairdee-modal-wrapper .modal-content .modal-message p {
          line-height: 1.6;
          margin-bottom: 16px; }
        .fairdee-modal-wrapper .modal-content .modal-message .text-wrap {
          position: relative;
          top: 50%;
          transform: translateY(-60%); }
      .fairdee-modal-wrapper .modal-content .mobile-header {
        display: block;
        position: fixed;
        padding: 18px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        margin-bottom: 45px;
        left: 0;
        top: 0;
        right: 0;
        background: #fff;
        z-index: 99999; }
        .fairdee-modal-wrapper .modal-content .mobile-header ul {
          padding: 0;
          margin: 0;
          list-style: none;
          display: table;
          width: 100%; }
          .fairdee-modal-wrapper .modal-content .mobile-header ul li {
            display: table-cell;
            text-align: center;
            text-align: right;
            cursor: pointer;
            width: 50%; }
            .fairdee-modal-wrapper .modal-content .mobile-header ul li:first-child {
              padding-right: 15px;
              border-right: 1px solid #eae8e8; }
            .fairdee-modal-wrapper .modal-content .mobile-header ul li:last-child {
              text-align: left;
              padding-left: 15px; }
            .fairdee-modal-wrapper .modal-content .mobile-header ul li.active span {
              color: #192383;
              font-family: 'sukh-medium', sans-serif; }
              .fairdee-modal-wrapper .modal-content .mobile-header ul li.active span[lang='english'] {
                font-family: 'sukh-bold', sans-serif; }
            .fairdee-modal-wrapper .modal-content .mobile-header ul li span {
              display: block;
              font-size: 14px;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #9b9b9b;
              font-family: 'sukh-light', sans-serif;
              text-transform: uppercase; }
              .fairdee-modal-wrapper .modal-content .mobile-header ul li span[lang='english'] {
                font-family: 'sukh-light';
                letter-spacing: 0; }
        .fairdee-modal-wrapper .modal-content .mobile-header .current-page {
          position: relative;
          left: 0;
          top: 0;
          padding: 0 0 0 32px; }
          .fairdee-modal-wrapper .modal-content .mobile-header .current-page .title-text {
            display: inline-block;
            margin: 0;
            font-size: 14px;
            font-family: 'sukh-medium', sans-serif; }
            .fairdee-modal-wrapper .modal-content .mobile-header .current-page .title-text[lang='english'] {
              font-family: 'sukh-medium', sans-serif;
              letter-spacing: 0; }
          .fairdee-modal-wrapper .modal-content .mobile-header .current-page img {
            display: inline-block;
            position: absolute;
            top: 5px;
            left: 0; }
      .fairdee-modal-wrapper .modal-content .title-text {
        display: none; }
        .fairdee-modal-wrapper .modal-content .title-text.with-ref {
          display: block; }
      .fairdee-modal-wrapper .modal-content .ui.form {
        max-width: 280px; }
    .fairdee-modal-wrapper.corona-cards .modal-content {
      margin: 10px auto; }
    .fairdee-modal-wrapper.corona-cards.medium .modal-content {
      margin: 10px auto; } }

.docs-rejection-form {
  padding: 20px; }
  .docs-rejection-form .reason-select-form-wrapper {
    margin: 0 auto 32px auto;
    text-align: left;
    max-width: 320px; }
    .docs-rejection-form .reason-select-form-wrapper .reason-select {
      margin: 0 auto;
      position: relative; }
      .docs-rejection-form .reason-select-form-wrapper .reason-select > div {
        width: 100%; }
  .docs-rejection-form .error-wrap p {
    color: red; }
  .docs-rejection-form .button-wrap-docs-rejection {
    display: flex;
    align-items: center;
    justify-content: center; }
    .docs-rejection-form .button-wrap-docs-rejection button {
      margin: 0 12px; }

.content-action-wrapper {
  display: flex;
  margin: 20px 0; }
  .content-action-wrapper > div {
    flex-grow: 1;
    flex-basis: 100%;
    padding: 12px;
    border-radius: 3.4px;
    border: solid 0.9px #ced0da;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column; }
    .content-action-wrapper > div .btn-wrap {
      flex-grow: 1;
      align-items: flex-end;
      justify-content: flex-end;
      display: flex; }
      .content-action-wrapper > div .btn-wrap.center {
        text-align: center; }
    .content-action-wrapper > div .t-text {
      font-size: 18px;
      font-family: 'sukh-bold';
      letter-spacing: 0px;
      color: #192383;
      margin-bottom: 20px; }
    .content-action-wrapper > div .text {
      font-size: 16px;
      font-family: 'sukh-regular';
      letter-spacing: 0px;
      color: #5b5858;
      position: relative;
      padding-left: 16px;
      margin-bottom: 10px; }
      .content-action-wrapper > div .text::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: #5b5858;
        position: absolute;
        left: 0;
        top: 4px; }
      .content-action-wrapper > div .text .value {
        display: inline-block;
        margin-left: 10px; }
    .content-action-wrapper > div:not(:last-child) {
      margin-right: 12px; }

.ui.form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  text-transform: capitalize !important;
  color: #9b9b9b !important;
  text-transform: capitalize; }

.ui.form ::-moz-placeholder {
  /* Firefox 19+ */
  text-transform: capitalize !important;
  color: #9b9b9b !important;
  text-transform: capitalize; }

.ui.form :-ms-input-placeholder {
  /* IE 10+ */
  text-transform: capitalize !important;
  color: #9b9b9b !important;
  text-transform: capitalize; }

.ui.form :-moz-placeholder {
  /* Firefox 18- */
  text-transform: capitalize !important;
  color: #9b9b9b !important;
  text-transform: capitalize; }

.ui.form input[type="text"],
.ui.form input[type="email"],
.ui.form input[type="password"],
.ui.form input[type="number"],
.ui.form textarea {
  width: 230px;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #192383;
  padding: 7px 13px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
  font-family: 'sukh-medium', sans-serif;
  line-height: 1.5;
  width: 100%; }
  .ui.form input[type="text"]:focus,
  .ui.form input[type="email"]:focus,
  .ui.form input[type="password"]:focus,
  .ui.form input[type="number"]:focus,
  .ui.form textarea:focus {
    border-radius: 2px;
    border: solid 1px #192383; }

.ui.form textarea {
  resize: none;
  height: 100px;
  border-radius: 15px; }
  .ui.form textarea:focus {
    border-radius: 15px; }

.ui.form .inline-error {
  font-size: 12px;
  color: #d8001a;
  margin-left: calc(100% - 235px); }

.ui.form .inline.field,
.ui.form .field {
  text-align: left;
  margin-bottom: 20px; }
  .ui.form .inline.field.mb-0,
  .ui.form .field.mb-0 {
    margin-bottom: 0; }
  .ui.form .inline.field > label,
  .ui.form .field > label {
    font-family: 'sukh-light', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #535766;
    display: none;
    font-weight: normal; }
    .ui.form .inline.field > label[lang='english'],
    .ui.form .field > label[lang='english'] {
      font-family: 'sukh-light';
      letter-spacing: 0; }
  .ui.form .inline.field .ui.input input[type='text'],
  .ui.form .inline.field .ui.input input[type='email'],
  .ui.form .inline.field .ui.input input[type='password'],
  .ui.form .inline.field .ui.input input[type='number'],
  .ui.form .inline.field .ui.input input[type='radio'],
  .ui.form .inline.field .ui.input textarea,
  .ui.form .field .ui.input input[type='text'],
  .ui.form .field .ui.input input[type='email'],
  .ui.form .field .ui.input input[type='password'],
  .ui.form .field .ui.input input[type='number'],
  .ui.form .field .ui.input input[type='radio'],
  .ui.form .field .ui.input textarea {
    width: 230px; }

.checkbox-wrap {
  margin-bottom: 24px; }
  .checkbox-wrap .form {
    margin-bottom: 24px; }
    .checkbox-wrap .form input[type='text'] {
      font-size: 16px;
      color: #000000;
      box-shadow: none;
      border: 0;
      border-radius: 0;
      border-bottom: 2px solid rgba(216, 216, 216, 0.3) !important;
      padding: 5px 0;
      color: #9b9b9b;
      text-transform: none;
      width: 100%;
      outline: none; }
      .checkbox-wrap .form input[type='text']:focus {
        border: 0;
        border-bottom: 2px solid rgba(216, 216, 216, 0.3);
        border-radius: 0; }
      .checkbox-wrap .form input[type='text']:focus ~ label {
        top: -12px;
        color: #f58b1e;
        font-size: 14px !important; }
      .checkbox-wrap .form input[type='text']:not(:placeholder-shown) ~ label {
        top: -12px;
        color: #f58b1e;
        font-size: 14px !important; }
    .checkbox-wrap .form > label {
      width: 100%;
      z-index: 1;
      transition: 0.2s ease all;
      font-size: 16px;
      color: #4a4a4a;
      display: inline-block;
      left: 0;
      pointer-events: none; }
      .checkbox-wrap .form > label > span {
        color: #d8001a; }
      .checkbox-wrap .form > label.selected {
        color: #9b9b9b !important; }
      .checkbox-wrap .form > label.move-up {
        bottom: 35px; }
  .checkbox-wrap .text {
    font-size: 12px;
    color: #4a4a4a;
    margin-top: 8px;
    font-family: 'sukh-regular'; }
  .checkbox-wrap .title {
    font-size: 16px;
    color: #4a4a4a;
    margin-top: 8px;
    font-family: 'sukh-regular';
    margin: 0 0 24px 0;
    position: relative; }
    .checkbox-wrap .title:after {
      content: '';
      position: absolute;
      right: 0;
      background: url(images/down-arrow.svg) no-repeat;
      background-position: center center; }
  .checkbox-wrap .ui.checkbox label {
    padding-left: 32px;
    font-size: 12px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    top: 0; }
    .checkbox-wrap .ui.checkbox label:before, .checkbox-wrap .ui.checkbox label:after {
      top: 0; }
  .checkbox-wrap .ui.checkbox input:checked ~ label:after {
    border: solid 2px #ff9800;
    background: #ff9800;
    top: 0; }

@media screen and (max-width: 1024px) {
  .ui.form ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent !important; }
  .ui.form :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent !important; }
  .ui.form ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent !important; }
  .ui.form :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent !important; }
  .ui.form textarea::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent; }
  .ui.form textarea:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent !important; }
  .ui.form textarea::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent !important; }
  .ui.form textarea:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent !important; }
  .ui.form input[type='text']:focus,
  .ui.form input[type='email']:focus,
  .ui.form input[type='password']:focus,
  .ui.form input[type='number']:focus,
  .ui.form input[type='radio']:focus {
    font-size: 16px !important; }
  .ui.form input[type='text'],
  .ui.form input[type='email'],
  .ui.form input[type='password'],
  .ui.form input[type='number'],
  .ui.form input[type='radio'],
  .ui.form .ui.fluid.dropdown,
  .ui.form textarea {
    width: 100%;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid rgba(216, 216, 216, 0.3);
    padding: 4px 0 !important;
    font-size: 16px !important; }
    .ui.form input[type='text']:focus,
    .ui.form input[type='email']:focus,
    .ui.form input[type='password']:focus,
    .ui.form input[type='number']:focus,
    .ui.form input[type='radio']:focus,
    .ui.form .ui.fluid.dropdown:focus,
    .ui.form textarea:focus {
      border: 0;
      border-bottom: 2px solid rgba(216, 216, 216, 0.3);
      border-radius: 0; }
    .ui.form input[type='text']:focus ~ label,
    .ui.form input[type='email']:focus ~ label,
    .ui.form input[type='password']:focus ~ label,
    .ui.form input[type='number']:focus ~ label,
    .ui.form input[type='radio']:focus ~ label,
    .ui.form .ui.fluid.dropdown:focus ~ label,
    .ui.form textarea:focus ~ label {
      bottom: 30px;
      color: #f58b1e;
      font-size: 12px; }
    .ui.form input[type='text']:not(:placeholder-shown) ~ label,
    .ui.form input[type='email']:not(:placeholder-shown) ~ label,
    .ui.form input[type='password']:not(:placeholder-shown) ~ label,
    .ui.form input[type='number']:not(:placeholder-shown) ~ label,
    .ui.form input[type='radio']:not(:placeholder-shown) ~ label,
    .ui.form .ui.fluid.dropdown:not(:placeholder-shown) ~ label,
    .ui.form textarea:not(:placeholder-shown) ~ label {
      bottom: 30px;
      color: #f58b1e;
      font-size: 12px; }
  .ui.form .inline-error {
    margin-left: 0;
    position: absolute;
    left: 0;
    top: 30px; }
  .ui.form .inline.field,
  .ui.form .field {
    width: 100%;
    position: relative;
    margin-bottom: 35px; }
    .ui.form .inline.field.mb-0,
    .ui.form .field.mb-0 {
      margin-bottom: 0; }
    .ui.form .inline.field > label.form-label,
    .ui.form .field > label.form-label {
      width: 100%;
      margin: 0 !important;
      font-size: 12px;
      color: #f58b1e;
      position: absolute;
      bottom: 5px;
      z-index: 1;
      transition: 0.2s ease all;
      font-size: 14px;
      color: #4a4a4a;
      display: inline-block;
      left: 0;
      pointer-events: none; }
      .ui.form .inline.field > label.form-label.move-up,
      .ui.form .field > label.form-label.move-up {
        bottom: 35px; }
    .ui.form .inline.field .ui.input,
    .ui.form .field .ui.input {
      width: 100% !important;
      margin-top: 20px; }
      .ui.form .inline.field .ui.input input[type='text'],
      .ui.form .inline.field .ui.input input[type='email'],
      .ui.form .inline.field .ui.input input[type='password'],
      .ui.form .inline.field .ui.input input[type='number'],
      .ui.form .inline.field .ui.input input[type='radio'],
      .ui.form .inline.field .ui.input textarea,
      .ui.form .field .ui.input input[type='text'],
      .ui.form .field .ui.input input[type='email'],
      .ui.form .field .ui.input input[type='password'],
      .ui.form .field .ui.input input[type='number'],
      .ui.form .field .ui.input input[type='radio'],
      .ui.form .field .ui.input textarea {
        width: 100%; }
  .checkbox-wrap .form > label {
    font-size: 14px; }
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  input[type='radio'],
  textarea {
    font-size: 16px !important;
    -webkit-appearance: none; }
  input[type='text']:focus,
  input[type='email']:focus,
  input[type='password']:focus,
  input[type='number']:focus,
  input[type='radio']:focus,
  textarea:focus {
    font-size: 16px !important;
    -webkit-appearance: none; }
  input[type='text']:active,
  input[type='email']:active,
  input[type='password']:active,
  input[type='number']:active,
  input[type='radio']:active,
  textarea:active {
    font-size: 16px !important;
    -webkit-appearance: none; } }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none; }

.fairdee-loader {
  background-color: rgba(77, 92, 116, 0.8);
  width: 100%;
  min-height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  padding: 100px 30px; }
  .fairdee-loader .content {
    max-width: 320px;
    min-height: 192px;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    padding: 35px; }
    .fairdee-loader .content img {
      width: 70px;
      height: 70px;
      margin-bottom: 12px; }
    .fairdee-loader .content .text {
      font-family: Avenir-Roman;
      font-family: 'sukh-light';
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      margin: 0; }

.redirection-loader {
  background-color: #fff;
  width: 100%;
  min-height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99; }
  .redirection-loader .logo-wrapper {
    background-color: #f3f4f7;
    width: 100%;
    padding: 12px;
    text-align: center;
    margin-top: 125px; }
    .redirection-loader .logo-wrapper img {
      width: 132px;
      height: 32px; }
  .redirection-loader .loader-wrap {
    margin: 30px auto 12px auto;
    text-align: center; }
    .redirection-loader .loader-wrap img {
      width: 70px;
      height: 70px;
      object-fit: contain; }
  .redirection-loader .text {
    font-family: 'sukh-medium', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    padding-bottom: 10px;
    border-bottom: dashed 1px rgba(151, 151, 151, 0.25);
    margin-bottom: 30px; }
  .redirection-loader .sub-text {
    font-family: 'sukh-light', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    margin: 0; }

.notification-wrap {
  position: fixed;
  width: 100%;
  min-height: 60px;
  z-index: 99999;
  right: 0;
  top: 0;
  left: 0;
  color: #fff;
  animation-duration: .5s;
  animation-fill-mode: both;
  background: #fff;
  display: table;
  border: solid 1px #e8e8e8;
  text-align: center; }
  .notification-wrap .close {
    position: absolute;
    right: 15px;
    top: calc(50% - 5px);
    cursor: pointer;
    color: #333; }
  .notification-wrap .icon-wrap {
    height: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 10px;
    text-align: right;
    padding-right: 20px; }
  .notification-wrap .message-wrap {
    height: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 10px; }
    .notification-wrap .message-wrap img {
      display: none;
      vertical-align: middle; }
    .notification-wrap .message-wrap .title-text {
      font-size: 16px;
      text-transform: capitalize;
      margin-bottom: 0; }
    .notification-wrap .message-wrap img {
      margin-right: 20px; }
    .notification-wrap .message-wrap .text {
      font-size: 14px;
      color: #4a4a4a;
      line-height: 1;
      display: inline-block;
      vertical-align: middle; }
  .notification-wrap.info {
    background-color: #dae8f8;
    box-shadow: 0 2px 12px 5px rgba(0, 0, 0, 0.04); }
    .notification-wrap.info .message-wrap .info {
      display: inline-block; }
    .notification-wrap.info .message-wrap .title-text {
      color: #23b9b0; }
  .notification-wrap.warning {
    background-color: #fcefd3;
    box-shadow: 0 2px 12px 5px rgba(0, 0, 0, 0.04); }
    .notification-wrap.warning .message-wrap .title-text {
      color: #f5822a; }
    .notification-wrap.warning .message-wrap .warning {
      display: inline-block; }
  .notification-wrap.error {
    background-color: #edd1d1;
    box-shadow: 0 2px 12px 5px rgba(0, 0, 0, 0.04); }
    .notification-wrap.error .message-wrap .error {
      display: inline-block; }
    .notification-wrap.error .message-wrap .title-text {
      color: #d92c2c; }
  .notification-wrap.success {
    background-color: #e0edcf;
    box-shadow: 0 2px 12px 5px rgba(0, 0, 0, 0.04); }
    .notification-wrap.success .message-wrap .success {
      display: inline-block; }
    .notification-wrap.success .message-wrap .title-text {
      color: #4a4a4a;
      font-size: 14px; }

@media screen and (max-width: 1024px) {
  .notification-wrap .close {
    display: none; } }

.user-information .img-wrap {
  margin: 0 auto;
  text-align: center; }
  .user-information .img-wrap div {
    display: inline-block;
    position: relative; }
    .user-information .img-wrap div img.user {
      width: 92px;
      height: 92px;
      object-fit: cover;
      border-radius: 100%; }
    .user-information .img-wrap div img.plus {
      position: absolute;
      right: -10px;
      top: calc(50% - 10px); }

.user-information .modal-content .button {
  margin-top: 36px; }

.car-details.small .buttons-wrap .button {
  display: inline-block;
  width: auto;
  padding: 10px 19px;
  min-width: calc(50% - 5px);
  line-height: 1;
  height: 34px; }
  .car-details.small .buttons-wrap .button:first-child {
    margin-right: 8px; }

.pre-launch-popup {
  position: fixed;
  background: #fff;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  text-align: center;
  overflow: auto;
  transform: translateY(-1000%);
  -webkit-transform: translateY(-1000%);
  animation: slide-down 3s forwards;
  -webkit-animation: slide-down 3s forwards; }
  .pre-launch-popup::-webkit-scrollbar {
    width: 0px; }
  .pre-launch-popup::-webkit-scrollbar-track {
    -webkit-box-shadow: #f4f4f4; }
  .pre-launch-popup::-webkit-scrollbar-thumb {
    background-color: rgba(22, 43, 106, 0.05);
    outline: 1px solid rgba(22, 43, 106, 0.05); }
  .pre-launch-popup.slide-up {
    animation: slide-up 1.5s forwards;
    -webkit-animation: slide-up 1.5s forwards; }

@keyframes slide-down {
  0% {
    transform: translateY(-1000%); }
  100% {
    transform: translateY(0%); } }

@keyframes slide-up {
  0% {
    transform: translateY(0%); }
  100% {
    transform: translateY(-1000%); } }
  .pre-launch-popup .header-wrap {
    position: relative;
    padding: 16px;
    text-align: center; }
    .pre-launch-popup .header-wrap .close {
      position: absolute;
      right: 20px;
      top: 25px;
      cursor: pointer; }
  .pre-launch-popup .prelaunch-banner {
    background: #192383;
    padding: 10px;
    text-align: center; }
    .pre-launch-popup .prelaunch-banner p {
      font-size: 18px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      margin: 0;
      font-family: 'sukh-light', sans-serif; }
  .pre-launch-popup div .button.primary {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 14px; }
  .pre-launch-popup .img-wrap {
    margin: 40px 0 20px 0;
    text-align: center; }
  .pre-launch-popup .title-text {
    font-size: 40px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #192383;
    margin: 0;
    margin-bottom: 10px; }
  .pre-launch-popup .sub-text {
    font-size: 28px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    margin: 0; }
  .pre-launch-popup .bordered-text {
    padding: 10px 50px;
    border: dotted 1px rgba(151, 151, 151, 0.5);
    font-size: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #192383;
    margin: 30px 0 20px 0;
    display: inline-block;
    font-family: 'sukh-light', sans-serif; }
  .pre-launch-popup .footer-wrap {
    margin-top: 25px;
    background: #f3f4f7;
    padding: 20px 0 35px 0;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0; }
    .pre-launch-popup .footer-wrap .text {
      font-size: 20px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #807f80;
      margin: 0;
      font-family: 'sukh-light', sans-serif; }
      .pre-launch-popup .footer-wrap .text span {
        font-family: 'sukh-regular', sans-serif;
        letter-spacing: 0; }
    .pre-launch-popup .footer-wrap .insurer-list {
      padding: 0;
      margin: 0;
      width: 100%;
      margin-top: 30px; }
      .pre-launch-popup .footer-wrap .insurer-list li {
        display: inline-block;
        vertical-align: middle;
        padding-right: 32px; }
        .pre-launch-popup .footer-wrap .insurer-list li:last-child {
          padding-right: 0; }
        .pre-launch-popup .footer-wrap .insurer-list li img {
          max-width: 50px; }
      .pre-launch-popup .footer-wrap .insurer-list .title-text {
        font-size: 24px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #807f80; }

.message {
  text-align: center; }
  .message .crm-table-wrapper table thead tr th,
  .message .crm-table-wrapper table tbody tr td {
    text-align: center; }
  .message .crm-table-wrapper table tbody tr:last-child td {
    border-bottom: none; }
  .message .modal-content {
    min-height: auto !important; }
    .message .modal-content img.tick {
      margin: 20px auto 30px auto; }
    .message .modal-content .sub-title {
      margin-bottom: 20px;
      line-height: 1.5;
      font-size: 12px; }
    .message .modal-content .qr-code {
      max-width: 200px;
      margin: 16px auto; }
      .message .modal-content .qr-code img {
        width: 100%; }
    .message .modal-content .mt-30 {
      margin-top: 30px; }
    .message .modal-content .title {
      font-size: 20px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #192383;
      margin-bottom: 16px; }
      .message .modal-content .title.left-align {
        text-align: left;
        margin: 0; }
    .message .modal-content .text {
      font-size: 14px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a; }
    .message .modal-content .button-wrap {
      display: flex;
      justify-content: center;
      margin-top: 16px; }
      .message .modal-content .button-wrap .button {
        margin: 0 8px; }
    .message .modal-content .button {
      width: auto !important;
      padding-left: 40px;
      padding-right: 40px; }
      .message .modal-content .button.full-width {
        padding-left: 0;
        padding-right: 0;
        width: 100% !important; }

.group.medium .modal-content, .group.large .modal-content {
  padding: 30px 50px; }
  .group.medium .modal-content.invite-members .title-text, .group.large .modal-content.invite-members .title-text {
    margin-bottom: 10px; }
  .group.medium .modal-content.invite-members .sub-title, .group.large .modal-content.invite-members .sub-title {
    font-family: 'sukh-light', sans-serif;
    font-size: 15px;
    line-height: 1.69;
    margin-bottom: 24px; }
  .group.medium .modal-content.invite-members .sub-text, .group.large .modal-content.invite-members .sub-text {
    font-family: 'sukh-light', sans-serif;
    font-size: 15px;
    color: rgba(74, 74, 74, 0.7);
    line-height: 1.5;
    text-align: center; }
  .group.medium .modal-content.invite-members .link-text, .group.large .modal-content.invite-members .link-text {
    font-family: 'sukh-medium', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #192383;
    margin-top: 12px; }
    .group.medium .modal-content.invite-members .link-text span, .group.large .modal-content.invite-members .link-text span {
      text-decoration: underline;
      display: inline-block;
      cursor: pointer; }
  .group.medium .modal-content.invite-members .buttons-wrap, .group.large .modal-content.invite-members .buttons-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    margin: 12px 0 22px 0; }
    .group.medium .modal-content.invite-members .buttons-wrap .button, .group.large .modal-content.invite-members .buttons-wrap .button {
      display: inline-block;
      padding: 10px 7px;
      font-size: 12px;
      text-transform: none; }
  .group.medium .modal-content .title-text, .group.large .modal-content .title-text {
    font-size: 20px; }
  .group.medium .modal-content .sub-title, .group.large .modal-content .sub-title {
    font-family: 'sukh-medium', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    line-height: 1.5; }
  .group.medium .modal-content .desc-text, .group.large .modal-content .desc-text {
    font-family: 'sukh-bold', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a; }
  .group.medium .modal-content .sub-title-text, .group.large .modal-content .sub-title-text {
    font-family: 'sukh-bold', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 17px; }
  .group.medium .modal-content .list-text, .group.large .modal-content .list-text {
    font-family: 'sukh-regular', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
    position: relative;
    padding-left: 30px; }
    .group.medium .modal-content .list-text:before, .group.large .modal-content .list-text:before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: #4a4a4a;
      position: absolute;
      left: 0;
      top: 8px; }
  .group.medium .modal-content .ui.form .inline-error, .group.large .modal-content .ui.form .inline-error {
    margin-left: 0; }
  .group.medium .modal-content .ui.form.with-email, .group.large .modal-content .ui.form.with-email {
    text-align: center;
    margin-top: 17px; }
    .group.medium .modal-content .ui.form.with-email .inline.field, .group.large .modal-content .ui.form.with-email .inline.field {
      display: inline-block;
      text-align: center; }
      .group.medium .modal-content .ui.form.with-email .inline.field input[type='text'], .group.large .modal-content .ui.form.with-email .inline.field input[type='text'] {
        font-size: 12px;
        padding: 7px 13px; }
    .group.medium .modal-content .ui.form.with-email .inline-error, .group.large .modal-content .ui.form.with-email .inline-error {
      margin-left: 0;
      display: block; }
  .group.medium .modal-content .ui.form .inline.field label, .group.large .modal-content .ui.form .inline.field label {
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: 16px;
    color: #4a4a4a; }
    .group.medium .modal-content .ui.form .inline.field label img, .group.large .modal-content .ui.form .inline.field label img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px; }
  .group.medium .modal-content .ui.form .inline.field input[type='text'], .group.large .modal-content .ui.form .inline.field input[type='text'] {
    opacity: 0.7;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #b8b8b8;
    padding: 6px 13px;
    font-size: 16px; }
  .group.medium .modal-content .ui.form .group-name .inline-error, .group.large .modal-content .ui.form .group-name .inline-error {
    width: 100%;
    text-align: center;
    position: relative;
    top: 5px; }
  .group.medium .modal-content .button-wrap, .group.large .modal-content .button-wrap {
    text-align: center;
    margin-top: 30px; }
    .group.medium .modal-content .button-wrap .button, .group.large .modal-content .button-wrap .button {
      padding: 10px 35px;
      font-size: 14px; }
  .group.medium .modal-content .users-list, .group.large .modal-content .users-list {
    max-width: 360px;
    margin: 20px auto 30px auto; }
    .group.medium .modal-content .users-list.no-border .desc-text, .group.large .modal-content .users-list.no-border .desc-text {
      margin-bottom: 12px; }
    .group.medium .modal-content .users-list.no-border .each-user, .group.large .modal-content .users-list.no-border .each-user {
      border: 0;
      padding: 7.5px 0; }
      .group.medium .modal-content .users-list.no-border .each-user .text, .group.large .modal-content .users-list.no-border .each-user .text {
        font-family: 'sukh-regular', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a; }
    .group.medium .modal-content .users-list .each-user, .group.large .modal-content .users-list .each-user {
      border-bottom: solid 0.5px #b0b2c6; }
      .group.medium .modal-content .users-list .each-user:last-child, .group.large .modal-content .users-list .each-user:last-child {
        border: 0; }
    .group.medium .modal-content .users-list .ui.grid .column, .group.large .modal-content .users-list .ui.grid .column {
      padding: 5px 0; }
    .group.medium .modal-content .users-list .ui.checkbox label, .group.large .modal-content .users-list .ui.checkbox label {
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      /* The Fallback */
      color: rgba(0, 0, 0, 0.7); }
    .group.medium .modal-content .users-list .text, .group.large .modal-content .users-list .text {
      font-family: 'sukh-regular', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      color: rgba(74, 74, 74, 0.7); }

.group.large .modal-content .title-text {
  margin-bottom: 10px; }

.group .recommended-group-wrap {
  margin-top: 40px; }
  .group .recommended-group-wrap .expanded .details-wrap {
    display: block; }
  .group .recommended-group-wrap .expanded .each-row {
    border: 0; }
  .group .recommended-group-wrap .selected .each-row {
    background: #f6f7f9; }
  .group .recommended-group-wrap .each-row {
    display: table;
    width: 100%;
    padding: 15px;
    border-bottom: solid 0.5px #b0b2c6; }
    .group .recommended-group-wrap .each-row:last-child {
      border: 0; }
    .group .recommended-group-wrap .each-row.header {
      border-bottom: solid 0.5px #b0b2c6; }
      .group .recommended-group-wrap .each-row.header .col:first-child {
        padding-left: 25px; }
    .group .recommended-group-wrap .each-row .col {
      width: 25%;
      display: table-cell; }
      .group .recommended-group-wrap .each-row .col p {
        margin: 0; }
      .group .recommended-group-wrap .each-row .col .title-text {
        font-family: 'sukh-regular', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(74, 74, 74, 0.7);
        text-transform: uppercase; }
      .group .recommended-group-wrap .each-row .col .desc-text {
        font-family: 'sukh-regular', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(74, 74, 74, 0.7); }
      .group .recommended-group-wrap .each-row .col .text {
        font-family: 'sukh-regular', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        /* The Fallback */
        color: rgba(0, 0, 0, 0.7); }
      .group .recommended-group-wrap .each-row .col .link-text {
        font-family: 'sukh-regular', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #192383;
        text-decoration: underline;
        cursor: pointer; }
  .group .recommended-group-wrap .details-wrap {
    display: none; }
    .group .recommended-group-wrap .details-wrap .each-row {
      border: 0;
      padding: 15px; }
      .group .recommended-group-wrap .details-wrap .each-row.header {
        padding: 15px 15px 5px 15px;
        border-bottom: solid 0.5px #b0b2c6; }
        .group .recommended-group-wrap .details-wrap .each-row.header .col:first-child {
          padding-left: 0; }
  .group .recommended-group-wrap .buttons-wrap {
    text-align: center;
    margin-top: 35px; }
    .group .recommended-group-wrap .buttons-wrap .button {
      padding: 12px 45px; }

.user-list .modal-content {
  max-width: 1100px;
  background: transparent;
  border: none; }

.user-list .close {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer; }

.user-list .slick-prev,
.user-list .slick-next {
  top: calc(50% - 22px);
  width: 55px;
  height: 55px; }

.user-list .slick-prev {
  left: -55px;
  background-position: 15px center; }
  .user-list .slick-prev:hover {
    left: -55px;
    background-position: 15px center; }

.user-list .slick-next {
  right: -55px;
  background-position: 20px center; }
  .user-list .slick-next:hover {
    right: -55px;
    background-position: 20px center; }

.user .modal-content .mobile-header {
  margin-bottom: 20px; }

.user .user-wrapper {
  background: #fff;
  padding: 0;
  height: calc(100% - 60px);
  overflow-y: auto; }
  .user .user-wrapper .content-wrapper .title-text {
    display: block;
    text-align: left !important;
    font-size: 14px;
    color: #4a4a4a; }
  .user .user-wrapper .content-wrapper .text,
  .user .user-wrapper .content-wrapper .sub-text {
    font-size: 14px; }
  .user .user-wrapper .content-wrapper .button {
    padding: 7px 20px;
    width: auto; }
  .user .user-wrapper .content-wrapper .each-row {
    display: table;
    width: 100%; }
    .user .user-wrapper .content-wrapper .each-row.title {
      background: transparent; }
      .user .user-wrapper .content-wrapper .each-row.title .text {
        font-size: 14px; }
    .user .user-wrapper .content-wrapper .each-row .col {
      display: table-cell; }
      .user .user-wrapper .content-wrapper .each-row .col:first-child {
        width: 120px; }
      .user .user-wrapper .content-wrapper .each-row .col:last-child {
        padding-left: 15px; }
      .user .user-wrapper .content-wrapper .each-row .col .text {
        font-size: 11px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%; }
  .user .user-wrapper .content-wrapper .account-info .each-row.social .col {
    width: 100% !important;
    margin-right: 0;
    text-align: left !important;
    display: block;
    margin-top: 10px;
    padding-left: 0; }
    .user .user-wrapper .content-wrapper .account-info .each-row.social .col .text,
    .user .user-wrapper .content-wrapper .account-info .each-row.social .col .google-connect {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%; }
  .user .user-wrapper .content-wrapper .section .right-links {
    top: -30px; }
    .user .user-wrapper .content-wrapper .section .right-links a {
      font-size: 10px; }
      .user .user-wrapper .content-wrapper .section .right-links a:last-child {
        margin-right: 0; }
  .user .user-wrapper .content-wrapper .cashback-info .cashback-wrap.group-cashback-waiting .text,
  .user .user-wrapper .content-wrapper .cashback-info .cashback-wrap.group-cashback-waiting .sub-text {
    font-size: 14px; }
  .user .user-wrapper .content-wrapper .cashback-info .default-cashback {
    padding: 0 32px 30px 32px; }
  .user .user-wrapper .content-wrapper .refer-and-earn {
    padding: 0 25px; }
    .user .user-wrapper .content-wrapper .refer-and-earn .text {
      font-size: 14px; }

.fairdee-modal-wrapper.make-model .make-modal-wrap {
  border: 0; }

.fairdee-modal-wrapper.make-model .modal-content .ui.form .field .ui.dropdown {
  padding: 6px 12px !important; }
  .fairdee-modal-wrapper.make-model .modal-content .ui.form .field .ui.dropdown input.search {
    padding: 0 12px !important; }

.fairdee-modal-wrapper.make-model .modal-content .ui.form .field .inline-error {
  padding-left: 13px; }

.fairdee-modal-wrapper.make-model .modal-content .mobile-header {
  box-shadow: none; }

.fairdee-modal-wrapper.corona-cards .with-pdf-link {
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .fairdee-modal-wrapper.corona-cards .with-pdf-link .button {
    align-self: flex-end; }

.fairdee-modal-wrapper.corona-cards .modal-content {
  border: none;
  background-image: linear-gradient(to top, rgba(255, 252, 248, 0) 40%, #f9eadb 108%); }

.fairdee-modal-wrapper.corona-cards .buttons-wrap {
  margin: 20px;
  text-align: center; }

.fairdee-modal-wrapper.corona-cards .prev-next {
  display: flex;
  justify-content: center;
  margin-top: 30px; }
  .fairdee-modal-wrapper.corona-cards .prev-next .next {
    align-self: flex-end;
    margin-left: 10px; }
  .fairdee-modal-wrapper.corona-cards .prev-next .prev {
    align-self: flex-start; }

.fairdee-modal-wrapper.corona-cards .flow-map {
  list-style: disc;
  padding-left: 18px; }

.fairdee-modal-wrapper.corona-cards .download-button {
  text-align: center; }

.fairdee-modal-wrapper.corona-cards .conditional-content.flex {
  display: flex; }

.fairdee-modal-wrapper.corona-cards .custom-input-container {
  text-align: left; }
  .fairdee-modal-wrapper.corona-cards .custom-input-container .form-field input[type='text'] {
    opacity: 0.7;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #b8b8b8;
    padding: 6px 13px;
    font-size: 16px; }
  .fairdee-modal-wrapper.corona-cards .custom-input-container .form-field > label.form-label {
    display: block; }
  .fairdee-modal-wrapper.corona-cards .custom-input-container .form-label {
    font-size: 14px;
    color: #4a4a4a; }

.fairdee-modal-wrapper.corona-cards .confirmation-step {
  text-align: center; }
  .fairdee-modal-wrapper.corona-cards .confirmation-step .coa-card .text {
    margin: 0; }
  .fairdee-modal-wrapper.corona-cards .confirmation-step .not-found {
    margin-top: 18px;
    line-height: 2; }
    .fairdee-modal-wrapper.corona-cards .confirmation-step .not-found .bold {
      font-family: 'sukh-bold'; }
  .fairdee-modal-wrapper.corona-cards .confirmation-step.min-360-wide {
    min-width: 360px;
    margin: auto; }
  .fairdee-modal-wrapper.corona-cards .confirmation-step .text {
    font-size: 14px;
    margin: 14px; }
  .fairdee-modal-wrapper.corona-cards .confirmation-step .custom-datepicker .text {
    margin: auto; }
  .fairdee-modal-wrapper.corona-cards .confirmation-step .important-text {
    font-size: 16px;
    font-weight: bold; }
  .fairdee-modal-wrapper.corona-cards .confirmation-step .dropdown-title {
    padding-bottom: 12px; }
    .fairdee-modal-wrapper.corona-cards .confirmation-step .dropdown-title.section-title {
      font-family: 'sukh-bold'; }
  .fairdee-modal-wrapper.corona-cards .confirmation-step .premium-slider .rc-slider-track {
    background-color: #eb7904; }
  .fairdee-modal-wrapper.corona-cards .confirmation-step .filter-wrap .each-filter .range-slider-wrap {
    max-width: 380px; }
  .fairdee-modal-wrapper.corona-cards .confirmation-step .ui.search.selection.dropdown .text {
    min-width: 92%; }

.fairdee-modal-wrapper.corona-cards .flow-steps {
  background-position-x: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 600px; }
  .fairdee-modal-wrapper.corona-cards .flow-steps.flow-steps-agent {
    background-image: url(images/corona/corona-insurance-flow.png); }
  .fairdee-modal-wrapper.corona-cards .flow-steps.flow-steps-member {
    background-image: url(images/corona/corona-insurance-flow-member.png); }
  .fairdee-modal-wrapper.corona-cards .flow-steps.flow-steps-covid-renewal {
    background-image: url(images/corona/corona-insurance-flow-renewal.png); }
  .fairdee-modal-wrapper.corona-cards .flow-steps.flow-steps-dengue {
    background-image: url(images/dengue/dengue-insurance-flow.png); }
  .fairdee-modal-wrapper.corona-cards .flow-steps.flow-steps-pa {
    background-image: url(images/pa/pa-insurance-flow.png); }

.accept-decline-invitation .img-wrap {
  text-align: center;
  margin: 12px 0 17px 0; }

.accept-decline-invitation .title-text {
  font-size: 20px !important;
  margin-bottom: 10px !important; }

.accept-decline-invitation .text {
  font-family: 'sukh-medium', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #282c3f;
  line-height: 1.25; }

.accept-decline-invitation .text-one {
  font-family: 'sukh-medium', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  /* The Fallback */
  color: rgba(0, 0, 0, 0.7);
  line-height: 1.25; }

.accept-decline-invitation .sub-title-text {
  font-family: 'sukh-medium', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
  line-height: 1.25;
  margin-bottom: 15px; }

.accept-decline-invitation .line {
  margin: 18px 0 16px 0;
  border-bottom: dashed 0.5px #b0b2c6; }

.accept-decline-invitation .buttons-wrap {
  text-align: center;
  margin-top: 32px; }
  .accept-decline-invitation .buttons-wrap .button {
    margin: 0 5px; }

.accept-decline-invitation .desc-wrap {
  margin-top: 20px; }
  .accept-decline-invitation .desc-wrap .text {
    font-family: 'sukh-regular', sans-serif;
    font-size: 14px;
    color: #4a4a4a;
    position: relative;
    padding-left: 20px;
    text-align: left;
    margin-bottom: 10px; }
    .accept-decline-invitation .desc-wrap .text:before {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: #4a4a4a;
      left: 0;
      top: 5px; }

.rewards-list .list-wrapper {
  padding: 0 16px; }
  .rewards-list .list-wrapper .ui.grid > .column:not(.row) {
    padding: 0 10px;
    border-bottom: 1px solid rgba(216, 216, 216, 0.8); }

.rewards-list.modal-content .mobile-header {
  margin-bottom: 16px !important; }

.rewards-list .text {
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 5px;
  padding-left: 0;
  font-family: 'sukh-light', sans-serif; }
  .rewards-list .text.red {
    color: #d8001a;
    text-align: right; }

.affiliate-info .label-text {
  margin-bottom: 12px; }

.affiliate-info .ui.selection.dropdown {
  border: 1px solid #cbcbcb;
  background: transparent;
  height: auto;
  min-height: auto;
  padding: 5px 10px;
  border-radius: 2px;
  min-width: 100%; }
  .affiliate-info .ui.selection.dropdown .menu {
    max-height: 180px; }
    .affiliate-info .ui.selection.dropdown .menu .item {
      padding: 8px !important; }
  .affiliate-info .ui.selection.dropdown .ui.form .field {
    margin: 0;
    padding-bottom: 16px; }
  .affiliate-info .ui.selection.dropdown .text {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #5f5f5f; }
  .affiliate-info .ui.selection.dropdown .icon {
    padding: 6px 10px;
    margin: 0;
    top: 0;
    right: 0; }

.affiliate-info .ui.checkbox.radio label {
  color: #4a4a4a !important;
  bottom: 0 !important; }

.affiliate-info .ui.form .field.mb0 {
  margin-bottom: 0; }

.image-file-viewer {
  padding: 10px;
  margin: 12px;
  height: auto;
  width: 100%;
  max-width: 600px;
  max-height: 500px;
  overflow: scroll; }
  .image-file-viewer .image-view {
    padding: 15px;
    text-align: center;
    border-bottom: 2px solid; }
    .image-file-viewer .image-view .payment-image {
      height: auto;
      max-width: 100%; }

.sum-insured-feedback-modal {
  padding: 24px; }
  .sum-insured-feedback-modal .form-wrapper .form-field {
    margin-bottom: 20px; }
    .sum-insured-feedback-modal .form-wrapper .form-field label {
      font-size: 14px;
      text-align: left;
      color: #535766;
      margin-bottom: 8px;
      display: block; }
  .sum-insured-feedback-modal .form-wrapper .button-wrap {
    text-align: right; }

@media screen and (max-width: 1024px) {
  .user-information .ui.form .field > label {
    display: block;
    bottom: 0px;
    position: absolute; }
  .pre-launch-popup .prelaunch-banner p {
    font-size: 12px; }
  .pre-launch-popup .img-wrap {
    margin: 20px 0; }
  .pre-launch-popup .bordered-text {
    padding: 10px;
    font-size: 14px;
    margin: 15px 0 20px 0; }
  .pre-launch-popup .title-text {
    font-size: 18px; }
  .pre-launch-popup .sub-text {
    font-size: 14px; }
  .pre-launch-popup .footer-wrap {
    position: relative;
    padding: 15px 0; }
    .pre-launch-popup .footer-wrap .text {
      font-size: 14px; }
    .pre-launch-popup .footer-wrap .insurer-list {
      margin-top: 0; }
      .pre-launch-popup .footer-wrap .insurer-list li {
        padding-right: 0;
        width: 33%;
        font-size: 14px;
        margin-bottom: 10px; }
  .fairdee-modal-wrapper.small.make-model .modal-content .sub-title {
    margin-bottom: 0 !important;
    font-size: 16px !important; }
  .fairdee-modal-wrapper.small.make-model .modal-content .ui.form {
    margin-top: 0 !important; }
  .fairdee-modal-wrapper.message .modal-content {
    padding: 160px 50px !important; }
    .fairdee-modal-wrapper.message .modal-content .sub-title {
      margin-bottom: 10px !important;
      line-height: 1.5; }
    .fairdee-modal-wrapper.message .modal-content .button {
      margin-top: 20px !important;
      width: 100%;
      padding: 14px;
      width: 100% !important;
      margin-top: 100px; }
  .fairdee-modal-wrapper.user-list {
    background-color: rgba(77, 92, 116, 0.8); }
    .fairdee-modal-wrapper.user-list .close {
      top: auto;
      bottom: 65px;
      right: calc(50% - 10px); }
    .fairdee-modal-wrapper.user-list .slick-prev,
    .fairdee-modal-wrapper.user-list .slick-next {
      top: auto;
      bottom: 40px;
      width: 30px;
      height: 30px;
      position: fixed; }
    .fairdee-modal-wrapper.user-list .slick-prev {
      background: transparent url(images/arrow-left.svg) no-repeat;
      background-size: 10px;
      border: 0;
      left: calc(50% - 80px);
      background-position: 15px center; }
      .fairdee-modal-wrapper.user-list .slick-prev:hover {
        background: transparent url(images/arrow-left.svg) no-repeat;
        background-size: 10px;
        border: 0;
        left: calc(50% - 80px);
        background-position: 15px center; }
    .fairdee-modal-wrapper.user-list .slick-next {
      background: transparent url(images/arrow-right.svg) no-repeat;
      background-size: 10px;
      border: 0;
      right: calc(50% - 80px);
      background-position: 20px center; }
      .fairdee-modal-wrapper.user-list .slick-next:hover {
        background: transparent url(images/arrow-right.svg) no-repeat;
        background-size: 10px;
        border: 0;
        right: calc(50% - 80px);
        background-position: 20px center; }
  .fairdee-modal-wrapper.corona-cards .with-pdf-link .button {
    align-self: center; }
  .fairdee-modal-wrapper.corona-cards .buttons-wrap-mobile-card {
    margin: 4px 0 0;
    position: relative;
    bottom: 0;
    width: 100%; }
    .fairdee-modal-wrapper.corona-cards .buttons-wrap-mobile-card div {
      text-align: right;
      padding: 4px 16px 8px 8px; }
    .fairdee-modal-wrapper.corona-cards .buttons-wrap-mobile-card .button {
      padding: 5px 24px;
      width: auto;
      font-size: 12px;
      font-family: sukh-bold;
      line-height: 1.67;
      color: #fff;
      border: 0.8px solid #f58b1e; }
  .fairdee-modal-wrapper.corona-cards .info-icon {
    width: 8px;
    height: 8px; }
  .fairdee-modal-wrapper.corona-cards .prev-next {
    position: fixed;
    bottom: 8px;
    width: 100%; }
    .fairdee-modal-wrapper.corona-cards .prev-next .next {
      padding: 5px 24px;
      right: 0; }
    .fairdee-modal-wrapper.corona-cards .prev-next .prev {
      padding: 5px 24px;
      left: 0; }
  .fairdee-modal-wrapper.corona-cards .download-button {
    text-align: center;
    margin-bottom: 10px; }
  .fairdee-modal-wrapper.corona-cards .confirmation-step {
    text-align: center; }
    .fairdee-modal-wrapper.corona-cards .confirmation-step .left-aligned {
      text-align: left; }
    .fairdee-modal-wrapper.corona-cards .confirmation-step .text {
      font-size: 14px;
      margin: 14px; }
    .fairdee-modal-wrapper.corona-cards .confirmation-step .important-text {
      font-size: 16px;
      font-weight: bold; }
    .fairdee-modal-wrapper.corona-cards .confirmation-step.filter-wrap {
      z-index: 0; }
  .fairdee-modal-wrapper.corona-cards .flow-steps {
    background-position-x: center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 600px; }
    .fairdee-modal-wrapper.corona-cards .flow-steps.flow-steps-agent {
      background-image: url(images/corona/corona-insurance-flow.png); }
    .fairdee-modal-wrapper.corona-cards .flow-steps.flow-steps-member {
      background-image: url(images/corona/corona-insurance-flow-member.png); }
  .make-model .modal-content .mobile-header {
    box-shadow: none; }
    .make-model .modal-content .mobile-header .current-page {
      text-align: left;
      padding-left: 40px; }
  .group.medium .modal-content, .group.large .modal-content {
    padding: 0; }
    .group.medium .modal-content .content-wrapper,
    .group.large .modal-content .content-wrapper {
      padding: 32px; }
    .group.medium .modal-content.invite-members .title-text, .group.large .modal-content.invite-members .title-text {
      margin: 0; }
    .group.medium .modal-content .mobile-header,
    .group.large .modal-content .mobile-header {
      margin-bottom: 20px; }
    .group.medium .modal-content .group-name label,
    .group.medium .modal-content .group-name input[type='text'],
    .group.large .modal-content .group-name label,
    .group.large .modal-content .group-name input[type='text'] {
      display: block !important;
      width: 100% !important;
      position: relative;
      margin-bottom: 5px !important; }
  .user .modal-content .mobile-header {
    margin-bottom: 0; }
    .user .modal-content .mobile-header .current-page {
      text-align: left; }
      .user .modal-content .mobile-header .current-page .title-text {
        padding-left: 25px; }
  .user .modal-content .user-wrapper {
    background: #f3f4f7; }
    .user .modal-content .user-wrapper .content-wrapper {
      padding: 16px;
      min-height: calc(var(--vh) - 60px); }
      .user .modal-content .user-wrapper .content-wrapper.policy-info, .user .modal-content .user-wrapper .content-wrapper.account {
        background: #fff; }
      .user .modal-content .user-wrapper .content-wrapper.account {
        padding-top: 45px; }
      .user .modal-content .user-wrapper .content-wrapper.refer-and-earn {
        padding-top: 2px;
        background: #fff; }
        .user .modal-content .user-wrapper .content-wrapper.refer-and-earn.rewards {
          background: #f3f4f7; }
      .user .modal-content .user-wrapper .content-wrapper .refer-and-earn {
        padding: 0; }
        .user .modal-content .user-wrapper .content-wrapper .refer-and-earn .sub-text {
          color: #282c3f !important;
          background: #fff;
          margin-left: -16px;
          margin-right: -16px;
          padding: 16px 0; }
        .user .modal-content .user-wrapper .content-wrapper .refer-and-earn .ui.tabular.menu .item {
          padding: 14px 18px;
          background: #fff;
          box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.02);
          border-radius: 6px;
          margin-bottom: 16px;
          position: relative; }
          .user .modal-content .user-wrapper .content-wrapper .refer-and-earn .ui.tabular.menu .item .text {
            font-size: 16px; }
          .user .modal-content .user-wrapper .content-wrapper .refer-and-earn .ui.tabular.menu .item .button {
            position: absolute;
            right: 10px;
            top: calc(50% - 15px);
            padding: 6px 12px; }
            .user .modal-content .user-wrapper .content-wrapper .refer-and-earn .ui.tabular.menu .item .button:focus, .user .modal-content .user-wrapper .content-wrapper .refer-and-earn .ui.tabular.menu .item .button:active {
              outline: none; }
        .user .modal-content .user-wrapper .content-wrapper .refer-and-earn .tabular-content {
          margin-top: 16px; }
      .user .modal-content .user-wrapper .content-wrapper .cashback-info .cashback-wrap {
        padding: 20px 24px;
        background: #fff;
        margin-bottom: 16px; }
        .user .modal-content .user-wrapper .content-wrapper .cashback-info .cashback-wrap .group-details {
          position: relative; }
          .user .modal-content .user-wrapper .content-wrapper .cashback-info .cashback-wrap .group-details .title-wrap {
            padding: 9px 0;
            background: #fff;
            margin-bottom: 0; }
            .user .modal-content .user-wrapper .content-wrapper .cashback-info .cashback-wrap .group-details .title-wrap img {
              display: none; }
            .user .modal-content .user-wrapper .content-wrapper .cashback-info .cashback-wrap .group-details .title-wrap .title-text {
              font-size: 14px; }
          .user .modal-content .user-wrapper .content-wrapper .cashback-info .cashback-wrap .group-details .button {
            position: absolute;
            top: -35px;
            right: 0;
            margin-top: 0; }
          .user .modal-content .user-wrapper .content-wrapper .cashback-info .cashback-wrap .group-details .grid .column {
            padding: 1rem 0 !important; }
        .user .modal-content .user-wrapper .content-wrapper .cashback-info .cashback-wrap .section {
          border-bottom: 0 !important;
          padding-bottom: 0; }
  .join-group .modal-content .mobile-header {
    margin-bottom: 0 !important; }
  .join-group .mobile-version {
    background: #f3f4f7;
    min-height: calc(var(--vh) - 55px); }
    .join-group .mobile-version .section-wrapper {
      padding: 0 16px; }
    .join-group .mobile-version .section {
      border-radius: 6px;
      background-color: #fff;
      box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.02);
      padding: 15px 18px;
      margin-top: 16px;
      display: table;
      width: 100%; }
      .join-group .mobile-version .section .content-section {
        display: table-cell;
        width: calc(100% - 100px);
        vertical-align: top; }
        .join-group .mobile-version .section .content-section .text {
          font-size: 12px;
          line-height: 1.14;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #282c3f;
          font-family: 'sukh-regular', sans-serif; }
          .join-group .mobile-version .section .content-section .text.link-text {
            color: #192383;
            text-decoration: underline; }
      .join-group .mobile-version .section .button-section {
        display: table-cell;
        width: 100px;
        vertical-align: middle; }
        .join-group .mobile-version .section .button-section .button {
          padding: 7px 14px;
          font-size: 12px; }
  .join-group .title-wrap {
    padding: 40px 30px 10px 30px;
    background: #fff; }
    .join-group .title-wrap .text {
      font-size: 16px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #282c3f;
      font-family: 'sukh-medium', sans-serif; } }

.search-list {
  margin-bottom: 40px; }
  .search-list .search-input {
    position: relative;
    background-position: right center;
    padding-right: 24px;
    border-bottom: 2px solid rgba(216, 216, 216, 0.3); }
    .search-list .search-input .ddl-arrow {
      position: absolute;
      right: 0; }
    .search-list .search-input.errors {
      border-bottom: 2px solid #d8001a;
      padding-bottom: 2px; }
    .search-list .search-input ::-webkit-input-placeholder {
      /* WebKit browsers */
      color: transparent; }
    .search-list .search-input :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: transparent; }
    .search-list .search-input ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: transparent; }
    .search-list .search-input :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: transparent; }
    .search-list .search-input input:-internal-autofill-selected {
      background-color: #fff !important; }
    .search-list .search-input input {
      font-size: 13px;
      color: #000000;
      box-shadow: none;
      border: 0;
      border-radius: 0;
      padding: 5px 0;
      color: #9b9b9b;
      font-size: 14px;
      font-family: 'sukh-light';
      letter-spacing: 0;
      text-transform: uppercase;
      width: 100%;
      outline: none; }
      .search-list .search-input input::selection {
        background-color: transparent; }
      .search-list .search-input input:focus {
        border: 0;
        border-bottom: 2px solid rgba(216, 216, 216, 0.3);
        border-radius: 0; }
      .search-list .search-input input:focus ~ label {
        bottom: 30px;
        font-size: 12px; }
      .search-list .search-input input:not(:placeholder-shown) ~ label {
        bottom: 30px;
        font-size: 12px; }
    .search-list .search-input > label {
      width: 100%;
      font-size: 14px;
      position: absolute;
      bottom: 10px;
      z-index: 1;
      transition: 0.2s ease all;
      font-size: 14px;
      color: #9b9b9b;
      display: inline-block;
      left: 0;
      pointer-events: none; }
      .search-list .search-input > label > span {
        color: #d8001a; }
      .search-list .search-input > label.move-up {
        bottom: 35px; }
  .search-list .popular-list-wrap {
    margin: 20px 0 0;
    animation-duration: 1s;
    animation-delay: 0s;
    min-height: 120px; }
    .search-list .popular-list-wrap .text {
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #000000; }
    .search-list .popular-list-wrap .list-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      margin: 10px 0 0; }
      .search-list .popular-list-wrap .list-wrap .list {
        animation-duration: 1s;
        animation-delay: 0s;
        border-radius: 2px;
        border: solid 1px #b6b6b6;
        padding: 10px;
        font-size: 12px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        cursor: pointer;
        text-transform: capitalize;
        text-align: center; }

.ui.form .search-list .search-input {
  position: relative; }
  .ui.form .search-list .search-input input[type="text"] {
    box-shadow: none;
    border: 0;
    border-radius: 0;
    padding: 4px 0 0 !important;
    font-size: 16px;
    color: #4a4a4a;
    width: 100%;
    font-family: 'sukh-medium'; }
    .ui.form .search-list .search-input input[type="text"]:focus {
      border: 0;
      border-radius: 0; }
    .ui.form .search-list .search-input input[type="text"]:focus ~ label {
      bottom: 30px;
      color: #f58b1e;
      font-size: 14px;
      color: #f58b1e; }
    .ui.form .search-list .search-input input[type="text"]:not(:placeholder-shown) ~ label {
      bottom: 30px;
      font-size: 14px; }
  .ui.form .search-list .search-input > label {
    width: 100%;
    position: absolute;
    bottom: 8px;
    z-index: 1;
    transition: 0.2s ease all;
    font-size: 16px;
    color: #4a4a4a;
    display: inline-block;
    left: 0;
    pointer-events: none; }
    .ui.form .search-list .search-input > label.selected {
      color: #9b9b9b !important; }
    .ui.form .search-list .search-input > label.move-up {
      bottom: 35px; }

@media screen and (max-width: 1024px) {
  .ui.form .search-list .search-input input[type="text"] {
    box-shadow: none;
    border: 0;
    border-radius: 0;
    width: 100%;
    outline: none; }
  .ui.form .search-list .search-input > label {
    font-size: 14px; }
  .search-list .search-input input {
    box-shadow: none;
    border: 0;
    border-radius: 0;
    width: 100%;
    outline: none; }
  .search-list .search-input > label {
    font-size: 14px; }
  .search-list .popular-list-wrap {
    min-height: auto; } }

header {
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;
  height: 62px; }
  header[lang="thai"] {
    font-family: 'sukh-regular'; }
  header.with-shadow {
    box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.07); }
  header .nav-wrap {
    padding: 0 65px;
    height: 100%;
    display: table;
    width: 100%;
    max-width: 1280px;
    margin: auto; }
    header .nav-wrap.authenticated .toggle-menu {
      position: absolute;
      right: 20px;
      top: 25px;
      display: none; }
  header .logo-wrap {
    display: table-cell;
    vertical-align: middle;
    font-size: 30px;
    cursor: pointer; }
    header .logo-wrap .menu {
      display: none; }
    header .logo-wrap .logo-text {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #5c5c5c;
      display: inline-block; }
      header .logo-wrap .logo-text .line {
        border-right: 1px solid #e2e2e2;
        display: inline-block;
        vertical-align: middle;
        margin: 0 28px;
        height: 28px; }
    header .logo-wrap .inline-img {
      display: inline-block;
      vertical-align: middle; }
    header .logo-wrap img {
      max-width: 100%;
      vertical-align: middle; }
      header .logo-wrap img.broker-img {
        height: 50px; }
    header .logo-wrap.corporate .inline-img {
      display: inline-block;
      vertical-align: middle; }
      header .logo-wrap.corporate .inline-img:first-child {
        border-right: solid 1px rgba(151, 151, 151, 0.2);
        padding-right: 20px; }
      header .logo-wrap.corporate .inline-img:last-child {
        padding-left: 20px; }
  header .user-det-wrap {
    cursor: pointer;
    display: table-cell;
    text-align: right;
    vertical-align: middle; }
    header .user-det-wrap .img-wrap {
      width: 33px;
      height: 33px;
      border-radius: 100%;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px; }
      header .user-det-wrap .img-wrap img {
        border-radius: 100%;
        max-width: 100%;
        width: 33px;
        height: 33px;
        object-fit: cover; }
    header .user-det-wrap .name {
      font-family: Avenir-Roman;
      font-family: 'sukh-light';
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      margin: 0;
      margin-right: 10px; }
      header .user-det-wrap .name + .language-switch {
        margin-left: 30px; }
    header .user-det-wrap .arrow {
      display: inline-block;
      width: 9px; }
  header .container {
    position: relative; }
  header .social-wrap {
    position: relative;
    margin-bottom: 5px; }
    header .social-wrap ul {
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: right; }
      header .social-wrap ul li {
        display: inline-block;
        margin-right: 15px; }
        header .social-wrap ul li a {
          font-family: 'sukh-light';
          letter-spacing: 0;
          font-size: 14px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #192383; }
        header .social-wrap ul li:last-child {
          margin-right: 0; }
  header nav {
    display: table-cell;
    width: calc(100% - 130px);
    vertical-align: middle; }
    header nav ul {
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: right; }
      header nav ul li {
        display: inline-block;
        margin-right: 28px; }
        header nav ul li.toggle-menu {
          display: none; }
        header nav ul li:last-child {
          margin-right: 0; }
        header nav ul li .header-button {
          border-radius: 4px;
          background-color: #ffffff;
          border: solid 1px #e5e5e5;
          padding: 4.5px 16px; }
        header nav ul li a {
          font-size: 16px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #282c3f;
          /* The Fallback */
          color: rgba(40, 44, 63, 0.8);
          text-decoration: none;
          border-bottom: 3px solid transparent;
          cursor: pointer;
          display: inline-block;
          position: relative;
          font-family: 'sukh-light'; }
          header nav ul li a:hover {
            color: inherit; }
            header nav ul li a:hover:before {
              content: '';
              width: 100%;
              height: 3px;
              background: #f58b1e;
              bottom: -21px;
              position: absolute; }
          header nav ul li a.header-button:hover:before {
            display: none; }
          header nav ul li a.active-menu {
            color: #192383; }
            header nav ul li a.active-menu:before {
              content: '';
              width: 100%;
              height: 3px;
              background: #f58b1e;
              bottom: -23px;
              position: absolute; }
  header .text-header {
    background-color: #192383;
    padding: 12px;
    display: none; }
    header .text-header p {
      font-size: 14px;
      font-family: 'sukh-light';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }
      header .text-header p span {
        font-family: 'sukh-medium'; }
  header .responsive-navigation {
    display: none; }

.language-switch {
  display: inline-block;
  margin: 0 20px 0 12px;
  margin-left: 32px; }
  .language-switch p {
    margin: 0;
    display: inline-block;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
    padding: 4px 8px;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    vertical-align: middle; }
    .language-switch p:first-child {
      border-radius: 4px 0 0 4px;
      border-right: 1px solid #d3d2d2; }
    .language-switch p:last-child {
      border-radius: 0 4px 4px 0; }
    .language-switch p.active {
      border: 1px solid solid 1px rgba(0, 0, 0, 0.1);
      box-shadow: none;
      color: #f58b1e;
      background: #192383; }

.mobile-header {
  display: none; }

@media screen and (max-width: 1024px) {
  .language-switch {
    margin-left: 0; }
    .language-switch p {
      line-height: 24px; }
  header {
    height: 56px; }
    header .nav-wrap {
      padding: 15px 20px 12px 20px; }
      header .nav-wrap.hide {
        display: none; }
      header .nav-wrap.authenticated .toggle-menu {
        display: block; }
    header .user-det-wrap {
      display: none; }
    header .logo-wrap {
      width: 160px; }
      header .logo-wrap .logo-text {
        display: none; }
      header .logo-wrap .menu {
        display: inline-block;
        margin-right: 15px;
        vertical-align: baseline;
        top: -2px;
        position: relative; }
      header .logo-wrap img {
        max-width: 80px;
        vertical-align: unset; }
        header .logo-wrap img.broker-img {
          height: 30px; }
      header .logo-wrap.corporate .inline-img {
        display: inline-block;
        vertical-align: middle; }
        header .logo-wrap.corporate .inline-img:first-child {
          padding-right: 10px;
          border: 0; }
        header .logo-wrap.corporate .inline-img:last-child {
          padding-left: 10px; }
    header nav ul li {
      display: none;
      margin: 0; }
      header nav ul li.toggle-menu, header nav ul li.login-register {
        display: inline-block; }
        header nav ul li.toggle-menu .header-button, header nav ul li.login-register .header-button {
          padding: 0;
          border: 0; }
      header nav ul li.login-register {
        margin-right: 20px; }
        header nav ul li.login-register.desktop {
          display: none; }
        header nav ul li.login-register a {
          font-size: 14px;
          text-transform: uppercase; }
    header .text-header {
      border-bottom: 1px solid #fff; }
      header .text-header p {
        font-size: 12px; }
    header .responsive-navigation {
      display: block;
      position: fixed;
      background-color: rgba(50, 50, 50, 0.5);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      transform: translateX(100%);
      -webkit-transform: translateX(100%); }
      header .responsive-navigation.show {
        transform: translateX(0%);
        -webkit-transform: translateX(0%); }
      header .responsive-navigation.hide {
        animation: slide-out 0.5s forwards;
        -webkit-animation: slide-out 0.5s forwards; }
      header .responsive-navigation .slide-in {
        animation: slide-in 0.5s forwards;
        -webkit-animation: slide-in 0.5s forwards; }
      header .responsive-navigation .slide-out {
        animation: slide-out 0.5s forwards;
        -webkit-animation: slide-out 0.5s forwards; }
  @keyframes slide-in {
    100% {
      transform: translateX(0%); } }
  @-webkit-keyframes slide-in {
    100% {
      -webkit-transform: translateX(0%); } }
  @keyframes slide-out {
    0% {
      transform: translateX(0%); }
    100% {
      transform: translateX(100%); } }
  @-webkit-keyframes slide-out {
    0% {
      -webkit-transform: translateX(0%); }
    100% {
      -webkit-transform: translateX(100%); } }
  @keyframes fade {
    0% {
      opacity: 1; }
    100% {
      opacity: 0; } }
      header .responsive-navigation .navigation {
        width: calc(100% - 60px);
        height: 100%;
        background: #fff;
        margin-left: 60px;
        transform: translateX(100%);
        -webkit-transform: translateX(100%); }
        header .responsive-navigation .navigation .empty-wrap {
          height: 24px;
          background-color: rgba(0, 0, 0, 0.2);
          width: 100%; }
        header .responsive-navigation .navigation .navigation-wrap ul {
          border-bottom: solid 0.5px rgba(176, 178, 198, 0.7);
          padding: 13px 35px; }
          header .responsive-navigation .navigation .navigation-wrap ul:last-child {
            border-bottom: 0; }
          header .responsive-navigation .navigation .navigation-wrap ul li {
            padding: 0;
            margin-bottom: 25px;
            position: relative; }
            header .responsive-navigation .navigation .navigation-wrap ul li .language-switch {
              position: absolute;
              right: 0;
              top: -5px;
              display: inline-block; }
            header .responsive-navigation .navigation .navigation-wrap ul li:last-child {
              margin-bottom: 0; }
            header .responsive-navigation .navigation .navigation-wrap ul li a {
              font-size: 16px;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: #282c3f;
              display: block; }
  .mobile-header.show {
    display: block;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 16; }
  .mobile-header .current-page {
    font-family: 'sukh-bold';
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #192383;
    padding: 19px;
    text-transform: uppercase; }
    .mobile-header .current-page img {
      vertical-align: middle;
      margin-right: 25px;
      margin-top: -2px; } }

.sort-wrap {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 15;
  display: none; }
  .sort-wrap.show {
    display: block; }
  .sort-wrap .click-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100% - 260px); }
  .sort-wrap .content-wrap {
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    padding-bottom: 25px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
    .sort-wrap .content-wrap .title-text {
      font-family: 'sukh-light';
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      border-bottom: 1px solid #f6f6f6;
      margin-bottom: 25px;
      padding: 8px 0; }
    .sort-wrap .content-wrap .text {
      font-family: 'sukh-regular';
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.57;
      letter-spacing: normal;
      text-align: center;
      color: #9b9b9b;
      cursor: pointer; }

.login-signup.small {
  display: flex;
  align-items: center; }
  .login-signup.small .modal-content {
    height: auto;
    max-width: 428px;
    width: 100%;
    margin: auto;
    padding: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    border: 0; }
    .login-signup.small .modal-content.signup {
      max-height: 90%;
      display: flex;
      flex-direction: column;
      overflow: hidden; }
    .login-signup.small .modal-content.login .title-text, .login-signup.small .modal-content.signup .title-text, .login-signup.small .modal-content.step .title-text {
      display: block; }
      .login-signup.small .modal-content.login .title-text.text, .login-signup.small .modal-content.signup .title-text.text, .login-signup.small .modal-content.step .title-text.text {
        display: none; }
    .login-signup.small .modal-content.login .sub-title, .login-signup.small .modal-content.signup .sub-title, .login-signup.small .modal-content.step .sub-title {
      font-family: 'sukh-light', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      line-height: 1; }
    .login-signup.small .modal-content.login .line, .login-signup.small .modal-content.signup .line, .login-signup.small .modal-content.step .line {
      width: 100%;
      border-bottom: 1px solid rgba(151, 151, 151, 0.17);
      margin: 12px auto; }
    .login-signup.small .modal-content.login .button-wrap .button, .login-signup.small .modal-content.signup .button-wrap .button, .login-signup.small .modal-content.step .button-wrap .button {
      width: 100%;
      padding: 12px 40px;
      font-size: 16px;
      font-family: "sukh-medium";
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      text-transform: capitalize; }
    .login-signup.small .modal-content.login .link-text, .login-signup.small .modal-content.signup .link-text, .login-signup.small .modal-content.step .link-text {
      font-size: 14px;
      font-family: "sukh-bold";
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383;
      display: inline-block;
      margin-bottom: 8px;
      text-align: center;
      text-decoration: none; }
      .login-signup.small .modal-content.login .link-text.block, .login-signup.small .modal-content.signup .link-text.block, .login-signup.small .modal-content.step .link-text.block {
        display: block;
        margin-top: 12px; }
      .login-signup.small .modal-content.login .link-text.text, .login-signup.small .modal-content.signup .link-text.text, .login-signup.small .modal-content.step .link-text.text {
        font-size: 14px;
        font-family: "sukh-regular";
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        display: block;
        text-align: center; }
        .login-signup.small .modal-content.login .link-text.text.small, .login-signup.small .modal-content.signup .link-text.text.small, .login-signup.small .modal-content.step .link-text.text.small {
          font-size: 9px;
          font-family: "sukh-light";
          font-style: normal;
          font-stretch: normal;
          line-height: 1.44;
          letter-spacing: normal; }
      .login-signup.small .modal-content.login .link-text.no-margin, .login-signup.small .modal-content.signup .link-text.no-margin, .login-signup.small .modal-content.step .link-text.no-margin {
        margin: 0; }
      .login-signup.small .modal-content.login .link-text.small, .login-signup.small .modal-content.signup .link-text.small, .login-signup.small .modal-content.step .link-text.small {
        font-size: 9px; }
      .login-signup.small .modal-content.login .link-text a, .login-signup.small .modal-content.signup .link-text a, .login-signup.small .modal-content.step .link-text a {
        color: #192383;
        cursor: pointer;
        text-decoration: none; }
        .login-signup.small .modal-content.login .link-text a.bold, .login-signup.small .modal-content.signup .link-text a.bold, .login-signup.small .modal-content.step .link-text a.bold {
          font-family: "sukh-bold"; }
    .login-signup.small .modal-content.step .mobile-header {
      box-shadow: none; }
    .login-signup.small .modal-content.step .sub-title {
      font-size: 16px;
      color: #4a4a4a;
      font-family: 'sukh-light', sans-serif;
      margin-bottom: 30px;
      line-height: 1.5; }
    .login-signup.small .modal-content.step .ui.form .button-wrap {
      margin-top: 40px !important; }
    .login-signup.small .modal-content.signup .title-text, .login-signup.small .modal-content.login .title-text {
      margin-top: 0; }
      .login-signup.small .modal-content.signup .title-text.text, .login-signup.small .modal-content.login .title-text.text {
        font-size: 18px;
        font-family: "sukh-light";
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #4a4a4a; }
    .login-signup.small .modal-content.login {
      padding: 52px; }
      .login-signup.small .modal-content.login .title-text {
        margin-bottom: 44px; }
      .login-signup.small .modal-content.login .link-text {
        margin: 0 0 8px 0; }
      .login-signup.small .modal-content.login .button-wrap {
        margin-top: 38px !important; }
    .login-signup.small .modal-content.forgot-password {
      padding: 15px 30px 25px 30px; }
      .login-signup.small .modal-content.forgot-password .title-text {
        font-family: 'sukh-medium', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #192383;
        margin-bottom: 30px;
        margin-top: 0; }
      .login-signup.small .modal-content.forgot-password .with-padding .img-wrap {
        height: 67px;
        margin-bottom: 10px;
        text-align: center;
        margin-top: 30px; }
      .login-signup.small .modal-content.forgot-password .sub-title {
        font-family: 'sukh-light', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #4a4a4a; }
      .login-signup.small .modal-content.forgot-password .form {
        margin-top: 23px; }
      .login-signup.small .modal-content.forgot-password .button-wrap {
        margin: 14px 0 22px 0; }
        .login-signup.small .modal-content.forgot-password .button-wrap .button {
          width: 100%; }
      .login-signup.small .modal-content.forgot-password .img-wrap {
        height: 67px;
        margin-bottom: 10px;
        text-align: center; }
      .login-signup.small .modal-content.forgot-password .text {
        font-family: 'sukh-light', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #4a4a4a; }
        .login-signup.small .modal-content.forgot-password .text[lang="english"] {
          font-family: 'sukh-light';
          letter-spacing: 0; }
    .login-signup.small .modal-content .welcome-header {
      border-radius: 4px 4px 0 0;
      background-color: #1e3250;
      padding: 16px; }
      .login-signup.small .modal-content .welcome-header .text-one {
        font-size: 16px;
        font-family: "sukh-bold";
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 2px; }
      .login-signup.small .modal-content .welcome-header .text-two {
        font-size: 12px;
        font-family: "sukh-regular";
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff; }
    .login-signup.small .modal-content .title-text {
      font-size: 16px;
      font-family: "sukh-light";
      text-align: center;
      color: #525252;
      margin: 0;
      cursor: pointer;
      line-height: 1; }
      .login-signup.small .modal-content .title-text span {
        font-family: "sukh-bold";
        color: #192383; }
      .login-signup.small .modal-content .title-text img {
        width: 95%; }
    .login-signup.small .modal-content .signup-container {
      max-height: calc(100% - 210px);
      overflow-y: auto;
      padding-bottom: 24px; }
      .login-signup.small .modal-content .signup-container.loading {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 24px; }
      .login-signup.small .modal-content .signup-container::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #cecece; }
      .login-signup.small .modal-content .signup-container::-webkit-scrollbar {
        width: 2px;
        background-color: #cecece; }
      .login-signup.small .modal-content .signup-container::-webkit-scrollbar-thumb {
        background-color: #cecece; }
      .login-signup.small .modal-content .signup-container > .section {
        margin-top: 20px; }
      .login-signup.small .modal-content .signup-container .ui.form {
        margin-top: 0; }
      .login-signup.small .modal-content .signup-container .section {
        padding: 0 60px; }
    .login-signup.small .modal-content .bottom-content {
      padding: 12px; }
      .login-signup.small .modal-content .bottom-content .button-wrap {
        margin-bottom: 8px; }
    .login-signup.small .modal-content .blue-messag {
      font-size: 10px;
      font-family: "sukh-medium";
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383;
      margin-top: 6px; }
    .login-signup.small .modal-content .mobile-header .current-page {
      position: absolute;
      top: 20px;
      left: 20px; }
    .login-signup.small .modal-content .referee-wrap {
      margin-bottom: 20px; }
      .login-signup.small .modal-content .referee-wrap .img-wrap {
        text-align: center;
        margin-bottom: 15px; }
        .login-signup.small .modal-content .referee-wrap .img-wrap img {
          width: 75px;
          height: 75px;
          object-fit: cover;
          border-radius: 100%; }
      .login-signup.small .modal-content .referee-wrap .text {
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #4a4a4a; }
    .login-signup.small .modal-content .or {
      position: relative;
      text-align: center;
      padding: 18px 0; }
      .login-signup.small .modal-content .or span {
        display: inline-block;
        font-size: 14px;
        color: rgba(106, 106, 106, 0.85);
        padding: 0 8px;
        position: relative;
        z-index: 2;
        background: #fff; }
      .login-signup.small .modal-content .or:before {
        content: "";
        width: 100%;
        border-bottom: solid 1px #e6e6e6;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 1;
        height: 1px; }
    .login-signup.small .modal-content .buttons-wrap .button {
      margin: 5px 0; }
    .login-signup.small .modal-content .buttons-wrap.actions {
      display: inline-block;
      width: 100%; }
      .login-signup.small .modal-content .buttons-wrap.actions:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0; }
      .login-signup.small .modal-content .buttons-wrap.actions .button {
        border: solid 1px #9b9b9b;
        font-family: 'sukh-medium', sans-serif;
        font-size: 16px;
        color: #192383;
        padding: 4px 10px;
        display: inline-block;
        width: 115px;
        margin: 0;
        text-transform: none;
        background: #fff; }
        .login-signup.small .modal-content .buttons-wrap.actions .button:first-child {
          float: left; }
        .login-signup.small .modal-content .buttons-wrap.actions .button:last-child {
          float: right; }
    .login-signup.small .modal-content .ui.form {
      max-width: 100%; }
      .login-signup.small .modal-content .ui.form ::-webkit-input-placeholder {
        /* WebKit browsers */
        color: transparent !important; }
      .login-signup.small .modal-content .ui.form :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: transparent !important; }
      .login-signup.small .modal-content .ui.form ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent !important; }
      .login-signup.small .modal-content .ui.form :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent !important; }
      .login-signup.small .modal-content .ui.form textarea::-webkit-input-placeholder {
        /* WebKit browsers */
        color: transparent; }
      .login-signup.small .modal-content .ui.form textarea:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: transparent !important; }
      .login-signup.small .modal-content .ui.form textarea::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent !important; }
      .login-signup.small .modal-content .ui.form textarea:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent !important; }
      .login-signup.small .modal-content .ui.form .inline-error {
        margin-left: 0;
        bottom: -16px;
        top: auto;
        position: absolute;
        font-size: 10px; }
        .login-signup.small .modal-content .ui.form .inline-error.with-offset {
          top: 64px; }
      .login-signup.small .modal-content .ui.form .ui.checkbox input:checked ~ .box:after,
      .login-signup.small .modal-content .ui.form .ui.checkbox input:checked ~ label:after {
        width: 14px;
        height: 14px;
        border: solid 1px #192383;
        border-radius: 2px;
        background: #fff;
        color: #192383 !important;
        font-size: 11px;
        font-weight: lighter;
        padding: 0 0 0 1px; }
      .login-signup.small .modal-content .ui.form .ui.checkbox label {
        font-family: 'sukh-light', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a; }
        .login-signup.small .modal-content .ui.form .ui.checkbox label:before {
          width: 14px;
          height: 14px;
          border: solid 1px #192383;
          border-radius: 0; }
      .login-signup.small .modal-content .ui.form .ui.dropdown {
        padding: 6px 13px;
        min-height: 35px;
        height: auto; }
        .login-signup.small .modal-content .ui.form .ui.dropdown .text {
          font-size: 14px; }
          .login-signup.small .modal-content .ui.form .ui.dropdown .text.default {
            color: #9b9b9b; }
        .login-signup.small .modal-content .ui.form .ui.dropdown.fluid input.search {
          font-size: 14px;
          padding: 0.67857143em 2.1em 0.67857143em 1em; }
        .login-signup.small .modal-content .ui.form .ui.dropdown .icon {
          top: 6px;
          right: 0; }
      .login-signup.small .modal-content .ui.form input[type="text"],
      .login-signup.small .modal-content .ui.form input[type="email"],
      .login-signup.small .modal-content .ui.form input[type="password"],
      .login-signup.small .modal-content .ui.form input[type="number"],
      .login-signup.small .modal-content .ui.form input[type="radio"],
      .login-signup.small .modal-content .ui.form .ui.fluid.dropdown,
      .login-signup.small .modal-content .ui.form textarea {
        width: 100%;
        padding: 4px 0 !important;
        font-size: 16px !important;
        border-radius: 2px;
        border: solid 1px #dcd9d6;
        background-color: #ffffff;
        color: #000;
        font-family: "sukh-regular";
        padding: 6px !important;
        line-height: 1; }
        .login-signup.small .modal-content .ui.form input[type="text"]:focus,
        .login-signup.small .modal-content .ui.form input[type="email"]:focus,
        .login-signup.small .modal-content .ui.form input[type="password"]:focus,
        .login-signup.small .modal-content .ui.form input[type="number"]:focus,
        .login-signup.small .modal-content .ui.form input[type="radio"]:focus,
        .login-signup.small .modal-content .ui.form .ui.fluid.dropdown:focus,
        .login-signup.small .modal-content .ui.form textarea:focus {
          border: solid 1px #ff901d !important;
          background-color: #fffcf2 !important; }
        .login-signup.small .modal-content .ui.form input[type="text"]:focus ~ label,
        .login-signup.small .modal-content .ui.form input[type="email"]:focus ~ label,
        .login-signup.small .modal-content .ui.form input[type="password"]:focus ~ label,
        .login-signup.small .modal-content .ui.form input[type="number"]:focus ~ label,
        .login-signup.small .modal-content .ui.form input[type="radio"]:focus ~ label,
        .login-signup.small .modal-content .ui.form .ui.fluid.dropdown:focus ~ label,
        .login-signup.small .modal-content .ui.form textarea:focus ~ label {
          color: #f58b1e; }
      .login-signup.small .modal-content .ui.form .inline.field,
      .login-signup.small .modal-content .ui.form .field {
        width: 100%;
        position: relative;
        margin: 0 0 18px 0;
        display: flex;
        flex-direction: column; }
        .login-signup.small .modal-content .ui.form .inline.field .radio-group span:last-child,
        .login-signup.small .modal-content .ui.form .field .radio-group span:last-child {
          font-size: 14px; }
        .login-signup.small .modal-content .ui.form .inline.field > div,
        .login-signup.small .modal-content .ui.form .field > div {
          order: 1; }
        .login-signup.small .modal-content .ui.form .inline.field > input,
        .login-signup.small .modal-content .ui.form .field > input {
          order: 2; }
        .login-signup.small .modal-content .ui.form .inline.field .inline-error,
        .login-signup.small .modal-content .ui.form .field .inline-error {
          order: 3; }
        .login-signup.small .modal-content .ui.form .inline.field.with-mb,
        .login-signup.small .modal-content .ui.form .field.with-mb {
          margin-bottom: 60px; }
        .login-signup.small .modal-content .ui.form .inline.field.errors input[type="text"],
        .login-signup.small .modal-content .ui.form .inline.field.errors input[type="email"],
        .login-signup.small .modal-content .ui.form .inline.field.errors input[type="password"],
        .login-signup.small .modal-content .ui.form .inline.field.errors input[type="number"],
        .login-signup.small .modal-content .ui.form .inline.field.errors input[type="radio"],
        .login-signup.small .modal-content .ui.form .inline.field.errors textarea,
        .login-signup.small .modal-content .ui.form .field.errors input[type="text"],
        .login-signup.small .modal-content .ui.form .field.errors input[type="email"],
        .login-signup.small .modal-content .ui.form .field.errors input[type="password"],
        .login-signup.small .modal-content .ui.form .field.errors input[type="number"],
        .login-signup.small .modal-content .ui.form .field.errors input[type="radio"],
        .login-signup.small .modal-content .ui.form .field.errors textarea {
          border: 1px solid #d8001a; }
        .login-signup.small .modal-content .ui.form .inline.field.errors > label,
        .login-signup.small .modal-content .ui.form .field.errors > label {
          color: #d8001a; }
        .login-signup.small .modal-content .ui.form .inline.field.mb-0,
        .login-signup.small .modal-content .ui.form .field.mb-0 {
          margin-bottom: 0; }
        .login-signup.small .modal-content .ui.form .inline.field.normal-input-label label,
        .login-signup.small .modal-content .ui.form .field.normal-input-label label {
          font-size: 14px;
          font-family: "sukh-regular";
          color: #000; }
        .login-signup.small .modal-content .ui.form .inline.field > label,
        .login-signup.small .modal-content .ui.form .field > label {
          font-size: 14px;
          font-family: "sukh-bold";
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #454346;
          display: block; }
        .login-signup.small .modal-content .ui.form .inline.field .ui.input,
        .login-signup.small .modal-content .ui.form .field .ui.input {
          width: 100% !important;
          margin-top: 20px; }
          .login-signup.small .modal-content .ui.form .inline.field .ui.input input[type="text"],
          .login-signup.small .modal-content .ui.form .inline.field .ui.input input[type="email"],
          .login-signup.small .modal-content .ui.form .inline.field .ui.input input[type="password"],
          .login-signup.small .modal-content .ui.form .inline.field .ui.input input[type="number"],
          .login-signup.small .modal-content .ui.form .inline.field .ui.input input[type="radio"],
          .login-signup.small .modal-content .ui.form .inline.field .ui.input textarea,
          .login-signup.small .modal-content .ui.form .field .ui.input input[type="text"],
          .login-signup.small .modal-content .ui.form .field .ui.input input[type="email"],
          .login-signup.small .modal-content .ui.form .field .ui.input input[type="password"],
          .login-signup.small .modal-content .ui.form .field .ui.input input[type="number"],
          .login-signup.small .modal-content .ui.form .field .ui.input input[type="radio"],
          .login-signup.small .modal-content .ui.form .field .ui.input textarea {
            width: 100%; }
      .login-signup.small .modal-content .ui.form .mb-10 {
        margin-bottom: 10px; }
      .login-signup.small .modal-content .ui.form .mb-0 {
        margin-bottom: 0 !important; }

@media screen and (max-width: 1024px) {
  .login-signup.small .close {
    display: none; }
  .login-signup.small .content-wrapper {
    padding: 0 40px; }
  .login-signup.small .modal-content {
    padding: 0;
    min-height: 100%;
    padding-top: 80px; }
    .login-signup.small .modal-content .signup-container .section {
      padding: 0 24px; }
    .login-signup.small .modal-content .or {
      display: none; }
    .login-signup.small .modal-content .title-text {
      text-align: center; }
      .login-signup.small .modal-content .title-text img {
        max-width: 95%;
        width: 248px; }
      .login-signup.small .modal-content .title-text .button {
        padding: 0;
        width: 280px;
        font-size: 14px;
        text-transform: none;
        margin: 6px auto; }
        .login-signup.small .modal-content .title-text .button span {
          width: 44px;
          height: 44px; }
    .login-signup.small .modal-content .ui.form .inline-error.with-offset {
      top: auto; }
    .login-signup.small .modal-content .ui.form .field > label {
      display: block; }
      .login-signup.small .modal-content .ui.form .field > label.with-offset {
        top: -44px; }
    .login-signup.small .modal-content .ui.form .mb-10 {
      margin-bottom: 20px !important; }
    .login-signup.small .modal-content .ui.form .button-wrap {
      margin-top: 25px !important; }
      .login-signup.small .modal-content .ui.form .button-wrap .button {
        padding: 13px 40px;
        font-size: 14px;
        width: 100%; }
    .login-signup.small .modal-content .line {
      display: none; }
    .login-signup.small .modal-content.forgot-password {
      padding: 80px 0 0; }
      .login-signup.small .modal-content.forgot-password .title-text {
        margin: 0; }
      .login-signup.small .modal-content.forgot-password .mobile-header .current-page {
        position: relative;
        left: 0;
        top: 0;
        padding: 0;
        text-align: center; }
      .login-signup.small .modal-content.forgot-password .with-padding {
        padding: 0 40px; }
        .login-signup.small .modal-content.forgot-password .with-padding .img-wrap {
          height: 67px;
          margin-bottom: 10px;
          text-align: center; }
    .login-signup.small .modal-content.signup {
      max-height: none;
      height: auto;
      padding: 60px 0 0; }
      .login-signup.small .modal-content.signup .welcome-header {
        border-radius: 0;
        display: none; }
      .login-signup.small .modal-content.signup .signup-container {
        max-height: none; }
    .login-signup.small .modal-content.login {
      padding: 90px 24px 24px; } }

@media screen and (max-width: 768px) {
  .login-signup.small .modal-content.signup {
    max-width: 100%;
    position: relative; } }

.empty-message-wrapper {
  padding: 32px;
  text-align: center !important;
  background: transparent; }
  .empty-message-wrapper img {
    margin-bottom: 16px; }
  .empty-message-wrapper .text {
    font-size: 20px;
    font-family: 'sukh-bold';
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3d486c;
    margin-bottom: 8px; }
  .empty-message-wrapper .sub-text {
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #3d486c; }
  .empty-message-wrapper button,
  .empty-message-wrapper a {
    border-radius: 2px;
    border: solid 1px #f58b1e;
    background-color: #f58b1e;
    cursor: pointer;
    outline: none;
    padding: 14px;
    max-width: 240px;
    width: 100%;
    margin: 20px auto auto;
    font-size: 14px;
    font-family: 'sukh-medium';
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: block; }

.circular-progress {
  padding: 32px;
  text-align: center !important;
  background: #fff; }

.search-input-filter {
  padding: 12px;
  display: flex;
  width: 100%;
  align-items: center; }
  .search-input-filter input {
    font-size: 14px;
    font-family: 'sukh-light';
    color: #8c93a4;
    background: url(images/search.svg) no-repeat;
    background-position: 12px;
    background-size: 20px;
    padding: 12px 12px 12px 36px;
    border: 1px solid #dedede;
    outline: none; }
  .search-input-filter .date-input {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .search-input-filter .date-input .date-field {
      margin-right: 12px; }
      .search-input-filter .date-input .date-field > div {
        border: 1px solid #dedede; }
        .search-input-filter .date-input .date-field > div:before {
          border-bottom: 0; }
        .search-input-filter .date-input .date-field > div:hover:before {
          border-bottom: 0 !important; }
    .search-input-filter .date-input input {
      font-size: 14px;
      font-family: 'sukh-light';
      color: #8c93a4;
      background: none;
      background-position: 12px;
      background-size: 20px;
      padding: 12px 12px 12px 36px;
      outline: none;
      border: 0; }

.custom-table-wrapper {
  position: relative;
  font-family: 'sukh-regular'; }
  .custom-table-wrapper .search-input-filter {
    padding: 12px; }
    .custom-table-wrapper .search-input-filter input {
      font-size: 14px;
      font-family: 'sukh-light';
      color: #8c93a4;
      background: url(images/search.svg) no-repeat;
      background-position: 12px;
      background-size: 20px;
      padding: 12px 12px 12px 36px;
      border: 1px solid #dedede;
      outline: none; }
  .custom-table-wrapper .remark-text-action {
    font-size: 13px;
    color: #2caafe;
    cursor: pointer;
    min-width: 90px;
    display: inline-block; }

.custom-table {
  border-radius: 6px;
  box-shadow: 1px 4px 6px 0 #e2e1e1;
  border: 1px solid #e2e1e1;
  background-color: #ffffff;
  table-layout: auto;
  font-family: 'sukh-regular' !important; }
  .custom-table.leads.affiliate tr td:nth-child(3),
  .custom-table.leads.affiliate tr th:nth-child(3),
  .custom-table.leads.affiliate tr td:nth-child(4),
  .custom-table.leads.affiliate tr th:nth-child(4) {
    display: none; }
  .custom-table.coa.affiliate tr td:nth-child(3),
  .custom-table.coa.affiliate tr th:nth-child(3),
  .custom-table.coa.affiliate tr td:nth-child(4),
  .custom-table.coa.affiliate tr th:nth-child(4) {
    display: none; }
  .custom-table .with-padding {
    padding: 24px; }
  .custom-table .blue,
  .custom-table .pending {
    color: #1d2785;
    font-family: 'sukh-bold'; }
  .custom-table .green,
  .custom-table .approved {
    color: #589707;
    font-family: 'sukh-bold'; }
  .custom-table .red,
  .custom-table .rejected {
    color: #d70202;
    font-family: 'sukh-bold'; }
  .custom-table .yellow {
    color: #f5a623;
    font-family: 'sukh-bold'; }
  .custom-table tr th,
  .custom-table tr td {
    border: 0;
    border-right: 1px solid #d1d1d1;
    max-width: 200px; }
  .custom-table thead tr th {
    border-bottom: 1px solid #d1d1d1;
    font-size: 14px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    text-align: left !important;
    padding: 4px 15px;
    min-width: 120px; }
    .custom-table thead tr th.right {
      text-align: right !important; }
    .custom-table thead tr th label {
      margin-left: 0; }
    .custom-table thead tr th:first-child {
      min-width: 60px; }
  .custom-table.affiliate-table thead tr th:first-child {
    min-width: 90px; }
  .custom-table tbody tr .helper-text {
    font-size: 10px;
    color: #ffffff;
    background: #f58b1e;
    padding: 4px;
    border-radius: 6px;
    display: inline-block; }
  .custom-table tbody tr td,
  .custom-table tbody tr th {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    text-align: left !important;
    padding: 4px 15px;
    word-break: break-all; }
    .custom-table tbody tr td.right,
    .custom-table tbody tr th.right {
      text-align: right !important; }
    .custom-table tbody tr td .remark-text,
    .custom-table tbody tr th .remark-text {
      font-size: 14px;
      color: #fe972c;
      cursor: pointer;
      min-width: 90px;
      display: inline-block; }
    .custom-table tbody tr td .button,
    .custom-table tbody tr th .button {
      font-size: 12px;
      font-family: 'sukh-bold';
      padding: 8px 20px;
      border: 1px solid #ff901d;
      background: #fff;
      color: #ff901d;
      text-transform: none; }
    .custom-table tbody tr td.message-wrapper,
    .custom-table tbody tr th.message-wrapper {
      padding: 32px;
      text-align: center !important; }
      .custom-table tbody tr td.message-wrapper .text,
      .custom-table tbody tr th.message-wrapper .text {
        font-size: 18px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #4a4a4a;
        margin-bottom: 20px; }
      .custom-table tbody tr td.message-wrapper .refer-earn-flat button,
      .custom-table tbody tr th.message-wrapper .refer-earn-flat button {
        min-width: 230px;
        background: #ff901d;
        color: #fff; }
  .custom-table tbody tr:nth-child(odd) td,
  .custom-table tbody tr:nth-child(odd) th {
    background: #f5f5f5; }
  .custom-table tbody tr:last-child td,
  .custom-table tbody tr:last-child th {
    border-bottom: 1px solid #d1d1d1; }

.table-header {
  margin-bottom: 20px;
  position: relative; }
  .table-header .dropdown-filter {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1; }
  .table-header .title {
    font-size: 18px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    margin: 0 0 20px 0; }

.mobile-modal.custom-mobile-table {
  padding: 0 12px; }

.mobile-modal .header {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  background: #fff;
  width: 100%; }
  .mobile-modal .header .link-text {
    position: absolute;
    right: 16px;
    top: 16px; }
    .mobile-modal .header .link-text img {
      max-width: 24px;
      max-height: 24px; }
  .mobile-modal .header .img-wrap {
    height: 32px;
    object-fit: contain;
    padding: 8px;
    margin-right: 18px;
    align-items: center; }
    .mobile-modal .header .img-wrap img {
      width: 100%; }
  .mobile-modal .header .header-text {
    font-size: 12px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #192383;
    text-transform: uppercase;
    flex-grow: 1; }
  .mobile-modal .header .filter-icon {
    width: 20px;
    height: 20px; }
    .mobile-modal .header .filter-icon img {
      width: 100%;
      object-fit: contain; }

.mobile-modal .settings-wrap {
  margin-top: 12px;
  padding: 12px;
  box-shadow: 0 2px 15px 9px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  display: flex;
  align-items: center; }
  .mobile-modal .settings-wrap div:last-child {
    flex-grow: 1;
    text-align: right; }

.custom-mobile-table.affiliate-referrals {
  background: #f6f9ff;
  padding: 8px;
  min-height: calc(var(--vh) - 58px);
  padding-bottom: 160px;
  position: relative;
  max-width: 480px;
  margin: 0 auto; }

.custom-mobile-table.referrals .search-input-filter input {
  opacity: 0; }

.custom-mobile-table.with-tab {
  position: relative; }
  .custom-mobile-table.with-tab .header {
    display: none; }
  .custom-mobile-table.with-tab .content-wrapper {
    padding: 0 0 80px 0;
    height: auto; }
  .custom-mobile-table.with-tab .search-input-filter {
    position: fixed;
    left: 0;
    right: 0;
    top: 52px;
    z-index: 9;
    padding: 10px;
    background: #f0f0f5; }

.custom-mobile-table .header {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 12px 8px 12px 24px;
  display: flex;
  align-items: center;
  background: #fff;
  width: 100%;
  display: none; }
  .custom-mobile-table .header .img-wrap {
    width: auto;
    height: 32px;
    object-fit: contain;
    margin-right: 18px;
    align-items: center;
    padding: 0;
    display: flex; }
    .custom-mobile-table .header .img-wrap img {
      width: 100%; }
  .custom-mobile-table .header .link-text {
    position: absolute;
    right: 16px;
    top: 16px; }
    .custom-mobile-table .header .link-text img {
      max-width: 24px;
      max-height: 24px; }
  .custom-mobile-table .header .header-text-wrap {
    display: flex;
    flex-grow: 1; }
    .custom-mobile-table .header .header-text-wrap p {
      font-size: 10px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.2px;
      color: #192383;
      text-transform: uppercase;
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center; }
      .custom-mobile-table .header .header-text-wrap p.selected {
        color: #f5a623; }
  .custom-mobile-table .header .header-text {
    font-size: 14px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    color: #192383;
    text-transform: uppercase;
    flex-grow: 1; }
  .custom-mobile-table .header .filter-icon {
    width: 20px;
    height: 20px;
    margin-left: 18px; }
    .custom-mobile-table .header .filter-icon img {
      width: 100%;
      object-fit: contain; }

.custom-mobile-table .message-wrapper {
  padding: 32px;
  text-align: center !important; }
  .custom-mobile-table .message-wrapper img {
    width: 45px;
    margin: 40px auto 16px auto; }
  .custom-mobile-table .message-wrapper .text {
    font-size: 18px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 20px; }
  .custom-mobile-table .message-wrapper .refer-earn-flat button {
    min-width: 230px;
    background: #ff901d;
    color: #fff; }

.custom-mobile-table .title-text {
  font-size: 14px;
  font-family: 'sukh-bold';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-bottom: 8px; }

.custom-mobile-table.with-tab .content-wrapper {
  padding: 0; }

.custom-mobile-table .content-wrapper {
  padding: 0; }

.custom-mobile-table .search-input-filter {
  background-color: transparent;
  display: flex;
  justify-content: stretch; }
  .custom-mobile-table .search-input-filter > div {
    max-width: 50px;
    min-width: 50px;
    margin-left: 8px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px; }
  .custom-mobile-table .search-input-filter input {
    font-size: 14px;
    font-family: 'sukh-light';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8c93a4;
    background: #fff url(images/search.svg) no-repeat;
    background-position: 18px;
    background-size: 20px;
    padding: 12px 12px 12px 46px;
    border: 0;
    outline: none;
    flex-grow: 1; }

.custom-mobile-table .pagination-wrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px 12px;
  background: #fff;
  display: flex;
  align-items: center; }
  .custom-mobile-table .pagination-wrap span {
    flex-grow: 1; }

.referral-banner {
  border-radius: 3px;
  box-shadow: 0 5px 12px 0 #c5c5cf;
  border: solid 1px #fff9ee;
  background-color: #ffffff;
  padding: 16px 8px;
  margin: 8px 0px 16px 0;
  display: flex;
  align-items: center; }
  .referral-banner .img-wrap {
    min-width: 120px;
    max-width: 120px; }
    .referral-banner .img-wrap img {
      width: 100%; }
  .referral-banner .content-wrap {
    flex-grow: 1;
    text-align: right; }
    .referral-banner .content-wrap .text-one {
      font-size: 20px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ff8f00;
      font-family: 'sukh-bold';
      line-height: 1.2; }
    .referral-banner .content-wrap .text-two {
      font-size: 28px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffa500;
      line-height: 1.2; }

.referral-card .card-content {
  padding: 12px; }
  .referral-card .card-content .flex-wrap {
    display: flex;
    align-items: center; }
    .referral-card .card-content .flex-wrap p {
      padding: 0 15px; }
      .referral-card .card-content .flex-wrap p:first-child {
        padding-left: 0;
        border-right: 1px solid #e4e4e4; }
  .referral-card .card-content .text-one {
    font-size: 14px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 8px; }
  .referral-card .card-content .text-two {
    font-size: 14px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a; }

.policy-card,
.lead-card,
.referral-card,
.coa-card {
  background: #fff;
  margin-top: 8px; }
  .policy-card .card-header,
  .lead-card .card-header,
  .referral-card .card-header,
  .coa-card .card-header {
    background-color: #e8f8fe;
    padding: 9px 8px;
    position: relative; }
    .policy-card .card-header .status-label,
    .lead-card .card-header .status-label,
    .referral-card .card-header .status-label,
    .coa-card .card-header .status-label {
      position: absolute;
      background-color: #1d2785;
      font-size: 10px;
      font-family: 'sukh-medium';
      color: #ffffff;
      padding: 4px 10px 4px 24px;
      right: 0;
      bottom: 8px;
      border-radius: 12px 2px 2px 12px; }
      .policy-card .card-header .status-label.red,
      .lead-card .card-header .status-label.red,
      .referral-card .card-header .status-label.red,
      .coa-card .card-header .status-label.red {
        background-color: #d70202; }
      .policy-card .card-header .status-label.green,
      .lead-card .card-header .status-label.green,
      .referral-card .card-header .status-label.green,
      .coa-card .card-header .status-label.green {
        background-color: #589707; }
      .policy-card .card-header .status-label.blue,
      .lead-card .card-header .status-label.blue,
      .referral-card .card-header .status-label.blue,
      .coa-card .card-header .status-label.blue {
        background-color: #1d2785; }
      .policy-card .card-header .status-label.yellow,
      .lead-card .card-header .status-label.yellow,
      .referral-card .card-header .status-label.yellow,
      .coa-card .card-header .status-label.yellow {
        background-color: #f5a623; }
    .policy-card .card-header .row,
    .lead-card .card-header .row,
    .referral-card .card-header .row,
    .coa-card .card-header .row {
      display: flex;
      align-items: center; }
      .policy-card .card-header .row:not(:last-child),
      .lead-card .card-header .row:not(:last-child),
      .referral-card .card-header .row:not(:last-child),
      .coa-card .card-header .row:not(:last-child) {
        margin-bottom: 8px; }
      .policy-card .card-header .row .text-one,
      .lead-card .card-header .row .text-one,
      .referral-card .card-header .row .text-one,
      .coa-card .card-header .row .text-one {
        font-size: 16px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a; }
      .policy-card .card-header .row .text,
      .lead-card .card-header .row .text,
      .referral-card .card-header .row .text,
      .coa-card .card-header .row .text {
        font-size: 14px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #4a4a4a; }
        .policy-card .card-header .row .text:not(:first-child),
        .lead-card .card-header .row .text:not(:first-child),
        .referral-card .card-header .row .text:not(:first-child),
        .coa-card .card-header .row .text:not(:first-child) {
          flex-grow: 1;
          text-align: right; }
        .policy-card .card-header .row .text.light,
        .lead-card .card-header .row .text.light,
        .referral-card .card-header .row .text.light,
        .coa-card .card-header .row .text.light {
          font-size: 12px;
          font-family: 'sukh-regular';
          color: #414141; }
  .policy-card .card-content,
  .lead-card .card-content,
  .referral-card .card-content,
  .coa-card .card-content {
    padding: 8px;
    position: relative; }
    .policy-card .card-content .link-wrap a,
    .lead-card .card-content .link-wrap a,
    .referral-card .card-content .link-wrap a,
    .coa-card .card-content .link-wrap a {
      color: #1d2785; }
    .policy-card .card-content .mini.label,
    .lead-card .card-content .mini.label,
    .referral-card .card-content .mini.label,
    .coa-card .card-content .mini.label {
      margin-left: 8px; }
    .policy-card .card-content .text,
    .lead-card .card-content .text,
    .referral-card .card-content .text,
    .coa-card .card-content .text {
      font-size: 12px;
      font-family: 'sukh-medium';
      color: #414141;
      margin-bottom: 4px; }
    .policy-card .card-content .link-text,
    .lead-card .card-content .link-text,
    .referral-card .card-content .link-text,
    .coa-card .card-content .link-text {
      position: absolute;
      font-size: 12px;
      font-family: 'sukh-bold';
      color: #f58b1e;
      right: 8px;
      bottom: 8px; }
    .policy-card .card-content .inline-key-value,
    .lead-card .card-content .inline-key-value,
    .referral-card .card-content .inline-key-value,
    .coa-card .card-content .inline-key-value {
      display: flex;
      align-items: center;
      margin: 10px 0 6px 0; }
      .policy-card .card-content .inline-key-value .key,
      .lead-card .card-content .inline-key-value .key,
      .referral-card .card-content .inline-key-value .key,
      .coa-card .card-content .inline-key-value .key {
        font-size: 10px;
        color: #9e9d9d;
        position: relative;
        padding-right: 10px; }
        .policy-card .card-content .inline-key-value .key:not(:first-child),
        .lead-card .card-content .inline-key-value .key:not(:first-child),
        .referral-card .card-content .inline-key-value .key:not(:first-child),
        .coa-card .card-content .inline-key-value .key:not(:first-child) {
          padding-left: 10px; }
        .policy-card .card-content .inline-key-value .key:not(:last-child):after,
        .lead-card .card-content .inline-key-value .key:not(:last-child):after,
        .referral-card .card-content .inline-key-value .key:not(:last-child):after,
        .coa-card .card-content .inline-key-value .key:not(:last-child):after {
          content: '';
          position: absolute;
          width: 1px;
          height: 10px;
          background: #f0f0f5;
          right: 0;
          top: 0; }
      .policy-card .card-content .inline-key-value .value,
      .lead-card .card-content .inline-key-value .value,
      .referral-card .card-content .inline-key-value .value,
      .coa-card .card-content .inline-key-value .value {
        font-size: 12px;
        font-family: 'sukh-bold';
        color: #4a4a4a;
        display: block;
        margin-top: 2px; }
  .policy-card .card-footer,
  .lead-card .card-footer,
  .referral-card .card-footer,
  .coa-card .card-footer {
    border-top: 1px solid #f0f0f5;
    border-bottom: 1px solid #f0f0f5;
    display: flex;
    align-items: center; }
    .policy-card .card-footer div,
    .lead-card .card-footer div,
    .referral-card .card-footer div,
    .coa-card .card-footer div {
      padding: 8px;
      text-align: center;
      cursor: pointer;
      font-size: 12px;
      font-family: 'sukh-medium';
      color: #f58b1e;
      flex-grow: 1;
      flex-basis: 0; }
      .policy-card .card-footer div a,
      .lead-card .card-footer div a,
      .referral-card .card-footer div a,
      .coa-card .card-footer div a {
        font-size: 12px;
        font-family: 'sukh-medium';
        color: #f58b1e; }
      .policy-card .card-footer div:first-child,
      .lead-card .card-footer div:first-child,
      .referral-card .card-footer div:first-child,
      .coa-card .card-footer div:first-child {
        border-right: 1px solid #f0f0f5;
        color: #4a4a4a; }

.coa-card .banner {
  background-color: #57079f;
  padding: 6px 8px;
  font-size: 12px;
  font-family: 'sukh-bold';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff; }

.coa-card .card-header {
  padding: 8px 8px 5px 8px;
  background: #fff; }
  .coa-card .card-header .status-label {
    position: relative;
    width: auto;
    display: inline-block;
    margin-right: -8px;
    font-size: 10px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 2px 8px;
    bottom: 0; }
    .coa-card .card-header .status-label.pending {
      background-color: rgba(245, 166, 35, 0.2);
      color: #f5a623; }
    .coa-card .card-header .status-label.approved {
      background-color: rgba(65, 117, 5, 0.2);
      color: #417505; }
    .coa-card .card-header .status-label.rejected {
      background: #d70202; }
  .coa-card .card-header .row {
    display: flex;
    align-items: center; }
    .coa-card .card-header .row .col {
      display: flex;
      align-items: center; }
      .coa-card .card-header .row .col:last-child {
        flex-grow: 1;
        text-align: right;
        position: relative;
        display: block; }
        .coa-card .card-header .row .col:last-child .text {
          width: 100%;
          text-align: right;
          margin-bottom: 6px; }
          .coa-card .card-header .row .col:last-child .text.blue {
            color: #1d2785; }
      .coa-card .card-header .row .col img {
        width: 42px;
        height: 42px;
        object-fit: contain;
        margin-right: 6px; }
      .coa-card .card-header .row .col .text {
        font-family: 'sukh-bold';
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        text-align: left; }
        .coa-card .card-header .row .col .text:not(:last-child) {
          margin-bottom: 4px; }
      .coa-card .card-header .row .col .text-one {
        font-size: 18px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000; }

.coa-card .card-content {
  padding-top: 0; }
  .coa-card .card-content .line {
    border-top: solid 1px #efefef;
    margin-bottom: 6px; }
  .coa-card .card-content .inline-key-value {
    align-items: flex-start; }
    .coa-card .card-content .inline-key-value .key {
      flex-grow: 1;
      flex-basis: 100%; }
      .coa-card .card-content .inline-key-value .key:after {
        display: none; }
  .coa-card .card-content .row {
    display: flex;
    align-items: center; }
    .coa-card .card-content .row .col {
      display: flex;
      align-items: center; }
      .coa-card .card-content .row .col:last-child {
        flex-grow: 1;
        text-align: right;
        position: relative;
        display: block; }
        .coa-card .card-content .row .col:last-child .text {
          width: 100%;
          text-align: right;
          margin-bottom: 6px; }
          .coa-card .card-content .row .col:last-child .text.blue {
            color: #1d2785; }
  .coa-card .card-content .text {
    font-size: 12px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #414141; }

.coa-card .card-footer .btn {
  border: solid 1px #f58b1e;
  background-color: #f58b1e;
  font-size: 10px;
  font-family: 'sukh-bold';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding: 6px;
  line-height: 1.6; }

.coa-card .card-footer .link {
  font-size: 12px;
  font-family: 'sukh-bold';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f58b1e; }

.affiliate-profile {
  font-family: 'sukh-regular';
  position: relative;
  padding: 20px; }
  .affiliate-profile .policies-in-process .custom-table tr td:nth-child(3),
  .affiliate-profile .policies-in-process .custom-table tr th:nth-child(3) {
    display: none; }
  .affiliate-profile .cmi-table,
  .affiliate-profile .invoice-table {
    border-radius: 4px;
    box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.03);
    background-color: #fff; }
    .affiliate-profile .cmi-table:before,
    .affiliate-profile .invoice-table:before {
      display: none; }
  .affiliate-profile .affiliate-details-banner {
    padding: 8px 12px;
    background: url(images/onboarding/affiliate-banner.svg) no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 12px; }
    .affiliate-profile .affiliate-details-banner .text-one {
      font-size: 12px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #1b1b1b;
      margin-bottom: 12px;
      text-transform: uppercase; }
    .affiliate-profile .affiliate-details-banner .text-two {
      font-size: 9px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #1b1b1b;
      margin-bottom: 5px;
      text-transform: uppercase; }
      .affiliate-profile .affiliate-details-banner .text-two span {
        font-size: 24px;
        font-weight: normal;
        letter-spacing: 2px;
        color: #406b09;
        font-family: 'brusher'; }
    .affiliate-profile .affiliate-details-banner .progress {
      max-width: 312px;
      background-color: rgba(64, 107, 9, 0.3);
      border-radius: 1px;
      position: relative;
      margin-bottom: 8px;
      height: 4px; }
      .affiliate-profile .affiliate-details-banner .progress > div {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 25%;
        background-color: #406b09;
        z-index: 1; }
    .affiliate-profile .affiliate-details-banner .text-three {
      font-size: 14px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #1b1b1b;
      margin-bottom: 16px; }
  .affiliate-profile .buttons-wrapper {
    border-radius: 6px;
    box-shadow: 1px 4px 6px 0 #e2e1e1;
    background-color: #ffffff;
    padding: 16px; }
    .affiliate-profile .buttons-wrapper .title {
      font-size: 18px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      margin-bottom: 8px; }
    .affiliate-profile .buttons-wrapper button {
      padding: 12px 24px;
      font-size: 14px;
      font-family: 'sukh-bold';
      color: #fff;
      text-transform: none;
      margin-bottom: 12px; }
      .affiliate-profile .buttons-wrapper button:not(:last-child) {
        margin-right: 24px; }
  .affiliate-profile .full-width {
    width: 100%; }
  .affiliate-profile .title-text {
    font-size: 18px;
    font-family: 'sukh-medium';
    color: #4a4a4a;
    margin-bottom: 16px; }
  .affiliate-profile .affiliate-details {
    margin-bottom: 20px; }
    .affiliate-profile .affiliate-details .commission-rates {
      margin-top: 24px; }
    .affiliate-profile .affiliate-details .affiliate-credit {
      margin-top: 24px; }
      .affiliate-profile .affiliate-details .affiliate-credit .content-wrap {
        margin-top: 12px; }
      .affiliate-profile .affiliate-details .affiliate-credit .flex-wrap {
        align-items: center; }
      .affiliate-profile .affiliate-details .affiliate-credit .button-wrap {
        display: flex;
        justify-content: center;
        padding: 5px; }
    .affiliate-profile .affiliate-details .paper {
      border-radius: 4px;
      box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.03);
      background-color: #fff; }
      .affiliate-profile .affiliate-details .paper.with-padding {
        padding: 16px 24px; }
    .affiliate-profile .affiliate-details .flex-wrap {
      display: flex;
      margin-bottom: 20px; }
      .affiliate-profile .affiliate-details .flex-wrap .card-content {
        border-radius: 4px;
        box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.03);
        background-color: #fff;
        padding: 16px 24px !important;
        flex-grow: 1;
        flex-basis: 100%; }
        .affiliate-profile .affiliate-details .flex-wrap .card-content .uploaded-file {
          line-height: 1;
          font-size: 11px;
          padding: 4px 8px;
          border: 1px solid #f5a623;
          margin: 0 12px 12px 0;
          display: inline-block;
          cursor: pointer;
          position: relative; }
        .affiliate-profile .affiliate-details .flex-wrap .card-content:not(:last-child) {
          margin-right: 40px; }
    .affiliate-profile .affiliate-details .card {
      padding: 24px;
      position: relative;
      box-shadow: none;
      background: transparent; }
      .affiliate-profile .affiliate-details .card.basic-details {
        padding: 0; }
      .affiliate-profile .affiliate-details .card.editable {
        padding-bottom: 160px; }
        .affiliate-profile .affiliate-details .card.editable .buttons-wrap button {
          margin-right: 12px; }
      .affiliate-profile .affiliate-details .card .card-content {
        padding: 0; }
        .affiliate-profile .affiliate-details .card .card-content .flex-wrap {
          display: flex; }
          .affiliate-profile .affiliate-details .card .card-content .flex-wrap button {
            margin-right: 12px; }
        .affiliate-profile .affiliate-details .card .card-content .inline-wrapper {
          display: flex; }
          .affiliate-profile .affiliate-details .card .card-content .inline-wrapper > div {
            flex-grow: 1;
            flex-basis: 100%;
            max-width: 50%; }
      .affiliate-profile .affiliate-details .card .card-title {
        font-size: 14px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        margin-bottom: 16px;
        text-align: left !important; }
      .affiliate-profile .affiliate-details .card .action-text-wrap {
        position: absolute;
        right: 0;
        top: 0;
        display: flex; }
        .affiliate-profile .affiliate-details .card .action-text-wrap .edit-text {
          cursor: pointer;
          font-size: 14px;
          font-family: 'sukh-medium';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #f58b1e;
          font-family: 'sukh-medium'; }
          .affiliate-profile .affiliate-details .card .action-text-wrap .edit-text:not(:last-child) {
            margin-right: 32px; }
      .affiliate-profile .affiliate-details .card .key-value {
        font-size: 14px;
        color: #4a4a4a;
        margin-bottom: 24px;
        display: table;
        width: 100%; }
        .affiliate-profile .affiliate-details .card .key-value span {
          display: table-cell;
          text-align: left;
          vertical-align: top; }
          .affiliate-profile .affiliate-details .card .key-value span.key {
            width: 180px; }
          .affiliate-profile .affiliate-details .card .key-value span.value {
            position: relative;
            padding-left: 32px; }
            .affiliate-profile .affiliate-details .card .key-value span.value.label {
              border-radius: 11px;
              font-size: 10px;
              font-family: 'sukh-bold';
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              line-height: normal;
              padding: 1px 4px;
              display: inline-block;
              margin-left: 32px;
              border: 1px solid #ccc;
              min-width: 40px;
              text-align: center;
              text-transform: capitalize; }
              .affiliate-profile .affiliate-details .card .key-value span.value.label.red {
                border: 1px solid red; }
              .affiliate-profile .affiliate-details .card .key-value span.value.label.green {
                border: 1px solid green; }
              .affiliate-profile .affiliate-details .card .key-value span.value.label.orange {
                border: 1px solid orange;
                padding: 3px 6px; }
              .affiliate-profile .affiliate-details .card .key-value span.value.label:before {
                content: ':';
                position: absolute;
                left: -32px; }
            .affiliate-profile .affiliate-details .card .key-value span.value .uploaded-file {
              word-break: break-all; }
            .affiliate-profile .affiliate-details .card .key-value span.value:before {
              content: ':';
              position: absolute;
              left: 0; }
      .affiliate-profile .affiliate-details .card .title {
        font-size: 18px;
        color: #555;
        margin-bottom: 12px;
        text-align: center; }
      .affiliate-profile .affiliate-details .card .card-actions {
        justify-content: flex-end; }
    .affiliate-profile .affiliate-details .form-field {
      margin-bottom: 8px; }
      .affiliate-profile .affiliate-details .form-field .form-label {
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 1rem;
        line-height: 1; }
      .affiliate-profile .affiliate-details .form-field .ui.selection.dropdown {
        border: 1px solid #cbcbcb !important;
        border-radius: 2px !important;
        padding: 6px 10px;
        min-height: auto;
        min-width: auto;
        width: 100%; }
        .affiliate-profile .affiliate-details .form-field .ui.selection.dropdown .menu > .item {
          padding: 8px 12px !important; }
        .affiliate-profile .affiliate-details .form-field .ui.selection.dropdown .text {
          margin: 0 !important;
          font-size: 12px;
          color: #5f5f5f; }
        .affiliate-profile .affiliate-details .form-field .ui.selection.dropdown .icon {
          top: 4px !important; }
      .affiliate-profile .affiliate-details .form-field.with-width {
        width: 150px; }
      .affiliate-profile .affiliate-details .form-field .form-label {
        display: none; }
      .affiliate-profile .affiliate-details .form-field .label-input {
        border: 1px solid #cbcbcb;
        border-radius: 2px;
        display: flex; }
        .affiliate-profile .affiliate-details .form-field .label-input input[type="text"],
        .affiliate-profile .affiliate-details .form-field .label-input input[type="number"] {
          border: 0; }
        .affiliate-profile .affiliate-details .form-field .label-input label {
          padding: 5px;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #5f5f5f;
          border-right: 1px solid #cbcbcb; }
      .affiliate-profile .affiliate-details .form-field > label.form-label {
        display: none; }
      .affiliate-profile .affiliate-details .form-field > p {
        display: inline-block;
        vertical-align: middle;
        margin: 0 8px 0 0;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #5f5f5f;
        padding-left: 28px; }
      .affiliate-profile .affiliate-details .form-field input[type="text"],
      .affiliate-profile .affiliate-details .form-field input[type="number"] {
        border-radius: 2px;
        background-color: #ffffff;
        border: 1px solid #cbcbcb;
        padding: 5px 10px;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #5f5f5f;
        width: 100%;
        outline: none; }
    .affiliate-profile .affiliate-details .buttons-wrap {
      position: absolute;
      bottom: 24px; }
  .affiliate-profile .settings-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px; }
    .affiliate-profile .settings-wrap > div:last-child {
      flex-grow: 1;
      text-align: right; }

.mobile-commission-details {
  border-top: 1px solid #d1d1d1; }
  .mobile-commission-details .row {
    display: flex;
    align-items: inherit; }
    .mobile-commission-details .row:first-child {
      border-bottom: 1px solid #d1d1d1; }
    .mobile-commission-details .row.striped:nth-child(odd) {
      background-color: #f5f5f5; }
    .mobile-commission-details .row .col {
      flex-basis: 0;
      flex-grow: 1; }
      .mobile-commission-details .row .col p {
        font-size: 11px;
        color: #414141;
        padding: 12px 8px; }
      .mobile-commission-details .row .col:last-child {
        border-left: 1px solid #d1d1d1; }

.credit-details {
  border-top: 1px solid #d1d1d1; }
  .credit-details .row {
    display: flex;
    align-items: center; }
    .credit-details .row:first-child {
      border-bottom: 1px solid #d1d1d1; }
    .credit-details .row.striped {
      background-color: #f5f5f5; }
    .credit-details .row .col {
      flex-basis: 0;
      flex-grow: 1; }
      .credit-details .row .col p {
        font-size: 14px;
        color: #414141;
        padding: 12px 8px; }
        .credit-details .row .col p span {
          cursor: pointer;
          color: #192383;
          margin-right: 12px;
          display: inline-block; }
      .credit-details .row .col:not(:first-child) {
        border-left: 1px solid #d1d1d1; }

@media screen and (max-width: 1024px) {
  .credit-details .row .col p {
    font-size: 11px; }
  .affiliate-profile {
    margin-top: 0;
    padding: 12px; }
    .affiliate-profile > .title {
      margin: 16px; }
    .affiliate-profile .invoice-table {
      margin-top: 12px !important;
      border-radius: 4px;
      box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.03);
      background-color: #fff; }
      .affiliate-profile .invoice-table:before {
        display: none; }
      .affiliate-profile .invoice-table > div {
        padding: 0 16px;
        min-height: auto; }
        .affiliate-profile .invoice-table > div p {
          font-size: 14px;
          font-family: 'sukh-medium';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000; }
    .affiliate-profile .affiliate-details {
      margin-bottom: 0; }
      .affiliate-profile .affiliate-details .flex-wrap {
        flex-direction: column;
        margin-bottom: 0; }
        .affiliate-profile .affiliate-details .flex-wrap .card-content {
          padding: 8px 12px !important; }
          .affiliate-profile .affiliate-details .flex-wrap .card-content:not(:last-child) {
            margin: 0 0 12px 0; }
      .affiliate-profile .affiliate-details .card {
        padding: 8px 16px; }
        .affiliate-profile .affiliate-details .card.basic-details {
          overflow: initial; }
        .affiliate-profile .affiliate-details .card .card-title {
          font-size: 16px; }
        .affiliate-profile .affiliate-details .card .action-text-wrap {
          padding: 6px 12px;
          background: #dedede;
          top: -5px; }
          .affiliate-profile .affiliate-details .card .action-text-wrap .edit-text {
            font-size: 12px; }
        .affiliate-profile .affiliate-details .card .card-content {
          padding: 0; }
          .affiliate-profile .affiliate-details .card .card-content .key-value {
            display: flex;
            align-items: flex-start;
            padding: 12px 0;
            margin-bottom: 0; }
            .affiliate-profile .affiliate-details .card .card-content .key-value:not(:last-child) {
              border-bottom: 1px solid #efefef; }
            .affiliate-profile .affiliate-details .card .card-content .key-value span {
              font-size: 14px;
              line-height: 1.4; }
            .affiliate-profile .affiliate-details .card .card-content .key-value span.key {
              min-width: 120px;
              max-width: 120px; }
            .affiliate-profile .affiliate-details .card .card-content .key-value span.value {
              flex-grow: 1;
              padding-left: 10px; }
            .affiliate-profile .affiliate-details .card .card-content .key-value span.label {
              padding-left: 0;
              flex-grow: initial;
              margin-left: 10px; }
        .affiliate-profile .affiliate-details .card.editable {
          background: #fff; }
      .affiliate-profile .affiliate-details .commission-rates {
        margin-top: 8px; }
        .affiliate-profile .affiliate-details .commission-rates .header-wrap {
          padding: 8px 16px; }
        .affiliate-profile .affiliate-details .commission-rates .card {
          padding: 0px; }
    .affiliate-profile .buttons-wrapper {
      margin: 12px 0;
      border-radius: 0; }
      .affiliate-profile .buttons-wrapper .title {
        margin: 0 0 12px 0;
        font-size: 14px;
        font-family: 'sukh-medium';
        color: #000; } }

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;
  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;
  /* clicking anywhere will focus the input */
  cursor: text; }

.react-tags.is-focused {
  border-color: #B1B1B1; }

.react-tags__selected {
  display: inline; }

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  background: #F1F1F1;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit; }

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px; }

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1; }

.react-tags__search {
  display: inline-block;
  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;
  /* prevent autoresize overflowing the container */
  max-width: 100%; }

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative; } }

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit; }

.react-tags__search input::-ms-clear {
  display: none; }

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; }

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px; } }

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); }

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px; }

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-family: 'sukh-medium'; }

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee; }

.react-tags__suggestions li.is-active {
  background: #b7cfe0; }

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto; }

.crm-detail-layout {
  padding: 12px;
  display: flex;
  max-width: 100%; }
  .crm-detail-layout .affiliate-credit {
    margin-bottom: 20px;
    padding: 20px; }
    .crm-detail-layout .affiliate-credit .label {
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424;
      display: block;
      margin-bottom: 12px; }
    .crm-detail-layout .affiliate-credit .flex-wrap {
      display: flex;
      margin: 12px 0 20px 0;
      align-items: center; }
      .crm-detail-layout .affiliate-credit .flex-wrap > div {
        display: flex;
        align-items: center; }
      .crm-detail-layout .affiliate-credit .flex-wrap .label {
        margin-bottom: 0; }
    .crm-detail-layout .affiliate-credit .button {
      padding: 6px;
      font-size: 10px;
      margin-left: 10px;
      line-height: 1; }
  .crm-detail-layout .action-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 12px; }
    .crm-detail-layout .action-wrapper .button {
      margin-left: 12px;
      display: flex;
      align-items: center; }
  .crm-detail-layout .content-wrapper {
    margin-left: 24px;
    display: flex;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%; }
    .crm-detail-layout .content-wrapper .row {
      display: flex;
      width: 100%; }
      .crm-detail-layout .content-wrapper .row .col {
        flex-basis: 100%;
        flex-grow: 1; }
        .crm-detail-layout .content-wrapper .row .col .card {
          height: 100%; }
        .crm-detail-layout .content-wrapper .row .col:first-child {
          margin-right: 24px; }
    .crm-detail-layout .content-wrapper .referral-dashboard {
      max-width: 100%;
      width: 100%; }
      .crm-detail-layout .content-wrapper .referral-dashboard .tabular-content.referrals {
        padding: 0; }
  .crm-detail-layout .left-wrapper {
    min-width: 366px;
    max-width: 366px;
    flex: 0 0 auto; }
  .crm-detail-layout .card {
    border-radius: 4px;
    box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    padding: 16px 24px; }
    .crm-detail-layout .card.key-value-wrapper .title-text {
      font-size: 10px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #242424;
      margin-bottom: 4px;
      text-transform: uppercase; }
    .crm-detail-layout .card.key-value-wrapper.col > div:first-child {
      margin-bottom: 12px; }
    .crm-detail-layout .card .top-border {
      border-top: solid 0.5px #e3e3e3; }
    .crm-detail-layout .card .key-value {
      display: flex;
      align-items: center;
      padding: 6px 0; }
      .crm-detail-layout .card .key-value input[type='text'] {
        font-size: 12px;
        margin: 0;
        padding: 0; }
      .crm-detail-layout .card .key-value a {
        font-size: 12px; }
      .crm-detail-layout .card .key-value .padded-datepicker .mui-field > div::before {
        display: none; }
      .crm-detail-layout .card .key-value button svg {
        font-size: 18px;
        color: #192383; }
      .crm-detail-layout .card .key-value:not(:last-child) {
        border-bottom: solid 0.5px #e3e3e3; }
      .crm-detail-layout .card .key-value p:first-child {
        min-width: 140px;
        max-width: 140px;
        margin-right: 8px; }
      .crm-detail-layout .card .key-value p:last-child {
        flex-grow: 1; }
      .crm-detail-layout .card .key-value p input {
        border: 0;
        padding: 0;
        margin: 0;
        padding: 0;
        font-size: 12px;
        font-family: 'sukh-regular';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #242424;
        outline: none; }
      .crm-detail-layout .card .key-value p .select-text {
        display: inline-block;
        min-width: 100%;
        cursor: pointer; }
    .crm-detail-layout .card .key {
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8a95aa; }
    .crm-detail-layout .card .value {
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424;
      word-break: break-word;
      flex-grow: 1; }
      .crm-detail-layout .card .value.red {
        border: 0.5px solid red;
        border-radius: 10px;
        padding: 0 10px;
        flex-grow: initial; }
      .crm-detail-layout .card .value.green {
        border: 0.5px solid green;
        border-radius: 10px;
        padding: 0 10px;
        flex-grow: initial; }
      .crm-detail-layout .card .value .file-upload .button {
        padding: 4px 12px !important;
        font-size: 10px;
        margin: 0; }
    .crm-detail-layout .card .link-text {
      font-size: 10px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383;
      text-decoration: none;
      cursor: pointer; }
  .crm-detail-layout .details-wrap .uploaded-file {
    padding: 4px 8px;
    font-size: 10px;
    line-height: 1;
    margin: 4px 4px 0 0; }
  .crm-detail-layout .details-wrap .initials-wrap {
    text-align: center;
    margin-bottom: 20px; }
    .crm-detail-layout .details-wrap .initials-wrap div {
      max-width: 48px;
      min-width: 48px;
      height: 48px;
      background-blend-mode: source-in;
      background-image: linear-gradient(to bottom, #f5a623, #f5a623);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.17px;
      color: #fffbef;
      padding: 12px;
      border-radius: 100%;
      margin: 0 auto;
      text-transform: uppercase; }
  .crm-detail-layout .details-wrap .tag {
    border-radius: 1px;
    padding: 2px 4px;
    font-size: 10px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #033134;
    display: flex;
    align-items: center;
    text-transform: uppercase; }
    .crm-detail-layout .details-wrap .tag.one {
      background: #0bc3ca; }
    .crm-detail-layout .details-wrap .tag.two {
      background: #ffcdcd; }
    .crm-detail-layout .details-wrap .tag img {
      width: 8px;
      height: 8px;
      cursor: pointer;
      margin-left: 4px;
      object-fit: cover; }
  .crm-detail-layout .details-wrap .tags-wrap {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 20px; }
    .crm-detail-layout .details-wrap .tags-wrap .link-text {
      position: absolute;
      right: 0; }
    .crm-detail-layout .details-wrap .tags-wrap .text {
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424;
      margin-right: 16px; }
    .crm-detail-layout .details-wrap .tags-wrap > div {
      flex-grow: 1; }
      .crm-detail-layout .details-wrap .tags-wrap > div .tag {
        margin: 0 6px 6px 0;
        display: inline-block;
        word-break: break-word; }
  .crm-detail-layout .details-wrap .duplicate-status-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    font-size: 12px; }
    .crm-detail-layout .details-wrap .duplicate-status-wrap .status-text {
      margin-left: 16px;
      font-size: 10px;
      font-family: 'sukh-medium';
      padding: 2px 4px;
      line-height: normal;
      border-radius: 1px;
      color: #fff; }
    .crm-detail-layout .details-wrap .duplicate-status-wrap .duplicate-yes {
      background-color: #d0021b; }
    .crm-detail-layout .details-wrap .duplicate-status-wrap .duplicate-no {
      background-color: #289a13; }
  .crm-detail-layout .details-wrap .padded-datepicker {
    width: 100%; }
    .crm-detail-layout .details-wrap .padded-datepicker > div {
      display: block; }
    .crm-detail-layout .details-wrap .padded-datepicker .mui-field > div {
      padding-bottom: 0;
      width: 100%; }
  .crm-detail-layout .details-wrap .file-upload {
    padding: 0; }
    .crm-detail-layout .details-wrap .file-upload .uploaded-file {
      margin: 7px 4px 0 0;
      padding: 4px 22px 4px 8px; }
      .crm-detail-layout .details-wrap .file-upload .uploaded-file + .button {
        margin: 3px 0 0;
        padding: 4px !important; }
  .crm-detail-layout .details-wrap .btn {
    border-radius: 2px;
    border: solid 1px #f58b1e;
    font-size: 11px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #242424;
    padding: 4px 12px 4px 8px;
    margin: 16px 0 6px 0;
    display: flex;
    align-items: center; }
    .crm-detail-layout .details-wrap .btn img {
      width: 26px;
      height: 26px;
      margin-right: 10px; }
  .crm-detail-layout .tabs-wrapper {
    width: 100%;
    margin-top: 0;
    min-height: auto; }
    .crm-detail-layout .tabs-wrapper > div > div > span {
      background: #192383; }
    .crm-detail-layout .tabs-wrapper > .tab {
      font-size: 14px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8a95aa;
      text-transform: capitalize;
      min-width: auto;
      min-height: auto; }
      .crm-detail-layout .tabs-wrapper > .tab[aria-selected='true'] {
        color: #192383;
        font-family: 'sukh-bold'; }
  .crm-detail-layout .tabular-content {
    display: block;
    width: 100%; }
  .crm-detail-layout .btn {
    font-size: 10px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f2fbff;
    border-radius: 2px;
    border: 0;
    outline: none;
    cursor: pointer;
    padding: 3px 8px; }
    .crm-detail-layout .btn.blue {
      border: solid 1px #192383;
      background-color: #192383;
      color: #f2fbff; }
  .crm-detail-layout .editable-field {
    background-image: url("./images/pencil.svg");
    background-repeat: no-repeat;
    background-position: right;
    position: relative;
    cursor: pointer; }
    .crm-detail-layout .editable-field .redirect-link {
      position: absolute;
      right: 30px; }
    .crm-detail-layout .editable-field.center-icon {
      background-position: center; }
  .crm-detail-layout .ui.styled.accordion {
    box-shadow: none;
    border-radius: 0; }
    .crm-detail-layout .ui.styled.accordion .affiliate-dashboard-tabs .fixed-filters {
      top: 60px; }
    .crm-detail-layout .ui.styled.accordion .affiliate-dashboard-tabs .agent-transfer-tab.filter-wrap {
      height: 80px; }
    .crm-detail-layout .ui.styled.accordion > .title {
      background-color: #dee5f0;
      color: #3f3f3f;
      font-size: 14px;
      padding: 15px;
      position: relative;
      margin-top: 20px;
      border: 0; }
      .crm-detail-layout .ui.styled.accordion > .title .icon {
        position: absolute;
        right: 0; }
    .crm-detail-layout .ui.styled.accordion > .content {
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
      background-color: #ffffff;
      padding: 0; }

.crm {
  padding: 20px; }

.crm-listing-layout .title-block {
  display: flex;
  align-items: end;
  margin-bottom: 32px;
  justify-content: space-between; }
  .crm-listing-layout .title-block .title-text {
    font-size: 24px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000; }
    .crm-listing-layout .title-block .title-text button {
      margin-left: 32px;
      font-size: 14px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      padding: 8px 14px;
      text-transform: none; }
  .crm-listing-layout .title-block .task-status {
    display: flex; }
    .crm-listing-layout .title-block .task-status .col {
      margin-left: 32px; }
    .crm-listing-layout .title-block .task-status .text-one {
      font-size: 14px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      margin-bottom: 4px; }
    .crm-listing-layout .title-block .task-status .text-two {
      font-size: 16px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #192383; }
      .crm-listing-layout .title-block .task-status .text-two.color-one {
        color: #192383; }
      .crm-listing-layout .title-block .task-status .text-two.color-two {
        color: #d0021b; }
      .crm-listing-layout .title-block .task-status .text-two.color-three {
        color: #69aa15; }

.crm-listing-layout .agent-transfer-summary {
  position: absolute;
  left: 0;
  top: 0; }

.crm-listing-layout .footer-wrapper {
  box-shadow: 0 -2px 18px 4px rgba(150, 160, 190, 0.39);
  background-color: #ffffff;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 24px;
  display: flex;
  align-items: center;
  width: 100%; }
  .crm-listing-layout .footer-wrapper .col {
    flex-grow: 1;
    flex-basis: 100%; }
    .crm-listing-layout .footer-wrapper .col:not(:last-child) {
      border-right: solid 1px #c2c4cc; }
    .crm-listing-layout .footer-wrapper .col .text-one {
      font-size: 18px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      margin-bottom: 4px; }
    .crm-listing-layout .footer-wrapper .col .text-two {
      font-size: 40px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #192383; }
      .crm-listing-layout .footer-wrapper .col .text-two.color-one {
        color: #192383; }
      .crm-listing-layout .footer-wrapper .col .text-two.color-two {
        color: #d0021b; }
      .crm-listing-layout .footer-wrapper .col .text-two.color-three {
        color: #69aa15; }
  .crm-listing-layout .footer-wrapper .close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer; }

.crm-filter-wrapper {
  display: block; }
  .crm-filter-wrapper .react-select-custom > div {
    border-radius: 2px;
    border: solid 1px #c4cfe4;
    background-color: #ffffff;
    min-width: 140px;
    margin-right: 0; }
    .crm-filter-wrapper .react-select-custom > div div:first-child div {
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424; }
    .crm-filter-wrapper .react-select-custom > div div:last-child > span {
      display: none; }
  .crm-filter-wrapper .tm-range {
    border-radius: 2px;
    border: solid 1px #c4cfe4;
    background-color: #ffffff;
    min-width: 120px;
    margin-right: 10px;
    min-height: 37px;
    position: relative; }
    .crm-filter-wrapper .tm-range.form-element {
      margin: 0; }
    .crm-filter-wrapper .tm-range > div:last-child > div:last-child {
      left: auto;
      left: 0; }
    .crm-filter-wrapper .tm-range.align-right > div:last-child > div:last-child {
      left: auto;
      right: auto;
      right: 0; }
  .crm-filter-wrapper .datepicker,
  .crm-filter-wrapper .mui-field {
    border-radius: 2px;
    border: solid 1px #c4cfe4;
    background-color: #ffffff;
    min-width: 170px;
    margin: 0;
    margin-right: 10px;
    padding: 2px 8px;
    font-size: 12px;
    font-family: 'sukh-regular';
    color: #242424; }
    .crm-filter-wrapper .datepicker input,
    .crm-filter-wrapper .mui-field input {
      font-size: 11px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424; }
    .crm-filter-wrapper .datepicker > div:hover:before,
    .crm-filter-wrapper .mui-field > div:hover:before {
      border-bottom: 0 !important; }
    .crm-filter-wrapper .datepicker > div:hover:after,
    .crm-filter-wrapper .mui-field > div:hover:after {
      border-bottom: 0; }
    .crm-filter-wrapper .datepicker > div:before,
    .crm-filter-wrapper .mui-field > div:before {
      border-bottom: 0; }
    .crm-filter-wrapper .datepicker > div:after,
    .crm-filter-wrapper .mui-field > div:after {
      border-bottom: 0; }
    .crm-filter-wrapper .datepicker ::-webkit-input-placeholder,
    .crm-filter-wrapper .mui-field ::-webkit-input-placeholder {
      /* WebKit browsers */
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424 !important; }
    .crm-filter-wrapper .datepicker :-moz-placeholder,
    .crm-filter-wrapper .mui-field :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424 !important; }
    .crm-filter-wrapper .datepicker ::-moz-placeholder,
    .crm-filter-wrapper .mui-field ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424 !important; }
    .crm-filter-wrapper .datepicker :-ms-input-placeholder,
    .crm-filter-wrapper .mui-field :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424 !important; }
    .crm-filter-wrapper .datepicker input-placeholder,
    .crm-filter-wrapper .mui-field input-placeholder {
      /* Internet Explorer 10+ */
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424 !important; }
  .crm-filter-wrapper .mui-field:hover:before {
    border-bottom: 0 !important; }
  .crm-filter-wrapper .mui-field:hover:after {
    border-bottom: 0; }
  .crm-filter-wrapper .mui-field:before {
    border-bottom: 0; }
  .crm-filter-wrapper .mui-field:after {
    border-bottom: 0; }
  .crm-filter-wrapper .form-element {
    display: inline-block;
    vertical-align: bottom;
    margin: 0 8px 0 0;
    min-width: 150px; }
    .crm-filter-wrapper .form-element .form-element {
      margin: 0; }
    .crm-filter-wrapper .form-element .form-label {
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424;
      margin-bottom: 4px; }
    .crm-filter-wrapper .form-element.mui-field {
      display: inline-flex; }
  .crm-filter-wrapper .clear-text {
    font-size: 10px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00b0fe;
    display: inline-block;
    cursor: pointer; }

.crm-form-layout ::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent !important; }

.crm-form-layout :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent !important; }

.crm-form-layout ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent !important; }

.crm-form-layout :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent !important; }

.crm-form-layout textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent; }

.crm-form-layout textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent !important; }

.crm-form-layout textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent !important; }

.crm-form-layout textarea:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent !important; }

.crm-form-layout .header-text {
  font-size: 18px;
  font-family: 'sukh-bold';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #192383;
  text-align: left;
  margin-bottom: 22px; }

.crm-form-layout .inline-error {
  margin-left: 0;
  bottom: -16px;
  font-size: 12px;
  top: auto;
  color: #d8001a; }
  .crm-form-layout .inline-error.with-offset {
    top: 64px; }

.crm-form-layout .search-list .search-input input[type='text'] {
  border: 0; }

.crm-form-layout input[type='text'],
.crm-form-layout input[type='email'],
.crm-form-layout input[type='password'],
.crm-form-layout input[type='number'],
.crm-form-layout input[type='radio'],
.crm-form-layout .ui.fluid.dropdown,
.crm-form-layout textarea {
  width: 100%;
  border: 0;
  border-radius: 0;
  padding: 4px 0 !important;
  font-size: 16px !important;
  font-size: 16px;
  font-family: 'sukh-regular';
  line-height: normal;
  color: rgba(0, 0, 0, 0.85); }
  .crm-form-layout input[type='text']:focus,
  .crm-form-layout input[type='email']:focus,
  .crm-form-layout input[type='password']:focus,
  .crm-form-layout input[type='number']:focus,
  .crm-form-layout input[type='radio']:focus,
  .crm-form-layout .ui.fluid.dropdown:focus,
  .crm-form-layout textarea:focus {
    border: 0;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0; }
  .crm-form-layout input[type='text']:focus ~ label,
  .crm-form-layout input[type='email']:focus ~ label,
  .crm-form-layout input[type='password']:focus ~ label,
  .crm-form-layout input[type='number']:focus ~ label,
  .crm-form-layout input[type='radio']:focus ~ label,
  .crm-form-layout .ui.fluid.dropdown:focus ~ label,
  .crm-form-layout textarea:focus ~ label {
    top: -12px;
    color: #f58b1e;
    font-size: 12px; }
  .crm-form-layout input[type='text']:not(:placeholder-shown) ~ label,
  .crm-form-layout input[type='email']:not(:placeholder-shown) ~ label,
  .crm-form-layout input[type='password']:not(:placeholder-shown) ~ label,
  .crm-form-layout input[type='number']:not(:placeholder-shown) ~ label,
  .crm-form-layout input[type='radio']:not(:placeholder-shown) ~ label,
  .crm-form-layout .ui.fluid.dropdown:not(:placeholder-shown) ~ label,
  .crm-form-layout textarea:not(:placeholder-shown) ~ label {
    top: -12px;
    color: #9b9b9b !important;
    font-size: 12px; }

.crm-form-layout > .button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-family: 'sukh-medium';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: none; }

.crm-form-layout > .inline.field,
.crm-form-layout > .field {
  width: 100%;
  position: relative;
  margin: 0 0 24px 0; }
  .crm-form-layout > .inline.field > div:hover:before,
  .crm-form-layout > .field > div:hover:before {
    border-bottom: 2px solid rgba(216, 216, 216, 0.3) !important; }
  .crm-form-layout > .inline.field > div:hover:after,
  .crm-form-layout > .field > div:hover:after {
    border-bottom: 2px solid rgba(216, 216, 216, 0.3); }
  .crm-form-layout > .inline.field > div:before,
  .crm-form-layout > .field > div:before {
    border-bottom: 2px solid rgba(216, 216, 216, 0.3); }
  .crm-form-layout > .inline.field > div:after,
  .crm-form-layout > .field > div:after {
    border-bottom: 2px solid rgba(216, 216, 216, 0.3); }
  .crm-form-layout > .inline.field.mb-0,
  .crm-form-layout > .field.mb-0 {
    margin-bottom: 0; }
  .crm-form-layout > .inline.field > label,
  .crm-form-layout > .field > label {
    font-size: 16px;
    font-family: 'sukh-regular';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #70757b;
    line-height: 1.4;
    top: 0; }
    .crm-form-layout > .inline.field > label[data-shrink='true'],
    .crm-form-layout > .field > label[data-shrink='true'] {
      font-size: 16px;
      color: #f58b1e;
      transform: translate(0, 1.5px) scale(0.75);
      transform-origin: top left; }
  .crm-form-layout > .inline.field.errors input[type='text'],
  .crm-form-layout > .inline.field.errors input[type='email'],
  .crm-form-layout > .inline.field.errors input[type='password'],
  .crm-form-layout > .inline.field.errors input[type='number'],
  .crm-form-layout > .inline.field.errors input[type='radio'],
  .crm-form-layout > .inline.field.errors textarea,
  .crm-form-layout > .field.errors input[type='text'],
  .crm-form-layout > .field.errors input[type='email'],
  .crm-form-layout > .field.errors input[type='password'],
  .crm-form-layout > .field.errors input[type='number'],
  .crm-form-layout > .field.errors input[type='radio'],
  .crm-form-layout > .field.errors textarea {
    border-bottom: 2px solid #d8001a; }
  .crm-form-layout > .inline.field .ui.input,
  .crm-form-layout > .field .ui.input {
    width: 100% !important;
    margin-top: 20px; }
    .crm-form-layout > .inline.field .ui.input input[type='text'],
    .crm-form-layout > .inline.field .ui.input input[type='email'],
    .crm-form-layout > .inline.field .ui.input input[type='password'],
    .crm-form-layout > .inline.field .ui.input input[type='number'],
    .crm-form-layout > .inline.field .ui.input input[type='radio'],
    .crm-form-layout > .inline.field .ui.input textarea,
    .crm-form-layout > .field .ui.input input[type='text'],
    .crm-form-layout > .field .ui.input input[type='email'],
    .crm-form-layout > .field .ui.input input[type='password'],
    .crm-form-layout > .field .ui.input input[type='number'],
    .crm-form-layout > .field .ui.input input[type='radio'],
    .crm-form-layout > .field .ui.input textarea {
      width: 100%; }
  .crm-form-layout > .inline.field > p,
  .crm-form-layout > .field > p {
    color: #d8001a;
    font-size: 12px;
    font-family: 'sukh-regular'; }

.crm-form-layout .react-select-custom {
  outline: none;
  margin-top: 16px; }
  .crm-form-layout .react-select-custom:focus {
    border: 0; }
  .crm-form-layout .react-select-custom > span {
    display: none !important; }
  .crm-form-layout .react-select-custom ::-webkit-input-placeholder {
    /* WebKit browsers */
    font-size: 16px !important;
    font-family: 'sukh-regular' !important;
    color: #70757b !important; }
  .crm-form-layout .react-select-custom :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 16px !important;
    font-family: 'sukh-regular' !important;
    color: #70757b !important; }
  .crm-form-layout .react-select-custom ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 16px !important;
    font-family: 'sukh-regular' !important;
    color: #70757b !important; }
  .crm-form-layout .react-select-custom :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    font-size: 16px !important;
    font-family: 'sukh-regular' !important;
    color: #70757b !important; }
  .crm-form-layout .react-select-custom.selected > div > div:first-child > div {
    font-size: 16px;
    font-family: 'sukh-regular';
    color: rgba(0, 0, 0, 0.85);
    line-height: 1; }
  .crm-form-layout .react-select-custom > div {
    border-radius: 2px;
    border: 0;
    border-bottom: 2px solid rgba(216, 216, 216, 0.3);
    background-color: #ffffff;
    min-width: 100%; }
    .crm-form-layout .react-select-custom > div > div {
      padding: 0; }
      .crm-form-layout .react-select-custom > div > div:first-child > div:last-child {
        padding: 0;
        margin: 0; }
        .crm-form-layout .react-select-custom > div > div:first-child > div:last-child input {
          padding: 0 !important; }
      .crm-form-layout .react-select-custom > div > div:nth-child(2) {
        display: none; }
        .crm-form-layout .react-select-custom > div > div:nth-child(2) > span {
          display: none; }

.crm-form {
  margin-top: 32px; }
  .crm-form .form-element {
    display: block;
    margin-bottom: 28px; }
    .crm-form .form-element.checkbox-group > div {
      min-width: 240px; }
      .crm-form .form-element.checkbox-group > div > label > span {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #f58b1e; }
        .crm-form .form-element.checkbox-group > div > label > span:last-child {
          color: #000; }
      .crm-form .form-element.checkbox-group > div svg {
        width: 14px;
        height: 14px; }
    .crm-form .form-element > label {
      font-size: 14px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #5e5e5e;
      top: -2px; }
      .crm-form .form-element > label[data-shrink='true'] {
        font-size: 18px; }
    .crm-form .form-element > div {
      min-width: 240px; }
      .crm-form .form-element > div > label > span {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000; }
      .crm-form .form-element > div input {
        padding: 6px 0 4px; }
      .crm-form .form-element > div:before, .crm-form .form-element > div:after {
        border-bottom: solid 1px #e6eaf8; }
      .crm-form .form-element > div:hover:before, .crm-form .form-element > div:hover:after {
        border-bottom: solid 1px #e6eaf8 !important; }
    .crm-form .form-element .button {
      text-transform: none;
      min-width: 240px; }

.affiliate-discount-form {
  padding: 20px; }
  .affiliate-discount-form .default {
    background-color: #ffe4c4; }
  .affiliate-discount-form .filter-wrapper,
  .affiliate-discount-form .filter-wrap {
    margin-bottom: 20px; }
  .affiliate-discount-form .button-wrap {
    display: flex;
    width: 100%;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-end; }
  .affiliate-discount-form > div {
    display: flex;
    align-items: center; }
    .affiliate-discount-form > div > div {
      flex-grow: 1;
      flex-basis: 100%; }

.affiliate-documents {
  padding: 20px;
  margin: 10px; }
  .affiliate-documents .title-text {
    font-size: 16px; }
  .affiliate-documents .key-value .key {
    padding: 10px; }
  .affiliate-documents .key-value .value {
    padding: 10px; }

.button-wrap-refund {
  margin-top: 20px; }

.crm-table-wrapper {
  display: block;
  width: 100%;
  background: #fff;
  background-color: #ffffff;
  position: relative; }
  .crm-table-wrapper.historical-data-table table {
    table-layout: auto; }
    .crm-table-wrapper.historical-data-table table tbody tr:nth-child(even) td,
    .crm-table-wrapper.historical-data-table table tbody tr:nth-child(even) th {
      background: #fff; }
    .crm-table-wrapper.historical-data-table table thead tr th {
      font-size: 14px;
      font-weight: bold; }
    .crm-table-wrapper.historical-data-table table tbody tr td {
      border-bottom: 1px solid #d1d1d1; }
    .crm-table-wrapper.historical-data-table table .sub-table {
      table-layout: fixed; }
      .crm-table-wrapper.historical-data-table table .sub-table tbody tr td,
      .crm-table-wrapper.historical-data-table table .sub-table thead tr th {
        padding: 6px 16px 6px 6px;
        word-break: break-word;
        font-size: 12px; }
      .crm-table-wrapper.historical-data-table table .sub-table tbody tr:nth-child(even) td,
      .crm-table-wrapper.historical-data-table table .sub-table tbody tr:nth-child(even) th {
        background: #f8fafc; }
      .crm-table-wrapper.historical-data-table table .sub-table tbody tr:last-child td {
        border: 0; }
      .crm-table-wrapper.historical-data-table table .sub-table tbody tr td {
        border-bottom: 1px solid #d1d1d1; }
  .crm-table-wrapper.pop-over {
    max-width: 400px;
    border: 1px solid; }
  .crm-table-wrapper .unlinked-bank-statements {
    table-layout: auto; }
  .crm-table-wrapper.sub-agent table thead tr th:nth-child(8) {
    width: 180px; }
  .crm-table-wrapper.sub-agent table tbody tr td:nth-child(8) {
    width: 180px; }
  .crm-table-wrapper.tele-sales table thead tr th:nth-child(8) {
    display: none; }
  .crm-table-wrapper.tele-sales table tbody tr td:nth-child(8) {
    display: none; }
  .crm-table-wrapper.scrollable-table {
    width: 195%; }
    .crm-table-wrapper.scrollable-table .scrollable-notify {
      position: absolute;
      top: -18px;
      font-size: 14px;
      background-color: #f58b1e;
      color: #192383;
      padding: 2px; }
  .crm-table-wrapper .with-overflow {
    overflow-x: auto; }
  .crm-table-wrapper .text-ellipsis {
    overflow-x: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 5ch; }
  .crm-table-wrapper .break-word-container {
    width: 15ch;
    word-break: break-word;
    padding-right: 15px; }
  .crm-table-wrapper .action-wrapper {
    position: absolute;
    right: 0;
    top: -12px;
    padding: 4px 24px 4px 4px; }
  .crm-table-wrapper .menu-wrapper {
    display: inline-block;
    position: absolute;
    right: 3px;
    top: calc(50% - 22px); }
    .crm-table-wrapper .menu-wrapper > div svg {
      fill: #c4cfe4;
      font-size: 18px; }
  .crm-table-wrapper .link {
    color: #4183c4;
    cursor: pointer; }
  .crm-table-wrapper button {
    cursor: pointer; }
  .crm-table-wrapper.leads.affiliate tr td:nth-child(3),
  .crm-table-wrapper.leads.affiliate tr th:nth-child(3),
  .crm-table-wrapper.leads.affiliate tr td:nth-child(4),
  .crm-table-wrapper.leads.affiliate tr th:nth-child(4) {
    display: none; }
  .crm-table-wrapper.coa.affiliate tr td:nth-child(3),
  .crm-table-wrapper.coa.affiliate tr th:nth-child(3),
  .crm-table-wrapper.coa.affiliate tr td:nth-child(4),
  .crm-table-wrapper.coa.affiliate tr th:nth-child(4) {
    display: none; }
  .crm-table-wrapper.invoice-table {
    width: 100%; }
    .crm-table-wrapper.invoice-table tr th {
      padding: 4px 20px 4px 0;
      word-break: break-word; }
    .crm-table-wrapper.invoice-table tr td {
      padding: 4px 20px 4px 0; }
  .crm-table-wrapper .with-padding {
    padding: 24px; }
  .crm-table-wrapper .blue,
  .crm-table-wrapper .pending {
    color: #1d2785;
    font-family: 'sukh-bold'; }
  .crm-table-wrapper .green,
  .crm-table-wrapper .approved {
    color: #589707;
    font-family: 'sukh-bold'; }
  .crm-table-wrapper .red,
  .crm-table-wrapper .rejected {
    color: #d70202;
    font-family: 'sukh-bold'; }
  .crm-table-wrapper .yellow {
    color: #f5a623;
    font-family: 'sukh-bold'; }
  .crm-table-wrapper table {
    width: 100%;
    margin-top: 20px;
    table-layout: fixed; }
    .crm-table-wrapper table .checkbox {
      padding: 0; }
    .crm-table-wrapper table input {
      border: 0;
      outline: 0;
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a; }
      .crm-table-wrapper table input:focus {
        border-bottom: 1px solid #efefef;
        padding-bottom: 2px;
        color: #000; }
    .crm-table-wrapper table .crm-label {
      border-radius: 11px;
      font-size: 10px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      line-height: normal;
      padding: 1px 4px;
      display: inline-block;
      margin-right: 4px;
      border: 1px solid #ccc;
      min-width: 40px;
      text-align: center;
      margin-bottom: 4px; }
      .crm-table-wrapper table .crm-label.red {
        color: #b04747;
        border: solid 1px #f8a0a0;
        background-color: #fff3f3; }
      .crm-table-wrapper table .crm-label.green {
        color: #378033;
        border: solid 1px #289a13;
        background-color: #f1fdf5; }
      .crm-table-wrapper table .crm-label.yellow {
        border: solid 1px #fff200;
        background-color: #ffffeb;
        color: #000000; }
      .crm-table-wrapper table .crm-label.blue {
        border: solid 1px #1890ff;
        background-color: #1890ff;
        color: #fff; }
    .crm-table-wrapper table thead tr.clickable-header:last-child th {
      border: 0;
      padding-top: 0; }
    .crm-table-wrapper table thead tr.clickable-header th {
      padding: 10px;
      cursor: pointer; }
    .crm-table-wrapper table thead tr th {
      font-size: 12px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424;
      padding: 4px 32px 4px 0;
      text-align: left;
      border-bottom: solid 0.5px #e3e3e3;
      text-transform: capitalize; }
      .crm-table-wrapper table thead tr th .insurer-logo {
        max-height: 40px;
        vertical-align: middle; }
        .crm-table-wrapper table thead tr th .insurer-logo.desktop {
          max-height: 24px; }
        .crm-table-wrapper table thead tr th .insurer-logo.mobile {
          max-height: 24px; }
      .crm-table-wrapper table thead tr th.with-top-border {
        border-top: solid 0.5px #e3e3e3; }
      .crm-table-wrapper table thead tr th.right {
        text-align: right !important; }
      .crm-table-wrapper table thead tr th label {
        margin-left: 0; }
      .crm-table-wrapper table thead tr th:first-child {
        padding-left: 24px; }
      .crm-table-wrapper table thead tr th:last-child {
        padding-right: 24px; }
      .crm-table-wrapper table thead tr th.align-right {
        text-align: right; }
    .crm-table-wrapper table tbody tr.payment-wrapper:last-child td.no-padding {
      padding-top: 0; }
    .crm-table-wrapper table tbody tr.payment-wrapper:last-child th.payment-table-headers {
      word-break: break-word;
      font-size: medium;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a; }
    .crm-table-wrapper table tbody tr td {
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      padding: 12px 32px 12px 0;
      word-break: break-all;
      min-width: 160px;
      position: relative; }
      .crm-table-wrapper table tbody tr td.cif-column {
        word-break: break-word; }
      .crm-table-wrapper table tbody tr td.invoice-table-column {
        word-break: break-word; }
      .crm-table-wrapper table tbody tr td.right {
        text-align: right !important; }
      .crm-table-wrapper table tbody tr td .button {
        font-size: 10px;
        font-family: 'sukh-bold';
        padding: 6px 10px;
        border: 1px solid #ff901d;
        background: #fff;
        color: #ff901d;
        text-transform: none; }
      .crm-table-wrapper table tbody tr td:first-child {
        padding-left: 24px; }
      .crm-table-wrapper table tbody tr td:last-child {
        padding-right: 12px;
        min-width: 180px; }
      .crm-table-wrapper table tbody tr td.with-menu {
        position: relative;
        padding-right: 44px; }
      .crm-table-wrapper table tbody tr td > span.select-text {
        cursor: pointer;
        display: block; }
      .crm-table-wrapper table tbody tr td .remark-text {
        font-size: 14px;
        color: #fe972c;
        cursor: pointer;
        min-width: 90px;
        display: inline-block; }
      .crm-table-wrapper table tbody tr td.message-wrapper {
        padding: 32px;
        text-align: center !important; }
        .crm-table-wrapper table tbody tr td.message-wrapper .text {
          font-size: 18px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #4a4a4a;
          margin-bottom: 20px; }
        .crm-table-wrapper table tbody tr td.message-wrapper .refer-earn-flat button {
          min-width: 230px;
          background: #ff901d;
          color: #fff; }
    .crm-table-wrapper table tbody tr:nth-child(even) td,
    .crm-table-wrapper table tbody tr:nth-child(even) th {
      background: #f8fafc; }
    .crm-table-wrapper table tbody tr:last-child td {
      padding-top: 24px; }
    .crm-table-wrapper table tbody tr:last-child td,
    .crm-table-wrapper table tbody tr:last-child th {
      border-bottom: 1px solid #d1d1d1; }
      .crm-table-wrapper table tbody tr:last-child td.invoice-table-column,
      .crm-table-wrapper table tbody tr:last-child th.invoice-table-column {
        border-bottom: solid 0.5px #e3e3e3; }
  .crm-table-wrapper.vertical-table table tr.clickable-header:last-child th {
    border: 0;
    padding-top: 0; }
  .crm-table-wrapper.vertical-table table tr.clickable-header th {
    padding: 10px;
    cursor: pointer; }
  .crm-table-wrapper.vertical-table table tr th {
    font-size: 12px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #242424;
    padding: 4px 32px 4px 0;
    text-align: left;
    border-bottom: solid 0.5px #e3e3e3;
    text-transform: capitalize; }
    .crm-table-wrapper.vertical-table table tr th .insurer-logo {
      max-height: 40px;
      vertical-align: middle; }
      .crm-table-wrapper.vertical-table table tr th .insurer-logo.desktop {
        max-height: 24px; }
      .crm-table-wrapper.vertical-table table tr th .insurer-logo.mobile {
        max-height: 24px; }
    .crm-table-wrapper.vertical-table table tr th.with-top-border {
      border-top: solid 0.5px #e3e3e3; }
    .crm-table-wrapper.vertical-table table tr th.right {
      text-align: right !important; }
    .crm-table-wrapper.vertical-table table tr th label {
      margin-left: 0; }
    .crm-table-wrapper.vertical-table table tr th:first-child {
      padding-left: 24px; }
    .crm-table-wrapper.vertical-table table tr th:last-child {
      padding-right: 24px; }
    .crm-table-wrapper.vertical-table table tr th.align-right {
      text-align: right; }
  .crm-table-wrapper.vertical-table table tr.payment-wrapper:last-child td.no-padding {
    padding-top: 0; }
  .crm-table-wrapper.vertical-table table tr.payment-wrapper:last-child th.payment-table-headers {
    word-break: break-word;
    font-size: medium;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a; }
  .crm-table-wrapper.vertical-table table tr td {
    font-size: 12px;
    font-family: 'sukh-regular';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    padding: 12px 32px 12px 0;
    word-break: break-all;
    min-width: 160px;
    position: relative; }
    .crm-table-wrapper.vertical-table table tr td.cif-column {
      word-break: break-word; }
    .crm-table-wrapper.vertical-table table tr td.invoice-table-column {
      word-break: break-word; }
    .crm-table-wrapper.vertical-table table tr td.right {
      text-align: right !important; }
    .crm-table-wrapper.vertical-table table tr td .button {
      font-size: 10px;
      font-family: 'sukh-bold';
      padding: 6px 10px;
      border: 1px solid #ff901d;
      background: #fff;
      color: #ff901d;
      text-transform: none; }
    .crm-table-wrapper.vertical-table table tr td:first-child {
      padding-left: 24px; }
    .crm-table-wrapper.vertical-table table tr td:last-child {
      padding-right: 12px;
      min-width: 180px; }
    .crm-table-wrapper.vertical-table table tr td.with-menu {
      position: relative;
      padding-right: 44px; }
    .crm-table-wrapper.vertical-table table tr td > span.select-text {
      cursor: pointer;
      display: block; }
    .crm-table-wrapper.vertical-table table tr td .remark-text {
      font-size: 14px;
      color: #fe972c;
      cursor: pointer;
      min-width: 90px;
      display: inline-block; }
    .crm-table-wrapper.vertical-table table tr td.message-wrapper {
      padding: 32px;
      text-align: center !important; }
      .crm-table-wrapper.vertical-table table tr td.message-wrapper .text {
        font-size: 18px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #4a4a4a;
        margin-bottom: 20px; }
      .crm-table-wrapper.vertical-table table tr td.message-wrapper .refer-earn-flat button {
        min-width: 230px;
        background: #ff901d;
        color: #fff; }
  .crm-table-wrapper.vertical-table table tr th {
    color: #8a95aa;
    font-family: 'sukh-regular'; }
  .crm-table-wrapper.vertical-table table tr td {
    border-bottom: solid 0.5px #e3e3e3; }
  .crm-table-wrapper .button-wrap {
    justify-content: flex-end;
    display: flex; }
    .crm-table-wrapper .button-wrap button {
      min-width: 52px;
      font-size: 10px;
      font-weight: bold; }
      .crm-table-wrapper .button-wrap button:first-child {
        margin-right: 4px; }

.crm-form-wrapper {
  box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  padding: 36px;
  max-width: 544px;
  height: 100%;
  max-height: var(--vh);
  overflow-y: auto;
  width: 100%; }
  .crm-form-wrapper .tabs-wrapper {
    width: 100%;
    min-height: auto; }
    .crm-form-wrapper .tabs-wrapper > div > div > span {
      background: #f58b1e; }
    .crm-form-wrapper .tabs-wrapper .tab {
      font-family: 'sukh-medium';
      font-size: 16px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #9b9b9b;
      text-transform: none;
      min-width: auto;
      min-height: auto;
      margin: 0 16px;
      position: relative;
      overflow: unset; }
      .crm-form-wrapper .tabs-wrapper .tab > span > span {
        padding: 0; }
      .crm-form-wrapper .tabs-wrapper .tab:first-child {
        margin-left: 0; }
      .crm-form-wrapper .tabs-wrapper .tab:not(:last-child):after {
        content: '';
        position: absolute;
        right: -16px;
        top: calc(50% - 7px);
        width: 1px;
        height: 14px;
        border: solid 1px #e1e1e1; }
      .crm-form-wrapper .tabs-wrapper .tab[aria-selected='true'] {
        color: #1b1b1b;
        font-family: 'sukh-bold'; }

.crm-action-sheet {
  background-color: rgba(77, 92, 116, 0.9);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  transform: translateY(100%);
  transition: all 0.3s ease-out;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end; }
  .crm-action-sheet .close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer; }
  .crm-action-sheet.show {
    transform: translateY(0); }
  .crm-action-sheet .blank-space {
    flex-grow: 1; }

.crm-menu > div {
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.13);
  border-radius: 0;
  width: auto !important;
  min-width: 110px; }
  .crm-menu > div ul li {
    font-size: 12px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #84878e;
    padding: 4px 12px; }

.crm-table-info-action-wrapper {
  display: flex;
  align-items: center;
  margin: 16px 0; }
  .crm-table-info-action-wrapper .text-wrap {
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    font-size: 12px; }
    .crm-table-info-action-wrapper .text-wrap span {
      font-family: 'sukh-bold';
      color: #000000; }
  .crm-table-info-action-wrapper .right-align {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end; }
    .crm-table-info-action-wrapper .right-align > div {
      display: flex;
      align-items: center; }
  .crm-table-info-action-wrapper .line {
    width: 1px;
    height: 17px;
    border: solid 1px #c4cfe4;
    margin: 0 16px; }
  .crm-table-info-action-wrapper .text {
    font-size: 12px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a; }
  .crm-table-info-action-wrapper .pagination {
    border-radius: 1.4px;
    border: solid 1px #c4cfe4;
    display: flex;
    margin-left: 16px; }
    .crm-table-info-action-wrapper .pagination p {
      padding: 4px 9px;
      cursor: pointer; }
      .crm-table-info-action-wrapper .pagination p:first-child {
        border-right: solid 0.7px #c4cfe4; }
      .crm-table-info-action-wrapper .pagination p img {
        max-width: 10px; }
  .crm-table-info-action-wrapper .refresh {
    width: 26px;
    height: 25px;
    border-radius: 1.4px;
    background-color: #ffffff;
    cursor: pointer; }
  .crm-table-info-action-wrapper .text-two {
    font-size: 16px;
    font-family: 'sukh-medium';
    color: #4a4a4a;
    margin-left: 15px; }
  .crm-table-info-action-wrapper .select {
    margin: 0px 10px;
    width: 80px; }
  .crm-table-info-action-wrapper .button {
    border-radius: 1px;
    border: solid 1px #f58b1e;
    background-color: #ffffff;
    padding: 4px 12px;
    font-size: 10px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f58b1e;
    text-transform: none;
    margin-left: 20px; }

.crm-loading {
  width: 100%;
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.crm-message-wrapper {
  padding: 24px; }
  .crm-message-wrapper p {
    font-size: 12px;
    font-family: 'sukh-regular';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #242424; }

.crm-affiliates .filter-wrap {
  width: 100%;
  margin-bottom: 12px; }

.crm-react-select-custom {
  max-width: 300px;
  min-width: 300px; }
  .crm-react-select-custom > div {
    border-radius: 2px;
    border: solid 1px #c4cfe4;
    background-color: #ffffff; }
    .crm-react-select-custom > div div:first-child div {
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424; }
    .crm-react-select-custom > div div:last-child > span {
      display: none; }

.crm-popover > div:last-child {
  padding: 12px;
  min-height: 112px; }

.crm-popover > div .crm-react-select-custom {
  margin-bottom: 12px; }

.crm-popover > div .button {
  width: 100%; }

.microsite-details {
  margin: 12px 0;
  padding-top: 16px;
  border-top: solid 0.5px #e3e3e3; }
  .microsite-details .edit {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0; }
  .microsite-details .microsite {
    position: relative;
    padding: 12px 0; }
    .microsite-details .microsite:not(:last-child) {
      border-bottom: solid 0.5px #e3e3e3;
      margin-bottom: 12px; }
  .microsite-details .title-text {
    font-size: 12px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8a95aa;
    margin-bottom: 8px; }
  .microsite-details .flex-wrap {
    display: flex;
    align-items: center; }
    .microsite-details .flex-wrap .key {
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8a95aa; }
    .microsite-details .flex-wrap .value {
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424;
      word-break: break-word; }
    .microsite-details .flex-wrap p {
      flex-grow: 1;
      flex-basis: 100%; }
      .microsite-details .flex-wrap p:first-child {
        min-width: 60%; }

@media screen and (max-width: 768px) {
  .crm {
    padding: 12px;
    height: 100%;
    width: 100%; }
    .crm .crm-listing-layout {
      height: 100%;
      width: 100%; }
      .crm .crm-listing-layout .mobile-listing-filter {
        position: absolute;
        right: 20px;
        top: 10px; }
      .crm .crm-listing-layout .mobile-view {
        height: calc(100% - 120px);
        width: 100%;
        overflow-y: auto; }
  .crm-listing-layout .title-block {
    flex-direction: column;
    margin-bottom: 0; }
    .crm-listing-layout .title-block .title-text {
      margin-bottom: 12px;
      font-size: 14px;
      width: 100%; }
      .crm-listing-layout .title-block .title-text button {
        padding: 6px 16px;
        font-size: 10px; }
    .crm-listing-layout .title-block .task-status {
      width: 100%; }
      .crm-listing-layout .title-block .task-status .text-one {
        font-size: 12px;
        font-family: 'sukh-regular'; }
      .crm-listing-layout .title-block .task-status .col {
        margin-left: 0;
        flex-grow: 1;
        flex-basis: 100%; }
  .crm-filter-wrapper .form-element {
    width: 100%; } }

.list-card {
  border-radius: 12px;
  box-shadow: 1px 1px 11px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ecebeb;
  background-color: #ffffff;
  margin-top: 18px; }
  .list-card.renewal-price-list {
    background-color: #fffcf2;
    box-shadow: 0 5px 8px 0 rgba(197, 197, 207, 0.78); }
  .list-card .card-wrapper {
    cursor: pointer; }
  .list-card .section {
    position: relative; }
    .list-card .section .row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative; }
    .list-card .section.one {
      padding: 18px 18px 8px 18px; }
      .list-card .section.one .align-right {
        flex-grow: 1;
        text-align: right; }
      .list-card .section.one .row {
        margin-bottom: 4px;
        display: flex;
        width: 100%; }
        .list-card .section.one .row .user-info-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%; }
        .list-card .section.one .row .user-info-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 25ch; }
        .list-card .section.one .row .value {
          font-size: 14px;
          font-family: 'sukh-bold';
          color: rgba(34, 34, 34, 0.85); }
      .list-card .section.one .checkbox {
        padding: 0;
        margin-right: 20px; }
        .list-card .section.one .checkbox svg {
          color: rgba(0, 0, 0, 0.85);
          width: 20px;
          height: 20px; }
      .list-card .section.one .img-wrap {
        margin-right: 12px; }
        .list-card .section.one .img-wrap img {
          max-height: 44px; }
      .list-card .section.one .line {
        margin-right: 12px;
        width: 1px;
        height: 24px;
        border: solid 1px #d7d7d7; }
      .list-card .section.one .text-one {
        font-size: 16px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #009c79; }
      .list-card .section.one .text-two {
        font-size: 14px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(34, 34, 34, 0.85);
        padding-left: 40px; }
      .list-card .section.one .tag-wrapper {
        display: flex;
        flex-direction: column;
        right: 0;
        position: absolute;
        bottom: 0;
        align-items: flex-end;
        justify-content: right; }
        .list-card .section.one .tag-wrapper .tag:not(:last-child) {
          margin-bottom: 5px; }
      .list-card .section.one .tag {
        border-radius: 100px 0 0 100px;
        background-color: #3b8fae;
        padding: 2px 10px;
        font-size: 12px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff; }
        .list-card .section.one .tag.green {
          background-color: #009c79; }
        .list-card .section.one .tag.yellow {
          background-color: #eeb22a; }
        .list-card .section.one .tag.cheapest-price {
          margin-left: auto;
          max-width: fit-content;
          min-width: 160px; }
        .list-card .section.one .tag.restriction-text {
          border-radius: 100px;
          max-width: fit-content;
          word-break: break-all; }
    .list-card .section.two {
      padding: 0 35px; }
      .list-card .section.two .row {
        border-top: solid 1px #f3efef;
        padding: 16px 0 12px 30px;
        align-items: flex-start; }
        .list-card .section.two .row .col {
          text-align: left; }
          .list-card .section.two .row .col:not(:last-child) {
            margin-right: 30px; }
          .list-card .section.two .row .col.mr-0 {
            margin-right: 0; }
          .list-card .section.two .row .col .commission-text {
            font-weight: bold;
            font-size: 12px;
            line-height: 24px;
            color: #4a4a4a;
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            justify-content: flex-end; }
            .list-card .section.two .row .col .commission-text img {
              margin-right: 4px; }
          .list-card .section.two .row .col.align-right {
            flex-grow: 1;
            text-align: right; }
          .list-card .section.two .row .col .text-one {
            font-size: 14px;
            font-family: 'sukh-medium';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 0.85); }
            .list-card .section.two .row .col .text-one:not(:last-child) {
              margin-bottom: 4px; }
            .list-card .section.two .row .col .text-one span {
              font-family: 'sukh-bold'; }
            .list-card .section.two .row .col .text-one img {
              width: 14px;
              height: 14px;
              object-fit: contain;
              margin-left: 6px; }
          .list-card .section.two .row .col .text-two {
            font-size: 24px;
            font-family: 'sukh-bold';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4a4a4a;
            display: flex;
            align-items: center;
            justify-content: flex-end; }
            .list-card .section.two .row .col .text-two span {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.85); }
            .list-card .section.two .row .col .text-two img {
              width: 14px;
              height: 14px;
              object-fit: contain;
              margin-left: 6px; }
          .list-card .section.two .row .col .large-text {
            font-size: 18px;
            font-family: 'sukh-bold';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4a4a4a;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: flex-end; }
            .list-card .section.two .row .col .large-text span {
              color: rgba(0, 0, 0, 0.85); }
            .list-card .section.two .row .col .large-text img {
              width: 14px;
              height: 14px;
              object-fit: contain;
              margin-left: 6px; }
        .list-card .section.two .row.no-border {
          border-top: 0;
          padding: 0 0 24px 0; }
    .list-card .section.three {
      background-color: #e2eff9;
      padding: 10px 18px; }
      .list-card .section.three .row {
        min-height: 22px;
        position: relative;
        width: 100%; }
      .list-card .section.three .see-details {
        font-size: 12px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        text-decoration: underline;
        cursor: pointer; }
      .list-card .section.three .tags-wrapper {
        display: flex;
        align-items: center;
        margin-right: 16px; }
        .list-card .section.three .tags-wrapper .tags {
          border-radius: 100px;
          border: solid 1px #3b8fae;
          background-color: #fffbf6;
          font-size: 10px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a;
          padding: 2px 6px;
          margin-right: 4px; }
    .list-card .section .line {
      width: 2px;
      height: 11px;
      border: solid 1px #94b1c7;
      margin-right: 16px; }
    .list-card .section .text-wrapper {
      display: flex; }
      .list-card .section .text-wrapper .text-one {
        font-size: 12px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.85);
        margin-right: 4px; }
        .list-card .section .text-wrapper .text-one span {
          font-family: 'sukh-bold';
          text-decoration: underline;
          cursor: pointer; }
    .list-card .section .toggle-arrow {
      position: absolute;
      right: 0;
      cursor: pointer;
      min-width: 32px;
      min-height: 32px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .list-card .section .toggle-arrow.rotate {
        transform: rotate(-180deg); }
  .list-card .details-wrapper .title-wrap {
    background-color: #f6f6f6;
    padding: 5px 12px;
    display: flex; }
    .list-card .details-wrapper .title-wrap .text-one {
      font-size: 14px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.85); }
      .list-card .details-wrapper .title-wrap .text-one.link {
        color: #ff901d;
        text-decoration: underline;
        cursor: pointer; }
      .list-card .details-wrapper .title-wrap .text-one:not(:first-child) {
        flex-grow: 1;
        text-align: right; }
  .list-card .details-wrapper .content {
    padding: 10px 12px;
    text-align: left; }
    .list-card .details-wrapper .content .mb-12 {
      margin-bottom: 12px; }
    .list-card .details-wrapper .content ul {
      padding: 0;
      margin: 0; }
      .list-card .details-wrapper .content ul li {
        margin: 0;
        padding: 0 0 0 16px;
        position: relative; }
        .list-card .details-wrapper .content ul li:before {
          content: '';
          position: absolute;
          width: 3px;
          height: 3px;
          border-radius: 100%;
          background: rgba(0, 0, 0, 0.85);
          left: 0;
          top: calc(50% - 2px); }
    .list-card .details-wrapper .content .mb-40 {
      margin-bottom: 40px; }
    .list-card .details-wrapper .content .mb-16 {
      margin-bottom: 16px; }
    .list-card .details-wrapper .content .text-one {
      font-size: 14px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.85); }
      .list-card .details-wrapper .content .text-one.bold {
        font-family: 'sukh-bold';
        line-height: 1.8; }
      .list-card .details-wrapper .content .text-one.link {
        color: #ff901d;
        text-decoration: underline;
        cursor: pointer; }
        .list-card .details-wrapper .content .text-one.link a {
          color: #ff901d;
          text-decoration: underline; }
      .list-card .details-wrapper .content .text-one.pre-wrap {
        white-space: pre-wrap; }
    .list-card .details-wrapper .content.tabular {
      padding: 0; }
      .list-card .details-wrapper .content.tabular > .row {
        display: flex;
        align-items: stretch; }
        .list-card .details-wrapper .content.tabular > .row:not(:last-child) {
          border-bottom: solid 1px #eaeaea; }
        .list-card .details-wrapper .content.tabular > .row:last-child .col {
          padding-bottom: 16px; }
        .list-card .details-wrapper .content.tabular > .row .col {
          flex-basis: 100%;
          flex-grow: 1;
          padding: 8px 16px 8px 12px; }
          .list-card .details-wrapper .content.tabular > .row .col:not(:last-child) {
            border-right: solid 1px #eaeaea; }
          .list-card .details-wrapper .content.tabular > .row .col:not(:first-child) {
            padding-left: 12px; }
          .list-card .details-wrapper .content.tabular > .row .col.two {
            min-width: 205px; }
          .list-card .details-wrapper .content.tabular > .row .col.three {
            min-width: 138px; }
          .list-card .details-wrapper .content.tabular > .row .col.four {
            min-width: 166px; }
          .list-card .details-wrapper .content.tabular > .row .col.five {
            max-width: 410px; }
          .list-card .details-wrapper .content.tabular > .row .col .key-value {
            display: flex; }
            .list-card .details-wrapper .content.tabular > .row .col .key-value p:not(:first-child) {
              flex-grow: 1;
              text-align: right; }
            .list-card .details-wrapper .content.tabular > .row .col .key-value > div {
              flex-grow: 1;
              text-align: right;
              min-width: 35%; }

.new-landing-wrapper {
  padding-top: 72px; }
  .new-landing-wrapper button {
    cursor: pointer; }
  .new-landing-wrapper .header {
    display: flex;
    align-items: center;
    padding: 20px 72px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 99;
    background-color: #ffffff; }
    .new-landing-wrapper .header > div {
      display: flex;
      align-items: center; }
      .new-landing-wrapper .header > div:first-child {
        flex-grow: 1; }
        .new-landing-wrapper .header > div:first-child img {
          margin-right: 24px;
          height: 32px; }
        .new-landing-wrapper .header > div:first-child span {
          font-size: 16px;
          font-family: 'sukh-regular';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #5c5c5c;
          padding-left: 24px;
          border-left: solid 1px #e2e2e2; }
      .new-landing-wrapper .header > div .language-toggle {
        display: flex; }
        .new-landing-wrapper .header > div .language-toggle span {
          flex-grow: 1;
          flex-basis: 100%;
          text-align: center;
          padding: 0 12px;
          cursor: pointer; }
          .new-landing-wrapper .header > div .language-toggle span:first-child {
            border-right: solid 1px #d3d2d2; }
          .new-landing-wrapper .header > div .language-toggle span.active {
            color: #ff901d; }
      .new-landing-wrapper .header > div:last-child p {
        font-size: 16px;
        font-family: 'sukh-regular';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1b1b1b;
        margin-right: 20px;
        cursor: pointer; }
        .new-landing-wrapper .header > div:last-child p.btn {
          border-radius: 20px;
          border: solid 2px #ff901d;
          background-color: #ffffff;
          font-size: 16px;
          font-family: 'sukh-medium';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b1b1b;
          padding: 5px 20px; }
  .new-landing-wrapper .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0; }
  .new-landing-wrapper .section-one {
    background: url(images/onboarding/nl-one.png) no-repeat;
    height: 489px;
    background-size: cover;
    display: flex;
    align-items: flex-start; }
    .new-landing-wrapper .section-one .text-one {
      font-size: 36px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.39;
      letter-spacing: normal;
      color: #000000; }
    .new-landing-wrapper .section-one button {
      margin-top: 12px;
      border-radius: 24px;
      border: solid 1px #f58b1e;
      background-color: #ff901d;
      font-size: 18px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      padding: 10px 40px;
      outline: none; }
    .new-landing-wrapper .section-one .container {
      max-width: 1000px;
      margin: 0;
      padding: 78px 70px; }
    .new-landing-wrapper .section-one .play-icon-holder {
      font-size: 16px;
      font-family: 'sukh-bold';
      color: #ff901d;
      margin: 12px;
      cursor: pointer; }
    .new-landing-wrapper .section-one .play-icon-text {
      display: inline-block;
      vertical-align: super;
      padding-left: 3px; }
  .new-landing-wrapper .section-two {
    margin-bottom: 80px; }
    .new-landing-wrapper .section-two .container {
      padding: 24px 0 32px 0;
      display: block;
      text-align: center;
      border-bottom: solid 2px #f0f0f0; }
      .new-landing-wrapper .section-two .container > div.insurers-wrapper {
        margin-top: 32px; }
        .new-landing-wrapper .section-two .container > div.insurers-wrapper img {
          max-height: 56px; }
          .new-landing-wrapper .section-two .container > div.insurers-wrapper img:not(:last-child) {
            margin-right: 32px; }
      .new-landing-wrapper .section-two .container > div:last-child {
        flex-grow: 1; }
    .new-landing-wrapper .section-two .oic-custom-logo {
      padding: 10px 10px 0;
      min-width: 300px;
      border-radius: 28px;
      background-color: #fff7e0;
      display: inline-block;
      box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.3);
      vertical-align: top;
      margin-right: 32px; }
      .new-landing-wrapper .section-two .oic-custom-logo img {
        max-height: 36px; }
      .new-landing-wrapper .section-two .oic-custom-logo .text {
        display: inline-block;
        font-family: 'sukh-bold';
        color: #ff6a00;
        vertical-align: top;
        padding-top: 10px; }
    .new-landing-wrapper .section-two .text-one {
      font-size: 28px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.79;
      letter-spacing: normal;
      color: #000000; }
    .new-landing-wrapper .section-two .text-two {
      font-size: 20px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: 2.5;
      letter-spacing: normal;
      color: #000000; }
  .new-landing-wrapper .section-three {
    margin-bottom: 136px; }
    .new-landing-wrapper .section-three .text-one {
      font-size: 36px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.39;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      margin-bottom: 80px; }
    .new-landing-wrapper .section-three .row {
      display: flex; }
      .new-landing-wrapper .section-three .row .col {
        text-align: center;
        flex-grow: 1;
        flex-basis: 100%;
        border-radius: 16px;
        box-shadow: inset 0 -3px 7px 3px #e97705;
        background-image: linear-gradient(225deg, #ffb972, #f27b01);
        position: relative; }
        .new-landing-wrapper .section-three .row .col > div {
          padding: 124px 18px 36px 18px;
          position: relative;
          border-radius: 16px 40% 16px 16px;
          box-shadow: 0 4px 8px 0 rgba(187, 184, 184, 0.5);
          background-image: linear-gradient(222deg, #ebebeb, #fcfcfc);
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 1; }
        .new-landing-wrapper .section-three .row .col:not(:last-child) {
          margin-right: 32px; }
        .new-landing-wrapper .section-three .row .col img {
          margin-bottom: 24px;
          max-height: 60px; }
        .new-landing-wrapper .section-three .row .col .text-one {
          font-size: 20px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #000000;
          margin-bottom: 4px; }
        .new-landing-wrapper .section-three .row .col .text-two {
          font-size: 16px;
          font-family: 'sukh-medium';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: rgba(0, 0, 0, 0.85);
          padding: 0 32px; }
  .new-landing-wrapper .section-four {
    background: url(images/onboarding/nl-four.png) no-repeat;
    background-size: cover;
    margin-bottom: 136px; }
    .new-landing-wrapper .section-four .container {
      display: flex;
      align-items: center;
      padding: 87px 0 87px 0px;
      justify-content: flex-end; }
      .new-landing-wrapper .section-four .container > div {
        max-width: 768px; }
      .new-landing-wrapper .section-four .container .text-one {
        font-size: 36px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.39;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 16px; }
      .new-landing-wrapper .section-four .container .text-two {
        font-size: 24px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff; }
      .new-landing-wrapper .section-four .container button {
        border-radius: 24px;
        border: solid 1px #f58b1e;
        background-color: #ff901d;
        font-size: 18px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        padding: 10px 40px;
        margin-top: 45px;
        outline: none; }
  .new-landing-wrapper .section-five .container {
    display: flex;
    align-items: flex-start; }
    .new-landing-wrapper .section-five .container > div:first-child {
      flex-grow: 1;
      margin-right: 65px; }
    .new-landing-wrapper .section-five .container > div:last-child {
      max-width: 470px;
      min-width: 470px; }
      .new-landing-wrapper .section-five .container > div:last-child img {
        max-width: 100%; }
  .new-landing-wrapper .section-five .text-one {
    font-size: 36px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 36px; }
  .new-landing-wrapper .section-five .text-two {
    font-size: 24px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 32px;
    display: flex;
    align-items: baseline; }
  .new-landing-wrapper .section-five .text-three {
    font-size: 36px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffaf00;
    margin-right: 24px; }
  .new-landing-wrapper .section-six, .new-landing-wrapper .video-section {
    background-color: #fffaf4; }
    .new-landing-wrapper .section-six .container, .new-landing-wrapper .video-section .container {
      padding: 80px 0; }
      .new-landing-wrapper .section-six .container .text-one, .new-landing-wrapper .video-section .container .text-one {
        font-size: 36px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.39;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
        margin-bottom: 56px; }
      .new-landing-wrapper .section-six .container .row, .new-landing-wrapper .video-section .container .row {
        display: flex;
        justify-content: center; }
        .new-landing-wrapper .section-six .container .row .col, .new-landing-wrapper .video-section .container .row .col {
          margin-right: 24px; }
          .new-landing-wrapper .section-six .container .row .col img, .new-landing-wrapper .video-section .container .row .col img {
            max-height: 174px;
            height: 100%; }
  .new-landing-wrapper .footer {
    background-color: #192383;
    padding: 32px 0; }
    .new-landing-wrapper .footer .container {
      display: flex; }
      .new-landing-wrapper .footer .container > div:last-child {
        flex-grow: 1;
        text-align: right; }
        .new-landing-wrapper .footer .container > div:last-child img {
          max-width: 32px; }
    .new-landing-wrapper .footer .text-one {
      font-size: 16px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.44px;
      color: #ffffff;
      margin-bottom: 8px; }
      .new-landing-wrapper .footer .text-one span {
        display: inline-block;
        margin-right: 4px;
        font-family: 'sukh-light'; }
    .new-landing-wrapper .footer .text-two {
      font-size: 16px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.44px;
      color: #ffffff; }

.landing-menu .language-toggle {
  display: flex; }
  .landing-menu .language-toggle span {
    flex-grow: 1;
    flex-basis: 100%;
    text-align: center;
    padding: 0 12px;
    cursor: pointer; }
    .landing-menu .language-toggle span:first-child {
      border-right: solid 1px #d3d2d2; }
    .landing-menu .language-toggle span.active {
      color: #ff901d; }

@media screen and (max-width: 1200px) {
  .new-landing-wrapper .header {
    padding: 20px 24px; }
  .new-landing-wrapper .container {
    max-width: 100%;
    padding: 0 24px; }
  .new-landing-wrapper .section-two {
    text-align: center; }
    .new-landing-wrapper .section-two .container {
      max-width: 100%;
      padding: 50px 24px 32px 24px; }
    .new-landing-wrapper .section-two .oic-custom-logo {
      margin-right: 0; }
      .new-landing-wrapper .section-two .oic-custom-logo .text {
        font-size: 14px; } }

@media screen and (max-width: 1024px) {
  .new-landing-wrapper {
    padding-top: 64px; }
    .new-landing-wrapper .header {
      padding: 20px 18px 18px; }
      .new-landing-wrapper .header > div:first-child img {
        margin-right: 24px;
        height: 24px; }
      .new-landing-wrapper .header > div:first-child span {
        font-size: 16px;
        font-family: 'sukh-regular';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5c5c5c;
        padding-left: 24px;
        border-left: solid 1px #e2e2e2; }
      .new-landing-wrapper .header > div:last-child p.btn {
        font-size: 14px;
        padding: 4px 16px;
        border: solid 1px #ff901d; }
    .new-landing-wrapper .container {
      max-width: 100%;
      padding: 0 24px; }
    .new-landing-wrapper .section-one {
      background: url(images/onboarding/nl-nine.png) no-repeat;
      height: 360px;
      background-size: cover;
      position: relative;
      align-items: flex-end;
      justify-content: center;
      margin-bottom: 40px; }
      .new-landing-wrapper .section-one .container {
        max-width: 100%;
        padding: 44px 24px;
        text-align: center; }
        .new-landing-wrapper .section-one .container .text-one {
          font-size: 20px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.83px;
          text-align: center;
          color: #ffffff; }
      .new-landing-wrapper .section-one .btn-wrapper {
        position: absolute;
        text-align: center;
        bottom: -75px;
        width: 100%;
        left: 0;
        right: 0; }
        .new-landing-wrapper .section-one .btn-wrapper button {
          position: relative; }
    .new-landing-wrapper .section-two {
      margin-bottom: 12px; }
      .new-landing-wrapper .section-two .container {
        display: block;
        text-align: center;
        border: 0; }
        .new-landing-wrapper .section-two .container > div:first-child {
          margin: 0; }
        .new-landing-wrapper .section-two .container > div.insurers-wrapper {
          max-width: 100%;
          justify-content: center; }
          .new-landing-wrapper .section-two .container > div.insurers-wrapper img {
            margin-bottom: 16px;
            max-width: 100%;
            height: 44px; }
            .new-landing-wrapper .section-two .container > div.insurers-wrapper img:not(:last-child) {
              margin-right: 16px; }
        .new-landing-wrapper .section-two .container .text-one {
          font-size: 24px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          margin-bottom: 8px; }
        .new-landing-wrapper .section-two .container .text-two {
          font-size: 16px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          margin-bottom: 8px; }
        .new-landing-wrapper .section-two .container .line {
          width: 252px;
          height: 2px;
          opacity: 0.3;
          border: solid 0.5px #979797;
          margin: 0 auto 24px auto; }
    .new-landing-wrapper .section-three {
      background-color: #fffaf4;
      padding: 56px 12px;
      margin: 0; }
      .new-landing-wrapper .section-three > .text-one {
        font-size: 26px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1b1b1b;
        margin-bottom: 72px;
        position: relative; }
        .new-landing-wrapper .section-three > .text-one:after {
          content: '';
          width: 300px;
          height: 1px;
          border: solid 1px #c2c2c2;
          position: absolute;
          bottom: -16px;
          left: 0;
          right: 0;
          margin: auto; }
      .new-landing-wrapper .section-three .row {
        display: block; }
        .new-landing-wrapper .section-three .row .col {
          background: transparent;
          border-radius: 0;
          padding: 0;
          box-shadow: none; }
          .new-landing-wrapper .section-three .row .col:not(:last-child) {
            margin: 0 0 80px 0; }
          .new-landing-wrapper .section-three .row .col .text-one {
            font-size: 18px;
            font-family: 'sukh-medium';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #1b1b1b;
            margin-bottom: 4px; }
          .new-landing-wrapper .section-three .row .col .text-two {
            font-size: 16px;
            font-family: 'sukh-regular';
            font-style: normal;
            font-stretch: normal;
            line-height: 1.63;
            letter-spacing: normal;
            text-align: center;
            color: #5e5e5e; }
      .new-landing-wrapper .section-three .container {
        padding: 0; }
    .new-landing-wrapper .section-four {
      background: url(images/onboarding/nl-eleven.png) no-repeat;
      background-size: cover;
      height: 560px;
      margin: 0; }
      .new-landing-wrapper .section-four .container {
        padding: 40px 24px;
        align-items: flex-end;
        justify-content: center;
        height: 100%; }
        .new-landing-wrapper .section-four .container .text-one {
          font-size: 18px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          margin-bottom: 8px; }
        .new-landing-wrapper .section-four .container .text-two {
          font-size: 16px;
          font-family: 'sukh-regular';
          font-style: normal;
          font-stretch: normal;
          line-height: 1.63;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff; }
        .new-landing-wrapper .section-four .container > div {
          max-width: 100%;
          text-align: center; }
    .new-landing-wrapper .section-five {
      background-color: #fffaf4;
      padding: 56px 24px; }
      .new-landing-wrapper .section-five .text-one {
        font-size: 26px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1b1b1b; }
      .new-landing-wrapper .section-five .text-two {
        font-size: 16px;
        font-family: 'sukh-regular';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #5e5e5e; }
      .new-landing-wrapper .section-five .text-three {
        font-size: 26px; }
      .new-landing-wrapper .section-five .container {
        padding: 0; }
        .new-landing-wrapper .section-five .container > div:last-child {
          min-width: 100%;
          max-width: 100%; }
    .new-landing-wrapper .section-six, .new-landing-wrapper .video-section {
      background: #fff;
      padding: 56px 24px; }
      .new-landing-wrapper .section-six .container, .new-landing-wrapper .video-section .container {
        padding: 0; }
        .new-landing-wrapper .section-six .container .text-one, .new-landing-wrapper .video-section .container .text-one {
          font-size: 26px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #1b1b1b;
          margin-bottom: 36px; }
        .new-landing-wrapper .section-six .container .row, .new-landing-wrapper .video-section .container .row {
          display: block; }
          .new-landing-wrapper .section-six .container .row .col, .new-landing-wrapper .video-section .container .row .col {
            margin: 0 0 16px 0; }
            .new-landing-wrapper .section-six .container .row .col img, .new-landing-wrapper .video-section .container .row .col img {
              max-width: 100%;
              height: auto;
              max-height: fit-content; }
    .new-landing-wrapper .footer {
      text-align: center; }
      .new-landing-wrapper .footer .container {
        display: block; }
        .new-landing-wrapper .footer .container > div:first-child {
          text-align: center;
          margin-bottom: 40px; }
          .new-landing-wrapper .footer .container > div:first-child img {
            max-width: 32px; }
        .new-landing-wrapper .footer .container > div:last-child {
          text-align: center; } }

.static-header {
  display: flex;
  align-items: center;
  padding: 20px 72px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;
  background-color: #ffffff; }
  .static-header > div {
    display: flex;
    align-items: center; }
    .static-header > div:first-child {
      flex-grow: 1; }
      .static-header > div:first-child img {
        margin-right: 24px;
        height: 32px;
        cursor: pointer; }
      .static-header > div:first-child span,
      .static-header > div:first-child a {
        font-size: 16px;
        font-family: 'sukh-regular';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5c5c5c;
        padding: 0 24px;
        border-left: solid 1px #e2e2e2; }
      .static-header > div:first-child a {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5c5c5c;
        padding-left: 48px;
        background: url(images/phone.svg) no-repeat;
        background-position: 24px center; }
    .static-header > div .language-toggle {
      display: flex; }
      .static-header > div .language-toggle span {
        flex-grow: 1;
        flex-basis: 100%;
        text-align: center;
        padding: 0 12px;
        cursor: pointer; }
        .static-header > div .language-toggle span:first-child {
          border-right: solid 1px #d3d2d2; }
        .static-header > div .language-toggle span.active {
          color: #ff901d; }
    .static-header > div:last-child p {
      font-size: 16px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b1b1b;
      margin-right: 20px;
      cursor: pointer; }
      .static-header > div:last-child p.btn {
        border-radius: 20px;
        border: solid 2px #ff901d;
        background-color: #ffffff;
        font-size: 16px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1b1b1b;
        padding: 5px 20px; }
  .static-header .header-links-holder .header-link {
    padding-right: 25px;
    cursor: pointer;
    color: #5c5c5c; }

.main-wrapper .static-page {
  padding-top: 0;
  margin: -16px; }

.main-wrapper .static-header {
  display: none; }

.static-page button {
  cursor: pointer; }

.static-page .wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  position: relative; }

.static-page.how-it-works .section {
  padding: 64px 0; }

.static-page.how-it-works .section-one {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  position: relative;
  min-height: 300px; }
  .static-page.how-it-works .section-one > img {
    width: 100%; }
  .static-page.how-it-works .section-one .wrapper {
    position: absolute;
    max-width: 1160px;
    top: 140px;
    left: 0;
    right: 0; }
  .static-page.how-it-works .section-one .text-one {
    font-size: 36px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: #000000; }
  .static-page.how-it-works .section-one .play-video {
    display: flex;
    align-items: center;
    margin-top: 12px;
    cursor: pointer; }
    .static-page.how-it-works .section-one .play-video span {
      font-size: 22px;
      font-family: 'sukh-bold';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ff901d;
      margin-left: 12px; }
  .static-page.how-it-works .section-one button {
    margin-top: 12px;
    border-radius: 24px;
    border: solid 1px #f58b1e;
    background-color: #ff901d;
    font-size: 18px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding: 10px 40px;
    outline: none; }

.static-page.how-it-works .section-two .wrapper {
  max-width: 1140px; }

.static-page.how-it-works .section-two > .text-one {
  font-size: 36px;
  font-family: 'sukh-bold';
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-bottom: 56px; }

.static-page.how-it-works .section-two > .text-two {
  font-size: 18px;
  font-family: 'sukh-medium';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  margin: 56px 0 32px 0; }
  .static-page.how-it-works .section-two > .text-two span {
    font-family: 'sukh-bold'; }

.static-page.how-it-works .section-two .text-three {
  font-size: 28px;
  font-family: 'sukh-bold';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #192383; }

.static-page.how-it-works .section-two .row {
  display: flex;
  align-items: flex-end; }
  .static-page.how-it-works .section-two .row .col {
    text-align: center;
    flex-grow: 1;
    flex-basis: 100%;
    border-radius: 16px;
    box-shadow: inset 0 -3px 7px 3px #e97705;
    background-image: linear-gradient(225deg, #ffb972, #f27b01);
    position: relative; }
    .static-page.how-it-works .section-two .row .col > div {
      padding: 96px 18px 36px 18px;
      position: relative;
      border-radius: 16px 40% 16px 16px;
      box-shadow: 0 4px 8px 0 rgba(187, 184, 184, 0.5);
      background-image: linear-gradient(222deg, #ebebeb, #fcfcfc);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1; }
    .static-page.how-it-works .section-two .row .col:not(:last-child) {
      margin-right: 32px; }
    .static-page.how-it-works .section-two .row .col img {
      margin-bottom: 24px;
      max-height: 60px; }
    .static-page.how-it-works .section-two .row .col .text-one {
      font-size: 20px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      margin-bottom: 4px; }
    .static-page.how-it-works .section-two .row .col .text-two {
      font-size: 16px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      padding: 0; }
    .static-page.how-it-works .section-two .row .col .text-three {
      font-size: 74px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      color: #192383;
      padding: 0;
      margin-bottom: 40px;
      line-height: 1; }
      .static-page.how-it-works .section-two .row .col .text-three span {
        font-size: 36px; }

.static-page.how-it-works .section-three {
  background: url(images/latest-landing/background-image-one.png) no-repeat;
  background-size: cover;
  background-position: 50%; }
  .static-page.how-it-works .section-three .text-one {
    font-size: 36px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    max-width: 610px;
    margin: 0 auto 72px auto; }
  .static-page.how-it-works .section-three .row {
    display: flex; }
    .static-page.how-it-works .section-three .row .col {
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center; }
      .static-page.how-it-works .section-three .row .col img {
        max-width: 784px; }

.static-page.how-it-works .section-four > .text-one {
  font-size: 36px;
  font-family: 'sukh-bold';
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  max-width: 610px;
  margin: 0 auto 56px auto; }

.static-page.how-it-works .section-four .row {
  display: flex;
  align-items: center; }
  .static-page.how-it-works .section-four .row img {
    max-width: 1083px; }

.static-page.how-it-works .section-five {
  background: url(images/latest-landing/background-image-one.png) no-repeat;
  background-size: cover;
  background-position: 50%; }
  .static-page.how-it-works .section-five .row {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .static-page.how-it-works .section-five .row img {
      max-width: 1065px; }

.static-page.how-it-works .section-six > .text-one {
  font-size: 36px;
  font-family: 'sukh-bold';
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  max-width: 610px;
  margin: 0 auto 38px auto; }

.static-page.how-it-works .section-six .wrapper {
  max-width: 1120px; }

.static-page.how-it-works .section-six .row {
  display: flex;
  align-items: center; }
  .static-page.how-it-works .section-six .row img {
    max-width: 100%; }

.static-page.how-it-works .section-seven {
  background: url(images/latest-landing/background-image-one.png) no-repeat;
  background-size: cover;
  background-position: 50%; }
  .static-page.how-it-works .section-seven > .text-one {
    font-size: 18px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 76px; }
  .static-page.how-it-works .section-seven .row {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .static-page.how-it-works .section-seven .row img {
      max-width: 1164px; }

.static-page.how-it-works .section-eight > .text-one {
  font-size: 18px;
  font-family: 'sukh-bold';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 76px; }

.static-page.how-it-works .section-eight .wrapper {
  max-width: 1120px; }

.static-page.how-it-works .section-eight .row {
  display: flex;
  align-items: center; }
  .static-page.how-it-works .section-eight .row img {
    max-width: 100%; }

.static-page.how-it-works .section-nine {
  background: url(images/latest-landing/background-image-one.png) no-repeat;
  background-size: cover;
  background-position: 50%; }
  .static-page.how-it-works .section-nine > .text-one {
    font-size: 18px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 76px; }
  .static-page.how-it-works .section-nine .row {
    display: flex;
    justify-content: center;
    align-items: center; }
    .static-page.how-it-works .section-nine .row img {
      max-width: 100%; }

.static-page.how-it-works .section-ten .row {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .static-page.how-it-works .section-ten .row img {
    max-width: 1251px; }

.static-page.how-it-works .section-eleven {
  background: url(images/latest-landing/background-image-one.png) no-repeat;
  background-size: cover;
  background-position: 50%; }
  .static-page.how-it-works .section-eleven > .text-one {
    font-size: 18px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 64px; }
  .static-page.how-it-works .section-eleven .row {
    display: flex;
    justify-content: center;
    align-items: center; }
    .static-page.how-it-works .section-eleven .row img {
      max-width: 1309px; }

.static-page.how-it-works .section-twelve > .text-one,
.static-page.how-it-works .section-fourteen-sixteen > .text-one {
  font-size: 18px;
  font-family: 'sukh-bold';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 4px; }

.static-page.how-it-works .section-twelve .wrapper,
.static-page.how-it-works .section-fourteen-sixteen .wrapper {
  max-width: 1124px; }

.static-page.how-it-works .section-twelve .row,
.static-page.how-it-works .section-fourteen-sixteen .row {
  display: flex;
  justify-content: center;
  align-items: center; }
  .static-page.how-it-works .section-twelve .row img,
  .static-page.how-it-works .section-fourteen-sixteen .row img {
    max-width: 100%; }

.static-page.how-it-works .section-fourteen-sixteen > .text-one {
  padding-top: 56px; }

.static-page.how-it-works .section-thirteen,
.static-page.how-it-works .section-final {
  background: url(images/latest-landing/background-image-one.png) no-repeat;
  background-size: cover;
  background-position: 50%; }
  .static-page.how-it-works .section-thirteen > .text-one,
  .static-page.how-it-works .section-final > .text-one {
    font-size: 18px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 4px; }
  .static-page.how-it-works .section-thirteen .wrapper,
  .static-page.how-it-works .section-final .wrapper {
    max-width: 1124px; }
  .static-page.how-it-works .section-thirteen .row,
  .static-page.how-it-works .section-final .row {
    display: flex;
    justify-content: center;
    align-items: center; }
    .static-page.how-it-works .section-thirteen .row img,
    .static-page.how-it-works .section-final .row img {
      max-width: 100%; }

.static-page.how-it-works .section-final {
  padding-bottom: 20px; }

.static-page.how-it-works .section-bottom {
  background: url(images/how-it-works/img-9.png) no-repeat;
  background-size: cover;
  text-align: center; }
  .static-page.how-it-works .section-bottom > .text-one {
    font-size: 36px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 24px; }
  .static-page.how-it-works .section-bottom .btn {
    border-radius: 27.5px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 12px 18px;
    font-size: 18px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block; }
  .static-page.how-it-works .section-bottom .wrapper {
    max-width: 680px; }
  .static-page.how-it-works .section-bottom .row {
    display: flex;
    margin-top: 80px;
    align-items: center; }
    .static-page.how-it-works .section-bottom .row .col {
      text-align: center;
      flex-grow: 1;
      flex-basis: 100%; }
      .static-page.how-it-works .section-bottom .row .col:first-child {
        position: relative; }
        .static-page.how-it-works .section-bottom .row .col:first-child:after {
          content: '';
          position: absolute;
          width: 1px;
          height: 70px;
          border: solid 1px #ffffff;
          top: 20px;
          right: 0; }
      .static-page.how-it-works .section-bottom .row .col img {
        max-width: 112px; }
      .static-page.how-it-works .section-bottom .row .col p {
        font-size: 18px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin-top: 16px; }
        .static-page.how-it-works .section-bottom .row .col p a {
          color: inherit; }

.static-page.how-it-works.mobile .coach-mark {
  position: absolute; }
  .static-page.how-it-works.mobile .coach-mark.one {
    right: 0;
    top: 0; }
  .static-page.how-it-works.mobile .coach-mark.two {
    right: 0;
    top: 172px; }
  .static-page.how-it-works.mobile .coach-mark.three {
    right: 0;
    bottom: 24px; }
  .static-page.how-it-works.mobile .coach-mark.four {
    text-align: center;
    top: calc(50% - 20px);
    right: 0; }
  .static-page.how-it-works.mobile .coach-mark.five {
    text-align: center;
    top: 74px;
    right: 0; }
  .static-page.how-it-works.mobile .coach-mark.six {
    text-align: center;
    bottom: -16px;
    left: 0; }
  .static-page.how-it-works.mobile .coach-mark.seven {
    text-align: center;
    bottom: 8px;
    right: 0; }
  .static-page.how-it-works.mobile .coach-mark.eight {
    text-align: center;
    bottom: 120px;
    left: 24px; }
  .static-page.how-it-works.mobile .coach-mark.nine {
    text-align: center;
    bottom: 8px;
    right: 0; }
  .static-page.how-it-works.mobile .coach-mark.ten {
    text-align: center;
    top: 76px;
    right: 0px; }
  .static-page.how-it-works.mobile .coach-mark.eleven {
    text-align: center;
    bottom: 25px;
    right: 0; }
  .static-page.how-it-works.mobile .coach-mark.twelve {
    text-align: center;
    bottom: -108px;
    right: 0; }
  .static-page.how-it-works.mobile .coach-mark.thirteen {
    text-align: center;
    top: -24px;
    right: 50px; }
  .static-page.how-it-works.mobile .coach-mark.fourteen {
    text-align: center;
    top: 222px;
    left: 162px; }
  .static-page.how-it-works.mobile .coach-mark.fifteen {
    text-align: center;
    top: 316px;
    left: 115px; }
  .static-page.how-it-works.mobile .coach-mark.sixteen {
    text-align: center;
    bottom: -24px;
    right: 0; }
  .static-page.how-it-works.mobile .coach-mark.flat-one {
    top: 68px;
    right: 0; }
  .static-page.how-it-works.mobile .coach-mark.flat-two {
    top: 0;
    right: 0; }
  .static-page.how-it-works.mobile .coach-mark.flat-three {
    top: 12px;
    right: 0; }
  .static-page.how-it-works.mobile .coach-mark.flat-four {
    top: 66%;
    right: 20%; }
  .static-page.how-it-works.mobile .coach-mark.flat-thirteen {
    bottom: 300px;
    left: 77px; }
  .static-page.how-it-works.mobile .coach-mark.flat-seventeen {
    bottom: 300px;
    left: 77px; }
  .static-page.how-it-works.mobile .coach-mark.flat-six {
    bottom: -60px;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 100%; }

.static-page.how-it-works.mobile .section-one > .text-one,
.static-page.how-it-works.mobile .section-two > .text-one,
.static-page.how-it-works.mobile .section-three > .text-one,
.static-page.how-it-works.mobile .section-four > .text-one,
.static-page.how-it-works.mobile .section-five > .text-one,
.static-page.how-it-works.mobile .section-six > .text-one,
.static-page.how-it-works.mobile .section-seven > .text-one,
.static-page.how-it-works.mobile .section-eight > .text-one,
.static-page.how-it-works.mobile .section-ten-eleven > .text-one,
.static-page.how-it-works.mobile .section-twelve > .text-one,
.static-page.how-it-works.mobile .section-eleven > .text-one,
.static-page.how-it-works.mobile .section-twelve > .text-one,
.static-page.how-it-works.mobile .section-thirteen > .text-one {
  font-size: 24px !important; }

.static-page.how-it-works.mobile .section-three > .text-one,
.static-page.how-it-works.mobile .section-four > .text-one,
.static-page.how-it-works.mobile .section-six > .text-one,
.static-page.how-it-works.mobile .section-eight > .text-one {
  font-size: 20px !important;
  margin-bottom: 24px; }

.static-page.how-it-works.mobile .section {
  padding: 40px 24px; }

.static-page.how-it-works.mobile .section-one {
  background: url(images/how-it-works/mobile/img-1.png) no-repeat;
  height: 400px;
  background-size: cover;
  align-items: flex-start;
  padding: 16px 18px;
  position: relative; }
  .static-page.how-it-works.mobile .section-one .wrapper {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .static-page.how-it-works.mobile .section-one .play-video {
    display: inline-flex;
    align-items: center;
    margin-top: 12px;
    cursor: pointer;
    border-radius: 24px;
    border: solid 1px #f58b1e;
    background-color: #ff901d;
    padding: 8px 10px;
    position: absolute;
    bottom: 0;
    margin: auto; }
    .static-page.how-it-works.mobile .section-one .play-video img {
      max-width: 32px; }
    .static-page.how-it-works.mobile .section-one .play-video span {
      font-size: 18px;
      font-family: 'sukh-bold';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff; }
  .static-page.how-it-works.mobile .section-one .text-one {
    font-size: 18px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #ffffff;
    text-align: left;
    display: block;
    width: 100%; }

.static-page.how-it-works.mobile .section-two > .text-one {
  font-size: 24px; }

.static-page.how-it-works.mobile .section-two > .text-two {
  font-size: 16px;
  margin: 0 0 8px 0; }

.static-page.how-it-works.mobile .section-two .text-three {
  font-size: 24px; }

.static-page.how-it-works.mobile .section-two .row {
  display: block; }
  .static-page.how-it-works.mobile .section-two .row .col {
    width: 100%;
    max-width: 320px;
    margin: 0 0 32px 0 !important;
    max-width: 100%; }
    .static-page.how-it-works.mobile .section-two .row .col .text-three {
      line-height: 1; }
    .static-page.how-it-works.mobile .section-two .row .col > div {
      padding: 52px 18px 36px 18px; }

.static-page.how-it-works.mobile .section-seven > .text-one,
.static-page.how-it-works.mobile .section-eleven > .text-one {
  font-size: 16px !important;
  margin-top: 24px;
  font-family: 'sukh-regular'; }

.static-page.how-it-works.mobile .section-eight > .text-one {
  font-size: 20px !important;
  margin: 0 0 24px 0; }

.static-page.how-it-works.mobile .section-ten-eleven > .text-one,
.static-page.how-it-works.mobile .section-thirteen > .text-one,
.static-page.how-it-works.mobile .section-fourteen-sixteen > .text-one {
  font-size: 20px !important;
  margin: 0 0 24px 0; }

.static-page.how-it-works.mobile .section-twelve,
.static-page.how-it-works.mobile .section-thirteen {
  padding-bottom: 80px; }
  .static-page.how-it-works.mobile .section-twelve > .text-one,
  .static-page.how-it-works.mobile .section-thirteen > .text-one {
    display: none; }

.static-page.how-it-works.mobile .section-twelve {
  padding-bottom: 120px; }

.static-page.how-it-works.mobile .section-final {
  padding-bottom: 80px; }

.static-page.how-it-works.mobile .section-three,
.static-page.how-it-works.mobile .section-five,
.static-page.how-it-works.mobile .section-seven,
.static-page.how-it-works.mobile .section-nine,
.static-page.how-it-works.mobile .section-eleven,
.static-page.how-it-works.mobile .section-thirteen {
  background: url(images/how-it-works/mobile/background-image-one.png) no-repeat;
  background-size: cover; }

.static-page.how-it-works.mobile .section-last {
  background: url(images/how-it-works/mobile/img-12.png) no-repeat;
  background-size: cover;
  text-align: center; }
  .static-page.how-it-works.mobile .section-last .text-one {
    font-size: 24px; }
  .static-page.how-it-works.mobile .section-last .btn {
    font-size: 12px;
    padding: 3px 9px; }
  .static-page.how-it-works.mobile .section-last .row {
    margin-top: 40px;
    display: block; }
    .static-page.how-it-works.mobile .section-last .row .col {
      margin-bottom: 40px; }
      .static-page.how-it-works.mobile .section-last .row .col p {
        margin-top: 8px;
        font-size: 16px; }
      .static-page.how-it-works.mobile .section-last .row .col img {
        width: 112px; }
      .static-page.how-it-works.mobile .section-last .row .col:first-child:after {
        display: none; }

.static-page.how-it-works.mobile .section-three .row img,
.static-page.how-it-works.mobile .section-four .row img,
.static-page.how-it-works.mobile .section-five .row img,
.static-page.how-it-works.mobile .section-seven .row img,
.static-page.how-it-works.mobile .section-ten-eleven .row img,
.static-page.how-it-works.mobile .section-twelve .row img,
.static-page.how-it-works.mobile .section-eleven .row img {
  max-width: 100%;
  width: 100%; }

.static-page.how-it-works.mobile .section-three .row .col img,
.static-page.how-it-works.mobile .section-four .row .col img,
.static-page.how-it-works.mobile .section-five .row .col img,
.static-page.how-it-works.mobile .section-seven .row .col img,
.static-page.how-it-works.mobile .section-ten-eleven .row .col img,
.static-page.how-it-works.mobile .section-twelve .row .col img,
.static-page.how-it-works.mobile .section-eleven .row .col img {
  max-width: 100%;
  width: 100%; }

.static-page.how-it-works.mobile .section-ten-eleven .row img {
  max-width: 300px; }

.static-page.how-it-works.mobile .section-bottom .text-one {
  font-size: 24px;
  margin-bottom: 16px; }

.static-page.how-it-works.mobile .section-bottom .btn {
  font-size: 12px;
  padding: 2px 12px; }

.static-page.how-it-works.mobile .section-bottom .wrapper {
  margin-top: 40px;
  flex-direction: column; }
  .static-page.how-it-works.mobile .section-bottom .wrapper.row .col p {
    font-size: 16px; }
  .static-page.how-it-works.mobile .section-bottom .wrapper.row .col:first-child {
    margin-bottom: 40px; }
    .static-page.how-it-works.mobile .section-bottom .wrapper.row .col:first-child::after {
      display: none; }

.static-footer {
  background-color: #192383;
  padding: 32px 0;
  padding: 32px 72px;
  width: 100%;
  overflow: hidden; }
  .static-footer .wrapper {
    display: flex; }
    .static-footer .wrapper > div:last-child {
      flex-grow: 1;
      text-align: right; }
      .static-footer .wrapper > div:last-child img {
        max-width: 32px; }
  .static-footer .text-one,
  .static-footer .find-more-header {
    font-size: 16px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.44px;
    color: #ffffff; }
    .static-footer .text-one span,
    .static-footer .find-more-header span {
      display: inline-block;
      margin-right: 4px;
      font-family: 'sukh-light'; }
  .static-footer .find-more-header {
    margin-bottom: 10px; }
  .static-footer .text-two,
  .static-footer .licence-info {
    font-size: 14px;
    font-family: 'sukh-regular';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.44px;
    color: #ffffff; }
  .static-footer .licence-info {
    font-size: 16px;
    margin-top: 12px; }
  .static-footer .find-more-links {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0.39px;
    line-height: 2;
    cursor: pointer; }
  .static-footer .fairdee-copyright {
    margin-top: 20px; }

.landing-menu .language-toggle {
  display: flex; }
  .landing-menu .language-toggle span {
    flex-grow: 1;
    flex-basis: 100%;
    text-align: center;
    padding: 0 12px;
    cursor: pointer; }
    .landing-menu .language-toggle span:first-child {
      border-right: solid 1px #d3d2d2; }
    .landing-menu .language-toggle span.active {
      color: #ff901d; }

.landing-menu .header-links-holder {
  text-align: center;
  width: 100%; }
  .landing-menu .header-links-holder .header-link {
    cursor: pointer;
    color: #5c5c5c; }

.coach-mark {
  display: flex;
  flex-direction: column;
  max-width: 220px; }
  .coach-mark.top-right {
    align-items: flex-end; }
  .coach-mark.top-center {
    align-items: center; }
  .coach-mark.bottom-center {
    align-items: center; }
    .coach-mark.bottom-center .number {
      order: 2;
      margin: 0; }
    .coach-mark.bottom-center .text {
      order: 1;
      margin-bottom: 4px; }
  .coach-mark.inline {
    flex-direction: row;
    max-width: 250px;
    align-items: center; }
    .coach-mark.inline .number {
      margin-right: 8px;
      margin-bottom: 0; }
  .coach-mark .number {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #00c7ff;
    padding: 2px;
    font-size: 16px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    line-height: normal; }
  .coach-mark .text {
    font-size: 14px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    background: #00c7ff;
    border-radius: 24px;
    padding: 2px 10px;
    display: inline-block;
    line-height: normal; }

.logged-in .static-pages {
  padding-top: 0;
  overflow-x: hidden; }

.logged-in .static-footer {
  overflow-x: hidden; }

.logged-in.homepage .main-wrapper {
  background-color: #fff !important; }

.logged-in.homepage .landing-header {
  position: fixed; }

.static-insurance-layout .banner {
  background-image: linear-gradient(to bottom, #fb8440, #f58b1e);
  padding: 70px 0 140px 0; }
  .static-insurance-layout .banner div {
    max-width: 1280px;
    margin: 0 auto; }
  .static-insurance-layout .banner .title-text {
    -webkit-text-stroke: 2px #ffffff;
    font-size: 64px;
    font-family: 'sukh-bold';
    font-stretch: normal;
    font-style: normal;
    line-height: 0.78;
    letter-spacing: 0.89px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 68px; }
  .static-insurance-layout .banner .header-wrap {
    display: flex;
    align-items: center; }
    .static-insurance-layout .banner .header-wrap .left-content {
      flex-grow: 1; }
      .static-insurance-layout .banner .header-wrap .left-content .label {
        font-size: 30px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #262626;
        padding: 8px 32px;
        border-radius: 27.5px;
        background-color: #ffffff;
        display: inline-block; }
    .static-insurance-layout .banner .header-wrap .social-icons {
      display: flex;
      align-items: center;
      border-radius: 27.5px;
      background-color: #ffffff;
      padding: 4px 32px; }
      .static-insurance-layout .banner .header-wrap .social-icons a img {
        width: 48px;
        height: 48px; }
      .static-insurance-layout .banner .header-wrap .social-icons span {
        width: 4px;
        background: #262626;
        height: 40px;
        margin: 0 10px; }

.static-insurance-layout .img-wrapper {
  max-width: 1280px;
  margin: -100px auto auto;
  position: relative;
  z-index: 1;
  border-radius: 20px;
  background-color: #1e026b;
  text-align: center; }
  .static-insurance-layout .img-wrapper img {
    max-width: 840px;
    width: 100%;
    border-radius: 20px; }

.static-insurance-layout .content-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding: 48px 0; }
  .static-insurance-layout .content-wrapper .title-text {
    font-size: 30px;
    font-family: 'sukh-bold';
    line-height: 1.33;
    color: #262626;
    margin-bottom: 10px; }
  .static-insurance-layout .content-wrapper .text {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    margin-bottom: 32px;
    color: #262626; }
  .static-insurance-layout .content-wrapper .img-wrap {
    margin-bottom: 20px;
    max-width: 768px; }
    .static-insurance-layout .content-wrapper .img-wrap img {
      max-width: 100%; }
  .static-insurance-layout .content-wrapper .list .text {
    margin-bottom: 40px;
    position: relative;
    padding-left: 32px;
    position: relative; }
    .static-insurance-layout .content-wrapper .list .text::before {
      content: '';
      position: absolute;
      left: 0;
      top: 12px;
      width: 10px;
      height: 10px;
      background-color: #1f0374;
      border-radius: 100%; }

@media screen and (max-width: 1200px) {
  .static-header {
    padding: 20px 24px; }
  .static-footer {
    padding: 32px 24px; }
  .static-page .wrapper {
    max-width: 100%;
    width: 100%;
    padding: 0 24px; } }

@media screen and (max-width: 1024px) {
  .static-header {
    padding: 20px 18px 18px; }
    .static-header > div:first-child img {
      margin-right: 12px;
      height: 20px; }
    .static-header > div:first-child span,
    .static-header > div:first-child a {
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      padding-left: 12px;
      border-left: solid 1px #e2e2e2; }
    .static-header > div:first-child a {
      padding-left: 24px;
      padding-right: 0;
      background-position: 8px center;
      background-size: 12px; }
    .static-header > div:last-child p.btn {
      font-size: 14px;
      padding: 4px 16px;
      border: solid 1px #ff901d; }
  .static-page {
    padding-top: 0; }
  .static-footer {
    padding-top: 80px;
    text-align: center; }
    .static-footer .wrapper {
      display: block; }
      .static-footer .wrapper > div:first-child {
        text-align: center;
        margin-bottom: 40px; }
        .static-footer .wrapper > div:first-child img {
          max-width: 32px; }
      .static-footer .wrapper > div:last-child {
        text-align: center; }
    .static-footer .connect-icon-holder {
      margin-top: 30px; }
      .static-footer .connect-icon-holder a {
        padding: 0 10px; } }

@media screen and (max-width: 768px) {
  .logged-in .static-pages {
    margin: 0;
    padding: 0; }
  .main-wrapper .static-page {
    padding-top: 0;
    margin: 0; }
  .main-wrapper .static-header {
    display: none; }
  .documents-required {
    color: white;
    border-radius: 19px;
    border: solid 1px #979797;
    background-color: #192383;
    padding: 22px;
    font-family: sukh-bold;
    position: absolute;
    bottom: -34px; }
  .static-page .wrapper {
    padding: 0; }
  .static-page.how-it-works .coach-mark {
    position: absolute; }
    .static-page.how-it-works .coach-mark.one {
      right: 0;
      top: 0; }
    .static-page.how-it-works .coach-mark.two {
      right: 0;
      top: 172px; }
    .static-page.how-it-works .coach-mark.three {
      right: 0;
      bottom: 24px; }
    .static-page.how-it-works .coach-mark.four {
      text-align: center;
      top: calc(50% - 20px);
      right: 0; }
    .static-page.how-it-works .coach-mark.five {
      text-align: center;
      top: 74px;
      right: 0; }
    .static-page.how-it-works .coach-mark.six {
      text-align: center;
      bottom: -16px;
      left: 0; }
    .static-page.how-it-works .coach-mark.seven {
      text-align: center;
      bottom: 8px;
      right: 0; }
    .static-page.how-it-works .coach-mark.eight {
      text-align: center;
      bottom: 120px;
      left: 24px; }
    .static-page.how-it-works .coach-mark.nine {
      text-align: center;
      bottom: 8px;
      right: 0; }
    .static-page.how-it-works .coach-mark.ten {
      text-align: center;
      top: 76px;
      right: 0px; }
    .static-page.how-it-works .coach-mark.eleven {
      text-align: center;
      bottom: 25px;
      right: 0; }
    .static-page.how-it-works .coach-mark.twelve {
      text-align: center;
      bottom: -108px;
      right: 0; }
    .static-page.how-it-works .coach-mark.thirteen {
      text-align: center;
      top: -24px;
      right: 50px; }
    .static-page.how-it-works .coach-mark.fourteen {
      text-align: center;
      top: 222px;
      left: 162px; }
    .static-page.how-it-works .coach-mark.fifteen {
      text-align: center;
      top: 316px;
      left: 115px; }
    .static-page.how-it-works .coach-mark.sixteen {
      text-align: center;
      bottom: -24px;
      right: 0; }
    .static-page.how-it-works .coach-mark.flat-one {
      top: 68px;
      right: 0; }
    .static-page.how-it-works .coach-mark.flat-two {
      top: 0;
      right: 0; }
    .static-page.how-it-works .coach-mark.flat-three {
      top: 12px;
      right: 0; }
    .static-page.how-it-works .coach-mark.flat-four {
      top: 66%;
      right: 20%; }
    .static-page.how-it-works .coach-mark.flat-thirteen {
      bottom: 300px;
      left: 77px; }
    .static-page.how-it-works .coach-mark.flat-seventeen {
      bottom: 300px;
      left: 77px; }
    .static-page.how-it-works .coach-mark.flat-six {
      bottom: -60px;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 100%; }
  .static-page.how-it-works .section-one > .text-one,
  .static-page.how-it-works .section-two > .text-one,
  .static-page.how-it-works .section-three > .text-one,
  .static-page.how-it-works .section-four > .text-one,
  .static-page.how-it-works .section-five > .text-one,
  .static-page.how-it-works .section-six > .text-one,
  .static-page.how-it-works .section-seven > .text-one,
  .static-page.how-it-works .section-eight > .text-one,
  .static-page.how-it-works .section-ten-eleven > .text-one,
  .static-page.how-it-works .section-twelve > .text-one,
  .static-page.how-it-works .section-eleven > .text-one,
  .static-page.how-it-works .section-twelve > .text-one,
  .static-page.how-it-works .section-thirteen > .text-one {
    font-size: 24px !important; }
  .static-page.how-it-works .section-three > .text-one,
  .static-page.how-it-works .section-four > .text-one,
  .static-page.how-it-works .section-six > .text-one,
  .static-page.how-it-works .section-eight > .text-one {
    font-size: 20px !important;
    margin-bottom: 24px; }
  .static-page.how-it-works .section {
    padding: 40px 24px; }
  .static-page.how-it-works .section-one {
    background: url(images/how-it-works/mobile/img-1.png) no-repeat;
    height: 400px;
    background-size: cover;
    align-items: flex-start;
    padding: 16px 18px;
    position: relative; }
    .static-page.how-it-works .section-one .wrapper {
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center; }
    .static-page.how-it-works .section-one .play-video {
      display: inline-flex;
      align-items: center;
      margin-top: 12px;
      cursor: pointer;
      border-radius: 24px;
      border: solid 1px #f58b1e;
      background-color: #ff901d;
      padding: 8px 10px;
      position: absolute;
      bottom: 0;
      margin: auto; }
      .static-page.how-it-works .section-one .play-video img {
        max-width: 32px; }
      .static-page.how-it-works .section-one .play-video span {
        font-size: 18px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff; }
    .static-page.how-it-works .section-one .text-one {
      font-size: 18px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #ffffff;
      text-align: left;
      display: block;
      width: 100%; }
  .static-page.how-it-works .section-two > .text-one {
    font-size: 24px; }
  .static-page.how-it-works .section-two > .text-two {
    font-size: 16px;
    margin: 0 0 8px 0; }
  .static-page.how-it-works .section-two .text-three {
    font-size: 24px; }
  .static-page.how-it-works .section-two .row {
    display: block; }
    .static-page.how-it-works .section-two .row .col {
      width: 100%;
      max-width: 320px;
      margin: 0 0 32px 0 !important;
      max-width: 100%; }
      .static-page.how-it-works .section-two .row .col .text-three {
        line-height: 1; }
      .static-page.how-it-works .section-two .row .col > div {
        padding: 52px 18px 36px 18px; }
  .static-page.how-it-works .section-seven > .text-one,
  .static-page.how-it-works .section-eleven > .text-one {
    font-size: 16px !important;
    margin-top: 24px;
    font-family: 'sukh-regular'; }
  .static-page.how-it-works .section-eight > .text-one {
    font-size: 20px !important;
    margin: 0 0 24px 0; }
  .static-page.how-it-works .section-ten-eleven > .text-one,
  .static-page.how-it-works .section-thirteen > .text-one,
  .static-page.how-it-works .section-fourteen-sixteen > .text-one {
    font-size: 20px !important;
    margin: 0 0 24px 0; }
  .static-page.how-it-works .section-twelve,
  .static-page.how-it-works .section-thirteen {
    padding-bottom: 80px; }
    .static-page.how-it-works .section-twelve > .text-one,
    .static-page.how-it-works .section-thirteen > .text-one {
      display: none; }
  .static-page.how-it-works .section-twelve {
    padding-bottom: 120px; }
  .static-page.how-it-works .section-final {
    padding-bottom: 80px; }
  .static-page.how-it-works .section-three,
  .static-page.how-it-works .section-five,
  .static-page.how-it-works .section-seven,
  .static-page.how-it-works .section-nine,
  .static-page.how-it-works .section-eleven,
  .static-page.how-it-works .section-thirteen {
    background: url(images/how-it-works/mobile/background-image-one.png) no-repeat;
    background-size: cover; }
  .static-page.how-it-works .section-last {
    background: url(images/how-it-works/mobile/img-12.png) no-repeat;
    background-size: cover;
    text-align: center; }
    .static-page.how-it-works .section-last .text-one {
      font-size: 24px; }
    .static-page.how-it-works .section-last .btn {
      font-size: 12px;
      padding: 3px 9px; }
    .static-page.how-it-works .section-last .row {
      margin-top: 40px;
      display: block; }
      .static-page.how-it-works .section-last .row .col {
        margin-bottom: 40px; }
        .static-page.how-it-works .section-last .row .col p {
          margin-top: 8px;
          font-size: 16px; }
        .static-page.how-it-works .section-last .row .col img {
          width: 112px; }
        .static-page.how-it-works .section-last .row .col:first-child:after {
          display: none; }
  .static-page.how-it-works .section-three .row img,
  .static-page.how-it-works .section-four .row img,
  .static-page.how-it-works .section-five .row img,
  .static-page.how-it-works .section-seven .row img,
  .static-page.how-it-works .section-ten-eleven .row img,
  .static-page.how-it-works .section-twelve .row img,
  .static-page.how-it-works .section-eleven .row img {
    max-width: 100%;
    width: 100%; }
  .static-page.how-it-works .section-three .row .col img,
  .static-page.how-it-works .section-four .row .col img,
  .static-page.how-it-works .section-five .row .col img,
  .static-page.how-it-works .section-seven .row .col img,
  .static-page.how-it-works .section-ten-eleven .row .col img,
  .static-page.how-it-works .section-twelve .row .col img,
  .static-page.how-it-works .section-eleven .row .col img {
    max-width: 100%;
    width: 100%; }
  .static-page.how-it-works .section-ten-eleven .row img {
    max-width: 300px; }
  .static-page.how-it-works .section-bottom .text-one {
    font-size: 24px;
    margin-bottom: 16px; }
  .static-page.how-it-works .section-bottom .btn {
    font-size: 12px;
    padding: 2px 12px; }
  .static-page.how-it-works .section-bottom .wrapper {
    margin-top: 40px;
    flex-direction: column; }
    .static-page.how-it-works .section-bottom .wrapper.row .col p {
      font-size: 16px; }
    .static-page.how-it-works .section-bottom .wrapper.row .col:first-child {
      margin-bottom: 40px; }
      .static-page.how-it-works .section-bottom .wrapper.row .col:first-child::after {
        display: none; }
  .static-insurance-layout .content-wrapper {
    padding: 48px 0; }
    .static-insurance-layout .content-wrapper .title-text {
      font-size: 18px; }
    .static-insurance-layout .content-wrapper .text {
      font-size: 14px;
      margin-bottom: 20px; }
    .static-insurance-layout .content-wrapper .list .text {
      margin-bottom: 20px; }
      .static-insurance-layout .content-wrapper .list .text:before {
        left: 0;
        top: 6px;
        width: 8px;
        height: 8px; }
  .static-insurance-layout .banner {
    padding: 20px 16px 140px 16px; }
    .static-insurance-layout .banner .title-text {
      -webkit-text-stroke: 2px #ffffff;
      font-size: 24px;
      line-height: 2.08;
      letter-spacing: 0.33px;
      margin-bottom: 20px;
      font-family: 'sukh-medium'; }
    .static-insurance-layout .banner .header-wrap .social-icons {
      padding: 2px 16px; }
      .static-insurance-layout .banner .header-wrap .social-icons span {
        width: 2px;
        background: #262626;
        height: 20px;
        margin: 0 5px; }
      .static-insurance-layout .banner .header-wrap .social-icons a img {
        width: 22px;
        height: 22px; }
    .static-insurance-layout .banner .header-wrap .left-content .label {
      font-size: 10px;
      padding: 5px 10px; }
  .static-insurance-layout .content-section {
    padding: 0 16px; } }

.manage-staffs,
.tasks-for-staff {
  position: relative;
  padding: 20px; }
  .manage-staffs .paper,
  .tasks-for-staff .paper {
    position: absolute;
    background: #fff;
    padding: 0;
    width: 100%;
    height: 100%; }
    .manage-staffs .paper .action-wrap,
    .tasks-for-staff .paper .action-wrap {
      margin-top: 40px;
      text-align: center; }
      .manage-staffs .paper .action-wrap button:not(:last-child),
      .tasks-for-staff .paper .action-wrap button:not(:last-child) {
        margin-right: 16px; }
  .manage-staffs .full-width,
  .tasks-for-staff .full-width {
    width: 100%; }
  .manage-staffs .auto-complete,
  .tasks-for-staff .auto-complete {
    width: 100%;
    margin-top: 16px; }
    .manage-staffs .auto-complete:not(:last-child),
    .tasks-for-staff .auto-complete:not(:last-child) {
      margin: 16px 0 32px 0; }
    .manage-staffs .auto-complete.no-margin,
    .tasks-for-staff .auto-complete.no-margin {
      margin-bottom: 0; }
    .manage-staffs .auto-complete.error > div:before, .manage-staffs .auto-complete.error > div:after,
    .tasks-for-staff .auto-complete.error > div:before,
    .tasks-for-staff .auto-complete.error > div:after {
      border-bottom: 2px solid red; }
    .manage-staffs .auto-complete.error:hover > div:before, .manage-staffs .auto-complete.error:hover > div:after,
    .tasks-for-staff .auto-complete.error:hover > div:before,
    .tasks-for-staff .auto-complete.error:hover > div:after {
      border-bottom: 2px solid red; }
    .manage-staffs .auto-complete > div,
    .tasks-for-staff .auto-complete > div {
      width: 100%;
      position: relative;
      font-size: 1rem;
      font-family: "Lato";
      line-height: 1.1875em; }
      .manage-staffs .auto-complete > div:hover:before, .manage-staffs .auto-complete > div:hover:after, .manage-staffs .auto-complete > div:focus:before, .manage-staffs .auto-complete > div:focus:after,
      .tasks-for-staff .auto-complete > div:hover:before,
      .tasks-for-staff .auto-complete > div:hover:after,
      .tasks-for-staff .auto-complete > div:focus:before,
      .tasks-for-staff .auto-complete > div:focus:after {
        border-bottom: 2px solid rgba(0, 0, 0, 0.87); }
      .manage-staffs .auto-complete > div:before,
      .tasks-for-staff .auto-complete > div:before {
        left: 0;
        right: 0;
        bottom: 0;
        content: "\00a0";
        position: absolute;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        pointer-events: none; }
      .manage-staffs .auto-complete > div:after,
      .tasks-for-staff .auto-complete > div:after {
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        position: absolute;
        transform: scaleX(0);
        transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        border-bottom: 2px solid #123446;
        pointer-events: none; }
      .manage-staffs .auto-complete > div input,
      .tasks-for-staff .auto-complete > div input {
        border: 0;
        margin: 0;
        padding: 6px 0 7px;
        display: block;
        min-width: 0;
        flex-grow: 1;
        box-sizing: content-box;
        background: none;
        -webkit-tap-highlight-color: transparent;
        width: 100%;
        outline: none;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87); }
    .manage-staffs .auto-complete div input + div,
    .tasks-for-staff .auto-complete div input + div {
      margin-top: 2px;
      background: #fff !important;
      z-index: 1; }
      .manage-staffs .auto-complete div input + div div,
      .tasks-for-staff .auto-complete div input + div div {
        color: rgba(0, 0, 0, 0.87);
        overflow: hidden;
        font-size: 16px;
        box-sizing: content-box;
        font-weight: 400;
        line-height: 1.5em;
        font-family: "Lato";
        white-space: nowrap;
        padding: 12px;
        text-overflow: ellipsis; }
  .manage-staffs .action-wrap,
  .tasks-for-staff .action-wrap {
    text-align: right;
    margin-bottom: 24px; }
    .manage-staffs .action-wrap button:not(:last-child),
    .tasks-for-staff .action-wrap button:not(:last-child) {
      margin-right: 16px; }
  .manage-staffs .fab,
  .tasks-for-staff .fab {
    margin: 0px;
    top: auto;
    right: 20px;
    bottom: 60px;
    left: auto;
    position: fixed;
    z-index: 2; }
  .manage-staffs .section,
  .tasks-for-staff .section {
    margin-bottom: 40px; }
    .manage-staffs .section .title,
    .tasks-for-staff .section .title {
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #1b1b1b;
      margin: 0 0 20px 0;
      text-transform: capitalize; }
    .manage-staffs .section .staffs-wrap,
    .tasks-for-staff .section .staffs-wrap {
      display: flex;
      flex-wrap: wrap; }
      .manage-staffs .section .staffs-wrap .staff,
      .tasks-for-staff .section .staffs-wrap .staff {
        background: #fff;
        padding: 12px;
        position: relative;
        flex-basis: calc(25% - 18px);
        margin-bottom: 24px; }
        .manage-staffs .section .staffs-wrap .staff:not(:last-child),
        .tasks-for-staff .section .staffs-wrap .staff:not(:last-child) {
          margin-right: 24px; }
        .manage-staffs .section .staffs-wrap .staff.default,
        .tasks-for-staff .section .staffs-wrap .staff.default {
          background: #cdcdcd; }
        .manage-staffs .section .staffs-wrap .staff .text,
        .tasks-for-staff .section .staffs-wrap .staff .text {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #5e5e5e;
          padding-right: 60px; }
        .manage-staffs .section .staffs-wrap .staff .cancel,
        .tasks-for-staff .section .staffs-wrap .staff .cancel {
          position: absolute;
          right: 12px;
          cursor: pointer;
          top: calc(50% - 10px); }
        .manage-staffs .section .staffs-wrap .staff .set-default,
        .tasks-for-staff .section .staffs-wrap .staff .set-default {
          position: absolute;
          right: 40px;
          cursor: pointer;
          top: calc(50% - 10px); }

@media screen and (max-width: 768px) {
  .manage-staffs .section .staffs-wrap {
    display: block; }
    .manage-staffs .section .staffs-wrap .staff {
      display: block;
      width: 100%; } }

.quotation-share {
  max-width: 1160px;
  margin: 0 auto;
  padding-top: 60px; }
  .quotation-share .qs-header {
    box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
    background-color: #fff;
    width: 100%;
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;
    right: 0; }
    .quotation-share .qs-header .content-wrapper {
      max-width: 1160px;
      margin: 0 auto;
      min-height: 40px;
      display: flex;
      align-items: center; }
      .quotation-share .qs-header .content-wrapper span {
        font-size: 18px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        margin-right: 8px; }
      .quotation-share .qs-header .content-wrapper img {
        max-height: 16px; }
  .quotation-share .title-wrap {
    position: relative; }
    .quotation-share .title-wrap .text-one {
      font-size: 32px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383; }
    .quotation-share .title-wrap .actions-wrap {
      position: absolute;
      right: 0;
      top: 8px;
      display: flex;
      align-items: center; }
      .quotation-share .title-wrap .actions-wrap button {
        padding: 6px 20px;
        text-transform: none; }
        .quotation-share .title-wrap .actions-wrap button:not(:last-child) {
          margin-right: 8px; }
        .quotation-share .title-wrap .actions-wrap button.green-button {
          background-color: #06C655;
          color: #ffffff;
          border: 0.5px solid #06C655; }
        .quotation-share .title-wrap .actions-wrap button .img-text-wrap {
          display: flex;
          align-items: center; }
          .quotation-share .title-wrap .actions-wrap button .img-text-wrap .line-icon {
            height: 18px;
            width: 18px;
            margin-right: 4px; }
  .quotation-share > .section-one {
    border-radius: 4px;
    box-shadow: 0 2px 13px 1px rgba(199, 206, 225, 0.23);
    background-color: #ffffff;
    margin-top: 16px;
    display: flex;
    width: 100%;
    padding: 16px 36px;
    margin-bottom: 40px; }
    .quotation-share > .section-one .col {
      flex-grow: 1;
      flex-basis: 100%;
      position: relative; }
      .quotation-share > .section-one .col:first-child {
        padding-right: 36px;
        border-right: solid 1px #e0e0e0; }
        .quotation-share > .section-one .col:first-child .text-one {
          margin-bottom: 18px; }
        .quotation-share > .section-one .col:first-child .text-two {
          margin-bottom: 2px; }
        .quotation-share > .section-one .col:first-child .text-three {
          padding-left: 25px; }
        .quotation-share > .section-one .col:first-child .text-four {
          position: absolute;
          right: 36px;
          top: 0; }
      .quotation-share > .section-one .col:last-child {
        padding-left: 36px; }
        .quotation-share > .section-one .col:last-child .text-one {
          margin-bottom: 18px; }
        .quotation-share > .section-one .col:last-child .desc-wrap {
          display: flex; }
          .quotation-share > .section-one .col:last-child .desc-wrap > div {
            min-width: 80px;
            margin-right: 24px; }
    .quotation-share > .section-one .text-one {
      font-size: 12px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.2px;
      color: #4a4a4a; }
    .quotation-share > .section-one .text-two {
      font-size: 16px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #282c3f; }
      .quotation-share > .section-one .text-two span {
        width: 18px;
        height: 18px;
        display: inline-block;
        border: solid 0.5px #282c3f;
        border-radius: 100%;
        padding: 2px;
        vertical-align: middle;
        margin-right: 8px;
        font-size: 12px; }
      .quotation-share > .section-one .text-two img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .quotation-share > .section-one .text-three {
      font-size: 14px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #282c3f; }
    .quotation-share > .section-one .text-four {
      font-size: 14px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383;
      cursor: pointer;
      text-decoration: underline; }
    .quotation-share > .section-one .text-five {
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0c103d; }
    .quotation-share > .section-one .text-six {
      font-size: 12px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.85); }
  .quotation-share .shadow-wrap {
    box-shadow: 0 2px 13px 1px rgba(199, 206, 225, 0.23);
    background: linear-gradient(180deg, #ff8a00 0%, rgba(255, 255, 255, 0) 286.87%);
    border-radius: 8px;
    padding-bottom: 12px; }
    .quotation-share .shadow-wrap.no-bg {
      background: #fff; }
    .quotation-share .shadow-wrap .slick-slider {
      max-width: 100%;
      position: relative; }
      .quotation-share .shadow-wrap .slick-slider .slick-arrow {
        background: rgba(255, 255, 255, 0.6);
        height: 100%;
        width: 68px;
        top: 0;
        bottom: 0;
        border-radius: 0;
        transform: none;
        margin: auto;
        border: 0;
        z-index: 9; }
        .quotation-share .shadow-wrap .slick-slider .slick-arrow.slick-next {
          right: 0; }
          .quotation-share .shadow-wrap .slick-slider .slick-arrow.slick-next:before {
            content: '';
            width: 57px;
            height: 57px;
            background: #192383 url(images/icons/arrow-right.svg) no-repeat;
            border-radius: 100%;
            position: absolute;
            top: calc(50% - 28px);
            right: 6px;
            z-index: 10;
            opacity: 1;
            background-position: center center; }
        .quotation-share .shadow-wrap .slick-slider .slick-arrow.slick-prev {
          left: 0; }
          .quotation-share .shadow-wrap .slick-slider .slick-arrow.slick-prev:before {
            content: '';
            width: 57px;
            height: 57px;
            background: #192383 url(images/icons/arrow-right.svg) no-repeat;
            border-radius: 100%;
            position: absolute;
            top: calc(50% - 28px);
            left: 6px;
            z-index: 10;
            opacity: 1;
            background-position: center center;
            transform: rotate(180deg); }
        .quotation-share .shadow-wrap .slick-slider .slick-arrow.slick-disabled {
          display: none !important; }
    .quotation-share .shadow-wrap > .section-two {
      display: flex;
      margin: 15px 8px;
      background-color: #ffffff;
      padding: 8px 0; }
      .quotation-share .shadow-wrap > .section-two.with-margin {
        margin-top: 24px; }
        .quotation-share .shadow-wrap > .section-two.with-margin .content {
          padding-top: 24px; }
      .quotation-share .shadow-wrap > .section-two.without-carousel {
        position: relative; }
        .quotation-share .shadow-wrap > .section-two.without-carousel .col {
          position: relative; }
      .quotation-share .shadow-wrap > .section-two .cheapest-price-wrap {
        background-color: rgba(255, 100, 0, 0.3);
        border-radius: 12px 12px 0px 0px;
        padding: 4px;
        max-width: 100%;
        text-align: center;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%; }
        .quotation-share .shadow-wrap > .section-two .cheapest-price-wrap img {
          height: 20px;
          width: 20px;
          border-radius: 100%;
          margin-right: 8px; }
      .quotation-share .shadow-wrap > .section-two .empty-div {
        height: 28px; }
      .quotation-share .shadow-wrap > .section-two .col {
        flex-grow: 1;
        flex-basis: 100%;
        border-right: solid 1px #e0e0e0; }
        .quotation-share .shadow-wrap > .section-two .col .header-section-wrap {
          background-color: #e8f8fe;
          padding: 14px;
          display: flex;
          align-items: center; }
          .quotation-share .shadow-wrap > .section-two .col .header-section-wrap .img-wrap {
            max-width: 35px;
            min-width: 35px;
            margin-right: 20px; }
            .quotation-share .shadow-wrap > .section-two .col .header-section-wrap .img-wrap img {
              max-width: 100%; }
          .quotation-share .shadow-wrap > .section-two .col .header-section-wrap > .content {
            flex-grow: 1;
            position: relative;
            padding: 0; }
            .quotation-share .shadow-wrap > .section-two .col .header-section-wrap > .content .text-one {
              font-size: 12px;
              font-family: 'sukh-regular';
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #414141; }
            .quotation-share .shadow-wrap > .section-two .col .header-section-wrap > .content .text-two {
              font-family: 'sukh-bold';
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #4a4a4a; }
              .quotation-share .shadow-wrap > .section-two .col .header-section-wrap > .content .text-two span {
                font-size: 12px; }
            .quotation-share .shadow-wrap > .section-two .col .header-section-wrap > .content .text-three {
              max-width: fit-content;
              min-height: 37px;
              right: -14px;
              top: -14px;
              font-size: 8px;
              font-family: 'sukh-bold';
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #ffffff;
              border-radius: 100px 0 0 100px;
              background-color: #3b8fae;
              padding: 3px 8px; }
              .quotation-share .shadow-wrap > .section-two .col .header-section-wrap > .content .text-three.restriction-text {
                border-radius: 100px;
                font-size: 10px; }
              .quotation-share .shadow-wrap > .section-two .col .header-section-wrap > .content .text-three.empty-div {
                background: none; }
        .quotation-share .shadow-wrap > .section-two .col > .content {
          padding: 0 24px 16px 24px; }
          .quotation-share .shadow-wrap > .section-two .col > .content .section-one {
            display: flex; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-one > .content {
              flex-grow: 1;
              flex-basis: 100%;
              position: relative;
              height: 100px;
              overflow: hidden; }
              .quotation-share .shadow-wrap > .section-two .col > .content .section-one > .content:not(:last-child) {
                margin-right: 8px; }
              .quotation-share .shadow-wrap > .section-two .col > .content .section-one > .content:nth-child(2) {
                text-align: center; }
              .quotation-share .shadow-wrap > .section-two .col > .content .section-one > .content .text-one {
                font-size: 12px;
                font-family: 'sukh-regular';
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #9e9d9d; }
              .quotation-share .shadow-wrap > .section-two .col > .content .section-one > .content .text-two {
                font-size: 12px;
                font-family: 'sukh-regular';
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #4a4a4a; }
          .quotation-share .shadow-wrap > .section-two .col > .content .section-two {
            margin-top: 16px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-two .tags-wrapper {
              min-height: 50px;
              max-height: 50px;
              overflow: hidden; }
              .quotation-share .shadow-wrap > .section-two .col > .content .section-two .tags-wrapper.hide {
                display: none; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-two .tag {
              border-radius: 100px;
              background-color: #fef1e3;
              padding: 2px 6px;
              font-size: 10px;
              font-family: 'sukh-bold';
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #4a4a4a;
              margin: 0 2px 2px 0;
              display: inline-block;
              vertical-align: middle; }
          .quotation-share .shadow-wrap > .section-two .col > .content .section-three .button {
            border-radius: 2px;
            border: solid 1px #f58b1e;
            background-color: #f5942f;
            font-size: 14px;
            font-family: 'sukh-bold';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            padding: 7px;
            display: block;
            width: 100%;
            margin: 0 auto 20px auto; }
          .quotation-share .shadow-wrap > .section-two .col > .content .section-three .with-background {
            background-color: #f2f2f8;
            margin: 0 -24px;
            padding: 8px 24px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .with-background .key-value .key {
              font-size: 12px;
              font-family: 'sukh-bold';
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #192383; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .with-background .key-value .instalment-information-text {
              white-space: pre-wrap;
              overflow-wrap: break-word; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .with-background .key-value .value {
              font-size: 14px;
              font-family: 'sukh-bold';
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: right;
              color: #192383; }
          .quotation-share .shadow-wrap > .section-two .col > .content .section-three .instalment-split {
            background-color: #fdefe2;
            margin: 0 -24px;
            padding: 8px 24px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .instalment-split.two {
              min-height: 62px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .instalment-split.three {
              min-height: 85px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .instalment-split.four {
              min-height: 108px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .instalment-split.five {
              min-height: 132px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .instalment-split.six {
              min-height: 155px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .instalment-split.eight {
              min-height: 155px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .instalment-split.ten {
              min-height: 155px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .instalment-split.twelve {
              min-height: 155px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .instalment-split.thirteen {
              min-height: 178px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .instalment-split .key-value .key {
              font-size: 12px;
              font-family: 'sukh-regular';
              color: #4a4a4a;
              max-width: 100px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .instalment-split .key-value .value {
              font-size: 12px;
              font-family: 'sukh-regular';
              color: #4a4a4a; }
          .quotation-share .shadow-wrap > .section-two .col > .content .section-three .key-value {
            display: flex;
            margin-bottom: 4px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .key-value.with-border {
              border-bottom: solid 1px rgba(151, 151, 151, 0.2);
              padding-bottom: 6px; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .key-value p {
              font-size: 12px;
              font-family: 'sukh-regular';
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #4a4a4a;
              flex-grow: 1;
              flex-basis: 100%; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .key-value p.text-one {
              font-size: 12px;
              font-family: 'sukh-bold';
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #000000;
              flex-basis: auto; }
            .quotation-share .shadow-wrap > .section-two .col > .content .section-three .key-value .value {
              flex-grow: 1;
              text-align: right; }
    .quotation-share .shadow-wrap .row {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      font-family: 'sukh-medium';
      background-color: #fff; }
      .quotation-share .shadow-wrap .row .col {
        padding: 10px 15px;
        background-color: #ff8a00;
        color: #ffffff;
        cursor: pointer; }
        .quotation-share .shadow-wrap .row .col:first-child {
          border-radius: 8px 8px 0 0; }
        .quotation-share .shadow-wrap .row .col:last-child {
          border-radius: 8px 8px 0 0; }
        .quotation-share .shadow-wrap .row .col p {
          font-size: 12px;
          line-height: 16px; }
          .quotation-share .shadow-wrap .row .col p.bold {
            font-family: 'sukh-bold'; }
        .quotation-share .shadow-wrap .row .col.disable {
          background: #edecec;
          color: #000000; }
        .quotation-share .shadow-wrap .row .col .flex-wrap {
          display: flex;
          align-items: center;
          margin-bottom: 6px; }
        .quotation-share .shadow-wrap .row .col .recommended-wrap {
          background: #fffbed;
          border: 1px solid #f5e6e0;
          border-radius: 4px;
          padding: 3px 4px;
          display: flex;
          align-items: center;
          margin-left: 8px; }
          .quotation-share .shadow-wrap .row .col .recommended-wrap p {
            font-size: 11px;
            line-height: 16px;
            color: #ff6400;
            font-family: 'sukh-bold'; }
          .quotation-share .shadow-wrap .row .col .recommended-wrap img {
            margin-right: 2px; }
      .quotation-share .shadow-wrap .row > .col {
        flex-basis: 100%;
        flex-grow: 1; }
  .quotation-share .large-button {
    box-shadow: 0 -10px 19px 5px rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    width: 100%;
    padding: 16px;
    font-size: 20px;
    font-family: 'sukh-medium';
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f5942f;
    border: 0;
    cursor: pointer; }

@media screen and (max-width: 1024px) {
  .quotation-share {
    padding-top: 16px;
    background: #f0f0f5; }
    .quotation-share .instalment-breakup-btn {
      position: absolute;
      right: 12px;
      top: 12px;
      max-width: 120px;
      background-color: #1d2785;
      padding: 8px 4px;
      font-size: 11px;
      border: 0;
      border-radius: 4px;
      color: #fff; }
    .quotation-share.logged-out {
      padding-top: 48px; }
    .quotation-share .qs-header {
      padding: 0 12px; }
    .quotation-share .title-wrap .text-one {
      font-size: 18px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383;
      margin-bottom: 4px;
      text-transform: uppercase; }
    .quotation-share .text-one {
      font-size: 18px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383;
      margin-bottom: 4px;
      text-transform: uppercase; }
    .quotation-share > .text-two {
      font-size: 10px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #4a4a4a;
      text-transform: uppercase;
      padding: 0 16px;
      margin-bottom: 8px; }
    .quotation-share .action-wrap {
      width: 100%; }
      .quotation-share .action-wrap button {
        background-color: #192383;
        font-size: 14px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        border: 0;
        width: 100%;
        padding: 12px;
        outline: none; }
        .quotation-share .action-wrap button img {
          max-width: 16px;
          margin-right: 8px; }
        .quotation-share .action-wrap button.download {
          margin-top: 4px; }
    .quotation-share > .section-one {
      display: block;
      padding: 12px 16px;
      margin: 0;
      box-shadow: none; }
      .quotation-share > .section-one > .col:first-child {
        padding: 0;
        border: 0; }
      .quotation-share > .section-one > .col:last-child .desc-wrap {
        display: block; }
        .quotation-share > .section-one > .col:last-child .desc-wrap a {
          position: absolute;
          right: 0;
          top: calc(50% - 12px); }
        .quotation-share > .section-one > .col:last-child .desc-wrap > div {
          display: flex; }
          .quotation-share > .section-one > .col:last-child .desc-wrap > div p {
            flex-grow: 1;
            flex-basis: 100%; }
    .quotation-share > .section-two {
      display: block;
      margin-top: 0;
      margin-bottom: 50px; }
    .quotation-share .title-wrap {
      padding: 0 16px; }
      .quotation-share .title-wrap .text-one {
        margin: 0; }
      .quotation-share .title-wrap .text-two {
        font-size: 10px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #192383;
        text-transform: uppercase; }
      .quotation-share .title-wrap .text-three {
        font-size: 10px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #4a4a4a;
        margin-bottom: 4px;
        text-transform: uppercase; } }

.after-signup-carousel {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(77, 92, 116, 0.9);
  z-index: 1299;
  display: flex;
  align-items: center;
  justify-content: center; }
  .after-signup-carousel .content {
    width: 722px;
    height: 491px;
    background: #fff;
    border-radius: 12px;
    position: relative; }
    .after-signup-carousel .content .cancel {
      position: absolute;
      top: -24px;
      right: -24px;
      cursor: pointer; }
    .after-signup-carousel .content .bg {
      position: absolute;
      width: 100%;
      height: calc(100% + 2px);
      left: 0;
      right: 0;
      bottom: -1.5px;
      top: 0px;
      background: url(images/after-signup-carousel/background.png) no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      border-radius: 12px; }
  .after-signup-carousel .slick-dots {
    bottom: 18px; }
    .after-signup-carousel .slick-dots li.slick-active button:before {
      line-height: 1 !important; }
    .after-signup-carousel .slick-dots li button:before {
      line-height: 1 !important; }
  .after-signup-carousel .slick-arrow {
    background: transparent;
    border-radius: 4px;
    border: solid 1px rgba(255, 255, 255, 0.6);
    min-width: 100px;
    width: auto;
    padding: 8px 12px;
    top: auto;
    bottom: 18px;
    transform: none;
    font-size: 14px;
    font-family: 'sukh-bold';
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(255, 255, 255, 0.85);
    z-index: 9999; }
    .after-signup-carousel .slick-arrow.orange {
      background: #f58a1f url(images/right-arrow.svg) no-repeat;
      border: 0;
      background-position: calc(100% - 12px) center;
      padding: 8px 32px 8px 16px; }
      .after-signup-carousel .slick-arrow.orange:hover {
        background: #f58a1f; }
    .after-signup-carousel .slick-arrow.hidden {
      display: none; }
    .after-signup-carousel .slick-arrow:before, .after-signup-carousel .slick-arrow:after {
      display: none; }
    .after-signup-carousel .slick-arrow:hover {
      background: inherit; }
  .after-signup-carousel .slick-prev {
    left: 20px; }
  .after-signup-carousel .slick-next {
    right: 20px; }
  .after-signup-carousel .slick-slider {
    height: 100%; }
    .after-signup-carousel .slick-slider .slick-list {
      height: 100%; }
    .after-signup-carousel .slick-slider .slick-track {
      height: 100%; }
  .after-signup-carousel .slick-slide > div {
    height: 100%; }
  .after-signup-carousel .slick-slide .slide {
    text-align: center;
    height: 100%;
    position: relative;
    padding: 24px 20px 20px; }
    .after-signup-carousel .slick-slide .slide.two .bg {
      background: url(images/after-signup-carousel/background-two.png) no-repeat;
      background-size: 100% 100%;
      background-position: center center; }
    .after-signup-carousel .slick-slide .slide.three .bg {
      background: url(images/after-signup-carousel/background-three.png) no-repeat;
      background-size: 100% 100%;
      background-position: center center; }
    .after-signup-carousel .slick-slide .slide.three .title {
      margin-bottom: 20px; }
    .after-signup-carousel .slick-slide .slide.four .bg {
      background: url(images/after-signup-carousel/background-four.png) no-repeat;
      background-size: 100% 100%;
      background-position: center center; }
    .after-signup-carousel .slick-slide .slide.four .title {
      margin-bottom: 24px; }
    .after-signup-carousel .slick-slide .slide.four .flex-content .img-wrap {
      max-width: 338px;
      min-width: 338px; }
    .after-signup-carousel .slick-slide .slide.four .flex-content .content-wrap {
      margin-right: 0;
      margin-left: 12px; }
      .after-signup-carousel .slick-slide .slide.four .flex-content .content-wrap .margin-high {
        margin-bottom: 12px; }
    .after-signup-carousel .slick-slide .slide.four .desc-wrap {
      margin-top: 62px;
      position: relative;
      z-index: 1;
      text-align: left;
      padding: 0 34px; }
      .after-signup-carousel .slick-slide .slide.four .desc-wrap .title-text {
        font-size: 16px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        position: relative;
        text-align: left;
        margin-bottom: 4px; }
        .after-signup-carousel .slick-slide .slide.four .desc-wrap .title-text:before {
          content: '';
          position: absolute;
          left: 0;
          width: 13px;
          height: 3px;
          background-color: #ff901d;
          top: -12px; }
      .after-signup-carousel .slick-slide .slide.four .desc-wrap .text {
        font-size: 14px;
        font-family: 'sukh-medium';
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(255, 255, 255, 0.85); }
    .after-signup-carousel .slick-slide .slide .title {
      display: inline-block;
      margin-bottom: 8px;
      height: 44px;
      z-index: 1;
      position: relative; }
    .after-signup-carousel .slick-slide .slide .text-one {
      font-size: 14px;
      font-family: 'sukh-medium';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: rgba(0, 0, 0, 0.85); }
    .after-signup-carousel .slick-slide .slide button {
      border-radius: 14.5px;
      background-color: #f58a1f;
      font-size: 16px;
      font-family: 'sukh-bold';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: rgba(255, 255, 255, 0.85);
      padding: 6px 20px;
      border: 0;
      outline: none;
      margin: 4px 0 8px 0; }
    .after-signup-carousel .slick-slide .slide .img-wrapper {
      width: 410px;
      height: 252px;
      background: url(images/after-signup-carousel/img-four.png) no-repeat;
      background-size: cover;
      background-position: center center;
      display: flex;
      align-items: flex-end;
      margin: 8px auto 0 auto;
      position: relative;
      z-index: 1; }
      .after-signup-carousel .slick-slide .slide .img-wrapper .text-content {
        padding: 10px;
        width: 100%; }
        .after-signup-carousel .slick-slide .slide .img-wrapper .text-content .text {
          font-size: 14px;
          font-family: 'sukh-bold';
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: rgba(0, 0, 0, 0.85); }
    .after-signup-carousel .slick-slide .slide .middle-image {
      position: relative;
      z-index: 1; }
      .after-signup-carousel .slick-slide .slide .middle-image img {
        max-width: 493px;
        display: inline-block; }
    .after-signup-carousel .slick-slide .slide .logo-image {
      margin-top: 70px;
      position: relative;
      z-index: 1; }
      .after-signup-carousel .slick-slide .slide .logo-image img {
        max-width: 581px;
        display: inline-block; }
    .after-signup-carousel .slick-slide .slide .flex-content {
      display: flex;
      padding: 0 34px; }
      .after-signup-carousel .slick-slide .slide .flex-content > div {
        flex-grow: 1;
        flex-basis: 100%; }
      .after-signup-carousel .slick-slide .slide .flex-content .content-wrap {
        margin-right: 12px; }
        .after-signup-carousel .slick-slide .slide .flex-content .content-wrap .margin {
          margin-bottom: 4px; }
        .after-signup-carousel .slick-slide .slide .flex-content .content-wrap .text {
          font-size: 14px;
          font-family: 'sukh-medium';
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.85);
          text-align: left; }
          .after-signup-carousel .slick-slide .slide .flex-content .content-wrap .text span {
            font-family: 'sukh-bold';
            color: inherit; }
          .after-signup-carousel .slick-slide .slide .flex-content .content-wrap .text a {
            font-size: 16px;
            font-family: 'sukh-bold';
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000; }
      .after-signup-carousel .slick-slide .slide .flex-content .img-wrap img {
        max-width: 100%; }
    .after-signup-carousel .slick-slide .slide .social-wrap {
      display: flex;
      align-items: center;
      margin-top: 66px;
      position: relative;
      z-index: 1;
      padding: 0 34px; }
      .after-signup-carousel .slick-slide .slide .social-wrap .qr-code {
        max-width: 88px;
        margin-right: 8px; }
        .after-signup-carousel .slick-slide .slide .social-wrap .qr-code img {
          width: 100%; }
      .after-signup-carousel .slick-slide .slide .social-wrap > div:last-child {
        flex-grow: 1; }
      .after-signup-carousel .slick-slide .slide .social-wrap .line-text {
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(255, 255, 255, 0.85);
        margin-bottom: 16px;
        display: flex;
        align-items: center; }
        .after-signup-carousel .slick-slide .slide .social-wrap .line-text img {
          width: 34px;
          margin-left: 4px; }
      .after-signup-carousel .slick-slide .slide .social-wrap .flex-text {
        display: flex;
        align-items: center; }
        .after-signup-carousel .slick-slide .slide .social-wrap .flex-text p {
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: rgba(255, 255, 255, 0.85); }
          .after-signup-carousel .slick-slide .slide .social-wrap .flex-text p:first-child {
            padding-right: 20px;
            position: relative; }
            .after-signup-carousel .slick-slide .slide .social-wrap .flex-text p:first-child:after {
              content: '';
              position: absolute;
              right: 0;
              height: 10px;
              border-right: 1px solid rgba(236, 236, 236, 0.2);
              top: 5px; }
          .after-signup-carousel .slick-slide .slide .social-wrap .flex-text p:last-child {
            padding-left: 20px; }
          .after-signup-carousel .slick-slide .slide .social-wrap .flex-text p a {
            font-family: 'sukh-bold';
            color: inherit; }
    .after-signup-carousel .slick-slide .slide .video-player {
      width: 387px;
      height: 206px;
      background: url(images/tutorials/play.svg), url(https://img.youtube.com/vi/2yXUXYPWswY/0.jpg);
      background-repeat: no-repeat,  no-repeat;
      background-size: contain cover;
      background-position: center center, center center;
      margin: 0 auto;
      cursor: pointer;
      position: relative;
      z-index: 1;
      border-radius: 10px; }

@media screen and (max-width: 768px) {
  .after-signup-carousel .content {
    width: 100%;
    height: 100%;
    border-radius: 0; }
    .after-signup-carousel .content .cancel {
      display: none; }
  .after-signup-carousel .slick-dots {
    bottom: 34px; }
    .after-signup-carousel .slick-dots.final-slide {
      bottom: 70px; }
  .after-signup-carousel .slick-arrow.orange {
    width: calc(100% - 40px);
    left: 20px;
    background: #f58a1f; }
  .after-signup-carousel .slick-slide .slide {
    padding: 24px 14px 14px; }
    .after-signup-carousel .slick-slide .slide .text-one {
      padding: 0 28px; }
    .after-signup-carousel .slick-slide .slide .video-player {
      width: 100%;
      background: url(images/tutorials/play.svg), url(https://img.youtube.com/vi/2yXUXYPWswY/0.jpg);
      background-repeat: no-repeat,  no-repeat;
      background-size: contain cover;
      background-position: center center, center center;
      height: 228px; }
    .after-signup-carousel .slick-slide .slide .bg {
      background: url(images/after-signup-carousel/slide-one-background-mobile.png) no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      border-radius: 0; }
    .after-signup-carousel .slick-slide .slide.two .bg {
      background: url(images/after-signup-carousel/slide-two-background-mobile.png) no-repeat;
      background-size: 100% 100%;
      background-position: center center; }
    .after-signup-carousel .slick-slide .slide.three .bg {
      background: url(images/after-signup-carousel/slide-three-background-mobile.png) no-repeat;
      background-size: 100% 100%;
      background-position: center center; }
    .after-signup-carousel .slick-slide .slide.four .bg {
      background: url(images/after-signup-carousel/slide-four-background-mobile.png) no-repeat;
      background-size: 100% 100%;
      background-position: center center; }
    .after-signup-carousel .slick-slide .slide.four .flex-content {
      position: relative; }
      .after-signup-carousel .slick-slide .slide.four .flex-content .img-wrap {
        order: unset;
        max-width: 100%;
        min-width: 100%; }
        .after-signup-carousel .slick-slide .slide.four .flex-content .img-wrap img {
          max-width: 100%; }
      .after-signup-carousel .slick-slide .slide.four .flex-content .content-wrap {
        margin: 0;
        position: absolute;
        left: 65px;
        top: 65%; }
        .after-signup-carousel .slick-slide .slide.four .flex-content .content-wrap .text {
          text-align: left;
          font-size: 13px; }
          .after-signup-carousel .slick-slide .slide.four .flex-content .content-wrap .text.margin-high {
            padding-bottom: 12px;
            border-radius: 0.5px;
            border-bottom: 1px solid #f58a1f; }
          .after-signup-carousel .slick-slide .slide.four .flex-content .content-wrap .text.margin {
            font-family: 'sukh-medium';
            text-align: center; }
          .after-signup-carousel .slick-slide .slide.four .flex-content .content-wrap .text.center {
            text-align: center; }
          .after-signup-carousel .slick-slide .slide.four .flex-content .content-wrap .text a {
            font-size: 14px; }
    .after-signup-carousel .slick-slide .slide.four .desc-wrap {
      position: absolute;
      bottom: 100px;
      z-index: 1;
      left: 0;
      right: 0;
      margin: 0;
      padding: 0 24px; }
    .after-signup-carousel .slick-slide .slide .title {
      margin: 0;
      margin-bottom: 36px;
      max-width: 100%;
      height: auto; }
    .after-signup-carousel .slick-slide .slide button {
      margin: 4px 0 12px 0; }
    .after-signup-carousel .slick-slide .slide .img-wrapper {
      width: 100%;
      height: 189px;
      background: url(images/after-signup-carousel/img-four-mobile.png) no-repeat;
      background-size: contain;
      background-position: center center;
      margin: 10% auto 0 auto; }
    .after-signup-carousel .slick-slide .slide .text-content {
      position: absolute;
      bottom: 80px;
      z-index: 1;
      left: 0;
      right: 0; }
      .after-signup-carousel .slick-slide .slide .text-content .text {
        font-size: 16px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgba(255, 255, 255, 0.85); }
    .after-signup-carousel .slick-slide .slide .middle-image img {
      width: 100%; }
    .after-signup-carousel .slick-slide .slide .logo-image {
      padding: 0 24px;
      max-height: initial;
      position: absolute;
      bottom: 80px;
      z-index: 1;
      left: 0;
      right: 0;
      margin: 0; }
      .after-signup-carousel .slick-slide .slide .logo-image img {
        width: 100%;
        max-width: 100%; }
    .after-signup-carousel .slick-slide .slide .flex-content {
      padding: 0;
      flex-direction: column;
      align-items: center; }
      .after-signup-carousel .slick-slide .slide .flex-content .img-wrap img {
        max-width: 300px; }
      .after-signup-carousel .slick-slide .slide .flex-content .content-wrap {
        text-align: center;
        margin: 0;
        max-width: 300px; }
        .after-signup-carousel .slick-slide .slide .flex-content .content-wrap .text {
          text-align: center;
          line-height: 1.4; }
      .after-signup-carousel .slick-slide .slide .flex-content > div:first-child {
        order: 2;
        margin-top: 16px; }
    .after-signup-carousel .slick-slide .slide .social-wrap {
      margin: 0;
      position: absolute;
      bottom: 80px;
      z-index: 1;
      left: 0;
      right: 0;
      margin: 0;
      padding: 0 16px; }
      .after-signup-carousel .slick-slide .slide .social-wrap .qr-code {
        display: none; }
      .after-signup-carousel .slick-slide .slide .social-wrap .line-text {
        flex-direction: column; }
        .after-signup-carousel .slick-slide .slide .social-wrap .line-text img {
          display: block;
          width: 160px;
          margin-top: 8px; }
      .after-signup-carousel .slick-slide .slide .social-wrap .flex-text {
        flex-direction: column; }
        .after-signup-carousel .slick-slide .slide .social-wrap .flex-text p {
          padding: 0; }
          .after-signup-carousel .slick-slide .slide .social-wrap .flex-text p:first-child {
            display: none; } }

.interaction-form-layout {
  padding: 0;
  height: calc(var(--vh) - 4rem); }
  .interaction-form-layout .ui.steps {
    margin: 0 !important; }
  .interaction-form-layout .stepper-content {
    height: calc(100% - 50px);
    overflow: auto;
    padding: 0 2rem; }

.survey-list-layout {
  padding: 2rem 0; }
  .survey-list-layout .card {
    width: 100%;
    padding: 12px 10px;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
    background-color: #fff;
    margin-bottom: 20px;
    position: relative; }
    .survey-list-layout .card textarea {
      border: solid 1px #e6eaf8;
      width: 100%;
      min-height: 80px;
      padding: 10px;
      outline: none;
      font-size: 14px;
      color: #2e394f; }
    .survey-list-layout .card .label {
      margin-bottom: 1rem;
      display: block; }
    .survey-list-layout .card input[type="text"] {
      border: solid 1px #e6eaf8;
      width: 100%;
      padding: 10px;
      outline: none;
      font-size: 14px;
      color: #2e394f; }
    .survey-list-layout .card .check-box,
    .survey-list-layout .card .radio {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer; }
      .survey-list-layout .card .check-box label,
      .survey-list-layout .card .radio label {
        cursor: pointer; }
      .survey-list-layout .card .check-box input,
      .survey-list-layout .card .radio input {
        width: 12px;
        height: 12px; }
      .survey-list-layout .card .check-box .label-text,
      .survey-list-layout .card .radio .label-text {
        padding-left: 12px; }
    .survey-list-layout .card .response-section .file-upload {
      display: flex;
      align-items: flex-end; }
      .survey-list-layout .card .response-section .file-upload img {
        max-width: 240px;
        margin-right: 20px; }
      .survey-list-layout .card .response-section .file-upload button {
        margin: 0; }
    .survey-list-layout .card .response-section > div > div {
      display: inline-flex;
      min-width: calc(50% - 2rem); }
      .survey-list-layout .card .response-section > div > div:nth-child(odd) {
        margin-right: 4rem; }
    .survey-list-layout .card .survey {
      font-size: 16px;
      font-family: 'sukh-medium';
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #2e394f;
      white-space: pre-wrap;
      margin-bottom: 1.2rem; }

.countdown-timer {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  width: 120px; }
  .countdown-timer .flip-unit-container {
    display: block;
    position: relative;
    width: 50px;
    height: 40px;
    perspective-origin: 50% 50%;
    perspective: 300px;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 10px 10px -10px grey; }
  .countdown-timer .upper-card, .countdown-timer .lower-card {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    height: 50%;
    overflow: hidden;
    border: 1px solid whitesmoke; }
  .countdown-timer .upper-card span, .countdown-timer .lower-card span {
    font-size: 1.2em;
    font-family: "sukh-regular";
    font-weight: lighter;
    color: #333333; }
  .countdown-timer .upper-card {
    align-items: flex-end;
    border-bottom: 0.5px solid whitesmoke;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
  .countdown-timer .upper-card span {
    transform: translateY(50%); }
  .countdown-timer .lower-card {
    align-items: flex-start;
    border-top: 0.5px solid whitesmoke;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px; }
  .countdown-timer .lower-card span {
    transform: translateY(-50%); }
  .countdown-timer .flip-card {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
    backface-visibility: hidden; }
  .countdown-timer .flip-card span {
    font-family: "sukh-regular";
    font-size: 1.2em;
    font-weight: lighter;
    color: #333333; }
  .countdown-timer .flip-card.unfold {
    top: 50%;
    align-items: flex-start;
    transform-origin: 50% 0%;
    transform: rotateX(180deg);
    background-color: white;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 0.5px solid whitesmoke;
    border-top: 0.5px solid whitesmoke; }
  .countdown-timer .flip-card.unfold span {
    transform: translateY(-50%); }
  .countdown-timer .flip-card.fold {
    top: 0%;
    align-items: flex-end;
    transform-origin: 50% 100%;
    transform: rotateX(0deg);
    background-color: white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 0.5px solid whitesmoke;
    border-bottom: 0.5px solid whitesmoke; }
  .countdown-timer .flip-card.fold span {
    transform: translateY(50%); }
  .countdown-timer .fold {
    animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    transform-style: preserve-3d; }
  .countdown-timer .unfold {
    animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    transform-style: preserve-3d; }

@keyframes fold {
  0% {
    transform: rotateX(0deg); }
  100% {
    transform: rotateX(-180deg); } }

@keyframes unfold {
  0% {
    transform: rotateX(180deg); }
  100% {
    transform: rotateX(0deg); } }

@media screen and (max-width: 1024px) {
  .qr-details {
    padding: 20px; } }

.flash-sale-promotion {
  background-image: linear-gradient(354deg, #ffe8d1 185%, #ffd5a7 46%, #fde9ce -54%);
  background-size: 100% 100%;
  position: relative;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  border: 2px solid #e8903d;
  padding: 48px 18px 18px; }
  .flash-sale-promotion .gift-wrap {
    position: absolute;
    left: 0;
    top: 0;
    padding: 8px 12px 8px 36px;
    background: #e8903d url(images/icons/gift.svg) no-repeat;
    background-position: 4px center;
    background-size: 30px 30px;
    border-radius: 0 0 4px 0;
    font-size: 14px;
    font-family: 'sukh-bold';
    line-height: normal;
    letter-spacing: 0.3px;
    color: #ffffff;
    text-transform: uppercase;
    display: flex;
    align-items: center; }
  .flash-sale-promotion:after {
    content: '';
    background: url(images/icons/coins.svg) no-repeat;
    background-position: center right;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; }
  .flash-sale-promotion .text {
    font-size: 16px;
    font-family: 'sukh-bold';
    color: #192383;
    position: relative;
    z-index: 1;
    line-height: normal; }

.insurer-listing-page {
  font-family: 'sukh-light', sans-serif;
  background: #ffffff; }
  .insurer-listing-page .select-filter-wrap {
    display: none; }
  .insurer-listing-page .cashback-plans .header-wrap {
    background-color: #f7f8ff;
    padding: 8px 12px;
    margin-bottom: 24px;
    position: relative;
    cursor: pointer; }
    .insurer-listing-page .cashback-plans .header-wrap p {
      font-size: 14px;
      font-family: 'sukh-medium', sans-serif;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #1d2785;
      text-align: left; }
    .insurer-listing-page .cashback-plans .header-wrap.close img {
      transform: rotate(-180deg); }
    .insurer-listing-page .cashback-plans .header-wrap img {
      position: absolute;
      right: 12px;
      top: 40%; }
  .insurer-listing-page .cashback-plans .manual-quote-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly; }
  .insurer-listing-page .comparison-wrapper {
    padding-top: 90px;
    display: flex;
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    justify-content: center; }
    .insurer-listing-page .comparison-wrapper.bike .content-wrapper {
      padding-left: 0; }
    .insurer-listing-page .comparison-wrapper .content-wrapper {
      vertical-align: top;
      padding-left: 40px;
      text-align: center;
      padding-bottom: 120px;
      width: 100%;
      max-width: 900px; }
      .insurer-listing-page .comparison-wrapper .content-wrapper .max-width-wrapper {
        max-width: 900px;
        display: inline-block;
        width: 100%; }
      .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap {
        z-index: 2;
        width: 100%;
        padding: 40px 0 10px 0;
        position: relative; }
        .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap.no-filter + .cards-wrapper {
          margin: 0 0 20px 0; }
        .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .filter-text {
          font-family: 'sukh-light', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #4a4a4a; }
          .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .filter-text span {
            font-family: 'sukh-regular', sans-serif;
            letter-spacing: 0; }
        .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .applied-filters {
          margin-top: 20px;
          text-align: left; }
          .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .applied-filters .each-filter {
            border-radius: 2px;
            background-color: #ffffff;
            border: solid 0.6px #9b9b9b;
            padding: 4px 10px;
            margin: 0 6px 6px 0;
            display: inline-block;
            vertical-align: middle; }
            .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .applied-filters .each-filter img {
              max-width: 8px; }
            .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .applied-filters .each-filter .text {
              font-family: 'sukh-regular', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #4a4a4a;
              display: inline-block;
              cursor: pointer; }
              .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .applied-filters .each-filter .text:first-child {
                margin-right: 4px; }
          .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .applied-filters .clear-filter {
            font-family: 'sukh-light', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #192383;
            display: inline-block;
            vertical-align: middle;
            text-transform: uppercase;
            cursor: pointer;
            margin-bottom: 5px; }
        .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .dropdown-wrap {
          position: absolute;
          right: 0;
          top: 40px; }
          .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .dropdown-wrap .ui.fluid.dropdown.mini .text {
            text-transform: none; }
          .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .dropdown-wrap .ui.fluid.dropdown.mini .dropdown.icon {
            top: 0;
            right: 0;
            bottom: 0;
            margin: 0; }
      .insurer-listing-page .comparison-wrapper .content-wrapper .cards-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        margin: 0px 0 20px 0; }
        .insurer-listing-page .comparison-wrapper .content-wrapper .cards-wrapper .manual-quote-actions {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly; }
        .insurer-listing-page .comparison-wrapper .content-wrapper .cards-wrapper.list {
          display: block; }
        .insurer-listing-page .comparison-wrapper .content-wrapper .cards-wrapper.close {
          display: none; }
      .insurer-listing-page .comparison-wrapper .content-wrapper .see-more {
        font-family: Avenir-Roman;
        font-family: 'sukh-light';
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #192383;
        margin-bottom: 30px; }
    .insurer-listing-page .comparison-wrapper .left-panel {
      width: 280px;
      min-width: 280px;
      position: relative;
      display: table-cell;
      vertical-align: top;
      padding-top: 45px; }
      .insurer-listing-page .comparison-wrapper .left-panel .panel-content {
        padding-bottom: 80px; }
        .insurer-listing-page .comparison-wrapper .left-panel .panel-content .sticky {
          top: -160px !important; }
        .insurer-listing-page .comparison-wrapper .left-panel .panel-content .cust-sticky {
          transform: none !important; }
        .insurer-listing-page .comparison-wrapper .left-panel .panel-content::-webkit-scrollbar {
          width: 3px; }
        .insurer-listing-page .comparison-wrapper .left-panel .panel-content::-webkit-scrollbar-track {
          -webkit-box-shadow: #f4f4f4; }
        .insurer-listing-page .comparison-wrapper .left-panel .panel-content::-webkit-scrollbar-thumb {
          background-color: rgba(22, 43, 106, 0.05);
          outline: 1px solid rgba(22, 43, 106, 0.05); }
  .insurer-listing-page .button-wrap-fixed {
    margin-top: 10px;
    padding: 0;
    text-align: left;
    position: fixed;
    bottom: 20px;
    width: 280px; }
    .insurer-listing-page .button-wrap-fixed button span {
      display: none; }
  .insurer-listing-page .mobile-compare {
    display: none; }
  .insurer-listing-page .no-results {
    text-align: center;
    padding: 180px 10px 180px 0; }
    .insurer-listing-page .no-results img {
      margin-bottom: 30px; }
    .insurer-listing-page .no-results .title-text {
      font-family: 'sukh-medium', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      margin-bottom: 20px; }
    .insurer-listing-page .no-results .sub-text {
      font-family: 'sukh-light', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a; }
  .insurer-listing-page .no-insurer-wrap {
    margin: 40px 0; }
    .insurer-listing-page .no-insurer-wrap .text {
      font-family: 'sukh-regular', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      letter-spacing: 0.1px; }
    .insurer-listing-page .no-insurer-wrap .no-insurer-cards-wrapper {
      text-align: center;
      margin: 25px 0; }
      .insurer-listing-page .no-insurer-wrap .no-insurer-cards-wrapper img {
        max-width: 50px;
        margin: 0 15px 15px;
        cursor: pointer; }
    .insurer-listing-page .no-insurer-wrap .buttons-wrap {
      text-align: center; }
  .insurer-listing-page .contact-us {
    border-radius: 12px;
    box-shadow: 1px 1px 11px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #ecebeb;
    background-color: #ffffff;
    margin-top: 18px;
    padding: 10px 0 0 0;
    line-height: 1.5; }
    .insurer-listing-page .contact-us .text {
      font-size: 14px; }
    .insurer-listing-page .contact-us .header {
      font-size: 18px;
      font-family: 'sukh-bold'; }
    .insurer-listing-page .contact-us .sub-header {
      background-color: #192383;
      border-radius: 0 0 12px 12px;
      margin-top: 18px; }
      .insurer-listing-page .contact-us .sub-header .container {
        display: flex;
        justify-content: center;
        padding: 8px;
        align-items: end; }
      .insurer-listing-page .contact-us .sub-header .phone {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        display: flex;
        align-items: center;
        margin-right: 24px; }
        .insurer-listing-page .contact-us .sub-header .phone img {
          width: 16px;
          height: 16px;
          object-fit: contain;
          margin-right: 4px; }
      .insurer-listing-page .contact-us .sub-header .social-icons a:not(:last-child) {
        margin-right: 24px; }
      .insurer-listing-page .contact-us .sub-header .social-icons img {
        width: 24px;
        height: 24px;
        object-fit: contain; }

.insurer-compare-list-wrap {
  z-index: 1; }
  .insurer-compare-list-wrap::-webkit-scrollbar {
    width: 3px; }
  .insurer-compare-list-wrap::-webkit-scrollbar-track {
    -webkit-box-shadow: #f4f4f4; }
  .insurer-compare-list-wrap::-webkit-scrollbar-thumb {
    background-color: rgba(22, 43, 106, 0.05);
    outline: 1px solid rgba(22, 43, 106, 0.05); }
  .insurer-compare-list-wrap .insurer-compare-list {
    position: relative;
    padding: 0;
    background: #ffffff;
    display: flex; }
  .insurer-compare-list-wrap .button-wrap {
    padding: 0 16px 16px; }

.ui.flowing.popup.compare {
  margin-bottom: -47.5px !important;
  margin-left: -17.5px !important;
  padding: 0; }

.ui.popup.add-to-compare {
  width: auto;
  min-width: 120px;
  max-width: 200px; }
  .ui.popup.add-to-compare .info-text {
    font-family: 'sukh-light', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a; }

@media screen and (max-width: 1240px) {
  .insurer-listing-page .cashback-plans .header-wrap {
    margin-bottom: 8px;
    background: #fff; }
  .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap {
    padding-right: 40px;
    max-width: 660px; }
  .insurer-listing-page .comparison-wrapper .content-wrapper .cards-wrapper {
    grid-template-columns: repeat(2, 1fr); }
    .insurer-listing-page .comparison-wrapper .content-wrapper .cards-wrapper.list {
      display: block; } }

@media screen and (max-width: 1024px) {
  .insurer-listing-page {
    min-height: auto;
    z-index: 999;
    position: relative; }
    .insurer-listing-page .button-wrap-fixed {
      display: none; }
    .insurer-listing-page .select-filter-wrap {
      display: table;
      width: 100%;
      background-color: #ffffff;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
      padding: 0 20px; }
      .insurer-listing-page .select-filter-wrap li {
        display: table-cell;
        vertical-align: middle;
        font-family: 'sukh-regular', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #192383;
        padding: 16px 0; }
        .insurer-listing-page .select-filter-wrap li:first-child span {
          border-right: 2px solid rgba(216, 216, 216, 0.49); }
        .insurer-listing-page .select-filter-wrap li span {
          display: block;
          text-transform: uppercase; }
    .insurer-listing-page .mobile-compare {
      position: fixed;
      bottom: 50px;
      z-index: 3;
      text-align: center;
      width: 100%;
      left: 0;
      right: 0;
      display: block; }
      .insurer-listing-page .mobile-compare .button {
        border-radius: 4px;
        background-color: #192383;
        -webkit-box-shadow: 4px 3px 10px 0 rgba(0, 0, 0, 0.33);
        box-shadow: 4px 3px 10px 0 rgba(0, 0, 0, 0.33);
        font-family: 'sukh-light';
        letter-spacing: 0;
        font-size: 14px;
        color: #fff;
        display: inline-block;
        width: auto;
        border: 0;
        letter-spacing: initial;
        padding: 10px 25px; }
    .insurer-listing-page .comparison-wrapper {
      padding: 0;
      z-index: 1;
      background: #f3f4f7;
      position: relative;
      overflow-y: auto; }
      .insurer-listing-page .comparison-wrapper .left-panel {
        width: 0;
        min-width: 0;
        position: relative;
        height: 0;
        display: block; }
        .insurer-listing-page .comparison-wrapper .left-panel .panel-content {
          overflow-y: initial;
          height: auto;
          padding: 0;
          position: static;
          overflow-x: initial; }
        .insurer-listing-page .comparison-wrapper .left-panel .button-wrap {
          position: fixed;
          bottom: 50px;
          z-index: 3;
          text-align: center;
          width: 100%;
          left: 0;
          right: 0; }
          .insurer-listing-page .comparison-wrapper .left-panel .button-wrap .button {
            border-radius: 4px;
            background-color: #192383;
            box-shadow: 4px 3px 10px 0 rgba(0, 0, 0, 0.33);
            font-family: 'sukh-light';
            letter-spacing: 0;
            font-size: 14px;
            color: #ffffff;
            display: inline-block;
            width: auto;
            border: 0;
            letter-spacing: initial;
            padding: 10px 25px; }
            .insurer-listing-page .comparison-wrapper .left-panel .button-wrap .button span {
              display: inline-block; }
      .insurer-listing-page .comparison-wrapper .insurer-compare-list {
        display: none; }
      .insurer-listing-page .comparison-wrapper .content-wrapper {
        padding-left: 0;
        padding-bottom: 0; }
        .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap {
          padding: 6px;
          z-index: 1; }
          .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .filter-text {
            text-align: center;
            font-size: 12px; }
          .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .applied-filters,
          .insurer-listing-page .comparison-wrapper .content-wrapper .applied-filter-wrap .dropdown-wrap {
            display: none; }
        .insurer-listing-page .comparison-wrapper .content-wrapper .cards-wrapper {
          display: block; }
    .insurer-listing-page .no-insurer-wrap {
      margin: 40px auto;
      max-width: 300px; }
      .insurer-listing-page .no-insurer-wrap .no-insurer-cards-wrapper img {
        max-width: 34px;
        margin-bottom: 20px; }
      .insurer-listing-page .no-insurer-wrap .buttons-wrap {
        text-align: center;
        margin: 0; } }

.insurer-comparison-page {
  padding-top: 0;
  min-height: var(--vh); }
  .insurer-comparison-page .with-shadow {
    box-shadow: 0 2px 15px 9px rgba(0, 0, 0, 0.05); }
  .insurer-comparison-page .breadcrumb {
    padding: 15px 0; }
    .insurer-comparison-page .breadcrumb .container {
      position: relative; }
    .insurer-comparison-page .breadcrumb ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: block; }
      .insurer-comparison-page .breadcrumb ul li {
        margin: 0;
        padding: 0;
        display: inline-block;
        position: relative; }
        .insurer-comparison-page .breadcrumb ul li:after {
          content: '/';
          padding: 0 2px;
          font-family: 'sukh-regular', sans-serif;
          color: #192383; }
        .insurer-comparison-page .breadcrumb ul li:last-child:after {
          content: ''; }
        .insurer-comparison-page .breadcrumb ul li a {
          font-family: 'sukh-regular', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #192383;
          cursor: pointer; }
          .insurer-comparison-page .breadcrumb ul li a:hover {
            text-decoration: none;
            color: inherit;
            opacity: .9; }
    .insurer-comparison-page .breadcrumb .buttons-wrap {
      position: absolute;
      right: 64px;
      display: flex;
      justify-content: flex-end; }
      .insurer-comparison-page .breadcrumb .buttons-wrap .button:not(:last-child) {
        margin-right: 16px; }
  .insurer-comparison-page .car-details-wrap p {
    font-size: 18px;
    font-family: 'sukh-medium';
    color: #4a4a4a;
    margin: 32px 0 20px 0; }
    .insurer-comparison-page .car-details-wrap p span {
      font-size: 18px;
      font-family: 'sukh-bold';
      text-align: center;
      color: #1d2785;
      border-bottom: 1px solid #1d2785; }
    .insurer-comparison-page .car-details-wrap p img {
      margin-left: 8px; }
  .insurer-comparison-page .insurer-details-wrapper {
    position: relative; }
    .insurer-comparison-page .insurer-details-wrapper .button-wrap {
      text-align: right;
      margin-bottom: 16px; }
    .insurer-comparison-page .insurer-details-wrapper.responsive {
      display: none; }
    .insurer-comparison-page .insurer-details-wrapper .each-wrap .header-wrap {
      padding: 18px 32px;
      background: #f8f8f8;
      cursor: pointer;
      position: relative; }
      .insurer-comparison-page .insurer-details-wrapper .each-wrap .header-wrap .arrow {
        position: absolute;
        right: 40px;
        top: 22px; }
        .insurer-comparison-page .insurer-details-wrapper .each-wrap .header-wrap .arrow.rotate {
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
      .insurer-comparison-page .insurer-details-wrapper .each-wrap .header-wrap .title-text {
        font-size: 14px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #1b1b1b;
        text-transform: uppercase; }
    .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap.hide {
      display: none; }
    .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .title-section p {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b1b1b; }
    .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .text {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #5e5e5e; }
      .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .text.val {
        font-family: Avenir-Roman;
        font-family: 'sukh-light'; }
      .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .text span.curr {
        font-family: 'sukh-regular', sans-serif;
        min-width: auto !important; }
      .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .text a {
        color: #192383;
        font-family: 'sukh-light', sans-serif; }
      .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .text.with-margin {
        margin-bottom: 15px; }
      .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .text span {
        font-family: 'sukh-light', sans-serif; }
    .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .column-wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 0; }
      .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .column-wrap:first-child .column {
        padding-top: 24px; }
      .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .column-wrap:last-child .column {
        padding-bottom: 24px; }
      .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .column-wrap .column {
        border-right: 1px solid rgba(151, 151, 151, 0.2);
        padding: 8px 32px; }

@media screen and (max-width: 1024px) {
  .insurer-comparison-page {
    padding: 56px 0 0; }
    .insurer-comparison-page .comparison-wrapper {
      padding: 0; }
    .insurer-comparison-page .insurer-details-wrapper {
      padding-top: 240px;
      display: none; }
      .insurer-comparison-page .insurer-details-wrapper .each-wrap {
        margin: 0; }
        .insurer-comparison-page .insurer-details-wrapper .each-wrap .header-wrap .title-text {
          font-size: 12px;
          text-align: center; }
        .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .text {
          font-size: 10px;
          text-align: left; }
          .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .text img {
            max-width: 10px; }
          .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .text span:first-child {
            display: inline-block;
            max-width: 55%;
            min-width: 55%;
            vertical-align: bottom; }
          .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .text span:nth-child(2) {
            font-family: Avenir-Roman;
            font-family: 'sukh-light'; }
        .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .grid .column {
          padding: 10px 15px; }
          .insurer-comparison-page .insurer-details-wrapper .each-wrap .content-wrap .grid .column:nth-child(odd) {
            border-right: solid 1px #f5f4f4; }
      .insurer-comparison-page .insurer-details-wrapper.responsive {
        display: block; }
  .insurer-comparison-page {
    padding: 0;
    background: #f3f4f7;
    margin-bottom: 60px; }
    .insurer-comparison-page .container {
      padding: 0; }
    .insurer-comparison-page .with-shadow {
      box-shadow: none; }
    .insurer-comparison-page .car-details-wrap {
      background: #ffffff;
      padding: 24px 24px 0 24px; }
      .insurer-comparison-page .car-details-wrap p {
        font-size: 12px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #7d7d7d;
        margin: 0;
        border-bottom: 1px solid #f0f0f5;
        padding-bottom: 12px; }
        .insurer-comparison-page .car-details-wrap p span {
          font-size: 12px;
          font-family: 'sukh-medium';
          font-style: normal;
          font-stretch: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #1d2785; }
    .insurer-comparison-page .breadcrumb {
      padding: 5px 0; }
      .insurer-comparison-page .breadcrumb ul {
        display: none; }
      .insurer-comparison-page .breadcrumb .buttons-wrap {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        background: #fff;
        z-index: 9; }
        .insurer-comparison-page .breadcrumb .buttons-wrap button {
          width: 50%;
          margin: 0;
          border: 0;
          font-size: 12px;
          font-family: 'sukh-medium';
          text-transform: none;
          border-radius: 0;
          padding: 18px 12px; }
          .insurer-comparison-page .breadcrumb .buttons-wrap button:first-child {
            background-color: #ffffff;
            color: #4a4a4a; }
          .insurer-comparison-page .breadcrumb .buttons-wrap button:last-child {
            background-color: #ff901d;
            color: #fff; }
    .insurer-comparison-page .comparison-wrapper {
      display: none; } }

.fairdee-wizard-wrapper {
  max-width: 1080px;
  margin: 0 auto; }
  .fairdee-wizard-wrapper .info-wrap-error {
    position: relative;
    padding: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
    overflow-x: auto;
    white-space: pre-wrap;
    border-radius: 0.25rem;
    background-color: rgba(206, 17, 38, 0.05);
    margin: 24px auto;
    text-align: center; }
    .fairdee-wizard-wrapper .info-wrap-error p {
      margin-bottom: 24px; }
  .fairdee-wizard-wrapper .back-to-compare {
    margin-bottom: 12px; }
  .fairdee-wizard-wrapper .wizard-banner {
    width: 100%;
    margin-bottom: 32px;
    padding: 14px 32px 14px 130px;
    max-width: 480px; }
    .fairdee-wizard-wrapper .wizard-banner p {
      font-size: 14px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.85); }
      .fairdee-wizard-wrapper .wizard-banner p span {
        color: #ff6100; }
    .fairdee-wizard-wrapper .wizard-banner.left {
      background: url(images/wizard-banner-one.png) no-repeat;
      background-size: cover;
      padding-left: 110px; }
    .fairdee-wizard-wrapper .wizard-banner.right {
      background: url(images/wizard-banner-two.svg) no-repeat;
      background-size: cover; }
  .fairdee-wizard-wrapper .banner-message {
    padding: 10px;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    align-items: center; }
    .fairdee-wizard-wrapper .banner-message.warning {
      background-color: #ffd191;
      color: black; }
      .fairdee-wizard-wrapper .banner-message.warning .icon-holder {
        border-right: 1px solid black; }
    .fairdee-wizard-wrapper .banner-message.error {
      background-color: #ec2c2c;
      color: white; }
      .fairdee-wizard-wrapper .banner-message.error .icon-holder {
        border-right: 1px solid white; }
    .fairdee-wizard-wrapper .banner-message .icon-holder {
      padding-right: 12px; }
    .fairdee-wizard-wrapper .banner-message .info-holder {
      padding-left: 12px; }
  .fairdee-wizard-wrapper .generate-contract {
    margin-bottom: 12px; }
    .fairdee-wizard-wrapper .generate-contract .or {
      margin: 0 10px; }
  .fairdee-wizard-wrapper .instalment-form-field {
    margin-bottom: 24px; }
    .fairdee-wizard-wrapper .instalment-form-field .instalment-plan {
      padding: 0 0 0 36px;
      margin-bottom: 10px; }
      .fairdee-wizard-wrapper .instalment-form-field .instalment-plan .text {
        font-size: 14px;
        color: #4a4a4a;
        font-family: 'sukh-regular'; }
  .fairdee-wizard-wrapper .invoices-list .invoice-card {
    padding: 24px 24px 24px 0; }
    .fairdee-wizard-wrapper .invoices-list .invoice-card:not(:last-child) {
      border-bottom: 1px solid #ccc; }
    .fairdee-wizard-wrapper .invoices-list .invoice-card .penalty {
      color: #b04747; }
    .fairdee-wizard-wrapper .invoices-list .invoice-card .row {
      display: flex;
      align-items: center;
      margin-bottom: 12px; }
      .fairdee-wizard-wrapper .invoices-list .invoice-card .row p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7f8fa4;
        margin-right: 12px;
        min-width: 180px; }
        .fairdee-wizard-wrapper .invoices-list .invoice-card .row p:last-child {
          color: #262626;
          font-family: 'sukh-medium';
          text-transform: capitalize; }
    .fairdee-wizard-wrapper .invoices-list .invoice-card .label {
      border-radius: 11px;
      font-size: 10px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      line-height: normal;
      padding: 1px 4px;
      display: inline-block;
      margin-right: 4px;
      border: 1px solid #ccc;
      min-width: 40px;
      text-align: center;
      margin-bottom: 4px;
      margin-left: 15px; }
      .fairdee-wizard-wrapper .invoices-list .invoice-card .label.red {
        color: #b04747;
        border: solid 1px #f8a0a0;
        background-color: #fff3f3; }
      .fairdee-wizard-wrapper .invoices-list .invoice-card .label.green {
        color: #378033;
        border: solid 1px #289a13;
        background-color: #f1fdf5; }
    .fairdee-wizard-wrapper .invoices-list .invoice-card .receipt-wrapper .receipt-button {
      display: flex; }
      .fairdee-wizard-wrapper .invoices-list .invoice-card .receipt-wrapper .receipt-button div {
        min-width: 180px;
        margin-right: 12px;
        font-size: 14px;
        color: #ff6400;
        cursor: pointer; }
    .fairdee-wizard-wrapper .invoices-list .invoice-card .receipt-wrapper .receipt {
      border-radius: 2px;
      background-color: #ffeed2;
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #5f5f5f;
      padding: 10px 12px;
      border: 1px solid #f5a623;
      margin: 0 12px 12px 0;
      display: inline-block;
      position: relative;
      padding-right: 24px;
      max-width: 100%;
      vertical-align: top;
      word-wrap: break-word;
      margin-top: 12px;
      cursor: pointer; }
  .fairdee-wizard-wrapper .invoices-list .desc-wrap p .amount {
    color: #228319 !important; }
  .fairdee-wizard-wrapper .instalment-docs-verification-wrapper {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 24px; }
  .fairdee-wizard-wrapper .intalment-types .instalment-card {
    border-radius: 2px;
    box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    margin-bottom: 24px;
    padding: 12px; }
    .fairdee-wizard-wrapper .intalment-types .instalment-card .title-text {
      margin: 24px 0 12px 0; }
    .fairdee-wizard-wrapper .intalment-types .instalment-card .breakup {
      margin-bottom: 12px;
      padding-bottom: 12px;
      display: inline-block;
      min-width: 51%; }
      .fairdee-wizard-wrapper .intalment-types .instalment-card .breakup:not(:last-child) {
        border-bottom: solid 1px #adb5c6; }
    .fairdee-wizard-wrapper .intalment-types .instalment-card .key-value {
      display: flex;
      align-items: center; }
      .fairdee-wizard-wrapper .intalment-types .instalment-card .key-value .key,
      .fairdee-wizard-wrapper .intalment-types .instalment-card .key-value .value {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #262626;
        font-family: 'sukh-medium';
        text-transform: capitalize; }
      .fairdee-wizard-wrapper .intalment-types .instalment-card .key-value .key {
        color: #7f8fa4;
        margin-right: 12px;
        min-width: 180px; }
  .fairdee-wizard-wrapper .form-six .button-wrap .content {
    display: none; }
  .fairdee-wizard-wrapper .form-six .button-wrap .policy-button-holder .info-text {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #5f5f5f;
    margin-bottom: 12px; }
  .fairdee-wizard-wrapper .form-six .button-wrap .policy-button-holder button {
    margin-bottom: 10px; }
  .fairdee-wizard-wrapper .form-six .desc-wrap {
    background-color: #fff6e8;
    padding: 10px;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 24px; }
    .fairdee-wizard-wrapper .form-six .desc-wrap p {
      font-size: 16px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      text-align: left;
      display: flex;
      margin-bottom: 4px;
      margin-left: 10px;
      position: relative;
      padding-left: 20px; }
      .fairdee-wizard-wrapper .form-six .desc-wrap p:first-child:before {
        content: 'i';
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background: #f5a623;
        position: absolute;
        color: #fff;
        text-align: center;
        line-height: 1.4;
        vertical-align: middle;
        left: -18px;
        top: 2px;
        font-size: 13px;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg); }
      .fairdee-wizard-wrapper .form-six .desc-wrap p .amount {
        color: #e6a03e;
        font-weight: bold;
        text-align: right;
        flex: 1; }
      .fairdee-wizard-wrapper .form-six .desc-wrap p .amount-red {
        color: #ff6100;
        font-weight: bold;
        text-align: right;
        flex: 1; }
      .fairdee-wizard-wrapper .form-six .desc-wrap p .amount-green {
        color: #028548d5;
        font-weight: bold;
        text-align: right;
        flex: 1; }
      .fairdee-wizard-wrapper .form-six .desc-wrap p .link {
        color: #3787e2;
        cursor: pointer;
        min-width: 90px; }
      .fairdee-wizard-wrapper .form-six .desc-wrap p .green {
        word-break: break-word;
        color: #028548d5;
        font-weight: bold;
        text-align: right;
        flex: 1;
        margin-left: 2px; }
      .fairdee-wizard-wrapper .form-six .desc-wrap p .red {
        word-break: break-word;
        color: #ff6100;
        font-weight: bold;
        text-align: right;
        flex: 1;
        margin-left: 2px;
        font-size: 16px; }
    .fairdee-wizard-wrapper .form-six .desc-wrap .label {
      border-radius: 11px;
      font-size: 10px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      line-height: normal;
      padding: 1px 4px;
      display: inline-block;
      margin-right: 4px;
      border: 1px solid #ccc;
      min-width: 40px;
      text-align: center;
      margin-bottom: 4px;
      margin-left: 15px; }
      .fairdee-wizard-wrapper .form-six .desc-wrap .label.red {
        color: #b04747;
        border: solid 1px #f8a0a0;
        background-color: #fff3f3; }
      .fairdee-wizard-wrapper .form-six .desc-wrap .label.green {
        color: #378033;
        border: solid 1px #289a13;
        background-color: #f1fdf5; }
  .fairdee-wizard-wrapper .form-six .ui.styled.accordion {
    box-shadow: none;
    border-radius: 0;
    max-width: 360px;
    margin: 20px 0 40px 0; }
    .fairdee-wizard-wrapper .form-six .ui.styled.accordion > .title {
      background-color: #dee5f0;
      color: #3f3f3f;
      font-size: 14px;
      padding: 15px;
      position: relative;
      border: 0; }
      .fairdee-wizard-wrapper .form-six .ui.styled.accordion > .title .icon {
        position: absolute;
        right: 0; }
    .fairdee-wizard-wrapper .form-six .ui.styled.accordion > .content {
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
      background-color: #ffffff;
      padding: 12px; }
  .fairdee-wizard-wrapper .fairdee-form-wrapper.right-section {
    padding-bottom: 80px; }

.fairdee-wizard {
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'sukh-regular'; }
  .fairdee-wizard .mb-20 {
    margin-bottom: 20px; }
  .fairdee-wizard .mobile {
    display: none; }
  .fairdee-wizard .desktop {
    display: block; }
  .fairdee-wizard .custom-datepicker .picker-title {
    color: #f58b1e;
    font-size: 12px;
    font-family: 'sukh-bold';
    margin-bottom: 6px; }
  .fairdee-wizard .padding-24 {
    padding: 0 24px; }
  .fairdee-wizard .wrapper {
    display: flex;
    align-items: flex-start; }
    .fairdee-wizard .wrapper .content-wrap {
      min-height: 200px;
      flex-grow: 1;
      margin-right: 20px; }
      .fairdee-wizard .wrapper .content-wrap .step {
        border-radius: 6px;
        box-shadow: 1px 4px 6px 0 #e2e1e1;
        background-color: #ffffff; }
        .fairdee-wizard .wrapper .content-wrap .step.disabled {
          pointer-events: none;
          opacity: 0.7; }
        .fairdee-wizard .wrapper .content-wrap .step.open .content {
          display: block; }
        .fairdee-wizard .wrapper .content-wrap .step.two {
          padding-top: 24px; }
          .fairdee-wizard .wrapper .content-wrap .step.two .content .col:last-child {
            padding-bottom: 30px; }
        .fairdee-wizard .wrapper .content-wrap .step.three {
          padding-top: 24px; }
          .fairdee-wizard .wrapper .content-wrap .step.three .content .col:last-child {
            padding-bottom: 30px; }
        .fairdee-wizard .wrapper .content-wrap .step.four {
          padding-top: 24px; }
          .fairdee-wizard .wrapper .content-wrap .step.four .content .col:last-child {
            padding-bottom: 30px; }
        .fairdee-wizard .wrapper .content-wrap .step .header {
          background-color: #f8f8f8;
          padding: 16px 36px;
          position: relative; }
          .fairdee-wizard .wrapper .content-wrap .step .header span {
            width: 36px;
            border-radius: 4px 12px 12px 4px;
            background-color: #bebebe;
            position: absolute;
            display: inline-block;
            left: -8px;
            top: calc(50% - 10px);
            font-size: 12px;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 1px;
            color: #ffffff;
            text-align: right;
            padding: 3px 4px;
            vertical-align: middle; }
            .fairdee-wizard .wrapper .content-wrap .step .header span.completed {
              background: #68aa16; }
              .fairdee-wizard .wrapper .content-wrap .step .header span.completed:before {
                content: '\2713';
                position: absolute;
                left: 4px;
                top: 3px;
                font-size: 12px;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 1px;
                color: #ffffff; }
            .fairdee-wizard .wrapper .content-wrap .step .header span.read-only {
              background-color: #bebebe; }
              .fairdee-wizard .wrapper .content-wrap .step .header span.read-only:before {
                display: none; }
          .fairdee-wizard .wrapper .content-wrap .step .header p {
            font-size: 12px;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 1px;
            color: #4a4a4a;
            text-transform: uppercase; }
        .fairdee-wizard .wrapper .content-wrap .step .content {
          padding: 24px 36px;
          background: #fff;
          display: none; }
          .fairdee-wizard .wrapper .content-wrap .step .content .mb-8 {
            margin-bottom: 8px; }
          .fairdee-wizard .wrapper .content-wrap .step .content .mt-8 {
            margin-top: 8px; }
          .fairdee-wizard .wrapper .content-wrap .step .content .mt-20 {
            margin-top: 20px; }
          .fairdee-wizard .wrapper .content-wrap .step .content .row {
            display: flex;
            margin-bottom: 24px; }
            .fairdee-wizard .wrapper .content-wrap .step .content .row .col {
              padding-bottom: 12px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col .field {
                margin-bottom: 18px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col.with-border {
                border-bottom: 1px solid rgba(151, 151, 151, 0.4); }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col:first-child {
                min-width: 160px;
                max-width: 160px;
                margin-right: 30px; }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col:first-child p {
                  font-size: 14px;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #4a4a4a; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col:last-child {
                flex-grow: 1; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col p.text {
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #5f5f5f;
                margin-top: 8px;
                padding-left: 28px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col .payment-content {
                margin-top: 16px; }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col .payment-content .text-one {
                  font-size: 12px;
                  font-weight: normal;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  color: #5f5f5f;
                  margin-bottom: 12px; }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col .payment-content div.with-border {
                  border-left: 1px solid #e2e2e2;
                  padding-left: 12px;
                  margin-bottom: 26px; }
                  .fairdee-wizard .wrapper .content-wrap .step .content .row .col .payment-content div.with-border p {
                    font-size: 12px;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #5f5f5f;
                    margin-bottom: 2px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col .form-field-wrap.age-group .ui.selection.dropdown {
                min-width: 140px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col .form-field-wrap:not(:last-child) {
                margin-bottom: 20px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col .inline.fields {
                display: flex; }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col .inline.fields .field:first-child {
                  margin-right: 30px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col .datepicker {
                border-radius: 2px;
                background-color: #ffffff;
                border: 1px solid #cbcbcb;
                height: 30px; }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col .datepicker label {
                  display: none; }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col .datepicker p {
                  display: none; }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col .datepicker > div {
                  margin: 0; }
                  .fairdee-wizard .wrapper .content-wrap .step .content .row .col .datepicker > div:before, .fairdee-wizard .wrapper .content-wrap .step .content .row .col .datepicker > div:after {
                    display: none; }
                  .fairdee-wizard .wrapper .content-wrap .step .content .row .col .datepicker > div input {
                    border: 0;
                    width: 100%;
                    pointer-events: none; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col p.title-text {
                font-size: 14px;
                font-family: 'sukh-medium';
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #4a4a4a;
                margin-bottom: 12px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col .button-text-wrap {
                display: flex;
                align-items: center; }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col .button-text-wrap:not(:last-child) {
                  margin-bottom: 12px; }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col .button-text-wrap.block {
                  display: block; }
                  .fairdee-wizard .wrapper .content-wrap .step .content .row .col .button-text-wrap.block p.info-text {
                    margin: 0; }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col .button-text-wrap .button {
                  min-width: 120px;
                  text-transform: none;
                  position: relative; }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col .button-text-wrap p.info-text {
                  font-size: 12px;
                  font-weight: normal;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  color: #5f5f5f;
                  flex-grow: 1;
                  margin-left: 12px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col .ui.checkbox input:checked ~ label:after {
                border: solid 2px #ff9800;
                background: #ff9800; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col .ui.radio.checkbox input:checked ~ label:before {
                border: solid 2px #ff9800; }
          .fairdee-wizard .wrapper .content-wrap .step .content .button-wrap {
            border-top: 1px solid rgba(151, 151, 151, 0.4);
            padding-top: 12px;
            text-align: right; }
            .fairdee-wizard .wrapper .content-wrap .step .content .button-wrap.no-border {
              border: 0; }
            .fairdee-wizard .wrapper .content-wrap .step .content .button-wrap button {
              text-transform: none; }
              .fairdee-wizard .wrapper .content-wrap .step .content .button-wrap button.disabled {
                background-color: #bebebe;
                color: #000;
                pointer-events: none; }
              .fairdee-wizard .wrapper .content-wrap .step .content .button-wrap button.loading {
                pointer-events: none;
                opacity: 0.7; }
              .fairdee-wizard .wrapper .content-wrap .step .content .button-wrap button:not(:last-child) {
                margin-right: 12px; }
          .fairdee-wizard .wrapper .content-wrap .step .content .uploaded-file {
            border-radius: 2px;
            background-color: #ffeed2;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #5f5f5f;
            padding: 9px 12px;
            border: 1px solid #f5a623;
            margin: 0 12px 12px 0;
            display: inline-block;
            cursor: pointer;
            word-break: break-all;
            cursor: pointer; }
            .fairdee-wizard .wrapper .content-wrap .step .content .uploaded-file img {
              margin-left: 4px;
              display: inline-block;
              cursor: pointer;
              vertical-align: middle; }
              .fairdee-wizard .wrapper .content-wrap .step .content .uploaded-file img:hover {
                background: rgba(218, 212, 212, 0.2);
                border-radius: 100%; }
          .fairdee-wizard .wrapper .content-wrap .step .content .file-input {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
            width: 100%; }
          .fairdee-wizard .wrapper .content-wrap .step .content .form-field {
            margin-bottom: 8px; }
            .fairdee-wizard .wrapper .content-wrap .step .content .form-field.with-width {
              width: 150px; }
            .fairdee-wizard .wrapper .content-wrap .step .content .form-field .form-label {
              display: none; }
            .fairdee-wizard .wrapper .content-wrap .step .content .form-field .label-input {
              border: 1px solid #cbcbcb;
              border-radius: 2px;
              display: flex; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field .label-input input[type='text'],
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field .label-input input[type='number'] {
                border: 0; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field .label-input label {
                padding: 5px;
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #5f5f5f;
                border-right: 1px solid #cbcbcb; }
            .fairdee-wizard .wrapper .content-wrap .step .content .form-field > label.form-label {
              display: none; }
            .fairdee-wizard .wrapper .content-wrap .step .content .form-field > p {
              display: inline-block;
              vertical-align: middle;
              margin: 0 8px 0 0;
              font-size: 12px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.5;
              letter-spacing: normal;
              color: #5f5f5f;
              padding-left: 28px; }
            .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='text'],
            .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='number'] {
              border-radius: 2px;
              background-color: #ffffff;
              border: 1px solid #cbcbcb;
              padding: 5px 10px;
              font-size: 12px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.5;
              letter-spacing: normal;
              color: #5f5f5f;
              width: 100%;
              outline: none; }
          .fairdee-wizard .wrapper .content-wrap .step .content .ui.selection.dropdown {
            border: 1px solid #cbcbcb;
            background: transparent;
            height: auto;
            min-height: auto;
            padding: 5px 10px;
            border-radius: 2px;
            min-width: 150px; }
            .fairdee-wizard .wrapper .content-wrap .step .content .ui.selection.dropdown .menu {
              max-height: 180px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .ui.selection.dropdown .menu .item {
                padding: 8px !important; }
            .fairdee-wizard .wrapper .content-wrap .step .content .ui.selection.dropdown .text {
              font-size: 12px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.5;
              letter-spacing: normal;
              color: #5f5f5f; }
              .fairdee-wizard .wrapper .content-wrap .step .content .ui.selection.dropdown .text.default {
                color: #9b9b9b; }
            .fairdee-wizard .wrapper .content-wrap .step .content .ui.selection.dropdown .icon {
              padding: 6px 10px;
              margin: 0;
              top: 0;
              right: 0; }
    .fairdee-wizard .wrapper .card-wrap {
      min-width: 335px;
      max-width: 335px;
      border-radius: 6px;
      box-shadow: 1px 4px 6px 0 #e2e1e1;
      background-color: #ffffff; }
      .fairdee-wizard .wrapper .card-wrap .header {
        display: none; }
      .fairdee-wizard .wrapper .card-wrap .card {
        padding: 24px 16px; }
      .fairdee-wizard .wrapper .card-wrap .car-details-wrap {
        background-color: #f8f8f8;
        padding: 18px 16px; }
        .fairdee-wizard .wrapper .card-wrap .car-details-wrap p {
          font-size: 16px;
          color: #4a4a4a;
          font-family: 'sukh-medium';
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .fairdee-wizard .wrapper .card-wrap .car-details-wrap p .user-name {
            text-transform: capitalize; }
          .fairdee-wizard .wrapper .card-wrap .car-details-wrap p .car-desc {
            text-transform: uppercase; }
      .fairdee-wizard .wrapper .card-wrap .line {
        border-bottom: 1px solid rgba(151, 151, 151, 0.4); }
      .fairdee-wizard .wrapper .card-wrap .section:not(:last-child) {
        border-bottom: 1px solid rgba(151, 151, 151, 0.4); }
      .fairdee-wizard .wrapper .card-wrap .section .mb-6 {
        margin-bottom: 6px; }
      .fairdee-wizard .wrapper .card-wrap .section .mb-12 {
        margin-bottom: 12px; }
      .fairdee-wizard .wrapper .card-wrap .section .link-text {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #192383;
        cursor: pointer; }
      .fairdee-wizard .wrapper .card-wrap .section.one {
        padding-bottom: 24px;
        display: flex;
        border: 0; }
        .fairdee-wizard .wrapper .card-wrap .section.one .img-wrap {
          min-width: 70px;
          max-width: 70px;
          height: 54px;
          position: relative;
          display: flex;
          align-items: center; }
          .fairdee-wizard .wrapper .card-wrap .section.one .img-wrap:after {
            content: '';
            position: absolute;
            height: 32px;
            border-right: 1px solid rgba(151, 151, 151, 0.4);
            top: calc(50% - 16px);
            right: 0; }
          .fairdee-wizard .wrapper .card-wrap .section.one .img-wrap img {
            max-width: 54px;
            background: #e2e1e1; }
        .fairdee-wizard .wrapper .card-wrap .section.one .content {
          flex-grow: 1;
          padding-left: 16px; }
        .fairdee-wizard .wrapper .card-wrap .section.one .text-one {
          font-size: 16px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a; }
        .fairdee-wizard .wrapper .card-wrap .section.one .text-two {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a; }
        .fairdee-wizard .wrapper .card-wrap .section.one .text-three {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a; }
          .fairdee-wizard .wrapper .card-wrap .section.one .text-three span {
            font-size: 11px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #9b9b9b; }
      .fairdee-wizard .wrapper .card-wrap .section.two {
        padding: 12px 0 16px 0;
        border: 0; }
        .fairdee-wizard .wrapper .card-wrap .section.two .text-one {
          font-size: 18px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a; }
        .fairdee-wizard .wrapper .card-wrap .section.two .text-two {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a; }
        .fairdee-wizard .wrapper .card-wrap .section.two .flex {
          display: flex; }
          .fairdee-wizard .wrapper .card-wrap .section.two .flex span:last-child {
            flex-grow: 1;
            text-align: right; }
        .fairdee-wizard .wrapper .card-wrap .section.two .mb-8 {
          margin-bottom: 8px; }
      .fairdee-wizard .wrapper .card-wrap .section.three {
        padding-top: 24px;
        border-top: 1px solid #eee; }
        .fairdee-wizard .wrapper .card-wrap .section.three .line {
          padding-bottom: 24px;
          border-top: 1px solid #eee; }
        .fairdee-wizard .wrapper .card-wrap .section.three .text-one {
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #4a4a4a; }
        .fairdee-wizard .wrapper .card-wrap .section.three .text-two {
          font-size: 24px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #4a4a4a;
          flex-grow: 1;
          text-align: right; }
          .fairdee-wizard .wrapper .card-wrap .section.three .text-two span {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #4a4a4a; }
        .fairdee-wizard .wrapper .card-wrap .section.three .text-wrap {
          display: flex;
          align-items: top; }
        .fairdee-wizard .wrapper .card-wrap .section.three .link-text {
          text-align: right; }
        .fairdee-wizard .wrapper .card-wrap .section.three .mb-8 {
          margin-bottom: 8px; }
        .fairdee-wizard .wrapper .card-wrap .section.three .button-wrap {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 10px;
          margin-top: 24px; }
          .fairdee-wizard .wrapper .card-wrap .section.three .button-wrap.full-width {
            display: block; }
            .fairdee-wizard .wrapper .card-wrap .section.three .button-wrap.full-width .button {
              width: 100%; }
          .fairdee-wizard .wrapper .card-wrap .section.three .button-wrap .button {
            text-transform: none;
            padding-left: 4px;
            padding-right: 4px;
            position: relative;
            font-size: 10px; }
      .fairdee-wizard .wrapper .card-wrap .section.four {
        padding-top: 24px; }
        .fairdee-wizard .wrapper .card-wrap .section.four .text-one {
          font-size: 12px;
          font-family: 'sukh-regular';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #4a4a4a; }
        .fairdee-wizard .wrapper .card-wrap .section.four .text-two {
          font-size: 12px;
          font-family: 'sukh-regular';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          color: #4a4a4a; }
        .fairdee-wizard .wrapper .card-wrap .section.four .text-wrap {
          display: flex;
          align-items: center; }
        .fairdee-wizard .wrapper .card-wrap .section.four .link-text {
          text-align: right; }
        .fairdee-wizard .wrapper .card-wrap .section.four .mb-8 {
          margin-bottom: 8px; }
        .fairdee-wizard .wrapper .card-wrap .section.four .button-wrap {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 10px;
          margin-top: 24px; }
          .fairdee-wizard .wrapper .card-wrap .section.four .button-wrap.full-width {
            display: block; }
            .fairdee-wizard .wrapper .card-wrap .section.four .button-wrap.full-width .button {
              width: 100%; }
          .fairdee-wizard .wrapper .card-wrap .section.four .button-wrap .button {
            text-transform: none;
            padding-left: 4px;
            padding-right: 4px;
            position: relative;
            font-size: 10px; }
  .fairdee-wizard .error-messages-wrap {
    padding: 12px;
    box-shadow: 0 2px 12px 5px rgba(0, 0, 0, 0.04);
    background-color: #edd1d1; }
    .fairdee-wizard .error-messages-wrap p {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a; }
      .fairdee-wizard .error-messages-wrap p:not(:last-child) {
        margin-bottom: 8px; }
  .fairdee-wizard .info-text {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #5f5f5f; }
  .fairdee-wizard .verify-payment-proof-wrap {
    margin: 30px 0; }
  .fairdee-wizard .bold {
    font-size: 13px;
    font-family: 'sukh-bold' !important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a !important; }
  .fairdee-wizard .red {
    font-size: 13px;
    font-family: 'sukh-bold' !important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: red !important; }

.ui.popup.commission-popup {
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.06);
  background-color: #282c3f;
  border: solid 1px #282c3f;
  padding: 6px;
  z-index: 999;
  font-family: 'proxima-nova', sans-serif; }
  .ui.popup.commission-popup .text {
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #fff; }

.aff-address-card {
  margin: 13pt 0 0 11pt;
  font-family: 'Kanit-Regular';
  width: 62pt;
  height: 43pt; }
  .aff-address-card p {
    font-size: 3pt;
    color: #444;
    margin-bottom: 1pt;
    word-break: break-word;
    line-height: 1.2; }

@media screen and (max-width: 1024px) {
  .fairdee-wizard-wrapper {
    height: 100%;
    min-height: 100%; }
    .fairdee-wizard-wrapper .instalment-form-field {
      margin: 24px 0 24px 0; }
    .fairdee-wizard-wrapper .form-six .button-wrap {
      display: flex;
      align-items: center;
      box-shadow: 4px 2px 5px 0 #00000080;
      background-color: #ffffff;
      padding: 4px 8px; }
      .fairdee-wizard-wrapper .form-six .button-wrap .or {
        display: none; }
      .fairdee-wizard-wrapper .form-six .button-wrap .content {
        display: block;
        max-width: 35%; }
        .fairdee-wizard-wrapper .form-six .button-wrap .content .text {
          font-size: 20px;
          font-family: 'sukh-bold';
          color: #4a4a4a;
          margin-bottom: 4px; }
          .fairdee-wizard-wrapper .form-six .button-wrap .content .text span {
            font-size: 14px;
            font-family: 'sukh-regular'; }
        .fairdee-wizard-wrapper .form-six .button-wrap .content .see-details {
          font-size: 10px;
          font-family: 'sukh-regular';
          color: #192383; }
      .fairdee-wizard-wrapper .form-six .button-wrap > div,
      .fairdee-wizard-wrapper .form-six .button-wrap > button {
        flex-grow: 1;
        flex-basis: 100%; }
      .fairdee-wizard-wrapper .form-six .button-wrap button {
        border-radius: 2px;
        border: solid 1px #ff901d;
        background-color: #ff901d;
        font-size: 12px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        box-shadow: none;
        padding: 9px 16px; }
        .fairdee-wizard-wrapper .form-six .button-wrap button.text-black {
          color: #000; }
    .fairdee-wizard-wrapper div > .button-wrap .content {
      display: block; }
    .fairdee-wizard-wrapper .slick-slider {
      margin-bottom: 12px; }
    .fairdee-wizard-wrapper .wizard-banner {
      width: 100%;
      margin-bottom: 0;
      padding: 13px 32px 13px 130px;
      min-height: 60px; }
      .fairdee-wizard-wrapper .wizard-banner p {
        font-size: 10px; }
      .fairdee-wizard-wrapper .wizard-banner.left {
        padding-left: 22%; }
      .fairdee-wizard-wrapper .wizard-banner.right {
        padding-left: 25%; }
  .fairdee-wizard {
    height: 100%; }
    .fairdee-wizard .right-section {
      height: 100%; }
    .fairdee-wizard .mobile {
      display: block; }
    .fairdee-wizard .desktop {
      display: none; }
    .fairdee-wizard .wrapper {
      display: block; }
      .fairdee-wizard .wrapper .content-wrap {
        margin-right: 0; }
        .fairdee-wizard .wrapper .content-wrap .step {
          border-radius: 0;
          display: none; }
          .fairdee-wizard .wrapper .content-wrap .step.open {
            display: block; }
          .fairdee-wizard .wrapper .content-wrap .step.two, .fairdee-wizard .wrapper .content-wrap .step.three {
            padding-top: 0; }
          .fairdee-wizard .wrapper .content-wrap .step.two {
            margin: 0; }
            .fairdee-wizard .wrapper .content-wrap .step.two .content .col:last-child {
              padding-bottom: 16px; }
          .fairdee-wizard .wrapper .content-wrap .step .header {
            background: #f3f4f7;
            padding: 16px 24px; }
            .fairdee-wizard .wrapper .content-wrap .step .header span {
              display: none; }
          .fairdee-wizard .wrapper .content-wrap .step .content {
            padding: 16px 24px; }
            .fairdee-wizard .wrapper .content-wrap .step .content .button-wrap {
              border: 0; }
            .fairdee-wizard .wrapper .content-wrap .step .content .form-field {
              width: 100%;
              position: relative;
              margin-bottom: 35px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field ::-webkit-input-placeholder {
                /* WebKit browsers */
                color: transparent !important; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field :-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                color: transparent !important; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field ::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: transparent !important; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field :-ms-input-placeholder {
                /* Internet Explorer 10+ */
                color: transparent !important; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field textarea::-webkit-input-placeholder {
                /* WebKit browsers */
                color: transparent; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field textarea:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                color: transparent !important; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field textarea::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: transparent !important; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field textarea:-ms-input-placeholder {
                /* Internet Explorer 10+ */
                color: transparent !important; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='text'],
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='email'],
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='password'],
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='number'],
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='radio'],
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field .ui.selection.dropdown,
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field textarea {
                width: 100%;
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid rgba(151, 151, 151, 0.4);
                padding: 4px 0 !important; }
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='text']:focus,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='email']:focus,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='password']:focus,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='number']:focus,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='radio']:focus,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field .ui.selection.dropdown:focus,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field textarea:focus {
                  border: 0;
                  border-bottom: 1px solid rgba(151, 151, 151, 0.4);
                  border-radius: 0; }
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='text']:focus ~ label,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='email']:focus ~ label,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='password']:focus ~ label,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='number']:focus ~ label,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='radio']:focus ~ label,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field .ui.selection.dropdown:focus ~ label,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field textarea:focus ~ label {
                  bottom: 30px;
                  color: #4a4a4a;
                  font-size: 12px; }
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='text']:not(:placeholder-shown) ~ label,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='email']:not(:placeholder-shown) ~ label,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='password']:not(:placeholder-shown) ~ label,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='number']:not(:placeholder-shown) ~ label,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field input[type='radio']:not(:placeholder-shown) ~ label,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field .ui.selection.dropdown:not(:placeholder-shown) ~ label,
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field textarea:not(:placeholder-shown) ~ label {
                  bottom: 30px;
                  color: #4a4a4a;
                  font-size: 12px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field .inline-error {
                margin-left: 0;
                position: absolute;
                left: 0;
                top: 30px; }
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field .inline-error.top-offset {
                  top: 44px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field.mb-0 {
                margin-bottom: 0; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field > label.form-label {
                width: 100%;
                margin: 0 !important;
                position: absolute;
                bottom: 5px;
                z-index: 1;
                transition: 0.2s ease all;
                font-size: 14px;
                color: #9b9b9b;
                display: inline-block;
                left: 0;
                pointer-events: none; }
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field > label.form-label.move-up {
                  bottom: 35px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field .inline-label {
                font-size: 14px;
                color: #9b9b9b; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field .ui.input {
                width: 100% !important;
                margin-top: 20px; }
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field .ui.input input[type='text'],
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field .ui.input input[type='email'],
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field .ui.input input[type='password'],
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field .ui.input input[type='number'],
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field .ui.input input[type='radio'],
                .fairdee-wizard .wrapper .content-wrap .step .content .form-field .ui.input textarea {
                  width: 100%; }
              .fairdee-wizard .wrapper .content-wrap .step .content .form-field.with-width {
                width: 100%; }
            .fairdee-wizard .wrapper .content-wrap .step .content .row {
              display: block; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row.no-key .col:first-child {
                display: none; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col:first-child {
                max-width: 100%;
                width: 100%; }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col:first-child p {
                  margin-bottom: 12px; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col.with-border {
                border: 0; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col .button-text-wrap {
                display: block; }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col .button-text-wrap p.info-text {
                  margin: 8px 0 0; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col .datepicker {
                width: 100%;
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid rgba(151, 151, 151, 0.4); }
                .fairdee-wizard .wrapper .content-wrap .step .content .row .col .datepicker p {
                  display: none; }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col .form-field-wrap.age-group .ui.selection.dropdown {
                margin-left: 28px;
                width: calc(100% - 28px); }
              .fairdee-wizard .wrapper .content-wrap .step .content .row .col .form-field:last-child {
                margin: 0; }
      .fairdee-wizard .wrapper .card-wrap {
        min-width: 100%;
        max-width: 100%;
        padding: 0;
        padding-top: 24px;
        border-radius: 0; }
        .fairdee-wizard .wrapper .card-wrap .card {
          padding: 24px; }
        .fairdee-wizard .wrapper .card-wrap .car-details-wrap {
          padding: 16px 24px; }
        .fairdee-wizard .wrapper .card-wrap .header {
          background: #f3f4f7;
          padding: 16px 24px;
          display: block; }
          .fairdee-wizard .wrapper .card-wrap .header p {
            font-size: 12px;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 1px;
            color: #4a4a4a;
            text-transform: uppercase; } }

.qr-amount-type {
  background-color: #ffffff;
  padding: 20px; }
  .qr-amount-type .ant-radio-group {
    margin-bottom: 40px; }
  .qr-amount-type .button-wrapper {
    text-align: center; }
    .qr-amount-type .button-wrapper .button {
      width: 100%; }

.main-wrapper {
  background-color: #f3f4f7 !important;
  padding: 0;
  min-height: var(--vh); }
  .main-wrapper.no-background {
    background-color: #fff !important; }

.parent-wrapper {
  padding: 0 !important;
  flex-grow: 1; }

.gray-background {
  background-color: #f3f4f7 !important; }

.upload-documents-form .img-wrapper img {
  max-width: 100%; }

.upload-documents-form .key-value .key {
  font-size: 16px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-bottom: 12px;
  display: block; }

.refer-earn,
.upload-doc-bar {
  border-radius: 3px;
  box-shadow: 0 5px 8px 0 rgba(197, 197, 207, 0.78);
  background-color: #ffd092;
  padding: 26px 36px;
  display: flex;
  align-items: center;
  cursor: pointer; }
  .refer-earn img,
  .upload-doc-bar img {
    max-width: 50px;
    margin-right: 34px; }
  .refer-earn p,
  .upload-doc-bar p {
    font-size: 16px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a; }
    .refer-earn p span span,
    .upload-doc-bar p span span {
      font-family: 'sukh-medium'; }
    .refer-earn p span.link,
    .upload-doc-bar p span.link {
      font-family: 'sukh-medium';
      color: #f58b1e; }

.affiliate-landing {
  font-family: 'sukh-regular';
  max-width: 1200px;
  margin: 0 auto; }
  .affiliate-landing .landing-banner {
    display: flex;
    margin-bottom: 2rem; }
    .affiliate-landing .landing-banner div {
      flex-grow: 1;
      flex-basis: 100%; }
      .affiliate-landing .landing-banner div:first-child {
        margin-right: 10px; }
  .affiliate-landing .onboarding-trigger {
    cursor: pointer;
    font-size: 14px;
    font-family: 'sukh-bold';
    background-repeat: no-repeat;
    position: fixed;
    bottom: 20px;
    height: 50px;
    padding: 18px 0 0 56px;
    width: 350px;
    background-size: contain;
    background-image: url(images/video-thumbs/onboarding-video-trigger.png); }
  .affiliate-landing.member-view {
    text-align: left; }
    .affiliate-landing.member-view .title-text {
      font-size: 18px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      margin-bottom: 8px; }
    .affiliate-landing.member-view .buttons-wrap {
      margin-top: 24px;
      display: flex; }
      .affiliate-landing.member-view .buttons-wrap button {
        flex-grow: 1;
        flex-basis: 100%;
        border-radius: 6px;
        box-shadow: 1px 4px 6px 0 #e2e1e1;
        background-color: #ffffff;
        border: 0;
        font-size: 18px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        text-transform: capitalize;
        max-width: 280px; }
        .affiliate-landing.member-view .buttons-wrap button span {
          display: block;
          text-align: center; }
        .affiliate-landing.member-view .buttons-wrap button img {
          max-width: 72px;
          margin: 0 auto;
          display: block;
          margin-bottom: 24px; }
        .affiliate-landing.member-view .buttons-wrap button:not(:last-child) {
          margin-right: 32px; }
  .affiliate-landing .right-text {
    font-size: 18px;
    color: #4a4a4a;
    text-align: right;
    margin-top: 40px; }
  .affiliate-landing .buttons-wrapper {
    border-radius: 6px;
    box-shadow: 1px 4px 6px 0 #e2e1e1;
    background-color: #ffffff;
    padding: 16px; }
    .affiliate-landing .buttons-wrapper .title {
      font-size: 18px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      margin-bottom: 8px; }
    .affiliate-landing .buttons-wrapper button {
      padding: 12px 24px;
      font-size: 14px;
      font-family: 'sukh-bold';
      color: #ffffff;
      text-transform: none; }
      .affiliate-landing .buttons-wrapper button:not(:last-child) {
        margin-right: 24px; }
  .affiliate-landing .navigation-wrap .title-text {
    font-size: 16px;
    font-family: 'sukh-bold';
    line-height: 1.25;
    color: #000000;
    margin: 12px 0 12px 0;
    text-align: left; }
  .affiliate-landing .navigation-wrap .grid-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    align-items: center; }
    .affiliate-landing .navigation-wrap .grid-wrap .list {
      width: 100%;
      margin: 0; }
  .affiliate-landing .navigation-wrap .list-wrap {
    display: flex; }
  .affiliate-landing .navigation-wrap .list {
    border-radius: 6px;
    box-shadow: 1px 5px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    padding: 24px 30px 32px 30px;
    margin: 0 24px 0 0;
    flex-basis: 100%;
    cursor: pointer; }
    .affiliate-landing .navigation-wrap .list.active {
      border: 2px solid #f5a623; }
    .affiliate-landing .navigation-wrap .list:last-child {
      margin-right: 0; }
    .affiliate-landing .navigation-wrap .list p {
      font-size: 16px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      text-align: center; }
    .affiliate-landing .navigation-wrap .list .img-wrap {
      width: 72px;
      height: 75px;
      margin: 0 auto 20px auto; }
      .affiliate-landing .navigation-wrap .list .img-wrap img {
        width: 100%; }

.inline-filter-wrapper {
  border-radius: 2px;
  margin: 24px 0;
  display: flex;
  justify-content: flex-end;
  font-family: 'sukh-regular'; }
  .inline-filter-wrapper .download-button-wrap {
    display: inline-flex; }
  .inline-filter-wrapper .each-filter {
    background-color: #ffffff;
    position: relative;
    min-width: 200px;
    padding: 0 7px;
    border: 1px solid #d0d0d0;
    max-width: 240px; }
    .inline-filter-wrapper .each-filter.status > div {
      height: 100%;
      display: flex;
      align-items: center; }
    .inline-filter-wrapper .each-filter.no-border {
      border: 0;
      background: transparent;
      border-left: 1px solid #d0d0d0; }
    .inline-filter-wrapper .each-filter > label {
      font-size: 12px; }
    .inline-filter-wrapper .each-filter > div::before, .inline-filter-wrapper .each-filter > div::after {
      display: none; }
    .inline-filter-wrapper .each-filter:not(:last-child) {
      border-right: 0; }
    .inline-filter-wrapper .each-filter.tm-range {
      padding: 7px 15px;
      margin: 0; }
    .inline-filter-wrapper .each-filter.right-align .rdrDateRangePickerWrapper .rdrCalendarWrapper {
      right: -100%; }
    .inline-filter-wrapper .each-filter > div {
      width: 100%; }
    .inline-filter-wrapper .each-filter .custom-auto-complete {
      height: 100%; }
      .inline-filter-wrapper .each-filter .custom-auto-complete .list-wrap {
        top: 100%; }
      .inline-filter-wrapper .each-filter .custom-auto-complete > div {
        margin-top: 0;
        margin-bottom: 0; }
        .inline-filter-wrapper .each-filter .custom-auto-complete > div label {
          top: -13px;
          font-size: 14px;
          color: #8c93a4;
          font-family: 'sukh-medium'; }
        .inline-filter-wrapper .each-filter .custom-auto-complete > div label[data-shrink="true"] {
          top: 4px;
          font-size: 15px; }
        .inline-filter-wrapper .each-filter .custom-auto-complete > div div {
          margin-top: 24px;
          font-size: 14px;
          font-family: 'sukh-medium';
          text-align: center; }
          .inline-filter-wrapper .each-filter .custom-auto-complete > div div:before, .inline-filter-wrapper .each-filter .custom-auto-complete > div div:after {
            display: none; }
          .inline-filter-wrapper .each-filter .custom-auto-complete > div div input {
            padding: 0 4px 4px 0; }
    .inline-filter-wrapper .each-filter.search-input input {
      font-size: 14px;
      font-family: 'sukh-light';
      color: #8c93a4;
      background: url(images/search.svg) no-repeat;
      background-position: 12px;
      background-size: 20px;
      padding: 8px 8px 8px 36px;
      border: 0;
      outline: none; }
    .inline-filter-wrapper .each-filter.multiselect-filter {
      max-width: 600px; }
      .inline-filter-wrapper .each-filter.multiselect-filter .ant-select-selector {
        border: none; }
      .inline-filter-wrapper .each-filter.multiselect-filter .react-select-custom > div {
        border: none; }

.dropdown-filter {
  font-family: 'sukh-regular';
  width: 100%; }
  .dropdown-filter .title {
    font-size: 14px !important;
    font-family: 'sukh-light' !important;
    text-align: left !important;
    color: #8c93a4;
    text-align: right;
    margin: 0 !important; }
    .dropdown-filter .title img {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 7px;
      vertical-align: middle;
      object-fit: contain; }
  .dropdown-filter .text {
    font-size: 12px;
    font-family: 'sukh-bold';
    letter-spacing: 1px;
    color: #4a4a4a; }
  .dropdown-filter .payment-status-wrapper .text {
    margin: 8px 0;
    font-size: 12px; }
  .dropdown-filter .payment-status-wrapper .field:nth-child(5):after {
    content: 'จ่ายตรงเข้าแฟร์ดี';
    display: block;
    margin: 8px 0;
    font-size: 12px;
    font-family: 'sukh-bold';
    letter-spacing: 1px;
    color: #4a4a4a; }
  .dropdown-filter .popup {
    border-radius: 2px;
    box-shadow: 0 2px 9px 4px rgba(203, 203, 203, 0.5);
    background-color: #ffffff;
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 48px;
    z-index: 9; }
    .dropdown-filter .popup::-webkit-scrollbar {
      width: 1px; }
    .dropdown-filter .popup::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px #efefef;
      -webkit-box-shadow: inset 0 0 2px #efefef; }
    .dropdown-filter .popup::-webkit-scrollbar-thumb {
      background-color: #efefef;
      outline: 1px solid #efefef; }
    .dropdown-filter .popup .header {
      border-radius: 2px;
      background-color: #f5f5f5;
      padding: 10px; }
      .dropdown-filter .popup .header .text {
        font-size: 12px;
        font-family: 'sukh-bold';
        letter-spacing: 1px;
        color: #4a4a4a;
        text-transform: uppercase; }
    .dropdown-filter .popup .content {
      padding: 10px;
      display: flex; }
      .dropdown-filter .popup .content .col {
        min-width: 160px; }
        .dropdown-filter .popup .content .col:not(:first-child) {
          padding: 0 12px; }
      .dropdown-filter .popup .content .sub-text {
        font-size: 12px;
        font-family: 'sukh-medium';
        letter-spacing: 1px;
        color: #4a4a4a;
        text-transform: uppercase;
        margin-bottom: 12px; }
        .dropdown-filter .popup .content .sub-text.mt-12 {
          margin-top: 12px; }
      .dropdown-filter .popup .content .ui.checkbox label {
        font-size: 13px;
        text-align: left;
        color: #4a4a4a;
        padding-left: 20px; }

.tm-range {
  padding: 6px 10px;
  min-width: 200px;
  max-width: 200px;
  margin-right: 16px;
  font-family: 'sukh-regular';
  cursor: pointer; }
  .tm-range > div {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center; }
    .tm-range > div > img {
      width: 14px;
      margin-right: 8px;
      position: relative; }
    .tm-range > div > span {
      font-size: 12px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      padding-right: 8px; }
    .tm-range > div > p {
      font-size: 11px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      display: flex;
      align-items: center; }
      .tm-range > div > p span {
        padding-right: 2px;
        display: inline-block; }
  .tm-range .datetime-range-trigger {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    top: 0;
    z-index: 10;
    border: 1px solid #f5f0f0;
    border-radius: 4px; }
    .tm-range .datetime-range-trigger > div {
      display: inline-block;
      padding-left: 12px; }
    .tm-range .datetime-range-trigger .datetime-range-picker {
      padding: 12px; }
    .tm-range .datetime-range-trigger .btn {
      color: #fff;
      background-color: #5cc4ef;
      padding: 8px 16px;
      font-size: 12px;
      border: 0;
      border-radius: 4px;
      margin: 0 0 4px 0;
      outline: none;
      cursor: pointer; }
    .tm-range .datetime-range-trigger .datetime-range-picker {
      background: #fff;
      margin-top: 13px; }
    .tm-range .datetime-range-trigger .datetime-range-picker-panel .datetime-picker {
      border: 0;
      font-size: 12px;
      width: 200px; }
      .tm-range .datetime-range-trigger .datetime-range-picker-panel .datetime-picker .calendar table tbody tr td {
        height: 26px; }
      .tm-range .datetime-range-trigger .datetime-range-picker-panel .datetime-picker table {
        width: 90%; }
        .tm-range .datetime-range-trigger .datetime-range-picker-panel .datetime-picker table th {
          text-align: left;
          padding-bottom: 12px; }
      .tm-range .datetime-range-trigger .datetime-range-picker-panel .datetime-picker .calendar-nav {
        margin-bottom: 12px;
        display: flex;
        width: 90%;
        align-items: center; }
        .tm-range .datetime-range-trigger .datetime-range-picker-panel .datetime-picker .calendar-nav span {
          flex-grow: 1;
          text-align: center; }
    .tm-range .datetime-range-trigger .datetime-range-picker-panel .datetime-text {
      padding-bottom: 8px; }
      .tm-range .datetime-range-trigger .datetime-range-picker-panel .datetime-text .text-label,
      .tm-range .datetime-range-trigger .datetime-range-picker-panel .datetime-text .text-value {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.87); }
    .tm-range .datetime-range-trigger .fa-calendar {
      margin-left: 12px; }
  .tm-range .rdrDateDisplay {
    background-color: #fff; }
  .tm-range .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
    display: none; }
  .tm-range .rdrDateRangePickerWrapper .rdrCalendarWrapper {
    position: absolute;
    z-index: 9;
    top: 44px;
    left: 0;
    border: 1px solid #f5f0f0;
    border-radius: 4px; }
  .tm-range .text {
    font-size: 12px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.5px;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    display: inline-block;
    margin-right: 10px; }

.mobile-table-filter {
  padding: 0 !important;
  background: #ffffff !important;
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100%; }
  .mobile-table-filter .custom-auto-complete {
    padding: 0 24px; }
    .mobile-table-filter .custom-auto-complete input {
      font-size: 14px;
      color: #4a4a4a; }
    .mobile-table-filter .custom-auto-complete .list-wrap {
      width: calc(100% - 48px); }
  .mobile-table-filter .content-wrapper {
    padding-top: 40px !important; }
  .mobile-table-filter .dropdown-filter {
    max-width: 100%; }
    .mobile-table-filter .dropdown-filter .text {
      margin-bottom: 12px; }
    .mobile-table-filter .dropdown-filter .popup {
      box-shadow: none; }
      .mobile-table-filter .dropdown-filter .popup .header {
        background-color: #ffffff;
        box-shadow: none;
        padding: 10px 24px; }
        .mobile-table-filter .dropdown-filter .popup .header .text {
          font-size: 14px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #192383;
          text-transform: none; }
      .mobile-table-filter .dropdown-filter .popup .content {
        padding: 10px 24px; }
        .mobile-table-filter .dropdown-filter .popup .content .field {
          margin-bottom: 16px; }
        .mobile-table-filter .dropdown-filter .popup .content .ui.checkbox {
          width: 100%; }
          .mobile-table-filter .dropdown-filter .popup .content .ui.checkbox label {
            font-size: 14px;
            color: #4a4a4a;
            width: 100%;
            padding-left: 0; }
            .mobile-table-filter .dropdown-filter .popup .content .ui.checkbox label::before {
              left: auto;
              right: 0; }
            .mobile-table-filter .dropdown-filter .popup .content .ui.checkbox label:after {
              left: auto;
              right: 0; }
    .mobile-table-filter .dropdown-filter .content {
      padding: 10px;
      display: block; }
      .mobile-table-filter .dropdown-filter .content .col:not(:first-child) {
        padding: 0; }
  .mobile-table-filter .date-range-filter {
    padding: 10px 24px; }
    .mobile-table-filter .date-range-filter .text {
      font-size: 14px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383;
      text-transform: none;
      margin-bottom: 18px; }
    .mobile-table-filter .date-range-filter .row {
      display: flex;
      align-items: center;
      margin-bottom: 24px; }
      .mobile-table-filter .date-range-filter .row p {
        font-size: 14px;
        color: #4a4a4a; }
      .mobile-table-filter .date-range-filter .row .datepicker {
        flex-grow: 1;
        text-align: right;
        display: block; }
    .mobile-table-filter .date-range-filter .datepicker label {
      display: none; }
    .mobile-table-filter .date-range-filter .datepicker > div {
      max-width: 220px;
      margin: 0; }
      .mobile-table-filter .date-range-filter .datepicker > div input {
        font-size: 12px;
        color: #8b8b8b; }
      .mobile-table-filter .date-range-filter .datepicker > div .material-icons {
        font-size: 18px;
        color: #303990; }
  .mobile-table-filter .mobile-buttons-wrap {
    display: table;
    width: 100%;
    table-layout: fixed;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999; }
    .mobile-table-filter .mobile-buttons-wrap li {
      display: table-cell;
      vertical-align: middle;
      font-size: 14px;
      font-family: 'sukh-medium';
      text-align: center;
      color: #4a4a4a;
      text-transform: uppercase;
      padding: 18px 0;
      background: #ffffff;
      font-family: 'sukh-medium'; }
      .mobile-table-filter .mobile-buttons-wrap li:first-child {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); }
      .mobile-table-filter .mobile-buttons-wrap li:last-child {
        background: #f58b1e;
        color: #ffffff; }

@media screen and (max-width: 1024px) {
  .parent-wrapper {
    padding: 0 !important; }
  .upload-documents-form {
    padding: 24px; }
  .landing-carousel {
    margin-bottom: 16px; }
  .refer-earn,
  .upload-doc-bar {
    border-radius: 3px;
    box-shadow: 0 4px 10px 0 rgba(204, 204, 204, 0.34);
    background-color: #fff6e8;
    padding: 12px 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    min-height: 60px; }
    .refer-earn img,
    .upload-doc-bar img {
      max-width: 40px;
      margin-right: 16px; }
    .refer-earn p,
    .upload-doc-bar p {
      font-size: 14px; }
  .main-wrapper {
    padding: 0 !important;
    background-color: #f0f0f5 !important; }
  .dropdown-filter .popup {
    max-height: initial; }
  .affiliate-landing {
    padding: 16px;
    max-height: calc(var(--vh) - 104px);
    overflow-y: auto; }
    .affiliate-landing .onboarding-trigger {
      cursor: pointer;
      font-size: 14px;
      font-family: 'sukh-bold';
      background-repeat: no-repeat;
      position: fixed;
      bottom: 65px;
      height: 50px;
      padding: 18px 0 0 56px;
      width: 350px;
      background-size: contain;
      background-image: url(images/video-thumbs/onboarding-video-trigger.png); }
    .affiliate-landing .buttons-wrapper {
      border-radius: 0; }
      .affiliate-landing .buttons-wrapper .title {
        font-size: 14px;
        font-family: 'sukh-regular';
        line-height: 1.43;
        letter-spacing: normal;
        color: #5e5e5e;
        text-align: center; }
      .affiliate-landing .buttons-wrapper button {
        width: 100%; }
        .affiliate-landing .buttons-wrapper button:not(:last-child) {
          margin-right: 8px; }
    .affiliate-landing .navigation-wrap .title-text {
      font-size: 14px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      margin: 12px 0 12px 0; }
    .affiliate-landing .navigation-wrap .grid-wrap {
      display: block; }
      .affiliate-landing .navigation-wrap .grid-wrap .list {
        border-radius: 2px;
        border: solid 1px #ff901d;
        background-color: #ffffff;
        padding: 12px 24px;
        margin: 0 0 4px 0;
        box-shadow: none;
        display: flex;
        align-items: center; }
        .affiliate-landing .navigation-wrap .grid-wrap .list > div:first-child {
          flex-grow: 1; }
        .affiliate-landing .navigation-wrap .grid-wrap .list > div:last-child {
          max-width: 60px; }
        .affiliate-landing .navigation-wrap .grid-wrap .list p {
          font-size: 14px;
          font-family: 'sukh-regular';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a;
          text-align: left; }
        .affiliate-landing .navigation-wrap .grid-wrap .list .img-wrap {
          width: 45px;
          height: 47px;
          margin: 0 auto 12px auto; }
          .affiliate-landing .navigation-wrap .grid-wrap .list .img-wrap img {
            width: 100%; } }

.fairdee-form-wrapper {
  display: flex;
  max-width: 1080px;
  margin: 60px auto auto; }
  .fairdee-form-wrapper .printing-preference-layout .title-wrapper {
    margin: 12px 0 12px 0; }
  .fairdee-form-wrapper .printing-preference-layout .print-preference-label {
    color: #2f2f2f; }
  .fairdee-form-wrapper .printing-preference-layout .radio-group {
    display: contents; }
  .fairdee-form-wrapper .divider-wrapper {
    margin: 12px 0 24px 0; }
  .fairdee-form-wrapper.fairdee-wizard .left-section {
    max-width: 50%;
    min-width: 35%; }
    .fairdee-form-wrapper.fairdee-wizard .left-section .content .mobile-card {
      padding: 0; }
    .fairdee-form-wrapper.fairdee-wizard .left-section .content .card-wrap {
      background: transparent; }
      .fairdee-form-wrapper.fairdee-wizard .left-section .content .card-wrap .card {
        background: #fff; }
  .fairdee-form-wrapper.fairdee-wizard .admin-actions {
    max-width: 300px; }
    .fairdee-form-wrapper.fairdee-wizard .admin-actions .button-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      margin-top: 24px; }
      .fairdee-form-wrapper.fairdee-wizard .admin-actions .button-wrap button {
        text-transform: none;
        padding-left: 4px;
        padding-right: 4px;
        position: relative;
        font-size: 10px;
        color: #333; }
    .fairdee-form-wrapper.fairdee-wizard .admin-actions .key-value {
      margin-top: 24px; }
      .fairdee-form-wrapper.fairdee-wizard .admin-actions .key-value .key {
        color: rgba(0, 0, 0, 0.54);
        margin-bottom: 6px;
        font-size: 12px;
        font-family: 'sukh-regular'; }
      .fairdee-form-wrapper.fairdee-wizard .admin-actions .key-value .value {
        font-size: 18px;
        color: #4a4a4a; }
  .fairdee-form-wrapper.fairdee-wizard .fairdee-form .ui.selection.dropdown .dropdown.icon:before {
    background: url(images/arrow-down-white.svg) no-repeat !important;
    background-position: center;
    background-size: contain; }
  .fairdee-form-wrapper .payment-content {
    margin-top: 16px; }
    .fairdee-form-wrapper .payment-content .bank-details .text {
      font-size: 18px;
      font-family: 'sukh-bold';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #000000; }
    .fairdee-form-wrapper .payment-content .bank-details .subtitle-text {
      font-size: 16px;
      font-family: 'sukh-medium';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #000000; }
    .fairdee-form-wrapper .payment-content .bank-details .sub-text {
      font-size: 16px;
      font-family: 'sukh-medium';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #5b5858;
      margin-bottom: 2px; }
    .fairdee-form-wrapper .payment-content .bank-details .green-text {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #1daa8d; }
    .fairdee-form-wrapper .payment-content .text-one {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #5f5f5f;
      margin-bottom: 12px; }
    .fairdee-form-wrapper .payment-content .label-text {
      font-size: 16px;
      font-family: 'sukh-medium';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #5b5858;
      margin-bottom: 32px; }
    .fairdee-form-wrapper .payment-content .line-text {
      position: relative;
      opacity: 0.5;
      font-size: 12px;
      font-family: 'sukh-bold';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #4b4b4b;
      margin-bottom: 8px; }
      .fairdee-form-wrapper .payment-content .line-text span {
        display: inline-block;
        background: #fff;
        padding: 4px 4px 4px 0;
        position: relative;
        z-index: 2; }
      .fairdee-form-wrapper .payment-content .line-text:after {
        content: '';
        height: 1px;
        opacity: 0.5;
        background-color: #a5a5a5;
        position: absolute;
        left: 0;
        width: 100%;
        top: 50%; }
    .fairdee-form-wrapper .payment-content div.with-border {
      margin: 20px 0 24px 0; }
      .fairdee-form-wrapper .payment-content div.with-border p {
        opacity: 0.8;
        font-size: 16px;
        font-family: 'sukh-medium';
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 4px; }
  .fairdee-form-wrapper.with-header {
    margin: 130px auto auto;
    padding-bottom: 32px; }
  .fairdee-form-wrapper .left-section {
    flex-grow: 1;
    flex-basis: 100%;
    border-right: 1px solid rgba(151, 151, 151, 0.2); }
    .fairdee-form-wrapper .left-section .text-one {
      font-size: 16px;
      font-family: 'sukh-bold';
      color: #000000;
      margin-bottom: 4px; }
    .fairdee-form-wrapper .left-section .text-two {
      font-size: 18px;
      font-family: 'sukh-medium';
      color: #000000;
      margin-bottom: 16px; }
    .fairdee-form-wrapper .left-section .text-three {
      font-size: 24px;
      font-family: 'sukh-bold';
      color: #1d2785;
      margin-bottom: 32px; }
    .fairdee-form-wrapper .left-section .card {
      font-family: 'sukh-regular';
      position: relative;
      max-width: 350px;
      background: #fff; }
      .fairdee-form-wrapper .left-section .card .header {
        padding: 10px 0;
        margin-bottom: 8px;
        display: flex;
        align-items: center; }
        .fairdee-form-wrapper .left-section .card .header .img-wrap {
          padding-right: 24px;
          position: relative; }
          .fairdee-form-wrapper .left-section .card .header .img-wrap:after {
            content: '';
            position: absolute;
            width: 1px;
            height: 20px;
            background: rgba(151, 151, 151, 0.4);
            right: 0;
            top: calc(50% - 10px); }
          .fairdee-form-wrapper .left-section .card .header .img-wrap img {
            max-height: 32px; }
        .fairdee-form-wrapper .left-section .card .header .details-wrap {
          flex-grow: 1;
          padding-left: 24px; }
          .fairdee-form-wrapper .left-section .card .header .details-wrap .text {
            font-size: 14px;
            font-family: 'sukh-bold';
            color: #4a4a4a;
            text-align: left; }
      .fairdee-form-wrapper .left-section .card .insurance-price {
        font-size: 20px;
        color: #4a4a4a;
        display: block;
        padding: 0;
        margin-bottom: 8px;
        font-family: 'sukh-medium'; }
        .fairdee-form-wrapper .left-section .card .insurance-price img {
          vertical-align: middle;
          margin-left: 8px; }
        .fairdee-form-wrapper .left-section .card .insurance-price span.number {
          font-size: 24px;
          font-family: 'sukh-bold';
          display: inline-block;
          padding: 0 4px; }
          .fairdee-form-wrapper .left-section .card .insurance-price span.number.no-strike {
            text-decoration: none; }
      .fairdee-form-wrapper .left-section .card .insurance-type {
        font-size: 20px;
        font-family: 'sukh-medium';
        color: #4a4a4a; }
      .fairdee-form-wrapper .left-section .card .line {
        width: 100%;
        margin: 24px auto;
        height: 1px;
        background: #e8e8e8; }
      .fairdee-form-wrapper .left-section .card .low-margin-line {
        margin-top: 8px; }
      .fairdee-form-wrapper .left-section .card .insurance-description {
        padding: 0 26px;
        margin: 0;
        list-style: none;
        margin-bottom: 20px; }
        .fairdee-form-wrapper .left-section .card .insurance-description li {
          padding: 0 0 0 16px;
          font-size: 16px;
          line-height: 1.75;
          color: #9b9b9b;
          position: relative;
          text-align: left; }
          .fairdee-form-wrapper .left-section .card .insurance-description li:before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 100%;
            position: absolute;
            left: 0;
            top: 12px;
            background: #9b9b9b; }
          .fairdee-form-wrapper .left-section .card .insurance-description li span {
            font-family: 'sukh-bold';
            color: #4a4a4a; }
            .fairdee-form-wrapper .left-section .card .insurance-description li span:first-child {
              padding-left: 4px; }
          .fairdee-form-wrapper .left-section .card .insurance-description li.see-details:before {
            display: none; }
          .fairdee-form-wrapper .left-section .card .insurance-description li.see-details p {
            font-size: 13.5px;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            cursor: pointer;
            color: #192383; }
      .fairdee-form-wrapper .left-section .card .discount-wrap {
        text-align: left; }
        .fairdee-form-wrapper .left-section .card .discount-wrap img {
          display: inline-block;
          vertical-align: middle;
          max-width: 17px;
          margin-right: 12px; }
        .fairdee-form-wrapper .left-section .card .discount-wrap span {
          display: inline-block;
          vertical-align: middle;
          font-size: 16px; }
          .fairdee-form-wrapper .left-section .card .discount-wrap span.key {
            font-family: 'sukh-bold';
            color: #3b8fae;
            margin-right: 4px; }
          .fairdee-form-wrapper .left-section .card .discount-wrap span.value {
            color: #414141; }
  .fairdee-form-wrapper .right-section {
    flex-grow: 1;
    flex-basis: 100%;
    padding: 0 46px;
    position: relative; }
    .fairdee-form-wrapper .right-section .form-completed {
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 60px; }
      .fairdee-form-wrapper .right-section .form-completed > div {
        margin-top: -24px;
        width: 100%; }
      .fairdee-form-wrapper .right-section .form-completed .buttons-wrap {
        margin-top: 24px; }
        .fairdee-form-wrapper .right-section .form-completed .buttons-wrap.download-policy button {
          min-width: 130px; }
          .fairdee-form-wrapper .right-section .form-completed .buttons-wrap.download-policy button:first-child {
            margin-right: 10px; }
      .fairdee-form-wrapper .right-section .form-completed .text {
        font-size: 18px;
        font-family: 'sukh-medium';
        text-align: center;
        color: #4a4a4a;
        margin-top: 12px; }
    .fairdee-form-wrapper .right-section .error-messages-wrap {
      padding: 8px;
      box-shadow: 0 2px 12px 5px rgba(0, 0, 0, 0.04);
      background-color: #edd1d1;
      margin-bottom: 12px;
      max-width: 334px; }
      .fairdee-form-wrapper .right-section .error-messages-wrap p {
        font-size: 12px;
        color: #4a4a4a; }
        .fairdee-form-wrapper .right-section .error-messages-wrap p:not(:last-child) {
          margin-bottom: 4px; }
    .fairdee-form-wrapper .right-section .uploaded-file {
      word-break: break-all; }
    .fairdee-form-wrapper .right-section .steps-wrap {
      margin-bottom: 24px; }
      .fairdee-form-wrapper .right-section .steps-wrap .progress-bar {
        height: 5px;
        border-radius: 4px;
        background-color: rgba(255, 144, 29, 0.44);
        max-width: calc(100% - 24px);
        margin: 0 auto 12px auto;
        position: relative;
        overflow: hidden;
        margin-top: 12px; }
        .fairdee-form-wrapper .right-section .steps-wrap .progress-bar .progress {
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          border-radius: 4px;
          background-color: #ff901d;
          margin-left: -24px; }
      .fairdee-form-wrapper .right-section .steps-wrap .flex-wrap {
        display: flex;
        align-items: flex-start; }
      .fairdee-form-wrapper .right-section .steps-wrap .step {
        font-size: 14px;
        font-family: 'sukh-medium';
        color: #9b9b9b;
        position: relative;
        padding: 0 6px;
        cursor: pointer;
        line-height: 2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .fairdee-form-wrapper .right-section .steps-wrap .step.active {
          color: #417505;
          background: url(images/green-tick.svg) no-repeat;
          background-position: 4px 8px;
          background-size: 16px;
          padding-left: 24px !important; }
        .fairdee-form-wrapper .right-section .steps-wrap .step.current {
          color: #1d2785; }
        .fairdee-form-wrapper .right-section .steps-wrap .step:first-child {
          padding-left: 0; }
    .fairdee-form-wrapper .right-section .title-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px; }
      .fairdee-form-wrapper .right-section .title-bar .title-wrap .text-one {
        font-size: 16px;
        font-family: 'sukh-bold';
        color: #1d2785;
        margin-bottom: 8px; }
      .fairdee-form-wrapper .right-section .title-bar .title-wrap .text-two {
        font-size: 18px;
        font-family: 'sukh-medium';
        color: #4a4a4a; }
      .fairdee-form-wrapper .right-section .title-bar .title-wrap.warning {
        padding: 0; }
        .fairdee-form-wrapper .right-section .title-bar .title-wrap.warning ul {
          list-style: disc;
          padding-left: 20px; }
    .fairdee-form-wrapper .right-section .reward-card {
      background: url(images/hand-cash.svg) no-repeat;
      padding: 12px 0;
      margin-bottom: 24px;
      display: flex;
      align-items: center; }
      .fairdee-form-wrapper .right-section .reward-card .text-wrap {
        background: url(images/rectangle-text-wrap.svg) no-repeat;
        padding: 5px 30px 5px 8px;
        text-align: left;
        max-width: 260px;
        background-size: contain;
        height: 48px; }
        .fairdee-form-wrapper .right-section .reward-card .text-wrap .text-one {
          font-size: 12px;
          font-family: 'sukh-medium';
          color: #ffffff;
          margin-bottom: 2px;
          line-height: 1.2;
          word-break: break-all; }
          .fairdee-form-wrapper .right-section .reward-card .text-wrap .text-one span {
            color: #f58b1e;
            font-family: 'sukh-bold'; }
        .fairdee-form-wrapper .right-section .reward-card .text-wrap .text-two {
          font-size: 10px;
          font-family: 'sukh-medium';
          color: #ffffff;
          line-height: 1.2; }
          .fairdee-form-wrapper .right-section .reward-card .text-wrap .text-two span {
            font-family: 'sukh-bold';
            color: #fff; }
    .fairdee-form-wrapper .right-section .title-text {
      font-size: 14px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      margin-bottom: 16px; }
  .fairdee-form-wrapper .button-wrap {
    width: 100%; }
    .fairdee-form-wrapper .button-wrap button {
      font-size: 14px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.5px;
      color: white;
      padding: 12px;
      cursor: pointer;
      width: 100%;
      max-width: 334px;
      text-transform: none; }
      .fairdee-form-wrapper .button-wrap button.text-black {
        color: #000; }
  .fairdee-form-wrapper .fairdee-form {
    max-width: 360px; }
    .fairdee-form-wrapper .fairdee-form .print-btn {
      position: absolute;
      right: 0;
      padding: 8px 12px;
      font-size: 10px; }
    .fairdee-form-wrapper .fairdee-form ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      text-transform: capitalize !important;
      color: transparent !important;
      text-transform: capitalize; }
    .fairdee-form-wrapper .fairdee-form ::-moz-placeholder {
      /* Firefox 19+ */
      text-transform: capitalize !important;
      color: transparent !important;
      text-transform: capitalize; }
    .fairdee-form-wrapper .fairdee-form :-ms-input-placeholder {
      /* IE 10+ */
      text-transform: capitalize !important;
      color: transparent !important;
      text-transform: capitalize; }
    .fairdee-form-wrapper .fairdee-form :-moz-placeholder {
      /* Firefox 18- */
      text-transform: capitalize !important;
      color: transparent !important;
      text-transform: capitalize; }
    .fairdee-form-wrapper .fairdee-form input[type='number']::-webkit-inner-spin-button,
    .fairdee-form-wrapper .fairdee-form input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .fairdee-form-wrapper .fairdee-form input[type='number'] {
      -moz-appearance: textfield; }
    .fairdee-form-wrapper .fairdee-form input[type='color']:disabled,
    .fairdee-form-wrapper .fairdee-form input[type='date']:disabled,
    .fairdee-form-wrapper .fairdee-form input[type='datetime']:disabled,
    .fairdee-form-wrapper .fairdee-form input[type='datetime-local']:disabled,
    .fairdee-form-wrapper .fairdee-form input[type='email']:disabled,
    .fairdee-form-wrapper .fairdee-form input[type='month']:disabled,
    .fairdee-form-wrapper .fairdee-form input[type='password']:disabled,
    .fairdee-form-wrapper .fairdee-form input[type='number']:disabled,
    .fairdee-form-wrapper .fairdee-form input[type='search']:disabled,
    .fairdee-form-wrapper .fairdee-form input[type='tel']:disabled,
    .fairdee-form-wrapper .fairdee-form input[type='time']:disabled,
    .fairdee-form-wrapper .fairdee-form input[type='url']:disabled,
    .fairdee-form-wrapper .fairdee-form input[type='week']:disabled {
      background-color: transparent; }
    .fairdee-form-wrapper .fairdee-form input[type='text']:disabled {
      background-color: #f2f3f5;
      margin-top: 7px; }
    .fairdee-form-wrapper .fairdee-form .radio-wrap legend {
      font-size: 12px;
      color: #9b9b9b; }
    .fairdee-form-wrapper .fairdee-form .radio-wrap span:last-child {
      font-size: 12px;
      color: #9b9b9b; }
    .fairdee-form-wrapper .fairdee-form .custom-datepicker .picker-title {
      color: #f58b1e;
      font-size: 12px;
      font-family: 'sukh-bold';
      margin-bottom: 6px; }
    .fairdee-form-wrapper .fairdee-form .form-field {
      margin-bottom: 30px;
      position: relative; }
      .fairdee-form-wrapper .fairdee-form .form-field.errors {
        margin-bottom: 40px; }
        .fairdee-form-wrapper .fairdee-form .form-field.errors input[type='text'],
        .fairdee-form-wrapper .fairdee-form .form-field.errors input[type='email'],
        .fairdee-form-wrapper .fairdee-form .form-field.errors input[type='password'],
        .fairdee-form-wrapper .fairdee-form .form-field.errors input[type='number'],
        .fairdee-form-wrapper .fairdee-form .form-field.errors textarea,
        .fairdee-form-wrapper .fairdee-form .form-field.errors .ui.selection.dropdown,
        .fairdee-form-wrapper .fairdee-form .form-field.errors .datepicker > div:before {
          border-bottom: 1px solid #d8001a; }
      .fairdee-form-wrapper .fairdee-form .form-field > div {
        margin: 0; }
        .fairdee-form-wrapper .fairdee-form .form-field > div label[data-shrink='false'] {
          font-size: 14px;
          color: #9b9b9b;
          font-family: 'sukh-regular'; }
        .fairdee-form-wrapper .fairdee-form .form-field > div > div:before, .fairdee-form-wrapper .fairdee-form .form-field > div > div:after {
          border-bottom: 1px solid #f3f3f3; }
        .fairdee-form-wrapper .fairdee-form .form-field > div > div div div {
          font-size: 14px; }
        .fairdee-form-wrapper .fairdee-form .form-field > div > div:hover:before, .fairdee-form-wrapper .fairdee-form .form-field > div > div:hover:after {
          border-bottom: 1px solid #f3f3f3 !important; }
      .fairdee-form-wrapper .fairdee-form .form-field.no-margin {
        margin: 0; }
      .fairdee-form-wrapper .fairdee-form .form-field .ui.checkbox input:checked ~ label:after {
        border: solid 2px #ff9800;
        background: #ff9800; }
      .fairdee-form-wrapper .fairdee-form .form-field .ui.radio.checkbox input:checked ~ label:before {
        border: solid 2px #ff9800; }
      .fairdee-form-wrapper .fairdee-form .form-field .inline.fields {
        display: flex;
        padding-top: 16px; }
        .fairdee-form-wrapper .fairdee-form .form-field .inline.fields .field:first-child {
          margin-right: 30px; }
        .fairdee-form-wrapper .fairdee-form .form-field .inline.fields .field label {
          padding-left: 32px; }
          .fairdee-form-wrapper .fairdee-form .form-field .inline.fields .field label:before, .fairdee-form-wrapper .fairdee-form .form-field .inline.fields .field label:after {
            top: 0; }
      .fairdee-form-wrapper .fairdee-form .form-field .inline-error {
        position: absolute;
        left: 0;
        bottom: -20px; }
        .fairdee-form-wrapper .fairdee-form .form-field .inline-error.duplicate-phone {
          bottom: -30px; }
      .fairdee-form-wrapper .fairdee-form .form-field > label {
        width: 100%;
        margin: 0 !important;
        position: absolute;
        top: 16px;
        z-index: 1;
        transition: 0.2s ease all;
        font-size: 14px;
        color: #9b9b9b;
        display: inline-block;
        left: 0;
        pointer-events: none;
        line-height: 1; }
        .fairdee-form-wrapper .fairdee-form .form-field > label.open {
          top: -6px;
          left: 0;
          padding: 0;
          font-size: 12px;
          display: inline-block;
          width: auto; }
      .fairdee-form-wrapper .fairdee-form .form-field .datepicker {
        width: 100%;
        position: relative; }
        .fairdee-form-wrapper .fairdee-form .form-field .datepicker label {
          color: #9b9b9b;
          font-family: 'sukh-regular'; }
        .fairdee-form-wrapper .fairdee-form .form-field .datepicker > div input[type='text'] {
          padding-bottom: 2px; }
        .fairdee-form-wrapper .fairdee-form .form-field .datepicker > div:hover:after {
          border-bottom: 1px solid #f3f3f3; }
        .fairdee-form-wrapper .fairdee-form .form-field .datepicker > div:hover:before {
          border-bottom: 1px solid #f3f3f3; }
        .fairdee-form-wrapper .fairdee-form .form-field .datepicker > div:after {
          border-bottom: 1px solid #f3f3f3; }
        .fairdee-form-wrapper .fairdee-form .form-field .datepicker > div:before {
          border-bottom: 1px solid #f3f3f3; }
    .fairdee-form-wrapper .fairdee-form .inspection-option {
      margin-bottom: 24px; }
    .fairdee-form-wrapper .fairdee-form .file-upload-wrap {
      padding-bottom: 32px;
      margin-bottom: 24px; }
      .fairdee-form-wrapper .fairdee-form .file-upload-wrap .img-wrapper img {
        max-width: 100%; }
      .fairdee-form-wrapper .fairdee-form .file-upload-wrap.only-files {
        margin-bottom: 0;
        padding-bottom: 0; }
        .fairdee-form-wrapper .fairdee-form .file-upload-wrap.only-files:not(:last-child) {
          border-bottom: 0; }
      .fairdee-form-wrapper .fairdee-form .file-upload-wrap .img {
        max-width: 312px; }
      .fairdee-form-wrapper .fairdee-form .file-upload-wrap:not(:last-child) {
        border-bottom: 1px solid #eeeeee; }
      .fairdee-form-wrapper .fairdee-form .file-upload-wrap button {
        min-width: 110px;
        text-transform: none;
        font-family: 'sukh-bold'; }
        .fairdee-form-wrapper .fairdee-form .file-upload-wrap button.disabled {
          opacity: 0.9; }
      .fairdee-form-wrapper .fairdee-form .file-upload-wrap .inline-wrap > a,
      .fairdee-form-wrapper .fairdee-form .file-upload-wrap .inline-wrap > div {
        display: inline-block;
        vertical-align: top;
        text-decoration: none;
        margin-bottom: 12px; }
      .fairdee-form-wrapper .fairdee-form .file-upload-wrap .inline-wrap a {
        margin-right: 8px;
        text-transform: capitalize; }
      .fairdee-form-wrapper .fairdee-form .file-upload-wrap .block-element {
        display: block !important; }
      .fairdee-form-wrapper .fairdee-form .file-upload-wrap .text {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #5f5f5f; }
        .fairdee-form-wrapper .fairdee-form .file-upload-wrap .text.secondary {
          color: #f58b1e;
          text-decoration: underline; }
      .fairdee-form-wrapper .fairdee-form .file-upload-wrap .mb-32 {
        margin-bottom: 32px; }
      .fairdee-form-wrapper .fairdee-form .file-upload-wrap .mb-8 {
        margin-bottom: 8px; }
    .fairdee-form-wrapper .fairdee-form .ui.selection.dropdown {
      border: 0;
      padding: 12px 0 6px 0;
      font-size: 14px;
      outline: none;
      width: 100%;
      text-transform: none;
      border-bottom: 1px solid #f3f3f3;
      border-radius: 0;
      min-height: auto;
      line-height: 1; }
      .fairdee-form-wrapper .fairdee-form .ui.selection.dropdown input {
        padding-left: 0; }
      .fairdee-form-wrapper .fairdee-form .ui.selection.dropdown .dropdown.icon {
        bottom: 14px;
        top: auto; }
        .fairdee-form-wrapper .fairdee-form .ui.selection.dropdown .dropdown.icon:before {
          content: '';
          background: url(images/arrow-down.svg) no-repeat !important;
          background-position: center;
          background-size: contain;
          width: 12px;
          height: 12px;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto; }
      .fairdee-form-wrapper .fairdee-form .ui.selection.dropdown:hover, .fairdee-form-wrapper .fairdee-form .ui.selection.dropdown.visible {
        box-shadow: none;
        border-radius: 0 !important;
        background: transparent; }
      .fairdee-form-wrapper .fairdee-form .ui.selection.dropdown .menu.visible {
        border: 1px solid #f3f3f3; }
      .fairdee-form-wrapper .fairdee-form .ui.selection.dropdown .text {
        font-size: 14px;
        color: #1b1b1b; }
        .fairdee-form-wrapper .fairdee-form .ui.selection.dropdown .text.default {
          color: #9b9b9b; }
    .fairdee-form-wrapper .fairdee-form .selection-controls label span:last-child {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal; }
    .fairdee-form-wrapper .fairdee-form .details-text {
      font-size: 18px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.1px;
      text-align: left;
      margin: 8px 0 24px 0;
      max-width: 80%;
      cursor: pointer; }
      .fairdee-form-wrapper .fairdee-form .details-text span {
        font-size: 18px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.1px;
        text-align: center; }
      .fairdee-form-wrapper .fairdee-form .details-text img {
        display: inline-block;
        margin-left: 8px; }
    .fairdee-form-wrapper .fairdee-form input,
    .fairdee-form-wrapper .fairdee-form textarea {
      border: 0;
      padding: 12px 0 8px 0;
      font-size: 14px;
      outline: none;
      width: 100%;
      text-transform: none;
      border-bottom: 1px solid #f3f3f3;
      color: #1b1b1b; }
      .fairdee-form-wrapper .fairdee-form input:focus ~ label,
      .fairdee-form-wrapper .fairdee-form textarea:focus ~ label {
        top: -4px;
        font-size: 12px;
        padding: 0;
        left: 0;
        display: inline-block;
        width: auto; }
      .fairdee-form-wrapper .fairdee-form input:not(:placeholder-shown) ~ label,
      .fairdee-form-wrapper .fairdee-form textarea:not(:placeholder-shown) ~ label {
        top: -4px;
        left: 0;
        padding: 0;
        font-size: 12px;
        display: inline-block;
        width: auto; }
      .fairdee-form-wrapper .fairdee-form input:not([value='']) ~ label,
      .fairdee-form-wrapper .fairdee-form textarea:not([value='']) ~ label {
        top: -4px;
        left: 0;
        padding: 0;
        font-size: 12px;
        display: inline-block;
        width: auto; }
    .fairdee-form-wrapper .fairdee-form textarea {
      resize: none; }
  .fairdee-form-wrapper .mobile-card {
    padding: 0; }
    .fairdee-form-wrapper .mobile-card .with-padding {
      padding: 0 24px 60px 24px; }
    .fairdee-form-wrapper .mobile-card .button-wrap {
      margin-top: 12px; }
      .fairdee-form-wrapper .mobile-card .button-wrap.inline {
        display: flex;
        align-items: center; }
        .fairdee-form-wrapper .mobile-card .button-wrap.inline :first-child {
          margin-right: 8px; }
    .fairdee-form-wrapper .mobile-card .text-one {
      font-size: 14px;
      font-family: 'sukh-bold';
      color: #000000;
      margin-bottom: 4px; }
    .fairdee-form-wrapper .mobile-card .text-two {
      font-size: 14px;
      font-family: 'sukh-medium';
      color: #000000;
      margin-bottom: 12px; }
    .fairdee-form-wrapper .mobile-card .text-three {
      font-size: 18px;
      font-family: 'sukh-bold';
      color: #1d2785;
      margin-bottom: 10px;
      text-transform: capitalize; }
    .fairdee-form-wrapper .mobile-card .text-four {
      font-size: 14px;
      font-family: 'sukh-medium';
      line-height: 1.43;
      color: #4a4a4a;
      margin-top: 16px; }
  .fairdee-form-wrapper .card-wrap {
    width: 100%;
    border-radius: 6px;
    background-color: #ffffff; }
    .fairdee-form-wrapper .card-wrap .checkbox-wrap {
      padding: 0 12px 12px; }
    .fairdee-form-wrapper .card-wrap .header {
      display: none; }
    .fairdee-form-wrapper .card-wrap .card {
      padding: 0; }
    .fairdee-form-wrapper .card-wrap .car-details-wrap {
      background-color: #f8f8f8;
      padding: 18px 16px; }
      .fairdee-form-wrapper .card-wrap .car-details-wrap p {
        font-size: 16px;
        color: #4a4a4a;
        font-family: 'sukh-medium';
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .fairdee-form-wrapper .card-wrap .car-details-wrap p .user-name {
          text-transform: capitalize; }
        .fairdee-form-wrapper .card-wrap .car-details-wrap p .car-desc {
          text-transform: uppercase; }
    .fairdee-form-wrapper .card-wrap .section {
      padding: 10px 24px 10px 24px; }
      .fairdee-form-wrapper .card-wrap .section:not(:last-child) {
        border-bottom: 1px solid #eee; }
      .fairdee-form-wrapper .card-wrap .section .mb-6 {
        margin-bottom: 6px; }
      .fairdee-form-wrapper .card-wrap .section .mb-12 {
        margin-bottom: 12px; }
      .fairdee-form-wrapper .card-wrap .section .link-text {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #192383;
        cursor: pointer; }
      .fairdee-form-wrapper .card-wrap .section.one {
        padding-bottom: 24px;
        display: flex; }
        .fairdee-form-wrapper .card-wrap .section.one .img-wrap {
          min-width: 70px;
          max-width: 70px;
          height: 54px;
          position: relative;
          display: flex;
          align-items: center; }
          .fairdee-form-wrapper .card-wrap .section.one .img-wrap:after {
            content: '';
            position: absolute;
            height: 32px;
            border-right: 1px solid rgba(151, 151, 151, 0.4);
            top: calc(50% - 16px);
            right: 0; }
          .fairdee-form-wrapper .card-wrap .section.one .img-wrap img {
            max-width: 54px;
            background: #e2e1e1; }
        .fairdee-form-wrapper .card-wrap .section.one .content {
          flex-grow: 1;
          padding-left: 16px; }
        .fairdee-form-wrapper .card-wrap .section.one .text-one {
          font-size: 16px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a; }
        .fairdee-form-wrapper .card-wrap .section.one .text-two {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a; }
        .fairdee-form-wrapper .card-wrap .section.one .text-three {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a; }
          .fairdee-form-wrapper .card-wrap .section.one .text-three span {
            font-size: 11px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #9b9b9b; }
      .fairdee-form-wrapper .card-wrap .section.aff-info-section {
        background-color: #fff2d6;
        border-radius: 10px; }
      .fairdee-form-wrapper .card-wrap .section.two, .fairdee-form-wrapper .card-wrap .section.aff-info-section {
        border: 0; }
        .fairdee-form-wrapper .card-wrap .section.two .manual-billing-button-wrap, .fairdee-form-wrapper .card-wrap .section.aff-info-section .manual-billing-button-wrap {
          display: flex;
          flex-direction: column; }
          .fairdee-form-wrapper .card-wrap .section.two .manual-billing-button-wrap .orange-bordered-button, .fairdee-form-wrapper .card-wrap .section.aff-info-section .manual-billing-button-wrap .orange-bordered-button {
            color: #f58b1e; }
        .fairdee-form-wrapper .card-wrap .section.two .text-one, .fairdee-form-wrapper .card-wrap .section.aff-info-section .text-one {
          font-size: 18px;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: #4a4a4a; }
        .fairdee-form-wrapper .card-wrap .section.two .text-two, .fairdee-form-wrapper .card-wrap .section.aff-info-section .text-two {
          font-size: 12px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a; }
          .fairdee-form-wrapper .card-wrap .section.two .text-two .text-action-wrap, .fairdee-form-wrapper .card-wrap .section.aff-info-section .text-two .text-action-wrap {
            color: #096dd9; }
            .fairdee-form-wrapper .card-wrap .section.two .text-two .text-action-wrap .text-four, .fairdee-form-wrapper .card-wrap .section.aff-info-section .text-two .text-action-wrap .text-four {
              font-weight: bolder;
              color: #096dd9;
              cursor: pointer; }
              .fairdee-form-wrapper .card-wrap .section.two .text-two .text-action-wrap .text-four .edit-icon, .fairdee-form-wrapper .card-wrap .section.aff-info-section .text-two .text-action-wrap .text-four .edit-icon {
                height: 14px;
                width: 12px; }
            .fairdee-form-wrapper .card-wrap .section.two .text-two .text-action-wrap .action-text, .fairdee-form-wrapper .card-wrap .section.aff-info-section .text-two .text-action-wrap .action-text {
              font-weight: bold;
              cursor: pointer; }
          .fairdee-form-wrapper .card-wrap .section.two .text-two.align-baseline, .fairdee-form-wrapper .card-wrap .section.aff-info-section .text-two.align-baseline {
            align-items: baseline; }
        .fairdee-form-wrapper .card-wrap .section.two .flex, .fairdee-form-wrapper .card-wrap .section.aff-info-section .flex {
          display: flex; }
          .fairdee-form-wrapper .card-wrap .section.two .flex span, .fairdee-form-wrapper .card-wrap .section.aff-info-section .flex span {
            font-family: 'sukh-medium'; }
            .fairdee-form-wrapper .card-wrap .section.two .flex span:last-child, .fairdee-form-wrapper .card-wrap .section.aff-info-section .flex span:last-child {
              flex-grow: 1;
              text-align: right; }
        .fairdee-form-wrapper .card-wrap .section.two .mb-8, .fairdee-form-wrapper .card-wrap .section.aff-info-section .mb-8 {
          margin-bottom: 8px; }
      .fairdee-form-wrapper .card-wrap .section.three .line {
        margin-bottom: 24px;
        border-top: 1px solid #eee; }
      .fairdee-form-wrapper .card-wrap .section.three .text-one {
        font-size: 12px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a;
        max-width: 160px;
        font-family: 'sukh-medium'; }
      .fairdee-form-wrapper .card-wrap .section.three .text-two {
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #4a4a4a;
        flex-grow: 1;
        text-align: right; }
      .fairdee-form-wrapper .card-wrap .section.three .text-wrap {
        display: flex;
        align-items: center; }
      .fairdee-form-wrapper .card-wrap .section.three .desc-wrap {
        background-color: #fff6e8;
        padding: 10px;
        text-align: left;
        margin-bottom: 24px; }
        .fairdee-form-wrapper .card-wrap .section.three .desc-wrap p {
          font-size: 11px;
          font-family: 'sukh-medium';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a;
          text-align: left;
          display: flex;
          margin-bottom: 4px;
          position: relative;
          padding-left: 20px; }
          .fairdee-form-wrapper .card-wrap .section.three .desc-wrap p:first-child:before {
            content: 'i';
            width: 11px;
            height: 11px;
            border-radius: 100%;
            background: #f5a623;
            position: absolute;
            color: #fff;
            text-align: center;
            line-height: 1.4;
            vertical-align: middle;
            left: 0;
            top: 4px;
            font-size: 9px;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg); }
      .fairdee-form-wrapper .card-wrap .section.three .link-text {
        text-align: right; }
      .fairdee-form-wrapper .card-wrap .section.three .mb-8 {
        margin-bottom: 8px; }
      .fairdee-form-wrapper .card-wrap .section.three .button-wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        margin-top: 24px; }
        .fairdee-form-wrapper .card-wrap .section.three .button-wrap.full-width {
          display: block; }
          .fairdee-form-wrapper .card-wrap .section.three .button-wrap.full-width .button {
            width: 100%; }
        .fairdee-form-wrapper .card-wrap .section.three .button-wrap .button {
          text-transform: none;
          padding-left: 4px;
          padding-right: 4px;
          position: relative; }
      .fairdee-form-wrapper .card-wrap .section.four .loud p:last-child {
        min-width: 110px; }
      .fairdee-form-wrapper .card-wrap .section.four .loud .text-one {
        font-size: 13px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        margin-right: 8px; }
      .fairdee-form-wrapper .card-wrap .section.four .loud .text-two {
        font-size: 16px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #4a4a4a; }
        .fairdee-form-wrapper .card-wrap .section.four .loud .text-two span {
          font-size: 13px;
          font-family: 'sukh-regular';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a; }
      .fairdee-form-wrapper .card-wrap .section.four .text-one {
        font-size: 12px;
        font-family: 'sukh-regular';
        color: #4a4a4a;
        max-width: calc(100% - 80px);
        margin: 0; }
      .fairdee-form-wrapper .card-wrap .section.four .text-two {
        font-size: 12px;
        font-family: 'sukh-regular';
        text-align: right;
        color: #4a4a4a;
        flex-grow: 1;
        text-align: right;
        margin: 0; }
        .fairdee-form-wrapper .card-wrap .section.four .text-two span {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #4a4a4a; }
      .fairdee-form-wrapper .card-wrap .section.four .text-wrap {
        display: flex;
        align-items: center; }
      .fairdee-form-wrapper .card-wrap .section.four .link-text {
        text-align: right; }
      .fairdee-form-wrapper .card-wrap .section.four .mb-8 {
        margin-bottom: 8px; }
      .fairdee-form-wrapper .card-wrap .section.four .button-wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        margin-top: 24px; }
        .fairdee-form-wrapper .card-wrap .section.four .button-wrap.full-width {
          display: block; }
          .fairdee-form-wrapper .card-wrap .section.four .button-wrap.full-width .button {
            width: 100%; }
        .fairdee-form-wrapper .card-wrap .section.four .button-wrap .button {
          text-transform: none;
          padding-left: 4px;
          padding-right: 4px;
          position: relative; }
  .fairdee-form-wrapper .checkbox-wrap {
    margin-bottom: 24px; }
    .fairdee-form-wrapper .checkbox-wrap .text {
      font-size: 12px;
      color: #4a4a4a;
      margin-top: 8px;
      font-family: 'sukh-regular'; }
    .fairdee-form-wrapper .checkbox-wrap .title {
      font-size: 16px;
      color: #4a4a4a;
      margin-top: 8px;
      font-family: 'sukh-regular';
      margin: 0 0 8px 0; }
    .fairdee-form-wrapper .checkbox-wrap .ui.checkbox label {
      padding-left: 20px; }
    .fairdee-form-wrapper .checkbox-wrap .ui.checkbox input:checked ~ label:after {
      border: solid 2px #ff9800;
      background: #ff9800; }

.page-not-found {
  color: #495057;
  text-align: left;
  background-color: #f5f7fb;
  height: var(--vh);
  width: 100%;
  padding: 24px; }
  .page-not-found .page-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center; }
    .page-not-found .page-content .title-text {
      color: #9aa0ac !important;
      font-size: 4.5rem;
      line-height: 1.1;
      margin-bottom: 20px; }
    .page-not-found .page-content .text {
      color: #495057; }

.driver-form {
  margin-bottom: 20px; }
  .driver-form .label {
    margin-bottom: 20px; }

@media only screen and (max-width: 768px) {
  .fairdee-form-wrapper {
    display: block;
    margin: 0; }
    .fairdee-form-wrapper.fairdee-wizard .mobile-card {
      padding: 0; }
    .fairdee-form-wrapper.fairdee-wizard .button-wrap {
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 9; }
    .fairdee-form-wrapper .mobile-card {
      padding: 16px; }
    .fairdee-form-wrapper.fd-form {
      padding-top: 32px; }
      .fairdee-form-wrapper.fd-form .right-section {
        padding-top: 36px; }
      .fairdee-form-wrapper.fd-form .card-wrap .card {
        margin-bottom: 12px; }
    .fairdee-form-wrapper .mobile-card > .text-one,
    .fairdee-form-wrapper .mobile-card > .text-two,
    .fairdee-form-wrapper .mobile-card > .text-three,
    .fairdee-form-wrapper .mobile-card .text-four {
      padding: 0; }
    .fairdee-form-wrapper.fairdee-wizard .admin-actions {
      max-width: 100%;
      padding: 0 24px; }
    .fairdee-form-wrapper.with-header {
      margin-top: 56px; }
    .fairdee-form-wrapper .left-section {
      display: none; }
    .fairdee-form-wrapper .right-section {
      padding: 0 0 60px 0; }
      .fairdee-form-wrapper .right-section .error-messages-wrap {
        max-width: 100%; }
      .fairdee-form-wrapper .right-section .step-content .orange-bordered-button.ml {
        margin-left: 20px; }
      .fairdee-form-wrapper .right-section .car-inspection-img-wrap.hide .img {
        display: none; }
      .fairdee-form-wrapper .right-section .car-inspection-img-wrap.hide .text:after {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg); }
      .fairdee-form-wrapper .right-section .car-inspection-img-wrap .text {
        position: relative;
        padding-right: 20px;
        display: inline-block; }
        .fairdee-form-wrapper .right-section .car-inspection-img-wrap .text:after {
          content: '';
          background: url(images/down-arrow-orange.svg) no-repeat;
          background-position: center center;
          height: 16px;
          width: 16px;
          position: absolute;
          right: 0;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg); }
      .fairdee-form-wrapper .right-section .steps-wrap {
        background: #fff;
        padding: 12px;
        margin: 0;
        justify-content: center;
        z-index: 9;
        top: 48px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12); }
        .fairdee-form-wrapper .right-section .steps-wrap .step {
          flex-grow: 1;
          flex-basis: 100%;
          font-size: 11px;
          font-family: 'sukh-medium';
          text-align: center; }
          .fairdee-form-wrapper .right-section .steps-wrap .step.line {
            max-width: 32px;
            display: none; }
          .fairdee-form-wrapper .right-section .steps-wrap .step.active {
            background: url(images/green-tick.svg) no-repeat;
            background-position: 4px 7px;
            background-size: 10px;
            padding-left: 14px !important; }
      .fairdee-form-wrapper .right-section .button-wrap button {
        max-width: 100%; }
      .fairdee-form-wrapper .right-section .title-wrap {
        padding: 12px 24px 12px 24px;
        background: #f3f4f7;
        margin: 0 0 12px 0; }
        .fairdee-form-wrapper .right-section .title-wrap .text-one {
          font-size: 12px;
          font-family: 'sukh-bold';
          letter-spacing: 1px;
          color: #4a4a4a;
          text-transform: uppercase;
          margin: 0; }
        .fairdee-form-wrapper .right-section .title-wrap .text-two {
          display: none; }
        .fairdee-form-wrapper .right-section .title-wrap.warning {
          background: none; }
          .fairdee-form-wrapper .right-section .title-wrap.warning .text-two {
            display: block; }
    .fairdee-form-wrapper .fairdee-form {
      max-width: 100%;
      padding: 8px 24px; }
      .fairdee-form-wrapper .fairdee-form .file-upload-wrap .img {
        max-width: 100%; } }

.static-pages {
  background: #f3f4f7; }
  .static-pages .container {
    max-width: 1140px;
    margin: 0 auto; }
  .static-pages .sections.faq .title-text {
    font-family: 'sukh-bold', sans-serif !important;
    font-size: 22px !important;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
    position: relative;
    line-height: 1.8;
    margin-bottom: 16px; }
  .static-pages .sections.faq .sub-text {
    font-family: 'sukh-bold', sans-serif !important;
    font-size: 18px !important;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
    position: relative;
    line-height: 1.8;
    margin-bottom: 16px; }
  .static-pages .sections.faq > div {
    background: #fff;
    padding: 12px;
    margin-bottom: 24px;
    border-radius: 12px; }
  .static-pages .sections.faq .question-answer {
    margin-bottom: 32px; }
  .static-pages .question {
    font-family: 'sukh-medium', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
    position: relative;
    line-height: 1.8;
    margin-bottom: 12px; }
  .static-pages .answer {
    font-family: 'sukh-regular', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
    position: relative;
    line-height: 1.8;
    margin-bottom: 8px; }
  .static-pages .wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    position: relative; }
  .static-pages .banner-background {
    padding: 40px;
    background: #192383; }
    .static-pages .banner-background .title-text {
      font-family: 'sukh-medium', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }
  .static-pages .container {
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.02); }
  .static-pages.contact-us .container {
    padding: 78px 0 136px 0;
    margin-top: 32px; }
  .static-pages.contact-us .img-wrap {
    margin: 0 auto 50px auto;
    max-width: 320px;
    width: 100%; }
  .static-pages.contact-us .desc-wrap {
    margin: 0 auto;
    max-width: 900px;
    display: flex; }
    .static-pages.contact-us .desc-wrap .col {
      display: inline-block;
      vertical-align: top;
      padding: 0 20px; }
      .static-pages.contact-us .desc-wrap .col:first-child {
        border-right: 1.5px solid #d8d8d8; }
      .static-pages.contact-us .desc-wrap .col:last-child {
        flex: 1; }
    .static-pages.contact-us .desc-wrap .text {
      font-size: 22px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.55;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      font-family: 'sukh-light', sans-serif; }
      .static-pages.contact-us .desc-wrap .text .key {
        text-transform: capitalize;
        font-family: 'sukh-regular', sans-serif; }
  .static-pages.terms-of-use .tabular-content, .static-pages.privacy-policy .tabular-content, .static-pages.how-it-works .tabular-content {
    margin: auto;
    padding: 50px 0;
    max-width: 1080px; }
    .static-pages.terms-of-use .tabular-content .ui.grid, .static-pages.privacy-policy .tabular-content .ui.grid, .static-pages.how-it-works .tabular-content .ui.grid {
      display: block; }
    .static-pages.terms-of-use .tabular-content .five.wide.column, .static-pages.privacy-policy .tabular-content .five.wide.column, .static-pages.how-it-works .tabular-content .five.wide.column {
      padding: 0; }
      .static-pages.terms-of-use .tabular-content .five.wide.column .sticky, .static-pages.privacy-policy .tabular-content .five.wide.column .sticky, .static-pages.how-it-works .tabular-content .five.wide.column .sticky {
        top: 100px !important;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 580px; }
        .static-pages.terms-of-use .tabular-content .five.wide.column .sticky::-webkit-scrollbar, .static-pages.privacy-policy .tabular-content .five.wide.column .sticky::-webkit-scrollbar, .static-pages.how-it-works .tabular-content .five.wide.column .sticky::-webkit-scrollbar {
          width: 0.5em;
          height: 0.5em; }
        .static-pages.terms-of-use .tabular-content .five.wide.column .sticky::-webkit-scrollbar-thumb, .static-pages.privacy-policy .tabular-content .five.wide.column .sticky::-webkit-scrollbar-thumb, .static-pages.how-it-works .tabular-content .five.wide.column .sticky::-webkit-scrollbar-thumb {
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 3px; }
          .static-pages.terms-of-use .tabular-content .five.wide.column .sticky::-webkit-scrollbar-thumb:hover, .static-pages.privacy-policy .tabular-content .five.wide.column .sticky::-webkit-scrollbar-thumb:hover, .static-pages.how-it-works .tabular-content .five.wide.column .sticky::-webkit-scrollbar-thumb:hover {
            background: rgba(255, 255, 255, 0.2); }
    .static-pages.terms-of-use .tabular-content .eleven.wide.column, .static-pages.privacy-policy .tabular-content .eleven.wide.column, .static-pages.how-it-works .tabular-content .eleven.wide.column {
      background: transparent;
      padding-left: 25px;
      border-radius: 6px; }
    .static-pages.terms-of-use .tabular-content .tabular.menu, .static-pages.privacy-policy .tabular-content .tabular.menu, .static-pages.how-it-works .tabular-content .tabular.menu {
      border: 0;
      background: #ffffff;
      padding: 10px 0;
      border-radius: 6px; }
      .static-pages.terms-of-use .tabular-content .tabular.menu .item, .static-pages.privacy-policy .tabular-content .tabular.menu .item, .static-pages.how-it-works .tabular-content .tabular.menu .item {
        font-family: 'sukh-regular', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a;
        position: relative;
        padding: 10px 10px 10px 30px;
        line-height: 1.5; }
        .static-pages.terms-of-use .tabular-content .tabular.menu .item:after, .static-pages.privacy-policy .tabular-content .tabular.menu .item:after, .static-pages.how-it-works .tabular-content .tabular.menu .item:after {
          content: '';
          width: 8px;
          height: 12px;
          position: absolute;
          left: 0;
          background-size: contain;
          top: 15px; }
        .static-pages.terms-of-use .tabular-content .tabular.menu .item.active, .static-pages.privacy-policy .tabular-content .tabular.menu .item.active, .static-pages.how-it-works .tabular-content .tabular.menu .item.active {
          border: 0;
          border-left: 4px solid #f58b1e;
          background: rgba(216, 216, 216, 0.2);
          border-radius: 0 !important;
          color: #192383;
          font-family: 'sukh-medium', sans-serif; }
    .static-pages.terms-of-use .tabular-content .stretched, .static-pages.privacy-policy .tabular-content .stretched, .static-pages.how-it-works .tabular-content .stretched {
      padding: 0; }
      .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment {
        border: 0;
        padding: 0;
        background: transparent; }
        .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .section, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .section, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .section {
          padding: 20px 40px;
          margin-bottom: 20px;
          background: #ffffff;
          border-radius: 6px;
          box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.02); }
        .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .content-wrapper, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .content-wrapper, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .content-wrapper {
          margin-bottom: 20px; }
          .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .content-wrapper:last-child, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .content-wrapper:last-child, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .content-wrapper:last-child {
            margin-bottom: 0;
            border: 0; }
          .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .content-wrapper .ui .column, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .content-wrapper .ui .column, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .content-wrapper .ui .column {
            padding: 0; }
            .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .content-wrapper .ui .column:last-child, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .content-wrapper .ui .column:last-child, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .content-wrapper .ui .column:last-child {
              padding-bottom: 24px;
              border-bottom: dashed 1px #d9d9d9;
              padding-left: 25px; }
              .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .content-wrapper .ui .column:last-child.no-border, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .content-wrapper .ui .column:last-child.no-border, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .content-wrapper .ui .column:last-child.no-border {
                border: 0; }
        .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .accordion .title, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .accordion .title, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .accordion .title {
          font-family: 'sukh-regular', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          text-align: left;
          line-height: 2.13;
          color: #4a4a4a;
          padding: 0; }
          .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .accordion .title .dropdown.icon, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .accordion .title .dropdown.icon, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .accordion .title .dropdown.icon {
            color: #192383; }
        .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .accordion .content, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .accordion .content, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .accordion .content {
          padding-left: 25px !important; }
          .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .accordion .content .suggestion-box, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .accordion .content .suggestion-box, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .accordion .content .suggestion-box {
            background-color: rgba(22, 43, 106, 0.05);
            padding: 25px;
            text-align: center;
            margin-top: 10px; }
            .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .accordion .content .suggestion-box .text, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .accordion .content .suggestion-box .text, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .accordion .content .suggestion-box .text {
              font-family: 'sukh-light', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              text-align: center;
              line-height: 2.13;
              color: #4a4a4a;
              padding: 0; }
            .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .accordion .content .suggestion-box .buttons-wrap, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .accordion .content .suggestion-box .buttons-wrap, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .accordion .content .suggestion-box .buttons-wrap {
              margin-top: 10px; }
              .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .accordion .content .suggestion-box .buttons-wrap button, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .accordion .content .suggestion-box .buttons-wrap button, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .accordion .content .suggestion-box .buttons-wrap button {
                border-radius: 4px;
                background-color: #ffffff;
                border: solid 1px #192383;
                font-size: 10px;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                text-align: center;
                color: #192383;
                padding: 4px 14px;
                margin: 0 7.5px;
                outline: none;
                cursor: pointer; }
        .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .title-text, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .title-text, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .title-text {
          font-family: 'sukh-regular', sans-serif;
          font-size: 18px;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.89;
          letter-spacing: normal;
          text-align: left;
          color: #4a4a4a;
          position: relative;
          margin-bottom: 20px; }
        .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .sub-title-text, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .sub-title-text, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .sub-title-text {
          font-family: 'sukh-regular', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.31;
          letter-spacing: normal;
          text-align: left;
          color: #4a4a4a;
          position: relative;
          margin-bottom: 10px; }
        .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .link-text, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .link-text, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .link-text {
          font-family: 'sukh-regular', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.31;
          letter-spacing: normal;
          text-align: left;
          position: relative; }
          .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .link-text a, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .link-text a, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .link-text a {
            text-decoration: underline;
            color: #192383; }
        .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .text, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .text, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .text {
          font-family: 'sukh-light', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #4a4a4a;
          position: relative;
          margin: 0; }
          .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .text span.margin-right, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .text span.margin-right, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .text span.margin-right {
            padding-right: 35px; }
          .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .text.margin-top, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .text.margin-top, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .text.margin-top {
            margin-top: 40px; }
          .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .text.dot, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .text.dot, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .text.dot {
            padding-left: 50px; }
            .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .text.dot:before, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .text.dot:before, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .text.dot:before {
              content: '';
              width: 3px;
              height: 3px;
              position: absolute;
              border-radius: 100%;
              background: #4a4a4a;
              left: 0;
              top: 10px; }
          .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .text.link, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .text.link, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .text.link {
            font-family: 'sukh-regular', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.31;
            letter-spacing: normal;
            text-align: left;
            position: relative; }
            .static-pages.terms-of-use .tabular-content .stretched .ui.attached.segment .text.link a, .static-pages.privacy-policy .tabular-content .stretched .ui.attached.segment .text.link a, .static-pages.how-it-works .tabular-content .stretched .ui.attached.segment .text.link a {
              text-decoration: underline;
              color: #192383; }
  .static-pages.referral-program .container {
    padding: 40px 0 60px 0;
    margin-top: 32px; }
  .static-pages.referral-program .sub-title {
    font-family: 'sukh-regular', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: center;
    color: #192383; }
    .static-pages.referral-program .sub-title .english {
      font-family: 'sukh-regular', sans-serif;
      letter-spacing: 0; }
  .static-pages.referral-program .sub-text {
    font-family: 'sukh-light', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a; }
    .static-pages.referral-program .sub-text .english {
      font-family: 'sukh-light';
      letter-spacing: 0; }
  .static-pages.referral-program .col-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 70px;
    margin: 0 auto 20px auto; }
    .static-pages.referral-program .col-wrap .col {
      text-align: center;
      padding: 0 10px; }
      .static-pages.referral-program .col-wrap .col .img-wrap .refer-mini {
        display: none; }
      .static-pages.referral-program .col-wrap .col .img-wrap .reward-mini {
        display: none; }
      .static-pages.referral-program .col-wrap .col .title {
        font-family: 'sukh-regular', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #192383; }
      .static-pages.referral-program .col-wrap .col .text {
        font-family: 'sukh-light', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #282c3f;
        margin-top: 15px; }
        .static-pages.referral-program .col-wrap .col .text .english {
          font-family: 'sukh-light';
          letter-spacing: 0; }
  .static-pages.referral-program .link-text {
    text-align: center; }
    .static-pages.referral-program .link-text a {
      font-family: 'sukh-light', sans-serif;
      font-size: 18px;
      font-style: initial;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #192383;
      display: inline-block;
      padding: 1px 0;
      border-bottom: 1px solid #192383; }
      .static-pages.referral-program .link-text a .english {
        font-family: 'sukh-light';
        letter-spacing: 0; }
  .static-pages.referral-program .line {
    background: url(images/referral-program/line.svg) repeat;
    margin: 40px auto 15px auto;
    width: 100%;
    height: 1px;
    max-width: 1080px; }
  .static-pages.referral-program .button-wrap {
    text-align: center;
    margin: 20px auto 0 auto; }
    .static-pages.referral-program .button-wrap .button {
      padding: 10px 25px;
      font-size: 14px;
      min-width: 200px; }

@media screen and (max-width: 1024px) {
  .static-pages {
    padding: 0; }
    .static-pages .banner-background {
      display: none; }
    .static-pages.terms-of-use .tabular-content, .static-pages.privacy-policy .tabular-content, .static-pages.how-it-works .tabular-content {
      padding: 0; }
      .static-pages.terms-of-use .tabular-content .five.wide.column .sticky, .static-pages.privacy-policy .tabular-content .five.wide.column .sticky, .static-pages.how-it-works .tabular-content .five.wide.column .sticky {
        top: 0 !important;
        position: relative !important;
        width: auto !important; }
    .static-pages.referral-program .container {
      margin-top: 0; }
    .static-pages.referral-program .col-wrap {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0;
      margin: 0; }
      .static-pages.referral-program .col-wrap .col {
        padding: 5px 20px; }
        .static-pages.referral-program .col-wrap .col .img-wrap .refer {
          display: none; }
        .static-pages.referral-program .col-wrap .col .img-wrap .refer-mini {
          display: inline-block; }
        .static-pages.referral-program .col-wrap .col .img-wrap .reward {
          display: none; }
        .static-pages.referral-program .col-wrap .col .img-wrap .reward-mini {
          display: inline-block; }
        .static-pages.referral-program .col-wrap .col .img-wrap .win {
          max-width: 44px; }
        .static-pages.referral-program .col-wrap .col .title {
          font-size: 16px;
          color: #4a4a4a; }
        .static-pages.referral-program .col-wrap .col .text {
          font-size: 14px;
          color: #4a4a4a;
          margin-top: 5px; }
    .static-pages.referral-program .link-text,
    .static-pages.referral-program .sub-title {
      display: none; }
    .static-pages.referral-program .sub-text {
      font-size: 14px;
      color: #192383;
      padding: 0 60px; }
    .static-pages.referral-program .line {
      max-width: 70%;
      background: rgba(216, 216, 216, 0.8); }
    .static-pages.referral-program .button {
      width: 70%; }
    .static-pages.contact-us .container {
      margin-top: 0;
      padding: 36px 0 53px 0; }
    .static-pages.contact-us .img-wrap {
      text-align: center; }
      .static-pages.contact-us .img-wrap img {
        max-width: 193px; }
    .static-pages.contact-us .desc-wrap {
      display: block; }
      .static-pages.contact-us .desc-wrap .col {
        display: block;
        width: 100%;
        border: 0 !important; }
        .static-pages.contact-us .desc-wrap .col .text {
          text-align: center;
          font-size: 14px;
          color: #4a4a4a;
          margin-bottom: 20px; }
          .static-pages.contact-us .desc-wrap .col .text span {
            display: block;
            margin-bottom: 6px;
            font-size: 16px; } }

.onboarding-wrapper {
  background: #192383;
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  padding: 48px 0 48px 80px;
  font-family: 'sukh-regular'; }
  .onboarding-wrapper .content {
    flex-grow: 1; }
    .onboarding-wrapper .content .slider-wrap.slick-slider {
      max-width: 100%; }
    .onboarding-wrapper .content .slider-wrap .slick-slide {
      width: 100%; }
      .onboarding-wrapper .content .slider-wrap .slick-slide > div {
        margin-left: 32px; }
      .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide {
        border-radius: 10px;
        box-shadow: 0 2px 10px 7px #061178;
        background-color: #ffffff;
        display: flex !important;
        flex-direction: column;
        height: calc(calc(var(--vh, 1vh) * 100) - 96px);
        min-height: calc(calc(var(--vh, 1vh) * 100) - 96px);
        padding: 32px 42px; }
        .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .header {
          display: flex;
          align-items: center;
          margin-bottom: 48px; }
          .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .header div {
            display: flex;
            align-items: center; }
            .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .header div:first-child {
              flex-grow: 1; }
              .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .header div:first-child img {
                margin-right: 24px; }
              .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .header div:first-child span {
                font-size: 16px;
                font-family: 'sukh-regular';
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #5c5c5c;
                padding-left: 24px;
                border-left: solid 1px #e2e2e2; }
            .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .header div .language-toggle {
              display: flex; }
              .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .header div .language-toggle span {
                flex-grow: 1;
                flex-basis: 100%;
                text-align: center;
                padding: 0 12px;
                cursor: pointer; }
                .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .header div .language-toggle span:first-child {
                  border-right: solid 1px #d3d2d2; }
                .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .header div .language-toggle span.active {
                  color: #ff901d; }
            .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .header div:last-child p {
              font-size: 16px;
              font-family: 'sukh-regular';
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #1b1b1b;
              margin-right: 20px;
              cursor: pointer; }
        .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content {
          flex-grow: 1;
          display: flex;
          align-items: center; }
          .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content.center div:last-child.fifth img {
            max-width: 100%; }
          .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content.center div:last-child img {
            max-width: 460px; }
          .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content.right div:last-child {
            text-align: right;
            position: relative; }
            .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content.right div:last-child img {
              position: relative;
              margin-right: -48px;
              max-width: 660px;
              width: 100%; }
          .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content > div .text-one {
            font-size: 24px;
            font-family: 'sukh-bold';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
            margin-bottom: 20px; }
          .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content > div .text-two {
            margin-top: 12px;
            font-size: 14px;
            font-family: 'sukh-regular';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1b1b1b; }
            .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content > div .text-two span {
              font-family: 'sukh-bold';
              color: #ff901d;
              cursor: pointer; }
          .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content > div .button {
            font-size: 14px;
            font-family: 'sukh-bold';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            padding: 12px 32px;
            border-radius: 1px;
            border: solid 1px #ff901d;
            background-color: #ff901d;
            text-transform: none; }
          .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content > div:first-child {
            max-width: 420px;
            min-width: 420px; }
          .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content > div:last-child {
            text-align: center;
            flex-grow: 1;
            max-height: calc(var(--vh) - 300px); }
            .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content > div:last-child > img {
              max-width: 100%;
              max-height: 100%;
              display: inline-block;
              object-fit: cover; }
            .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content > div:last-child.fifth {
              padding-right: 100px;
              text-align: right; }
              .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content > div:last-child.fifth > div {
                max-width: 440px;
                margin: 0 auto;
                display: inline-block;
                text-align: center; }
                .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content > div:last-child.fifth > div > img {
                  max-width: 130px !important;
                  margin: 20px auto auto; }
              .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content > div:last-child.fifth .text-one {
                font-size: 13.9px;
                font-family: 'sukh-regular';
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #282c3f;
                margin-bottom: 16px; }
              .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content > div:last-child.fifth .logo-wrap {
                border-bottom: solid 1px #e1e3ef;
                margin-bottom: 32px;
                padding-bottom: 28px; }
                .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .content > div:last-child.fifth .logo-wrap img {
                  display: inline-block;
                  margin: 22px 16px;
                  width: 55px;
                  height: 55px; }
        .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .footer {
          display: block;
          margin-top: 16px; }
          .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .footer .text-one {
            font-size: 14px;
            font-family: 'sukh-bold';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ff901d;
            margin-bottom: 6px;
            cursor: pointer; }
          .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .footer .text-two {
            font-size: 14px;
            font-family: 'sukh-regular';
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1b1b1b; }
            .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .footer .text-two span {
              font-family: 'sukh-bold';
              color: #ff901d;
              cursor: pointer; }
              .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide .footer .text-two span a {
                font-family: 'sukh-bold';
                color: #ff901d; }
        .onboarding-wrapper .content .slider-wrap .slick-slide .each-slide:focus {
          outline: none; }
    .onboarding-wrapper .content .slider-wrap .slick-dots {
      bottom: 62px;
      position: fixed;
      z-index: 999;
      left: 0;
      right: 0;
      width: 100%; }
      .onboarding-wrapper .content .slider-wrap .slick-dots li {
        width: 8px;
        height: 8px;
        margin: 0 4px; }
        .onboarding-wrapper .content .slider-wrap .slick-dots li:first-child {
          margin-left: 0; }
        .onboarding-wrapper .content .slider-wrap .slick-dots li:last-child {
          margin-right: 0; }
        .onboarding-wrapper .content .slider-wrap .slick-dots li button {
          opacity: 0.4;
          width: 6px;
          height: 6px; }
          .onboarding-wrapper .content .slider-wrap .slick-dots li button:before {
            color: #fff;
            width: 6px;
            height: 6px; }
        .onboarding-wrapper .content .slider-wrap .slick-dots li.slick-active button {
          opacity: 1;
          width: 8px;
          height: 8px; }
          .onboarding-wrapper .content .slider-wrap .slick-dots li.slick-active button:before {
            width: 8px;
            height: 8px; }
    .onboarding-wrapper .content .slider-wrap .with-margin .title-text {
      margin: 20px 0 0 0;
      padding: 0 24px; }
    .onboarding-wrapper .content .slider-wrap .with-margin .text {
      margin: 10px 0;
      padding: 0 24px; }
    .onboarding-wrapper .content .slider-wrap .title-text {
      font-size: 20px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-bottom: 10px;
      height: 62px;
      min-height: 62px; }
    .onboarding-wrapper .content .slider-wrap .text {
      font-size: 14px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin: 10px 0; }
      .onboarding-wrapper .content .slider-wrap .text span {
        text-decoration: underline; }
  .onboarding-wrapper .footer {
    display: none; }

@media screen and (max-width: 1140px) {
  .onboarding-wrapper .content {
    max-width: 100%; } }

@media screen and (max-width: 1024px) {
  .onboarding-wrapper {
    background: #192383;
    height: calc(var(--vh, 1vh) * 100);
    max-height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0; }
    .onboarding-wrapper .content {
      flex-grow: 1; }
      .onboarding-wrapper .content .slider-wrap .header {
        border: solid 1px #e6e6e6;
        background-color: #ffffff;
        padding: 20px 24px;
        display: flex;
        width: 100%;
        align-items: center; }
        .onboarding-wrapper .content .slider-wrap .header .logo-wrap img {
          max-width: 66px;
          width: 100%; }
      .onboarding-wrapper .content .slider-wrap .slick-slide {
        position: relative; }
        .onboarding-wrapper .content .slider-wrap .slick-slide.slick-active > div > div {
          opacity: 1; }
        .onboarding-wrapper .content .slider-wrap .slick-slide > div {
          margin: 0; }
          .onboarding-wrapper .content .slider-wrap .slick-slide > div > div {
            display: flex !important;
            flex-direction: column;
            height: calc(calc(var(--vh, 1vh) * 100) - 50px);
            min-height: calc(calc(var(--vh, 1vh) * 100) - 50px);
            position: relative;
            -webkit-transition: opacity 1s ease;
            -moz-transition: opacity 1s ease;
            -o-transition: opacity 1s ease;
            transition: opacity 1s ease;
            opacity: 0; }
            .onboarding-wrapper .content .slider-wrap .slick-slide > div > div:focus {
              outline: none; }
            .onboarding-wrapper .content .slider-wrap .slick-slide > div > div .img-wrap {
              flex: 1;
              height: calc(calc(var(--vh, 1vh) * 100) - 180px);
              min-height: calc(calc(var(--vh, 1vh) * 100) - 180px); }
              .onboarding-wrapper .content .slider-wrap .slick-slide > div > div .img-wrap img {
                margin: 0 auto;
                width: auto;
                max-height: 100%;
                max-width: 100%; }
            .onboarding-wrapper .content .slider-wrap .slick-slide > div > div .title-text,
            .onboarding-wrapper .content .slider-wrap .slick-slide > div > div .text {
              flex: 1;
              display: block;
              padding-top: 6px; }
      .onboarding-wrapper .content .slider-wrap .slick-dots {
        bottom: 32px;
        position: fixed;
        z-index: 999;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px; }
        .onboarding-wrapper .content .slider-wrap .slick-dots li {
          width: 8px;
          height: 8px;
          margin: 0 4px; }
          .onboarding-wrapper .content .slider-wrap .slick-dots li:first-child {
            margin-left: 0; }
          .onboarding-wrapper .content .slider-wrap .slick-dots li:last-child {
            margin-right: 0; }
          .onboarding-wrapper .content .slider-wrap .slick-dots li button {
            opacity: 0.4;
            width: 6px;
            height: 6px;
            position: relative; }
            .onboarding-wrapper .content .slider-wrap .slick-dots li button:before {
              color: #fff;
              width: 6px;
              height: 6px;
              line-height: 1 !important; }
          .onboarding-wrapper .content .slider-wrap .slick-dots li.slick-active button {
            opacity: 1;
            width: 8px;
            height: 8px; }
            .onboarding-wrapper .content .slider-wrap .slick-dots li.slick-active button:before {
              width: 8px;
              height: 8px; }
      .onboarding-wrapper .content .slider-wrap .with-margin .title-text {
        margin: 20px 0 0 0;
        padding: 0 24px; }
      .onboarding-wrapper .content .slider-wrap .with-margin .text {
        margin: 0 0 10px 0;
        padding: 0 24px; }
      .onboarding-wrapper .content .slider-wrap .title-text {
        font-size: 20px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin-bottom: 10px;
        height: 62px;
        min-height: 62px; }
      .onboarding-wrapper .content .slider-wrap .text {
        font-size: 14px;
        font-family: 'sukh-regular';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin: 10px 0; }
        .onboarding-wrapper .content .slider-wrap .text span {
          text-decoration: underline; }
    .onboarding-wrapper .footer {
      background: #050e5d;
      padding: 10px 24px;
      display: flex;
      width: 100%; }
      .onboarding-wrapper .footer.single {
        display: block;
        text-align: center;
        z-index: 999; }
      .onboarding-wrapper .footer div:first-child {
        margin-right: 8px; }
      .onboarding-wrapper .footer div:last-child {
        margin-left: 8px; }
      .onboarding-wrapper .footer div.dots {
        flex-grow: 1; }
      .onboarding-wrapper .footer button {
        border-radius: 2px;
        font-size: 14px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        padding: 2px 6px;
        width: 86px; }
        .onboarding-wrapper .footer button.login {
          background-color: rgba(225, 228, 255, 0.2);
          border: none;
          padding: 3px 6px; }
        .onboarding-wrapper .footer button.signup {
          border: solid 1px #ff901d;
          background-color: #ff901d; } }

@media screen and (max-width: 360px) {
  .onboarding-wrapper .content .slider-wrap .slick-slide img {
    margin: 0 auto;
    width: 100%;
    max-width: 100%; } }

.about-us {
  font-family: 'sukh-regular'; }
  .about-us .header {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding: 20px 72px;
    background: #fff; }
    .about-us .header div {
      display: flex;
      align-items: center; }
      .about-us .header div:first-child {
        flex-grow: 1; }
        .about-us .header div:first-child img {
          margin-right: 24px; }
        .about-us .header div:first-child span {
          font-size: 16px;
          font-family: 'sukh-regular';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #5c5c5c;
          padding-left: 24px;
          border-left: solid 1px #e2e2e2; }
      .about-us .header div .language-toggle {
        display: flex; }
        .about-us .header div .language-toggle span {
          flex-grow: 1;
          flex-basis: 100%;
          text-align: center;
          padding: 0 12px;
          cursor: pointer; }
          .about-us .header div .language-toggle span:first-child {
            border-right: solid 1px #d3d2d2; }
          .about-us .header div .language-toggle span.active {
            color: #ff901d; }
      .about-us .header div:last-child p {
        border-radius: 20px;
        border: solid 2px #ff901d;
        background-color: #ffffff;
        font-size: 16px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1b1b1b;
        padding: 5px 20px; }
  .about-us .mobile {
    display: none; }
  .about-us .container {
    max-width: 100%;
    margin: 0 auto; }
  .about-us .content {
    max-width: 1040px;
    margin: 0 auto; }
  .about-us .section-one {
    padding: 0 0 80px 0; }
    .about-us .section-one .content .title {
      font-size: 36px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: #1b1b1b;
      margin-bottom: 30px; }
    .about-us .section-one .content .toggle-text {
      display: none; }
    .about-us .section-one .content .link-text {
      font-size: 16px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #192383;
      cursor: pointer;
      display: inline-block;
      margin-top: 12px;
      text-decoration: underline; }
    .about-us .section-one .content .expansion-panel {
      margin-top: 24px;
      display: none; }
      .about-us .section-one .content .expansion-panel.open {
        display: block; }
      .about-us .section-one .content .expansion-panel .title {
        font-size: 36px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: #1b1b1b;
        margin-bottom: 32px; }
      .about-us .section-one .content .expansion-panel .text {
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.44;
        letter-spacing: normal;
        color: #5e5e5e; }
    .about-us .section-one .content .line {
      border-bottom: 1px solid #c2c2c2;
      margin-bottom: 50px; }
    .about-us .section-one .content .text-one {
      font-size: 24px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b1b1b; }
    .about-us .section-one .content .text-two {
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #5e5e5e;
      margin-bottom: 12px; }
    .about-us .section-one .content .text-three {
      font-size: 16px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #525252;
      margin-bottom: 8px; }
    .about-us .section-one .content .inline-wrap {
      display: flex;
      margin-top: 32px; }
      .about-us .section-one .content .inline-wrap .img-wrap {
        min-width: 430px;
        max-width: 430px;
        margin-right: 24px; }
        .about-us .section-one .content .inline-wrap .img-wrap img {
          max-width: 100%; }
      .about-us .section-one .content .inline-wrap .content-wrap {
        flex-grow: 1;
        max-width: 540px; }
        .about-us .section-one .content .inline-wrap .content-wrap button {
          margin-top: 16px; }
  .about-us .section-two {
    padding: 0 0 80px 0; }
    .about-us .section-two .content .title {
      font-size: 36px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: left;
      color: #1b1b1b;
      margin-bottom: 57px;
      padding-bottom: 32px;
      border-bottom: solid 1px #c2c2c2; }
    .about-us .section-two .content .col-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 80px; }
      .about-us .section-two .content .col-wrap .col .expansion-panel.open {
        border-bottom: 1px solid #c2c2c2;
        margin-bottom: 24px; }
        .about-us .section-two .content .col-wrap .col .expansion-panel.open .desc-text {
          display: block; }
        .about-us .section-two .content .col-wrap .col .expansion-panel.open .expansion-wrap {
          display: block; }
        .about-us .section-two .content .col-wrap .col .expansion-panel.open .text:before {
          background: url(images/onboarding/arrow.svg) no-repeat;
          background-position: center;
          transform: rotate(0deg); }
      .about-us .section-two .content .col-wrap .col .text {
        font-size: 21px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1b1b1b;
        margin-bottom: 24px;
        position: relative;
        padding-left: 44px; }
        .about-us .section-two .content .col-wrap .col .text:before {
          content: '';
          width: 24px;
          height: 24px;
          background: url(images/onboarding/arrow.svg) no-repeat;
          background-position: center;
          position: absolute;
          left: 0;
          top: 4px;
          transform: rotate(-180deg); }
      .about-us .section-two .content .col-wrap .col .desc-text {
        font-size: 16px;
        font-family: 'sukh-light';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5e5e5e;
        display: none;
        padding-left: 44px;
        margin-bottom: 24px; }
    .about-us .section-two .content .expansion-wrap {
      display: none; }
      .about-us .section-two .content .expansion-wrap .title-text {
        font-size: 16px;
        font-family: 'sukh-medium';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #000000;
        margin-bottom: 4px; }
      .about-us .section-two .content .expansion-wrap .desc-text {
        font-size: 16px;
        font-family: 'sukh-regular';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #525252;
        margin-bottom: 24px; }

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .about-us .container,
  .about-us .content {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 24px; } }

@media screen and (max-width: 1024px) {
  .about-us .desktop {
    display: none; }
  .about-us .mobile {
    display: block; }
  .about-us .section-one .content {
    padding: 0 16px; }
    .about-us .section-one .content .title {
      font-size: 26px; }
    .about-us .section-one .content .link-text {
      display: none; }
    .about-us .section-one .content .expansion-wrap.open .text-two,
    .about-us .section-one .content .expansion-wrap.open button {
      display: block !important; }
    .about-us .section-one .content .expansion-wrap.open .toggle-text:after {
      transform: rotate(-180deg); }
    .about-us .section-one .content .toggle-text {
      font-size: 14px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: 1.86;
      letter-spacing: normal;
      color: #192383;
      text-decoration: underline;
      position: relative;
      cursor: pointer;
      display: block; }
      .about-us .section-one .content .toggle-text:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background: url(images/onboarding/down-arrow.svg) no-repeat;
        background-position: center;
        top: 4px; }
    .about-us .section-one .content .line {
      margin-bottom: 24px; }
    .about-us .section-one .content .text-one {
      font-size: 16px; }
    .about-us .section-one .content .text-two {
      font-size: 16px;
      display: none; }
    .about-us .section-one .content button {
      display: none; }
    .about-us .section-one .content .inline-wrap {
      display: block; }
      .about-us .section-one .content .inline-wrap .img-wrap {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 8px; }
    .about-us .section-one .content .col-wrap {
      display: block; }
      .about-us .section-one .content .col-wrap .col .text {
        font-size: 18px;
        font-family: 'sukh-medium'; }
  .about-us .section-two {
    padding: 16px; }
    .about-us .section-two .title {
      font-size: 24px; }
    .about-us .section-two .mobile-header {
      display: block;
      position: fixed;
      padding: 18px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
      background-color: #ffffff;
      margin-bottom: 45px;
      left: 0;
      top: 0;
      right: 0;
      background: #fff;
      z-index: 1; }
      .about-us .section-two .mobile-header .current-page {
        position: relative;
        left: 0;
        top: 0;
        padding: 0 32px 0 0;
        font-size: 16px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000; }
        .about-us .section-two .mobile-header .current-page .title-text {
          display: inline-block;
          margin: 0;
          font-size: 14px;
          font-family: 'sukh-medium', sans-serif; }
      .about-us .section-two .mobile-header img {
        display: inline-block;
        position: absolute;
        top: calc(50% - 6px);
        right: 24px; }
    .about-us .section-two .content .col-wrap .col .expansion-panel.open:last-child {
      border-bottom: 0; }
    .about-us .section-two .content .expansion-panel {
      padding-bottom: 20px;
      margin-bottom: 32px; }
      .about-us .section-two .content .expansion-panel:not(:last-child) {
        border-bottom: solid 1px rgba(151, 151, 151, 0.3); }
    .about-us .section-two .content .col-wrap {
      display: block; }
      .about-us .section-two .content .col-wrap .col .expansion-panel .text:before {
        right: 0;
        left: auto; }
      .about-us .section-two .content .col-wrap .col .text {
        padding: 0 44px 0 0;
        font-size: 16px;
        font-family: 'sukh-bold';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        margin-bottom: 12px; }
      .about-us .section-two .content .col-wrap .col .desc-text {
        padding: 0;
        font-size: 16px;
        font-family: 'sukh-regular';
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #525252; }
      .about-us .section-two .content .col-wrap .col .img-wrap {
        max-width: 100%;
        margin-bottom: 20px; }
        .about-us .section-two .content .col-wrap .col .img-wrap img {
          max-width: 100%;
          widows: 100%; } }

.mobile-bottom-nav {
  background: #192383;
  padding: 10px 8px 6px 6px;
  display: flex;
  z-index: 99;
  align-items: flex-end;
  width: 100%;
  min-height: 54px;
  max-height: 54px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0; }
  .mobile-bottom-nav > div {
    flex-grow: 1;
    flex-basis: 100%; }
    .mobile-bottom-nav > div.active .text {
      color: #f58b1e; }
    .mobile-bottom-nav > div .img-wrap.scale img {
      width: 32px;
      height: 32px;
      max-width: 100%; }
    .mobile-bottom-nav > div.large-icon {
      position: relative;
      text-align: center; }
      .mobile-bottom-nav > div.large-icon .img-wrap {
        position: absolute;
        width: 44px;
        height: 44px;
        left: 0;
        right: 0;
        margin: auto;
        top: -44px; }
        .mobile-bottom-nav > div.large-icon .img-wrap img {
          max-width: 100%;
          width: auto;
          height: auto;
          margin: 0; }
    .mobile-bottom-nav > div .img-wrap {
      text-align: center;
      margin-bottom: 2px;
      height: 18px;
      overflow: hidden; }
      .mobile-bottom-nav > div .img-wrap svg {
        color: #fff; }
        .mobile-bottom-nav > div .img-wrap svg.active {
          color: #f58b1e; }
      .mobile-bottom-nav > div .img-wrap img {
        max-width: 18px;
        width: 18px;
        height: 100%; }
        .mobile-bottom-nav > div .img-wrap img.show {
          display: inline-block; }
        .mobile-bottom-nav > div .img-wrap img.hide {
          display: none; }
    .mobile-bottom-nav > div .text {
      font-size: 8px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #e7eaff; }

.referral-dashboard {
  position: relative;
  padding: 20px 0; }
  .referral-dashboard .tabular-content {
    position: relative; }
    .referral-dashboard .tabular-content.referrals {
      padding-top: 60px; }
      .referral-dashboard .tabular-content.referrals .tabs-wrapper {
        margin-bottom: 12px; }
  .referral-dashboard .last-column {
    display: inline-block;
    margin-right: 10px; }
  .referral-dashboard .incentive-total {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5c5c5c;
    text-align: right;
    margin-top: 24px; }
  .referral-dashboard .tm-range .rdrDateRangePickerWrapper .rdrCalendarWrapper {
    right: 0;
    left: auto; }
  .referral-dashboard .tm-range.left .rdrDateRangePickerWrapper .rdrCalendarWrapper {
    left: 0;
    right: auto; }
  .referral-dashboard .inline-filter-wrapper {
    margin: 0 0 12px 0; }
    .referral-dashboard .inline-filter-wrapper .each-filter .custom-auto-complete > div div {
      margin-top: 0; }
      .referral-dashboard .inline-filter-wrapper .each-filter .custom-auto-complete > div div input {
        padding: 16px 0 0;
        font-size: 13px !important; }
  .referral-dashboard .tabular-content.no-filter {
    margin-top: 40px; }
  .referral-dashboard .tabular-content .tabs-wrapper > div > div > span {
    height: 4px;
    border-radius: 2px;
    background-color: #f58b1e; }
  .referral-dashboard .tabular-content .tabs-wrapper .tab {
    max-width: 100%;
    margin-right: 30px;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
    min-width: auto;
    text-transform: none; }
    .referral-dashboard .tabular-content .tabs-wrapper .tab[aria-selected='true'] {
      font-size: 18px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #000000; }
    .referral-dashboard .tabular-content .tabs-wrapper .tab > span > span {
      padding: 0; }
  .referral-dashboard.tutorials {
    padding: 12px !important; }
    .referral-dashboard.tutorials .empty-wrapper {
      width: 100%;
      text-align: center; }
      .referral-dashboard.tutorials .empty-wrapper .text {
        margin-top: 24px; }
    .referral-dashboard.tutorials .tabular-content {
      padding: 0 10px 10px 10px;
      margin: 0; }
      .referral-dashboard.tutorials .tabular-content .section {
        margin-top: 24px; }
        .referral-dashboard.tutorials .tabular-content .section .title {
          font-size: 14px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #192383;
          margin-bottom: 4px;
          margin: 0 0 12px 0; }
      .referral-dashboard.tutorials .tabular-content .tabs-wrapper .tab {
        min-width: 50%; }
    .referral-dashboard.tutorials .back-arrow {
      position: fixed;
      max-width: 480px;
      margin: 0 auto;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 9999;
      background: #fff;
      display: flex;
      padding: 17px;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 0px 6px #d8e7fd; }
      .referral-dashboard.tutorials .back-arrow .text {
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #1253a4; }

.sub-menu {
  background-color: #192383;
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 62px;
  z-index: 9; }
  .sub-menu .button {
    border-radius: 2px;
    background-color: #ffffff;
    font-size: 12px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #192383;
    border: 0;
    outline: none;
    padding: 4px 18px; }
    .sub-menu .button.active:after {
      display: none; }
  .sub-menu .container {
    margin: 0 auto;
    position: relative;
    max-width: 1440px !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 3px 72px !important; }
    .sub-menu .container .menu-items {
      flex-grow: 1; }
  .sub-menu a,
  .sub-menu button.blue {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: inline-block;
    margin-right: 40px;
    position: relative;
    padding: 9px 0;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    font-family: 'sukh-regular'; }
    .sub-menu a.link img,
    .sub-menu button.blue.link img {
      display: none; }
    .sub-menu a.active:after,
    .sub-menu button.blue.active:after {
      content: '';
      border-radius: 2px;
      background-color: #f58b1e;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%; }
  .sub-menu .button {
    margin-right: 0; }

.custom-table-wrapper {
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  position: relative; }
  .custom-table-wrapper .insurer-logo {
    max-height: 24px; }
  .custom-table-wrapper .padding-left-12 {
    padding-left: 12px; }
  .custom-table-wrapper .select {
    min-width: 100px; }
    .custom-table-wrapper .select .MuiSvgIcon-root-1 {
      right: 6px; }
    .custom-table-wrapper .select .MuiSelect-select-155.MuiSelect-selectMenu-158 {
      padding-right: 18px; }
    .custom-table-wrapper .select .MuiInput-underline-94:before {
      transform: scaleX(0.9); }
    .custom-table-wrapper .select.policy_sent_to_user > div > div > div, .custom-table-wrapper .select.policy_uploaded > div > div > div {
      color: green;
      font-family: 'sukh-bold'; }
    .custom-table-wrapper .select.docs_rejected > div > div > div {
      color: red;
      font-family: 'sukh-bold'; }
    .custom-table-wrapper .select.coa_approval_pending > div > div > div {
      color: orange;
      font-family: 'sukh-bold'; }
  .custom-table-wrapper .action .more .MuiSvgIcon-root-1 {
    font-size: 20px; }
  .custom-table-wrapper .blue,
  .custom-table-wrapper .pending {
    color: #1d2785;
    font-family: 'sukh-bold'; }
  .custom-table-wrapper .green,
  .custom-table-wrapper .approved {
    color: #589707;
    font-family: 'sukh-bold'; }
  .custom-table-wrapper .red,
  .custom-table-wrapper .rejected {
    color: #d70202;
    font-family: 'sukh-bold'; }
  .custom-table-wrapper .yellow {
    color: #f5a623;
    font-family: 'sukh-bold'; }
  .custom-table-wrapper.corona-table .button a {
    color: #f58b1e; }
  .custom-table-wrapper .ui.mini.label {
    margin: 4px -6px; }
  .custom-table-wrapper.timing-table .ui.search.dropdown {
    min-width: 100%; }
    .custom-table-wrapper.timing-table .ui.search.dropdown .menu {
      max-height: 150px; }
      .custom-table-wrapper.timing-table .ui.search.dropdown .menu .item {
        font-size: 12px;
        padding: 4px; }
  .custom-table-wrapper.timing-table .with-overflow {
    min-height: calc(100vh - 240px); }
  .custom-table-wrapper.affiliate-incentive-table table thead tr th:last-child {
    text-align: right; }
  .custom-table-wrapper.affiliate-incentive-table table tbody tr td:last-child {
    text-align: right; }
  .custom-table-wrapper.scrollable-table {
    width: 195%; }
    .custom-table-wrapper.scrollable-table .scrollable-notify {
      position: absolute;
      top: -18px;
      font-size: 14px;
      background-color: #f58b1e;
      color: #192383;
      padding: 2px; }
  .custom-table-wrapper .with-overflow {
    overflow-x: auto; }
  .custom-table-wrapper .text-ellipsis {
    overflow-x: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 5ch; }
  .custom-table-wrapper .action-wrapper {
    position: absolute;
    right: 0;
    top: 12px;
    padding: 4px 24px 4px 4px; }
  .custom-table-wrapper .menu-wrapper {
    display: inline-block;
    position: absolute;
    right: 12px;
    top: 0; }
    .custom-table-wrapper .menu-wrapper > div svg {
      fill: #c4cfe4;
      font-size: 18px; }
  .custom-table-wrapper .actions-column .ant-space {
    margin: 4px 16px 4px 26px; }
  .custom-table-wrapper table {
    width: 100%;
    margin-top: 20px;
    table-layout: fixed; }
    .custom-table-wrapper table .checkbox {
      padding: 0; }
    .custom-table-wrapper table input {
      border: 0;
      outline: 0;
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a; }
      .custom-table-wrapper table input:focus {
        border-bottom: 1px solid #efefef;
        padding-bottom: 2px;
        color: #000; }
    .custom-table-wrapper table .crm-label {
      border-radius: 11px;
      font-size: 10px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      line-height: normal;
      padding: 1px 4px;
      display: inline-block;
      margin-right: 4px;
      border: 1px solid #ccc;
      min-width: 40px;
      text-align: center;
      margin-bottom: 4px; }
      .custom-table-wrapper table .crm-label.red {
        color: #b04747;
        border: solid 1px #f8a0a0;
        background-color: #fff3f3; }
      .custom-table-wrapper table .crm-label.green {
        color: #378033;
        border: solid 1px #289a13;
        background-color: #f1fdf5; }
    .custom-table-wrapper table thead tr th {
      font-size: 12px;
      font-family: 'sukh-medium';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #242424;
      padding: 4px 12px 4px 0;
      text-align: left;
      border-bottom: solid 0.5px #e3e3e3;
      text-transform: capitalize; }
      .custom-table-wrapper table thead tr th.right {
        text-align: right !important; }
      .custom-table-wrapper table thead tr th label {
        margin-left: 0; }
      .custom-table-wrapper table thead tr th:first-child {
        padding-left: 24px; }
      .custom-table-wrapper table thead tr th:last-child {
        padding-right: 24px; }
    .custom-table-wrapper table tbody tr td {
      font-size: 12px;
      font-family: 'sukh-regular';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      padding: 12px 12px 12px 0;
      word-break: break-word;
      min-width: 160px;
      position: relative; }
      .custom-table-wrapper table tbody tr td.action {
        min-width: 200px; }
      .custom-table-wrapper table tbody tr td .flex {
        display: flex; }
        .custom-table-wrapper table tbody tr td .flex .more {
          padding: 4px;
          font-size: 10px;
          line-height: 1;
          margin-left: 4px; }
      .custom-table-wrapper table tbody tr td .select {
        margin: 0; }
        .custom-table-wrapper table tbody tr td .select > div {
          font-size: 12px; }
      .custom-table-wrapper table tbody tr td.right {
        text-align: right !important; }
      .custom-table-wrapper table tbody tr td .button {
        font-size: 10px;
        font-family: 'sukh-bold';
        padding: 6px 10px;
        border: 1px solid #ff901d;
        background: #ffffff;
        color: #ff901d;
        text-transform: none; }
      .custom-table-wrapper table tbody tr td:first-child {
        padding-left: 24px; }
      .custom-table-wrapper table tbody tr td:last-child {
        padding-right: 24px; }
      .custom-table-wrapper table tbody tr td.with-menu {
        position: relative;
        padding-right: 44px; }
      .custom-table-wrapper table tbody tr td > span.select-text {
        cursor: pointer;
        display: block; }
    .custom-table-wrapper table tbody tr:nth-child(even) td,
    .custom-table-wrapper table tbody tr:nth-child(even) th {
      background: #f8fafc; }
    .custom-table-wrapper table tbody tr:last-child td {
      padding-top: 24px; }
    .custom-table-wrapper table tbody tr:last-child td,
    .custom-table-wrapper table tbody tr:last-child th {
      border-bottom: 1px solid #d1d1d1; }
  .custom-table-wrapper.vertical-table table tr th {
    font-size: 12px;
    font-family: 'sukh-medium';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #242424;
    padding: 4px 12px 4px 0;
    text-align: left;
    border-bottom: solid 0.5px #e3e3e3;
    text-transform: capitalize; }
    .custom-table-wrapper.vertical-table table tr th.right {
      text-align: right !important; }
    .custom-table-wrapper.vertical-table table tr th label {
      margin-left: 0; }
    .custom-table-wrapper.vertical-table table tr th:first-child {
      padding-left: 24px; }
    .custom-table-wrapper.vertical-table table tr th:last-child {
      padding-right: 24px; }
  .custom-table-wrapper.vertical-table table tr td {
    font-size: 12px;
    font-family: 'sukh-regular';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    padding: 12px 12px 12px 0;
    word-break: break-word;
    min-width: 160px;
    position: relative; }
    .custom-table-wrapper.vertical-table table tr td.action {
      min-width: 200px; }
    .custom-table-wrapper.vertical-table table tr td .flex {
      display: flex; }
      .custom-table-wrapper.vertical-table table tr td .flex .more {
        padding: 4px;
        font-size: 10px;
        line-height: 1;
        margin-left: 4px; }
    .custom-table-wrapper.vertical-table table tr td .select {
      margin: 0; }
      .custom-table-wrapper.vertical-table table tr td .select > div {
        font-size: 12px; }
    .custom-table-wrapper.vertical-table table tr td.right {
      text-align: right !important; }
    .custom-table-wrapper.vertical-table table tr td .button {
      font-size: 10px;
      font-family: 'sukh-bold';
      padding: 6px 10px;
      border: 1px solid #ff901d;
      background: #ffffff;
      color: #ff901d;
      text-transform: none; }
    .custom-table-wrapper.vertical-table table tr td:first-child {
      padding-left: 24px; }
    .custom-table-wrapper.vertical-table table tr td:last-child {
      padding-right: 24px; }
    .custom-table-wrapper.vertical-table table tr td.with-menu {
      position: relative;
      padding-right: 44px; }
    .custom-table-wrapper.vertical-table table tr td > span.select-text {
      cursor: pointer;
      display: block; }
  .custom-table-wrapper.vertical-table table tr th {
    color: #8a95aa;
    font-family: 'sukh-regular'; }
  .custom-table-wrapper.vertical-table table tr td {
    border-bottom: solid 0.5px #e3e3e3; }

@media screen and (max-width: 1024px) {
  .sub-menu {
    display: none; }
  .referral-dashboard {
    padding: 0;
    flex-direction: column; }
    .referral-dashboard .content-viewport {
      overflow: auto;
      overflow-x: hidden;
      padding: 12px;
      flex-grow: 1; }
    .referral-dashboard.mobile .fixed-header {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1001;
      background: #fff;
      padding: 20px;
      display: flex;
      align-items: center; }
      .referral-dashboard.mobile .fixed-header img {
        margin-right: 12px; }
    .referral-dashboard.mobile.affiliate {
      height: 100%; }
      .referral-dashboard.mobile.affiliate .fixed-header {
        position: relative;
        padding: 16px 20px 16px 20px !important;
        z-index: 9; }
      .referral-dashboard.mobile.affiliate .mobile-content {
        height: 100%;
        margin: 0; }
        .referral-dashboard.mobile.affiliate .mobile-content .affiliate-dashboard-tabs {
          height: 100%; }
          .referral-dashboard.mobile.affiliate .mobile-content .affiliate-dashboard-tabs .content-wrapper {
            height: 100%;
            overflow-y: auto; }
    .referral-dashboard.mobile .list {
      border-bottom: solid 1px #efefef;
      padding: 24px 46px 24px 24px;
      background: #fff url(images/states/arrow-right.svg) no-repeat;
      background-position: calc(100% - 46px) center;
      display: flex;
      align-items: center; }
      .referral-dashboard.mobile .list img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        margin-right: 10px; }
      .referral-dashboard.mobile .list .text {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.85); }
    .referral-dashboard.mobile.fixed .mobile-modal {
      background: #fff;
      height: var(--vh);
      overflow-y: auto;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      z-index: 1000;
      padding: 120px 20px 20px; }
      .referral-dashboard.mobile.fixed .mobile-modal.no-search {
        padding: 100px 20px 20px; }
    .referral-dashboard.tutorials .empty-wrapper {
      width: 100%;
      text-align: center; }
      .referral-dashboard.tutorials .empty-wrapper .text {
        margin-top: 24px; }
    .referral-dashboard.tutorials .tabular-content {
      padding: 0 10px 10px 10px;
      margin: 0; }
      .referral-dashboard.tutorials .tabular-content .section {
        margin-top: 24px; }
        .referral-dashboard.tutorials .tabular-content .section .title {
          font-size: 14px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #192383;
          margin-bottom: 4px;
          margin: 0 0 12px 0; }
      .referral-dashboard.tutorials .tabular-content .tabs-wrapper .tab {
        min-width: 50%; }
    .referral-dashboard.tutorials .back-arrow {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 9999;
      background: #fff;
      display: flex;
      padding: 20px;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 0px 6px #d8e7fd; }
    .referral-dashboard .tabular-content {
      padding: 0 10px 80px 10px; }
      .referral-dashboard .tabular-content.referrals {
        margin-top: 8px; }
      .referral-dashboard .tabular-content .tabs-wrapper {
        border-radius: 4px;
        background-color: #ffffff; }
        .referral-dashboard .tabular-content .tabs-wrapper > div > div > span {
          top: 0;
          border-radius: 4px;
          background-color: #192383; }
        .referral-dashboard .tabular-content .tabs-wrapper .tab {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.85);
          border-radius: 4px;
          background-color: rgba(25, 35, 131, 0.09);
          margin-right: 0;
          padding: 0 10px;
          font-size: 11px; }
          .referral-dashboard .tabular-content .tabs-wrapper .tab:last-child {
            flex-grow: 1; }
          .referral-dashboard .tabular-content .tabs-wrapper .tab[aria-selected='true'] {
            font-family: 'sukh-bold';
            font-size: 14px;
            background: #fff; }
  .pip-list-wrap {
    border-radius: 10px 10px 0 0;
    background-color: #ffffff;
    padding: 24px 16px 16px;
    width: 100%; }
    .pip-list-wrap .title-text {
      font-size: 14px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 16px; }
    .pip-list-wrap .list {
      border-radius: 2px;
      border: solid 1px #f58b1e;
      background-color: #ffffff;
      padding: 8px 16px;
      margin: 0 0 6px 0;
      box-shadow: none;
      display: flex;
      align-items: center; }
      .pip-list-wrap .list img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        margin-right: 10px; }
      .pip-list-wrap .list svg {
        color: #f58b1e;
        margin-right: 12px;
        font-size: 24px; }
      .pip-list-wrap .list .text {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.85); } }

.no-display {
  display: none !important; }

.home-page {
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 12px 24px 24px; }
  .home-page .container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0; }
  .home-page .navigation-wrap {
    width: 100%; }
  .home-page .content {
    flex-grow: 1;
    padding: 0;
    margin-right: 20px; }
    .home-page .content .carousel-wrapper {
      width: 100%;
      margin-top: 24px; }
      .home-page .content .carousel-wrapper .slick-slider {
        max-width: 100%; }
    .home-page .content .middle-section {
      max-width: 700px;
      margin: 0 auto; }
      .home-page .content .middle-section .refer-earn {
        margin-bottom: 20px; }
    .home-page .content .card {
      border-radius: 8px;
      background: #e8903d url(images/new-landing/homepage-banner.jpg) no-repeat;
      background-size: 100% 100%;
      padding: 60px 20px 20px;
      margin: 30px 0 20px 0; }
      .home-page .content .card .text-one {
        font-size: 16px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        margin-bottom: 6px; }
      .home-page .content .card .text-two {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        margin-bottom: 12px; }
      .home-page .content .card .btn {
        padding: 7px 12px 6px;
        border-radius: 4px;
        background-color: #ffffff;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #e8903d;
        border: 0;
        outline: none;
        cursor: pointer; }
  .home-page .right-section {
    width: 280px; }
    .home-page .right-section .carousel-wrapper {
      max-width: 100%;
      min-width: 100%; }
      .home-page .right-section .carousel-wrapper .item {
        margin-bottom: 24px;
        overflow: hidden; }
        .home-page .right-section .carousel-wrapper .item img {
          width: 100%;
          height: 100%;
          border-radius: 8px; }
      .home-page .right-section .carousel-wrapper .slick-prev,
      .home-page .right-section .carousel-wrapper .slick-next {
        background: none;
        width: auto;
        height: auto;
        top: calc(100% - 6px);
        margin-top: 0; }
      .home-page .right-section .carousel-wrapper .slick-dots {
        bottom: 0; }
        .home-page .right-section .carousel-wrapper .slick-dots li.slick-active button:before {
          color: #192383; }
        .home-page .right-section .carousel-wrapper .slick-dots li button:before {
          line-height: 1 !important; }
    .home-page .right-section .month-picker {
      max-width: 148px;
      display: inline-block;
      width: 100%; }
      .home-page .right-section .month-picker .dropdown-datepicker .dropdown-month-of-year {
        margin-right: 0;
        border: solid 1px #d7d7d7; }
        .home-page .right-section .month-picker .dropdown-datepicker .dropdown-month-of-year .ui.search.selection.dropdown {
          border-radius: 4px;
          padding: 8px 8px 8px 0;
          min-height: auto;
          border: 0;
          padding-left: 30px;
          background: #ffffff url(images/icons/calendar.svg) no-repeat;
          background-position: 6px center; }
          .home-page .right-section .month-picker .dropdown-datepicker .dropdown-month-of-year .ui.search.selection.dropdown .text {
            font-size: 12px;
            font-family: 'sukh-medium';
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: #979393; }
          .home-page .right-section .month-picker .dropdown-datepicker .dropdown-month-of-year .ui.search.selection.dropdown .search {
            padding-left: 30px; }
    .home-page .right-section .col {
      margin-top: 10px; }
      .home-page .right-section .col .title-text {
        margin-bottom: 5px; }
    .home-page .right-section .card {
      padding: 12px 12px 10px 16px;
      border-radius: 6px;
      background-color: rgba(245, 139, 30, 0.1);
      min-height: 220px;
      margin-bottom: 20px; }
      .home-page .right-section .card .align-right {
        text-align: right;
        width: 100%; }
      .home-page .right-section .card .list {
        padding: 10px 0;
        display: flex;
        width: 100%;
        position: relative; }
        .home-page .right-section .card .list .dip,
        .home-page .right-section .card .list .growth {
          display: flex;
          align-items: center;
          margin-left: 12px; }
          .home-page .right-section .card .list .dip p,
          .home-page .right-section .card .list .growth p {
            font-size: 14px;
            font-family: 'sukh-medium';
            -webkit-text-stroke: initial; }
        .home-page .right-section .card .list .link {
          position: absolute;
          right: 0;
          bottom: 0;
          cursor: pointer; }
        .home-page .right-section .card .list:not(:last-child) {
          border-bottom: 1px solid rgba(245, 139, 30, 0.1); }
        .home-page .right-section .card .list .icon {
          width: 20px;
          height: 20px;
          margin-right: 8px; }
          .home-page .right-section .card .list .icon img {
            max-width: 100%; }
        .home-page .right-section .card .list .content-wrap {
          flex-grow: 1; }
        .home-page .right-section .card .list .text-one {
          opacity: 0.8;
          font-size: 14px;
          font-family: 'sukh-bold';
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #32302e; }
        .home-page .right-section .card .list .text-two {
          -webkit-text-stroke: 2px #32302e;
          font-size: 28px;
          font-family: 'sukh-bold';
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #32302e;
          display: flex;
          align-items: center; }
        .home-page .right-section .card .list .text-three {
          font-size: 18px;
          font-family: 'sukh-light';
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: rgba(0, 0, 0, 0.85); }
    .home-page .right-section .promotions-wrap {
      margin-top: 20px; }
    .home-page .right-section .youtube-player iframe {
      border-radius: 6px; }
  .home-page .credit-card {
    font-size: 14px;
    border-radius: 8px;
    background-color: #e8903d;
    background-image: linear-gradient(to top, #de812a, rgba(232, 144, 61, 0));
    padding: 0 20px 20px 20px;
    margin-bottom: 20px;
    color: #fff; }
    .home-page .credit-card .header {
      font-family: 'sukh-bold';
      padding-bottom: 5px; }
      .home-page .credit-card .header.padded-header {
        padding-top: 20px; }
    .home-page .credit-card .separator {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      margin: 15px 0; }
    .home-page .credit-card .btn {
      margin-top: 12px;
      padding: 7px 12px 6px;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 14px;
      line-height: normal;
      text-align: left;
      color: #e8903d;
      border: 0;
      outline: none;
      cursor: pointer; }
  .home-page .title-text {
    font-size: 20px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #192383;
    margin-bottom: 16px; }
  .home-page .link-button {
    border-radius: 4px;
    font-size: 24px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding: 10px 46px;
    background: #f58b1e url(images/right-arrow.svg) no-repeat;
    background-position: 95% center;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
    border: 0; }
  .home-page .navigation-wrap .carousel-wrapper .carousel-one {
    margin-bottom: 10px; }
    .home-page .navigation-wrap .carousel-wrapper .carousel-one .slick-slide {
      box-shadow: 0 7px 7px 0 #d9d9db;
      border-radius: 8px;
      max-height: 280px;
      max-width: 100%;
      overflow: hidden;
      margin-right: 20px; }
      .home-page .navigation-wrap .carousel-wrapper .carousel-one .slick-slide img {
        width: 100%;
        height: 100%; }
    .home-page .navigation-wrap .carousel-wrapper .carousel-one .slick-next,
    .home-page .navigation-wrap .carousel-wrapper .carousel-one .slick-prev {
      margin-top: 32px !important; }
    .home-page .navigation-wrap .carousel-wrapper .carousel-one .slick-dots {
      bottom: -40px !important; }
  .home-page .navigation-wrap .title-text {
    font-size: 14px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    margin: 12px 0 12px 0; }
  .home-page .navigation-wrap .grid-wrap {
    display: block; }
    .home-page .navigation-wrap .grid-wrap .list {
      border-radius: 2px;
      border: solid 1px #ff901d;
      background-color: #ffffff;
      padding: 8px 12px;
      margin: 0 0 4px 0;
      box-shadow: none;
      display: flex;
      align-items: center; }
      .home-page .navigation-wrap .grid-wrap .list > div:first-child {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start; }
        .home-page .navigation-wrap .grid-wrap .list > div:first-child img {
          margin-right: 10px; }
      .home-page .navigation-wrap .grid-wrap .list > div:last-child {
        max-width: 60px; }
      .home-page .navigation-wrap .grid-wrap .list p {
        font-size: 14px;
        font-family: 'sukh-regular';
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        text-align: left; }
      .home-page .navigation-wrap .grid-wrap .list .img-wrap {
        width: 45px;
        height: 47px;
        margin: 0 auto 12px auto; }
        .home-page .navigation-wrap .grid-wrap .list .img-wrap img {
          width: 100%; }
  .home-page .onboarding-trigger {
    cursor: pointer;
    font-size: 14px;
    font-family: 'sukh-bold';
    background-repeat: no-repeat;
    position: absolute;
    top: 20px;
    height: 50px;
    padding: 18px 0 0 56px;
    width: 250px;
    background-size: contain;
    background-image: url(images/onboarding-play.svg);
    z-index: 2; }
  .home-page .carousel-wrapper .slick-prev,
  .home-page .carousel-wrapper .slick-next {
    background: none;
    width: auto;
    height: auto;
    top: 100%;
    margin-top: 5px;
    z-index: 2;
    border: 0; }
    .home-page .carousel-wrapper .slick-prev:before, .home-page .carousel-wrapper .slick-prev:after,
    .home-page .carousel-wrapper .slick-next:before,
    .home-page .carousel-wrapper .slick-next:after {
      font-size: 14px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383; }
  .home-page .carousel-wrapper .slick-prev {
    left: calc(50% - 60px); }
    .home-page .carousel-wrapper .slick-prev:before {
      content: 'ก่อน'; }
  .home-page .carousel-wrapper .slick-next {
    right: calc(50% - 60px); }
    .home-page .carousel-wrapper .slick-next:before {
      content: 'ต่อไป'; }
  .home-page .carousel-wrapper .slick-dots {
    bottom: -12px; }
    .home-page .carousel-wrapper .slick-dots li.slick-active button:before {
      color: #192383; }
    .home-page .carousel-wrapper .slick-dots li button:before {
      line-height: 1 !important; }
  .home-page .row {
    display: flex;
    width: 100%;
    max-width: 100%;
    align-items: flex-start; }
    .home-page .row .col:first-child {
      margin-right: 90px;
      flex-grow: 1;
      max-width: calc(100% - 400px); }
      .home-page .row .col:first-child .refer-earn,
      .home-page .row .col:first-child .upload-doc-bar {
        border-radius: 6px;
        box-shadow: 0 3px 8px 0 rgba(224, 224, 226, 0.41);
        background-image: linear-gradient(275deg, #aad7ff, #070b6c); }
        .home-page .row .col:first-child .refer-earn p,
        .home-page .row .col:first-child .upload-doc-bar p {
          color: #fff; }
      .home-page .row .col:first-child .link-button {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 12px 10px; }
        .home-page .row .col:first-child .link-button img {
          margin-right: 8px; }
      .home-page .row .col:first-child .buttons-wrap {
        display: flex;
        align-items: center; }
        .home-page .row .col:first-child .buttons-wrap a {
          border-radius: 2px;
          border: solid 2px #f58b1e;
          background-color: #ffffff;
          font-size: 15px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #f58b1e;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 8px 10px;
          flex-grow: 1;
          flex-basis: 100%; }
          .home-page .row .col:first-child .buttons-wrap a span {
            margin-right: 8px;
            width: 30px;
            height: 30px;
            border: solid 1.1px #f58b1e;
            background-color: #ffffff;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center; }
          .home-page .row .col:first-child .buttons-wrap a:not(:last-child) {
            margin-right: 12px; }
      .home-page .row .col:first-child .carousel-wrapper {
        max-width: 100%; }
        .home-page .row .col:first-child .carousel-wrapper .slick-dots {
          bottom: -30px; }
        .home-page .row .col:first-child .carousel-wrapper .slick-prev,
        .home-page .row .col:first-child .carousel-wrapper .slick-next {
          background: none;
          width: auto;
          height: auto;
          top: 100%;
          margin-top: 8px; }
      .home-page .row .col:first-child .slick-slider {
        margin: 0 auto; }
    .home-page .row .col:last-child {
      max-width: 320px;
      min-width: 320px; }
      .home-page .row .col:last-child .carousel-wrapper .item {
        margin-bottom: 24px; }
        .home-page .row .col:last-child .carousel-wrapper .item img {
          width: 100%;
          height: 100%; }
      .home-page .row .col:last-child .carousel-wrapper .slick-prev,
      .home-page .row .col:last-child .carousel-wrapper .slick-next {
        background: none;
        width: auto;
        height: auto;
        top: 100%; }
      .home-page .row .col:last-child .carousel-wrapper .slick-dots {
        bottom: -12px; }
        .home-page .row .col:last-child .carousel-wrapper .slick-dots li.slick-active button:before {
          color: #192383; }
        .home-page .row .col:last-child .carousel-wrapper .slick-dots li button:before {
          line-height: 1 !important; }
  .home-page .line {
    opacity: 0.3;
    border: solid 1px #979797;
    margin: 50px 0; }
  .home-page .video-player {
    width: 100%;
    height: 380px;
    background: url(images/tutorials/play.svg), url(https://img.youtube.com/vi/2yXUXYPWswY/0.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: 10%, cover;
    background-position: center center, center center;
    margin: 0 auto;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    margin-top: 34px; }
  .home-page .youtube-player {
    margin-bottom: 20px; }
    .home-page .youtube-player iframe {
      border-radius: 6px; }
  .home-page .dynamic-wrapper {
    display: flex; }
    .home-page .dynamic-wrapper > div {
      flex-grow: 1;
      flex-basis: 100%; }
      .home-page .dynamic-wrapper > div:first-child {
        margin-right: 24px; }
    .home-page .dynamic-wrapper .installment-banner {
      cursor: pointer; }
      .home-page .dynamic-wrapper .installment-banner img {
        max-width: 100%;
        border-radius: 8px;
        height: 146px; }

.main-content {
  display: flex;
  align-items: flex-start;
  width: 100%; }

.sidebar {
  min-width: 60px;
  max-width: 60px;
  min-height: var(--vh);
  padding: 64px 0 22px;
  position: relative;
  overflow: hidden;
  background-color: #151d68;
  transition: all 250ms linear; }
  .sidebar .fixed-section {
    position: fixed;
    background-color: #151d68;
    min-width: 60px;
    max-width: 60px;
    height: calc(var(--vh) - 64px);
    padding: 40px 0 22px;
    top: 64px;
    left: 0;
    bottom: 0;
    transition: all 250ms linear 0;
    z-index: 1; }
  .sidebar:hover {
    min-width: 250px;
    max-width: 250px;
    overflow-y: auto;
    overflow-x: hidden; }
    .sidebar:hover .fixed-section {
      min-width: 250px;
      max-width: 250px;
      overflow-y: auto;
      overflow-x: hidden; }
    .sidebar:hover .refer-card {
      visibility: visible;
      height: auto;
      transition: all 100ms linear 100ms; }
    .sidebar:hover .nav-wrap {
      padding-bottom: 160px; }
    .sidebar:hover .list .text {
      visibility: visible;
      width: auto;
      height: auto;
      max-width: 180px;
      min-width: 180px;
      transition: all 100ms linear 100ms; }
  .sidebar .list {
    padding: 20px 0;
    display: flex;
    align-items: center;
    cursor: pointer; }
    .sidebar .list:hover {
      background-color: rgba(159, 162, 180, 0.1); }
      .sidebar .list:hover .text {
        font-family: 'sukh-bold';
        letter-spacing: 0.2px;
        text-align: left;
        color: #dde2ff;
        opacity: 1; }
      .sidebar .list:hover .icon .dark {
        display: none; }
      .sidebar .list:hover .icon .light {
        display: block; }
      .sidebar .list:hover .icon svg {
        color: #fff;
        opacity: 1; }
    .sidebar .list.active {
      border-left: 3px solid #f58b1e;
      background-color: rgba(159, 162, 180, 0.1); }
      .sidebar .list.active .text {
        font-family: 'sukh-bold';
        letter-spacing: 0.2px;
        text-align: left;
        color: #dde2ff;
        opacity: 1; }
      .sidebar .list.active .icon .dark {
        display: none; }
      .sidebar .list.active .icon .light {
        display: block; }
      .sidebar .list.active .icon svg {
        color: #fff;
        opacity: 1; }
    .sidebar .list .icon {
      margin-right: 0;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 50px;
      min-width: 50px;
      min-height: 19px; }
      .sidebar .list .icon svg {
        color: #dde2ff;
        opacity: 0.1;
        font-size: 24px; }
      .sidebar .list .icon .dark {
        display: block; }
      .sidebar .list .icon .light {
        display: none; }
      .sidebar .list .icon img {
        width: 16px;
        height: 16px; }
    .sidebar .list .text {
      opacity: 0.8;
      font-size: 16px;
      line-height: 1;
      font-stretch: normal;
      letter-spacing: 0.2px;
      text-align: left;
      color: #dde2ff;
      visibility: hidden;
      width: 0;
      height: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%; }
  .sidebar .refer-card {
    padding: 18px;
    border-radius: 6px;
    background-color: #fef3e8;
    background-image: linear-gradient(to top, #ffebd8, rgba(254, 243, 232, 0));
    position: relative;
    margin: 12px;
    visibility: hidden;
    width: 0;
    height: 0;
    bottom: 0;
    max-width: 220px;
    width: 100%; }
    .sidebar .refer-card .text-one {
      font-size: 16px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #32302e;
      margin-bottom: 4px; }
      .sidebar .refer-card .text-one span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #32302e;
        margin-bottom: 16px; }
    .sidebar .refer-card .link-text {
      font-size: 12px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #f58b1e; }

@media screen and (max-width: 1024px) {
  .home-page {
    padding: 12px; }
    .home-page .dynamic-promotions {
      margin: 0 0 16px 0; }
      .home-page .dynamic-promotions:after {
        display: none; }
    .home-page .dynamic-wrapper {
      flex-direction: column;
      margin-bottom: 16px; }
      .home-page .dynamic-wrapper > div:first-child {
        margin: 0 0 16px 0; }
    .home-page .video-player {
      margin: 0 0 60px 0;
      height: 280px; }
    .home-page .navigation-wrap .carousel-wrapper .carousel-one {
      margin-bottom: 10px; }
      .home-page .navigation-wrap .carousel-wrapper .carousel-one .slick-slide {
        box-shadow: 0 7px 7px 0 #d9d9db;
        border-radius: 8px;
        max-height: 280px;
        max-width: 100%;
        overflow: hidden;
        margin-right: 20px; }
        .home-page .navigation-wrap .carousel-wrapper .carousel-one .slick-slide img {
          width: 100%;
          height: 100%; }
      .home-page .navigation-wrap .carousel-wrapper .carousel-one .slick-next,
      .home-page .navigation-wrap .carousel-wrapper .carousel-one .slick-prev {
        margin-top: 32px !important; }
      .home-page .navigation-wrap .carousel-wrapper .carousel-one .slick-dots {
        bottom: -40px !important; }
    .home-page .navigation-wrap .title-text {
      font-size: 14px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      margin: 12px 0 12px 0; }
    .home-page .navigation-wrap .grid-wrap {
      display: block; }
      .home-page .navigation-wrap .grid-wrap .list {
        border-radius: 2px;
        border: solid 1px #ff901d;
        background-color: #ffffff;
        padding: 8px 12px;
        margin: 0 0 4px 0;
        box-shadow: none;
        display: flex;
        align-items: center; }
        .home-page .navigation-wrap .grid-wrap .list > div:first-child {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start; }
          .home-page .navigation-wrap .grid-wrap .list > div:first-child img {
            margin-right: 10px; }
        .home-page .navigation-wrap .grid-wrap .list > div:last-child {
          max-width: 60px; }
        .home-page .navigation-wrap .grid-wrap .list p {
          font-size: 14px;
          font-family: 'sukh-regular';
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a;
          text-align: left; }
        .home-page .navigation-wrap .grid-wrap .list .img-wrap {
          width: 45px;
          height: 47px;
          margin: 0 auto 12px auto; }
          .home-page .navigation-wrap .grid-wrap .list .img-wrap img {
            width: 100%; }
    .home-page .row {
      padding-bottom: 20px; }
      .home-page .row .col:first-child {
        margin-right: 0;
        max-width: 100%; }
        .home-page .row .col:first-child .carousel-wrapper {
          max-width: 100%;
          margin: 0 auto;
          width: 100%; }
      .home-page .row .col:last-child {
        max-width: 100%; } }

.tutorial-card {
  border-radius: 6px;
  box-shadow: 1px 2px 3px 0 rgba(60, 60, 80, 0.22);
  background-color: #ffffff;
  border-radius: 0;
  margin-bottom: 8px;
  width: 100%; }
  .tutorial-card .media-wrapper {
    height: 170px;
    position: relative;
    cursor: pointer; }
    .tutorial-card .media-wrapper img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px 6px 0 0; }
    .tutorial-card .media-wrapper .play {
      position: absolute;
      height: 44px;
      width: 44px;
      object-fit: contain;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer; }
  .tutorial-card .content-wrapper {
    padding: 12px; }
    .tutorial-card .content-wrapper .title-text {
      font-size: 14px;
      font-family: 'sukh-bold';
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383;
      margin-bottom: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .tutorial-card .content-wrapper .text {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 20px; }
  .tutorial-card .action-wrapper {
    display: flex;
    align-items: center;
    margin-top: 20px; }
    .tutorial-card .action-wrapper > div {
      display: flex;
      align-items: center;
      padding: 0 8px 0 8px; }
      .tutorial-card .action-wrapper > div img {
        margin-right: 8px; }
      .tutorial-card .action-wrapper > div:first-child {
        padding-left: 0; }
      .tutorial-card .action-wrapper > div:last-child {
        flex-grow: 1;
        justify-content: flex-end;
        padding-right: 0; }
    .tutorial-card .action-wrapper span {
      display: flex;
      align-items: center;
      font-size: 14.4px;
      color: #000000; }
      .tutorial-card .action-wrapper span.btn {
        cursor: pointer;
        border-radius: 2px;
        border: solid 1px #f58b1e;
        padding: 3px 8px;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #f58b1e; }
  .tutorial-card .tag {
    border-radius: 4px;
    background-color: #ffa600;
    padding: 4px 12px;
    font-size: 12px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: #ffffff; }
  .tutorial-card .media-wrapper,
  .tutorial-card .content-wrapper {
    flex-grow: 1;
    flex-basis: 100%;
    min-width: 50%; }
  .tutorial-card .content-wrapper {
    position: relative; }
    .tutorial-card .content-wrapper .action-wrapper {
      position: absolute;
      right: 8px;
      left: 12px;
      bottom: 8px; }

.search-wrapper {
  background-color: #192383;
  padding: 68px 12px 20px 12px;
  margin: 0 -16px 0 -16px; }

.search-bar {
  border-radius: 24px;
  background-color: #ffffff;
  position: relative;
  z-index: 2;
  max-width: 400px;
  margin: 0 auto; }
  .search-bar input {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #b9c0d3;
    border: 0;
    outline: 0;
    padding: 12px 12px 12px 52px;
    background: url(images/tutorials/search.svg) no-repeat;
    background-position: 20px center;
    width: 100%; }

.tutorials {
  padding: 12px 0 0 0 !important;
  min-height: calc(var(--vh) - 58px);
  padding-bottom: 160px;
  position: relative;
  max-width: 480px;
  margin: 0 auto;
  background: #f6f9ff;
  padding: 12px; }
  .tutorials .row-content {
    margin-bottom: 24px; }
  .tutorials .sub-title {
    font-size: 16px;
    font-family: 'sukh-bold';
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
    margin: 0 0 16px 0; }

.tutorials-wrapper .title {
  font-size: 24px;
  font-family: 'sukh-bold';
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  margin: 0 0 16px 0; }

.tutorials-wrapper .section {
  margin: 0 auto;
  max-width: 980px;
  margin-bottom: 20px; }
  .tutorials-wrapper .section .row {
    display: block; }
    .tutorials-wrapper .section .row > div {
      display: inline-block;
      width: calc(25% - 40px);
      margin: 0 40px 40px 0; }

.tutorials-wrapper .slick-slider {
  max-width: 100%; }
  .tutorials-wrapper .slick-slider .slick-list {
    padding-bottom: 12px; }
    .tutorials-wrapper .slick-slider .slick-list .slick-track {
      margin-left: 0;
      padding: 12px 0; }
    .tutorials-wrapper .slick-slider .slick-list .slick-slide > div {
      margin-right: 20px; }
  .tutorials-wrapper .slick-slider .slick-next,
  .tutorials-wrapper .slick-slider .slick-prev {
    top: 50%; }
    .tutorials-wrapper .slick-slider .slick-next.slick-disabled,
    .tutorials-wrapper .slick-slider .slick-prev.slick-disabled {
      display: none !important; }
  .tutorials-wrapper .slick-slider .slick-next {
    background: #fff url(images/arrow-right.svg) no-repeat;
    background-size: 15px;
    background-position: center center; }
  .tutorials-wrapper .slick-slider .slick-prev {
    left: -52px;
    background: #fff url(images/arrow-left.svg) no-repeat;
    background-size: 15px;
    background-position: center center; }

.about-us {
  padding-top: 40px; }
  .about-us .banner-wrapper {
    height: 690px;
    background: url(images/about-us/banner.png) no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 46px; }
    .about-us .banner-wrapper .content {
      max-width: 1072px;
      margin: 0 auto;
      text-align: center; }
      .about-us .banner-wrapper .content .title-text {
        font-size: 30px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 10px; }
      .about-us .banner-wrapper .content .sub-text {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff; }
  .about-us .section .title-text {
    position: relative;
    font-size: 30px;
    font-family: 'sukh-bold';
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #262626;
    margin-bottom: 12px; }
    .about-us .section .title-text::before {
      content: '';
      position: absolute;
      height: 6px;
      border-radius: 3px;
      background-color: #f89734;
      width: 60px;
      top: -20px; }
  .about-us .section .sub-text {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #262626; }
    .about-us .section .sub-text:not(:last-child) {
      margin-bottom: 16px; }
  .about-us .container {
    max-width: 1216px;
    margin: 0 auto; }
  .about-us .section-one,
  .about-us .section-two {
    padding: 65px 0 105px 0; }
    .about-us .section-one .row,
    .about-us .section-two .row {
      display: flex;
      align-items: center; }
      .about-us .section-one .row .col,
      .about-us .section-two .row .col {
        flex-grow: 1;
        flex-basis: 100%; }
        .about-us .section-one .row .col:last-child,
        .about-us .section-two .row .col:last-child {
          margin-left: 70px; }
  .about-us .section-two {
    padding: 100px 0;
    background: url(images/about-us/background-two.png) no-repeat;
    background-position: center center;
    background-size: 100% 100%; }
    .about-us .section-two .blue-text {
      font-size: 30px;
      font-family: 'sukh-bold';
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      color: #192383;
      margin-top: 18px; }
  .about-us .section-three {
    background-color: #192383;
    padding: 80px 0; }
    .about-us .section-three .title-text {
      color: #fff; }
    .about-us .section-three .sub-text {
      color: #fff;
      max-width: 514px; }
    .about-us .section-three .sub-title {
      font-size: 30px;
      font-family: 'sukh-bold';
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: rgba(255, 255, 255, 0.85);
      margin: 80px 0 32px 0; }
    .about-us .section-three .row {
      display: flex;
      align-items: flex-start;
      margin-bottom: 64px; }
      .about-us .section-three .row .col {
        flex-grow: 1;
        flex-basis: 100%; }
        .about-us .section-three .row .col:first-child {
          margin-right: 120px; }
        .about-us .section-three .row .col img {
          max-width: 100%;
          border-radius: 12px;
          background-color: #d8d8d8;
          height: 340px; }
        .about-us .section-three .row .col .text {
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: center;
          color: rgba(255, 255, 255, 0.85); }
  .about-us .section-four {
    padding: 80px 0;
    background: url(images/about-us/background.png) no-repeat;
    background-position: center center;
    background-size: 100% 100%; }
    .about-us .section-four .title-text {
      margin-bottom: 40px; }
    .about-us .section-four .slick-slider {
      width: 100%;
      max-width: 100%; }
      .about-us .section-four .slick-slider .slick-arrow {
        width: 44px;
        height: 44px;
        opacity: 0.9;
        border: 0;
        top: calc(50% - 22px);
        z-index: 9; }
        .about-us .section-four .slick-slider .slick-arrow.slick-next {
          background: url(images/latest-landing/arrow-right.svg) no-repeat;
          background-size: contain;
          background-position: center center;
          right: 64px; }
        .about-us .section-four .slick-slider .slick-arrow.slick-prev {
          background: url(images/latest-landing/arrow-left.svg) no-repeat;
          background-size: contain;
          background-position: center center;
          left: 64px; }
    .about-us .section-four .news {
      margin-right: 40px;
      cursor: pointer; }
      .about-us .section-four .news img {
        max-width: 100%; }
    .about-us .section-four .investors {
      margin-top: 90px; }
      .about-us .section-four .investors .title-text {
        margin-bottom: 20px; }
      .about-us .section-four .investors .sub-text {
        max-width: 573px; }
      .about-us .section-four .investors .row {
        margin-top: 40px;
        display: flex; }
        .about-us .section-four .investors .row .col {
          min-width: 255px;
          max-width: 255px;
          border-radius: 12.8px; }
          .about-us .section-four .investors .row .col img {
            max-width: 100%; }
          .about-us .section-four .investors .row .col .text {
            font-family: SukhumvitSet;
            font-size: 20px;
            font-family: 'sukh-medium';
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            color: #262626;
            margin-top: 20px; }
          .about-us .section-four .investors .row .col:not(:last-child) {
            margin-right: 140px; }
  .about-us .section-five {
    padding: 123px 0 64px 0;
    background: url(images/about-us/background-one.png) no-repeat;
    background-position: center center;
    background-size: 100% 100%; }
    .about-us .section-five .map {
      width: 100%;
      height: 435px;
      object-fit: contain;
      box-shadow: 0 2px 15px 0 rgba(211, 193, 176, 0.37);
      margin-bottom: 24px; }
    .about-us .section-five .row {
      display: flex;
      align-items: center; }
      .about-us .section-five .row .center {
        text-align: center; }
      .about-us .section-five .row .no-margin {
        margin: 48px 0 0; }
      .about-us .section-five .row .bold-text {
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.85);
        margin: 30px 0 8px 0; }
      .about-us .section-five .row .sub-text a {
        color: inherit; }
      .about-us .section-five .row .col {
        flex-grow: 1;
        flex-basis: 100%; }
        .about-us .section-five .row .col img {
          max-width: 100%; }
        .about-us .section-five .row .col:not(:last-child) {
          margin-right: 60px; }

.about-us.mobile {
  padding-top: 0; }
  .about-us.mobile .banner-wrapper {
    background: url(images/about-us/banner-mobile.png) no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    height: auto;
    padding: 24px;
    height: 630px; }
    .about-us.mobile .banner-wrapper .content .title-text {
      font-size: 24px; }
    .about-us.mobile .banner-wrapper .content .sub-text {
      font-size: 16px; }
  .about-us.mobile .section .title-text {
    font-size: 24px;
    margin-bottom: 8px; }
  .about-us.mobile .section .sub-text {
    font-size: 16px; }
  .about-us.mobile .section-one {
    position: relative;
    padding: 95px 0 56px 0; }
    .about-us.mobile .section-one .title-text {
      position: absolute;
      top: 75px; }
    .about-us.mobile .section-one .row {
      flex-direction: column; }
      .about-us.mobile .section-one .row .col img {
        max-width: 100%; }
      .about-us.mobile .section-one .row .col:first-child {
        margin-bottom: 32px; }
      .about-us.mobile .section-one .row .col:last-child {
        margin: 0; }
  .about-us.mobile .section-two {
    position: relative;
    padding: 56px 0; }
    .about-us.mobile .section-two .blue-text {
      font-size: 20px; }
    .about-us.mobile .section-two .row {
      flex-direction: column; }
      .about-us.mobile .section-two .row .col img {
        max-width: 100%;
        margin-bottom: 32px; }
      .about-us.mobile .section-two .row .col .youtube-video {
        width: 100%; }
      .about-us.mobile .section-two .row .col:first-child {
        margin-top: 42px;
        order: 2; }
      .about-us.mobile .section-two .row .col:last-child {
        margin: 0; }
  .about-us.mobile .section-three {
    background-color: #192383;
    padding: 56px 0; }
    .about-us.mobile .section-three .title-text {
      color: #fff; }
    .about-us.mobile .section-three .sub-text {
      color: #fff;
      max-width: 100%; }
    .about-us.mobile .section-three .sub-title {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.22;
      letter-spacing: normal;
      color: rgba(255, 255, 255, 0.85);
      margin: 20px 0 16px 0; }
    .about-us.mobile .section-three .row {
      flex-direction: column;
      margin-bottom: 0; }
      .about-us.mobile .section-three .row .col {
        margin-bottom: 40px; }
        .about-us.mobile .section-three .row .col img {
          height: auto; }
        .about-us.mobile .section-three .row .col:first-child {
          margin: 0;
          margin-bottom: 40px; }
        .about-us.mobile .section-three .row .col .text {
          font-size: 14px; }
  .about-us.mobile .section-four {
    padding: 58px 0; }
    .about-us.mobile .section-four .news {
      margin: 0; }
    .about-us.mobile .section-four .investors .row {
      flex-direction: column; }
      .about-us.mobile .section-four .investors .row .col {
        width: 100%;
        min-width: auto;
        max-width: 100%; }
        .about-us.mobile .section-four .investors .row .col .text {
          margin-top: 16px; }
        .about-us.mobile .section-four .investors .row .col:not(:last-child) {
          margin: 0 0 24px 0; }
  .about-us.mobile .section-five {
    padding: 56px 0; }
    .about-us.mobile .section-five .row {
      flex-direction: column; }
      .about-us.mobile .section-five .row .col {
        width: 100%; }
        .about-us.mobile .section-five .row .col:not(:last-child) {
          margin: 0 0 32px 0; }
      .about-us.mobile .section-five .row .center {
        text-align: left; }
      .about-us.mobile .section-five .row .bold-text {
        font-size: 22px; }

@media screen and (max-width: 768px) {
  .about-us {
    padding-top: 0; }
    .about-us .banner-wrapper {
      background: url(images/about-us/banner-mobile.png) no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      height: auto;
      padding: 24px;
      height: 630px; }
      .about-us .banner-wrapper .content .title-text {
        font-size: 24px; }
      .about-us .banner-wrapper .content .sub-text {
        font-size: 16px; }
    .about-us .section .title-text {
      font-size: 24px;
      margin-bottom: 8px; }
    .about-us .section .sub-text {
      font-size: 16px; }
    .about-us .section-one {
      position: relative;
      padding: 95px 0 56px 0; }
      .about-us .section-one .title-text {
        position: absolute;
        top: 75px; }
      .about-us .section-one .row {
        flex-direction: column; }
        .about-us .section-one .row .col img {
          max-width: 100%; }
        .about-us .section-one .row .col:first-child {
          margin-bottom: 32px; }
        .about-us .section-one .row .col:last-child {
          margin: 0; }
    .about-us .section-two {
      position: relative;
      padding: 56px 0; }
      .about-us .section-two .blue-text {
        font-size: 20px; }
      .about-us .section-two .row {
        flex-direction: column; }
        .about-us .section-two .row .col img {
          max-width: 100%;
          margin-bottom: 32px; }
        .about-us .section-two .row .col .youtube-video {
          width: 100%; }
        .about-us .section-two .row .col:first-child {
          margin-top: 42px;
          order: 2; }
        .about-us .section-two .row .col:last-child {
          margin: 0; }
    .about-us .section-three {
      background-color: #192383;
      padding: 56px 0; }
      .about-us .section-three .title-text {
        color: #fff; }
      .about-us .section-three .sub-text {
        color: #fff;
        max-width: 100%; }
      .about-us .section-three .sub-title {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.22;
        letter-spacing: normal;
        color: rgba(255, 255, 255, 0.85);
        margin: 20px 0 16px 0; }
      .about-us .section-three .row {
        flex-direction: column;
        margin-bottom: 0; }
        .about-us .section-three .row .col {
          margin-bottom: 40px; }
          .about-us .section-three .row .col img {
            height: auto; }
          .about-us .section-three .row .col:first-child {
            margin: 0;
            margin-bottom: 40px; }
          .about-us .section-three .row .col .text {
            font-size: 14px; }
    .about-us .section-four {
      padding: 58px 0; }
      .about-us .section-four .news {
        margin: 0; }
      .about-us .section-four .investors .row {
        flex-direction: column; }
        .about-us .section-four .investors .row .col {
          width: 100%;
          min-width: auto;
          max-width: 100%; }
          .about-us .section-four .investors .row .col .text {
            margin-top: 16px; }
          .about-us .section-four .investors .row .col:not(:last-child) {
            margin: 0 0 24px 0; }
    .about-us .section-five {
      padding: 56px 0; }
      .about-us .section-five .row {
        flex-direction: column; }
        .about-us .section-five .row .col {
          width: 100%; }
          .about-us .section-five .row .col:not(:last-child) {
            margin: 0 0 32px 0; }
        .about-us .section-five .row .center {
          text-align: left; }
        .about-us .section-five .row .bold-text {
          font-size: 22px; } }

.road-tax-calculator .mb-24 {
  margin-bottom: 24px; }

.road-tax-calculator .content-wrapper {
  background: url(images/road-tax-calculator/background.png) no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 56px;
  position: relative; }
  .road-tax-calculator .content-wrapper:after {
    content: '';
    position: absolute;
    height: 100px;
    border-radius: 100% 100% 0 0;
    background: #192383;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0; }
  .road-tax-calculator .content-wrapper .content {
    max-width: 1080px;
    margin: 0 auto; }
  .road-tax-calculator .content-wrapper .form-wrapper {
    background: url(images/road-tax-calculator/form-background.png) no-repeat;
    background-size: cover;
    padding: 42px 56px;
    min-height: 640px;
    border-radius: 8px;
    margin-top: 40px;
    position: relative; }
    .road-tax-calculator .content-wrapper .form-wrapper.white {
      background: #fff; }
    .road-tax-calculator .content-wrapper .form-wrapper > div {
      display: flex; }
    .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper {
      flex-grow: 1;
      padding: 0 44px; }
      .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper .title-text {
        font-size: 32px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        margin-bottom: 24px;
        background: transparent;
        -webkit-text-stroke: 0;
        font-family: inherit; }
      .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper .key {
        font-size: 18px;
        font-family: 'sukh-medium';
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        margin-bottom: 4px; }
      .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper .value {
        font-size: 24px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        margin-bottom: 24px; }
        .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper .value.red {
          color: #b32424; }
        .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper .value.blue {
          color: #192383; }
      .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper .line {
        margin-top: 0; }
    .road-tax-calculator .content-wrapper .form-wrapper .line {
      border-bottom: 2px solid #efebeb;
      margin-bottom: 16px; }
    .road-tax-calculator .content-wrapper .form-wrapper .form {
      max-width: 410px;
      margin-right: 100px; }
      .road-tax-calculator .content-wrapper .form-wrapper .form .form-field {
        margin-bottom: 16px; }
        .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .label {
          font-size: 14px;
          font-family: 'sukh-medium';
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a;
          margin-bottom: 4px;
          display: block; }
          .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .label.flex {
            display: flex; }
            .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .label.flex span {
              flex-grow: 1;
              text-align: right; }
        .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .selection.dropdown {
          width: 100%;
          border-radius: 6px;
          border: solid 1px #cbcbcb;
          background-color: #ffffff;
          min-height: auto;
          padding: 9px;
          background: url(images/arrow-down.svg) no-repeat;
          background-position: calc(100% - 10px) center; }
          .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .selection.dropdown > .text {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #232323;
            text-transform: uppercase; }
          .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .selection.dropdown input {
            text-transform: uppercase; }
          .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .selection.dropdown .menu div span {
            text-transform: uppercase; }
          .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .selection.dropdown .dropdown.icon {
            top: auto;
            display: none; }
        .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .dropdown-datepicker > div {
          flex-grow: 1;
          flex-basis: 100%; }
          .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .dropdown-datepicker > div .selection.dropdown {
            border-radius: 6px;
            border: solid 1px #cbcbcb;
            background-color: #ffffff;
            min-width: auto; }
            .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .dropdown-datepicker > div .selection.dropdown .icon {
              display: none; }
          .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .dropdown-datepicker > div:last-child {
            margin-right: 0; }
        .road-tax-calculator .content-wrapper .form-wrapper .form .form-field input {
          border-radius: 6px;
          border: solid 1px #cbcbcb;
          background-color: #ffffff;
          padding: 9px;
          width: 100%;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #232323;
          outline: none; }
        .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .button {
          border-radius: 6px;
          background-color: #ff901d;
          font-size: 14px;
          font-family: 'sukh-bold';
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: #ffffff;
          text-transform: none;
          padding: 12px;
          width: 100%; }
      .road-tax-calculator .content-wrapper .form-wrapper .form .helper-text {
        font-size: 14px;
        font-family: 'sukh-medium';
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        margin-bottom: 24px; }
        .road-tax-calculator .content-wrapper .form-wrapper .form .helper-text span {
          font-family: 'sukh-bold';
          color: #192383; }
  .road-tax-calculator .content-wrapper .title-text {
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #ffffff;
    -webkit-text-stroke: 2px #ffffff;
    font-family: 'Chonburi', cursive;
    background-color: #192383;
    display: inline-block;
    padding: 4px; }
  .road-tax-calculator .content-wrapper .sub-text {
    font-size: 16px;
    font-family: 'sukh-bold';
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #192383;
    margin-top: 12px; }

.road-tax-calculator {
  padding-top: 0;
  margin: 0; }
  .road-tax-calculator.non-logged {
    padding-top: 0; }
  .road-tax-calculator .content-wrapper {
    background: #fff;
    background: url(images/road-tax-calculator/form-background-mobile.png) no-repeat;
    background-size: contain;
    background-position: top center;
    max-height: initial !important; }
    .road-tax-calculator .content-wrapper:after {
      display: none; }
    .road-tax-calculator .content-wrapper .form-wrapper {
      border-radius: 24px;
      background: #ffffff;
      padding: 32px 24px; }
      .road-tax-calculator .content-wrapper .form-wrapper > div {
        display: flex;
        flex-direction: column; }
      .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper {
        padding: 0 0 24px 0; }
        .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper .title-text {
          font-size: 16px;
          margin-bottom: 12px; }
        .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper .key {
          font-size: 14px; }
        .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper .value {
          font-size: 16px; }
        .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper .key-value {
          display: flex;
          margin-bottom: 12px; }
          .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper .key-value .key {
            margin: 0; }
          .road-tax-calculator .content-wrapper .form-wrapper .result-wrapper .key-value .value {
            flex-grow: 1;
            text-align: right;
            margin: 0; }
      .road-tax-calculator .content-wrapper .form-wrapper .form {
        margin: 0; }
        .road-tax-calculator .content-wrapper .form-wrapper .form .line {
          display: none; }
        .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .selection.dropdown,
        .road-tax-calculator .content-wrapper .form-wrapper .form .form-field input {
          border: 0;
          box-shadow: none;
          border-bottom: solid 1px #f3f3f3;
          border-radius: 0;
          padding: 8px 0; }
        .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .dropdown-datepicker {
          display: block; }
          .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .dropdown-datepicker > div {
            margin: 0 0 12px 0; }
            .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .dropdown-datepicker > div .selection.dropdown {
              border: 0;
              box-shadow: none;
              border-bottom: solid 1px #f3f3f3;
              border-radius: 0;
              padding: 8px 0; }
        .road-tax-calculator .content-wrapper .form-wrapper .form .form-field .button {
          width: 100%;
          border-radius: 0; }
    .road-tax-calculator .content-wrapper .title-text {
      font-size: 16px;
      font-family: 'sukh-bold';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #192383;
      background: #fff;
      -webkit-text-stroke: #192383;
      margin-bottom: 8px; }
    .road-tax-calculator .content-wrapper .sub-text {
      margin-top: 0;
      font-size: 14px;
      font-family: 'sukh-regular';
      margin-bottom: 24px; }

.mobile-layout {
  min-height: var(--vh);
  width: 100%;
  background-color: #fff;
  max-width: 480px;
  margin: 0 auto; }

.mobile-modal-wrapper {
  position: fixed;
  width: 100%;
  z-index: 99999;
  height: var(--vh);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: rgba(112, 112, 112, 0.4);
  /* Optional: show position indicator in red */ }
  .mobile-modal-wrapper::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */ }
  .mobile-modal-wrapper::-webkit-scrollbar-thumb {
    background: transparent; }
  .mobile-modal-wrapper.center-align {
    display: flex;
    align-items: center; }
    .mobile-modal-wrapper.center-align .modal-content {
      height: auto; }
  .mobile-modal-wrapper .modal-content {
    width: 100%;
    height: 100%;
    max-width: 480px;
    margin: auto;
    background-color: transparent; }
  .mobile-modal-wrapper.actionsheet {
    display: flex;
    align-items: flex-end; }
    .mobile-modal-wrapper.actionsheet .modal-content {
      display: flex;
      align-items: flex-end;
      max-width: 100%;
      border-radius: 8px 8px 0px 0px;
      height: auto;
      margin: initial; }
  .mobile-modal-wrapper.no-background {
    background-color: transparent; }
  .mobile-modal-wrapper.with-opacity {
    background: rgba(3, 18, 26, 0.3); }
  .mobile-modal-wrapper.center-aligned {
    text-align: left;
    display: flex;
    align-items: center; }
    .mobile-modal-wrapper.center-aligned .modal-content {
      max-width: 100%;
      height: auto; }

@media screen and (min-width: 768px) {
  .mobile-layout {
    min-height: auto; }
    .mobile-layout.login-register {
      padding: 0 0 40px 0; }
      .mobile-layout.login-register.number-verified .content-wrapper {
        padding-top: 0; }
      .mobile-layout.login-register.sign-up {
        min-height: auto;
        margin-top: 20px; }
        .mobile-layout.login-register.sign-up .header {
          display: none; }
      .mobile-layout.login-register .header {
        position: relative; }
      .mobile-layout.login-register .bottom-section {
        position: relative; }
  .mobile-modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center; }
    .mobile-modal-wrapper.actionsheet .modal-content {
      max-width: 480px; }
    .mobile-modal-wrapper.white-background {
      background-color: #fff; }
      .mobile-modal-wrapper.white-background .modal-content {
        background-color: #fff;
        height: 100%; }
        .mobile-modal-wrapper.white-background .modal-content .profile-layout {
          min-height: calc(var(--vh) - 75px); }
        .mobile-modal-wrapper.white-background .modal-content .mobile-layout {
          padding: 0; }
          .mobile-modal-wrapper.white-background .modal-content .mobile-layout .header {
            border-bottom: 1px solid #d8e7fd;
            box-shadow: none; }
    .mobile-modal-wrapper.no-background .modal-content {
      background-color: #fff;
      height: 100%; }
      .mobile-modal-wrapper.no-background .modal-content .profile-layout {
        min-height: calc(var(--vh) - 75px); }
        .mobile-modal-wrapper.no-background .modal-content .profile-layout .header-section {
          border-bottom: 1px solid #d8e7fd; }
      .mobile-modal-wrapper.no-background .modal-content .mobile-layout {
        padding: 0; }
        .mobile-modal-wrapper.no-background .modal-content .mobile-layout .header {
          border-bottom: 1px solid #d8e7fd;
          box-shadow: none; }
    .mobile-modal-wrapper.full-screen .modal-content {
      width: 100%;
      height: 100%;
      max-width: 100%;
      margin: auto;
      background-color: transparent;
      position: static; }
    .mobile-modal-wrapper .modal-content {
      height: auto;
      background-color: transparent;
      position: relative; }
      .mobile-modal-wrapper .modal-content .fairdee-modal {
        position: absolute; } }

.slick-slider {
  max-width: 1024px;
  margin: 0 auto; }

.slick-dots {
  bottom: -20px; }
  .slick-dots li {
    width: 10px;
    height: 10px; }
    .slick-dots li.slick-active button:before {
      color: #f58b1e;
      opacity: 1;
      font-size: 10px;
      width: 10px;
      height: 10px;
      line-height: 10 !important; }
    .slick-dots li button {
      width: 10px;
      height: 10px; }
      .slick-dots li button:before {
        color: #d8d8d8;
        opacity: 1;
        font-size: 10px;
        width: 10px;
        height: 10px;
        line-height: 10 !important; }

.slick-prev,
.slick-next {
  width: 43px;
  height: 43px;
  background-color: #ffffff;
  border: solid 1.5px #f5f5f6;
  border-radius: 100%;
  top: 30%; }

.slick-prev {
  background: #ffffff url(images/arrow-left.svg) no-repeat;
  background-size: 15px;
  background-position: 10px center; }
  .slick-prev:before {
    content: ''; }
  .slick-prev:hover {
    background: #ffffff url(images/arrow-left.svg) no-repeat;
    background-size: 15px;
    background-position: 10px center; }

.slick-next {
  background: #ffffff url(images/arrow-right.svg) no-repeat;
  background-size: 15px;
  background-position: 15px center; }
  .slick-next:before {
    content: ''; }
  .slick-next:hover {
    background: #ffffff url(images/arrow-right.svg) no-repeat;
    background-size: 15px;
    background-position: 15px center; }

.rangeslider {
  border-radius: 60px;
  height: 5px;
  background: #e6e6e6; }

.rangeslider-horizontal {
  width: 180px;
  margin: 0;
  cursor: pointer;
  box-shadow: none; }
  .rangeslider-horizontal .rangeslider__fill {
    border-radius: 100px;
    background-color: #f58b1e;
    box-shadow: none; }
  .rangeslider-horizontal .rangeslider__handle-tooltip {
    width: auto;
    padding: 0 10px;
    min-width: 40px; }
  .rangeslider-horizontal .rangeslider__handle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 0; }
    .rangeslider-horizontal .rangeslider__handle:focus {
      outline: none; }
    .rangeslider-horizontal .rangeslider__handle:after {
      display: none; }
  .rangeslider-horizontal .rangeslider__labels li {
    font-family: 'sukh-light';
    letter-spacing: 0;
    font-size: 10px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    top: 11px; }
    .rangeslider-horizontal .rangeslider__labels li:first-child, .rangeslider-horizontal .rangeslider__labels li:last-child {
      font-size: 14px;
      top: 8px; }

.rc-slider-tooltip {
  z-index: 999 !important; }

.rc-slider .rc-slider-track {
  background: #192383; }

.rc-slider .rc-slider-handle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 0;
  box-shadow: 0 2px 4px 0 rgba(140, 140, 140, 0.3), 0 -2px 4px 0 rgba(140, 140, 140, 0.2);
  border: 0;
  margin-top: -4px; }
  .rc-slider .rc-slider-handle:focus {
    border: 0;
    box-shadow: 0 2px 4px 0 rgba(140, 140, 140, 0.3), 0 -2px 4px 0 rgba(140, 140, 140, 0.2);
    outline: none; }

.rc-slider .rc-slider-rail {
  background: #e6e6e6; }

.rc-slider .rc-slider-dot {
  display: none; }

.rc-slider .rc-slider-mark {
  pointer-events: none; }

.rc-slider .rc-slider-mark-text {
  color: #4a4a4a;
  font-size: 10px;
  top: 2px; }
  .rc-slider .rc-slider-mark-text[lang='english'] {
    font-family: 'sukh-light';
    letter-spacing: 0; }
  .rc-slider .rc-slider-mark-text:first-child, .rc-slider .rc-slider-mark-text:last-child {
    font-size: 14px;
    top: 0; }
    .rc-slider .rc-slider-mark-text:first-child[lang='english'], .rc-slider .rc-slider-mark-text:last-child[lang='english'] {
      font-family: 'sukh-regular', sans-serif;
      letter-spacing: 0; }
  .rc-slider .rc-slider-mark-text:first-child {
    margin-left: 0 !important;
    left: 0 !important;
    right: auto !important;
    text-align: left; }
  .rc-slider .rc-slider-mark-text:last-child {
    margin-right: 0 !important;
    right: -26px !important;
    left: auto !important;
    text-align: right; }

.grecaptcha-badge {
  display: none; }

.component-loading {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  background-color: #f6f9ff; }

.api-error {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  font-size: 16px;
  color: #ff901d; }

.component-mini-loader {
  position: relative;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 4px;
  width: 100%; }
  .component-mini-loader div {
    width: 16px !important;
    height: 16px !important; }

.fab {
  margin: 0;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed !important; }

.lg-listing-layout {
  padding: 40px 24px 160px 24px; }
  .lg-listing-layout .title-block {
    display: flex;
    align-items: end;
    margin-bottom: 32px;
    justify-content: space-between; }
    .lg-listing-layout .title-block .title-text {
      font-size: 24px;
      font-family: 'sukh-bold';
      text-align: left;
      color: #000000; }
      .lg-listing-layout .title-block .title-text .button {
        margin-left: 32px;
        font-size: 14px;
        font-family: 'sukh-medium';
        text-align: center;
        color: #ffffff;
        padding: 8px 14px;
        text-transform: none; }

.lg-form-layout {
  padding: 80px; }
  .lg-form-layout .header-text {
    font-size: 18px;
    font-family: 'sukh-bold';
    color: #192383;
    text-align: left;
    margin-bottom: 22px; }
  .lg-form-layout .inline-error {
    margin-left: 0;
    bottom: -16px;
    font-size: 12px;
    top: auto;
    color: red; }
    .lg-form-layout .inline-error.with-offset {
      top: 52px; }
  .lg-form-layout .search-list .search-input input[type="text"] {
    border: 0; }
  .lg-form-layout input[type="text"],
  .lg-form-layout input[type="email"],
  .lg-form-layout input[type="password"],
  .lg-form-layout input[type="number"],
  .lg-form-layout input[type="radio"],
  .lg-form-layout .ui.fluid.dropdown,
  .lg-form-layout textarea {
    width: 100%;
    border: 0;
    border-radius: 0;
    padding: 4px 0 !important;
    font-size: 16px !important;
    font-size: 16px;
    font-family: 'sukh-regular';
    line-height: normal;
    color: rgba(0, 0, 0, 0.85); }
    .lg-form-layout input[type="text"]:focus,
    .lg-form-layout input[type="email"]:focus,
    .lg-form-layout input[type="password"]:focus,
    .lg-form-layout input[type="number"]:focus,
    .lg-form-layout input[type="radio"]:focus,
    .lg-form-layout .ui.fluid.dropdown:focus,
    .lg-form-layout textarea:focus {
      border: 0;
      border-bottom: 1px solid #d7d7d7;
      border-radius: 0; }
    .lg-form-layout input[type="text"]:focus ~ label,
    .lg-form-layout input[type="email"]:focus ~ label,
    .lg-form-layout input[type="password"]:focus ~ label,
    .lg-form-layout input[type="number"]:focus ~ label,
    .lg-form-layout input[type="radio"]:focus ~ label,
    .lg-form-layout .ui.fluid.dropdown:focus ~ label,
    .lg-form-layout textarea:focus ~ label {
      top: -12px;
      color: #f58b1e;
      font-size: 12px; }
    .lg-form-layout input[type="text"]:not(:placeholder-shown) ~ label,
    .lg-form-layout input[type="email"]:not(:placeholder-shown) ~ label,
    .lg-form-layout input[type="password"]:not(:placeholder-shown) ~ label,
    .lg-form-layout input[type="number"]:not(:placeholder-shown) ~ label,
    .lg-form-layout input[type="radio"]:not(:placeholder-shown) ~ label,
    .lg-form-layout .ui.fluid.dropdown:not(:placeholder-shown) ~ label,
    .lg-form-layout textarea:not(:placeholder-shown) ~ label {
      top: -12px;
      color: #9b9b9b !important;
      font-size: 12px; }
  .lg-form-layout > .button {
    width: 100%;
    padding: 12px;
    font-size: 20px;
    font-family: 'sukh-medium';
    color: #ffffff;
    text-transform: none; }
  .lg-form-layout > .inline.field,
  .lg-form-layout > .field {
    width: 100%;
    position: relative;
    margin: 0 0 24px 0; }
    .lg-form-layout > .inline.field.mb-0,
    .lg-form-layout > .field.mb-0 {
      margin-bottom: 0; }
    .lg-form-layout > .inline.field > label,
    .lg-form-layout > .field > label {
      font-size: 16px;
      font-family: 'sukh-regular';
      color: #70757b;
      line-height: 1.4;
      top: 0; }
      .lg-form-layout > .inline.field > label[data-shrink="true"],
      .lg-form-layout > .field > label[data-shrink="true"] {
        font-size: 16px;
        color: #f58b1e;
        transform: translate(0, 1.5px) scale(0.75);
        transform-origin: top left; }
    .lg-form-layout > .inline.field.errors input[type="text"],
    .lg-form-layout > .inline.field.errors input[type="email"],
    .lg-form-layout > .inline.field.errors input[type="password"],
    .lg-form-layout > .inline.field.errors input[type="number"],
    .lg-form-layout > .inline.field.errors input[type="radio"],
    .lg-form-layout > .inline.field.errors textarea,
    .lg-form-layout > .field.errors input[type="text"],
    .lg-form-layout > .field.errors input[type="email"],
    .lg-form-layout > .field.errors input[type="password"],
    .lg-form-layout > .field.errors input[type="number"],
    .lg-form-layout > .field.errors input[type="radio"],
    .lg-form-layout > .field.errors textarea {
      border-bottom: 2px solid #d8001a; }
    .lg-form-layout > .inline.field > p,
    .lg-form-layout > .field > p {
      color: #d8001a;
      font-size: 12px;
      font-family: 'sukh-regular'; }
  .lg-form-layout .react-select-custom {
    outline: none;
    margin-top: 16px; }
    .lg-form-layout .react-select-custom:focus {
      border: 0; }
    .lg-form-layout .react-select-custom > span {
      display: none !important; }
    .lg-form-layout .react-select-custom ::-webkit-input-placeholder {
      /* WebKit browsers */
      font-size: 16px !important;
      font-family: 'sukh-regular' !important;
      color: #70757b !important; }
    .lg-form-layout .react-select-custom :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      font-size: 16px !important;
      font-family: 'sukh-regular' !important;
      color: #70757b !important; }
    .lg-form-layout .react-select-custom ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      font-size: 16px !important;
      font-family: 'sukh-regular' !important;
      color: #70757b !important; }
    .lg-form-layout .react-select-custom :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      font-size: 16px !important;
      font-family: 'sukh-regular' !important;
      color: #70757b !important; }
    .lg-form-layout .react-select-custom.selected > div > div:first-child > div {
      font-size: 16px;
      font-family: 'sukh-regular';
      color: rgba(0, 0, 0, 0.85);
      line-height: 1; }
    .lg-form-layout .react-select-custom > div {
      border-radius: 2px;
      border: 0;
      border-bottom: 2px solid rgba(216, 216, 216, 0.3);
      background-color: #ffffff;
      min-width: 100%; }
      .lg-form-layout .react-select-custom > div > div {
        padding: 0; }
        .lg-form-layout .react-select-custom > div > div:first-child > div:last-child {
          padding: 0;
          margin: 0; }
          .lg-form-layout .react-select-custom > div > div:first-child > div:last-child input {
            padding: 0 !important; }
        .lg-form-layout .react-select-custom > div > div:nth-child(2) {
          display: none; }
          .lg-form-layout .react-select-custom > div > div:nth-child(2) > span {
            display: none; }

.lg-table-wrapper {
  padding: 10px;
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  position: relative; }
  .lg-table-wrapper.scrollable-table {
    width: 195%; }
    .lg-table-wrapper.scrollable-table .scrollable-notify {
      position: absolute;
      top: -18px;
      font-size: 14px;
      background-color: #f58b1e;
      color: #192383;
      padding: 2px; }
  .lg-table-wrapper .with-overflow {
    overflow-x: auto; }
  .lg-table-wrapper .text-ellipsis {
    overflow-x: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 5ch; }
  .lg-table-wrapper .break-word-container {
    width: 15ch;
    word-break: break-word;
    padding-right: 15px; }
  .lg-table-wrapper .action-wrapper {
    position: absolute;
    right: 0;
    top: 12px;
    padding: 4px 24px 4px 4px; }
  .lg-table-wrapper .menu-wrapper {
    display: inline-block;
    position: absolute;
    right: 3px;
    top: calc(50% - 22px); }
    .lg-table-wrapper .menu-wrapper > div svg {
      fill: #c4cfe4;
      font-size: 18px; }
  .lg-table-wrapper .with-padding {
    padding: 24px; }
  .lg-table-wrapper table {
    width: 100%;
    margin-top: 20px;
    table-layout: fixed;
    vertical-align: bottom; }
    .lg-table-wrapper table input {
      border: 0;
      outline: 0;
      font-size: 12px;
      font-family: 'sukh-regular';
      color: #4a4a4a; }
      .lg-table-wrapper table input:focus {
        border-bottom: 1px solid #efefef;
        padding-bottom: 2px;
        color: #000; }
  .lg-table-wrapper.vertical-table table tr th {
    color: #8a95aa;
    font-family: 'sukh-regular'; }
  .lg-table-wrapper.vertical-table table tr td {
    border-bottom: solid 0.5px #e3e3e3; }

.lg-loading {
  width: 100%;
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.mobile-form .form-field {
  position: relative;
  margin-bottom: 10px; }
  .mobile-form .form-field.error input[type='number'],
  .mobile-form .form-field.error input[type='text'],
  .mobile-form .form-field.error input[type='email'],
  .mobile-form .form-field.error input[type='password'] {
    border: 1px solid #ff0000; }
  .mobile-form .form-field.error .ant-select .ant-select-selector {
    border: 1px solid #ff0000; }
  .mobile-form .form-field .ui.checkbox input + label {
    font-size: 12px;
    line-height: 20px;
    color: #707070;
    display: flex;
    align-items: center;
    padding-left: 28px; }
    .mobile-form .form-field .ui.checkbox input + label:before {
      width: 20px;
      height: 20px; }
  .mobile-form .form-field ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #889bb9 !important;
    font-size: 14px; }
  .mobile-form .form-field ::-moz-placeholder {
    /* Firefox 19+ */
    color: #889bb9 !important;
    font-size: 14px; }
  .mobile-form .form-field :-ms-input-placeholder {
    /* IE 10+ */
    color: #889bb9 !important;
    font-size: 14px; }
  .mobile-form .form-field :-moz-placeholder {
    /* Firefox 18- */
    color: #889bb9 !important;
    font-size: 14px; }
  .mobile-form .form-field > label {
    margin-bottom: 4px;
    display: block;
    font-family: 'sukh-medium';
    font-size: 12px;
    line-height: 14px;
    color: #707070; }
  .mobile-form .form-field.inline-field {
    display: flex;
    align-items: center; }
  .mobile-form .form-field input[type='number'],
  .mobile-form .form-field input[type='text'],
  .mobile-form .form-field input[type='email'],
  .mobile-form .form-field input[type='password'],
  .mobile-form .form-field .month-picker,
  .mobile-form .form-field .dropdown-picker,
  .mobile-form .form-field input[type='password'] {
    border: 1px solid #e3e3e3;
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #2f2f2f;
    padding: 9px;
    outline: none;
    transition: all 0.5s ease; }
  .mobile-form .form-field .month-picker,
  .mobile-form .form-field .dropdown-picker {
    height: 35px;
    cursor: pointer; }
  .mobile-form .form-field .month-picker {
    background: url("./images/fairdee-x-plus/icons/calendar.svg") no-repeat;
    background-position: calc(100% - 10px) center; }
  .mobile-form .form-field .dropdown-picker {
    background: url("./images/fairdee-x-plus/teams/dropdown.svg") no-repeat;
    background-position: calc(100% - 10px) center; }
  .mobile-form .form-field .inline-labels {
    display: flex;
    align-items: center; }
    .mobile-form .form-field .inline-labels label {
      margin-bottom: 4px;
      display: block;
      font-family: 'sukh-medium';
      font-size: 12px;
      line-height: 14px;
      color: #707070; }
      .mobile-form .form-field .inline-labels label:first-child {
        flex-grow: 1; }
      .mobile-form .form-field .inline-labels label:last-child {
        text-align: right;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        font-size: 10px;
        line-height: 12px;
        color: #ff6400;
        cursor: pointer; }
        .mobile-form .form-field .inline-labels label:last-child .info {
          display: inline-block;
          margin-right: 5px;
          cursor: pointer; }

.mobile-form .phone-number-input {
  position: relative; }
  .mobile-form .phone-number-input > span {
    font-family: 'sukh-medium';
    font-size: 14px;
    line-height: 17px;
    border-right: 1px solid #e3e3e3;
    display: inline-block;
    width: 65px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .mobile-form .phone-number-input > span img {
      margin-right: 4px; }
  .mobile-form .phone-number-input input[type='number'] {
    padding-left: 80px; }

.mobile-form .inline-error {
  color: #ff0000;
  display: block;
  margin-top: 5px;
  font-size: 12px;
  line-height: 14px;
  font-family: 'sukh-medium'; }

.mobile-form .uploaded-files .file {
  box-sizing: border-box;
  margin: 0 8px 8px 0;
  color: #000000d9;
  display: inline-flex;
  height: auto;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
  align-items: center; }
  .mobile-form .uploaded-files .file .img-wrap {
    margin-left: 4px;
    cursor: pointer; }

.affiliate-discount-form .button-wrap {
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-end; }

.affiliate-discount-form .flex-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 8px; }
  .affiliate-discount-form .flex-wrap .key {
    margin-bottom: 4px;
    display: block;
    font-family: 'sukh-medium';
    font-size: 12px;
    line-height: 14px;
    color: #707070; }
  .affiliate-discount-form .flex-wrap .value {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #707070; }
  .affiliate-discount-form .flex-wrap > div:not(:last-child) {
    margin-right: 10px; }

.info-popup-content {
  text-align: left; }
  .info-popup-content .text {
    position: relative;
    margin-bottom: 4px;
    padding-left: 16px; }
    .info-popup-content .text:before {
      content: '';
      position: absolute;
      left: 0;
      top: 8px;
      width: 8px;
      height: 8px;
      background-color: #ff8743;
      border-radius: 100%; }

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2 / 3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important; }
  .input-search input {
    font-size: 14px !important; } }

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40 / 71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important; }
  .input-search input {
    font-size: 14px !important; } }

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375 / 667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px !important; }
  .input-search input {
    font-size: 14px !important; } }

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9 / 16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px !important; }
  .input-search input {
    font-size: 14px !important; } }

.ant-select {
  display: block; }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #e3e3e3;
    background-color: #ffffff;
    border-radius: 4px;
    width: 100%;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #2f2f2f;
    padding: 2px 9px;
    outline: none;
    height: auto; }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: 100%; }
  .ant-select .ant-select-arrow {
    color: #ff6400; }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: 1px solid #e3e3e3; }

.ant-picker {
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  border-radius: 4px;
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #2f2f2f;
  padding: 6px 9px;
  outline: none;
  height: auto; }

.fairdee-modal {
  border-radius: 8px;
  min-width: 100%;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(112, 112, 112, 0.4); }
  .fairdee-modal .modal-wrapper {
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    max-width: 380px;
    margin: 0 auto; }
  .fairdee-modal .header {
    padding: 8px; }
    .fairdee-modal .header .title-text {
      margin-top: 14px;
      padding-bottom: 12px;
      font-family: 'sukh-bold';
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #2f2f2f;
      text-align: center;
      color: #2f2f2f; }
      .fairdee-modal .header .title-text.border {
        border-bottom: 0.3px solid #94c9de; }
  .fairdee-modal .content {
    margin: 12px 16px 18px 16px;
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #707070;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    /* Optional: show position indicator in red */ }
    .fairdee-modal .content::-webkit-scrollbar {
      width: 0;
      /* Remove scrollbar space */
      background: transparent;
      /* Optional: just make scrollbar invisible */ }
    .fairdee-modal .content::-webkit-scrollbar-thumb {
      background: #ff0000; }
    .fairdee-modal .content .text {
      font-size: 14px;
      line-height: 22px; }
      .fairdee-modal .content .text img {
        max-width: 100%; }
      .fairdee-modal .content .text a {
        color: #4c70ac;
        font-family: 'sukh-medium'; }
    .fairdee-modal .content .message {
      margin-top: 18px;
      font-family: 'sukh-medium';
      font-size: 18px;
      line-height: 22px; }
  .fairdee-modal .button-wrapper {
    padding: 0 12px 16px 12px; }
    .fairdee-modal .button-wrapper button {
      width: 100%; }
  .fairdee-modal .inline-buttons {
    display: flex;
    align-items: center; }
    .fairdee-modal .inline-buttons button {
      flex-grow: 1;
      flex-basis: 100%; }
      .fairdee-modal .inline-buttons button:first-child {
        margin-right: 12px; }

@media screen and (max-width: 360px) {
  .fairdee-modal .inline-buttons {
    flex-direction: column; }
    .fairdee-modal .inline-buttons button:first-child {
      margin: 0 0 12px 0; } }

.inline-buttons {
  display: flex;
  align-items: center; }
  .inline-buttons.record-payments {
    max-width: 50%; }
  .inline-buttons button {
    flex-grow: 1;
    flex-basis: 100%; }
    .inline-buttons button:first-child {
      margin-right: 12px; }
    .inline-buttons button.with-right-margin {
      margin-right: 12px; }
  .inline-buttons.with-padding {
    padding: 0 8px 23px 8px; }

.ant-btn {
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  color: #1253a4;
  font-family: 'sukh-bold';
  font-size: 14px;
  line-height: 17px;
  padding: 12px 24px;
  height: auto;
  box-shadow: none;
  cursor: pointer; }
  .ant-btn img {
    margin-right: 6px; }
  .ant-btn:active, .ant-btn:focus {
    border: 1px solid #e3e3e3;
    color: #1253a4; }
  .ant-btn.radio {
    font-weight: normal;
    padding: 8px 12px; }
    .ant-btn.radio.selected {
      background: #ffefe2;
      border: 1px solid #ff6400;
      color: #1253a4;
      font-family: 'sukh-medium'; }
  .ant-btn.selected {
    background: #ffefe2;
    border: 1px solid #ff6400;
    color: #1253a4; }
  .ant-btn.orange-button {
    background: linear-gradient(180deg, #ff9738 0%, #ff8743 100%), #ff9738;
    color: #ffffff;
    border: 1px solid #ff8743; }
  .ant-btn.orange-bordered-button {
    background: #ffffff;
    color: #ff6400;
    border: 1px solid #ff6400; }
  .ant-btn.line-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding-top: inherit !important;
    line-height: inherit; }
  .ant-btn.white {
    background: #ffffff; }
  .ant-btn.medium-text {
    font-size: 12px;
    line-height: 14px;
    text-align: center; }
  .ant-btn.small {
    padding: 9px 24px; }
  .ant-btn.mini {
    padding: 6px 12px;
    font-size: 12px;
    line-height: 14px; }
  .ant-btn.button-two {
    padding: 12px 5px;
    font-size: 10px; }
  .ant-btn.with-right-margin {
    margin-right: 8px; }
  .ant-btn.medium {
    padding: 8px 24px !important; }

.ant-btn[disabled],
.ant-btn.disabled {
  background: #d3dded;
  font-family: 'sukh-bold';
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  text-align: center;
  border-radius: 8px;
  box-shadow: none;
  border: 0;
  opacity: 1; }

.recaptcha-btn-wrap.inline {
  display: flex;
  align-items: center;
  padding-bottom: 23px; }
  .recaptcha-btn-wrap.inline .recaptcha {
    flex-grow: 1;
    flex-basis: 100%;
    padding: 8px 9px; }
    .recaptcha-btn-wrap.inline .recaptcha .button-two {
      width: 100%; }
