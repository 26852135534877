@import '../scss/variables';

.bottom-navigation {
  background-color: $white;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  max-width: 480px;
  margin: 0 auto;

  > div {
    flex-grow: 1;
    flex-basis: 100%;
    text-align: center;
    padding: 8px 0 6px 0;
    cursor: pointer;

    &.active {
      .text {
        font-weight: bold;
      }
    }

    img {
      margin-bottom: 4px;
    }

    .text {
      font-size: 12px;
      line-height: 14px;
      color: #4c70ac;
    }
  }
}
