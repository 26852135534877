.app-update {
  background: #ffffff;
  border-radius: 20px;
  width: 100%;

  .title-section {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    padding: 12px 20px;
    border-bottom: 1px solid #d3dded;
  }

  .content-section {
    padding: 12px;

    .img-wrap {
      margin-bottom: 18px;
      text-align: center;
    }

    .text {
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      margin-bottom: 12px;
    }

    .bg-wrap {
      background: #ffefe2;
      border-radius: 8px;
      margin-bottom: 12px;
      padding: 10px 12px;

      .text-one {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-bottom: 12px;
      }

      .text-two {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2d2d2d;
        margin-bottom: 8px;
        margin-left: 12px;
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          margin-right: 6px;
          border-radius: 100%;
          width: 6px;
          height: 6px;
          background-color: #000000;
        }
      }
    }

    .info-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #fd5662;
    }

    .helper-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #2f2f2f;
      margin-bottom: 12px;
    }

    .button-wrapper {
      margin-top: 32px;

      button {
        width: 100%;
        background: linear-gradient(180deg, #ff9738 0%, #ff8743 100%), #ff9738;
        /* elevation 100 */

        box-shadow: 0px 1px 6px rgba(58, 151, 226, 0.2);
        border-radius: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height, or 129% */

        text-align: center;

        /* White */

        color: #ffffff;
        padding: 12px;
      }
    }
  }
}

.app-update-wrapper {
  padding: 37px 0;
  height: var(--vh);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
}
