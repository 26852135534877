.invite-friends {
  background-color: #f6f9ff;
  min-height: var(--vh);

  .background-wrap {
    padding: 12px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 80%;
      background: linear-gradient(0deg, #ff8b41, #ff8b41), #ff9738;
      left: 0;
      top: 0;
      right: 0;
      z-index: 1;
      border-radius: 0 0 80px 80px;
    }
  }

  .title-text {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    padding: 16px;
    position: relative;
    z-index: 2;

    img {
      position: absolute;
      left: 0;
      cursor: pointer;
    }
  }

  .content-wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(104, 137, 255, 0.1);
    border-radius: 8px;
    padding: 16px 12px;
    position: relative;
    z-index: 2;

    .text-one {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #2f2f2f;
      margin-bottom: 12px;
    }

    .text-two {
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #9597a1;
    }

    .text-three {
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #ff8b41;
      margin-top: 8px;
      cursor: pointer;
    }

    .img-wrap {
      max-width: 180px;
      margin: 16px auto 48px auto;
    }

    .link-wrap {
      background: #f6f9ff;
      padding: 12px;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #1253a4;
      cursor: pointer;
      margin-bottom: 12px;
      text-align: center;
    }

    .button-wrap {
      button {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .invite-friends {
    min-height: var(--vh);
    position: relative;
  }
}
