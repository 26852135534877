@import '../../scss/variables';

.event-form {
  background: $white;
  border-radius: 8px;
  padding: 16px 12px;
  width: 100%;

  .title {
    font-family: 'sukh-medium';
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $input-dark;
    margin-bottom: 16px;
  }

  .list {
    background: $white;
    border: 1px solid $tertiary;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $secondary;
    margin-bottom: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;

    .link-text {
      flex-grow: 1;
      text-align: right;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
      color: $primary;
      text-decoration: none;
    }
  }

  .close {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: $primary;
    margin-top: 16px;
    text-align: center;
    cursor: pointer;
  }

  .label {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: $input-label;
  }

  .input {
    border: 0;
    padding: 12px 0 8px 0;
    padding-bottom: 0;
    font-size: 14px;
    outline: none;
    width: 100%;
    text-transform: none;
    border-bottom: 1px solid $tertiary;
    color: $input-black;
    line-height: 1.21428571em;
    margin-bottom: 25px;
  }

  .text-box-input {
    border: 0;
    padding: 12px 0 8px 0;
    padding-bottom: 0;
    font-size: 14px;
    outline: none;
    width: 100%;
    text-transform: none;
    border-bottom: 1px solid $tertiary;
    color: $input-black;
    line-height: 1.21428571em;
  }

  .sub-label-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #4d4f5c;
    margin-bottom: 25px;
  }

  .checkbox {
    border-bottom: 0;
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $primary;
      border-color: $primary;
    }
  }

  .submit-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
