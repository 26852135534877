.landing-layout {
  .scroll-wrap {
    visibility: hidden;

    @keyframes fadein {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    /* Internet Explorer */
    @-ms-keyframes fadein {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    /* Opera < 12.1 */
    @-o-keyframes fadein {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    &.visible {
      visibility: visible;
      -webkit-animation: fadein 1s;
      /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 1s;
      /* Firefox < 16 */
      -ms-animation: fadein 1s;
      /* Internet Explorer */
      -o-animation: fadein 1s;
      /* Opera < 12.1 */
      animation: fadein 1s;
    }
  }

  .mobile-view {
    display: none;
  }

  .slick-slider {
    width: 100%;
    max-width: 100%;
    min-height: 400px;

    .slick-arrow {
      width: 44px;
      height: 44px;
      opacity: 0.9;
      border: 0;
      top: calc(50% - 22px);
      z-index: 9;

      &.slick-next {
        background: url(../images/icons/ss-ra.svg) no-repeat;
        background-size: contain;
        background-position: center center;
        right: 64px;
      }

      &.slick-prev {
        background: url(../images/icons/ss-la.svg) no-repeat;
        background-size: contain;
        background-position: center center;
        left: 64px;
      }
    }
  }

  .content-wrapper {
    // max-width: 1440px;
    // padding: 0 72px;

    .line {
      height: 6px;
      border-radius: 3px;
      background-color: #f89734;
      width: 60px;
      margin-bottom: 20px;
    }
  }

  .center-align {
    display: flex;
    justify-content: center;
  }

  .section-one {
    .banner-label {
      background: #00b1b7;
      border-radius: 24px;
      // font-family: Sriracha;
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      padding: 4px 24px 4px 20px;
      display: inline-flex;
      align-items: center;
      font-family: 'Sriracha', cursive;
      -webkit-text-stroke: 0.5px #ffffff;
    }

    .button {
      border-radius: 24px;
      border: solid 1px #f58b1e;
      background-color: #ff901d;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      padding: 10px 26px;
      margin-top: 28px;
      padding-right: 112px;
      display: flex;
      align-items: center;
      position: relative;

      img {
        width: 10px;
        position: absolute;
        right: 24px;
        animation-duration: 8s;
        // animation-fill-mode: both;
      }
    }

    .slick-slider {
      .item {
        position: relative;
        display: inline-flex !important;
        align-items: center;

        img {
          width: 100%;
        }

        .content {
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 80px 220px 80px 180px;
          margin: 0 auto;
          overflow: hidden;

          .button {
            img {
              width: 10px;
            }
          }

          .title-text {
            font-size: 36px;
            font-family: 'sukh-bold';
            font-stretch: normal;
            font-style: normal;
            line-height: 1.39;
            letter-spacing: 0.5px;
            color: #ffffff;
            margin-top: 20px;
            max-width: 520px;
            position: relative;

            img {
              position: absolute;
              left: -44px;
              top: 10px;
              cursor: pointer;
              width: 31px;
              height: 31px;
              object-fit: contain;
            }
          }

          .sub-text {
            font-size: 22px;
            font-family: 'sukh-medium';
            font-stretch: normal;
            font-style: normal;
            line-height: 1.64;
            letter-spacing: normal;
            color: rgba(255, 255, 255, 0.85);
            margin-top: 10px;
            max-width: 420px;
            position: relative;
          }
        }
      }
    }

    .oic-wrapper {
      display: flex;
      align-items: center;
      margin: 40px auto 45px auto;
      background: #fff;
      flex-wrap: nowrap;
      justify-content: space-between;

      > div {
        border: 1px solid #ccc;
        padding: 3px 24px 4px 24px;
        border-radius: 32px 0 0 32px;
        border-right: 0;
        display: flex;
        align-items: center;
        align-self: stretch;

        img {
          max-width: 128px;
        }

        &:last-child {
          background: #192383;
          border: 1px solid #192383;
          // padding: 1.2px;
          border-radius: 0 32px 32px 0;

          span {
            -webkit-text-stroke: 1px #ffffff;
            font-size: 18px;
            font-family: 'sukh-bold';
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
          }
        }
      }
    }

    .insurers-wrapper {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-gap: 20px;
      justify-content: center;
      align-content: center;
      text-align: center;
      margin-bottom: 60px;

      .item {
        position: relative;

        img {
          width: 80px;
          height: 80px;
          object-fit: contain;
        }
      }
    }
  }

  .section-two {
    background: url(../images/latest-landing/background-image-one.png) no-repeat;
    background-size: cover;
    background-position: center center;

    .react-player__shadow {
      background: transparent url(../images/latest-landing/play-icon-one.png) !important;
      background-size: 100% 100% !important;
      background-position: center center !important;
      z-index: 1;
      position: relative;
      width: 100px !important;
      height: 100px !important;
    }

    .react-player__play-icon {
      display: none;
    }

    .container {
      padding: 104px 97px;
      margin: 0 auto;

      .row {
        display: flex;
        align-items: center;

        .col {
          flex-grow: 1;
          flex-basis: 100%;
          position: relative;

          &:first-child {
            margin-right: 74px;
          }

          .fairdee-cares {
            position: absolute;
            top: -42px;
            left: -24px;
            max-width: 200px;
          }

          img {
            max-width: 100%;
          }

          .title-text {
            font-size: 30px;
            font-family: 'sukh-bold';
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #262626;
            margin-bottom: 12px;
          }

          .sub-text {
            font-size: 20px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            color: #262626;
            margin-bottom: 18px;
          }

          .text {
            font-size: 30px;
            font-family: 'sukh-bold';
            font-stretch: normal;
            font-style: normal;
            line-height: 1.07;
            letter-spacing: normal;
            color: #192383;
          }
        }
      }
    }
  }

  .section-three {
    .container {
      margin: 0 auto;
      padding: 127px 72px 200px 108px;
      background: url(../images/latest-landing/map.svg) no-repeat;
      background-position: calc(100% - 72px) center;

      .title-text {
        max-width: 540px;
        font-size: 30px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 88px;
      }

      .row {
        display: flex;
        align-items: flex-start;

        .col {
          &:not(:last-child) {
            margin-right: 48px;
          }

          max-width: 335px;

          img {
            margin-bottom: 20px;
            max-width: 100%;
          }

          &:last-child {
            img {
              max-width: 136px;
            }
          }

          p {
            font-size: 20px;
            font-family: 'sukh-bold';
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
  }

  .section-four {
    background: #192383;

    .container {
      padding: 80px 108px;
      margin: 0 auto;

      .title-text {
        font-size: 48px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 57px;
      }

      .row {
        display: flex;
        align-items: flex-start;

        .col {
          flex-grow: 1;
          flex-basis: 100%;

          &:not(:last-child) {
            margin-right: 80px;
          }

          img {
            margin-bottom: 32px;
            width: 144px;
            height: 138px;
            object-fit: contain;
          }

          .text {
            font-size: 30px;
            font-family: 'sukh-bold';
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: rgba(255, 255, 255, 0.85);
            margin-bottom: 15px;
          }

          .sub-text {
            font-size: 18px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            color: rgba(255, 255, 255, 0.85);
          }
        }
      }
    }
  }

  .mlm-benefits,
  .management-fees,
  .fairdee-plus {
    position: relative;
    background: url(../images/latest-landing/background-image-three.png) no-repeat;
    background-size: cover;
    background-position: center center;
    padding-bottom: 80px;

    .container {
      padding: 104px 0 0 80px;
      margin: 0 auto;
    }

    .title-text {
      font-size: 48px;
      font-family: 'sukh-bold';
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 57px;
    }

    .row {
      display: flex;
      align-items: flex-start;

      .col {
        flex-grow: 1;
        flex-basis: 100%;

        &:not(:last-child) {
          margin-right: 80px;
        }

        img {
          width: 480px;
        }

        .title-text {
          font-size: 32px;
          font-family: 'sukh-bold';
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          color: #192383;
        }

        .text {
          font-size: 22px;
          font-family: 'sukh-bold';
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #192383;
          margin-bottom: 16px;
          display: flex;
          align-items: flex-start;

          .anticon {
            margin-right: 6px;
            margin-top: 4px;
          }
        }
      }
    }
  }

  .management-fees {
    background: #192383;
    padding-bottom: 80px;

    .row {
      .col {
        .title-text,
        .text {
          color: #fff;
        }
      }
    }
  }

  .fairdee-plus {
    padding-bottom: 80px;
    background: url(../images/latest-landing/background-image-one.png) no-repeat;
    background-size: cover;
    background-position: center center;
    padding-bottom: 32px;
  }

  .section-five {
    position: relative;

    .container {
      padding: 104px 0 0 80px;
      margin: 0 auto;

      .react-player__shadow {
        background: transparent url(../images/latest-landing/play-icon-two.jpg) !important;
        background-size: 100% 100% !important;
        background-position: center center !important;
        z-index: 1;
        position: relative;
        width: 100px !important;
        height: 100px !important;
      }

      .react-player__play-icon {
        display: none;
      }

      .row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        .col {
          flex-grow: 1;
          flex-basis: 100%;
          align-self: stretch;

          &:first-child {
            margin-right: 74px;
            margin-bottom: 80px;
            max-width: 560px;
          }

          &:last-child {
            background: url(../images/latest-landing/background-image-two.png) no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            max-width: calc(100% - 560px);
            min-height: 780px;

            .slick-slider {
              margin: 0 32px 0 0;
              max-width: 360px;
            }

            .testimonial {
              img {
                margin-bottom: 30px;
              }

              .text-one {
                font-size: 24px;
                font-family: 'sukh-bold';
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #ffffff;
                margin-bottom: 16px;
              }

              .text-two {
                font-size: 22px;
                font-family: 'sukh-bold';
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: rgba(255, 255, 255, 0.85);
              }
            }
          }

          img {
            max-width: 100%;
          }

          .title-text {
            font-size: 26px;
            font-family: 'sukh-bold';
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #262626;
            margin-bottom: 8px;
          }

          .sub-text {
            font-size: 18px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            color: #262626;
            margin-bottom: 50px;
          }
        }
      }
    }
  }

  .section-six {
    background: url(../images/latest-landing/background-image-three.png) no-repeat;
    background-size: cover;
    background-position: center center;

    .container {
      padding: 104px 97px;
      margin: 0 auto;

      .row {
        display: flex;
        align-items: center;

        .col {
          flex-grow: 1;
          flex-basis: 100%;

          &:first-child {
            margin-right: 146px;

            img {
              max-width: 100%;
            }
          }

          .number {
            display: none;
          }

          .title-text {
            font-size: 30px;
            font-family: 'sukh-bold';
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #262626;
            margin-bottom: 56px;
          }

          .more-details {
            font-size: 20px;
            font-family: 'sukh-medium';
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #192383;
            margin-top: 54px;
            cursor: pointer;
            text-decoration: underline;

            a {
              color: #192383;
            }
          }

          .timeline-wrap {
            padding-left: 80px;

            .text {
              font-size: 20px;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              color: rgba(0, 0, 0, 0.85);
              margin-bottom: 56px;
              position: relative;
              max-width: 360px;
              position: relative;

              &:after {
                content: '';
                position: absolute;
                height: 100%;
                width: 2px;
                background: url(../images/latest-landing/dotted-line.svg) no-repeat;
                background-size: cover;
                background-position: center center;
                left: -52px;
                top: 34px;
              }

              &.one {
                &:before {
                  content: '';
                  position: absolute;
                  width: 57px;
                  height: 50px;
                  background: url(../images/latest-landing/dot-one.png) no-repeat;
                  background-size: contain;
                  background-position: center center;
                  left: -80px;
                  z-index: 2;
                }

                &:after {
                  height: calc(100% + 36px);
                  top: 30px;
                }
              }

              &.two {
                &:before {
                  content: '';
                  position: absolute;
                  width: 57px;
                  height: 50px;
                  background: url(../images/latest-landing/dot-two.png) no-repeat;
                  background-size: contain;
                  background-position: center center;
                  left: -80px;
                  z-index: 2;
                }

                &:after {
                  height: calc(100% + 28px);
                  top: 40px;
                }
              }

              &.three {
                &:before {
                  content: '';
                  position: absolute;
                  width: 57px;
                  height: 50px;
                  background: url(../images/latest-landing/dot-three.png) no-repeat;
                  background-size: contain;
                  background-position: center center;
                  left: -80px;
                  z-index: 2;
                }

                &:after {
                  display: none;
                }
              }

              img {
                max-width: 86px;
              }

              span {
                font-family: 'sukh-bold';
                color: #f58b1e;
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .section-seven {
    .container {
      padding: 80px 108px;

      .title-text {
        font-size: 30px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.85);
        max-width: 540px;
        margin-bottom: 60px;
      }

      .row {
        display: flex;
        align-items: center;

        .col {
          flex-grow: 1;
          flex-basis: 100%;
          position: relative;
          display: flex;
          align-items: center;

          .text {
            position: absolute;
            left: 115px;
            // top: calc(50% - 2.8px);
            font-size: 28px;
            font-family: 'sukh-bold';
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #000000;
            line-height: 1;
            padding: 0 24px 0 0;
          }

          img {
            max-width: 100%;
          }

          &:not(:last-child) {
            margin-right: 72px;
          }
        }
      }
    }
  }

  .section-eight {
    background-color: #fffaf4;

    .container {
      padding: 80px 108px 280px 108px;
      margin: 0 auto;

      .title-text {
        font-size: 30px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 72px;
      }

      .news {
        margin-right: 40px;
        cursor: pointer;

        img {
          max-width: 100%;
        }
      }
    }
  }

  .section-nine {
    position: relative;

    .container {
      max-width: 1116px;
      margin: 0 auto;
      position: absolute;
      background: url(../images/latest-landing/background-image-four.png) no-repeat;
      background-size: cover;
      background-position: center center;
      text-align: center;
      padding: 110px 50px 50px;
      top: -300px;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);

      .title-text {
        font-size: 30px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 4px;
      }

      .text {
        font-size: 20px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 1.5px;
        color: #ffffff;
      }

      .button {
        border-radius: 8px;
        border: solid 1px #f58b1e;
        background-color: #ffffff;
        padding: 13px 130px;
        font-size: 20px;
        font-family: 'sukh-bold';
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #f48a22;
        margin-top: 24px;
      }
    }
  }

  .static-footer {
    position: relative;
    padding-top: 280px;
  }
}

.landing-header {
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;
  width: 100%;

  .affiliate-toggle {
    margin-left: 20px;
  }

  .sub-header {
    background-color: #192383;

    .container {
      display: flex;
      align-items: center;
      padding: 0 65px;

      a,
      .link {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        color: #fff;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        &.active {
          border-bottom: 2px solid #f58b1e;
        }

        a.active {
          border-bottom: 2px solid #f58b1e;
        }

        &:hover {
          .link-ddl {
            display: block;
          }
        }

        &:not(:last-child) {
          margin-right: 40px;
        }
      }

      .link-ddl {
        position: absolute;
        top: 26px;
        min-width: 200px;
        display: none;

        div {
          border-radius: 6px;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04), 0 -2px 4px 0 rgba(161, 158, 158, 0.17);
          background-color: #ffffff;
          border: 0;
          position: relative;
          padding: 10px 20px;
          margin-top: 10px;

          &:before {
            position: absolute;
            content: '';
            width: 0.71428571em;
            height: 0.71428571em;
            background: #fff;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            z-index: 2;
            -webkit-box-shadow: 1px 1px 0 0 #fff;
            box-shadow: 1px 1px 0 0 #fff;
            top: -0.30714286em;
            left: 1em;
            right: auto;
            bottom: auto;
            margin-left: 0;
          }
        }

        .link {
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.57;
          letter-spacing: normal;
          color: #191715;
          display: block;
          margin: 0;

          &:not(:last-child) {
            border-bottom: 1px solid #d8d8d8;
          }
        }
      }
    }
  }

  .content {
    padding: 16px 72px;
    display: flex;
    align-items: center;

    .logo-wrap {
      display: flex;
      align-items: center;

      img {
        max-width: 120px;
        margin-right: 10px;
      }

      .phone {
        display: flex;
        align-items: center;
        color: #192383;
        font-size: 14px;

        img {
          margin-right: 4px;
          max-width: 12px;
        }
      }
    }

    .menu {
      font-family: 'sukh-regular';
      justify-content: flex-end;
      flex-grow: 1;
      display: flex;
      align-items: center;

      &.unset-flex-grow {
        flex-grow: unset;
      }

      .profile {
        padding-right: 0;
        color: #5c5c5c;
        font-size: 16px;

        .label {
          border-radius: 4px;
          font-size: 14px;
          font-family: 'sukh-bold';
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          line-height: normal;
          padding: 4px 10px;
          display: inline-block;
          margin-right: 4px;
          border: 1px solid #192383;
          text-align: center;
          color: #192383;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100px;
        }

        img {
          max-width: 22px;
          margin-right: 4px;
        }
      }

      .social-icons {
        display: flex;
        align-items: center;
        margin-right: 30px;

        a {
          &:not(:last-child) {
            margin-right: 16px;
          }
        }

        img {
          width: 32px;
          height: 32px;
          object-fit: contain;
        }
      }

      .phone {
        font-family: 'sukh-bold';
        font-size: 14px;
        line-height: 17px;
        color: #1253a4;
        background: #f6f9ff;
        border-radius: 8px;
        padding: 4px 6px;
        margin-right: 24px;
        display: flex;
        align-items: center;

        img {
          object-fit: contain;
          margin-right: 4px;
        }
      }

      .user-name {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
      }

      a {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5c5c5c;
        display: flex;
        align-items: center;

        &.active {
          border-bottom: 2px solid #192383;
        }

        &:not(:last-child) {
          margin-right: 24px;
        }
      }

      .button {
        border-radius: 2px;
        border: solid 1px #ff901d;
        background-color: #ffffff;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: #ff901d;
        padding: 6px 28px;
        outline: none;
        margin-right: 12px;

        &.orange {
          background-color: #ff901d;
          color: #fff;
        }
      }
    }

    .language-toggle {
      display: flex;
      background: #ffffff;
      border: 0.5px solid #ebf3ff;
      border-radius: 4px;

      span {
        font-size: 10px;
        line-height: 12px;
        color: #9cb6d6;
        padding: 6px 8px;
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          margin-right: 4px;
        }

        &.active {
          font-size: 10px;
          line-height: 12px;
          color: #ff6400;
          background: #ebf3ff;
        }
      }
    }
  }

  .container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0;
  }
}

@media screen and (max-width: 1024px) {
  .landing-header {
    // position: relative;
    width: 100%;

    .content {
      padding: 7px 12px;

      .logo-wrap {
        img {
          max-width: auto;
          max-height: 32px;
        }
      }

      .menu {
        img {
          max-width: 30px;
          margin-left: 4px;
        }

        .social-icons {
          margin-right: 12px;
          background: #f6f9ff;
          border-radius: 8px;
          padding: 4px;

          a {
            font-size: 12px;

            &:not(:last-child) {
              margin-right: 12px;
            }
          }
        }

        a {
          font-size: 12px;

          &:not(:last-child) {
            margin-right: 8px;
          }
        }

        .button {
          margin-right: 0;
          font-size: 10px;
          padding: 4px 8px;
        }

        .language-toggle {
          display: none;
        }

        .phone {
          position: fixed;
          width: 100px;
          height: 100px;
          // box-shadow: -1px -5px 10px 0 rgba(34, 37, 72, 0.16);
          bottom: 0;
          right: 0;
          z-index: 99;
          border-radius: 100%;
          background: url(../images/icons/phone-call.svg) no-repeat;
          background-size: cover;
          margin: 0 !important;
          padding: 0;
          font-size: 0;

          img {
            display: none;
          }
        }
      }
    }

    .sub-header {
      display: none;
    }
  }

  .landing-layout {
    padding-top: 0;
    min-height: var(--vh);

    .rounded-phone {
      position: fixed;
      right: 0;
      bottom: 12px;
      z-index: 999;
      display: inline-block;
      text-align: right;

      img {
        object-fit: contain;
      }
    }

    .slick-slider {
      min-height: auto;
    }

    .desktop-view {
      display: none;
    }

    .mobile-view {
      display: block;
    }

    .slick-slider {
      .slick-dots {
        bottom: 80px;

        li button:before {
          line-height: 1 !important;
        }
      }
    }

    .section-one {
      padding-top: 0;

      > div {
        position: relative;

        .button {
          position: relative;
        }
      }

      .buttons-wrap {
        display: flex;
        align-items: center;
        padding: 0 12px;

        button {
          padding: 8px 26px;
          flex-grow: 1;
          flex-basis: 100%;
          background-color: #fff;
          color: #ff6400;
          border: 1px solid #ff9738;
          border-radius: 8px;
          text-align: center;
          justify-content: center;

          &.orange {
            background-color: #ff9738;
            color: #fff;
          }

          &:first-child {
            margin-right: 12px;
          }
        }
      }

      .agent-login-link {
        margin-top: 10px;
        text-align: center;

        span {
          font-size: 18px;
          font-family: 'sukh-bold';
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: #ff901d;
          border-bottom: 1px solid #ff901d;
        }
      }

      .banner-label {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        background: #00b1b7;
        -webkit-text-stroke: 1px #ffffff;
        font-size: 14px;
        border-radius: 0;
        z-index: 9;
        justify-content: center;
        color: #fff;
        letter-spacing: 1px;
      }

      .slick-slider {
        &.first-section {
          padding-top: 30px;

          .slick-list {
            position: relative;
          }

          .slick-arrow {
            &.slick-next {
              right: 0;
            }

            &.slick-prev {
              left: 0;
            }
          }

          .slick-dots {
            bottom: 80px;
          }

          .item {
            padding-bottom: 0;
            align-items: flex-start;
            position: relative;
            outline: none;
            border: 0;

            img {
              // height: calc(var(--vh) - 120px);
              object-fit: fill;
              height: 220px;
            }

            .content {
              padding: 0 20px;
              max-width: 100%;
              top: auto;
              right: 0;
              bottom: 100px;
              z-index: 2;

              .title-text {
                font-size: 20px;
                text-align: center;
                color: #ffffff;
                display: flex;
                align-items: center;
                -webkit-text-stroke: 1.5px #ffffff;

                img {
                  position: relative;
                  left: auto;
                  top: -10px;
                }
              }

              .banner-label {
                display: none;
              }

              .sub-text {
                font-size: 14px;
                font-family: 'sukh-medium';
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.35px;
                text-align: center;
                color: #ffffff;
              }

              .button {
                position: absolute;
                bottom: 6px;
                z-index: 9;
                left: 50%;
                transform: translateX(-50%);
                padding: 10px;
                width: 240px;
                justify-content: center;
                display: none;

                img {
                  display: none;
                }
              }
            }
          }
        }
      }

      .oic-wrapper {
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 32px auto 0 auto;
        padding-bottom: 32px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: calc(100% - 50px);
          border-bottom: 0.5px solid rgba(151, 151, 151, 0.3);
          height: 1px;
          margin: auto;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        > div {
          padding: 2px 12px 3px 12px;
          min-height: 36px;

          img {
            max-width: 90px;
          }

          &:last-child {
            border: 1px solid #192383;

            span {
              font-size: 12px;
            }
          }
        }
      }

      .insurers-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        margin-top: 40px;

        .item {
          img {
            width: 70px;
            height: 70px;
          }
        }
      }
    }

    .section-two {
      .react-player__shadow {
        width: 80px !important;
        height: 80px !important;
      }

      .container {
        padding: 86px 18px 56px 18px;

        .row {
          flex-direction: column;

          .col {
            width: 100%;

            &:first-child {
              margin: 0 0 30px 0;
            }

            .fairdee-cares {
              top: -38px;
              left: -15px;
            }

            .title-text {
              font-size: 22px;
              line-height: 1.82;
              margin-bottom: 20px;
            }

            .sub-text {
              font-size: 14px;
              margin-bottom: 24px;
            }

            .text {
              font-size: 20px;
            }
          }
        }
      }
    }

    .section-three {
      .container {
        padding: 56px 22px;
        background: url(../images/latest-landing/map-mobile.svg) no-repeat;
        background-position: right 65%;

        .title-text {
          font-size: 22px;
          margin-bottom: 46px;
        }

        .row {
          flex-direction: column;

          .col {
            max-width: 200px;
            min-width: 200px;
            padding: 35px 0;

            &:not(:last-child) {
              border-bottom: solid 1px #dfdfdf;
            }

            &:last-child {
              padding-bottom: 0;

              img {
                max-width: 100px;
              }
            }

            img {
              max-width: 190px;
              margin-bottom: 4px;
            }

            p {
              font-size: 14px;
              font-family: 'sukh-medium';
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: rgba(0, 0, 0, 0.85);
            }
          }
        }
      }
    }

    .mlm-benefits,
    .management-fees,
    .fairdee-plus {
      .container {
        padding: 56px 22px;
      }

      .row {
        flex-direction: column;

        .col {
          &:not(:last-child) {
            border-bottom: solid 1px #dfdfdf;
            margin-right: 0;
          }

          &:first-child {
            margin-bottom: 20px;
          }

          img {
            max-width: 100%;
            margin-bottom: 4px;
            width: 100%;
          }

          .title-text {
            font-size: 22px;
            margin-bottom: 20px;
          }

          .text {
            font-size: 14px;
            font-family: 'sukh-medium';
          }
        }
      }
    }

    .section-four {
      padding-bottom: 40px;

      .container {
        padding: 56px 22px;

        .title-text {
          font-size: 26px;
        }

        .slick-dots {
          bottom: -48px;
        }

        .row {
          flex-direction: column;

          .col {
            text-align: center;
            width: 100%;

            &:not(:last-child) {
              margin-right: 0;
              margin-bottom: 48px;
            }

            img {
              width: 80px;
              height: 80px;
              object-fit: contain;
              margin: 0 auto 16px auto;
            }

            .text {
              font-size: 18px;
              margin-bottom: 4px;
            }

            .sub-text {
              font-size: 16px;
              line-height: 1.63;
            }
          }
        }
      }
    }

    .section-five {
      .container {
        padding: 56px 0 0;

        .react-player__shadow {
          width: 80px !important;
          height: 80px !important;
        }

        .row {
          flex-direction: column;

          .col {
            max-width: 100%;

            .title-text {
              font-size: 22px;
              margin-bottom: 16px;
            }

            .sub-text {
              font-size: 14px;
            }

            &:not(:last-child) {
              margin-right: 0;
              max-width: 100%;
              padding: 0 24px;
              position: relative;
              z-index: 1;
            }

            &:last-child {
              max-width: 100%;
              min-height: 560px;
              background: url(../images/latest-landing/background-image-mobile-two.png) no-repeat;
              background-size: 100% 100%;
              background-position: center center;
              padding: 24px;
              align-items: flex-end;
              justify-content: center;
              padding-bottom: 56px;
              margin-top: -240px;

              .testimonial {
                margin-bottom: 40px;

                .text-one {
                  font-size: 16px;
                  margin-bottom: 20px;
                }

                .text-two {
                  font-size: 14px;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: rgba(255, 255, 255, 0.85);
                }
              }

              .slick-slider {
                margin: 0;
                max-width: 100%;

                .slick-dots {
                  bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    .section-six {
      .container {
        padding: 56px 22px;

        .row {
          flex-direction: column;

          .col {
            &:first-child {
              margin-right: 0;
              margin-bottom: 20px;
            }

            .title-text {
              font-size: 22px;
            }

            .more-details {
              font-size: 14px;
              display: none;
            }

            .number {
              font-size: 20px;
              font-family: 'sukh-bold';
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #192383;
              display: block;
            }

            .timeline-wrap {
              padding-left: 0;

              .text {
                font-size: 14px;
                margin-bottom: 16px;

                &:before,
                &after {
                  display: none;
                }

                img {
                  width: 69px;
                }
              }
            }
          }
        }
      }
    }

    .section-seven {
      .container {
        padding: 56px 22px;

        .title-text {
          font-size: 22px;
          margin-bottom: 40px;
        }

        .row {
          flex-direction: column;

          .col {
            &:not(:last-child) {
              margin: 0 0 24px 0;
            }

            .text {
              font-size: 20px;
              top: calc(50% - 16px);
              left: 125px;
            }
          }
        }
      }
    }

    .section-eight {
      .container {
        padding: 56px 22px 150px 22px;

        .news {
          margin-right: 8px;
          margin-bottom: 32px;
        }

        .slick-slider {
          .slick-dots {
            bottom: 0;
          }
        }

        .title-text {
          font-size: 22px;
          margin-bottom: 36px;
        }
      }
    }

    .section-nine {
      .container {
        max-width: calc(100% - 56px);
        background: url(../images/latest-landing/background-image-mobile-four.png) no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        padding: 58px 48px 24px 55px;
        top: -145px;

        .title-text {
          font-size: 15px;
          margin-bottom: 4px;
        }

        .text {
          font-size: 14px;
          font-family: 'sukh-medium';
        }

        .button {
          font-size: 14px;
          padding: 13px;
          width: 100%;
          max-width: 80%;
        }
      }
    }

    .static-footer {
      padding-top: 120px;
    }
  }
}
