.loading-screen {
  background: rgba(47, 47, 47, 0.2);
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .content {
    max-width: 340px;
    min-width: 340px;
    background: #ffffff;
    border-radius: 8px;
    padding: 54px;

    .text-one {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #2f2f2f;
    }

    .text-two {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #707070;
    }
  }
}

.ai-loader {
  display: inline-flex;
  .animation {
    width: 32px;
    height: 32px;
  }
}
