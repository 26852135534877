.dropdown-datepicker {
  &.padding-24 {
    padding: 0 24px;
  }

  &.inline-fields {
    display: flex;
    justify-content: left;
    .dropdown-year, .dropdown-day, .dropdown-month, .dropdown-month-of-year {
      margin-right: 10px;
      flex-basis: 100%;
      flex-grow: 1;

      >div {
        min-width: 100% !important;
      }

      .ui.search.selection.dropdown {
        min-width: 9em;
        border: 1px solid rgba(34,36,38,.15);
        border-radius: 1px;
        padding: 10px 6px 10px;

        .icon {
            bottom: initial;
            top: initial;

            &.angle.down {
              position: absolute;
              right: 0;
            }
            &::before {
              content: inherit;
              background: none;
            }
        }
      }
    }
  }
}
