// Core Variables
$primary: #ff6400;
$primary-light: #ffefe2;
$primary-dark: #ffc107;
$primary-shade: #ffa000;
$primary-gradient: linear-gradient(180deg, #ff9738 0%, #ff8743 100%), #ff9738;
$primary-gradient-light: linear-gradient(180deg, #ff8a00 0%, rgba(255, 255, 255, 0) 286.87%);
$primary-color: #192383;

$secondary: #1253a4;
$secondary-light: #b3caef;

$tertiary: #e3e3e3;
$tertiary-light: #f6f9ff;
$tertiary-dark: #d8e7fd;
$tertiary-mid-dark: #d3dded;

$text-dark: #2f2f2f;
$text-light: #707070;
$text-gray: #6d6e71;

$input-dark: #202020;
$input-light: #889bb9;

$error: #ff0000;

$white: #ffffff;
$green: #06c655;
$black: #000000;
$primary-light-yellow: #fdf0cc;
$light-yellow-1: #fffef2;

$primary-light-blue: #9cb6d6; // base
$light-blue-1: #f6f9ff; // lb 1
$light-blue-2: #ebf3ff; // lb 2
$light-blue-3: #d8e7fd; // lb 3
$light-blue-4: #d3dded; // lb 4
$light-blue-5: #889bb9; // prev: input-light | lb-5
$light-blue-6: rgba(18, 83, 164, 0.05);
$light-blue-7: rgba(246, 249, 255, 0.5);
$light-blue-8: rgba(58, 151, 226, 0.2);

$primary-red: #ff0000; // prev: $error
$red-1: #ff6f6f; // prev: $red-secondary
$red-2: #e93d3d; // prev: $red
$red-3: #fd3c4a;

$primary-rose: #d5596c;
$rose-1: #fff5f5;
$rose-2: #ffc9c9;
$rose-3: #fd5662; // not documenting
$rose-4: rgba(253, 60, 74, 0.05);
$rose-gradient: rgba(253, 60, 74, 0.03);

$primary-blue: #192383;
$blue-1: #7097c7;
$blue-2: #3062a1;
$blue-3: #1253a4;
$blue-4: #11417c;
$blue-5: rgba(216, 231, 253, 0.5);

$orange-1: #fffbf7;
$orange-2: #ffefe2; // prev: primary-light
$orange-3: #ffa000; // prev: orange-alt
$orange-4: #ff6400;
$orange-5: rgba(255, 100, 0, 0.05);

$primary-black: #2f2f2f; //prev: $text-dark
$black-1: #0000004d;
$black-2: #707070;
$black-3: #6d6e71;
$black-4: rgba(45, 45, 45, 0.03);
$black-5: rgba(45, 45, 45, 0.8);
$black-6: #5d7183; // prev: text-gray-1
$black-seven: rgba(0, 0, 0, 0.87);
$black-eight: #242424;
$black-nine: #555454;
$black-ten: #677489;
$black-eleven: #161719;
