// Core Variables
$primary: #ff6400;
$primary-light: #ffefe2;
$primary-dark: #ffc107;
$primary-gradient: linear-gradient(180deg, #ff9738 0%, #ff8743 100%), #ff9738;
$primary-gradient-light: linear-gradient(180deg, #FF8A00 0%, rgba(255, 255, 255, 0) 286.87%);

$secondary: #1253a4;
$secondary-light: #b3caef;
$secondary-three: #096dd9;

$tertiary: #e3e3e3;
$tertiary-light: #f6f9ff;
$tertiary-dark: #d8e7fd;
$tertiary-mid-dark: #d3dded;

$text-dark: #2f2f2f;
$text-light: #707070;
$text-gray: #6D6E71;

$input-dark: #202020;
$input-light: #889bb9;
$input-label: #4D4F5C;
$input-black: #1b1b1b;

$error: #ff0000;

$white: #ffffff;
$green: #06C655;