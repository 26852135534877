$easeInOut: cubic-bezier(0.455, 0.03, 0.515, 0.955);

.qr-details {
  margin-bottom: 56px;

  .flex-item {
    display: flex;
  }

  .bold-text {
    font-family: 'sukh-bold';
  }

  .discount-text {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #f58c1e;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    img {
      width: 16px;
      height: 16px;
      object-fit: contain;
      margin-right: 5px;
    }
  }

  .discount-button-holder {
    padding-bottom: 15px;

    .button {
      text-transform: capitalize;
      border: 0;
      background: #fff;
      outline: none;
      box-shadow: none;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #f58c1e;
      border-bottom: solid 2px #f58c1e;
      padding: 0;
      border-radius: 0;
    }
  }

  .qr-amount-wrapper {
    margin-top: 28px;
    text-align: center;
    background: #f6f9ff;
    border-radius: 4px;

    .text-wrapper {
      border-bottom: 1px solid #d3dded;

      .title-text {
        padding-top: 4px;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #6d6e71;
      }

      .text {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1253a4;
      }
    }

    .discount-edit-wrapper {
      padding: 6px 0 8px 0;
      p {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        color: #ff6400;

        img {
          margin-right: 4px;
        }
      }
    }
  }

  .border-wrapper {
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

    .border {
      border: 1px solid #d3dded;
      width: 100%;
    }

    .text {
      padding: 0 8px 0 8px;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #9cb6d6;
    }
  }

  .payment-choice {
    margin-top: 22px;
    padding: 8px;
    text-align: center;
    background: #ebf3ff;
    box-shadow: 0px 3px 6px #d8e7fd;
    border-radius: 8px;
    cursor: pointer;

    .text-wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .text {
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #6d6e71;

        span {
          color: #1253a4;
        }
      }

      img {
        margin-left: auto;
      }
    }
  }

  .pay-via-qr {
    @extend .bold-text;
    @extend .flex-item;
    margin-top: 60px;
  }

  .qr-image-wrapper {
    width: 279px;
    height: 350px;
    margin: 0 auto;
    .qr-banner {
      img {
        max-width: 100%;
      }
    }

    .prompt-pay {
      width: 85%;
      margin-top: 10px;
      img {
        max-width: 100%;
        display: inline-block;
        padding-left: 40px;
        height: 70px;
        margin: 0 auto;
      }
    }

    .img-wrap {
      padding: 10px 20px 10px 20px;
    }

    .qr-image {
      width: 100%;
      height: auto;
      max-width: 100%;
      cursor: pointer;
    }
  }

  .qr-image {
    width: 165px;
    height: 170px;
  }

  .text {
    font-size: 18px;
    font-family: 'sukh-bold';
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #000000;
  }

  .subtitle-text {
    font-size: 16px;
    font-family: 'sukh-medium';
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #000000;
  }

  .sub-text {
    font-size: 16px;
    font-family: 'sukh-medium';
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #5b5858;
    margin-bottom: 2px;

    &.margin {
      margin-bottom: 20px;
    }
  }

  .green-text {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #1daa8d;
    margin-bottom: 8px;
  }

  .red-text {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #e93d3d;
    margin-bottom: 8px;
  }
}
