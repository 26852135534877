
.pdf-viewer {
  .pdf-content {

    .zoom-action {
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 1;
      right: 16px;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }

      .buttons {
        margin-bottom: 8px;
      }
    }

    .pdf-doc {
      overflow: auto;
      canvas {
        min-width: auto;
        height: auto;
      }

      .loader{
        .loading-screen{
          position: relative;
          height: 60vh;
         display: flex;
         align-items: center;
         justify-content: center;
        }
      }

      .error-load {
        overflow: hidden;
        background-color: orange;
        border-radius: 4px;
        height: 60vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .icon-wrapper {
         display: flex;
         align-items: center;

          margin: 16px;
          .icon {
            width: 32px;
          }
        }

        .text{
          margin: 8px
        }
      }
    }
    .react-pdf__Page__textContent {
      width: 100%;
      height: auto;
      span {
        display: none;
      }
    }
    .react-pdf__Page__annotations.annotationLayer {
      display: none;
    }
  }

  .pagination {
    .bullet {
     display: flex;
     align-items: center;

      .indicator {
        background-color:grey;
        width: 16px;
        height: 16px;
        border-radius: 200px;
        margin-right: 8px;
        cursor: pointer;

        &.active {
          background-color: orange;
        }
      }
    }

    .page-counter {
      text-align: center;
      margin: 12px 0;
    }
    .button-counter {
     display: flex;
     align-items: center;


      .buttons {
        margin-right: 8px;
      }
    }
  }
}
