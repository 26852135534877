.global-search {
  padding: 0 12px;
  flex-grow: 1;

  .input-wrap {
    display: flex;
    justify-content: flex-end;

    .input-field {
      border: 1px solid #dcdfe3;
      background: #fff;
      padding: 8px 16px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      cursor: pointer;
      width: 320px;
    }
  }

  .search-wrap {
    width: 100%;
    height: var(--vh);
    background-color: #f5f5f5;
    padding: 32px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;

    .content-wrap {
      margin: 0 auto;
      max-width: 1200px;
      position: relative;

      .text {
        color: #2f2f2f;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 16px;
      }
    }

    .input-element {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: flex-end;

      .search {
        min-width: 36px;
      }

      input {
        border: 0;
        background-color: transparent;
        width: 100%;
        color: rgba(0, 0, 0, 0.9);
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 142.857% */
        outline: none;
        flex-grow: 1;
      }

      .clear-text {
        color: var(--text-primary-orange-500, #ff6400);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;
      }
    }

    .search-results {
      .grid-wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
      }

      .column {
        padding: 16px 0;

        .title-text {
          color: #2f2f2f;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 16px;
        }
      }

      .result-wrap {
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 1px 6px 0px rgba(216, 231, 253, 0.5);
        padding: 12px;
      }

      .key-value-wrap {
        display: flex;
        align-items: flex-start;
        margin-bottom: 2px;

        .key {
          color: var(--dark-80, #2d2d2d);
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          min-width: 160px;
          text-transform: capitalize;
        }

        .value {
          color: var(--text-primary-black-600, #2f2f2f);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          word-break: break-word;

          &.tag {
            border-radius: 10px;
            padding: 1px 6px;
            background: rgba(255, 100, 0, 0.05);
            color: #ff9738;
            font-size: 12px;
            font-weight: 600;

            &.red {
              background: rgba(253, 60, 74, 0.05);
              color: #fd5662;
            }

            &.green {
              background: rgba(0, 168, 107, 0.05);
              color: #00a86b;
            }

            &.blue {
              background: rgba(18, 83, 164, 0.05);
              color: #1253a4;
            }
          }
        }
      }
    }
  }

  .cross {
    right: 0;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: -24px;
  }

  .no-data {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 220px;
    font-size: 24px;
    font-weight: 600;
  }

  .loading-wrap {
    text-align: center;
    padding: 12px;

    .anticon {
      color: orange;
      font-size: 24px;
    }
  }

  .link-text {
    color: #ff6400;
    text-align: right;
    display: block;
    text-decoration: none;
  }

  em {
    color: #000;
    font-weight: bold;
    background-color: #fffb0075;
  }

  .agent-card {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 1px 6px 0px rgba(216, 231, 253, 0.5);
    padding: 12px;

    .text-wrap {
      display: flex;
      align-items: center;

      .text-one {
        flex-grow: 1;
      }

      .link-text {
        font-size: 12px;
      }
    }

    .img-wrap {
      min-width: 60px;
      min-height: 60px;
      max-width: 60px;
      max-height: 60px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid rgb(216, 231, 253);
      margin: 0 auto;

      .anticon {
        font-size: 44px;
        color: rgb(216, 231, 253);
      }

      img {
        height: 100%;
        width: 100%;
        border-radius: 100%;
      }
    }

    .content-wrap {
      margin-top: 12px;

      .text-one {
        font-size: 20px;
        color: #000;
        line-height: 24px;
        font-weight: bold;
      }

      .line {
        border-bottom: 1px solid rgb(216, 231, 253);
        margin: 12px 0;
      }
    }
  }
}
