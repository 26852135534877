@import '../scss/variables';

.header-section {
  background: $white;
  box-shadow: 0px 0px 6px $tertiary-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px 20px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  max-width: 480px;
  margin: 0 auto;
  z-index: 1001;

  &.logo {
    padding: 0;
    box-shadow: none;
  }

  .img-wrap {
    text-align: center;
    min-width: 100%;
    width: 100%;

    img {
      width: 150px;
      display: inline-block;
    }
  }

  .back {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    cursor: pointer;

    img {
      width: 6px;
      height: 12px;
    }
  }

  .text {
    flex-grow: 1;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $secondary;
  }
}

@media screen and (min-width: 768px) {
  .header-section {
    box-shadow: none;
  }
}
